import { FormProps } from 'antd'

export type CampaignStepForm<T> = Pick<
  FormProps<T>,
  | 'name'
  | 'disabled'
  | 'initialValues'
  | 'onValuesChange'
  | 'onFinish'
  | 'onFinishFailed'
> & {
  loading?: boolean
  site?: string | number
  template_type?: string
}

export function combineInitialValue(
  initValue: Record<string, any>,
  defaultValue?: Record<string, any>
): Record<string, any> {
  const newInitValue = { ...defaultValue, ...initValue }
  Object.keys(newInitValue).forEach((key) => {
    const value = newInitValue[key]
    if (!value) {
      newInitValue[key] = defaultValue?.[key] || null
    }
  })
  return newInitValue
}

export * from './CampaignStepOverviews'
export * from './CampaignStepSegments'
export * from './CampaignStepSites'
export * from './CampaignStepTemplates'
