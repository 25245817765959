/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, notification } from 'antd'
import { Box } from 'components/atoms/box'
import { getToken } from 'context/Auth'
import FeatureCarousel from 'pages/login/FeatureCarousel'
import LoginFooter from 'pages/login/LoginFooter'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { sendResetPassword } from 'services/user'

const DURATION_TO_RESEND = 60

const ResetPasswordPage = () => {
  const navigate = useNavigate()
  const intervalId = useRef<NodeJS.Timer | null>(null)
  const [loading, setLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [email, setEmail] = useState('')
  const [countDown, setCountDown] = useState(0)

  useEffect(() => {
    if (getToken()) {
      navigate('/')
    }
  }, [navigate])

  useEffect(() => {
    return () => {
      intervalId.current && clearInterval(intervalId.current)
    }
  }, [])

  useEffect(() => {
    if (countDown === DURATION_TO_RESEND) {
      intervalId.current && clearInterval(intervalId.current)
      intervalId.current = setInterval(() => {
        setCountDown((prev) => {
          if (prev - 1 === 0) {
            intervalId.current && clearInterval(intervalId.current)
          }
          return prev - 1
        })
      }, 1000)
    }
  }, [countDown])
  // {baseURL}/password/reset/9bb8803769d55f1f64b1f6376b7d07465defc2d6c9dc971f98c7a728f075469c?email=luc.tran%40hellohealthgroup.com
  const handleSentEmail = async ({ email }: { email: string }) => {
    if (loading) return
    try {
      setLoading(true)
      setEmail(email)
      setCountDown(DURATION_TO_RESEND)
      await sendResetPassword({ email })
      setIsSent(true)
    } catch (err) {
      console.log('[Login] error', err)
      notification.error({
        message: 'Oops!',
        description:
          'Something went wrong. Please try again or contact support.',
        placement: 'topLeft'
      })
    }
    setLoading(false)
  }

  const handleClickResend = (evt: any) => {
    evt.preventDefault()

    if (countDown === 0 && email) {
      handleSentEmail({ email })
    }
  }

  return (
    <Box className="flex flex-row md:h-[100vh] bg-[#FAFAFA]">
      <Box className="flex flex-1 h-full flex-col justify-center items-center relative overflow-y-auto">
        <Box className="text-center max-w-full w-[440px] p-[40px] bg-[#fff] rounded-[16px] my-auto">
          {isSent ? (
            <img
              src="/images/sidis-email-sent.png"
              alt="Sidis"
              className="w-[80px] h-auto block mb-[40px] mx-auto"
            />
          ) : (
            <img
              src="/images/sidis-logo.png"
              alt="Sidis"
              className="w-[96px] h-auto block mb-[40px] mx-auto"
            />
          )}
          <h2 className="text-[#262626] font-bold text-[22px] leading-[32px] tracking-[-0.6px]">
            {isSent ? 'Email Sent' : 'Forgot password?'}
          </h2>
          {isSent ? (
            <p className="text-base mt-[8px] mb-[24px] text-[#595959]">
              We&apos;ve sent an email to <strong>{email}</strong> to reset your
              password. Please check your inbox.
            </p>
          ) : (
            <p className="text-base mt-[8px] mb-[24px] text-[#595959]">
              Enter the email associated with your account and we&apos;ll send
              an email with instructions to reset your password.
            </p>
          )}

          {!isSent && (
            <Form
              layout="vertical"
              className="text-left"
              onFinish={handleSentEmail}>
              <Form.Item
                label="Email"
                name="email"
                requiredMark="optional"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'Please input a valid email' }
                ]}>
                <Input
                  size="large"
                  className="!py-[12px]"
                  placeholder="Enter your email here"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                  className="w-full block">
                  Continue
                </Button>
              </Form.Item>
            </Form>
          )}

          {isSent ? (
            <p className="text-[#737373] text-[14px] leading-[22px] mr-[24px tracking-[-0.2px]">
              Didn’t get the email?{' '}
              <Link
                to="/login"
                className="text-[#2D87F3] font-semibold"
                onClick={handleClickResend}>
                Resend ({countDown})
              </Link>
            </p>
          ) : (
            <p className="text-[#737373] text-[14px] leading-[22px] mr-[24px tracking-[-0.2px]">
              Have your password?{' '}
              <Link to="/login" className="text-[#2D87F3] font-semibold">
                Login
              </Link>
            </p>
          )}
        </Box>
        <LoginFooter />
      </Box>
      <FeatureCarousel />
    </Box>
  )
}

export default ResetPasswordPage
