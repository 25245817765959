import { List } from 'antd'
import { InputSearch } from 'components/atoms/input'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

export const StyledInputSearch = styled(InputSearch)``

export const StyledListItemMeta = styled(List.Item.Meta)`
  .ant-list-item-meta-title {
    margin-bottom: 0;
    font-weight: 400;
    transition: 0.25s ease all;
  }
`

export const StyledListItem = styled(List.Item)`
  padding: 8px 0;
  cursor: pointer;
  &:hover {
    ${StyledListItemMeta} {
      .ant-list-item-meta-title {
        font-weight: 500;
        transition: 0.25s ease all;
      }
    }
  }
`

export const StyledList = styled(List)`
  .ant-list-header {
    padding-top: 0;
    padding-bottom: 8px;
    border-bottom: 0;
  }
  .ant-list-items {
    ${cssScrollBar}
    ${StyledListItem} {
      border-bottom: 0;
    }
  }
`
