import {
  SEGMENT_STATUS_KEYS,
  SegmentFormValue,
  formatSegmentForm
} from 'components/molecules/segment'
import { segmentEventIdVisitSite } from 'components/molecules/segment/SegmentForm/SegmentFormQuery/SegmentFormQuery.helpers'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import { SEGMENT_TYPE } from 'constants/segment'
import dayjs from 'dayjs'
import { segmentServices } from 'services/segment'
import { segmentConditonBuilderServices } from 'services/segmentBuilder'
import { FormNamePath } from 'utils/form'

export type ErrorsSegment = { name?: FormNamePath; errors: string[] }
type PostCreateSegmentReturn = {
  data: Partial<SegmentFormValue> | null
  errors: ErrorsSegment[]
}

type PostAddConditionSegmentATTRIBUTE = {
  createdBy: number
  segmentId: string
  startDate: string
  endDate: string
  listCondition: any[]
  operator: string
  site: string
  type: string
}
export async function postAddConditionSegmentATTRIBUTE(
  id: string | number,
  value: PostAddConditionSegmentATTRIBUTE
) {
  if (!id) {
    return { data: null, errors: [{ errors: ['Segment is not exist'] }] }
  }
  if (!value.startDate || !value.endDate) {
    return { data: null, errors: [{ errors: ['Time range is required'] }] }
  }
  if (!value.site) {
    return { data: null, errors: [{ errors: ['Site is required'] }] }
  }
  try {
    const responseAddCond: any =
      await segmentConditonBuilderServices.createSegmentConditionBaseBuilder(
        id,
        value
      )

    return { data: responseAddCond.data, errors: [] }
  } catch (error) {
    console.log('** RYAN index.ts 157 error : ', error)
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}

// POST : Create Segment
export async function postCreateSegmentATTRIBUTE(
  value: SegmentFormValue
): Promise<PostCreateSegmentReturn> {
  try {
    const segment: PostCreateSegmentReturn = { data: null, errors: [] }
    const today = dayjs()
    // Create segment ATTRIBUTE
    // #region
    const response: any = await segmentServices.createSegment({
      name: value?.name || today.toString(),
      description: value?.description,
      type: value?.list?.[0]?.segment_type,
      site_ids: value?.site_ids || [],
      tag_ids: value?.tag_ids || [],
      attribute_conditions: value?.list || []
    })
    if (response?._message || !response.data) {
      throw new Error(JSON.stringify(response))
    }

    segment.data = response.data
    return segment
  } catch (error: any) {
    const errorObj = JSON.parse(error.message)
    if (errorObj?._message) {
      if (errorObj._message.includes('name')) {
        return {
          data: null,
          errors: [{ name: 'name', errors: [errorObj._message] }]
        }
      }
      return { data: null, errors: [{ errors: errorObj?._message }] }
    }
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}
export async function postCreateSegmentMOBILE(
  value: SegmentFormValue
): Promise<PostCreateSegmentReturn> {
  try {
    const today = dayjs()
    const response = await segmentServices.createSegment({
      name: value?.name || today.toString(),
      date_type: value?.date_type,
      description: value?.description,
      type: value?.list?.[0]?.segment_type,
      site_ids: value?.site_ids || [],
      tag_ids: value?.tag_ids || [],
      mobile_conditions: value?.list
    })
    if (response._message || !response.data) {
      throw new Error(JSON.stringify(response))
    }

    return { data: formatSegmentForm(response.data), errors: [] }
  } catch (error: any) {
    const errorObj = JSON.parse(error?.message)
    if (errorObj?._message) {
      if (errorObj._message.includes('name')) {
        return {
          data: null,
          errors: [{ name: 'name', errors: [errorObj._message] }]
        }
      }
      return { data: null, errors: [{ errors: errorObj?._message }] }
    }
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}
export async function postCreateSegmentEVENTCUSTOM(
  value: SegmentFormValue
): Promise<PostCreateSegmentReturn> {
  try {
    const today = dayjs()
    const response = await segmentServices.createSegment({
      name: value?.name || today.toString(),
      description: value?.description,
      type: value?.list?.[0]?.segment_type,
      site_ids: value?.site_ids || [],
      tag_ids: value?.tag_ids || [],
      custom_conditions: value?.list || []
    })
    if (response._message || !response.data) {
      throw new Error(JSON.stringify(response))
    }

    return { data: formatSegmentForm(response.data), errors: [] }
  } catch (error: any) {
    const errorObj = JSON.parse(error?.message)
    if (errorObj?._message) {
      if (errorObj._message.includes('name')) {
        return {
          data: null,
          errors: [{ name: 'name', errors: [errorObj._message] }]
        }
      }
      return { data: null, errors: [{ errors: errorObj?._message }] }
    }
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}
export async function postCreateSegmentEVENT(value: SegmentFormValue) {
  try {
    const today = dayjs()
    const response = await segmentServices.createSegment({
      name: value?.name || today.toString(),
      description: value?.description,
      type: value?.list?.[0]?.segment_type,
      site_ids: value?.site_ids || [],
      tag_ids: value?.tag_ids || [],
      events_conditions: value?.list
        ?.map((item) => {
          const shouldSkipSubmitted = item.event_id === segmentEventIdVisitSite
          if (shouldSkipSubmitted) {
            return {
              ...item,
              submitted: undefined,
              submitted_from: undefined,
              submitted_to: undefined
            }
          }
          if (!item.submitted?.[0] || !item.submitted?.[1]) {
            return
          }
          return {
            ...item,
            submitted_from: item.submitted[0].format(FORMAT_DATE_DAYJS_API),
            submitted_to: item.submitted[1].format(FORMAT_DATE_DAYJS_API)
          }
        })
        .filter(Boolean)
    })
    if (response._message || !response.data) {
      throw new Error(JSON.stringify(response))
    }

    return { data: formatSegmentForm(response.data), errors: [] }
  } catch (error: any) {
    const errorObj = JSON.parse(error?.message)
    if (errorObj?._message) {
      if (errorObj._message.includes('name')) {
        return {
          data: null,
          errors: [{ name: 'name', errors: [errorObj._message] }]
        }
      }
      return { data: null, errors: [{ errors: errorObj?._message }] }
    }
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}
export async function postCreateSegment(
  value: SegmentFormValue
): Promise<PostCreateSegmentReturn> {
  switch (value?.list?.[0]?.segment_type) {
    case SEGMENT_TYPE.ATTRIBUTE: {
      return postCreateSegmentATTRIBUTE(value)
    }
    case SEGMENT_TYPE.EVENT: {
      return postCreateSegmentEVENT(value)
    }
    case SEGMENT_TYPE.MOBILE: {
      return postCreateSegmentMOBILE(value)
    }
    case SEGMENT_TYPE.EVENT_CUSTOM: {
      return postCreateSegmentEVENTCUSTOM(value)
    }
    default:
      return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}

// GET : Get Segment
export async function getSegment(
  id?: string
): Promise<{ data: SegmentFormValue | null; errors: ErrorsSegment[] }> {
  if (!id) {
    return { data: null, errors: [{ errors: ['Segment ID is NOT valid'] }] }
  }

  try {
    const response: any = await segmentServices.getSegment(id)
    if (response?._message || !response?.data) {
      throw new Error(JSON.stringify(response))
    }
    return {
      data: formatSegmentForm(response.data),
      errors: []
    }
  } catch (error) {
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}

// PUT : Put Segment
export async function putUpdateSegmentATTRIBUTE(value: SegmentFormValue) {
  if (!value?.id) {
    return {
      data: null,
      errors: [{ errors: ['Segment is NOT valid'] }]
    }
  }
  try {
    const response: any = await segmentServices.updateSegment(value.id, {
      name: value?.name || '',
      description: value?.description,
      tag_ids: value?.tag_ids || [],
      type: SEGMENT_TYPE.ATTRIBUTE,
      attribute_conditions: value?.list || [],
      status: value?.status || SEGMENT_STATUS_KEYS.DRAFT
    })
    if (response?._message || !response.data) {
      throw new Error(JSON.stringify(response))
    }

    return { data: formatSegmentForm(response.data), errors: [] }
  } catch (error) {}
  return { data: null, errors: [{ errors: ['Something went wrong'] }] }
}
export async function putUpdateSegmentMOBILE(value: SegmentFormValue) {
  if (!value?.id) {
    return {
      data: null,
      errors: [{ errors: ['Segment is NOT valid'] }]
    }
  }
  try {
    const response: any = await segmentServices.updateSegment(value.id, {
      name: value?.name || '',
      description: value?.description || '',
      tag_ids: value?.tag_ids || [],
      type: SEGMENT_TYPE.MOBILE,
      mobile_conditions: value?.list || [],
      status: value?.status || SEGMENT_STATUS_KEYS.DRAFT
    })

    if (response?._message || !response.data) {
      throw new Error(JSON.stringify(response))
    }

    return { data: formatSegmentForm(response.data), errors: [] }
  } catch (error: any) {
    const errorObj = JSON.parse(error.message)
    if (errorObj?._message) {
      if (errorObj._message.includes('name')) {
        return {
          data: null,
          errors: [{ name: 'name', errors: [errorObj._message] }]
        }
      }
      return { data: null, errors: [{ errors: errorObj?._message }] }
    }
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}
export async function putUpdateSegmentEVENTCUSTOM(value: SegmentFormValue) {
  if (!value?.id) {
    return {
      data: null,
      errors: [{ errors: ['Segment is NOT valid'] }]
    }
  }
  try {
    const response: any = await segmentServices.updateSegment(value.id, {
      name: value?.name || '',
      description: value?.description || '',
      tag_ids: value?.tag_ids || [],
      type: SEGMENT_TYPE.EVENT_CUSTOM,
      custom_conditions: value?.list || [],
      status: value?.status || SEGMENT_STATUS_KEYS.DRAFT
    })

    if (response?._message || !response.data) {
      throw new Error(JSON.stringify(response))
    }

    return { data: formatSegmentForm(response.data), errors: [] }
  } catch (error: any) {
    const errorObj = JSON.parse(error.message)
    if (errorObj?._message) {
      if (errorObj._message.includes('name')) {
        return {
          data: null,
          errors: [{ name: 'name', errors: [errorObj._message] }]
        }
      }
      return { data: null, errors: [{ errors: errorObj?._message }] }
    }
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}
export async function putUpdateSegmentEVENT(value: SegmentFormValue) {
  if (!value?.id) {
    return {
      data: null,
      errors: [{ errors: ['Segment is NOT valid'] }]
    }
  }
  try {
    const response: any = await segmentServices.updateSegment(value.id, {
      name: value?.name || '',
      description: value?.description || '',
      tag_ids: value?.tag_ids || [],
      type: SEGMENT_TYPE.EVENT,
      status: value?.status || SEGMENT_STATUS_KEYS.DRAFT,
      events_conditions:
        value?.list
          ?.map((item) => {
            const shouldSkipSubmitted =
              item.event_id === segmentEventIdVisitSite
            if (shouldSkipSubmitted) {
              return {
                ...item,
                submitted: undefined,
                submitted_from: undefined,
                submitted_to: undefined
              }
            }
            if (!item.submitted?.[0] || !item.submitted?.[1]) {
              return
            }
            return {
              ...item,
              submitted_from: item.submitted[0].format(FORMAT_DATE_DAYJS_API),
              submitted_to: item.submitted[1].format(FORMAT_DATE_DAYJS_API)
            }
          })
          .filter(Boolean) || []
    })

    if (response?._message || !response.data) {
      throw new Error(JSON.stringify(response))
    }

    return { data: formatSegmentForm(response.data), errors: [] }
  } catch (error: any) {
    const errorObj = JSON.parse(error.message)
    if (errorObj?._message) {
      if (errorObj._message.includes('name')) {
        return {
          data: null,
          errors: [{ name: 'name', errors: [errorObj._message] }]
        }
      }
      return { data: null, errors: [{ errors: errorObj?._message }] }
    }
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}
export async function putUpdateSegment(
  value: SegmentFormValue
): Promise<PostCreateSegmentReturn> {
  switch (value?.list?.[0]?.segment_type) {
    case SEGMENT_TYPE.ATTRIBUTE: {
      return putUpdateSegmentATTRIBUTE(value)
    }
    case SEGMENT_TYPE.EVENT: {
      return putUpdateSegmentEVENT(value)
    }
    case SEGMENT_TYPE.MOBILE: {
      return putUpdateSegmentMOBILE(value)
    }
    case SEGMENT_TYPE.EVENT_CUSTOM: {
      return putUpdateSegmentEVENTCUSTOM(value)
    }
    default:
      return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}

export * from './SegmentPageCreate'
export * from './SegmentPageEdit'
