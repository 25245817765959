import {
  Button as BaseButton,
  Menu as BaseMenu,
  ButtonProps,
  MenuProps
} from 'antd'
import styled from 'styled-components'
import { cssMenuDropdown } from 'styles'

export const StyleContainerTable = styled.div`
  display: flex;
  height: 100%;
  min-height: 0;

  .ant-table {
    .ant-table-container {
      .ant-table-thead {
        .has-sort {
          display: flex;
          align-items: center;
          font-weight: inherit;
        }
      }

      .ant-table-tbody {
        td {
          padding: 12px 16px;
          font-size: 14px;
          line-height: 22px;
          color: ${({ theme }) => theme?.colors?.gray700 || ''};
          &:first-child {
            padding-left: 24px;
          }
        }
      }
    }
  }

  &.expand-filter {
    .ant-table {
      .ant-table-header {
        &.ant-table-sticky-holder {
          top: 159px !important;
        }
      }
    }
  }
`

export const StyledWrapperStatus = styled.div`
  display: flex;
  align-items: center;
`

export const StyleWrapperIcon = styled.div`
  flex: 0 0 14px;
  width: 14px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  .one_time {
    svg {
      font-size: 6px;
    }
  }
`

export const StyleStatusText = styled.div``

export const StyleWrapperAction = styled.div``

export const Button = styled(BaseButton)<ButtonProps>`
  color: ${({ theme }) => theme?.colors?.gray600 || ''};
`
export const MenuDropdown = styled(BaseMenu)<MenuProps>`
  ${cssMenuDropdown}
`
export const StyleIconExpand = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme?.colors?.gray100 || ''};
    border-radius: 2px;
  }
`

export const StyledCampaignTableCellName = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  .campaignTable--icon {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }
  .campaignTable--name {
    overflow: hidden;
    flex: 1;
  }
`

export const StyledCampaignTableMoreInfo = styled.div`
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme?.colors?.gray700};
  }
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors?.gray500};
  }
`

export const StyleSegmentName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyleWrapperFilter = styled.div`
  display: flex;
  align-items: center;
`

export const StyledFilterIcon = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  background-color: ${({ theme }) => theme?.colors?.white || ''};
  border-radius: ${({ theme }) => theme?.borderRadiusBase};
  cursor: pointer;
  transition: 0.2s linear all;
  .anticon {
    font-size: 22px;
  }
  &:hover {
    background-color: ${({ theme }) => theme?.colors?.gray100 || ''};
  }
`
