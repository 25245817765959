import { getCampaignMessagingInsightMetricKey } from '../CampaignMessagingInsight'
import {
  CAMPAIGN_INSIGHT_TYPE_OPTIONS,
  CampaignMessagingInsightFilterValue
} from '../CampaignMessagingInsightFilter'
import { Percent, Star, Total } from 'icons'
import { CampaignServices } from 'services/campaign'

export type CampaignMessagingInsightTabOverviewRef = {
  refresh?: (value: CampaignMessagingInsightFilterValue) => void
}

export type CampaignMessagingInsightTabOverviewItem = {
  label: string
  unit?: string
  icon?: AntIconComponent
  description?: string
  key?: string
  value?: Array<{ key: string; label: string; value: number }> | number
}
export const CAMPAIGN_MESSAGING_INSIGHT_OVERVIEW_ITEMS: Record<
  string,
  CampaignMessagingInsightTabOverviewItem
> = {
  // #region AUDIENCE
  total_send: { label: 'Total sent', icon: Total, description: 'Email' },
  total_delivery: { label: 'Total delivered', icon: Total },
  delivery_rate: { label: 'Delivery rate', unit: '%', icon: Percent },
  email_open_rate: { label: 'Email open rate', unit: '%', icon: Percent },
  click_rate: { label: 'Click rate', unit: '%', icon: Percent },
  total_clicks: { label: 'Total clicks', icon: Total },
  total_opens: { label: 'Total opens', icon: Total },
  unique_clicks: { label: 'Unique clicks', icon: Star },
  unique_open_rate: { label: 'Unique open rate', unit: '%', icon: Star },
  unique_opens: { label: 'Unique opens', icon: Star },
  total_bounces: { label: 'Total bounces', icon: Total },
  bounce_rate: { label: 'Bounce rate', unit: '%', icon: Percent },
  total_send_skip: {
    label: 'Total send skip',
    icon: Total,
    description: 'Email, Webpush'
  },
  unique_hosted_unsubscribe_clicks: {
    label: 'Unique hosted unsubscribe clicks',
    icon: Star
  },
  unique_hosted_unsubscribes: { label: 'Unique unsubscribes', icon: Star },
  total_unsubscribes: { label: 'Total unsubscribes', icon: Total },
  unique_email_sends: {
    label: 'Unique email sends',
    icon: Star,
    description: 'Email, Web Push, App Push'
  },
  // #endregion End AUDIENCE
  // #region DELIVERY
  unique_email_delivery: { label: 'Unique emails delivered', icon: Star },
  // #endregion End DELIVERY
  // #region ENGAGEMENT
  click_divide_opens: { label: 'Clicks/Opens', unit: '%', icon: Percent },

  // #endregion End ENGAGEMENT
  // #region RENTATION
  complaint_rate: { label: 'Complaint rate', unit: '%', icon: Percent },
  total_complaints: { label: 'Total complaints', icon: Total },
  total_hosted_unsubscribe_clicks: {
    label: 'Total hosted unsubscribe clicks',
    icon: Total
  },
  unique_rate: { label: 'Unique rate', unit: '%', icon: Star },
  // #endregion End RENTATION
  // #region CONVERSIONS
  conversions_divide_email_hold_outs: {
    label: 'Conversions / email hold outs',
    unit: '%',
    icon: Percent
  },
  conversion_divide_hold_out: {
    label: 'Conversions / hold out',
    unit: '%',
    icon: Percent
  },
  conversion_divide_send_delivered: {
    label: 'Conversions / send delivered',
    unit: '%',
    icon: Percent
  },
  conversion_divide_unique_emails_delivered: {
    label: 'Coversions / unique emails delivered',
    unit: '%',
    icon: Percent
  },
  purchases_divide_m: {
    label: 'Purchases / M',
    unit: '%',
    icon: Percent
  },
  purchases_divide_m_email: {
    label: 'Purchases / M (email)',
    unit: '%',
    icon: Percent
  },
  revenue: {
    label: 'Revenue',
    icon: Total
  },
  revenue_divide_m: {
    label: 'Revenue / M',
    unit: '%',
    icon: Percent
  },
  revenue_divide_m_email: {
    label: 'Revenue / M (email)',
    unit: '%',
    icon: Percent
  },
  total_conversions: { label: 'Total conversions', icon: Total },
  total_purchases: { label: 'Total purchases', icon: Total }
  // #endregion End CONVERSIONS
}

export const formatCampaignMessagingInsightOverviewItems = ({
  data,
  campaign_channels
}: {
  data?: Record<string, number | Record<string, number>>
  campaign_channels?: string
}) => {
  if (!data) {
    return []
  }
  return Object.keys(CAMPAIGN_MESSAGING_INSIGHT_OVERVIEW_ITEMS)
    .map((key) => {
      if (key in data) {
        const value = data[key]
        const config = CAMPAIGN_MESSAGING_INSIGHT_OVERVIEW_ITEMS[key]
        if (!value) {
          return false
        }
        if (typeof value === 'number') {
          return {
            key,
            icon: config.icon,
            label: config.label,
            unit: config?.unit || '',
            value: value
          }
        }

        if (Object.keys(value).length) {
          return {
            key,
            icon: config.icon,
            label: config.label,
            unit: config?.unit || '',
            value: Object.keys(value)
              .map((kV) => {
                const channelConfig = CAMPAIGN_INSIGHT_TYPE_OPTIONS.find(
                  (opt) => opt.value === kV
                )
                if (
                  channelConfig &&
                  (!campaign_channels || campaign_channels === kV)
                ) {
                  return {
                    key: kV,
                    label: channelConfig.label,
                    value: value[kV]
                  }
                }
                return false
              })
              .filter(Boolean)
          }
        }
      }
      return false
    })
    .filter(Boolean) as any[]
}

export const getCampaignMessagingInsightOverview = async (
  params: CampaignMessagingInsightFilterValue
) => {
  try {
    const { date_recorded, campaigns } = params
    if (
      !date_recorded ||
      !date_recorded?.[0].isValid() ||
      !date_recorded?.[1].isValid()
    ) {
      return {
        data: null,
        errors: [
          { name: 'date_recorded', errors: ['Date recorded field is required'] }
        ]
      }
    }

    if (!campaigns?.length) {
      return {
        data: null,
        errors: [
          { name: 'campaigns', errors: ['Campaign name field is required'] }
        ]
      }
    }

    const get_metrics_key = getCampaignMessagingInsightMetricKey(params)
    const { data } = await CampaignServices.insightByFilter({
      from: date_recorded[0].valueOf(),
      to: date_recorded[1].valueOf(),
      list_campaign: campaigns?.map(String) || [],
      get_insights_key: ['overview'],
      get_metrics_key: get_metrics_key.length ? get_metrics_key : null
    })

    return {
      data: formatCampaignMessagingInsightOverviewItems({
        data: data?.overview?.data || {},
        campaign_channels: params.campaign_channels
      }),
      error: []
    }
  } catch (error) {
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}
