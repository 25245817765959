import { Box } from 'components/atoms/box'

const LoginFooter = () => {
  return (
    <Box className="text-center pb-[32px] pt-[16px]">
      <p className="text-[#737373] text-[14px] leading-[22px]">
        Powered by Hellohealth Group
      </p>
      <p className="text-[#737373] text-[12px] leading-[18px] mr-[8px]">
        Hello Health Group Pte. Ltd. 100 Peck Seah Street, #08-14 PS100,
        Singapore 079333
      </p>
    </Box>
  )
}

export default LoginFooter
