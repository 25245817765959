import { Skeleton } from 'antd'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

export const StyledSegmentPredictionHighchartsWrapper = styled.div`
  position: relative;
  overflow: hidden;
  .ant-tooltip-content .ant-tooltip-inner {
    padding: 8px;
  }

  .highcharts__slider-wrapper {
    position: absolute;
    bottom: 47px;
    right: 10px;
    width: 430px;

    .ant-slider {
      margin: 0;
      .ant-slider-rail {
        height: 1px;
      }

      .ant-slider-track {
        height: 2px;
      }

      .ant-slider-handle {
        width: 12px;
        height: 12px;
        border: none;
        margin-top: -5px;
      }

      &:hover {
        .ant-slider-track {
          background-color: ${({ theme }) => theme?.colors?.primary};
        }
      }

      &.ant-slider-disabled {
        .ant-slider-handle {
          background-color: ${({ theme }) => theme?.colors.gray200};
        }
        .ant-slider-track {
          background-color: ${({ theme }) => theme?.colors.gray200} !important;
        }
      }
    }

    &.isResize {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }

  &.custom {
    .highcharts__slider-wrapper {
      .ant-slider {
        .ant-slider-handle {
          margin-top: -6px;
          width: 14px;
          height: 14px;
          border: 1px solid #fff;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            width: 4px;
            height: 4px;
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
          }
        }
      }
    }
  }
`

export const StyledSegmentPredictionItem = styled.div``

export const StyledSegmentPredictionItemWrapper = styled.div`
  ${cssScrollBar}
`

export const StyledSegmentPredictionSkeleton = styled(Skeleton)`
  &.title {
    .ant-skeleton-title {
      margin-bottom: 4px;
    }
    .ant-skeleton-paragraph {
      display: none;
    }
  }
`

export const StyledSegmentPrediction = styled.div``
