import { CAMPAIGN_PATHS } from 'constants/apiPaths'
import { ResponseType } from 'interfaces'
import {
  Campaign,
  CampaignActionParams,
  CampaignQueryParams,
  CampaignSetting,
  InsightByFilterParams,
  InsightExportParams,
  InsightFitlerDataQuery
} from 'interfaces/campaign'
import { CampaignInsightData } from 'pages/campaign/insight/emailInsight/emailInsight.helpers'
import { convertQueryParams, getAsPathApi } from 'utils'
import { sidisBaseAxios } from 'utils/api'

export const CampaignServices = {
  createCampaign: (data: CampaignSetting) => {
    return sidisBaseAxios.post<CampaignSetting, ResponseType<Campaign>>(
      CAMPAIGN_PATHS.campaigns,
      data
    )
  },
  updateCampaign: (id: string | number, data: CampaignSetting) => {
    return sidisBaseAxios.put<CampaignSetting, ResponseType<Campaign>>(
      `${CAMPAIGN_PATHS.campaigns}/${id}`,
      data
    )
  },
  deleteCampaign: (id: string | number) => {
    return sidisBaseAxios.delete<null, ResponseType<null>>(
      `${CAMPAIGN_PATHS.campaigns}/${id}`
    )
  },
  duplicateCampaign: (id: string | number) => {
    return sidisBaseAxios.get<any, ResponseType<Campaign>>(
      `${CAMPAIGN_PATHS.campaigns}/duplicate/${id}`
    )
  },
  restoreCampaign: (id: string | number) => {
    return sidisBaseAxios.post(`${CAMPAIGN_PATHS.campaigns}/${id}`)
  },
  actionCampaign: (id: string | number, data: CampaignActionParams) => {
    return sidisBaseAxios.post<CampaignActionParams, ResponseType<Campaign>>(
      `${CAMPAIGN_PATHS.campaigns}/${id}/action`,
      data
    )
  },
  getById: (id: string | number) => {
    return sidisBaseAxios.get(`${CAMPAIGN_PATHS.campaigns}/${id}`)
  },
  getList: (params: CampaignQueryParams) => {
    const queryString = convertQueryParams(params)
    return sidisBaseAxios.get(`${CAMPAIGN_PATHS.campaigns}?${queryString}`)
  },
  emailInsight: (id: number | string) => {
    return sidisBaseAxios.get<any, ResponseType<CampaignInsightData>>(
      `${CAMPAIGN_PATHS.email_insight}/${id}`
    )
  },
  webpushInsight: (id: number | string) => {
    return sidisBaseAxios.get(
      getAsPathApi(`${CAMPAIGN_PATHS.web_push_insight}`, { id })
    )
  },
  insightFilterData(params: InsightFitlerDataQuery) {
    return sidisBaseAxios.post(CAMPAIGN_PATHS.insight_filter_data, params)
  },
  insightByFilter(params: InsightByFilterParams) {
    return sidisBaseAxios.post(CAMPAIGN_PATHS.insight_by_filter, params)
  },
  insightExport(params: InsightExportParams) {
    return sidisBaseAxios.post(CAMPAIGN_PATHS.insight_export, params, {
      responseType: 'blob'
    })
  }
}
