import { Drawer } from 'antd'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

export const StyledDrawer = styled(Drawer)`
  top: 137px;
  .ant-drawer-content {
    padding: 16px 16px 24px;

    .ant-drawer-header {
      padding: 0 0 10px 0;

      .ant-drawer-header-title {
        .ant-drawer-title {
          color: ${({ theme }) => theme?.colors?.black};
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        .ant-drawer-close {
          order: 1;
          margin: 0;
        }
      }
    }

    .ant-drawer-body {
      padding: 15px 0;
      overflow-y: auto;
      ${cssScrollBar}
    }

    .ant-drawer-footer {
      padding: 10px 0 0 0;
      border-top: none;
    }
  }
`

export const StyleWrapperFooterButton = styled.div`
  display: flex;
  justify-content: space-between;
`
