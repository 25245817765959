import {
  StyledButton,
  StyledContainer,
  StyledResult
} from './SegmentFormComparison.styled'
import Icon from '@ant-design/icons'
import { Col, Form, FormInstance, Row } from 'antd'
import { SelectSegment } from 'components/atoms/select'
import { Comparison, Plus } from 'icons'
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'

export type SegmentFormComparisonRef = FormInstance
export type SegmentFormComparisonProps = {
  segmentId?: number
  disabledComparison?: boolean
}
const SegmentFormComparisonWithoutRef = (
  props: SegmentFormComparisonProps,
  ref?: Ref<SegmentFormComparisonRef>
) => {
  // const { id } = useParams()
  // const [form] = Form.useForm()
  // const [openComparison, setOpenComparison] = useState(false)
  // const [loading, setLoading] = useState(false)
  // const [refresh, setRefresh] = useState(false)
  // const [optionSegment, setOptionSegment] = useState<CheckboxOptionType[]>([])
  // const [comparisonData, setComparisonData] = useState<SegmentCompareItem[]>([])
  // const [queryComparison, setQueryComparision] = useState(false)
  // const [isExcludedEmail, setIsExcludedEmail] = useState(false)
  // const [settingValue, setSettingValue] = useState<any>({
  //   segment: Number(id),
  //   compare_segment: null
  // })
  // const {
  //   segmentDumplicate,
  //   segmentRootName,
  //   segmentCompareName,
  //   segmentRootUser,
  //   segmentCompareUser
  // } = getInforSegmentCompare(comparisonData)

  // const handleOnValuesChange = (_: any, allValues: any) => {
  //   setSettingValue(allValues)
  // }

  // const formatDataVenn = (
  //   data: SegmentCompareItem[],
  //   options: CheckboxOptionType[]
  // ) => {
  //   return data
  //     .map((item: SegmentCompareItem) => {
  //       const result = options.find(
  //         (option) => Number(item.segmentId) === option.value
  //       )
  //       return {
  //         ...item,
  //         name: result ? result.label : ''
  //       }
  //     })
  //     .filter((item) => {
  //       return Number(item.users) > 0
  //     })
  // }

  // const isRunningCompare = (data: SegmentCompareItem[]) => {
  //   const compareData = data.find(
  //     (item: SegmentCompareItem) => item.type === 'mid'
  //   )
  //   return compareData && Number(compareData.users) < 0
  // }

  // const updateDataVenn = useCallback(
  //   (data: any) => {
  //     const formatData = formatDataVenn(
  //       data,
  //       optionSegment
  //     ) as SegmentCompareItem[]
  //     setComparisonData(formatData)
  //   },
  //   [optionSegment]
  // )

  // const handleRunComparison = async () => {
  //   const values = await form.validateFields()
  //   try {
  //     const res = await segmentConditonBuilderServices.runCompareSegment(
  //       values.segment,
  //       values.compare_segment
  //     )
  //     if (!res.data.data) {
  //       throw new Error('')
  //     }
  //     if (isRunningCompare(res.data.data)) {
  //       setQueryComparision(true)
  //       setLoading(true)
  //     } else {
  //       updateDataVenn(res.data.data)
  //       setLoading(false)
  //     }
  //   } catch (error) {
  //     notification.error({
  //       message: 'Something went wrong. Please try again!'
  //     })
  //     setLoading(false)
  //   }
  // }
  // const fetchSegment = useCallback(async () => {
  //   if (!id) return
  //   setLoading(true)
  //   try {
  //     const allPromises = [
  //       segmentServices.getSegmentList({
  //         page: default_current_page,
  //         per_page: default_page_size_all
  //       }),
  //       segmentConditonBuilderServices.getCompareSegment(id)
  //     ]
  //     const [resSegmentList, resCompareSegment] = await Promise.all(allPromises)
  //     if (!resSegmentList.data.data) {
  //       throw new Error('')
  //     }
  //     const options = resSegmentList.data.data.map((segment: any) => {
  //       return {
  //         label: segment.name,
  //         value: segment.id,
  //         disabled: segment.id === Number(id)
  //       }
  //     })
  //     setOptionSegment(options)
  //     if (resCompareSegment.data) {
  //       const formatData = formatDataVenn(
  //         resCompareSegment.data.data,
  //         options
  //       ) as SegmentCompareItem[]
  //       const result = resCompareSegment.data.data.find(
  //         (item: SegmentCompareItem) => item.type === 'right'
  //       )
  //       if (result) {
  //         setSettingValue({
  //           segment: Number(id),
  //           compare_segment: Number(result.segmentId[0])
  //         })
  //       }
  //       setComparisonData(formatData)
  //       setOpenComparison(true)
  //     }
  //     setLoading(false)
  //   } catch (error) {
  //     setLoading(false)
  //   }
  // }, [id])

  // const compareResult = useMemo(() => {
  //   const array = [] as any[]
  //   orderVennData.forEach((key) => {
  //     const result = comparisonData.find((item) => item.type === key)
  //     if (result && key !== 'mid') {
  //       const excludeDumplicateUser = Number(result.users) - segmentDumplicate
  //       const data = {
  //         segmentRoot: result.name,
  //         segmentCompare: key === 'left' ? segmentCompareName : segmentRootName,
  //         type: result.type,
  //         users: excludeDumplicateUser,
  //         percentSegmentRoot: (
  //           (excludeDumplicateUser / Number(result.users)) *
  //           100
  //         ).toFixed(2),
  //         percentSegmentCompare: 0,
  //         segmentRootId: Number(result.segmentId[0]),
  //         segmentCompareId: key === 'left' ? settingValue.compare_segment : id,
  //         isCreateSubSegment: result.isCreateSubSegment,
  //         isExcludeEmail: result.isExcludeEmail
  //       }
  //       array.push(data)
  //     }
  //     if (result && key === 'mid') {
  //       const data = {
  //         segmentRoot: segmentRootName,
  //         segmentCompare: segmentCompareName,
  //         type: result.type,
  //         users: Number(result.users),
  //         percentSegmentRoot: segmentRootUser
  //           ? ((Number(result.users) / segmentRootUser) * 100).toFixed(2)
  //           : 0,
  //         percentSegmentCompare: segmentCompareUser
  //           ? ((Number(result.users) / segmentCompareUser) * 100).toFixed(2)
  //           : 0
  //       }
  //       array.push(data)
  //     }
  //   })
  //   return array
  // }, [
  //   comparisonData,
  //   segmentDumplicate,
  //   segmentRootName,
  //   segmentCompareName,
  //   segmentRootUser,
  //   segmentCompareUser,
  //   settingValue,
  //   id
  // ])
  // useEffect(() => {
  //   fetchSegment()
  // }, [fetchSegment])

  // useEffect(() => {
  //   if (refresh) {
  //     fetchSegment()
  //     setRefresh(false)
  //   }
  // }, [refresh, fetchSegment])

  // useEffect(() => {
  //   form.setFieldsValue(settingValue)
  // }, [settingValue, form])

  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     if (id && (queryComparison || isExcludedEmail)) {
  //       const res = await segmentConditonBuilderServices.getCompareSegment(id)
  //       if (!res.data) {
  //         return
  //       }

  //       if (!isRunningCompare(res.data.data)) {
  //         setQueryComparision(false)
  //         setIsExcludedEmail(false)
  //         setLoading(false)
  //         updateDataVenn(res.data.data)
  //         clearInterval(interval)
  //       }
  //     } else {
  //       setQueryComparision(false)
  //       setIsExcludedEmail(false)
  //       setLoading(false)
  //       clearInterval(interval)
  //     }
  //   }, 3000)
  //   return () => clearInterval(interval)
  // }, [id, queryComparison, isExcludedEmail, updateDataVenn])

  // return (
  //   <>
  //     <Loading spinning={loading}>
  //       {!openComparison && (
  //         <StyleContainer>
  //           <Comparison />
  //           <StyleDescription>
  //             To create a new segment from overlap segments, simply add at least
  //             two segments to start the comparison.
  //           </StyleDescription>
  //           <Button
  //             icon={<Icon component={Plus} />}
  //             size="small"
  //             disabled={!id}
  //             onClick={() => setOpenComparison(true)}>
  //             Add Comparison
  //           </Button>
  //         </StyleContainer>
  //       )}

  //       <StyledFormContainer height={openComparison ? 'auto' : 0}>
  //         <StyleForm
  //           form={form}
  //           layout="horizontal"
  //           disabled={comparisonData.length > 0}
  //           onValuesChange={handleOnValuesChange}
  //           initialValues={settingValue}>
  //           <StyleRow>
  //             <Col span={12}>
  //               <Form.Item
  //                 label="Segment"
  //                 name="segment"
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: 'This field is require.'
  //                   }
  //                 ]}>
  //                 <Select
  //                   options={optionSegment}
  //                   placeholder="Segment"
  //                   size="small"
  //                   disabled={true}
  //                 />
  //               </Form.Item>
  //             </Col>
  //             <Col span={12}>
  //               <Form.Item
  //                 label="Compare Segment"
  //                 name="compare_segment"
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: 'This field is require.'
  //                   }
  //                 ]}>
  //                 <Select
  //                   options={optionSegment}
  //                   placeholder="Compare Segment"
  //                   showSearch
  //                   optionFilterProp="label"
  //                   size="small"
  //                 />
  //               </Form.Item>
  //             </Col>
  //           </StyleRow>
  //           <Button type="ghost" size="small" onClick={handleRunComparison}>
  //             Run Comparison
  //           </Button>
  //         </StyleForm>
  //         <ComparisonChart
  //           comparisonData={comparisonData}
  //           segmentRootName={segmentRootName}
  //           segmentCompareName={segmentCompareName}
  //         />
  //         <StyleListResult>
  //           {compareResult.map((data: any, index: number) => (
  //             <ComparisonResult
  //               key={index}
  //               data={data}
  //               setRefresh={setRefresh}
  //               setIsExcludedEmail={setIsExcludedEmail}
  //               setLoading={setLoading}
  //             />
  //           ))}
  //         </StyleListResult>
  //       </StyledFormContainer>
  //     </Loading>
  //   </>
  // )

  const { segmentId, disabledComparison } = props
  const [form] = Form.useForm()
  const [open, setOpen] = useState(false)

  const disabledOpen = useMemo(
    () => !Boolean(segmentId) || !!disabledComparison,
    [segmentId]
  )

  useEffect(() => {
    form.setFieldsValue({ segmentId })
    setOpen(false)
  }, [segmentId])

  useImperativeHandle(ref, () => form, [form])

  return (
    <StyledContainer>
      <Form form={form} layout="horizontal">
        <Form.Item shouldUpdate={open} noStyle>
          {!open && (
            <StyledResult
              icon={<Icon component={Comparison} />}
              subTitle="To create a new segment from overlap segments, simply add at least two segments to start the comparison."
              extra={[
                <StyledButton
                  key="add"
                  size="small"
                  className="add_button"
                  disabled={disabledOpen}
                  onClick={() => setOpen(true)}
                  icon={<Icon component={Plus} />}>
                  Add Comparison
                </StyledButton>
              ]}
            />
          )}
          {!!open && (
            <>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    label="Segment"
                    name="segmentId"
                    rules={[
                      { required: true, message: 'This field is required' }
                    ]}
                    required>
                    <SelectSegment placeholder="Segment" size="small" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Compare Segment"
                    name="compareId"
                    rules={[
                      { required: true, message: 'This field is required' }
                    ]}
                    required>
                    <SelectSegment placeholder="Compare Segment" size="small" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <StyledButton
                    size="small"
                    htmlType="submit"
                    className="run_button">
                    Run Comparison
                  </StyledButton>
                </Col>
              </Row>
            </>
          )}
        </Form.Item>
      </Form>
    </StyledContainer>
  )
}

export const SegmentFormComparison = forwardRef(SegmentFormComparisonWithoutRef)
