import { StyledActions, StyledWrap } from './index.styled'
import { Iphone, ScreenEmpty } from 'icons'
import { TemplateBuilderPreviewIframe } from 'pages/templateBuilderCreate/TemplateBuilderPreview'
import { useState } from 'react'

export type EmailPreview = {
  htmlTemplate: string
}

type Props = {
  preview: EmailPreview
}

export function PreviewEmail({ preview }: Props) {
  const [viewMode, setViewMode] = useState<'mobile' | 'desktop'>('mobile')

  return (
    <>
      <StyledWrap>
        <TemplateBuilderPreviewIframe
          isMobile={viewMode === 'mobile'}
          htmlTemplate={preview.htmlTemplate}
        />
      </StyledWrap>
      <StyledActions>
        <li
          onClick={() => setViewMode('desktop')}
          className={viewMode === 'desktop' ? 'active' : ''}>
          <ScreenEmpty />
        </li>
        <li
          onClick={() => setViewMode('mobile')}
          className={viewMode === 'mobile' ? 'active' : ''}>
          <Iphone />
        </li>
      </StyledActions>
    </>
  )
}
