import { Button, Result } from 'antd'
import styled from 'styled-components'

// export const StyleContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   height: 100%;
// `

// export const StyleDescription = styled.div`
//   line-height: 22px;
//   color: ${(props) => props.theme?.colors?.gray600};
//   text-align: center;
//   margin: 15px 0;
// `

// export const StyleForm = styled(Form)`
//   .ant-form-item {
//     flex: 1;
//     &.btn-comparison {
//       margin-right: 0;
//       .ant-form-item-label {
//         visibility: hidden;
//       }
//     }
//     .ant-form-item-label {
//       padding-bottom: 0;
//     }
//   }
// `

// export const StyledFormContainer = styled.div<{ height?: number | string }>`
//   height: ${({ height }) => height};
//   overflow: hidden;
// `

// export const StyleListResult = styled.div`
//   display: flex;
//   gap: 20px;
//   overflow-x: auto;
// `

// export const StyleRow = styled(Row)`
//   gap: 20px;
//   .ant-col-12 {
//     flex: 0 0 calc(50% - 10px);
//     max-width: calc(50% - 10px);
//   }
// `

export const StyledButton = styled(Button)`
  &.add_button {
    padding: 4px 24px;
  }
  &.run_button {
    padding: 4px 24px;
  }
`

export const StyledResult = styled(Result)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 100px 0;
`

export const StyledContainer = styled.div`
  height: 100%;
  .ant-form {
    height: 100%;
  }
`
