import { Switch } from 'antd'
import cls from 'classnames'
import { Box } from 'components/atoms/box'

type Props = {
  value?: boolean
  onChange?: (newValue: boolean) => void
  disabled?: boolean
}

export const CampaignStepFrequencyBypass = ({
  value,
  onChange,
  disabled
}: Props) => {
  return (
    <Box
      className={cls('rounded-[4px] p-4 flex', {
        'bg-[#E3F2FF]': !disabled,
        'bg-[#F7F9FC] cursor-not-allowed': disabled
      })}>
      <Box className="w-[48px]">
        <Switch
          size="small"
          checked={value}
          onChange={onChange}
          disabled={disabled}
        />
      </Box>
      <Box className="flex-1">
        <p className="text-[#262626] text-sm leading-[22px] m-0">
          Bypass Channel Frequency Capping
        </p>
        <p className="text-[#595959] text-sm leading-[22px] m-0 pt-[4px]">
          Set your campaign to ignore the number of times that users can get
          your message specified in the Channel Frequency Capping Settings
        </p>
      </Box>
    </Box>
  )
}
