import { StyledTabsTemplate } from './index.styled'
import { Button, Form, Input, Select } from 'antd'

const optionsTemplate = [
  { value: 'Segment', label: 'Segment' },
  { value: 'Email', label: 'Email' },
  { value: 'Marketing', label: 'Marketing' }
]
const optionsPurpose = [
  {
    value: 'Suggest potential segmentation',
    label: 'Suggest potential segmentation'
  },
  {
    value: 'Review selected segmentation',
    label: 'Review selected segmentation'
  },
  {
    value: `Suggest potential segmentation base on client's profile`,
    label: `Suggest potential segmentation base on client's profile`
  }
]

function CopilotTabsTemplate() {
  return (
    <StyledTabsTemplate>
      <Form layout="vertical">
        <Form.Item required label="Template">
          <Select
            size="small"
            options={optionsTemplate}
            placeholder="- Select -"
          />
        </Form.Item>
        <Form.Item required label="Purpose">
          <Select
            size="small"
            options={optionsPurpose}
            placeholder="- Select -"
          />
        </Form.Item>
        <Form.Item required label="Company or Product name">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="small" block>
            Generate text
          </Button>
        </Form.Item>
      </Form>
    </StyledTabsTemplate>
  )
}

export default CopilotTabsTemplate
