import { useAuth } from 'context/Auth'

export type UseCheckAuthProps = {
  sites?: string[]
  roles?: string[]
  permissions?: string[]
}
export function useCheckAuth(compare: UseCheckAuthProps) {
  const { userAuth } = useAuth() || {}
  if (!userAuth) {
    return false
  }
  const { auth } = userAuth
  return checkAuth(compare, auth)
}

// use in react-hook
export function checkAuth(
  compare: UseCheckAuthProps,
  authActive: Partial<UserAuth['auth']>
) {
  const { sites, roles, permissions } = compare
  const { sitesActive, rolesActive, permissionsActive } = authActive

  // if not a user
  // return false
  if (
    !sitesActive ||
    !sitesActive?.length ||
    !rolesActive ||
    !rolesActive?.length ||
    !permissionsActive ||
    !permissionsActive?.length
  ) {
    return false
  }

  // if all 3 params = undefined
  // return true
  if (!sites && !roles && !permissions) {
    return true
  }

  // always true when undefined
  let siteChecked = true,
    roleChecked = true,
    permissionChecked = true

  if (typeof sites !== 'undefined') {
    siteChecked = sites.some((siteKey) => sitesActive.includes(siteKey))
  }

  if (typeof roles !== 'undefined') {
    roleChecked = roles.some((role) => rolesActive.includes(role))
  }

  if (typeof permissions !== 'undefined') {
    permissionChecked = permissions.some((permission) =>
      permissionsActive.includes(permission)
    )
  }

  return siteChecked && permissionChecked && roleChecked
}
