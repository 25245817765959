import { Button, ButtonProps, Dropdown, Skeleton } from 'antd'
import { ModalEmpty } from 'components/atoms/modal'
import styled from 'styled-components'
import { cssPadding, cssTitle } from 'styles'

export const StyledButton = styled(Button)<ButtonProps>`
  font-weight: 600;
  font-size: 14px;
  padding: 4px 12px;

  &.btn_back {
    display: inline-block;
    padding: 0;
    width: 24px;
    height: 24px;
    font-size: 24px;
    > .anticon {
      font-size: 24px;
      > * {
        line-height: 0;
      }
    }
  }
`

export const StyledTitle = styled.h2`
  ${cssTitle}
`

export const StyledDropdown = styled(Dropdown)`
  .ant-dropdown {
    .ant-dropdown-menu {
      padding: 0;
      min-width: 260px;
      .ant-dropdown-menu-item {
        padding: 8px 16px;
        &:first-child {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
        &:last-child {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
`

export const StyledHeaderSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    .ant-skeleton-title {
      margin-bottom: 0;
    }
    .ant-skeleton-paragraph {
      display: none;
    }
  }
`

export const StyledHeaderButtons = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  min-width: 20%;
`

export const StyledHeaderSteps = styled.div`
  flex: 1;
  min-width: 0;
  max-width: 60%;

  .ant-steps {
    &.ant-steps-small {
      .ant-steps-item-icon {
        width: 20px;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        .anticon {
          font-size: 12px;
          line-height: 0;
          vertical-align: -0.1em;
        }
      }
    }

    .ant-steps-item-icon,
    .ant-steps-item-content {
      vertical-align: middle;
    }

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background-color: ${({ theme }) => theme?.colors?.primary};
        > .ant-steps-icon {
          color: #fff;
        }
      }

      &:not(.ant-steps-item-active) {
        > .ant-steps-item-container[role='button'] {
          &:hover {
            .ant-steps-item-icon {
              > .ant-steps-icon {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .ant-steps-item-process {
      > .ant-steps-item-container {
        > .ant-steps-item-icon {
          background-color: #fff;
          > .ant-steps-icon {
            color: ${({ theme }) => theme?.colors?.primary};
          }
        }
        > .ant-steps-item-content {
          > .ant-steps-item-title {
            color: ${({ theme }) => theme?.colors?.primary};
          }
        }
      }
    }
  }
`

export const StyledHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  min-width: 20%;
  ${StyledTitle} {
    flex: 1;
    min-width: 0;
    margin-left: 4px;
  }
`

export const StyledHeader = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme?.colors?.white};
  position: sticky;
  top: 65px;
  z-index: 2;
  ${cssPadding}
`

export const StyledModal = styled(ModalEmpty)``
