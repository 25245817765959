import cn from 'classnames'
import { TEMPLATE_TYPE } from 'constants/template'
import { theme } from 'constants/theme'
import { FC, useMemo } from 'react'
import styled from 'styled-components'
import { formatNumberString, formatNumberToCurrency } from 'utils'

const StyledProgressBarItem = styled.div<{
  color?: string
  zIndex?: number
  width?: number
}>`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};
  top: 0;
  left: 0;
  width: ${({ width }) => width}%;
  max-width: 100%;
  border-bottom: 4px solid;
  border-color: ${({ color }) => color};
`
const StyledProgressBar = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 4px solid;
  border-color: ${({ theme }) => theme?.colors?.neutral200};
`
const StyledLegendItem = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme?.colors?.gray600};
  &:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    margin-right: 4px;
  }

  > strong {
    color: ${({ theme }) => theme?.colors?.gray800};
    margin-right: 4px;
  }

  & + & {
    margin-left: 16px;
  }
`
const StyledLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
`
const StyledWrapper = styled.div`
  flex: 1;
  min-width: 0;
`
const StyledNumberTotal = styled.div`
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 14px;
  font-weight: 600;
  width: 80px;
  padding-right: 8px;
`
const StyledContainer = styled.div`
  display: flex;
`

type CampaignPerfomanceOptions = {
  label?: string
  isNumber?: boolean
  color?: string
  value: number
}

const CAMPAIGN_OPTIONS_COLOR = [
  theme?.colors?.blue600,
  theme?.colors?.green600,
  theme?.colors?.yellow600
]

export type CampaignPerformanceProps = {
  total: CampaignPerfomanceOptions
  options: CampaignPerfomanceOptions[]
  hasTotal?: boolean
  className?: string
  itemClassName?: string
}

export function getCampaignPerformanceProps(
  record: any
): CampaignPerformanceProps {
  const result: CampaignPerformanceProps = {
    total: { value: record?.total_send || 0 },
    options: [
      { label: 'Impression', value: record?.email_open_rate || 0 },
      { label: 'Click', value: record?.email_click_rate || 0 },
      { label: 'Delivery', value: record?.email_delivery_rate || 0 }
    ]
  }
  if (record?.template_type === TEMPLATE_TYPE.EMAIL) {
    result.options = [
      { label: 'Click', value: record?.email_click_rate || 0 },
      { label: 'Open', value: record?.email_open_rate || 0 },
      { label: 'Delivery', value: record?.email_delivery_rate || 0 }
    ]
  }
  if (record?.template_type === TEMPLATE_TYPE.APP_PUSH) {
    result.options = [
      { label: 'Click', value: record?.app_push_click_rate || 0 },
      { label: 'Impression', value: record?.app_push_impression_rate || 0 },
      { label: 'Delivery', value: record?.app_push_delivery_rate || 0 }
    ]
  }
  if (record?.template_type === TEMPLATE_TYPE.WEB_PUSH) {
    result.options = [
      { label: 'Click', value: record?.web_push_click_rate || 0 },
      { label: 'Impression', value: record?.web_push_impression_rate || 0 },
      { label: 'Delivery', value: record?.web_push_delivery_rate || 0 }
    ]
  }
  if (record?.template_type === TEMPLATE_TYPE.WHATSAPP) {
    result.options = [
      { label: 'Read', value: record?.whatsapp_read_rate || 0 },
      { label: 'Delivery', value: record?.whatsapp_delivered_rate || 0 }
    ]
  }

  // With another label so use this for CampaignPerformance
  return result
}
export const CampaignPerformance: FC<CampaignPerformanceProps> = (props) => {
  const { total, options, hasTotal, className, itemClassName } = props

  const legendComp = useMemo(() => {
    const optionsComp = options.map((option, index) => {
      const color =
        CAMPAIGN_OPTIONS_COLOR[index % CAMPAIGN_OPTIONS_COLOR.length]
      return (
        <StyledLegendItem
          color={color}
          key={`${index}`}
          className={cn('perf--legend_item', itemClassName, {
            'perf--legend_item_number': option.isNumber
          })}>
          <strong>{option.isNumber ? option.value : `${option.value}%`}</strong>
          <span>{option.label}</span>
        </StyledLegendItem>
      )
    })

    if (!!hasTotal) {
      optionsComp.unshift(
        <StyledLegendItem
          key={`${optionsComp.length}`}
          className={cn(
            'perf--legend_item',
            itemClassName,
            'perf--legend_item_number'
          )}>
          <strong>{formatNumberToCurrency(total.value)}</strong>
          <span>Total sent</span>
        </StyledLegendItem>
      )
    }
    return optionsComp
  }, [hasTotal, itemClassName, options, total.value])

  const progressItemComp = useMemo(() => {
    return options.map((option, index) => {
      const color =
        CAMPAIGN_OPTIONS_COLOR[index % CAMPAIGN_OPTIONS_COLOR.length]

      return (
        <StyledProgressBarItem
          color={color}
          width={option.value}
          zIndex={options.length - index}
          key={`${index}`}
        />
      )
    })
  }, [options])

  return (
    <StyledContainer className={className}>
      <StyledNumberTotal className="perf--total">
        {formatNumberString(total.value)}
      </StyledNumberTotal>
      <StyledWrapper className="perf--wrapper">
        <StyledLegend className="perf--legend">{legendComp}</StyledLegend>
        <StyledProgressBar className="perf--progress_bar">
          {progressItemComp}
        </StyledProgressBar>
      </StyledWrapper>
    </StyledContainer>
  )
}
