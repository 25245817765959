import { Switch, SwitchProps } from 'antd'
import cn from 'classnames'
import { ReactNode, Ref, forwardRef } from 'react'
import styled from 'styled-components'

const StyledSwitch = styled(Switch)`
  min-width: 30px;
  height: 18px;
  .ant-switch-handle {
    width: 12px;
    height: 12px;
    top: 3px;
  }
  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 16px);
  }
`

const StyledSwitchContainer = styled.div`
  min-width: 40px;
  &.isShowSwitch {
    min-width: auto;
  }
`

const StyledTitle = styled.h5`
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.2px;
`

const StyledDescription = styled.div`
  margin-top: 4px;
  font-size: 14px;
  color: ${({ theme }) => theme?.colors.gray600};
  font-weight: 400;
  letter-spacing: -0.2px;
  a {
    text-decoration: underline;
  }
`

const StyledChildren = styled.div``

const StyledWrapper = styled.div`
  flex: 1;
  min-width: 0;
  padding-left: 8px;
`

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.blue50};
  &.disabled {
    background-color: ${({ theme }) => theme.colors?.neutral50};
    cursor: not-allowed;
  }
  &.checked {
    background-color: ${({ theme }) => theme?.colors?.blue50};
  }
`

export type CampaignStepSwitchDetailRef = HTMLDivElement
export type CampaignStepSwitchDetailProps = {
  isShowSwitch?: boolean
  disabled?: boolean
  title?: string
  description?: ReactNode
  children?: ReactNode
} & Pick<SwitchProps, 'checked' | 'onChange'>

const CampaignStepSwitchWithoutRef = (
  props: CampaignStepSwitchDetailProps,
  ref?: Ref<CampaignStepSwitchDetailRef>
) => {
  const {
    isShowSwitch = true,
    disabled,
    title,
    description,
    children,
    checked,
    onChange
  } = props

  return (
    <StyledContainer ref={ref} className={cn({ disabled, checked })}>
      <StyledSwitchContainer className={cn({ isShowSwitch })}>
        {isShowSwitch && (
          <StyledSwitch
            disabled={disabled}
            checked={checked}
            onChange={onChange}
          />
        )}
      </StyledSwitchContainer>
      <StyledWrapper>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
        <StyledChildren>{children}</StyledChildren>
      </StyledWrapper>
    </StyledContainer>
  )
}
export const CampaignStepSwitch = forwardRef(CampaignStepSwitchWithoutRef)
