import Icon from '@ant-design/icons'
import cn from 'classnames'
import { theme } from 'constants/theme'
import { AlertSuccess, Draft } from 'icons'
import { CSSProperties, FC, useMemo } from 'react'
import styled from 'styled-components'

export const StyledBadgeText = styled.span`
  display: inline-block;
  margin-left: 8px;
`

export const SEGMENT_STATUS_KEYS = {
  DRAFT: 1,
  PUBLIC: 2
}

const STATUS_MAP_KEYS = {
  [SEGMENT_STATUS_KEYS.DRAFT]: 'draft',
  [SEGMENT_STATUS_KEYS.PUBLIC]: 'publish'
}

const SEGMENT_STATUS_CONFIGS = {
  [STATUS_MAP_KEYS[1]]: {
    label: 'Ready',
    color: theme.colors.red500,
    icon: Draft
  },
  [STATUS_MAP_KEYS[2]]: {
    label: 'Publish',
    color: theme.colors.green500,
    icon: AlertSuccess
  }
}

export type SegmentStatusProps = {
  status?: string | number
  className?: string
  style?: CSSProperties
}

export const SegmentStatus: FC<SegmentStatusProps> = ({
  status,
  className,
  style
}) => {
  const statusConfig = useMemo(() => {
    if (!status) {
      return
    }
    if (status in STATUS_MAP_KEYS) {
      return SEGMENT_STATUS_CONFIGS[
        STATUS_MAP_KEYS[status as keyof typeof STATUS_MAP_KEYS]
      ]
    }
  }, [status])

  if (!statusConfig) {
    return null
  }

  return (
    <div className={cn('flex items-center', className)} style={style}>
      <Icon component={statusConfig.icon} className="text-[16px] mr-2" />
      <div>{statusConfig.label}</div>
    </div>
  )
}
