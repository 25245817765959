import { CAMPAIGN_TEMPLATE } from '.'
import { CAMPAIGN_STEP_KEYS } from 'components/molecules/campaign/CampaignForm/CampaignStepHeader'
import { CampaignPageCreate } from 'components/pages'
import { useSessionStorage } from 'hooks/useSessionStorage'
import Page404 from 'pages/404'
import { useEffect } from 'react'
import { useParams } from 'react-router'

export const CampaignTemplateCreate = () => {
  const { template_type } = useParams()
  const [, setStep] = useSessionStorage(
    '$campaignStep',
    CAMPAIGN_STEP_KEYS.overviews
  )

  useEffect(() => {
    setStep(CAMPAIGN_STEP_KEYS.overviews)
  }, [setStep])

  if (!CAMPAIGN_TEMPLATE.includes(template_type as string)) {
    return <Page404 />
  }
  return <CampaignPageCreate template_type={template_type} />
}
