import dayjs from 'dayjs'

export type NotificationItem = {
  id: number
  body: string
  link: string
  status: string
  title: string
  time: number
  data?: {
    flow_id: string | number
    flow_type: string // approve_campaign
    status: string
    notification_id: number
  }
}

export type NotificationContext = {
  notifications: NotificationItem[]
  isShow: boolean
  countNewNoti: number
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const calculateFromTo = (filterDate: string) => {
  const today = dayjs()
  const yesterday = today.subtract(1, 'day')

  if (filterDate === 'today') {
    return {
      fromInSeconds: yesterday.toDate().getTime() / 1000,
      toInSeconds: today.toDate().getTime() / 1000
    }
  }
  if (filterDate === 'yesterday') {
    return {
      fromInSeconds: today.subtract(2, 'day').toDate().getTime() / 1000,
      toInSeconds: yesterday.toDate().getTime() / 1000
    }
  }
  if (filterDate === 'this-week') {
    return {
      fromInSeconds: today.subtract(1, 'week').toDate().getTime() / 1000,
      toInSeconds: today.toDate().getTime() / 1000
    }
  }
  if (filterDate === 'this-month') {
    return {
      fromInSeconds: today.subtract(1, 'month').toDate().getTime() / 1000,
      toInSeconds: today.toDate().getTime() / 1000
    }
  }
  return {}
}
