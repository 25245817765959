import { ATTRIBUTE_FIELD_PATHS, EVENT_FIELD_PATHS } from 'constants/apiPaths'
import { SEGMENT_PATHS } from 'constants/apiPaths'
import { ResponseType } from 'interfaces'
import { EventCreateParams } from 'interfaces/dictionary'
import { convertQueryParams, getAsPathApi } from 'utils'
import { sidisBaseAxios } from 'utils/api'
import { segmentBuilderBaseAxios } from 'utils/api'

export const FieldEventServices = {
  createEventField: (data: any) => {
    return sidisBaseAxios.post<EventCreateParams, ResponseType<any>>(
      EVENT_FIELD_PATHS.field,
      data
    )
  },
  getListFields: ({
    event_id = '',
    page = 1,
    per_page = 1000000,
    search = '',
    sort_type = '',
    sort_key = '',
    filter_data_type = [],
    category = '',
    site = ''
  } = {}) => {
    const newParams = {
      event_id,
      page,
      per_page,
      search,
      sort_type,
      sort_key,
      filter_data_type,
      category,
      site
    }
    Object.keys(newParams).forEach((key) => {
      const value = newParams?.[key as keyof typeof newParams]
      if (
        typeof value === 'undefined' ||
        (typeof value === 'string' && value === '') ||
        (Array.isArray(value) && !value.length)
      ) {
        delete newParams[key as keyof typeof newParams]
      }
    })
    const queryString = convertQueryParams(newParams)
    return sidisBaseAxios.get(`${EVENT_FIELD_PATHS.field}?${queryString}`)
  },
  updateField: (data: any, id: string) => {
    return sidisBaseAxios.put<EventCreateParams, ResponseType<any>>(
      `${EVENT_FIELD_PATHS.field}/${id}`,
      data
    )
  },
  deleteField: (id: string) => {
    return sidisBaseAxios.delete<any, ResponseType<any>>(
      `${EVENT_FIELD_PATHS.field}/${id}`
    )
  }
}

export const FieldAttributeServices = {
  getListFields: (data: any) => {
    return segmentBuilderBaseAxios.post(
      SEGMENT_PATHS.condition_base_builder,
      data
    )
  },
  getFieldsById: (id: string) => {
    return segmentBuilderBaseAxios.get(
      getAsPathApi(ATTRIBUTE_FIELD_PATHS.detail_field, { id })
    )
  },
  updateField: (data: any, id: string) => {
    return segmentBuilderBaseAxios.put(
      `${ATTRIBUTE_FIELD_PATHS.field}/${id}/update`,
      data
    )
  }
}
