import { sort } from 'constants/common'

export const menuItemsSort = [
  {
    label: 'A-Z',
    type: undefined,
    key: sort.ascend
  },
  {
    label: 'Z-A',
    type: undefined,
    key: sort.descend
  }
]
