import { CachedSystem } from './cachedSystem'
import { TemplateBuilderListParams } from 'interfaces/template'
import { AppPushSettingsParams } from 'pages/templateBuilderCreate/appPush/helpers'
import { EmailSettingsParams } from 'pages/templateBuilderCreate/emailTemplate/helpers'
import { TemplateSettingsParams } from 'pages/templateBuilderCreate/landingPage/helpers'
import { TwilioSettingsParams } from 'pages/templateBuilderCreate/twilio/helpers'
import { WebPushSettingsParams } from 'pages/templateBuilderCreate/webPush/helpers'
import { FormWhatsAppPayload } from 'pages/templateBuilderCreate/whatsApp/types'
import { ZaloSettingsParams } from 'pages/templateBuilderCreate/zalo/types'
import { convertQueryParams } from 'utils'
import { templateBaseAxios } from 'utils/api'

const PREFIX = 'TemplateServices/'

export const TemplateServices = {
  getListClients: () => {
    return CachedSystem.withCached('TemplateClients', () => {
      return templateBaseAxios.get('/api/templates/clients')
    })
  },
  deleteTemplate: (id: string | number) => {
    CachedSystem.clearCached(PREFIX)
    return templateBaseAxios.delete('/api/templates/' + id)
  },
  createTemplate: (
    data:
      | TemplateSettingsParams
      | EmailSettingsParams
      | AppPushSettingsParams
      | WebPushSettingsParams
      | TwilioSettingsParams
      | ZaloSettingsParams
  ) => {
    CachedSystem.clearCached(PREFIX)
    return templateBaseAxios.post('/api/templates/', data)
  },
  createWhatsAppTemplate: (data: FormWhatsAppPayload) => {
    CachedSystem.clearCached(PREFIX)
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      const value = data[key as keyof FormWhatsAppPayload]

      if (value instanceof Array && key === 'buttons') {
        formData.append(key, JSON.stringify(value))
        return
      }

      if (value instanceof Array) {
        value.forEach((valueItem) => {
          formData.append(key, valueItem)
        })
        return
      }

      formData.append(key, value as any)
    })
    return templateBaseAxios.post(
      '/api/templates/create-whatsapp-template',
      formData
    )
  },
  updateWhatsAppTemplate: (data: FormWhatsAppPayload, id: number) => {
    CachedSystem.clearCached(PREFIX)
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      const value = data[key as keyof FormWhatsAppPayload]

      if (value instanceof Array && key === 'buttons') {
        formData.append(key, JSON.stringify(value))
        return
      }

      if (value instanceof Array) {
        value.forEach((valueItem) => {
          formData.append(key, valueItem)
        })
        return
      }

      formData.append(key, value as any)
    })
    return templateBaseAxios.post(
      '/api/templates/update-whatsapp-template/' + id,
      formData
    )
  },
  updateTemplate: (
    settingId: number,
    data:
      | TemplateSettingsParams
      | EmailSettingsParams
      | AppPushSettingsParams
      | WebPushSettingsParams
      | ZaloSettingsParams
  ) => {
    CachedSystem.clearCached(PREFIX)
    return templateBaseAxios.put(`/api/templates/${settingId}`, data)
  },
  getById: (id: string | number) => {
    return templateBaseAxios.get(`/api/templates/${id}`)
  },
  getList: (params: TemplateBuilderListParams) => {
    const newParams = { ...params }
    Object.keys(newParams).forEach((k) => {
      const key = k as keyof typeof newParams
      const value = newParams[key]
      if (!value || (Array.isArray(value) && !value.length)) {
        delete newParams[key]
      }
    })
    const keyCached =
      PREFIX + '/api/templates/?' + convertQueryParams(newParams)
    return CachedSystem.withCached(keyCached, () => {
      return templateBaseAxios.get(
        `/api/templates/?${convertQueryParams(newParams)}`
      )
    })
  },
  getListEmailBuilder: ({
    currentPage = 1,
    pageSize = 10,
    type = 'all',
    search = '',
    site = ''
  } = {}) => {
    return templateBaseAxios.get('/api/template-campaign/email-builder', {
      params: {
        page: currentPage,
        per_page: pageSize,
        type: type,
        keyword: search,
        site: site
      }
    })
  },
  uploadMedia: async (file: File, customName = false) => {
    const formData = new FormData()
    formData.append('file', file)

    if (customName) {
      formData.append('custom_name', 'true')
    }

    return templateBaseAxios.post('/api/media/', formData).catch((err) => {
      return {
        data: { URL: '' } as any,
        error: err
      }
    })
  },
  duplicateTemplate: (id: string | number) => {
    CachedSystem.clearCached(PREFIX)
    return templateBaseAxios.get(`/api/templates/duplicate/${id}`)
  },
  getTemplateByType: ({
    currentPage = 1,
    pageSize = 10,
    type = 'email',
    search = '',
    fromEmail = ''
  } = {}) => {
    return templateBaseAxios.get('/api/templates/email-app-push', {
      params: {
        page: currentPage,
        per_page: pageSize,
        type: type,
        keyword: search,
        from_site: fromEmail
      }
    })
  },
  getListVariables: () => {
    const keyCached = 'VARIATION'
    return CachedSystem.withCached(keyCached, () => {
      return templateBaseAxios.get('/api/variation', {
        params: {
          per_page: 100
        }
      })
    })
  },
  getLeadCampaignData: ({
    currentPage = 1,
    pageSize = 10,
    campaignCode = ''
  } = {}) => {
    return templateBaseAxios.get('/api/leadgen-campaign', {
      params: {
        page: currentPage,
        per_page: pageSize,
        campaign_code: campaignCode
      }
    })
  },
  downloadLeadgenCampaign: (code: string) => {
    return templateBaseAxios.get(`/api/leadgen-campaign/download/${code}`, {
      responseType: 'arraybuffer'
    })
  },
  getListZaloTemplates: () => {
    const keyCached = `${PREFIX}/api/templates/zalo`
    return CachedSystem.withCached(keyCached, () => {
      return templateBaseAxios.get('/api/templates/template-zns')
    })
  },
  syncZaloTemplate: () => {
    CachedSystem.clearCached(`${PREFIX}/api/templates/zalo`)
    return templateBaseAxios.get('/api/templates/sync-template-zns')
  }
}
