import { Checkbox } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { WEB_PAGE } from 'constants/site'
import { useApp } from 'context/App'
import { Ref, forwardRef, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

const StyledCheckbox = styled(Checkbox.Group)`
  .ant-checkbox-group-item {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
`

export type CampaignStepCheckboxSitesProps = {
  disabled?: boolean
  defaultValue?: CheckboxValueType[]
  value?: CheckboxValueType[]
  onChange?: (value: CheckboxValueType[]) => void
}

const CampaignStepCheckboxSitesWithoutRef = (
  props: CampaignStepCheckboxSitesProps,
  ref?: Ref<HTMLInputElement>
) => {
  const { disabled, defaultValue, value: valueProps, onChange } = props
  const [value, setValue] = useState(defaultValue || valueProps || [])
  const { sites } = useApp()

  const options = useMemo(() => {
    return sites.map((site) => {
      return {
        value: site.value,
        label: WEB_PAGE?.[site.domain as unknown as keyof typeof WEB_PAGE] || ''
      }
    })
  }, [sites])

  const handleChange = (vals: CheckboxValueType[]) => {
    const newValue = vals.filter((val) => !value.includes(val))
    setValue(newValue)
    onChange?.(newValue)
  }

  useEffect(() => {
    setValue(valueProps || [])
  }, [valueProps])

  return (
    <StyledCheckbox
      ref={ref}
      disabled={disabled}
      options={options}
      value={value}
      onChange={handleChange}
    />
  )
}
export const CampaignStepCheckboxSites = forwardRef(
  CampaignStepCheckboxSitesWithoutRef
)
