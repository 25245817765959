import { Button } from 'antd'
import { Select } from 'components/atoms/select'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  &.run_button {
  }
  &.add_group_button {
    margin-right: 16px;
  }
`

export const StyledSelect = styled(Select)`
  &.form_content {
    display: inline-block;
    width: 150px;
    margin-inline: 4px;
    &.operator {
      margin-left: 0;
    }
  }
`

export const StyledSegmentListCondition = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  position: relative;
  padding-top: 16px;
  .relation_container {
    display: flex;
    width: 110px;
    align-items: flex-end;
    padding-bottom: 32px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 55px;
      width: 1px;
      height: calc(100% - 32px);
      background-color: ${({ theme }) => theme?.colors?.neutral100};
    }
    &.noPadding {
      padding-bottom: 0;
    }
    .relation {
      margin-bottom: 0;
      z-index: 1;
    }
  }
  .condition_container {
    flex: 1;
    min-width: 0;

    .condition_type_container {
      display: flex;
      gap: 16px;
      .form_item {
        min-width: 180px;
      }
    }
    .condition_operator_container {
      display: flex;
      gap: 4px;
      align-items: center;
      padding-top: 8px;
      .form_item {
        min-width: 180px;
      }
    }
  }
`

export const StyledSegmentListCol = styled.div`
  &.list_col-left {
    flex: 1;
    min-width: 0;
    .item_event {
      display: flex;
    }
  }
`
export const StyledSegmentListRow = styled.div`
  display: flex;
  gap: 24px;
`

export const StyledDescription = styled.div`
  font-size: 14px;
  &.gray400 {
    color: ${({ theme }) => theme?.colors?.gray400};
  }
`

export const StyledFormQueryContainer = styled.div`
  .header {
    margin-bottom: 4px;
  }

  .footer {
    margin-top: 16px;
  }

  .ant-form-item {
    margin-bottom: 0;
    .ant-form-item-control-input {
      min-height: 0;
    }
  }
`
