/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  InsightOverviewCheckboxGroup,
  InsightOverviewCheckboxGroupOptions
} from '../InsightOverviewCheckboxGroup'
import { InsightOverviewTable } from '../InsightOverviewTable'
import {
  StyledOverviewBox,
  StyledOverviewBoxHeader,
  StyledOverviewBoxTypo,
  StyledOverviewBoxWrapper
} from '../WorkflowInsightOverview.styled'
import {
  WORKFLOW_INSIGHT_LABEL,
  WORKFLOW_INSIGHT_MAPING_HIGHCHARTS_KEYS,
  WORKFLOW_INSIGHT_OPTIONS_NUMBER,
  WORKFLOW_INSIGHT_OPTIONS_RATE
} from './WorkflowInsightOverviewTotal.helpers'
import {
  BarChartOutlined,
  FieldNumberOutlined,
  LineChartOutlined,
  PercentageOutlined
} from '@ant-design/icons'
import { Segmented, Select } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { HighchartsLine } from 'components/atoms/Highcharts'
import { Box } from 'components/atoms/box'
import { DatePicker } from 'components/atoms/datePicker'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import dayjs, { ManipulateType } from 'dayjs'
import { WorkflowInsightNodes } from 'interfaces/workflow'
import type { RangeValue } from 'rc-picker/lib/interface'
import { FC, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

const StyledSegmented = styled(Segmented)`
  .ant-segmented-item-label {
    padding-inline: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .anticon {
      font-size: 16px;
      line-height: 0;
    }
  }
`

const WORKFLOW_INSIGHT_TYPE_OPTIONS = [
  { label: <LineChartOutlined />, value: 'line' },
  { label: <BarChartOutlined />, value: 'column' }
]

const WORKFLOW_INSIGHT_UNIT_OPTIONS = [
  { label: <PercentageOutlined />, value: 'percent' },
  { label: <FieldNumberOutlined />, value: 'number' }
]

export type WorkflowInsightOverviewTotalProps = {
  channel_ids?: CheckboxValueType[]
  nodes: WorkflowInsightNodes
  insights: Record<string, Record<string, number>>
  total: Record<string, number>
  totalDetail: Record<string, number>
}
export const WorkflowInsightOverviewTotal: FC<
  WorkflowInsightOverviewTotalProps
> = (props) => {
  const [startDate, setStartDate] = useState(() => dayjs().subtract(4, 'weeks'))
  const [endDate, setEndDate] = useState(() => dayjs())
  const { channel_ids, nodes, insights, total, totalDetail } = props
  const [optionValue, setOptionValue] = useState<CheckboxValueType[]>(
    WORKFLOW_INSIGHT_OPTIONS_RATE.map(({ value }) => value)
  )
  const [scaleRange, setScaleRange] = useState('week')
  const [type, setType] = useState('line')
  const [unit, setUnit] = useState('percent')
  const options = useWorkflowInsightCheckboxGroupOptions(
    unit === 'percent'
      ? WORKFLOW_INSIGHT_OPTIONS_RATE
      : WORKFLOW_INSIGHT_OPTIONS_NUMBER,
    unit === 'percent' ? total : totalDetail
  )
  const insightsSorted = useMemo(() => {
    return Object.values(insights).sort((itemA, itemB) => {
      const dateItemA = dayjs(itemA.date)
      const dateItemB = dayjs(itemB.date)
      return dateItemA.toDate().getTime() - dateItemB.toDate().getTime()
      // return dateItemB.toDate().getTime() - dateItemA.toDate().getTime()
    })
  }, [insights])
  const rangesCanBeSelected = useMemo(() => {
    return [
      insightsSorted[0] ? dayjs(insightsSorted[0].date) : startDate,
      insightsSorted[insightsSorted.length - 1]
        ? dayjs(insightsSorted[insightsSorted.length - 1].date)
        : endDate
    ]
  }, [insightsSorted, startDate, endDate])

  const optionValueHighcharts = useMemo(() => {
    return optionValue
      .map(
        (key) =>
          WORKFLOW_INSIGHT_MAPING_HIGHCHARTS_KEYS[
            key as keyof typeof WORKFLOW_INSIGHT_MAPING_HIGHCHARTS_KEYS
          ]
      )
      .filter(Boolean)
  }, [optionValue])

  const disabledDate = useCallback(
    (current: dayjs.Dayjs) => {
      const isAfterRange = current && current.isAfter(dayjs())
      const isBeforeRange = current && current.isBefore(rangesCanBeSelected[0])
      return isBeforeRange || isAfterRange
    },
    [rangesCanBeSelected]
  )

  const onChangeDate = useCallback(
    (values: RangeValue<dayjs.Dayjs>, formatString: [string, string]) => {
      const newStartDate = values?.[0]
      const newEndDate = values?.[1]
      if (newStartDate && newEndDate) {
        setStartDate(newStartDate)
        setEndDate(newEndDate)
      } else if (!formatString[0] && !formatString[1]) {
        setStartDate(dayjs().subtract(4, 'weeks'))
        setEndDate(dayjs())
      }
    },
    []
  )

  const insightsFilteredByRange = useMemo(() => {
    return insightsSorted.filter((insigntItem) => {
      const currentDate = dayjs(insigntItem.date)
      return (
        currentDate.isAfter(startDate.subtract(1, 'days')) &&
        currentDate.isBefore(endDate)
      )
    })
  }, [insightsSorted, endDate, startDate])

  const insightsGroupByScaleRange = useMemo(() => {
    const firstItem = insightsFilteredByRange[0]
    const lastItem = insightsFilteredByRange[insightsFilteredByRange.length - 1]
    if (!firstItem || !lastItem || scaleRange === 'day')
      return insightsFilteredByRange

    const unit = scaleRange as ManipulateType
    let start = dayjs(startDate).startOf(unit)
    const computedInsights: Record<string, any[]> = {}

    // console.log('======')
    while (start.isBefore(endDate) || start.isSame(endDate, unit)) {
      let from = start
      const to = start.add(1, unit)
      // eslint-disable-next-line no-loop-func
      const keyGroup = (() => {
        if (unit === 'week') {
          return `${start.format('DD/MM/YYYY')}-${to
            .subtract(1, 'day')
            .format('DD/MM/YYYY')}`
        }
        if (unit === 'month') {
          return start.format('MM-YYYY')
        }
        return start.format('YYYY')
      })()

      // console.log(`${start.format('DD/MM/YYYY')} -> ${to.format('DD/MM/YYYY')}`)
      while (from.isBefore(to) && from.isBefore(endDate)) {
        // console.log('----', from.format('DD/MM/YYYY'))
        const keyOfInsightObj = from.format('YYYY-MM-DD')

        if (insights[keyOfInsightObj]) {
          computedInsights[keyGroup] = [
            ...(computedInsights[keyGroup] || []),
            insights[keyOfInsightObj]
          ]
        }

        // Move to the next day
        from = from.add(1, 'day')
      }
      // Move to the next week
      start = start.add(1, unit)
    }
    console.log('===========================')
    console.log('RawInsignsGroupBy', unit, computedInsights)
    const returnedValue = Object.keys(computedInsights).map((key) => {
      const length = computedInsights[key].length
      const listGroupInsighsByUnit = computedInsights[key].reduce(
        (result, current) => {
          result.delivery_rate += current.delivery_rate / length
          result.click_rate += current.click_rate / length
          result.impression_rate += current.impression_rate / length
          result.open_rate += current.open_rate / length
          result.click_through_rate += current.click_through_rate / length
          return result
        },
        {
          date: key,
          delivery_rate: 0,
          click_rate: 0,
          impression_rate: 0,
          open_rate: 0,
          click_through_rate: 0
        }
      )

      return listGroupInsighsByUnit
    })
    console.log('InsignsGroupBy', unit, returnedValue)
    return returnedValue
  }, [insightsFilteredByRange, scaleRange, startDate, endDate, insights])

  const insigntsToDisplay = useMemo(() => {
    return insightsGroupByScaleRange.reduce((result, insigntItem) => {
      result[insigntItem.date] = insigntItem
      return result
    }, {} as Record<string, any>)
  }, [insightsGroupByScaleRange])

  const highchartsOptions = useWorkflowInsightHighchartsOptions(
    insigntsToDisplay,
    type,
    optionValueHighcharts
  )
  const tableDataSource = useTableDataSource(nodes, channel_ids)

  return (
    <StyledOverviewBox>
      <StyledOverviewBoxHeader>
        <StyledOverviewBoxTypo as="h4" className="title">
          Total Performance Metrics
        </StyledOverviewBoxTypo>
        <Box className="flex items-center gap-3">
          {insightsSorted.length > 0 && (
            <>
              <Select
                value={scaleRange}
                onChange={setScaleRange}
                options={[
                  { value: 'day', label: 'Day' },
                  { value: 'week', label: 'Week' },
                  { value: 'month', label: 'Month' },
                  { value: 'year', label: 'Year' }
                ]}
              />
              <DatePicker.RangePicker
                size="small"
                onChange={onChangeDate}
                value={[startDate, endDate]}
                disabledDate={disabledDate}
              />
            </>
          )}
          <StyledSegmented
            options={WORKFLOW_INSIGHT_UNIT_OPTIONS}
            value={unit}
            onChange={(val) => {
              setUnit(val.toString())
              setOptionValue(
                (val === 'percent'
                  ? WORKFLOW_INSIGHT_OPTIONS_RATE
                  : WORKFLOW_INSIGHT_OPTIONS_NUMBER
                ).map(({ value }) => value)
              )
            }}
          />
          <StyledSegmented
            options={WORKFLOW_INSIGHT_TYPE_OPTIONS}
            value={type}
            onChange={(val) => setType(val.toString())}
          />
        </Box>
        {/* <WorkflowInsightOverviewSelect /> */}
      </StyledOverviewBoxHeader>

      <StyledOverviewBoxWrapper>
        <InsightOverviewCheckboxGroup
          options={options}
          value={optionValue}
          onChange={setOptionValue}
        />
      </StyledOverviewBoxWrapper>

      <StyledOverviewBoxWrapper>
        <HighchartsLine
          options={highchartsOptions}
          emptyProps={{ className: 'py-[170px]', iconClassName: 'text-[80px]' }}
        />
      </StyledOverviewBoxWrapper>

      <StyledOverviewBoxWrapper>
        <InsightOverviewTable dataSource={tableDataSource} />
      </StyledOverviewBoxWrapper>
    </StyledOverviewBox>
  )
}

function roundValue(number: number) {
  return Math.round(number * 100) / 100
}

function useWorkflowInsightCheckboxGroupOptions(
  options: InsightOverviewCheckboxGroupOptions,
  total: any
) {
  if (!options || !options.length) {
    return []
  }

  return options.map((option) => {
    const key = option.value.toString()
    if (key in total || key.split('_')?.[0] in total) {
      const value = roundValue(
        parseFloat(total[key as keyof typeof total] || '0') ||
          parseFloat(total[key.split('_')?.[0]] || '0')
      )
      return { ...option, description: `${value}${option.unit}` }
    }
    return { ...option, description: `0${option.unit}` }
  })
}

function useWorkflowInsightHighchartsOptions(
  insights: any,
  type: string,
  options: CheckboxValueType[]
): Highcharts.Options {
  const highchartsOptions: Highcharts.Options = {
    chart: {
      type: type,
      scrollablePlotArea: {
        minWidth: 200,
        scrollPositionX: 0
      }
    },
    xAxis: {
      crosshair: true,
      lineWidth: 1,
      tickWidth: 1,
      tickmarkPlacement: 'on',
      labels: {
        enabled: true,
        rotation: 0,
        overflow: 'justify'
      },
      scrollbar: {
        enabled: true,
        height: 10,
        barBorderRadius: 5,
        buttonBorderWidth: 0,
        buttonBorderColor: 'transparent',
        buttonBackgroundColor: 'transparent'
      }
    },
    yAxis: {
      labels: { enabled: true },
      tickWidth: 1,
      tickmarkPlacement: 'on',
      lineWidth: 1,
      min: 0,
      max: 100
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        pointWidth: 16,
        centerInCategory: true,
        grouping: true,
        groupPadding: 0.37
      },
      line: {
        marker: {
          enabled: false
        }
      },
      series: {
        marker: {
          symbol: 'circle',
          radius: 1
        }
      }
    }
  }
  const series: Array<any> = []
  const categories: string[] = []
  const insightsArraged = useMemo(() => {
    if (!insights || !Object.keys(insights).length) {
      return []
    }
    return Object.values(insights).sort((a: any, b: any) => {
      const timeA = dayjs(a?.date, FORMAT_DATE_DAYJS_API)
      const timeB = dayjs(b?.date, FORMAT_DATE_DAYJS_API)
      if (!timeA.isValid() || !timeB.isValid()) {
        return 0
      }
      if (timeA.isAfter(timeB)) {
        return 1
      }
      return 0
    })
  }, [insights])
  if (!options.length || !insightsArraged?.length) {
    return highchartsOptions
  }
  try {
    insightsArraged.forEach((insight: any) => {
      categories.push(insight?.date)
      options.forEach((option) => {
        if (option.toString() in WORKFLOW_INSIGHT_LABEL) {
          const seriesItemName = WORKFLOW_INSIGHT_LABEL[option.toString()]
          const seriesItemValue = roundValue(
            parseFloat(insight[option.toString()] || '0')
          )
          const insightCheckboxOption = WORKFLOW_INSIGHT_OPTIONS_RATE.find(
            ({ value }) => value === option.toString()
          )
          const seriesItem = series.find(({ name }) => name === seriesItemName)
          if (seriesItem) {
            seriesItem.data.push(seriesItemValue)
          } else {
            series.push({
              name: seriesItemName,
              data: [seriesItemValue],
              color: insightCheckboxOption?.color,
              tooltip: {
                valueSuffix: insightCheckboxOption?.unit
              }
            })
          }
        }
      })
    })
  } catch (error) {
    console.log('** ERROR WorkflowInsightOverviewTotal.tsx : ', error)
  }
  return {
    ...highchartsOptions,
    xAxis: { ...highchartsOptions.xAxis, categories },
    series
  }
}

function useTableDataSource(
  nodes: WorkflowInsightNodes,
  channel_ids?: CheckboxValueType[]
): WorkflowInsightNodes {
  return useMemo(() => {
    if (!channel_ids || !channel_ids.length) {
      return nodes
    }
    return nodes?.filter((node) => channel_ids.includes(node.type))
  }, [nodes, channel_ids])
}

// const mockup = {
//   '2024-01-05': {
//     date: '2024-01-05',
//     delivery_rate: 95.83,
//     click_rate: 13.04,
//     impression_rate: 0,
//     open_rate: 33.33,
//     click_through_rate: 39.13
//   },
//   '2024-01-09': {
//     date: '2024-01-09',
//     delivery_rate: 89.13,
//     click_rate: 4.88,
//     impression_rate: 0,
//     open_rate: 2.44,
//     click_through_rate: 100
//   },
//   '2024-01-12': {
//     date: '2024-01-12',
//     delivery_rate: 100,
//     click_rate: 5,
//     impression_rate: 0,
//     open_rate: 5,
//     click_through_rate: 100
//   },
//   '2024-01-15': {
//     date: '2024-01-15',
//     delivery_rate: 0,
//     click_rate: 0,
//     impression_rate: 0,
//     open_rate: 0,
//     click_through_rate: 0
//   },
//   '2024-01-23': {
//     date: '2024-01-23',
//     delivery_rate: 100,
//     click_rate: 0,
//     impression_rate: 0,
//     open_rate: 50,
//     click_through_rate: 0
//   },
//   '2024-01-26': {
//     date: '2024-01-26',
//     delivery_rate: 0,
//     click_rate: 0,
//     impression_rate: 0,
//     open_rate: 0,
//     click_through_rate: 0
//   },
//   '2024-01-27': {
//     date: '2024-01-27',
//     delivery_rate: 100,
//     click_rate: 0,
//     impression_rate: 0,
//     open_rate: 0,
//     click_through_rate: 0
//   },
//   '2024-01-30': {
//     date: '2024-01-30',
//     delivery_rate: 100,
//     click_rate: 0,
//     impression_rate: 0,
//     open_rate: 100,
//     click_through_rate: 0
//   },
//   '2024-02-05': {
//     date: '2024-02-05',
//     delivery_rate: 0,
//     click_rate: 0,
//     impression_rate: 0,
//     open_rate: 0,
//     click_through_rate: 0
//   }
// }
