import { formatTemplateOptions } from '../../../../../../pages/campaign/automation/create/CampaignAutomationCreatePage.helpers'
import {
  StyleButton,
  StyleWrapperButton
} from '../CampaignFormAutomation.styled'
import TemplateItemFields from '../TemplateItemFields/TemplateItemFields'
import Icon from '@ant-design/icons'
import { CheckboxOptionType, Form, notification } from 'antd'
import {
  FormNamePath,
  getFormNamePath
} from 'components/condition/conditions/form/index.helpers'
import { HHG_FROM_EMAILS } from 'constants/campaign'
import { default_current_page, default_page_size_all } from 'constants/common'
import { Plus } from 'icons'
import { FC, useEffect, useState } from 'react'
import { TemplateServices } from 'services/template'

interface TemplateListFormProps {
  sites: CheckboxOptionType[]
  onChangeField: (name: FormNamePath) => (value: any) => void
}

const TemplateListForm: FC<TemplateListFormProps> = ({
  sites,
  onChangeField
}) => {
  const form = Form.useFormInstance()
  const [templateOptions, setTemplateOptions] = useState<CheckboxOptionType[]>(
    []
  )
  const [templates, setTemplates] = useState([])
  const fieldRecurring = Form.useWatch(getFormNamePath('recurring'), form)
  const fieldSite = Form.useWatch(getFormNamePath('sites'), form)
  useEffect(() => {
    if (fieldSite) {
      let fromEmail = [] as string[]
      const findSite = sites.find((site) => site.value === fieldSite) as any
      if (findSite) {
        if (findSite?.key === 'hellohealthgroup.com') {
          fromEmail = HHG_FROM_EMAILS
        } else {
          fromEmail.push(findSite?.fromEmail)
        }
      }
      if (findSite) {
        TemplateServices.getTemplateByType({
          currentPage: default_current_page,
          pageSize: default_page_size_all,
          fromEmail: fromEmail.toString(),
          type: 'email'
        })
          .then((res) => {
            if (!res.data) {
              throw new Error('')
            }
            const options = formatTemplateOptions(res.data.data)
            setTemplateOptions(options)
            setTemplates(res.data.data)
          })
          .catch((err) => {
            notification.error({
              message: 'Something went wrong. Please try again!'
            })
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldSite])
  return (
    <Form.List name="templates">
      {(fields, operations) => {
        return (
          <>
            {fields.map((field, index) => {
              return (
                <TemplateItemFields
                  key={field.key}
                  indexTemplate={index}
                  lengthFields={fields.length}
                  onChangeField={onChangeField}
                  formListFieldData={field}
                  formListOperation={operations}
                  templates={templates}
                  templateOptions={templateOptions}
                />
              )
            })}
            <StyleWrapperButton>
              <StyleButton
                icon={<Icon component={Plus} />}
                onClick={() => {
                  if (!fieldRecurring) {
                    notification.info({
                      message:
                        'You need to enable recurring to can add more template!'
                    })
                    return
                  }
                  operations.add()
                }}>
                Add more
              </StyleButton>
            </StyleWrapperButton>
          </>
        )
      }}
    </Form.List>
  )
}

export default TemplateListForm
