import { ConditionBaseProps } from '..'
import { ConditionApiComponent } from '../conditionApi'
import { ConditionDate } from '../conditionDate'
import { ConditionNumber } from '../conditionNumber'
import { ConditionNumberRange } from '../conditionNumberRange'
import { ConditionSelect } from '../conditionSelect'
import { ConditionText } from '../conditionText'
import { ConditionDataType, operationDataType } from '../index.helpers'
import {
  StyledCondition,
  StyledConditionContent,
  StyledConditionLabel,
  StyledConditionOperator,
  StyledConditionSelect
} from '../index.styled'
import { CheckboxOptionType, Form } from 'antd'
import { OperatorNumberValue } from 'components/condition'
import {
  ConditionValueOperator,
  operatorLabel,
  operatorValue
} from 'interfaces/condition'
import { Fragment, useEffect, useMemo, useState } from 'react'

export const ConditionDataField = {
  API: ConditionApiComponent,
  BOOLEAN: ConditionSelect,
  TEXT: ConditionText,
  NUMBER: ConditionNumber,
  DATE: ConditionDate,
  CHECKBOX: ConditionText
}

export type OperatorKeys = keyof typeof operatorLabel
export const defautlOperatorOptions = Object.keys(operatorLabel).map((k) => {
  const key = k as OperatorKeys
  return {
    value: operatorValue[key],
    label: operatorLabel[key]
  }
})

function transferPropsToState(
  valueProps?: OperatorNumberValue
): OperatorNumberValue {
  const defaultValue: OperatorNumberValue = { operator: 'eq', value: 2 }
  if (!valueProps) {
    return defaultValue
  }
  if (typeof valueProps === 'object' && valueProps.hasOwnProperty('operator')) {
    return { ...valueProps, value: valueProps.value || null }
  }
  return defaultValue
}

export type ConditionOperatorComponentProps =
  ConditionBaseProps<OperatorNumberValue> & {
    itemName?: any
    rootName?: any
    placeholder?: string
    operator?: number | string
    condition?: ConditionDataType | null
  }
export const ConditionOperatorComponent = (
  props: ConditionOperatorComponentProps
) => {
  const {
    className,
    placeholder,
    defaultValue,
    value: valueProps,
    condition,
    itemName,
    rootName,
    operator
  } = props
  const [value, setValue] = useState<OperatorNumberValue>(
    transferPropsToState(defaultValue || valueProps)
  )

  const RenderConditionFieldComponent = useMemo(() => {
    if (!condition) {
      return Fragment
    }

    if (
      condition.dataType === 'NUMBER' &&
      operator === ConditionValueOperator.BETWEEN
    ) {
      return (
        <ConditionNumberRange
          itemName={itemName}
          rootName={rootName}
          placeholder={placeholder}
          className="condition-field"
        />
      )
    }
    const ConditionComponent =
      ConditionDataField[condition.dataType as keyof typeof ConditionDataField]
    return (
      <Form.Item
        name={[itemName, 'fieldValue']}
        rules={[
          {
            required: true,
            message: 'Value is required'
          }
        ]}>
        <ConditionComponent
          pathUrl={condition.value[0] || ''}
          site={condition.site}
          placeholder={placeholder}
          className="condition-field"
        />
      </Form.Item>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, operator])

  const optionsOperation = useMemo(() => {
    const options = [] as CheckboxOptionType[]
    if (!condition) {
      return []
    }

    const operationKey = operationDataType[
      condition.dataType as keyof typeof ConditionDataField
    ] as string[]

    Object.keys(operatorLabel).forEach((k) => {
      const key = k as OperatorKeys
      if (operationKey.includes(key)) {
        options.push({
          value: operatorValue[key],
          label: operatorLabel[key]
        })
      }
    })
    return options
  }, [condition])

  useEffect(() => {
    if (condition) {
      setValue(transferPropsToState(valueProps))
    }
  }, [valueProps, condition])

  return (
    <StyledConditionOperator className={className}>
      <Form.Item
        name={[itemName, 'fieldOperator']}
        rules={[
          {
            required: true,
            message: 'Operator is required'
          }
        ]}>
        <StyledConditionSelect
          options={optionsOperation}
          value={value.operator}
          placeholder="Select operator"
        />
      </Form.Item>
      {RenderConditionFieldComponent}
    </StyledConditionOperator>
  )
}

export type ConditionOperatorProps = ConditionOperatorComponentProps & {
  label?: string
}
export const ConditionOperator = (props: ConditionOperatorProps) => {
  const { label, className, ...restProps } = props

  return (
    <StyledCondition className={className}>
      {label && <StyledConditionLabel>{label}</StyledConditionLabel>}
      <StyledConditionContent>
        <ConditionOperatorComponent {...restProps} />
      </StyledConditionContent>
    </StyledCondition>
  )
}
