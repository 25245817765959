import { useSiteOptions, useTagOptions } from 'hooks/useSegment'
import { OptionType, OptionTypeSite } from 'interfaces'
import { FC, ReactNode, createContext, useContext, useMemo } from 'react'

export type AppContextProps = {
  sites: OptionTypeSite[]
  tags: OptionType[]
  refetchTags: () => void
}

export const AppContext = createContext<AppContextProps>({
  sites: [],
  tags: [],
  refetchTags: () => null
})
export default AppContext

export function useApp() {
  return useContext(AppContext)
}

export function useAppContextProvider() {
  const { tags, refetchTags } = useTagOptions()
  const sites = useSiteOptions()

  return useMemo<AppContextProps>(
    () => ({ sites, tags, refetchTags }),
    [sites, tags, refetchTags]
  )
}

type AppContextComponentProps = {
  children: ReactNode
}
const AppContextComponent: FC<AppContextComponentProps> = ({ children }) => {
  const appContext = useAppContextProvider()

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  )
}

export { AppContextComponent }
