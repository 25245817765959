import { ConditionApiComponent } from './conditionApi'
import {
  ConditionCheckbox,
  ConditionCheckboxComponent
} from './conditionCheckbox'
import { ConditionDate } from './conditionDate'
import { ConditionDropdownTabs } from './conditionDropdownTabs'
import { ConditionNumber } from './conditionNumber'
import { ConditionNumberRange } from './conditionNumberRange'
import {
  ConditionOperator,
  ConditionOperatorComponent
} from './conditionOperator'
import { ConditionRadio } from './conditionRadio'
import { ConditionRange } from './conditionRange'
import { ConditionRangeDate } from './conditionRangeDate'
import { ConditionSelect, ConditionSelectComponent } from './conditionSelect'
import {
  ConditionSelectCheckbox,
  ConditionSelectCheckboxComponent
} from './conditionSelectCheckbox'
import { ConditionSwitch, ConditionSwitchComponent } from './conditionSwitch'
import { ConditionText } from './conditionText'

export type ConditionBaseProps<T> = {
  value?: T
  defaultValue?: T
  onChange?: (value: T) => void
  className?: string
}

export const ConditionBase = {
  ConditionCheckboxComponent,
  ConditionCheckbox,
  ConditionDropdownTabs,
  ConditionNumber,
  ConditionNumberRange,
  ConditionOperatorComponent,
  ConditionOperator,
  ConditionRadio,
  ConditionSelectComponent,
  ConditionSelect,
  ConditionSelectCheckbox,
  ConditionSelectCheckboxComponent,
  ConditionSwitch,
  ConditionSwitchComponent,
  ConditionRange,
  ConditionText,
  ConditionRangeDate,
  ConditionApiComponent,
  ConditionDate
}
