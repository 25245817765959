import { CachedSystem } from './cachedSystem'
import { SEGMENT_PATHS } from 'constants/apiPaths'
import { default_current_page, default_page_size_all } from 'constants/common'
import { convertQueryParams, getAsPathApi } from 'utils'
import {
  segmentBaseAxios,
  segmentBuilderBaseAxios,
  sidisInternalBaseAxios
} from 'utils/api'

const getSegmentBuilder = () => {
  return segmentBuilderBaseAxios.get(SEGMENT_PATHS.segment_builder)
}

const getSegmentBuilderDetail = (request: {
  id: string | number
  category: string
}) => {
  return segmentBuilderBaseAxios.get(
    getAsPathApi(SEGMENT_PATHS.segment_builder_detail, request)
  )
}

const getSegmentListUser = (id: string | number) => {
  return segmentBuilderBaseAxios.get(
    getAsPathApi(SEGMENT_PATHS.segment_contacts, { id })
  )
}

const exportSegmentUsers = (id: string | number) => {
  return segmentBuilderBaseAxios.get(
    getAsPathApi(SEGMENT_PATHS.segment_export_contacts, { id }),
    {
      responseType: 'blob'
    }
  )
}

export const segmentBuilderServices = {
  getSegmentBuilder,
  getSegmentBuilderDetail,
  getSegmentListUser,
  exportSegmentUsers,
  getSegmentAnalytics({ id = '', startDate = '', endDate = '' } = {}) {
    return segmentBuilderBaseAxios.post(
      getAsPathApi(SEGMENT_PATHS.segment_v2_analytics, {
        id
      }),
      { startDate, endDate }
    )
  }
}

const PREFIX = 'SegmentConditionsServives/'
// Segment builder conditions
const getConditionBaseBuilder = () => {
  return segmentBuilderBaseAxios.post(SEGMENT_PATHS.condition_base_builder, {
    page: default_current_page,
    perPage: default_page_size_all
  })
}

const getConditions = ({ page = 1, perPage = 10000, search = '' } = {}) => {
  const queryString = convertQueryParams({
    page,
    perPage,
    search
  })
  const URL = `${SEGMENT_PATHS.segment_v2_get_condition_for_workflow}?${queryString}`

  return CachedSystem.withCached(PREFIX + URL, () =>
    segmentBuilderBaseAxios.get(URL)
  )
}

const createSegmentConditionBaseBuilder = (id: string | number, data: any) => {
  return segmentBuilderBaseAxios.post(
    getAsPathApi(SEGMENT_PATHS.segment_condition_base_builder_create, { id }),
    data
  )
}

const detailSegmentConditionBaseBuilder = (id: string | number, data: any) => {
  return segmentBuilderBaseAxios.post(
    getAsPathApi(SEGMENT_PATHS.segment_condition_base_builder_detail, { id }),
    data
  )
}

const countUserBySegmentCondition = (data: any) => {
  return segmentBuilderBaseAxios.post(
    SEGMENT_PATHS.count_user_by_segment_condition,
    data
  )
}

// GET : get segment condition detail
const getSegmentCondition = (id: string | number) => {
  return segmentBaseAxios.get(
    getAsPathApi(SEGMENT_PATHS.segment_condition_detail, { id })
  )
}

const fetchApiConditionField = (url: string, data: any) => {
  return sidisInternalBaseAxios.post(url, data)
}

const getCountUserAllSite = () => {
  return segmentBuilderBaseAxios.get(SEGMENT_PATHS.count_user_all_site)
}

const getUserReachable = (id: string | number) => {
  return segmentBuilderBaseAxios.get(
    getAsPathApi(SEGMENT_PATHS.count_user_reachable, { id })
  )
}

const runCompareSegment = (
  segmentId: string | number,
  compareSegmentId: string | number
) => {
  return segmentBuilderBaseAxios.post(
    getAsPathApi(SEGMENT_PATHS.run_compare_segment, {
      segmentId
    }),
    {
      segmentId: segmentId,
      compareId: compareSegmentId
    }
  )
}

const getCompareSegment = (segmentId: string | number) => {
  return segmentBuilderBaseAxios.get(
    getAsPathApi(SEGMENT_PATHS.get_compare_segment, {
      segmentId
    })
  )
}

const excludeEmailCompare = (id: string | number) => {
  return segmentBuilderBaseAxios.get(
    getAsPathApi(SEGMENT_PATHS.exclude_email_compare, { id })
  )
}

const countByCondition = (params: any) => {
  return segmentBuilderBaseAxios.post(SEGMENT_PATHS.count_by_condition, params)
}

export const segmentConditonBuilderServices = {
  getConditions,
  getConditionBaseBuilder,
  createSegmentConditionBaseBuilder,
  detailSegmentConditionBaseBuilder,
  countUserBySegmentCondition,
  getSegmentCondition,
  fetchApiConditionField,
  getCountUserAllSite,
  getUserReachable,
  runCompareSegment,
  getCompareSegment,
  excludeEmailCompare,
  countByCondition
}
