import { CampaignStepForm, combineInitialValue } from '..'
import { CampaignStepDateTimePicker } from './CampaignStepDateTimePicker'
import { CampaignStepFrequencyBypass } from './CampaignStepFrequencyBypass'
import {
  StyledStepOverviews,
  StyledStepOverviewsCol,
  StyledStepOverviewsDivider,
  StyledStepOverviewsFormItem,
  StyledStepOverviewsRadio,
  StyledStepOverviewsRadioGroup,
  StyledStepOverviewsSkeleton,
  StyledStepOverviewsTitle
} from './CampaignStepOverviews.styled'
import { CampaignStepSwitchThreshold } from './CampaignStepSwitchThreshold'
import { CampaignStepTimePicker } from './CampaignStepTimePicker'
import { Form, FormInstance, Input, Row, Select, Switch } from 'antd'
import { CheckboxButton } from 'components/atoms/checkbox'
import { optionsTimezone } from 'components/atoms/select'
import {
  CAMPAIGN_APPROACH_OPTIONS,
  CAMPAIGN_TARGET_AUDIENCE_OPTIONS,
  CAMPAIGN_TIMEFRAME_OPTIONS,
  CAMPAIGN_WEEKLY_OPTIONS
} from 'constants/campaign'
import { FORMAT_DATE_TIME_DAYJS } from 'constants/common'
import { TEMPLATE_TYPE } from 'constants/template'
import dayjs from 'dayjs'
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'

export type CampaignStepOverviewsValue = {
  name?: string
  description?: string
  approach?: number
  time_zone?: string
  type?: string
  start_time?: string
  end_time?: string
  is_recurring?: boolean
  recurring_cycle?: string
  trigger_time?: string
  days_of_week?: string[]
  template_type?: string
  threshold?: number
  push_type?: string
  by_pass_frequency?: boolean
}

const defaultCampaignStepOverviewValue = {
  approach: 1,
  type: 'manual',
  recurring_cycle: 'Daily',
  threshold: 1,
  by_pass_frequency: false
}

export const campaignStepOverviewsProps = {
  labelCol: { span: 6, style: { maxWidth: 360 } },
  wrapperCol: { span: 10, style: { maxWidth: 540 } }
}

export type CampaignStepOverviewsProps =
  CampaignStepForm<CampaignStepOverviewsValue>

const CampaignStepOverviewsWithoutRef = (
  props: CampaignStepOverviewsProps,
  ref?: Ref<FormInstance<CampaignStepOverviewsValue>>
) => {
  const {
    template_type,
    loading,
    disabled,
    name,
    initialValues: initialValuesProps,
    onValuesChange,
    onFinish,
    onFinishFailed
  } = props
  const [form] = Form.useForm<CampaignStepOverviewsValue>()
  const approach = Form.useWatch('approach', form)
  const type = Form.useWatch('type', form)
  const startTime = Form.useWatch('start_time', form)
  const endTime = Form.useWatch('end_time', form)
  const isRecurring = Form.useWatch('is_recurring', form)
  const recurringCycle = Form.useWatch('recurring_cycle', form)

  const hasDelivery = useMemo(
    () => [TEMPLATE_TYPE.EMAIL].some((val) => val === template_type),
    [template_type]
  )
  const hasTargetAudience = useMemo(() => {
    return [TEMPLATE_TYPE.APP_PUSH, TEMPLATE_TYPE.WEB_PUSH].some(
      (val) => val === template_type
    )
  }, [template_type])

  const initialValues = useMemo(() => {
    return combineInitialValue(
      { ...initialValuesProps, template_type },
      defaultCampaignStepOverviewValue
    )
  }, [initialValuesProps, template_type])

  const weeklyOptions = useMemo(() => {
    const disabledKeys: Record<number, boolean> = {}
    const dayjsStartTime = dayjs(startTime, FORMAT_DATE_TIME_DAYJS, true)
    const dayjsEndTime = dayjs(endTime, FORMAT_DATE_TIME_DAYJS, true)

    if (dayjsStartTime.isValid() && dayjsEndTime.isValid()) {
      for (let i = 0; i < 7; i++) {
        const daycount = dayjsStartTime.add(i, 'day')
        if (daycount < dayjsEndTime) {
          disabledKeys[daycount.day()] = false
        } else {
          disabledKeys[daycount.day()] = true
        }
      }
    }

    return CAMPAIGN_WEEKLY_OPTIONS.map(({ value, label, key }) => {
      return { label, value, disabled: Boolean(disabledKeys[key]) }
    })
  }, [startTime, endTime])

  const handleFinish = (values: any) => {
    onFinish?.(values)
  }

  const handleValuesChange = (valChanged: any, valsChanged: any) => {
    const newValChanged = { ...valsChanged }
    if (valChanged.hasOwnProperty('approach')) {
      newValChanged.type = null
      newValChanged.start_time = ''
      newValChanged.end_time = ''
    }
    if (valChanged.hasOwnProperty('start_time') && valChanged.start_time) {
      const startTimeArr = valChanged.start_time?.split(' ') || []
      startTimeArr[0] = startTimeArr[0] ? startTimeArr[0] : ''
      startTimeArr[1] = startTimeArr[1]
        ? startTimeArr[1]
        : startTimeArr[0]
        ? '00:00:00'
        : ''
      newValChanged.start_time = startTimeArr.join(' ')
      valChanged.start_time = startTimeArr.join(' ')
      newValChanged.days_of_week = null
    }

    if (valChanged.hasOwnProperty('end_time') && valChanged.end_time) {
      const endTimeArr = valChanged.end_time?.split(' ') || []
      endTimeArr[0] = endTimeArr[0] ? endTimeArr[0] : ''
      endTimeArr[1] = endTimeArr[1]
        ? endTimeArr[1]
        : endTimeArr[0]
        ? '23:59:59'
        : ''
      newValChanged.end_time = endTimeArr.join(' ')
      valChanged.end_time = endTimeArr.join(' ')
      newValChanged.days_of_week = null
    }
    if (valChanged.hasOwnProperty('type')) {
      newValChanged.start_time = ''
      newValChanged.end_time = ''
    }
    form.setFieldsValue(newValChanged)
    onValuesChange?.(valChanged, newValChanged)
  }

  useEffect(() => {
    form.setFieldsValue(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  useImperativeHandle(ref, () => form, [form])

  // if (typeof window !== 'undefined') {
  //   ;(window as any).form = form
  // }

  return (
    <StyledStepOverviews>
      <StyledStepOverviewsTitle>Info</StyledStepOverviewsTitle>
      <Form
        form={form}
        name={name}
        initialValues={initialValues}
        onFinish={handleFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={handleValuesChange}
        colon={false}
        layout="horizontal"
        labelAlign="left"
        {...campaignStepOverviewsProps}>
        <StyledStepOverviewsFormItem name="template_type" hidden noStyle>
          <Input />
        </StyledStepOverviewsFormItem>
        <StyledStepOverviewsSkeleton
          active
          className="form_item"
          loading={loading}>
          <StyledStepOverviewsFormItem
            required
            name="name"
            label="Campaign name"
            rules={[
              { required: true, message: 'Please input campaign name' },
              { max: 255, message: 'Maximum 255 characters for name' },
              {
                whitespace: true,
                message: 'Please input campaign name with characters'
              }
            ]}>
            <Input placeholder="Fill campaign name here" />
          </StyledStepOverviewsFormItem>
        </StyledStepOverviewsSkeleton>
        <StyledStepOverviewsSkeleton
          active
          className="form_item"
          loading={loading}>
          <StyledStepOverviewsFormItem name="description" label="Description">
            <Input.TextArea placeholder="Description" rows={3} />
          </StyledStepOverviewsFormItem>
        </StyledStepOverviewsSkeleton>
        <Form.Item shouldUpdate={hasTargetAudience} noStyle>
          {hasTargetAudience && (
            <>
              <StyledStepOverviewsDivider className="divider" />
              <StyledStepOverviewsSkeleton
                active
                className="form_item paragraph"
                loading={loading}>
                <StyledStepOverviewsFormItem
                  required
                  name="push_type"
                  label="Target Audience"
                  rules={[
                    { required: true, message: 'Please select target audience' }
                  ]}>
                  <StyledStepOverviewsRadioGroup disabled={disabled}>
                    {CAMPAIGN_TARGET_AUDIENCE_OPTIONS.map((option) => {
                      return (
                        <StyledStepOverviewsRadio
                          value={option.value}
                          key={`${option.value}`}>
                          <div className="label">{option.label}</div>
                          <p className="description">{option.description}</p>
                        </StyledStepOverviewsRadio>
                      )
                    })}
                  </StyledStepOverviewsRadioGroup>
                </StyledStepOverviewsFormItem>
              </StyledStepOverviewsSkeleton>
            </>
          )}
        </Form.Item>
        <StyledStepOverviewsDivider className="divider" />
        <StyledStepOverviewsSkeleton
          active
          className="form_item paragraph"
          loading={loading}>
          <StyledStepOverviewsFormItem
            required
            name="approach"
            label="Approach"
            rules={[{ required: true, message: 'Please select approach' }]}>
            <StyledStepOverviewsRadioGroup disabled={disabled}>
              {CAMPAIGN_APPROACH_OPTIONS.map((option) => {
                return (
                  <StyledStepOverviewsRadio
                    value={option.value}
                    key={`${option.value}`}>
                    <div className="label">{option.label}</div>
                    <p className="description">{option.description}</p>
                  </StyledStepOverviewsRadio>
                )
              })}
            </StyledStepOverviewsRadioGroup>
          </StyledStepOverviewsFormItem>
        </StyledStepOverviewsSkeleton>
        <StyledStepOverviewsDivider className="divider" />
        <StyledStepOverviewsSkeleton
          active
          className="form_item"
          loading={loading}>
          <StyledStepOverviewsFormItem
            required
            name="time_zone"
            label="Timezone"
            rules={[{ required: true, message: 'Please select timezone' }]}>
            <Select
              allowClear
              disabled={disabled}
              placeholder="Select a timezone"
              options={optionsTimezone}
            />
          </StyledStepOverviewsFormItem>
        </StyledStepOverviewsSkeleton>
        <StyledStepOverviewsSkeleton
          active
          className="form_item"
          loading={loading}>
          <StyledStepOverviewsFormItem
            required
            name="type"
            label="Time frame"
            rules={[{ required: true, message: 'Please select time frame' }]}>
            <StyledStepOverviewsRadioGroup disabled={disabled}>
              {CAMPAIGN_TIMEFRAME_OPTIONS.map((option) => {
                return (
                  <StyledStepOverviewsRadio
                    key={`${option.value}`}
                    value={option.value}
                    disabled={option.value === 'manual' && approach === 2}>
                    <div className="label">{option.label}</div>
                  </StyledStepOverviewsRadio>
                )
              })}
            </StyledStepOverviewsRadioGroup>
          </StyledStepOverviewsFormItem>
        </StyledStepOverviewsSkeleton>
        <Form.Item shouldUpdate={(prev, cur) => prev.type !== cur.type} noStyle>
          {type === 'schedule' && approach === 1 && (
            <Row>
              <StyledStepOverviewsCol span={6} className="form_label" />
              <StyledStepOverviewsCol flex={1} className="form_input">
                <StyledStepOverviewsSkeleton
                  active
                  className="title"
                  loading={loading}>
                  <StyledStepOverviewsFormItem
                    required
                    name="start_time"
                    rules={[
                      { required: true, message: 'Please input start time' }
                    ]}>
                    <CampaignStepDateTimePicker
                      dayProps={{
                        disabled,
                        placeholder: 'Start date',
                        disabledDate(date) {
                          const endDate = dayjs(
                            endTime,
                            FORMAT_DATE_TIME_DAYJS,
                            true
                          )

                          if (endDate.isValid()) {
                            return (
                              date < dayjs().startOf('day') || date > endDate
                            )
                          }
                          return date < dayjs().startOf('day')
                        }
                      }}
                      timeProps={{ disabled, placeholder: 'Start time' }}
                    />
                  </StyledStepOverviewsFormItem>
                </StyledStepOverviewsSkeleton>
              </StyledStepOverviewsCol>
            </Row>
          )}
          {type === 'schedule' && approach === 2 && (
            <>
              <Row>
                <StyledStepOverviewsCol span={6} className="form_label" />
                <StyledStepOverviewsCol flex={1} className="form_input">
                  <StyledStepOverviewsSkeleton
                    active
                    className="title"
                    loading={loading}>
                    <StyledStepOverviewsFormItem
                      required
                      name="start_time"
                      rules={[
                        { required: true, message: 'Please input start time' }
                      ]}>
                      <CampaignStepDateTimePicker
                        dayProps={{
                          disabled,
                          placeholder: 'Start date',
                          disabledDate(date) {
                            const endDate = dayjs(
                              endTime,
                              FORMAT_DATE_TIME_DAYJS,
                              true
                            )
                            return (
                              date < dayjs().startOf('day') || date > endDate
                            )
                          }
                        }}
                        timeProps={{
                          disabled: true,
                          placeholder: 'Start time'
                        }}
                      />
                    </StyledStepOverviewsFormItem>
                  </StyledStepOverviewsSkeleton>
                </StyledStepOverviewsCol>
              </Row>
              <Form.Item
                shouldUpdate={(prev, cur) => {
                  return Boolean(prev.is_recurring !== cur.is_recurring)
                }}
                noStyle>
                {Boolean(isRecurring) && (
                  <Row>
                    <StyledStepOverviewsCol span={6} className="form_label" />
                    <StyledStepOverviewsCol flex={1} className="form_input">
                      <StyledStepOverviewsSkeleton
                        active
                        className="title"
                        loading={loading}>
                        <StyledStepOverviewsFormItem
                          required
                          name="end_time"
                          rules={[
                            {
                              required: true,
                              message: 'Please input start time'
                            }
                          ]}>
                          <CampaignStepDateTimePicker
                            dayProps={{
                              disabled,
                              placeholder: 'End date',
                              disabledDate(date) {
                                const startDate = dayjs(
                                  startTime,
                                  FORMAT_DATE_TIME_DAYJS,
                                  true
                                )
                                return startDate.isValid()
                                  ? date < startDate
                                  : date < dayjs()
                              }
                            }}
                            timeProps={{
                              disabled: true,
                              placeholder: 'End time'
                            }}
                          />
                        </StyledStepOverviewsFormItem>
                      </StyledStepOverviewsSkeleton>
                    </StyledStepOverviewsCol>
                  </Row>
                )}
              </Form.Item>
              <StyledStepOverviewsSkeleton
                active
                className="form_item"
                loading={loading}>
                <StyledStepOverviewsFormItem
                  required
                  name="is_recurring"
                  label="Recurring"
                  valuePropName="checked">
                  <Switch disabled={disabled} />
                </StyledStepOverviewsFormItem>
              </StyledStepOverviewsSkeleton>
              <Form.Item
                shouldUpdate={(prev, cur) => {
                  return Boolean(prev.is_recurring !== cur.is_recurring)
                }}
                noStyle>
                {Boolean(isRecurring) && (
                  <>
                    <StyledStepOverviewsSkeleton
                      active
                      className="form_item"
                      loading={loading}>
                      <StyledStepOverviewsFormItem
                        required
                        name="recurring_cycle"
                        label="Replication"
                        rules={[
                          {
                            required: true,
                            message: 'Please select replication'
                          }
                        ]}>
                        <StyledStepOverviewsRadioGroup disabled={disabled}>
                          <StyledStepOverviewsRadio.Button value="Daily">
                            Daily
                          </StyledStepOverviewsRadio.Button>
                          <StyledStepOverviewsRadio.Button value="Weekly">
                            Weekly
                          </StyledStepOverviewsRadio.Button>
                        </StyledStepOverviewsRadioGroup>
                      </StyledStepOverviewsFormItem>
                    </StyledStepOverviewsSkeleton>
                    <Form.Item
                      shouldUpdate={(prev, cur) => {
                        return Boolean(
                          prev.recurring_cycle !== cur.recurring_cycle
                        )
                      }}
                      noStyle>
                      {recurringCycle === 'Weekly' && (
                        <StyledStepOverviewsSkeleton
                          active
                          className="form_item"
                          loading={loading}>
                          <StyledStepOverviewsFormItem
                            required
                            name="days_of_week"
                            label="Schedule on"
                            rules={[
                              {
                                required: true,
                                message: 'Please select trigger time'
                              }
                            ]}>
                            <CheckboxButton
                              disabled={disabled}
                              options={weeklyOptions}
                            />
                          </StyledStepOverviewsFormItem>
                        </StyledStepOverviewsSkeleton>
                      )}
                    </Form.Item>
                    <StyledStepOverviewsSkeleton
                      active
                      className="form_item"
                      loading={loading}>
                      <StyledStepOverviewsFormItem
                        required
                        name="trigger_time"
                        label="Trigger"
                        rules={[
                          {
                            required: true,
                            message: 'Please select trigger time'
                          }
                        ]}>
                        <CampaignStepTimePicker
                          disabled={disabled}
                          placeholder="Select time"
                        />
                      </StyledStepOverviewsFormItem>
                    </StyledStepOverviewsSkeleton>
                  </>
                )}
              </Form.Item>
            </>
          )}
        </Form.Item>
        <Form.Item shouldUpdate={() => hasDelivery} noStyle>
          {hasDelivery && (
            <>
              <StyledStepOverviewsDivider className="divider" />
              <StyledStepOverviewsSkeleton
                active
                className="form_item"
                loading={loading}>
                <StyledStepOverviewsFormItem
                  label={
                    <>
                      Delivery <i>(optional)</i>
                    </>
                  }
                  name="threshold">
                  <CampaignStepSwitchThreshold disabled={disabled} />
                </StyledStepOverviewsFormItem>

                <StyledStepOverviewsFormItem
                  label={<></>}
                  name="by_pass_frequency">
                  <CampaignStepFrequencyBypass disabled={disabled} />
                </StyledStepOverviewsFormItem>
              </StyledStepOverviewsSkeleton>
              {/* <StyledStepOverviewsSkeleton
                active
                className="form_item"
                loading={loading}>
                <StyledStepOverviewsFormItem
                  label={
                    <>
                      Delivery <i>(optional)</i>
                    </>
                  }
                  name="quite_hours">
                  <CampaignStepSwitch
                    title="Quiet hours"
                    description={
                      <>
                        Prevents messages from being sent during part of the
                        day. <Link to="#">Learn more</Link>
                      </>
                    }
                    disabled
                  />
                </StyledStepOverviewsFormItem>
              </StyledStepOverviewsSkeleton>
							<Row>
                <StyledStepsOverviewCol span={6} className="form_label" />
                <StyledStepsOverviewCol flex={1} className="form_input">
                  <StyledStepOverviewsSkeleton
                    active
                    className="title"
                    loading={loading}>
                    <StyledStepOverviewsFormItem
                      required
                      name="threshold"
                      rules={[
                        {
                          required: true,
                          message: 'Please input start time'
                        }
                      ]}>
                      <CampaignStepSwitch
                        title="Send time optimization"
                        description="Optimize the delivery of this message based on past campaign performance & user behavior."
                        disabled
                      />
                    </StyledStepOverviewsFormItem>
                  </StyledStepOverviewsSkeleton>
                </StyledStepsOverviewCol>
              </Row>
              <Row>
                <StyledStepsOverviewCol span={6} className="form_label" />
                <StyledStepsOverviewCol flex={1} className="form_input">
                  <StyledStepOverviewsSkeleton
                    active
                    className="title"
                    loading={loading}>
                    <StyledStepOverviewsFormItem
                      required
                      name="threshold"
                      rules={[
                        {
                          required: true,
                          message: 'Please input start time'
                        }
                      ]}>
                      <CampaignStepSwitchThreshold disabled={disabled} />
                    </StyledStepOverviewsFormItem>
                  </StyledStepOverviewsSkeleton>
                </StyledStepsOverviewCol>
              </Row>
              <Row>
                <StyledStepsOverviewCol span={6} className="form_label" />
                <StyledStepsOverviewCol flex={1} className="form_input">
                  <StyledStepOverviewsSkeleton
                    active
                    className="title"
                    loading={loading}>
                    <StyledStepOverviewsFormItem
                      required
                      name="threshold"
                      rules={[
                        {
                          required: true,
                          message: 'Please input start time'
                        }
                      ]}>
                      <CampaignStepSwitch
                        title="Bypass Channel Frequency Capping"
                        description="Set your campaign to ignore the number of times that users can get your message specified in the Channel Frequency Capping Settings"
                        disabled
                      />
                    </StyledStepOverviewsFormItem>
                  </StyledStepOverviewsSkeleton>
                </StyledStepsOverviewCol>
              </Row> */}
            </>
          )}
        </Form.Item>
      </Form>
    </StyledStepOverviews>
  )
}

export const CampaignStepOverviews = forwardRef(CampaignStepOverviewsWithoutRef)
