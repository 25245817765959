import { HighchartsCompProps, HighchartsCompRef } from '.'
import { HighchartsEmpty } from './HighchartsEmpty'
import cn from 'classnames'
import Highcharts from 'highcharts'
import Accessibility from 'highcharts/modules/accessibility'
import SeriesLabel from 'highcharts/modules/series-label'
import Stock from 'highcharts/modules/stock'
import { Ref, forwardRef, useImperativeHandle, useRef } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

export type HighchartsLineRef = HighchartsCompRef
export type HighchartsLineProps = HighchartsCompProps

const HighchartsLineWithoutRef = (
  props: HighchartsLineProps,
  ref?: Ref<HighchartsLineRef>
) => {
  const { options, className, style, emptyProps } = props
  const debounce = useRef<NodeJS.Timeout>()
  const $highchart = useRef<HTMLDivElement>(null)
  const highchartRef = useRef<Highcharts.Chart>()

  useDeepCompareEffect(() => {
    if (debounce.current) clearTimeout(debounce.current)
    debounce.current = setTimeout(() => {
      if ($highchart.current) {
        Stock(Highcharts)
        SeriesLabel(Highcharts)
        Accessibility(Highcharts)
        Highcharts.chart(
          $highchart.current,
          {
            ...options,
            title: { text: '', ...options.title },
            yAxis: {
              labels: { enabled: false },
              title: { text: null },
              ...options.yAxis
            },
            xAxis: {
              labels: { enabled: false },
              title: { text: null },
              ...options.xAxis
            },
            legend: { enabled: false, ...options.legend },
            credits: { enabled: false, ...options.credits }
          },
          (chart) => {
            highchartRef.current = chart
          }
        )
      }
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useImperativeHandle(ref, () => highchartRef.current as Highcharts.Chart)

  if (!options.series?.length) {
    return (
      <div className={cn('highchartsLine', className)} style={style}>
        <HighchartsEmpty {...emptyProps} />
      </div>
    )
  }

  return (
    <div className={cn('highchartsLine', className)} style={style}>
      <div ref={$highchart} className="highchartsLine--container h-full" />
    </div>
  )
}
export const HighchartsLine = forwardRef(HighchartsLineWithoutRef)
