import { Dropdown, DropdownProps, MenuProps } from 'antd'
import cn from 'classnames'
import { ReactNode, useMemo } from 'react'

export type DropdownMenuProps = {
  children?: ReactNode
  items: MenuProps['items']
  onClickItem?: MenuProps['onClick']
} & Pick<
  DropdownProps,
  'trigger' | 'className' | 'openClassName' | 'overlayClassName'
>

export const DropdownMenu = (props: DropdownMenuProps) => {
  const { children, items: itemsProps, onClickItem, ...restProps } = props
  const items = useMemo(() => {
    return (itemsProps?.map((item) => {
      return {
        ...item,
        className: cn(
          item?.className,
          'py-2 px-4 gap-2 first:rounded-tl-lg first:rounded-tr-lg last:rounded-bl-lg last:rounded-br-lg'
        )
      }
    }) || []) as MenuProps['items']
  }, [itemsProps])

  return (
    <Dropdown
      menu={{
        items,
        onClick: onClickItem,
        className: 'p-0 min-w-[188px] rounded-lg'
      }}
      {...restProps}>
      {children}
    </Dropdown>
  )
}
