import styled from 'styled-components'

export const StyleDescription = styled.div`
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.colors.gray600};
`
