import { SegmentConditionValueProps } from './SegmentConditionValue'
import { Input } from 'antd'
import { ChangeEventHandler } from 'react'

export type SegmentConditionValueTEXTProps = Omit<
  SegmentConditionValueProps<string>,
  'type'
>
export const SegmentConditionValueTEXT = (
  props: SegmentConditionValueTEXTProps
) => {
  const { operator, options, onChange, ...restProps } = props

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange?.(e.target.value)
  }

  return <Input {...restProps} placeholder="Value" onChange={handleChange} />
}
