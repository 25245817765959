import { SegmentTableHeaderValue, SegmentTableHeaderValueState } from '.'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import dayjs from 'dayjs'

export function segmentTableHeaderValuePropsToState(
  value: SegmentTableHeaderValue
): SegmentTableHeaderValueState {
  const stateValue: SegmentTableHeaderValueState = {
    search: value.search,
    columns: value.columns,
    created_date: null,
    sites: value.sites,
    types: value.types
  }

  if (value.created_from && value.created_to) {
    const created_from = dayjs(value.created_from)
    const created_to = dayjs(value.created_to)

    const isValid =
      created_from.isValid() &&
      created_to.isValid() &&
      created_from.isBefore(created_to)

    if (isValid) {
      stateValue.created_date = [created_from, created_to]
    }
  }

  return stateValue
}

export function segmentTableHeaderValueStateToProps(
  value: SegmentTableHeaderValueState
): SegmentTableHeaderValue {
  const propsValue: SegmentTableHeaderValue = {
    search: value.search,
    columns: value.columns,
    created_from: undefined,
    created_to: undefined,
    sites: value.sites,
    types: value.types || 'condition'
  }

  if (value.created_date) {
    if (Array.isArray(value.created_date)) {
      const created_from = value.created_date[0].isValid()
        ? value.created_date[0].format(FORMAT_DATE_DAYJS_API)
        : null
      const created_to = value.created_date[1].isValid()
        ? value.created_date[1].format(FORMAT_DATE_DAYJS_API)
        : null
      if (created_from) propsValue.created_from = created_from
      if (created_to) propsValue.created_to = created_to
    }
  }

  return propsValue
}
