import { CachedSystem } from './cachedSystem'
import { FIELD_PATHS } from 'constants/apiPaths'
import { ResponseType } from 'interfaces'
import { EventItem, EventQueryParams, EventSetting } from 'interfaces/event'
import { convertQueryParams } from 'utils'
import { sidisBaseAxios } from 'utils/api'

const PREFIX = 'EventServices/'

export const EventServices = {
  createEvent: (data: EventSetting) => {
    CachedSystem.clearCached(PREFIX + FIELD_PATHS.event)
    return sidisBaseAxios.post<EventSetting, ResponseType<EventItem>>(
      FIELD_PATHS.event,
      data
    )
  },
  updateEvent: (id: string | number, data: any) => {
    CachedSystem.clearCached(PREFIX + FIELD_PATHS.event)
    return sidisBaseAxios.put<EventSetting, ResponseType<EventItem>>(
      `${FIELD_PATHS.event}/${id}`,
      data
    )
  },
  deleteEvent: (id: string | number) => {
    CachedSystem.clearCached(PREFIX + FIELD_PATHS.event)
    return sidisBaseAxios.delete<null, ResponseType<null>>(
      `${FIELD_PATHS.event}/${id}`
    )
  },
  getById: (id: string | number) => {
    return sidisBaseAxios.get(`${FIELD_PATHS.event}/${id}`)
  },
  getList: async (params: EventQueryParams) => {
    const queryString = convertQueryParams(params)
    const url = `${FIELD_PATHS.event}?${queryString}`

    return CachedSystem.withCached(PREFIX + url, () => sidisBaseAxios.get(url))
  },
  getEventConditions: () => {
    return sidisBaseAxios.get(`${FIELD_PATHS.field_condition}`)
  },
  getEventOperators: () => {
    return sidisBaseAxios.get(`${FIELD_PATHS.field_operator}`)
  },
  getDataTypeEvent: () => {
    return sidisBaseAxios.get(`${FIELD_PATHS.field_data_type}`)
  },
  countUserByEvent: (data: any) => {
    return sidisBaseAxios.post(`${FIELD_PATHS.count_user}`, data)
  }
}
