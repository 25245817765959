const data = {
  body: {
    container: {
      style: {
        'background-color': '#ffffff',
        'background-image': 'none',
        'background-position': 'top left',
        'background-repeat': 'no-repeat',
        'background-size': 'auto'
      }
    },
    content: {
      computedStyle: {
        align: 'center',
        linkColor: '#0068A5',
        messageBackgroundColor: 'transparent',
        messageWidth: '600px'
      },
      style: {
        color: '#000000',
        'font-family': 'Arial, Helvetica Neue, Helvetica, sans-serif'
      }
    },
    type: 'mailup-bee-page-properties',
    webFonts: []
  },
  description: '',
  rows: [
    {
      columns: [
        {
          'grid-columns': 2,
          uuid: 'dca1d6e5-5e89-4370-b0fe-1f469e2209fb',
          modules: [
            {
              type: 'mailup-bee-newsletter-modules-image',
              descriptor: {
                image: {
                  alt: '',
                  src: 'https://d1oco4z2z1fhwp.cloudfront.net/templates/default/2461/joblogo.png',
                  href: ''
                },
                style: {
                  width: '100%',
                  'padding-top': '10px',
                  'padding-right': '0px',
                  'padding-bottom': '0px',
                  'padding-left': '0px'
                },
                computedStyle: {
                  class: 'left autowidth',
                  width: '100%',
                  hideContentOnMobile: false
                }
              },
              uuid: '0fafde19-e84a-4920-8b75-6ad106a1e818'
            }
          ],
          style: {
            'background-color': 'transparent',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px'
          }
        },
        {
          'grid-columns': 2,
          uuid: '44601196-1e52-4a29-bf4f-e0cb0a16cd7a',
          modules: [
            {
              type: 'mailup-bee-newsletter-modules-spacer',
              descriptor: {
                spacer: {
                  style: {
                    height: '40px'
                  }
                },
                computedStyle: {
                  hideContentOnMobile: false
                }
              },
              uuid: '369b32d0-8df1-4ba7-bfbb-c25dc276718b'
            }
          ],
          style: {
            'background-color': 'transparent',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px'
          }
        },
        {
          'grid-columns': 2,
          uuid: '9db557da-0d1d-4d84-bfa5-43cfbdf3e14b',
          modules: [
            {
              type: 'mailup-bee-newsletter-modules-spacer',
              descriptor: {
                spacer: {
                  style: {
                    height: '40px'
                  }
                },
                computedStyle: {
                  hideContentOnMobile: false
                }
              },
              uuid: 'fc2635dc-b1d9-46ef-817f-0d8ab3337105'
            }
          ],
          style: {
            'background-color': 'transparent',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px'
          }
        },
        {
          'grid-columns': 2,
          uuid: 'c128cf23-2a7f-46e8-a1a3-d9c0d9664e0e',
          modules: [
            {
              type: 'mailup-bee-newsletter-modules-button',
              descriptor: {
                button: {
                  html: '<p>Find a job</p>',
                  json: {
                    type: 'doc',
                    content: [
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            text: 'Find a job'
                          }
                        ]
                      }
                    ]
                  },
                  href: '',
                  style: {
                    'font-family': 'inherit',
                    'font-weight': '400',
                    'background-color': '#5855bd',
                    'border-radius': '4px',
                    'border-top': '0px solid transparent',
                    'border-right': '0px solid transparent',
                    'border-bottom': '0px solid transparent',
                    'border-left': '0px solid transparent',
                    color: '#ffffff',
                    'line-height': '200%',
                    'padding-top': '10px',
                    'padding-right': '25px',
                    'padding-bottom': '10px',
                    'padding-left': '25px',
                    width: 'auto',
                    'max-width': '100%'
                  }
                },
                style: {
                  'text-align': 'center',
                  'padding-top': '10px',
                  'padding-right': '0px',
                  'padding-bottom': '10px',
                  'padding-left': '0px'
                },
                computedStyle: {
                  width: 86,
                  height: 42,
                  hideContentOnMobile: false
                }
              },
              uuid: 'eb01fa1a-f81c-4af6-ba4a-de685945a8ce'
            }
          ],
          style: {
            'background-color': 'transparent',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px'
          }
        },
        {
          'grid-columns': 2,
          uuid: 'cb745be1-a34a-4599-9089-bb6ac9a21877',
          modules: [
            {
              type: 'mailup-bee-newsletter-modules-button',
              descriptor: {
                button: {
                  html: '<p>Find a job</p>',
                  json: {
                    type: 'doc',
                    content: [
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            text: 'Find a job'
                          }
                        ]
                      }
                    ]
                  },
                  href: '',
                  style: {
                    'font-family': 'inherit',
                    'font-weight': '400',
                    'background-color': '#5855bd',
                    'border-radius': '4px',
                    'border-top': '0px solid transparent',
                    'border-right': '0px solid transparent',
                    'border-bottom': '0px solid transparent',
                    'border-left': '0px solid transparent',
                    color: '#ffffff',
                    'line-height': '200%',
                    'padding-top': '10px',
                    'padding-right': '25px',
                    'padding-bottom': '10px',
                    'padding-left': '25px',
                    width: 'auto',
                    'max-width': '100%'
                  }
                },
                style: {
                  'text-align': 'center',
                  'padding-top': '10px',
                  'padding-right': '0px',
                  'padding-bottom': '10px',
                  'padding-left': '0px'
                },
                computedStyle: {
                  width: 86,
                  height: 42,
                  hideContentOnMobile: false
                }
              },
              uuid: 'c3b5002d-68e5-450b-8e4f-787eaf77bffe'
            }
          ],
          style: {
            'background-color': 'transparent',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px'
          }
        },
        {
          'grid-columns': 2,
          uuid: 'ef51881d-d39e-4688-aa4e-4f9280aa22a0',
          modules: [
            {
              type: 'mailup-bee-newsletter-modules-button',
              descriptor: {
                button: {
                  html: '<p>Find a job</p>',
                  json: {
                    type: 'doc',
                    content: [
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            text: 'Find a job'
                          }
                        ]
                      }
                    ]
                  },
                  href: '',
                  style: {
                    'font-family': 'inherit',
                    'font-weight': '400',
                    'background-color': '#5855bd',
                    'border-radius': '4px',
                    'border-top': '0px solid transparent',
                    'border-right': '0px solid transparent',
                    'border-bottom': '0px solid transparent',
                    'border-left': '0px solid transparent',
                    color: '#ffffff',
                    'line-height': '200%',
                    'padding-top': '10px',
                    'padding-right': '25px',
                    'padding-bottom': '10px',
                    'padding-left': '25px',
                    width: 'auto',
                    'max-width': '100%'
                  }
                },
                style: {
                  'text-align': 'center',
                  'padding-top': '10px',
                  'padding-right': '0px',
                  'padding-bottom': '10px',
                  'padding-left': '0px'
                },
                computedStyle: {
                  width: 86,
                  height: 42,
                  hideContentOnMobile: false
                }
              },
              uuid: 'a110dda6-179c-4229-80c2-d8cf67ff66c3'
            }
          ],
          style: {
            'background-color': 'transparent',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px'
          }
        }
      ],
      container: {
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          'background-size': 'auto'
        }
      },
      content: {
        computedStyle: {
          rowColStackOnMobile: true,
          rowReverseColStackOnMobile: false
        },
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          color: '#000000',
          'background-size': 'auto'
        }
      },
      locked: false,
      type: 'Column2And2And2And2And2And2',
      uuid: 'c384a8dd-f6d9-48db-9456-78e61b40e147'
    },
    {
      columns: [
        {
          'grid-columns': 6,
          uuid: '818f732a-d6cc-4ce4-ba05-b8c726cc7c42',
          modules: [
            {
              type: 'mailup-bee-newsletter-modules-paragraph',
              descriptor: {
                paragraph: {
                  html: '<p><span>Hi Mike,</span></p>',
                  json: {
                    type: 'doc',
                    content: [
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'textStyle',
                                attrs: {
                                  color: ''
                                }
                              }
                            ],
                            text: 'Hi Mike,'
                          }
                        ]
                      }
                    ]
                  },
                  style: {
                    'font-size': '20px',
                    'font-family': 'inherit',
                    'line-height': '120%',
                    'text-align': 'left',
                    direction: 'ltr',
                    'letter-spacing': '0px',
                    color: '#61697a'
                  },
                  computedStyle: {
                    paragraphSpacing: '0px'
                  }
                },
                style: {
                  width: '100%',
                  'text-align': 'left',
                  'padding-top': '0px',
                  'padding-right': '0px',
                  'padding-bottom': '0px',
                  'padding-left': '20px'
                },
                computedStyle: {}
              },
              uuid: 'af19c90f-1d93-4e9b-a34c-4130e4c317b1',
              locked: false
            },
            {
              type: 'mailup-bee-newsletter-modules-heading',
              descriptor: {
                heading: {
                  title: 'h1',
                  html: '<p><span>Welcome Aboard!</span></p>',
                  json: {
                    type: 'doc',
                    content: [
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'textStyle',
                                attrs: {
                                  color: ''
                                }
                              }
                            ],
                            text: 'Welcome Aboard!'
                          }
                        ]
                      }
                    ]
                  },
                  style: {
                    color: '#393d47',
                    'font-size': '38px',
                    'font-family': 'inherit',
                    'line-height': '120%',
                    'text-align': 'left',
                    direction: 'ltr',
                    'font-weight': '700',
                    'letter-spacing': '0px'
                  }
                },
                style: {
                  width: '100%',
                  'text-align': 'center',
                  'padding-top': '20px',
                  'padding-right': '0px',
                  'padding-bottom': '20px',
                  'padding-left': '20px'
                },
                computedStyle: {
                  width: 52,
                  height: 42
                }
              },
              uuid: '1e037025-7e0c-412f-8c29-dd905f412cda',
              locked: false
            },
            {
              type: 'mailup-bee-newsletter-modules-paragraph',
              descriptor: {
                paragraph: {
                  html: '<p><span>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.</span></p>',
                  json: {
                    type: 'doc',
                    content: [
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'textStyle',
                                attrs: {
                                  color: ''
                                }
                              }
                            ],
                            text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.'
                          }
                        ]
                      }
                    ]
                  },
                  style: {
                    'font-size': '24px',
                    'font-family': 'inherit',
                    'line-height': '120%',
                    'text-align': 'left',
                    direction: 'ltr',
                    'letter-spacing': '0px',
                    color: '#393d47'
                  }
                },
                style: {
                  width: '100%',
                  'text-align': 'left',
                  'padding-top': '0px',
                  'padding-right': '0px',
                  'padding-bottom': '0px',
                  'padding-left': '20px'
                },
                computedStyle: {}
              },
              uuid: '0d37742a-664e-4e89-a1e0-393f61c3e38b',
              locked: false
            },
            {
              type: 'mailup-bee-newsletter-modules-button',
              descriptor: {
                button: {
                  html: '<p>Get started</p>',
                  json: {
                    type: 'doc',
                    content: [
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            text: 'Get started'
                          }
                        ]
                      }
                    ]
                  },
                  href: '',
                  style: {
                    'font-family': 'inherit',
                    'font-weight': '400',
                    'background-color': '#5855bd',
                    'border-radius': '4px',
                    'border-top': '0px solid transparent',
                    'border-right': '0px solid transparent',
                    'border-bottom': '0px solid transparent',
                    'border-left': '0px solid transparent',
                    color: '#ffffff',
                    'line-height': '200%',
                    'padding-top': '10px',
                    'padding-right': '25px',
                    'padding-bottom': '10px',
                    'padding-left': '25px',
                    width: 'auto',
                    'max-width': '100%'
                  }
                },
                style: {
                  'text-align': 'left',
                  'padding-top': '10px',
                  'padding-right': '0px',
                  'padding-bottom': '10px',
                  'padding-left': '10px'
                },
                computedStyle: {
                  width: 86,
                  height: 42,
                  hideContentOnMobile: false
                }
              },
              uuid: 'e0d2cc4d-93bf-49fb-9006-9f5ad2fbde46'
            }
          ],
          style: {
            'background-color': 'transparent',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px'
          }
        },
        {
          'grid-columns': 6,
          uuid: '1e8672d9-d268-4538-a391-2afe9182ed41',
          modules: [
            {
              type: 'mailup-bee-newsletter-modules-image',
              descriptor: {
                image: {
                  alt: '',
                  src: 'https://d1oco4z2z1fhwp.cloudfront.net/templates/default/2461/Image_1.png',
                  href: ''
                },
                style: {
                  width: '100%',
                  'padding-top': '0px',
                  'padding-right': '0px',
                  'padding-bottom': '0px',
                  'padding-left': '0px'
                },
                computedStyle: {
                  class: 'center autowidth',
                  width: '100%',
                  hideContentOnMobile: false
                }
              },
              uuid: '7497120b-f732-43bc-a088-566f36b28c57'
            },
            {
              type: 'mailup-bee-newsletter-modules-spacer',
              descriptor: {
                spacer: {
                  style: {
                    height: '60px'
                  }
                },
                computedStyle: {
                  hideContentOnMobile: false
                }
              },
              uuid: 'c2721686-b330-42e7-ae62-d74b11b30f8b'
            }
          ],
          style: {
            'background-color': 'transparent',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px'
          }
        }
      ],
      container: {
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          'background-size': 'auto'
        }
      },
      content: {
        computedStyle: {
          rowColStackOnMobile: true,
          rowReverseColStackOnMobile: false
        },
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          color: '#000000',
          'background-size': 'auto'
        }
      },
      locked: false,
      type: 'Column6And6',
      uuid: 'ba567891-4c5b-4984-90d1-7e707c810a3c'
    },
    {
      columns: [
        {
          'grid-columns': 12,
          uuid: '0b1387a7-d718-4a6d-81f4-0ae0ec9cefed',
          modules: [
            {
              type: 'mailup-bee-newsletter-modules-spacer',
              descriptor: {
                spacer: {
                  style: {
                    height: '40px'
                  }
                },
                computedStyle: {
                  hideContentOnMobile: false
                }
              },
              uuid: '1ca1d66b-7f0d-4450-acaa-d1cc50a2341e'
            },
            {
              type: 'mailup-bee-newsletter-modules-paragraph',
              descriptor: {
                paragraph: {
                  html: '<p><span>JobFind is a user-friendly end-to-end hiring software with a unique</span></p><p><span>focus on creating rich and beautiful job postings.</span></p>',
                  json: {
                    type: 'doc',
                    content: [
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'textStyle',
                                attrs: {
                                  color: ''
                                }
                              }
                            ],
                            text: 'JobFind is a user-friendly end-to-end hiring software with a unique'
                          }
                        ]
                      },
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'textStyle',
                                attrs: {
                                  color: ''
                                }
                              }
                            ],
                            text: 'focus on creating rich and beautiful job postings.'
                          }
                        ]
                      }
                    ]
                  },
                  style: {
                    'font-size': '24px',
                    'font-family': 'inherit',
                    'line-height': '150%',
                    'text-align': 'center',
                    direction: 'ltr',
                    'letter-spacing': '0px',
                    color: '#34495e',
                    'font-weight': '700'
                  }
                },
                style: {
                  width: '100%',
                  'text-align': 'left',
                  'padding-top': '0px',
                  'padding-right': '0px',
                  'padding-bottom': '0px',
                  'padding-left': '0px'
                },
                computedStyle: {}
              },
              uuid: '42d75597-daad-4d4d-8f52-0b0e9272af9d',
              locked: false
            },
            {
              type: 'mailup-bee-newsletter-modules-image',
              descriptor: {
                image: {
                  alt: 'fdsfsdfsdf',
                  src: 'https://d1oco4z2z1fhwp.cloudfront.net/templates/default/2461/middle_Image.png',
                  href: ''
                },
                style: {
                  width: '100%',
                  'padding-top': '0px',
                  'padding-right': '0px',
                  'padding-bottom': '0px',
                  'padding-left': '0px'
                },
                computedStyle: {
                  class: 'center fixedwidth',
                  width: '60%',
                  hideContentOnMobile: false
                }
              },
              uuid: '1fd92093-9974-449c-93f8-f88b513c5bdd'
            },
            {
              type: 'mailup-bee-newsletter-modules-spacer',
              descriptor: {
                spacer: {
                  style: {
                    height: '60px'
                  }
                },
                computedStyle: {
                  hideContentOnMobile: false
                }
              },
              uuid: 'cf641380-e0e9-47b7-b570-0e50dfe4e1c5'
            },
            {
              type: 'mailup-bee-newsletter-modules-paragraph',
              descriptor: {
                paragraph: {
                  html: '<p><span>3 easy steps to post your job and</span></p><p><span>find your next superstar</span></p>',
                  json: {
                    type: 'doc',
                    content: [
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'textStyle',
                                attrs: {
                                  color: ''
                                }
                              }
                            ],
                            text: '3 easy steps to post your job and'
                          }
                        ]
                      },
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'textStyle',
                                attrs: {
                                  color: ''
                                }
                              }
                            ],
                            text: 'find your next superstar'
                          }
                        ]
                      }
                    ]
                  },
                  style: {
                    'font-size': '24px',
                    'font-family': 'inherit',
                    'line-height': '150%',
                    'text-align': 'center',
                    direction: 'ltr',
                    'letter-spacing': '0px',
                    color: '#34495e',
                    'font-weight': '700'
                  }
                },
                style: {
                  width: '100%',
                  'text-align': 'left',
                  'padding-top': '0px',
                  'padding-right': '0px',
                  'padding-bottom': '0px',
                  'padding-left': '0px'
                },
                computedStyle: {}
              },
              uuid: '15ce2696-c2b3-438c-835b-c20168e0bed5',
              locked: false
            }
          ],
          style: {
            'background-color': 'transparent',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px'
          }
        }
      ],
      container: {
        style: {
          'background-color': 'transparent',
          'background-image':
            "url('https://d1oco4z2z1fhwp.cloudfront.net/templates/default/2461/Background.png')",
          'background-position': 'top center',
          'background-repeat': 'no-repeat',
          'background-size': 'auto'
        }
      },
      content: {
        computedStyle: {
          rowColStackOnMobile: true,
          rowReverseColStackOnMobile: false
        },
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          color: '#000000',
          'background-size': 'auto'
        }
      },
      locked: false,
      type: 'Column12',
      uuid: '5f407b07-b9a0-40c6-bdb2-935d35163ab0'
    },
    {
      columns: [
        {
          'grid-columns': 6,
          uuid: '6ccca79a-231d-4a62-a3fc-364b307b40dc',
          modules: [
            {
              type: 'mailup-bee-newsletter-modules-image',
              descriptor: {
                image: {
                  alt: '',
                  src: 'https://d1oco4z2z1fhwp.cloudfront.net/templates/default/2461/search.png',
                  href: ''
                },
                style: {
                  width: '100%',
                  'padding-top': '0px',
                  'padding-right': '0px',
                  'padding-bottom': '0px',
                  'padding-left': '0px'
                },
                computedStyle: {
                  class: 'center autowidth',
                  width: '100%',
                  hideContentOnMobile: false
                }
              },
              uuid: 'dc984314-6887-4efc-bb69-84805d292cae'
            }
          ],
          style: {
            'background-color': 'transparent',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px'
          }
        },
        {
          'grid-columns': 6,
          uuid: '42b9396e-b0b8-4363-8357-852b6c92194f',
          modules: [
            {
              type: 'mailup-bee-newsletter-modules-heading',
              descriptor: {
                heading: {
                  title: 'h1',
                  html: '<p><span>Job Post</span></p>',
                  json: {
                    type: 'doc',
                    content: [
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'textStyle',
                                attrs: {
                                  color: ''
                                }
                              }
                            ],
                            text: 'Job Post'
                          }
                        ]
                      }
                    ]
                  },
                  style: {
                    color: '#393d47',
                    'font-size': '38px',
                    'font-family': 'inherit',
                    'line-height': '120%',
                    'text-align': 'center',
                    direction: 'ltr',
                    'font-weight': '700',
                    'letter-spacing': '0px'
                  }
                },
                style: {
                  width: '100%',
                  'text-align': 'center',
                  'padding-top': '60px',
                  'padding-right': '0px',
                  'padding-bottom': '20px',
                  'padding-left': '0px'
                },
                computedStyle: {
                  width: 52,
                  height: 42
                }
              },
              uuid: 'c4227526-7c71-4b19-9d0b-2b2a1914ed14',
              locked: false
            },
            {
              type: 'mailup-bee-newsletter-modules-paragraph',
              descriptor: {
                paragraph: {
                  html: '<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean <u class="hhg-underline">commodo</u> ligula eget <u class="hhg-underline">dolor</u>. Aenean massa. Cum sociis natoque <em>penatibus</em> et <span style="color: #335ba2"><mark data-color="#ebebeb" style="background-color: #ebebeb">magnis</mark></span> dis <s>parturient</s> montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,</p>',
                  json: {
                    type: 'doc',
                    content: [
                      {
                        type: 'paragraph',
                        content: [
                          {
                            type: 'text',
                            text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean '
                          },
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'underline'
                              }
                            ],
                            text: 'commodo'
                          },
                          {
                            type: 'text',
                            text: ' ligula eget '
                          },
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'underline'
                              }
                            ],
                            text: 'dolor'
                          },
                          {
                            type: 'text',
                            text: '. Aenean massa. Cum sociis natoque '
                          },
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'italic'
                              }
                            ],
                            text: 'penatibus'
                          },
                          {
                            type: 'text',
                            text: ' et '
                          },
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'textStyle',
                                attrs: {
                                  color: '#335ba2'
                                }
                              },
                              {
                                type: 'highlight',
                                attrs: {
                                  color: '#ebebeb'
                                }
                              }
                            ],
                            text: 'magnis'
                          },
                          {
                            type: 'text',
                            text: ' dis '
                          },
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'strike'
                              }
                            ],
                            text: 'parturient'
                          },
                          {
                            type: 'text',
                            text: ' montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,'
                          }
                        ]
                      }
                    ]
                  },
                  style: {
                    'font-size': '18px',
                    'font-family': 'inherit',
                    'line-height': '180%',
                    'text-align': 'center',
                    direction: 'ltr',
                    'letter-spacing': '0px',
                    color: '#555555'
                  }
                },
                style: {
                  width: '100%',
                  'text-align': 'left',
                  'padding-top': '0px',
                  'padding-right': '0px',
                  'padding-bottom': '0px',
                  'padding-left': '0px'
                },
                computedStyle: {}
              },
              uuid: 'f0b04208-fd6f-4e98-9e01-10e04514c67f',
              locked: false
            }
          ],
          style: {
            'background-color': 'transparent',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px'
          }
        }
      ],
      container: {
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          'background-size': 'auto'
        }
      },
      content: {
        computedStyle: {
          rowColStackOnMobile: true,
          rowReverseColStackOnMobile: false
        },
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          color: '#000000',
          'background-size': 'auto'
        }
      },
      locked: false,
      type: 'Column6And6',
      uuid: '8f66eb23-55a2-4163-bc15-fc80f305976a'
    }
  ],
  template: {
    name: 'template-base',
    type: 'basic',
    version: '2.0.0'
  },
  title: ''
}

export const initTemplateBodyJson = {
  body: {
    container: {
      style: {
        'background-color': 'transparent',
        'background-image': 'none',
        'background-position': 'top left',
        'background-repeat': 'no-repeat',
        'background-size': 'auto'
      }
    },
    content: {
      computedStyle: {
        align: 'center',
        linkColor: '#0068A5',
        messageBackgroundColor: 'transparent',
        messageWidth: '600px'
      },
      style: {
        color: '#000000',
        'font-family':
          "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif"
      }
    },
    type: 'mailup-bee-page-properties',
    webFonts: []
  },
  description: '',
  rows: [
    {
      columns: [
        {
          'grid-columns': 12,
          uuid: 'dec74a6f-12ed-4cfd-ad18-6e06bcc21cd7',
          modules: [],
          style: {
            'background-color': 'transparent',
            'border-top': '0px solid transparent',
            'border-right': '0px solid transparent',
            'border-bottom': '0px solid transparent',
            'border-left': '0px solid transparent',
            'padding-top': '0px',
            'padding-right': '0px',
            'padding-bottom': '0px',
            'padding-left': '0px'
          }
        }
      ],
      container: {
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat'
        }
      },
      content: {
        computedStyle: {
          rowColStackOnMobile: true,
          rowReverseColStackOnMobile: false
        },
        style: {
          'background-color': 'transparent',
          'background-image': 'none',
          'background-position': 'top left',
          'background-repeat': 'no-repeat',
          color: '#000000'
        }
      },
      locked: false,
      type: 'Column12',
      uuid: '28c77a40-818c-4078-b600-113e5f449184'
    }
  ],
  template: {
    name: 'template-base',
    type: 'basic',
    version: '2.0.0'
  },
  title: ''
}

export default data
