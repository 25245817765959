export const theme: ThemeProps = {
  colors: {
    primary: '#2685F1',
    primaryPink: '#E55C8A',
    secondary: '#284A75',
    secondaryDark: '#1B3250',
    ghost: '#E4E8EC',
    success: '#00B16A',
    warning: '#FE921D',
    error: '#F44D2C',
    info: '#2685F1',
    white: '#fff',
    black: '#000',
    // blue
    blue50: '#E3F2FF',
    blue100: '#BCDEFF',
    blue200: '#91CAFF',
    blue300: '#65B5FF',
    blue400: '#45A4FF',
    blue500: '#2B94FF',
    blue600: '#2D87F3',
    blue700: '#2C74DF',
    blue800: '#2B62CD',
    blue900: '#2743AD',
    // yellow
    yellow50: '#FFF8E2',
    yellow100: '#FFECB6',
    yellow200: '#FFE187',
    yellow300: '#FFD658',
    yellow400: '#FFCB37',
    yellow500: '#FFC326',
    yellow600: '#FFB521',
    yellow700: '#FEA21E',
    yellow800: '#FE921D',
    yellow900: '#FC7419',
    // green
    green50: '#E0F8EE',
    green100: '#B3EED5',
    green200: '#7CE2B9',
    green300: '#2ED69E',
    green400: '#00CB8A',
    green500: '#00C076',
    green600: '#00B16A',
    green700: '#009E5C',
    green800: '#008C50',
    green900: '#006C39',
    // teal
    teal50: '#E0F7FC',
    teal100: '#B1EBF8',
    teal200: '#7DDFF3',
    teal300: '#44D1EC',
    teal400: '#00C7E6',
    teal500: '#00BEE0',
    teal600: '#00AECD',
    teal700: '#0099B2',
    teal800: '#008599',
    teal900: '#00626C',
    // red
    red50: '#FAE9E8',
    red100: '#FECCBF',
    red200: '#FEAA96',
    red300: '#FF886C',
    red400: '#FF6F4D',
    red500: '#FF5331',
    red600: '#F44D2C',
    red700: '#E64728',
    red800: '#D84023',
    red900: '#BF331B',
    // neutral
    neutral50: '#F7F9FC',
    neutral100: '#E4E8EC',
    neutral200: '#D2D6DC',
    neutral300: '#BFC5CC',
    neutral400: '#ADB3BC',
    neutral500: '#9AA2AC',
    neutral600: '#87909C',
    neutral700: '#757F8C',
    neutral800: '#626D7C',
    // neutral900:"",
    // redViolet
    redViolet50: '#F3EAEB',
    redViolet100: '#E8D5D7',
    redViolet200: '#DDC1C4',
    redViolet300: '#D1ACB0',
    redViolet400: '#C5979C',
    redViolet500: '#BA8288',
    redViolet600: '#AF6D74',
    redViolet700: '#A35961',
    redViolet800: '#97444D',
    redViolet900: '#8C2F39',
    // pink
    pink50: '#FFF1F6',
    pink100: '#FFDCE9',
    pink200: '#FEC7DC',
    pink300: '#FEB2CF',
    pink400: '#FE9DC2',
    pink500: '#FD87B4',
    pink600: '#FD72A7',
    pink700: '#FC5D9A',
    pink800: '#FC488D',
    pink900: '#E92D74',
    // gray
    gray50: '#FAFAFA',
    gray100: '#F2F2F2',
    gray200: '#D9D9D9',
    gray300: '#BFBFBF',
    gray400: '#8C8C8C',
    gray500: '#737373',
    gray600: '#595959',
    gray700: '#404040',
    gray800: '#262626',
    // gray900:"",
    // violet
    violet50: '#F5EDFF',
    violet100: '#E7D2F7',
    violet200: '#D8B7F0',
    violet300: '#CA9CE8',
    violet400: '#BB82E1',
    violet500: '#AD67D9',
    violet600: '#9E4CD1',
    violet700: '#9031CA',
    violet800: '#8116C2'
    // violet900: "",
  },
  mbColors: {},
  sizes: {},
  zIndexes: {},
  defaultColor: '#2D87F3',
  borderRadiusBase: '6px'
}
