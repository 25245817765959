import { CampaignMessagingInsightFilterValue } from '../CampaignMessagingInsightFilter'
import {
  CAMPAIGN_MESSAGING_INSIGHT_OVERVIEW_ITEMS,
  CampaignMessagingInsightTabOverviewItem,
  getCampaignMessagingInsightOverview
} from './CampaignMessagingInsightTabOverview.helpers'
import { StyledCampaignMessagingInsightItem } from './CampaignMessagingInsightTabOverview.styled'
import Icon from '@ant-design/icons'
import { Divider, Empty, Progress, Spin } from 'antd'
import cn from 'classnames'
import { NoDataFolder } from 'icons'
import {
  Ref,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState
} from 'react'
import { formatNumberToCurrency } from 'utils'

export { CAMPAIGN_MESSAGING_INSIGHT_OVERVIEW_ITEMS }
export type { CampaignMessagingInsightTabOverviewItem }
export type CampaignMessagingInsightTabOverviewRef = {
  refresh?: (value: CampaignMessagingInsightFilterValue) => void
}
export type CampaignMessagingInsightTabOverviewProps = {
  params?: CampaignMessagingInsightFilterValue
}
const CampaignMessagingInsightTabOverviewWithoutRef = (
  props: CampaignMessagingInsightTabOverviewProps,
  ref?: Ref<CampaignMessagingInsightTabOverviewRef>
) => {
  const { params } = props
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState<CampaignMessagingInsightTabOverviewItem[]>(
    []
  )

  const refresh = useCallback(
    async (input: CampaignMessagingInsightFilterValue) => {
      setLoading(true)
      const { data } = await getCampaignMessagingInsightOverview(
        input || params
      )
      if (data) {
        setItems(data)
      }
      setLoading(false)
    },
    [params]
  )

  useImperativeHandle(ref, () => ({ refresh }), [refresh])

  if (!items || !items?.length) {
    return (
      <Empty
        image={<Icon component={NoDataFolder} className="text-[80px]" />}
        imageStyle={{ height: 'auto' }}
        className="py-28"
      />
    )
  }

  return (
    <Spin spinning={loading}>
      <div className="flex flex-wrap justify-between -m-2">
        {items.map((itemComp, index) => {
          return (
            <StyledCampaignMessagingInsightItem
              key={`${itemComp.key}_${index}`}
              className="w-1/3 p-2 last:mr-auto">
              <div
                className={cn(
                  'h-full bg-white border border-solid border-neutral100 rounded-[4px] pt-3 px-4 pb-4'
                )}>
                <div className="flex items-center">
                  <Icon
                    component={itemComp?.icon}
                    className="mr-2 w-[32px] h-[32px] flex justify-center items-center rounded-full text-[16px] text-gray800 bg-neutral-50 text-center"
                  />
                  <h5 className="m-0 flex-1 min-w-0 text-[16px] text-gray800 font-semibold">
                    {itemComp?.label}
                  </h5>
                </div>
                <Divider className="!mb-0" />
                {typeof itemComp.value === 'number' && (
                  <span className="font-bold text-gray800">
                    {formatNumberToCurrency(itemComp.value)}
                    {itemComp.unit}
                  </span>
                )}
                {Array.isArray(itemComp.value) &&
                  itemComp.value.map((itemCompValue: any, idx) => {
                    return (
                      <div key={`${idx}`} className="mt-4 leading-none">
                        <div className="flex justify-between">
                          {itemCompValue?.label}
                          <span className="font-bold text-gray800">
                            {formatNumberToCurrency(itemCompValue?.value || 0)}
                            {itemComp.unit}
                          </span>
                        </div>
                        <Progress
                          percent={itemCompValue?.value}
                          size="small"
                          strokeWidth={4}
                          showInfo={false}
                          className="!text-[4px] !leading-none"
                        />
                      </div>
                    )
                  })}
              </div>
            </StyledCampaignMessagingInsightItem>
          )
        })}
      </div>
    </Spin>
  )
}

export const CampaignMessagingInsightTabOverview = forwardRef(
  CampaignMessagingInsightTabOverviewWithoutRef
)
