import { TagGroup } from 'components/atoms/tag'
import {
  CAMPAIGN_APPROACH_OPTIONS,
  CAMPAIGN_TIMEFRAME_OPTIONS
} from 'constants/campaign'
import { WEB_PAGE } from 'constants/site'
import { theme } from 'constants/theme'
import { OptionTypeSite } from 'interfaces'
import { renderTemplateStatus } from 'pages/templateBuilderList/helpers'
import { segmentServices } from 'services/segment'
import { TemplateServices } from 'services/template'
import { formatNumberToCurrency } from 'utils'

export const fetchCampaignDrawerSegments = async (
  segment_ids?: Array<string | number>
) => {
  if (!segment_ids?.length) {
    return []
  }
  const response = await Promise.all(
    segment_ids.map(
      (id) =>
        new Promise(async (resolve, reject) => {
          if (!id) {
            reject({ data: null, errors: ['Not valid ID'] })
          }
          try {
            const res: any = await segmentServices.getSegment(id)
            if (res?._message || !res.data) {
              throw new Error(JSON.stringify(res))
            }

            resolve({ data: res.data, errors: [] })
          } catch (error) {
            reject({ data: null, errors: ['Not found'] })
          }
        })
    )
  )
  return response
    .filter(({ errors }: any) => !errors.length)
    .map(({ data }: any) => data)
}

export const fetchCampaignDrawerTemplate = async (
  template_id?: string | number
) => {
  if (typeof template_id === 'undefined' || !template_id || template_id === 0) {
    return {}
  }
  try {
    const response = await TemplateServices.getById(template_id)
    if (!response.data) {
      throw new Error(JSON.stringify(response))
    }
    return response.data.data || {}
  } catch (error) {
    return {}
  }
}

export const getCampaignDrawerSettings = (input: any) => {
  const settings = [{ label: 'Description', content: input?.description }]
  try {
    if (input?.approach) {
      const approach = CAMPAIGN_APPROACH_OPTIONS.find(
        ({ value }) => value === input.approach
      )
      settings.push({
        label: 'Approach',
        content: approach?.label || ''
      })
    }
    settings.push({ label: 'Timezone', content: input?.time_zone })
    if (input?.type) {
      const type = CAMPAIGN_TIMEFRAME_OPTIONS.find(
        ({ value }) => value === input.type
      )
      settings.push({ label: 'Timeframe', content: type?.label || '' })
    }
  } catch (error) {}
  return settings
}

export const getCampaignDrawerSite = (input: any, sites: OptionTypeSite[]) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const site = []
  try {
    if (input?.sites && input?.sites?.[0]) {
      const curSite = sites.find((site) => site.value === input.sites[0])
      if (curSite && curSite.domain in WEB_PAGE) {
        site.push({
          label: 'Platform',
          content:
            WEB_PAGE[curSite.domain as unknown as keyof typeof WEB_PAGE] || ''
        })
      }
    }
  } catch (error) {}
  return site
}

const getCampaignDrawerSegment = (input: any) => {
  const numberOfUser =
    input?.count_data?.reduce((total: number, item: any) => {
      return total + (item?.totalCount || 0)
    }, 0) || 0
  const segment = [
    { label: 'Name', content: input?.name || '' },
    { label: 'Description', content: input?.description || '' },
    { label: 'ID', content: input?.id || '' },
    {
      label: 'Created by',
      content: `${input?.created_by?.first_name} ${input?.created_by?.last_name}`
    },
    {
      label: 'Label',
      content: input?.tags && input.tags?.length && (
        <TagGroup
          maxTagsCount={4}
          tags={input.tags?.map(({ name }: any) => ({
            label: name,
            color: theme.colors.blue50,
            className: 'text-blue-900'
          }))}
        />
      )
    },
    {
      label: 'User',
      content: formatNumberToCurrency(numberOfUser)
    }
  ]
  return segment
}
export const getCampaignDrawerSegments = (input?: any) => {
  if (input?.segment && input.segment?.length) {
    const segment = input.segment
      .map(getCampaignDrawerSegment)
      .flatMap((x: any) => [...x])
    return segment
  }
  return []
}

export const getCampaignDrawerTemplate = (input: any) => {
  if (input?.template && Object.keys(input?.template).length) {
    const templateObject = input.template?.template || {}
    const template = [
      { label: 'Name', content: templateObject?.name || '' },
      { label: 'ID', content: templateObject?.id || '' },
      {
        label: 'Status',
        content: renderTemplateStatus(templateObject)
      }
    ]
    if (templateObject?.type === 'email') {
      template.push({
        label: 'From email',
        content: input.template?.from_email
      })
    }
    template.push({
      label: 'Country',
      content: templateObject?.country_code?.toUpperCase()
    })

    return template
  }
  return []
}
