import { Box } from '../box'
import { StyledFormHeader } from './FormHeader.styled'
import { FormHeaderTags } from './FormHeaderTags'
import { Button } from 'antd'
import Form, { FormInstance } from 'antd/lib/form'
import Input from 'antd/lib/input'
import Skeleton from 'antd/lib/skeleton'
import cn from 'classnames'
import { useTags } from 'hooks/useTags'
import { GenerativeAI } from 'icons'
import { CSSProperties, useCallback, useMemo } from 'react'
import { getFormNamePath } from 'utils/form'

export type FormHeaderValue = {
  title: string | null
  description: string | null
  tag_ids: number[] | null
}
export type FormHeaderProps = {
  id?: string
  form?: FormInstance
  loading?: boolean
  disabled?: boolean
  rootName?: string
  name?: string

  className?: string
  style?: CSSProperties
  onClick?: () => void
}
export const FormHeader = (props: FormHeaderProps) => {
  const {
    id,
    form: formProps,
    loading,
    disabled,
    name,
    className,
    style,
    onClick
  } = props
  const [form] = Form.useForm(formProps)
  const tag_ids = Form.useWatch(getFormNamePath(name, 'tag_ids'), form)
  const { loading: loadingTag, tags, createTag, setTags } = useTags()

  const tagOptions = useMemo(() => {
    return tags?.map(({ id, name }) => ({ value: id, label: name }))
  }, [tags])

  const onCreate = useCallback(
    async (tagName: string) => {
      try {
        const { data, errors } = await createTag({
          name: tagName,
          type: 'segment'
        })
        if (errors.length) {
          return
        }
        setTags((tags) => [...tags, data].slice(0))
        setTimeout(() => {
          const newTagIds = tag_ids ? [...tag_ids, data.id] : [data.id]
          form.setFieldValue(getFormNamePath(name, 'tag_ids'), newTagIds)
        }, 10)
      } catch (error) {}
    },
    [createTag, form, name, setTags, tag_ids]
  )

  return (
    <StyledFormHeader id={id} className={className} style={style}>
      <div className="formHeader--content">
        <Skeleton active loading={loading} paragraph={{ rows: 2 }}>
          <Form.Item
            name={getFormNamePath(name, 'name')}
            rules={[{ required: true, message: 'Name is required' }]}
            noStyle>
            <Input className="formHeader--title" placeholder="Untitled" />
          </Form.Item>
          <Form.Item name={getFormNamePath(name, 'tag_ids')} noStyle>
            <FormHeaderTags
              showCreate
              loading={loadingTag}
              disabled={disabled}
              size="small"
              options={tagOptions}
              onCreate={onCreate}
              className={cn('formHeader--tags', {
                '!m-0': disabled && !tag_ids?.length
              })}
              addButtonProps={{ id: 'formHeader--add-tags' }}
            />
          </Form.Item>
          <Form.Item name={getFormNamePath(name, 'description')} noStyle>
            <Input.TextArea
              className="formHeader--description"
              placeholder="Add description"
              autoSize={{ minRows: 1, maxRows: 3 }}
            />
          </Form.Item>
        </Skeleton>
      </div>
      <div className="formHeader--button ml-auto">
        <Box
          className="bg-gradient p-[1px] rounded-[8px]"
          data-disabled={disabled}>
          <Button
            size="small"
            onClick={onClick}
            loading={loading}
            disabled={disabled}
            className="flex items-center rounded-[8px] py-[8px] pl-[12px] pr-[15px] text-[#595959] gap-[4px] whitespace-nowrap border-0">
            <GenerativeAI /> <span>Generate</span>
          </Button>
        </Box>
      </div>
    </StyledFormHeader>
  )
}
