import { Button, Tabs } from 'antd'
import styled from 'styled-components'

export const StyledTitle = styled.h3`
  margin-bottom: 0;
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.4px;
`

export const StyledTimezone = styled.div<{ active?: boolean }>`
  font-weight: ${({ active }) => (active ? 700 : 400)};
  padding-left: 5px;
  padding-right: 5px;
  align-self: center;
  cursor: pointer;
`

export const StyledHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &.segmented {
    margin-top: 16px;
    align-items: flex-end;
    justify-content: space-between;
  }

  ${StyledTitle} {
    flex: 1;
    min-width: 0;
  }
`

export const StyledBox = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  background-color: #fff;
  border-radius: 8px;
`

export const StyledCampaignMessagingInsightTabAudienceButton = styled(Button)`
  padding: 0;
  color: ${({ theme }) => theme?.colors?.gray600};
  width: 24px;
  height: 24px;
  .anticon {
    font-size: 24px;
  }
`

export const StyledCampaignMessagingInsightTabAudienceTab = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    .ant-tabs-nav-list {
      border: none;
      .ant-tabs-tab {
        padding-bottom: 0;
      }
    }
  }
`

export const StyledCampaignMessagingInsightTabAudience = styled.div``
