import Checkbox from 'antd/lib/checkbox'
import styled, { css } from 'styled-components'
import { cssScrollBar } from 'styles'

export const cssTagsItem = css`
  display: inline-flex;
  align-items: center;
  height: auto;
  padding: 0 0.5rem;
  margin: 0;
  border-radius: 0.5rem;
  font-size: var(--font-size);
  font-weight: 400;
  line-height: var(--line-height);
  border: 1px solid ${({ theme }) => theme?.colors?.blue100};
  background-color: ${({ theme }) => theme?.colors?.blue50};
`
export const StyledFormHeaderTags = styled.div`
  --font-size: 14px;
  --line-height: 22px;

  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 0.375rem;

  &.disabled {
    opacity: 0.9;
  }

  span {
    display: inline-flex;
    align-items: center;
    font-size: var(--font-size);
    line-height: var(--line-height);
  }

  .ant-btn {
    ${cssTagsItem}
    border-color: ${({ theme }) => theme?.colors?.gray200};
    background-color: white;
    > .anticon {
      font-size: 1rem;
      line-height: 0;
      + span {
        margin-left: 0.25rem;
      }
    }
  }

  .formHeaderTags--item {
    ${cssTagsItem}
    .formHeaderTags--item-icon {
      font-size: 1rem;
      line-height: 0;
      margin-left: 0.25rem;
    }
    .formHeaderTags--item-label {
      color: ${({ theme }) => theme?.colors?.blue900};
    }
  }
`

export const StyledFormHeaderTagsDropdownCheckboxGroup = styled(Checkbox.Group)`
  display: block;
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0.325rem 0.75rem;
    .ant-checkbox {
      top: 0;
    }
  }
`

export const StyledFormHeaderTagsDropdown = styled.div`
  min-width: 240px;
  max-width: 300px;
  max-height: 378px;
  padding-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0px 8px 40px 0px #0000001a;

  .formHeaderTags--dropdown-search {
    padding: 0.75rem 0.75rem 0.5rem;
  }
  .formHeaderTags--dropdown-list {
    flex: 1;
    min-height: 0;
    overflow: overlay;
    ${cssScrollBar}
  }

  .formHeaderTags--dropdown-item {
    padding: 0.25rem 0.75rem;
    &:has(.ant-checkbox-wrapper-checked) {
      background-color: ${({ theme }) => theme?.colors?.blue50};
    }
  }

  .formHeaderTags--dropdown-add {
    padding: 0.325rem 0.75rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0.5rem;
    border-color: transparent;
    background-color: ${({ theme }) => theme?.colors?.neutral50};
    .anticon {
      font-size: 1.25rem;
      line-height: 0;
    }
  }
`
