import {
  StyleSegmentPredictionDrawer,
  StyledSegmentPredictionButton,
  StyledSegmentPredictionButtonChildren
} from './SegmentPredictionDrawer.styled'
import Icon from '@ant-design/icons'
import cn from 'classnames'
import {
  SEGMENT_PREDICTION_VALUES,
  SegmentPrediction,
  SegmentPredictionProps,
  SegmentPredictionRef,
  SegmentPredictionValue
} from 'components/molecules/segment/SegmentPrediction'
import {
  SEGMENT_CONDITION_OPERATOR,
  SEGMENT_CONDITION_TYPE
} from 'constants/segment'
import { Collapse, Expand, ExplorePrediction } from 'icons/V2'
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

const SEGMENT_DRAWER_MIN_WIDTH = 888
export const defaultValueSegmentPrediction: SegmentPredictionValue = {
  field: 'segment_prediction_range',
  operator: SEGMENT_CONDITION_OPERATOR.BETWEEN,
  type: SEGMENT_CONDITION_TYPE.number,
  value: [...SEGMENT_PREDICTION_VALUES.TOP_20]
}
export type SegmentPredictionDrawerProps = Pick<
  SegmentPredictionProps,
  | 'loading'
  | 'data'
  | 'disabled'
  | 'defaultValue'
  | 'value'
  | 'onChange'
  | 'onFinish'
> & {
  open?: boolean
  onClose?: (open: boolean) => void
}
export const SegmentPredictionDrawer = (
  props: PropsWithChildren<SegmentPredictionDrawerProps>
) => {
  const {
    children: childrenProps,
    loading,
    data,
    disabled,
    defaultValue,
    value,
    onFinish: onFinishProps,
    onChange,
    open: openProps,
    onClose: onCloseProps
  } = props
  const [open, setOpen] = useState(openProps)
  const [isFullWidth, setIsFullWidth] = useState(false)
  const form = useRef<SegmentPredictionRef | null>(null)

  const iconFullScreen = <Icon component={isFullWidth ? Collapse : Expand} />
  const onOpen = useCallback(() => {
    if (disabled) {
      return
    }
    const newOpen = !open
    setOpen(newOpen)
    onCloseProps?.(newOpen)
  }, [disabled, onCloseProps, open])

  const children = useMemo(() => {
    if (childrenProps) {
      return (
        <StyledSegmentPredictionButtonChildren
          onClick={onOpen}
          className={cn({ disabled })}>
          {childrenProps}
        </StyledSegmentPredictionButtonChildren>
      )
    }

    return (
      <StyledSegmentPredictionButton
        type="primary"
        disabled={disabled}
        icon={<Icon component={ExplorePrediction} />}
        onClick={onOpen}>
        Explore Prediction
      </StyledSegmentPredictionButton>
    )
  }, [childrenProps, disabled, onOpen])

  const onClose = () => {
    setOpen(false)
    onCloseProps?.(false)
  }

  const onFinish = (values: any) => {
    onFinishProps?.(values)
  }

  useEffect(() => {
    if (open) {
      form.current?.onChange(defaultValue || defaultValueSegmentPrediction)
    }
  }, [open])

  useEffect(() => {
    setOpen(openProps)
  }, [openProps])

  return (
    <>
      {children}
      <StyleSegmentPredictionDrawer
        width={isFullWidth ? '100%' : SEGMENT_DRAWER_MIN_WIDTH}
        open={open}
        onClose={onClose}
        afterOpenChange={(open) => {
          if (!open) {
            form.current?.reset()
          }
        }}
        headerStyle={{ display: 'none' }}>
        <SegmentPrediction
          ref={form}
          loading={loading}
          data={data}
          disabled={disabled}
          defaultValue={defaultValue || defaultValueSegmentPrediction}
          value={value}
          onFinish={onFinish}
          onChange={onChange}
          onClose={onClose}
          buttonFullScreenProps={{ icon: iconFullScreen }}
          onFullScreen={() => setIsFullWidth(!isFullWidth)}
        />
      </StyleSegmentPredictionDrawer>
    </>
  )
}
