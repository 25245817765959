import { menuRoutes } from 'components/molecules/layout'
import { SelectTemplateItem } from 'components/molecules/templateBuilder'
import { FORMAT_DATE_DAYJS_API, FORMAT_DATE_TIME_DAYJS } from 'constants/common'
import { isProduction } from 'constants/env'
import { PERMISSIONS } from 'constants/permission'
import { TEMPLATE_TYPE } from 'constants/template'
import dayjs, { Dayjs } from 'dayjs'
import {
  AppPush,
  Email,
  Screen,
  ThumbnailCreateAppPush,
  ThumbnailCreateDefault,
  ThumbnailCreateEmail,
  ThumbnailCreateLandingPage,
  ThumbnailCreateSMS,
  ThumbnailCreateWebPush,
  ThumbnailCreateWhatsApp,
  TwilioSMS,
  WebPush,
  WhatsappThin
} from 'icons'
import { ZaloLogo } from 'icons/V2'
import { ReactNode } from 'react'

export type CampaignHeaderValue = {
  approach?: string | null
  search?: string
  columns?: string[]
  site_id?: string | null
  type?: string
  created_from?: string
  created_to?: string
  status?: string[]
  template_type?: string
  template_types?: string[]
  send_time_from?: string
  send_time_to?: string
}

export type CampaignHeaderState = Omit<
  CampaignHeaderValue,
  'created_from' | 'created_to' | 'send_time_from' | 'send_time_to'
> & {
  created?: [Dayjs, Dayjs] | null
  send_time?: [Dayjs, Dayjs] | null
}

export const tabCampaign = [
  { label: 'All', key: 'all', children: null },
  // { label: 'Automation', key: 'automation', children: null }, HHS-1675
  { label: 'Email', key: TEMPLATE_TYPE.EMAIL, children: null },
  { label: 'App Push', key: TEMPLATE_TYPE.APP_PUSH, children: null },
  { label: 'Web Push', key: TEMPLATE_TYPE.WEB_PUSH, children: null },
  isProduction
    ? null
    : { label: 'Landing Page', key: TEMPLATE_TYPE.LP_PAGE, children: null },
  { label: 'WhatsApp', key: TEMPLATE_TYPE.WHATSAPP, children: null },
  { label: 'SMS', key: 'sms', children: null },
  { label: 'Zalo', key: TEMPLATE_TYPE.ZALO, children: null }
].filter(Boolean) as { label: string; key: string; children: ReactNode }[]

export const menuCreateCampaign = [
  {
    key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.EMAIL}/create`,
    label: 'Email',
    permissions: [PERMISSIONS.campaign_email_create],
    disabled: false,
    icon: <Email className="text-[#2D87F3] w-6 h-6 block" />,
    iconBg: 'bg-[#E3F2FF]',
    thumbnail: <ThumbnailCreateEmail className="w-full h-auto" />
  },
  // HHS-1675
  // {
  //   key: `${menuRoutes.campaign}/automation/create`,
  //   label: 'Automation Campaign',
  //   disabled: false
  // },
  {
    key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.APP_PUSH}/create`,
    label: 'App Push',
    permissions: [PERMISSIONS.campaign_app_push_create],
    disabled: false,
    icon: <AppPush className="text-[#FE921D] w-6 h-6 block" />,
    iconBg: 'bg-[#FFF8E2]',
    thumbnail: <ThumbnailCreateAppPush className="w-full h-auto" />
  },
  {
    key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.WEB_PUSH}/create`,
    label: 'Web Push',
    permissions: [PERMISSIONS.campaign_web_push_create],
    disabled: false,
    icon: <WebPush className="text-[#9E4CD1] w-6 h-6 block" />,
    iconBg: 'bg-[#F5EDFF]',
    thumbnail: <ThumbnailCreateWebPush className="w-full h-auto" />
  },
  isProduction
    ? null
    : {
        key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.LP_PAGE}/create`,
        label: 'Landing Page',
        permissions: [PERMISSIONS.campaign_landing_page_create],
        disabled: false,
        icon: <Screen className="text-[#F44D2C] w-6 h-6 block" />,
        iconBg: 'bg-[#FAE9E8]',
        thumbnail: <ThumbnailCreateLandingPage className="w-full h-auto" />
      },
  {
    key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.WHATSAPP}/create`,
    label: 'WhatsApp',
    permissions: [PERMISSIONS.campaign_whatsapp_create],
    disabled: false,
    icon: <WhatsappThin className="text-[#4CAF50] w-6 h-6 block" />,
    iconBg: 'bg-[#E0F8EE]',
    thumbnail: <ThumbnailCreateWhatsApp className="w-full h-auto" />
  },
  {
    key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.VNPT}/create`,
    label: 'SMS',
    // permissions: [PERMISSIONS.campaign_whatsapp_create],
    disabled: false,
    icon: <TwilioSMS className="text-[#F22F46] w-6 h-6 block" />,
    iconBg: 'bg-[#feeaec]',
    thumbnail: <ThumbnailCreateSMS className="w-full h-auto" />
  },
  {
    key: `${menuRoutes.campaign}/${TEMPLATE_TYPE.ZALO}/create`,
    label: 'Zalo',
    // permissions: [PERMISSIONS.campaign_whatsapp_create],
    disabled: false,
    icon: <ZaloLogo className="w-6 h-6 block" />,
    iconBg: 'bg-[#E3F2FF]',
    thumbnail: <ThumbnailCreateDefault className="w-full h-auto" />
  }
].filter(Boolean) as SelectTemplateItem[]

export const campaignHeaderPropsToState = (
  input: CampaignHeaderValue
): CampaignHeaderState => {
  const newState: CampaignHeaderState = {
    approach: input?.approach,
    search: input?.search,
    columns: input?.columns,
    site_id: input?.site_id,
    type: input?.type,
    status: input?.status,
    template_type: input?.template_type,
    template_types: input?.template_types
  }

  if (input?.created_from && input?.created_to) {
    const createdFrom = dayjs(input.created_from, FORMAT_DATE_DAYJS_API)
    const createdTo = dayjs(input.created_to, FORMAT_DATE_DAYJS_API)
    if (createdFrom.isValid() && createdTo.isValid()) {
      newState.created = [createdFrom, createdTo]
    }
  }
  if (input?.send_time_from && input?.send_time_to) {
    const startDatetimeFrom = dayjs(
      input.send_time_from,
      FORMAT_DATE_TIME_DAYJS
    )
    const startDatetimeTo = dayjs(input.send_time_to, FORMAT_DATE_TIME_DAYJS)
    if (startDatetimeFrom.isValid() && startDatetimeTo.isValid()) {
      newState.send_time = [startDatetimeFrom, startDatetimeTo]
    }
  }
  return newState
}

export const campaignHeaderStateToProps = (
  input: CampaignHeaderState
): CampaignHeaderValue => {
  const newState: CampaignHeaderValue = {
    approach: input?.approach,
    search: input?.search,
    columns: input?.columns,
    site_id: input?.site_id,
    type: input?.type,
    status: input?.status,
    template_type: input?.template_type,
    template_types: input?.template_types
  }

  if (input?.created && input.created?.length === 2) {
    const createdFrom = input.created?.[0].isValid()
      ? input.created?.[0].format(FORMAT_DATE_DAYJS_API)
      : ''
    const createdTo = input.created?.[1]?.isValid()
      ? input.created?.[1].format(FORMAT_DATE_DAYJS_API)
      : ''

    newState.created_from = createdFrom
    newState.created_to = createdTo
  } else {
    newState.created_from = undefined
    newState.created_to = undefined
  }

  if (input?.send_time && input.send_time?.length === 2) {
    const startDatetimeFrom = input.send_time?.[0].isValid()
      ? input.send_time?.[0].startOf('date').format(FORMAT_DATE_TIME_DAYJS)
      : ''
    const startDatetimeTo = input.send_time?.[1].isValid()
      ? input.send_time?.[1].endOf('date').format(FORMAT_DATE_TIME_DAYJS)
      : ''
    newState.send_time_from = startDatetimeFrom
    newState.send_time_to = startDatetimeTo
  } else {
    newState.send_time_from = undefined
    newState.send_time_to = undefined
  }

  return newState
}
