import { Alert, Button, Empty, Form, Input, Select, SelectProps } from 'antd'
import styled from 'styled-components'

export const StyledFormError = styled(Form.ErrorList)`
  margin-top: 8px;
  font-size: 12px;
`

export const StyledButtonAddEvent = styled(Button)`
  padding: 5px 12px;
  height: auto;
  .anticon {
    font-size: 18px;
    line-height: 1;
    vertical-align: middle;
  }
`

export const StyledButton = styled(Button)`
  &.remove_field_btn {
    width: 18px;
    height: 18px;
    padding: 0;
    margin-left: 12px;
    color: ${({ theme }) => theme?.colors?.neutral400};
    font-size: 18px;
    .anticon {
      font-size: inherit;
    }
    &:hover {
      color: inherit;
    }
  }
  &.add_field_btn {
  }
`

export const StyledSelect = styled(Select)<SelectProps>``

export const StyledInput = styled(Input)``

export const StyledItemTitle = styled.h4`
  flex: 1;
  min-width: 0;
  font-weight: 600;
  color: ${({ theme }) => theme?.colors?.gray800};
`

export const StyledItemHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const StyledItem = styled.div`
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  border-radius: 4px;
`

export const StyledList = styled.div`
  margin-bottom: 16px;
`

export const StyledEmpty = styled(Empty)`
  padding: 30px 0;
  margin: 0;
  background-color: ${({ theme }) => theme?.colors?.neutral50};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
`

export const StyledAlert = styled(Alert)``
