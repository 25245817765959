import { CAMPAIGN_MESSAGING_INSIGHT_TAB } from '../CampaignMessagingInsight'
import {
  CAMPAIGN_INSIGHT_DATE_RECORDED_FORMATED,
  CAMPAIGN_INSIGHT_STATUS_OPTIONS,
  CAMPAIGN_INSIGHT_TYPE_OPTIONS,
  getCampaignInsightFilterData,
  useCampaignInsightFieldValueOptions,
  useCampaignInsightFilterOptions
} from './CampaignMessagingInsightFilter.helpers'
import {
  StyledCampaignInsightFilterFormContainer,
  StyledCampaignInsightFilterFormTitle
} from './CampaignMessagingInsightFilter.styled'
import { CheckboxGroupComponent } from './CheckboxGroupComponent'
import {
  Button,
  Divider,
  Form,
  FormInstance,
  FormProps,
  Skeleton,
  notification
} from 'antd'
import { Rule } from 'antd/lib/form'
import cn from 'classnames'
import { DatePicker } from 'components/atoms/datePicker'
import { InputSearch } from 'components/atoms/input'
import { Select, SelectSite } from 'components/atoms/select'
import dayjs, { Dayjs } from 'dayjs'
import {
  Fragment,
  Ref,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

export type CampaignMessagingInsightFilterValue = {
  date_recorded?: [Dayjs, Dayjs]
  sites?: number[]
  campaign_channels?: string
  fieldKey?: string
  fieldValue?: string
  campaigns?: number[]

  // Metrics group
  audience?: string[]
  delivery?: string[]
  rentention?: string[]
  engagement?: string[]
  conversions?: string[]
}
type CampaignMessagingInsightFilterKey =
  keyof CampaignMessagingInsightFilterValue
const CAMPAIGN_MESSAGING_INSIGHT_FILTER_FETCH: Partial<CampaignMessagingInsightFilterKey>[] =
  ['date_recorded', 'sites', 'campaign_channels', 'fieldKey', 'fieldValue']
const CAMPAIGN_MESSAGING_INSIGHT_METRIC_GROUP: Partial<CampaignMessagingInsightFilterKey>[] =
  ['audience', 'delivery', 'rentention', 'engagement', 'conversions']
export { CAMPAIGN_INSIGHT_TYPE_OPTIONS }
export type CampaignMessagingInsightFilterRef =
  FormInstance<CampaignMessagingInsightFilterValue>
export type CampaignMessagingInsightFilterProps = {
  tab?: string
} & Pick<
  FormProps<CampaignMessagingInsightFilterValue>,
  | 'initialValues'
  | 'onValuesChange'
  | 'onFinish'
  | 'onFinishFailed'
  | 'className'
  | 'style'
>
const CampaignMessagingInsightFilterWithoutRef = (
  props: CampaignMessagingInsightFilterProps,
  ref?: Ref<CampaignMessagingInsightFilterRef>
) => {
  const {
    tab,
    className,
    onValuesChange,
    onFinish,
    onFinishFailed,
    ...restProps
  } = props
  const debounce = useRef<NodeJS.Timeout>()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [filterOptions, setFilterOptions] = useState<Record<string, any>>({})
  const [search, setSearch] = useState<string>()
  const fieldKey = Form.useWatch('fieldKey', form)
  const campaigns = Form.useWatch('campaigns', form)

  const fetchFilterOptions = useCallback(
    async (value: CampaignMessagingInsightFilterValue) => {
      if (debounce.current) clearTimeout(debounce.current)
      debounce.current = setTimeout(async () => {
        setLoading(true)
        const data = await getCampaignInsightFilterData(value)
        setFilterOptions(data)
        setLoading(false)
      }, 700)
    },
    []
  )

  const campaignChannelsOptions = useMemo(() => {
    if (filterOptions?.campaign_channels) {
      return filterOptions?.campaign_channels.map((key: string) => {
        const option = CAMPAIGN_INSIGHT_TYPE_OPTIONS.find(
          (opt) => opt.value === key
        )
        if (option) {
          return option
        }
        return { value: key, label: key }
      })
    }
    return CAMPAIGN_INSIGHT_TYPE_OPTIONS
  }, [filterOptions?.campaign_channels])
  const campaignOptions = useMemo(() => {
    return (
      filterOptions?.campaigns?.map(({ id, name }: any) => ({
        value: id,
        label: name
      })) || []
    )
  }, [filterOptions?.campaigns])
  const campaignButtonText = useMemo(() => {
    const isAll = campaignOptions.length === campaigns?.length
    return isAll ? 'Unselect all campaigns' : 'Select all campaigns'
  }, [campaigns, campaignOptions])
  const fieldValueOptions = useCampaignInsightFieldValueOptions({
    fieldKey,
    filterOptions
  })
  const audienceOptions = useCampaignInsightFilterOptions({
    filterOptions,
    filterKey: 'audience'
  })
  const deliveryOptions = useCampaignInsightFilterOptions({
    filterOptions,
    filterKey: 'delivery'
  })
  const retentionOptions = useCampaignInsightFilterOptions({
    filterOptions,
    filterKey: 'retention'
  })
  const engagementOptions = useCampaignInsightFilterOptions({
    filterOptions,
    filterKey: 'engagement'
  })
  const conversionsOptions = useCampaignInsightFilterOptions({
    filterOptions,
    filterKey: 'conversions'
  })
  const metricRules = useCallback<(message?: string) => Rule[]>(
    (message) => {
      return [
        ({ getFieldsValue }) => ({
          async validator() {
            const metricVals = getFieldsValue(
              CAMPAIGN_MESSAGING_INSIGHT_METRIC_GROUP
            )
            if (
              Object.values(metricVals).some(
                (val: any) => !!(val && val?.length)
              ) ||
              tab !== CAMPAIGN_MESSAGING_INSIGHT_TAB.OVERVIEW
            ) {
              return Promise.resolve()
            }
            return Promise.reject(message)
          }
        })
      ]
    },
    [tab]
  )

  const handleChangeCampaign = useCallback(() => {
    if (campaigns?.length === campaignOptions.length) {
      form.setFieldValue('campaigns', [])
    } else {
      form.setFieldValue(
        'campaigns',
        campaignOptions.map(({ value }: any) => value)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns, campaignOptions])
  const handleSearch = (s: string) => {
    setSearch(s)
    form.setFieldsValue({
      audience: undefined,
      delivery: undefined,
      retention: undefined,
      engagement: undefined,
      conversions: undefined
    })
  }
  const handleValuesChange = (
    valueChanged: Partial<CampaignMessagingInsightFilterValue>,
    valuesChanged: CampaignMessagingInsightFilterValue
  ) => {
    const keyChanged = Object.keys(valueChanged)
    const newValuesChanged = { ...valuesChanged }

    if (keyChanged.includes('fieldKey')) {
      newValuesChanged['fieldValue'] = undefined
    }

    const isRemoveCampaigns = [
      'date_recorded',
      'sites',
      'campaign_channels',
      'fieldKey',
      'fieldValue'
    ].some((key) => keyChanged.includes(key))
    if (isRemoveCampaigns) {
      newValuesChanged['campaigns'] = undefined
    }

    const isFetch = keyChanged.some((k) => {
      const key = k as CampaignMessagingInsightFilterKey
      return CAMPAIGN_MESSAGING_INSIGHT_FILTER_FETCH.includes(key)
    })
    if (isFetch) {
      fetchFilterOptions(newValuesChanged)
    }
    form.setFieldsValue(newValuesChanged)
    onValuesChange?.(valueChanged, newValuesChanged)
  }
  const handleFinishFailed: CampaignMessagingInsightFilterProps['onFinishFailed'] =
    (props) => {
      const { errorFields } = props
      if (errorFields.length) {
        const errMessages: string[] = []
        errorFields.forEach((errorField) => {
          if (errorField.errors?.length) {
            errMessages.push(...errorField.errors)
          }
        })
        notification.warning({
          message: errMessages.map((errMessage: string, index) => {
            return (
              <Fragment key={`${index}`}>
                <span>{errMessage}</span>
                <br />
              </Fragment>
            )
          })
        })
      }
      onFinishFailed?.(props)
    }

  useImperativeHandle(ref, () => form)
  return (
    <Form
      {...restProps}
      form={form}
      onValuesChange={handleValuesChange}
      onFinish={onFinish}
      onFinishFailed={handleFinishFailed}
      className={cn('messagingInsightForm flex flex-col h-full', className)}>
      <div className="messagingInsightForm-body flex-1 min-h-0 scrollbar overflow-auto">
        <StyledCampaignInsightFilterFormContainer className="p-6">
          <StyledCampaignInsightFilterFormTitle className="messagingInsightForm-title !mb-2">
            Date recorded
          </StyledCampaignInsightFilterFormTitle>
          <Form.Item
            name="date_recorded"
            className="group"
            rules={[
              { required: true, message: 'Date recorded field is required' }
            ]}
            noStyle>
            <DatePicker.RangePicker
              format={CAMPAIGN_INSIGHT_DATE_RECORDED_FORMATED}
              disabledDate={(current) =>
                current && current > dayjs().endOf('day')
              }
            />
          </Form.Item>
          <StyledCampaignInsightFilterFormTitle className="messagingInsightForm-title !mb-2 !mt-4">
            Campaign filter
          </StyledCampaignInsightFilterFormTitle>
          <Form.Item
            name="sites"
            rules={[{ required: true, message: 'Sites field is required' }]}
            noStyle>
            <SelectSite
              allowClear
              mode="multiple"
              placeholder="Sites"
              maxTagCount={3}
            />
          </Form.Item>
          <Form.Item name="campaign_channels" noStyle>
            <Select
              allowClear
              placeholder="Campaign type"
              maxTagCount={2}
              options={campaignChannelsOptions}
              className="w-full mt-2"
            />
          </Form.Item>
          <div className="flex items-center gap-3 mt-2">
            <Form.Item name="fieldKey" noStyle>
              <Select
                allowClear
                placeholder="Status"
                options={CAMPAIGN_INSIGHT_STATUS_OPTIONS}
                className="flex-1 min-w-0"
              />
            </Form.Item>
            <span>is</span>
            <Form.Item
              shouldUpdate={(prev, next) => prev.fieldKey !== next.fieldKey}
              noStyle>
              <Form.Item name="fieldValue" noStyle>
                <Select
                  allowClear
                  placeholder="Schedule"
                  options={fieldValueOptions}
                  className="flex-1 min-w-0"
                  popupClassName="!min-w-[40vw]"
                />
              </Form.Item>
            </Form.Item>
          </div>
          <Form.Item
            name="campaigns"
            rules={[
              { required: true, message: 'Campaign name field is required' }
            ]}
            noStyle>
            <Select
              allowClear
              mode="multiple"
              maxTagCount={3}
              options={campaignOptions}
              placeholder="Campaign name"
              className="mt-2 w-full"
              popupClassName="!min-w-[40vw]"
            />
          </Form.Item>
          <div className="flex justify-between">
            <Button
              type="link"
              disabled={!campaignOptions?.length}
              onClick={handleChangeCampaign}
              className="!p-0 h-auto text-gray-500 text-[0.9em]">
              {campaignButtonText}
            </Button>
            <span className="text-gray-500 text-[0.9em]">
              <span className="font-bold">{campaigns?.length || 0}</span>/
              {campaignOptions?.length || 0} campaigns
            </span>
          </div>
          <Divider className="!mt-8 !mb-1 !border-neutral100" />
          <StyledCampaignInsightFilterFormTitle className="messagingInsightForm-title !text-[14px] !text-gray-500 !mb-1">
            METRIC
          </StyledCampaignInsightFilterFormTitle>
          <InputSearch
            allowClear
            value={search}
            onSearch={handleSearch}
            placeholder="Search all metric"
          />
          <Divider className="!mt-4 !mb-1 !border-neutral100" />

          {loading && <Skeleton active paragraph={{ rows: 7 }} />}
          {!loading && (
            <>
              <Form.Item
                name="audience"
                dependencies={CAMPAIGN_MESSAGING_INSIGHT_METRIC_GROUP}
                noStyle
                rules={metricRules('Audience field is required')}>
                <CheckboxGroupComponent
                  title="AUDIENCE"
                  options={audienceOptions}
                  search={search}
                />
              </Form.Item>
              <Divider className="!mt-2 !mb-1 !border-neutral100" />
              <Form.Item
                name="delivery"
                dependencies={CAMPAIGN_MESSAGING_INSIGHT_METRIC_GROUP}
                noStyle
                rules={metricRules('Delivery field is required')}>
                <CheckboxGroupComponent
                  title="DELIVERY"
                  options={deliveryOptions}
                  search={search}
                />
              </Form.Item>
              <Divider className="!mt-2 !mb-1 !border-neutral100" />
              <Form.Item
                name="engagement"
                dependencies={CAMPAIGN_MESSAGING_INSIGHT_METRIC_GROUP}
                noStyle
                rules={metricRules('Engagement field is required')}>
                <CheckboxGroupComponent
                  title="ENGAGEMENT"
                  options={engagementOptions}
                  search={search}
                />
              </Form.Item>
              <Divider className="!mt-2 !mb-1 !border-neutral100" />
              <Form.Item
                name="rentention"
                dependencies={CAMPAIGN_MESSAGING_INSIGHT_METRIC_GROUP}
                noStyle
                rules={metricRules('Rentention field is required')}>
                <CheckboxGroupComponent
                  title="RETENTION"
                  options={retentionOptions}
                  search={search}
                />
              </Form.Item>
              <Divider className="!mt-2 !mb-1 !border-neutral100" />
              <Form.Item
                name="conversions"
                dependencies={CAMPAIGN_MESSAGING_INSIGHT_METRIC_GROUP}
                noStyle
                rules={metricRules('Conversions field is required')}>
                <CheckboxGroupComponent
                  title="CONVERSIONS"
                  options={conversionsOptions}
                  search={search}
                />
              </Form.Item>
            </>
          )}
        </StyledCampaignInsightFilterFormContainer>
      </div>
      <div className="messagingInsightForm-footer p-6 pt-2 flex justify-between border-0 border-solid border-t border-gray-100">
        <Button htmlType="reset" onClick={() => setSearch(undefined)}>
          Reset All
        </Button>
        <Button type="primary" htmlType="submit">
          Apply
        </Button>
      </div>
    </Form>
  )
}

export const CampaignMessagingInsightFilter = forwardRef(
  CampaignMessagingInsightFilterWithoutRef
)
