import {
  AnalyticWidgetItemProps,
  WIDGET_ITEM_HIGHCHARTS_EMPTY_PROPS,
  WIDGET_ITEM_KEY,
  WIDGET_ITEM_LABEL,
  usePeriodLabel
} from './AnalyticWidgetItem.helpers'
import { HighchartsSpider } from 'components/atoms/Highcharts'
import { theme } from 'constants/theme'
import { FC } from 'react'

const breakdownBySECClassKeys = [
  // WIDGET_ITEM_KEY.UNKNOWN,
  WIDGET_ITEM_KEY.FEMALE,
  WIDGET_ITEM_KEY.MALE
]
const breakdownBySECClassTheme = {
  [WIDGET_ITEM_KEY.MALE]: { color: theme.colors.blue600, unit: '' },
  [WIDGET_ITEM_KEY.FEMALE]: { color: theme.colors.pink600, unit: '' }
  // [WIDGET_ITEM_KEY.UNKNOWN]: { color: theme.colors.yellow600, unit: '' }
}
const breakdownBySECClassOrder = [
  'Upper Class',
  'Upper Middle Class',
  'Middle Class',
  'Working Class',
  'Labour Class'
]

export const AnalyticWidgetItemBreakdownBySECClass: FC<
  AnalyticWidgetItemProps
> = (props) => {
  const { data, item } = props
  const label = usePeriodLabel(item?.query?.period)
  const highchartsOptions = formatBreakdownBySECClass(data)

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between">
        <h4 className="period mb-0 text-gray600">{label}</h4>
      </div>
      <div className="flex-1 min-h-0">
        <HighchartsSpider
          options={highchartsOptions}
          className="h-full"
          emptyProps={WIDGET_ITEM_HIGHCHARTS_EMPTY_PROPS}
        />
      </div>
    </div>
  )
}

export function formatBreakdownBySECClass(
  data: any,
  keys = breakdownBySECClassKeys
): Highcharts.Options {
  const { detail: details, ...total } = data
  const highchartsOptions: Highcharts.Options = {
    xAxis: {
      labels: { enabled: true },
      lineWidth: 0,
      crosshair: true
    },
    yAxis: {
      labels: { enabled: true },
      gridLineDashStyle: 'Dot'
    },
    series: [],
    plotOptions: {
      bar: {
        pointWidth: 16,
        grouping: true,
        centerInCategory: true
      }
    },
    legend: { enabled: true }
  }

  if (!total || !Object.keys(total).length || !details || !details?.length) {
    return highchartsOptions
  }

  const xCategories: string[] = []
  const seriesBars: Highcharts.SeriesBarOptions[] = []

  try {
    details
      .filter((item: any) => item.sec !== 'Other')
      .sort((itemA: any, itemB: any) => {
        if (
          breakdownBySECClassOrder.indexOf(itemA.sec) === -1 ||
          breakdownBySECClassOrder.indexOf(itemB.sec) === -1
        ) {
          return 0
        }
        return (
          breakdownBySECClassOrder.indexOf(itemA.sec) -
          breakdownBySECClassOrder.indexOf(itemB.sec)
        )
      })
      .forEach((detail: any) => {
        xCategories.push(detail.sec)
        keys.forEach((key) => {
          const seriesBarsItemName = WIDGET_ITEM_LABEL[key]
          const seriesBarsItem = seriesBars.find(
            ({ name }) => name === seriesBarsItemName
          )
          const seriesBarsItemValue = parseFloat(detail?.[key] || '0') || null

          if (seriesBarsItem) {
            seriesBarsItem.data?.push(seriesBarsItemValue)
          } else {
            const seriesBarsItemTheme = breakdownBySECClassTheme?.[key]
            seriesBars.push({
              type: 'bar',
              name: seriesBarsItemName,
              data: [seriesBarsItemValue],
              color: seriesBarsItemTheme?.color,
              tooltip: {
                valueSuffix: seriesBarsItemTheme?.unit
              }
            })
          }
        })
      })

    if (
      xCategories.length &&
      seriesBars.length &&
      seriesBars.some(({ data }) =>
        data?.some((item) => typeof item === 'number')
      )
    ) {
      highchartsOptions.xAxis = {
        ...highchartsOptions.xAxis,
        categories: xCategories
      }
      highchartsOptions.series?.push(...seriesBars)
    }
  } catch (error) {}

  return highchartsOptions
}

// const mockup = {
//   detail: [
//     {
//       female: 0,
//       male: 0,
//       sec: 'Upper Class',
//       unknown: 10000
//     },
//     {
//       female: 0,
//       male: 0,
//       sec: 'Upper Middle Class',
//       unknown: 0
//     },
//     {
//       female: 0,
//       male: 0,
//       sec: 'Middle Class',
//       unknown: 0
//     },
//     {
//       female: 0,
//       male: 0,
//       sec: 'Working Class',
//       unknown: 0
//     },
//     {
//       female: 74203,
//       male: 555457,
//       sec: 'Labour Class',
//       unknown: 0
//     },
//     {
//       female: 555457,
//       male: 74203,
//       sec: 'Other',
//       unknown: 0
//     }
//   ],
//   female: 555457,
//   male: 74203,
//   unknown: 0
// }
