import useSound from 'use-sound'

const useNotifySoundEffect = () => {
  const [play] = useSound('/sounds/rebound.mp3')
  return {
    ting: play
  }
}

export default useNotifySoundEffect
