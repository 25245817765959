import { Switch } from 'antd'
import styled from 'styled-components'

export const StyledSwitch = styled(Switch)``

export const StyledSwitchLabel = styled.span`
  display: inline-block;
  margin-top: 0.05em;
`

export const StyledSwitchContainer = styled.label`
  display: flex;
  ${StyledSwitch} {
    + ${StyledSwitchLabel} {
      margin-left: 8px;
    }
    &.ant-switch-small {
      + ${StyledSwitchLabel} {
        margin-top: -0.3em;
      }
    }
  }
`
