import { Spin } from 'antd'
import { SpinProps } from 'antd/lib/spin'
import React from 'react'

const Loading: React.FC<SpinProps> = ({
  children,
  indicator,
  ...restProps
}) => <Spin {...restProps}>{children}</Spin>

export { Loading }
