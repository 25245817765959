import { SegmentPredictionValue } from '../SegmentPrediction'
import { defaultValueSegmentPrediction } from '../SegmentPredictionDrawer'
import { SEGMENT_STATUS_KEYS } from '../SegmentStatus'
import { SegmentFormOverviewValue } from './SegmentFormOverview'
import { formatSegmentFormOverview } from './SegmentFormOverview/SegmentFormOverview.helpers'
import { SegmentFormQueryValue } from './SegmentFormQuery'
import { formatSegmentFormQuery } from './SegmentFormQuery/SegmentFormQuery.helpers'
import {
  ButtonProps,
  FormInstance,
  FormProps,
  ModalProps,
  notification
} from 'antd'
import { putUpdateSegment } from 'components/pages'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import { SEGMENT_TYPE } from 'constants/segment'
import { SITE_KEYS } from 'constants/site'
import { useApp } from 'context/App'
import { useCountAllSite } from 'hooks/useSegment'
import { SegmentCountByConditionParams } from 'interfaces/segment'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { segmentServices } from 'services/segment'
import { FormNamePath } from 'utils/form'

export type SegmentReachableData = {
  totalCount: number
  email: number
  phoneCount: number
  appPushCount: number
  webPushCount: number
  whatsappCount: number
  isCountSuccess: boolean
  status: string[] | string
  error: string[] | string
}
export type SegmentFormValue = SegmentFormOverviewValue &
  SegmentFormQueryValue & {
    count_data?: SegmentReachableData
  }
export type SegmentFormContainerRef<T = SegmentFormValue> = {
  overviewForm: FormInstance<SegmentFormOverviewValue | T> | null
  queryForm: FormInstance<SegmentFormQueryValue | T> | null
  openConfirm: boolean
  setOpenConfirm: Dispatch<SetStateAction<boolean>>
}
export type SegmentFormContainerProps<T = SegmentFormValue> = {
  title?: string
  loading?: boolean
  disabled?: boolean
  disabledKeys?: string[]
  notFound?: boolean
  segment_type?: string
  defaultValue?: T
  value?: T
  onChange?: (value: T) => void
  saveButtonProps?: Omit<ButtonProps, 'onClick'>
  onSave?: (value: T) => void

  confirmModalProps?: Pick<ModalProps, 'onOk' | 'onCancel'>

  roles?: string[]
  permissions?: string[]

  siteId?: string | number
  onCreateSegment?: (data: any) => void
}
export type SegmentFormProps<T = any> = Pick<
  FormProps<T>,
  | 'form'
  | 'name'
  | 'disabled'
  | 'initialValues'
  | 'onValuesChange'
  | 'onFinish'
  | 'onFinishFailed'
> &
  Pick<SegmentFormContainerProps, 'loading' | 'segment_type'> & {
    disabledKeys?: string[]
  }

export const defaultSegmentFormValue: SegmentFormValue = {
  type_time_range: 'custom_range'
}

export const formatSegmentForm = (
  initValue: any,
  defaultValue = defaultSegmentFormValue
): SegmentFormValue => {
  let newValue: SegmentFormValue = formatSegmentFormOverview({
    ...defaultValue,
    ...initValue
  })
  newValue = formatSegmentFormQuery({ ...defaultValue, ...newValue })

  return newValue
}

export type FetchQueryAnalyticsInput = {
  start_date: string
  end_date: string
  type?: string
  operator?: string
  site_code?: string[]
  list: any[]
}
export type FetchQueryAnalyticsOutput = {
  data: SegmentReachableData | null
  errors: { name?: FormNamePath; errors: string[] }[]
}

export const SEGMENT_REACHABLE_DEFAULT: SegmentReachableData = {
  appPushCount: 0,
  email: 0,
  phoneCount: 0,
  totalCount: 0,
  webPushCount: 0,
  whatsappCount: 0,
  isCountSuccess: false,
  status: '',
  error: ''
}
export const formatCountDataToReachable = (
  countAllData: any[]
): FetchQueryAnalyticsOutput['data'] => {
  const data: FetchQueryAnalyticsOutput['data'] = {
    appPushCount: 0,
    email: 0,
    phoneCount: 0,
    totalCount: 0,
    webPushCount: 0,
    whatsappCount: 0,
    isCountSuccess: true,
    error: [],
    status: []
  }
  if (!countAllData?.length) {
    return data
  }
  try {
    ;(countAllData || []).forEach((countData: any) => {
      data.appPushCount += parseInt(countData?.appPushCount || '0')
      data.email += parseInt(countData?.email || '0')
      data.phoneCount += parseInt(countData?.phoneCount || '0')
      data.totalCount += parseInt(countData?.totalCount || '0')
      data.webPushCount += parseInt(countData?.webPushCount || '0')
      data.whatsappCount += parseInt(countData?.whatsappCount || '0')
      data.isCountSuccess = data.isCountSuccess && countData?.isCountSuccess
      if (countData?.error) {
        if (Array.isArray(data.error)) {
          data.error.push(countData.error as string)
        }
      }
      if (countData?.status) {
        if (Array.isArray(data.status)) {
          data.status.push(countData.status)
        }
      }
    })
  } catch (error) {}
  return data
}
export const formatDataToCountByConditionParams = (
  input: Partial<FetchQueryAnalyticsInput>
): SegmentCountByConditionParams => {
  const params: SegmentCountByConditionParams = {
    type: input?.type,
    site_code: input?.site_code
  }
  switch (input.type) {
    case SEGMENT_TYPE.ATTRIBUTE: {
      params.attribute_conditions = input?.list || []
      break
    }
    case SEGMENT_TYPE.EVENT: {
      params.events_conditions = input?.list || []
      if (input.list?.[0]?.submitted?.[0] && input.list?.[0]?.submitted?.[1]) {
        const startDate = input.list[0].submitted[0]
        const endDate = input.list[0].submitted[1]
        if (startDate.isValid() && endDate.isValid()) {
          params.events_conditions[0].submitted_from = startDate.format(
            FORMAT_DATE_DAYJS_API
          )
          params.events_conditions[0].submitted_to = endDate.format(
            FORMAT_DATE_DAYJS_API
          )
        }
      }
      if (!input?.list?.[0]?.event_id) {
        throw new Error('There is no event seleted')
      }
      break
    }
    case SEGMENT_TYPE.EVENT_CUSTOM: {
      params.custom_conditions = input?.list || []
      break
    }
    case SEGMENT_TYPE.MOBILE: {
      params.mobile_conditions = input?.list || []
      break
    }
  }
  return params
}
export const fetchQueryAnalytics = async (
  input: FetchQueryAnalyticsInput
): Promise<FetchQueryAnalyticsOutput> => {
  try {
    const params: any = formatDataToCountByConditionParams(input)
    const response: any = await segmentServices.segmentCountByCondition(params)
    if (response?._message || !response?.data) {
      throw new Error(JSON.stringify(response))
    }
    return {
      data: formatCountDataToReachable(response.data.count_data),
      errors: []
    }
  } catch (error) {
    console.log('** SegmentForm.helpers.ts 205 Error : ', error)
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}

const SITE_KEYS_EXCLUDE_TOTAL_PH = [SITE_KEYS.HELLO_DOCTOR, SITE_KEYS.HELLO_FIL]
const SITE_KEYS_EXCLUDE_TOTAL_MB = [SITE_KEYS.MARRY_BABY]
export const useSegmentForm = (props: {
  value: SegmentFormValue
  overviewForm: FormInstance<SegmentFormOverviewValue>
  siteId?: string | number
}) => {
  const { value, overviewForm, siteId } = props
  const { sites: sitesApp } = useApp()
  const { countAllSites } = useCountAllSite(sitesApp)

  const site_ids = useMemo<Array<string | number>>(
    () => value?.site_ids || overviewForm.getFieldValue('site_ids') || [siteId],
    [overviewForm, siteId, value?.site_ids]
  )
  const sites = useMemo(() => {
    const sites =
      sitesApp?.filter((s) => site_ids?.some((sId) => sId === s.value)) || []

    const sitesFilter: typeof sites = []
    for (const site of sites) {
      if (SITE_KEYS_EXCLUDE_TOTAL_MB.includes(site.name)) {
        if (
          sitesFilter.some((s) => SITE_KEYS_EXCLUDE_TOTAL_MB.includes(s.name))
        ) {
          continue
        }
      }
      if (SITE_KEYS_EXCLUDE_TOTAL_PH.includes(site.name)) {
        if (
          sitesFilter.some((s) => SITE_KEYS_EXCLUDE_TOTAL_PH.includes(s.name))
        ) {
          continue
        }
      }
      if (site.name === SITE_KEYS.MARRY_BABY) {
        site.domain = 'www.marrybaby.vn' // Fuck marrybaby too much domain (www.marrybaby.vn || marrybaby.vn)
      }
      sitesFilter.push(site)
    }

    return sitesFilter
  }, [sitesApp, site_ids])
  const sitesTotal = useMemo(() => {
    if (!sites || !sites?.length) {
      return 0
    }

    return sites.reduce((total, site) => {
      let value = 0
      if (site.name in countAllSites) {
        value = countAllSites[site.name]
      }
      return total + value
    }, 0)
  }, [sites, countAllSites])

  return useMemo(() => ({ sites, sitesTotal }), [sites, sitesTotal])
}

const NUMBER_GET_REACHABLE = 30
export const useSegmentReachable = (props: {
  value: SegmentFormValue
  overviewForm: FormInstance<SegmentFormOverviewValue>
  queryForm: FormInstance<SegmentFormQueryValue>
}) => {
  const { value, overviewForm } = props
  const countReachable = useRef(0)
  const debounce = useRef<NodeJS.Timeout>()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<SegmentReachableData>(
    SEGMENT_REACHABLE_DEFAULT
  )

  const onError = useCallback(
    (errors: FetchQueryAnalyticsOutput['errors']) => {
      errors?.forEach((error: any) => {
        if (
          typeof error.name === 'string' &&
          ['type_time_range'].some((name) => name === error.name)
        ) {
          notification.info({ message: error.errors })
          const name =
            value.type_time_range === 'custom_range'
              ? 'custom_time_range'
              : 'default_time_range'
          overviewForm.setFields([
            { name, errors: error.errors, touched: true }
          ])
        } else {
          notification.info({ message: error.errors })
        }
      })
    },
    [overviewForm, value.type_time_range]
  )

  const fetchQueryAnalyticsFrequency = useCallback(
    async (params: any) => {
      setLoading(true)
      const { data, errors } = await fetchQueryAnalytics(params)
      if (debounce.current) clearTimeout(debounce.current)
      if (errors?.length) {
        onError(errors)
        setLoading(false)
        return
      }
      if (
        (!data || !data?.isCountSuccess) &&
        countReachable.current < NUMBER_GET_REACHABLE
      ) {
        countReachable.current++
        debounce.current = setTimeout(async () => {
          await fetchQueryAnalyticsFrequency(params)
        }, 1000)
        setLoading(false)
        return
      }

      if (!data) {
        notification.error({ message: 'Something went wrong!' })
        setLoading(false)
        return
      }

      notification.success({ message: 'Your query has completed' })
      setLoading(false)
      setData(data)
    },
    [onError]
  )

  useEffect(() => {
    setData(
      formatCountDataToReachable((value?.count_data || []) as any[]) ||
        SEGMENT_REACHABLE_DEFAULT
    )
  }, [value?.count_data])

  useEffect(() => {
    return () => {
      countReachable.current = 0
      if (debounce.current) clearTimeout(debounce.current)
    }
  }, [])

  return useMemo(
    () => ({ loading, data, setData, fetchQueryAnalyticsFrequency }),
    [data, fetchQueryAnalyticsFrequency, loading]
  )
}

export const useSegmentPredictionDrawer = (props: {
  value?: SegmentFormValue
}) => {
  const { value } = props
  const dataCache = useRef<Record<string, any>>({})
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>()
  const [open, setOpen] = useState(false)
  const debounce = useRef<NodeJS.Timeout>()

  const defaultValue = useMemo<SegmentPredictionValue | undefined>(() => {
    let segPredictionVal = undefined
    value?.list?.forEach((item) => {
      const predictionItem = item.conditions?.find(
        (cond) => cond.field === defaultValueSegmentPrediction.field
      )
      if (predictionItem) {
        segPredictionVal = { ...predictionItem }
      }
    })

    return segPredictionVal
  }, [value?.list])

  const disabled = useMemo(() => {
    if (
      value?.segment_type === SEGMENT_TYPE.ATTRIBUTE &&
      value?.status !== SEGMENT_STATUS_KEYS.PUBLIC
    ) {
      return Boolean(!value?.list?.length)
    }

    return true
  }, [value])

  const onChange = useCallback((value: Partial<FetchQueryAnalyticsInput>) => {
    setLoading(true)
    if (debounce.current) {
      clearTimeout(debounce.current)
    }
    debounce.current = setTimeout(() => {
      const newInput = { ...value, list: value?.list || [] }
      const dataKey = JSON.stringify(newInput)

      if (dataCache.current?.[dataKey]) {
        setData(dataCache.current?.[dataKey])
        setLoading(false)
        return
      }
      segmentServices
        .segmentCountByCondition(formatDataToCountByConditionParams(newInput))
        .then((res: any) => {
          if (!res.data || res._message) {
            throw new Error(JSON.stringify(res))
          }
          setData(res.data.performance_data)
          dataCache.current[dataKey] = res.data.performance_data
        })
        .catch(() => {
          delete dataCache.current[dataKey]
        })
        .finally(() => setLoading(false))
    }, 250)
  }, [])

  return useMemo(
    () => ({ loading, disabled, data, defaultValue, onChange, open, setOpen }),
    [data, disabled, loading, onChange, open, defaultValue]
  )
}

export const useSegmentAnalyticsDrawer = ({
  value,
  setValue
}: {
  value: SegmentFormValue
  setValue: Dispatch<SetStateAction<SegmentFormValue>>
}) => {
  const [loading, setLoading] = useState(false)

  const onSave = useCallback(
    async (valueSaved: Partial<SegmentFormValue>) => {
      if (!valueSaved?.id) {
        return false
      }

      setLoading(true)
      try {
        const { data, errors } = await putUpdateSegment({
          ...value,
          ...valueSaved
        })
        if (errors.length || !data) {
          throw new Error(JSON.stringify({ data, errors }))
        }
        setValue(data)
        setLoading(false)
        return true
      } catch (error) {
        console.log('** useSegmentAnalyticsDrawer Error : ', error)
      }

      setLoading(false)
      return true
    },
    [setValue, value]
  )

  return useMemo(() => ({ loading, setLoading, onSave }), [loading, onSave])
}
