import { StyledSortDropdownOverlay, StyledSortIcon } from './index.styled'
import { Dropdown, MenuProps } from 'antd'
import cn from 'classnames'
import { sort } from 'constants/common'
import { CaretDown } from 'icons'
import { FC } from 'react'

interface DropdownSortProps {
  value?: string | null
  sorted?: boolean
  onChange?: (value: any) => void
  items?: MenuProps['items']
}

export const DropdownSort: FC<DropdownSortProps> = ({
  value,
  sorted,
  onChange,
  items
}) => {
  const menus = [
    {
      label: 'A-Z',
      type: undefined,
      key: sort.ascend
    },
    {
      label: 'Z-A',
      type: undefined,
      key: sort.descend
    }
  ]
  return (
    <Dropdown
      trigger={['click']}
      placement="bottom"
      menu={{
        items: items || menus,
        selectedKeys: value ? [value] : [],
        onClick: (item) => {
          onChange?.(item.key)
        }
      }}
      dropdownRender={(MenuItem) => (
        <StyledSortDropdownOverlay>
          <h4 className="title">SORT BY</h4>
          {MenuItem}
        </StyledSortDropdownOverlay>
      )}>
      <StyledSortIcon
        className={cn('section--sort', {
          sorted
        })}>
        <CaretDown />
      </StyledSortIcon>
    </Dropdown>
  )
}
