import { DictionaryFormValue } from '../DictionaryForm'
import { DICTIONARY_CATEGORY } from '../DictionaryTable/DictionaryTable.helpers'
import { FieldServices } from 'services/field'
import { FieldAttributeServices } from 'services/fieldAttribute'
import { FormNamePath } from 'utils/form'

export const dictionaryModalTitle: Record<string, string> = {
  [DICTIONARY_CATEGORY.ATTRIBUTE]: 'Attributes',
  [DICTIONARY_CATEGORY.EVENT]: 'Event',
  [DICTIONARY_CATEGORY.MOBILE]: 'Mobile'
}

type DictionaryError = {
  name?: FormNamePath
  errors: string[]
}
type DictionaryReturn = {
  data: any | null
  errors: DictionaryError[]
}
export async function postCreateDictionary(
  value: DictionaryFormValue
): Promise<DictionaryReturn> {
  try {
    const response = await FieldServices.createField({
      ...value,
      status: value.status ? 'enable' : 'disable'
    })
    if (response?._message || !response.data) {
      throw new Error(JSON.stringify(response))
    }

    return { data: response.data, errors: [] }
  } catch (error: any) {
    const errorObj = JSON.parse(error.message)
    if (errorObj._message.includes('name')) {
      return {
        data: null,
        errors: [{ name: 'name', errors: ['Name is existing'] }]
      }
    }
    if (errorObj._message.includes('key')) {
      return {
        data: null,
        errors: [{ name: 'field', errors: ['Key is existing'] }]
      }
    }

    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}

export async function putUpdateDictionary(
  value: DictionaryFormValue
): Promise<DictionaryReturn> {
  if (!value?.id) {
    return { data: null, errors: [{ errors: ['Data is NOT valid'] }] }
  }
  try {
    const response = await FieldServices.updateField(value.id, {
      ...value,
      status: value.status ? 'enable' : 'disable'
    })
    if (response._message || !response.data) {
      throw new Error(JSON.stringify(response))
    }

    return { data: response.data, errors: [] }
  } catch (error: any) {
    const errorObj = JSON.parse(error.message)
    if (errorObj._message.includes('name')) {
      return {
        data: null,
        errors: [{ name: 'name', errors: ['Name is existing'] }]
      }
    }
    if (errorObj._message.includes('key')) {
      return {
        data: null,
        errors: [{ name: 'field', errors: ['Key is existing'] }]
      }
    }
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}

export async function getDictionaryATTRIBUTE(id: string | number) {
  try {
    const response = await FieldAttributeServices.getFieldsById(id as string)
    console.log('** RYAN index.ts 6 response : ', response)
  } catch (error) {}
}

export * from './DictionaryModalCreate'
export * from './DictionaryModalEdit'
