import Icon from '@ant-design/icons'
import { Avatar, Badge } from 'antd'
import classNames from 'classnames'
import { NotificationsContext } from 'context/Notifications'
import { ChevronLeft } from 'icons'
import { ReactComponent as BellSvg } from 'icons/Bell.svg'
import { FC, ReactNode, useContext } from 'react'
import styled from 'styled-components'
import { cssPadding, cssTitle } from 'styles'

const Title = styled.h2`
  ${cssTitle}
`

const Notification = styled.div`
  cursor: pointer;
  .ant-badge {
    .ant-avatar {
      background-color: transparent;
      .anticon {
        font-size: 24px;
      }
    }
    .ant-badge-count {
      min-width: 15px;
      height: 15px;
      font-size: 10px;
      line-height: 15px;
    }
  }
`

const HeadTitle = styled.div`
  display: flex;
  align-items: center;
  ${Title} {
    + .description {
      margin-left: 16px;
    }
  }
  .description {
    color: ${({ theme }) => theme?.colors?.gray500 || '#737373'};
    font-size: 14px;
    line-height: 1.5;
  }
`

const HeadWrapper = styled.div`
  flex: 1;
`

const HeadContainer = styled.div`
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme?.colors?.white};
  ${cssPadding}
`

export type HeadProps = {
  title?: string
  description?: string
  className?: string
  onBack?: () => void
  showHeaderRightComponent?: boolean
  headerRightComponent?: ReactNode
}

const HeaderNotification: FC<HeadProps> = ({
  title,
  description,
  children,
  onBack,
  showHeaderRightComponent = true,
  headerRightComponent,
  ...props
}) => {
  const { setIsShow, countNewNoti } = useContext(NotificationsContext)

  return (
    <HeadContainer
      {...props}
      className={classNames('header--notification', props?.className)}>
      <HeadWrapper>
        {!children && (
          <HeadTitle>
            {!!onBack && (
              <ChevronLeft
                className="text-lg mr-1 cursor-pointer hover:text-blue-500 transition-all"
                onClick={onBack}
              />
            )}
            <Title>{title}</Title>
            {!!description && <div className="description">{description}</div>}
          </HeadTitle>
        )}
        {!!children && children}
      </HeadWrapper>

      {!!headerRightComponent &&
        showHeaderRightComponent &&
        headerRightComponent}
      {!headerRightComponent && showHeaderRightComponent && (
        <Notification onClick={() => setIsShow(true)}>
          <Badge count={countNewNoti}>
            <Avatar size={24} icon={<Icon component={BellSvg} />} />
          </Badge>
        </Notification>
      )}
    </HeadContainer>
  )
}

export default HeaderNotification
