import { StyleButton, StyledRight, StyledWrap } from './index.styled'
import Icon from '@ant-design/icons'
import { Button } from 'antd'
import cls from 'classnames'
import { Box } from 'components/atoms/box'
import { InputSearch } from 'components/atoms/input'
import { menuRoutes } from 'components/molecules'
import { StyledModal } from 'components/molecules/campaign/CampaignForm/CampaignStepHeader/CampaignStepHeader.styled'
import { PanelTabItem, PanelTabs } from 'components/molecules/templateBuilder'
import { PERMISSIONS } from 'constants/permission'
import { ROLES } from 'constants/role'
import { TEMPLATE_TYPE } from 'constants/template'
import { useCheckAuth } from 'hooks/useCheckAuth'
import {
  AppPush,
  CheckedCircle,
  Email,
  Filter,
  Grid,
  List,
  Plus,
  Screen,
  ThumbnailCreateAppPush,
  ThumbnailCreateDefault,
  ThumbnailCreateEmail,
  ThumbnailCreateLandingPage,
  ThumbnailCreateSMS,
  ThumbnailCreateWebPush,
  ThumbnailCreateWhatsApp,
  TwilioSMS,
  WebPush,
  WhatsappThin
} from 'icons'
import { ZaloLogo } from 'icons/V2'
import { ReactNode, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  listTabs: PanelTabItem[]
  viewType: string
  searchStr: string
  activeTab: string
  openFilter: boolean
  onChangeTab: (activeKey: string) => void
  onChangeSearch: (searchStr: string) => void
  onChangeViewType: (viewType: string) => void
  setOpenFilter?: (val: boolean) => void
}

export type SelectTemplateItem = {
  key: string
  label: string
  className?: string
  disabled?: boolean
  icon: ReactNode
  iconBg: string
  thumbnail: ReactNode
  permissions?: string[]
}

export function PanelTableHeader({
  listTabs,
  viewType,
  searchStr,
  activeTab,
  openFilter,
  onChangeTab,
  onChangeSearch,
  onChangeViewType,
  setOpenFilter
}: Props) {
  const navigate = useNavigate()
  const [selectedTemplate, setSelectedTemplate] =
    useState<SelectTemplateItem | null>(null)
  const [isOpenPopupSelectTemplate, setIsOpenPopupSelectTemplate] =
    useState(false)

  const isCreateEmail = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [PERMISSIONS.template_email_create]
  })
  const isCreateLandingPage = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [PERMISSIONS.template_landing_page_create]
  })
  const isCreateAppPush = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [PERMISSIONS.template_app_push_create]
  })
  const isCreateWebPush = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [PERMISSIONS.template_web_push_create]
  })
  const isSearch = useCheckAuth({
    permissions: [PERMISSIONS.template_search]
  })
  const isFilter = useCheckAuth({
    permissions: [PERMISSIONS.template_filter]
  })
  const dropdownMenuProps = useMemo(
    () =>
      [
        {
          key: menuRoutes.template + '/new-email-template',
          label: 'Email',
          className: 'py-[9px] px-[16px] first:rounded-t-[6px]',
          disabled: isCreateEmail,
          icon: <Email className="text-[#2D87F3] w-6 h-6 block" />,
          iconBg: 'bg-[#E3F2FF]',
          thumbnail: <ThumbnailCreateEmail className="w-full h-auto" />
        },
        {
          key: menuRoutes.template + '/new-app-push',
          label: 'App Push',
          className: 'py-[9px] px-[16px]',
          disabled: isCreateAppPush,
          icon: <AppPush className="text-[#FE921D] w-6 h-6 block" />,
          iconBg: 'bg-[#FFF8E2]',
          thumbnail: <ThumbnailCreateAppPush className="w-full h-auto" />
        },
        {
          key: menuRoutes.template + '/new-web-push',
          label: 'Web Push',
          className: 'py-[9px] px-[16px]',
          disabled: isCreateWebPush,
          icon: <WebPush className="text-[#9E4CD1] w-6 h-6 block" />,
          iconBg: 'bg-[#F5EDFF]',
          thumbnail: <ThumbnailCreateWebPush className="w-full h-auto" />
        },
        {
          key: menuRoutes.template + '/new-landing-page',
          label: 'Landing Page',
          className: 'py-[9px] px-[16px]',
          disabled: isCreateLandingPage,
          icon: <Screen className="text-[#F44D2C] w-6 h-6 block" />,
          iconBg: 'bg-[#FAE9E8]',
          thumbnail: <ThumbnailCreateLandingPage className="w-full h-auto" />
        },
        {
          key: menuRoutes.template + '/new-whats-app',
          label: 'WhatsApp',
          className: 'py-[9px] px-[16px] last:rounded-b-[6px]',
          disabled: isCreateWebPush,
          icon: <WhatsappThin className="text-[#4CAF50] w-6 h-6 block" />,
          iconBg: 'bg-[#E0F8EE]',
          thumbnail: <ThumbnailCreateWhatsApp className="w-full h-auto" />
        },
        {
          key: menuRoutes.template + `/new-${TEMPLATE_TYPE.TWILIO}`,
          label: 'Twilio',
          className: 'py-[9px] px-[16px] last:rounded-b-[6px]',
          disabled: isCreateWebPush,
          icon: <TwilioSMS className="text-[#F22F46] w-6 h-6 block" />,
          iconBg: 'bg-[#feeaec]',
          thumbnail: <ThumbnailCreateSMS className="w-full h-auto" />
        },
        {
          key: menuRoutes.template + `/new-${TEMPLATE_TYPE.ZALO}`,
          label: 'Zalo',
          className: 'py-[9px] px-[16px] last:rounded-b-[6px]',
          disabled: isCreateWebPush,
          icon: <ZaloLogo className="w-6 h-6 block" />,
          iconBg: 'bg-[#E3F2FF]',
          thumbnail: <ThumbnailCreateDefault className="w-full h-auto" />
        }
      ].filter(Boolean) as SelectTemplateItem[],
    [isCreateEmail, isCreateLandingPage, isCreateAppPush, isCreateWebPush]
  )

  return (
    <StyledWrap className="panel-table-header">
      <PanelTabs
        listTabs={listTabs}
        activeTab={activeTab}
        onChangeTab={onChangeTab}
      />
      <StyledRight>
        <InputSearch
          disabled={!isSearch}
          value={searchStr}
          onChange={(e) => onChangeSearch(e.target.value)}
          placeholder="Search template"
        />
        <Button
          disabled={!isFilter}
          type={openFilter ? 'primary' : 'ghost'}
          className={openFilter ? 'active-filter' : ''}
          icon={<Icon component={Filter} />}
          onClick={() => setOpenFilter && setOpenFilter(!openFilter)}>
          Filter
        </Button>
        <StyleButton
          icon={<Plus />}
          type="primary"
          size="middle"
          onClick={() => {
            setIsOpenPopupSelectTemplate(true)
            setSelectedTemplate(dropdownMenuProps[0])
          }}>
          New template
        </StyleButton>
        <div>
          <Button
            onClick={() => {
              onChangeViewType(viewType === 'list' ? 'grid' : 'list')
            }}
            icon={<Icon component={viewType === 'list' ? Grid : List} />}
            size="middle"
            type={viewType === 'grid' ? 'primary' : 'default'}
          />
        </div>
      </StyledRight>
      <StyledModal
        width={709}
        open={isOpenPopupSelectTemplate}
        okText="Create Template"
        onCancel={() => {
          setIsOpenPopupSelectTemplate(false)
          setSelectedTemplate(null)
        }}
        onOk={() => {
          if (selectedTemplate) {
            navigate(selectedTemplate.key)
          }
        }}>
        <h2 className="pt-2 pb-4 text-[22px] leading-[32px] tracking-[-0.6px] font-semibold text-[##262626]">
          New Template
        </h2>
        <p className="text-base text-[#595959]">
          Select the template you want to create...
        </p>
        <Box className="flex flex-row gap-4">
          <Box className="flex flex-col flex-1 gap-1">
            {dropdownMenuProps.map((item) => {
              const isActive = item === selectedTemplate
              return (
                <Box
                  key={item.key}
                  onClick={() => setSelectedTemplate(item)}
                  className={cls(
                    'flex flex-row gap-4 items-center p-2 border border-solid rounded-[12px] cursor-pointer',
                    {
                      'border-[#F7F9FC]': !isActive,
                      'border-[#BCDEFF]': isActive
                    }
                  )}>
                  <Box className={cls(item.iconBg, 'p-2 rounded-[8px]')}>
                    {item.icon}
                  </Box>
                  <p className="m-0 text-[#111111]">{item.label}</p>
                  {isActive && (
                    <CheckedCircle className="text-[#2D87F3] w-[19px] h-[19px] ml-auto mr-1" />
                  )}
                </Box>
              )
            })}
          </Box>
          <Box className="flex-1">{selectedTemplate?.thumbnail}</Box>
        </Box>
      </StyledModal>
    </StyledWrap>
  )
}
