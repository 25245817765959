import { Checkbox } from 'antd'
import { CheckboxGroupProps } from 'antd/lib/checkbox'
import styled from 'styled-components'

export const StyleContainer = styled.div``

export const StyleCheckbox = styled(Checkbox.Group)<CheckboxGroupProps>`
  &.ant-checkbox-group {
    .ant-checkbox-wrapper {
      margin: 0;
      padding: 8px 25px;
      border: 1px solid ${({ theme }) => theme.colors.gray200};
      border-left: none;
      color: ${({ theme }) => theme.colors.gray800};
      background-color: ${({ theme }) => theme.colors.white};

      &:first-child {
        border-left: 1px solid ${({ theme }) => theme.colors.gray200};
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }

      .ant-checkbox {
        display: none;

        + span {
          padding: 0;
        }
      }

      &.ant-checkbox-wrapper-checked {
        background-color: ${({ theme }) => theme.colors.blue600};
        color: ${({ theme }) => theme.colors.white};
        .ant-checkbox-disabled {
          + span {
            color: ${({ theme }) => theme.colors.white};
          }
        }

        &.ant-checkbox-wrapper-disabled {
          background-color: ${({ theme }) => theme.colors.blue600};
        }
      }

      &.ant-checkbox-wrapper-disabled {
        background-color: ${({ theme }) => theme.colors.gray100};
      }
    }
  }
`
