import { StyledDropdownOverlay } from './index.styled'
import { Button, Dropdown, MenuProps } from 'antd'
import { Kebab } from 'icons'
import { ItemMenu } from 'interfaces'
import { useCallback } from 'react'

export type TableDetailDropdownProps = {
  items: ItemMenu[]
  onClick: (key: string) => void
}
export const TableDetailDropdown = (props: TableDetailDropdownProps) => {
  const { items, onClick: onClickProps } = props

  const onClick: MenuProps['onClick'] = useCallback(
    (item) => {
      onClickProps(item.key)
    },
    [onClickProps]
  )

  return (
    <Dropdown
      trigger={['click']}
      menu={{ items, onClick, className: 'menuDropdownTable' }}
      dropdownRender={(menuComp) => {
        return <StyledDropdownOverlay>{menuComp}</StyledDropdownOverlay>
      }}
      destroyPopupOnHide>
      <Button type="link" icon={<Kebab />} />
    </Dropdown>
  )
}

export default TableDetailDropdown
