import { Form, Spin, notification } from 'antd'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input'
import { menuRoutes } from 'components/molecules'
import { isProduction } from 'constants/env'
import { useAuth } from 'context/Auth'
import { EmailVerification, LogoHorizontal, Permission } from 'icons/V2'
import {
  AnalyticSharingAuthorizationCheckRes,
  AnalyticSharingRequestPermissionsAccessReq
} from 'interfaces/analytic'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AnalyticServices } from 'services/analytic'

export default function AnaLyticRequestPage() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { userAuth } = useAuth()
  const [checkUser, setCheckUser] =
    useState<AnalyticSharingAuthorizationCheckRes>()
  const [loadingContainer, setLoadingContainer] = useState(true)
  const [loading, setLoading] = useState(false)

  const initialValues = useMemo(() => {
    return { share_email: userAuth?.email }
  }, [userAuth?.email])
  const isSentRequest = useMemo(
    () => !!checkUser?.is_requested,
    [checkUser?.is_requested]
  )

  const share_url = isProduction
    ? `https://sidis.hellohealthgroup.com${menuRoutes.analytic}/${id}`
    : `https://sandbox-sidis.hellohealthgroup.com${menuRoutes.analytic}/${id}`

  const onFinish = useCallback(
    (values: any) => {
      if ((!values?.share_email && !userAuth?.email) || !id) {
        notification.error({ message: 'Something went wrong' })
        return
      }
      const req: AnalyticSharingRequestPermissionsAccessReq = {
        template_id: id,
        share_email: values?.share_email || userAuth?.email,
        share_url,
        request_message: values.request_message
      }
      setLoading(true)
      AnalyticServices.postAnalyticSharingRequestPermissionsAccess(req)
        .then((res) => {
          if (res.data?.template_id.toString() === id.toString()) {
            setCheckUser(
              (checkU) =>
                ({
                  ...checkU,
                  is_requested: true
                } as AnalyticSharingAuthorizationCheckRes)
            )
            notification.success({
              message: 'Your request was sent successfully'
            })
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [id, share_url, userAuth?.email]
  )

  useEffect(() => {
    if (!id || !userAuth?.email) {
      return
    }
    setLoadingContainer(true)
    AnalyticServices.postAnalyticSharingAuthorizationCheck({
      template_id: id,
      share_email: userAuth?.email
    })
      .then((res) => {
        if (!res.data) {
          return
        }

        setCheckUser(res.data)
        if (res.data?.is_granted) {
          navigate(`${menuRoutes.analytic}/${id}`)
        }
      })
      .finally(() => {
        setLoadingContainer(false)
      })
  }, [id, navigate, userAuth?.email])

  return (
    <div className="bg-neutral50 min-w-screen min-h-screen flex justify-center pt-[50px] lg:pt-[70px] pb-[50px] lg:pb-[76px] ">
      <div className="w-full max-w-[460px] text-center">
        <LogoHorizontal className="text-[32px] mb-10 text-primary" />
        <div className="w-full p-8 bg-white rounded-2xl min-h-[300px]">
          {!loadingContainer && (
            <>
              {!isSentRequest ? (
                <Form initialValues={initialValues} onFinish={onFinish}>
                  <Permission className="text-[180px] mb-6" />
                  <h2 className="title text-[26px] text-gray900 font-bold mb-2">
                    You need permission
                  </h2>
                  <p className="description text-gray600 mb-6">
                    Want it? Ask for access or login to a sidis account with
                    permission
                  </p>
                  <Form.Item shouldUpdate={!userAuth?.email} noStyle>
                    {!userAuth?.email && (
                      <Form.Item
                        name="share_email"
                        rules={[
                          {
                            required: true,
                            message: 'This field email is required'
                          },
                          { type: 'email', message: 'Input your right email' }
                        ]}
                        className="mb-2">
                        <Input
                          placeholder="Your email"
                          className="placeholder:text-gray600 rounded-lg py-2 px-4"
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item
                    name="request_message"
                    className="mb-6"
                    rules={[
                      {
                        async validator(rule, value) {
                          if (value.length <= 150) {
                            return true
                          }
                          return Promise.reject(
                            'Your request message is limit 150 characters'
                          )
                        }
                      }
                    ]}>
                    <Input.TextArea
                      rows={3}
                      maxLength={151}
                      placeholder="Message (option)"
                      className="placeholder:text-gray600 rounded-lg py-2 px-4"
                    />
                  </Form.Item>
                  <div className="flex gap-2 justify-center">
                    <Button
                      block={!userAuth?.email}
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      className="rounded-lg py-2 px-4 min-w-[40%]">
                      Request access
                    </Button>
                    {!userAuth?.email && (
                      <Button
                        block
                        className="rounded-lg py-2 px-4"
                        onClick={() => navigate('/login')}>
                        Log in to Sidis
                      </Button>
                    )}
                  </div>
                </Form>
              ) : (
                <>
                  <EmailVerification className="text-[180px] mb-4" />
                  <h2 className="title text-[26px] text-gray900 font-bold mb-2">
                    Request sent
                  </h2>
                  <p className="description text-gray600 text-base mb-6">
                    You’ll get an email letting you know if your request was
                    approved
                  </p>
                </>
              )}
            </>
          )}
          {loadingContainer && (
            <Spin
              spinning={loadingContainer || true}
              className="flex items-center justify-center min-h-[300px]"
            />
          )}
        </div>
      </div>
    </div>
  )
}
