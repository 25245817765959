import {
  StyledActions,
  StyledNav,
  StyledSteps,
  StyledWrap
} from './index.styled'
import { Button } from 'antd'
import { menuRoutes } from 'components/molecules'
import { PERMISSIONS } from 'constants/permission'
import { ROLES } from 'constants/role'
import { useCheckAuth } from 'hooks/useCheckAuth'
import { ReactComponent as ChevronLeft } from 'icons/ChevronLeft.svg'
import { Link } from 'react-router-dom'

// const { Step } = Steps

type Props = {
  stepNumber: number
  isEmailTemplate: boolean
  children: (step: number) => JSX.Element
  onChangeStep: (current: number) => void
  onContinue: () => void
  onSaveAsDraft: () => void
  showSaveAsDraft: boolean
  btnContinueText?: string
  btnSaveDraftText?: string
  btnContinueLoading?: boolean
  btnSaveDraftLoading?: boolean
  btnSaveDraftDisabled?: boolean
}

export function TopbarWithSteps({
  isEmailTemplate,
  stepNumber,
  onChangeStep,
  children,
  onContinue,
  onSaveAsDraft,
  showSaveAsDraft,
  btnContinueText = 'Continue',
  btnContinueLoading,
  btnSaveDraftText = 'Save as draft',
  btnSaveDraftLoading,
  btnSaveDraftDisabled
}: Props) {
  const isEdit = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [
      PERMISSIONS.template_email_edit,
      PERMISSIONS.template_landing_page_edit,
      PERMISSIONS.template_web_push_edit,
      PERMISSIONS.template_app_push_edit,
      PERMISSIONS.template_whatsapp_edit
    ]
  })

  return (
    <StyledWrap className="topbar-with-steps">
      <StyledNav>
        <Link to={menuRoutes.template}>
          <ChevronLeft />
          <span>
            {isEmailTemplate ? 'Email builder' : 'Landing Page builder'}
          </span>
        </Link>
      </StyledNav>
      <StyledSteps size="small" current={stepNumber} onChange={onChangeStep}>
        {children(stepNumber)}
      </StyledSteps>
      <StyledActions>
        {showSaveAsDraft && (
          <Button
            type="ghost"
            onClick={onSaveAsDraft}
            size="small"
            loading={btnSaveDraftLoading}
            disabled={isEdit || btnSaveDraftDisabled}>
            {btnSaveDraftText}
          </Button>
        )}
        <Button
          type="primary"
          onClick={onContinue}
          size="small"
          loading={btnContinueLoading}
          disabled={isEdit}>
          {btnContinueText}
        </Button>
      </StyledActions>
    </StyledWrap>
  )
}
