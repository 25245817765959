import {
  StyledTableCellTitle,
  StyledTableCellTitleH5,
  StyledTableCellTitleLink,
  StyledTableCellTitleP
} from './index.styled'
import { Tooltip } from 'antd'
import { useMemo, useRef } from 'react'

export type TableCellTitleProps = {
  to?: string
  title?: string
  titleLimitLines?: number
  subTitle?: string
  subTitleLimitLines?: number
  tooltip?: boolean
  className?: string
  style?: React.CSSProperties
}
export const TableCellTitle = (props: TableCellTitleProps) => {
  const {
    to,
    title,
    titleLimitLines = 1,
    subTitle,
    subTitleLimitLines = 2,
    tooltip = true,
    className,
    style
  } = props
  const titleRef = useRef(null)
  const titleComponent = useMemo(() => {
    if (!to) {
      return title
    }
    return <StyledTableCellTitleLink to={to}>{title}</StyledTableCellTitleLink>
  }, [title, to])

  return (
    <StyledTableCellTitle className={className} style={style}>
      {tooltip ? (
        <Tooltip
          overlay={title}
          getPopupContainer={(container) => {
            return container
          }}>
          <StyledTableCellTitleH5 ref={titleRef} limit={titleLimitLines}>
            {titleComponent}
          </StyledTableCellTitleH5>
        </Tooltip>
      ) : (
        <StyledTableCellTitleH5 limit={titleLimitLines}>
          {titleComponent}
        </StyledTableCellTitleH5>
      )}
      <StyledTableCellTitleP limit={subTitleLimitLines}>
        {subTitle}
      </StyledTableCellTitleP>
    </StyledTableCellTitle>
  )
}

export default TableCellTitle
