import { CampaignMessagingInsightFilterValue } from 'components/molecules/campaign/CampaignMessagingInsight/CampaignMessagingInsightFilter'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import {
  CAMPAIGN_INSIGHT_KEYS,
  InsightByFilterParams,
  InsightExportParams
} from 'interfaces/campaign'
import { CampaignServices } from 'services/campaign'

export const CAMPAIGN_MESSAGING_INSIGHT_TAB = {
  OVERVIEW: 'overview',
  PERFORMANCE: 'performance',
  AUDIENCE: 'audience',
  TIME_SERIES: 'time_series',
  LOCATION: 'location',
  DEVICES: 'devices'
}

export const getCampaignMessagingInsightMetricKey = (
  filter: CampaignMessagingInsightFilterValue
) => {
  const {
    date_recorded,
    sites,
    campaign_channels,
    campaigns,
    fieldKey,
    fieldValue,
    ...restInput
  } = filter
  return Object.keys(restInput)
    .flatMap((k) => restInput?.[k as keyof typeof restInput])
    .filter(Boolean) as string[]
}

export const getCampaignMessagingInsightFilter = async (
  input: InsightByFilterParams
) => {
  try {
    const response: any = await CampaignServices.insightByFilter(input)
    if (!response.data || response?._message) {
      throw new Error(JSON.stringify(response))
    }
    return { data: response.data, errors: [] }
  } catch (error) {
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}

export const postInsightExport = async (
  input?: CampaignMessagingInsightFilterValue
) => {
  if (!input) {
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
  if (
    !input.date_recorded?.[0].isValid() ||
    !input.date_recorded?.[1].isValid()
  ) {
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
  try {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.extend(advancedFormat)
    const params: InsightExportParams = {
      from: input.date_recorded[0].valueOf(),
      to: input.date_recorded[1].valueOf(),
      list_campaign: input.campaigns?.map(String) as string[],
      sites: input.sites || [],
      campaign_channels: input?.campaign_channels || 'All',
      get_insights_key: [
        CAMPAIGN_MESSAGING_INSIGHT_TAB.OVERVIEW,
        CAMPAIGN_MESSAGING_INSIGHT_TAB.PERFORMANCE
      ] as CAMPAIGN_INSIGHT_KEYS[],
      get_metrics_key: getCampaignMessagingInsightMetricKey(input),
      time_zone: dayjs().format('z zzz'),
      type: input.fieldKey || 'All',
      value: input.fieldValue || 'All'
    }
    const response: any = await CampaignServices.insightExport(params)
    if (response?._message) {
      throw new Error(JSON.stringify(response))
    }
    return { data: response.data, errors: [] }
  } catch (error) {
    console.log('** ERROR CampaignMessagingInsight.helpers.ts : ', error)
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}
