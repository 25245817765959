import SegmentTableHeader, {
  SegmentTableHeaderProps,
  SegmentTableHeaderValue
} from './SegmentTableHeader'
import {
  SegmentTableValue,
  segmentColumns,
  segmentColumnsDisabled,
  segmentColumnsTableAlwaysShow,
  segmentMenuItemsDetail,
  segmentTableValueDefault
} from './index.helpers'
import { StyledSegment, StyledTableHeaderCell } from './index.styled'
import { TablePaginationConfig } from 'antd'
import { TableColumnSort, TableScroll } from 'components/atoms/table'
import { SortKeys } from 'constants/segment'
import { useAuth } from 'context/Auth'
import { checkAuth } from 'hooks/useCheckAuth'
import { useTableScroll } from 'hooks/useTableScroll'
import { Segment } from 'interfaces/segment'
import { useEffect, useMemo, useRef, useState } from 'react'

export type SegmentTableProps = Pick<
  SegmentTableHeaderProps,
  | 'disabledSearch'
  | 'filterButtonProps'
  | 'openFilter'
  | 'setOpenFilter'
  | 'createButtonProps'
  | 'onCreate'
> & {
  loading?: boolean
  dataSource?: any[]
  value?: SegmentTableValue
  defaultValue?: SegmentTableValue
  onChange?: (value: SegmentTableValue) => void
  onClickDetail?: (id: Segment & any, type?: string) => void
}
export const SegmentTable = (props: SegmentTableProps) => {
  const {
    loading,
    dataSource,
    value: valueProps,
    defaultValue,
    disabledSearch,
    filterButtonProps,
    openFilter,
    setOpenFilter,
    onChange: onChangeProps,
    createButtonProps,
    onCreate,
    onClickDetail
  } = props
  const { userAuth } = useAuth()

  const menuItems = useMemo(
    () =>
      segmentMenuItemsDetail.map((menuItem) => {
        const disabled = !checkAuth(
          {
            roles: menuItem.roles,
            permissions: menuItem.permissions
          },
          userAuth?.auth || {}
        )

        return { ...menuItem, disabled }
      }),
    [userAuth?.auth]
  )
  const tableWrapRef = useRef<HTMLDivElement>(null)
  const { scrollTableVal, calculate } = useTableScroll(
    tableWrapRef,
    dataSource || []
  )
  const [value, setValue] = useState<SegmentTableValue>(
    valueProps || defaultValue || segmentTableValueDefault || {}
  )
  const columns = useMemo(
    () =>
      segmentColumns({
        menuItems,
        onMenuItemClick: onClickDetail
      }),
    [menuItems, onClickDetail]
  )
  const headerColumns = useMemo(
    () =>
      columns
        .map(({ key, title }) => {
          if (!title) {
            return
          }
          return {
            value: key,
            label: title
          }
        })
        .filter(Boolean)
        .map((column: any) => ({
          ...column,
          disabled: segmentColumnsDisabled.includes(column?.value)
        })),
    [columns]
  )
  const tableColumns = useMemo(
    () =>
      columns.filter(
        ({ key }) =>
          value.columns?.includes(key) ||
          segmentColumnsTableAlwaysShow.includes(key)
      ),
    [columns, value.columns]
  )
  const sortValue = useMemo(
    () => (value?.sort_by ? value.sort_type : undefined),
    [value?.sort_by, value?.sort_type]
  )
  const headerValue = useMemo(() => {
    return {
      search: value?.search,
      columns: value?.columns || segmentTableValueDefault.columns || [],
      created_from: value.created_from,
      created_to: value.created_to,
      types: value?.types
    }
  }, [
    value?.search,
    value?.columns,
    value?.created_from,
    value?.created_to,
    value?.types
  ])

  const onChange = (val: SegmentTableValue) => {
    if (val.search !== value.search || val.types !== value.types) {
      val.pagination = {
        ...val.pagination,
        current: 1
      }
    }
    setValue(val)
    onChangeProps?.(val)
  }

  const tableOnChange = (pagination: TablePaginationConfig) => {
    const newValue = { ...value, pagination: { ...pagination } }
    onChange(newValue)
  }

  const headerOnChange = (val: SegmentTableHeaderValue) => {
    const newValue = { ...value, ...val }
    onChange(newValue)
  }

  const sortOnChange = (key?: string) => {
    return (val: SortKeys) => {
      const newValue = { ...value, sort_by: key, sort_type: val }
      if (val === value.sort_type && key === value.sort_by) {
        newValue.sort_by = ''
      }
      newValue.pagination = {
        ...newValue.pagination,
        current: 1
      }
      onChange(newValue)
    }
  }

  const onTransitionEnd = () => {
    calculate()
  }

  useEffect(() => {
    setValue(valueProps || segmentTableValueDefault)
  }, [valueProps])

  return (
    <StyledSegment ref={tableWrapRef}>
      <TableScroll
        loading={loading}
        rowKey={(record) => record.id}
        columns={tableColumns}
        dataSource={dataSource}
        pagination={value?.pagination}
        scroll={{ y: scrollTableVal }}
        onChange={tableOnChange}
        components={{
          header: {
            cell: (props: any) => {
              const { children, ...restProps } = props
              if (children.includes('Segment')) {
                return (
                  <th {...restProps}>
                    <StyledTableHeaderCell className="name">
                      {children}
                      <TableColumnSort
                        disabled={filterButtonProps?.disabled}
                        value={sortValue}
                        onChange={sortOnChange('name')}
                      />
                    </StyledTableHeaderCell>
                  </th>
                )
              }
              return <th {...restProps}>{children}</th>
            }
          }
        }}
        title={() => (
          <SegmentTableHeader
            columns={headerColumns}
            value={headerValue}
            onChange={headerOnChange}
            onCreate={onCreate}
            disabledSearch={disabledSearch}
            onTransitionEnd={onTransitionEnd}
            filterButtonProps={filterButtonProps}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            createButtonProps={createButtonProps}
          />
        )}
      />
    </StyledSegment>
  )
}
