import { ConditionBaseProps } from '..'
import {
  StyledCondition,
  StyledConditionContent,
  StyledConditionInput,
  StyledConditionLabel
} from '../index.styled'
import { useEffect, useState } from 'react'

export type ConditionOperatorProps = ConditionBaseProps<string | null> & {
  label?: string
  placeholder?: string
}
export const ConditionText = (props: ConditionOperatorProps) => {
  const {
    label,
    className,
    placeholder,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    ...restProps
  } = props
  const [value, setValue] = useState(defaultValue || valueProps || null)

  const onChange = (val: string | null) => {
    const newValue = val
    setValue(newValue)
    onChangeProps && onChangeProps(newValue)
  }

  useEffect(() => {
    setValue(valueProps || null)
  }, [valueProps])

  return (
    <StyledCondition className={className}>
      {label && <StyledConditionLabel>{label}</StyledConditionLabel>}
      <StyledConditionContent>
        <StyledConditionInput
          placeholder={placeholder}
          value={value || undefined}
          onChange={(e) => {
            onChange(e.target.value)
          }}
          {...restProps}
        />
      </StyledConditionContent>
    </StyledCondition>
  )
}
