import {
  segmentTableHeaderValuePropsToState,
  segmentTableHeaderValueStateToProps
} from './index.helpers'
import {
  StyledButton,
  StyledCol,
  StyledRow,
  StyledSpace,
  StyledTabs
} from './index.styled'
import Icon from '@ant-design/icons'
import { Alert, Button, ButtonProps } from 'antd'
import { DropdownCheckbox } from 'components/atoms/dropdown'
import { InputSearch } from 'components/atoms/input'
import { SEGMENT_TYPE } from 'constants/segment'
import { Dayjs } from 'dayjs'
import { Column, Filter, Plus } from 'icons'
import { OptionType } from 'interfaces'
import { useEffect, useState } from 'react'

export type SegmentTableHeaderValue = {
  search?: string
  columns?: string[]
  types?: string
  sites?: number[]
  created_from?: string
  created_to?: string
  site_id?: number | string
  created?: any
}
export type SegmentTableHeaderValueState = Omit<
  SegmentTableHeaderValue,
  'created_form' | 'created_to'
> & {
  created_date?: [Dayjs, Dayjs] | null
}
export type SegmentTableHeaderProps = {
  columns?: OptionType[]
  value?: SegmentTableHeaderValue
  defaultValue?: SegmentTableHeaderValue
  disabledSearch?: boolean
  filterButtonProps?: Omit<
    ButtonProps,
    'onClick' | 'className' | 'type' | 'icon'
  >
  openFilter?: boolean
  setOpenFilter?: (val: boolean) => void
  onChange?: (value: SegmentTableHeaderValue) => void
  createButtonProps?: Omit<
    ButtonProps,
    'onClick' | 'className' | 'type' | 'icon'
  >
  onCreate?: () => void
  onTransitionEnd?: () => void
}
const SegmentTableHeader = (props: SegmentTableHeaderProps) => {
  const {
    columns,
    value: valueProps,
    defaultValue,
    disabledSearch,
    filterButtonProps,
    openFilter,
    setOpenFilter,
    onChange: onChangeProps,
    createButtonProps,
    onCreate,
    onTransitionEnd
  } = props
  const [value, setValue] = useState<SegmentTableHeaderValueState>(() => {
    return segmentTableHeaderValuePropsToState(valueProps || defaultValue || {})
  })

  const onChange = (val: SegmentTableHeaderValueState) => {
    setValue(val)
    onChangeProps?.(segmentTableHeaderValueStateToProps(val))
  }

  const onChangeByKey = (key: keyof SegmentTableHeaderValueState) => {
    return (val: any) => {
      const newValue = { ...value, [key]: val }
      onChange(newValue)
    }
  }

  useEffect(() => {
    setValue(() => segmentTableHeaderValuePropsToState(valueProps || {}))
  }, [valueProps])

  return (
    <StyledRow onTransitionEnd={onTransitionEnd}>
      <StyledCol span={10} className="colLeft">
        <StyledTabs
          activeKey={value.types}
          onChange={onChangeByKey('types')}
          items={[
            {
              label: 'Attribute Segment',
              key: SEGMENT_TYPE.ATTRIBUTE
            },
            {
              label: 'Event Segment',
              key: `${SEGMENT_TYPE.EVENT},${SEGMENT_TYPE.EVENT_CUSTOM}`
            },
            // { label: 'Custom Event Segment', key: SEGMENT_TYPE.EVENT_CUSTOM },
            {
              label: 'Mobile',
              key: SEGMENT_TYPE.MOBILE
            }
          ]}
        />
      </StyledCol>
      <StyledCol span={14} className="colRight">
        <StyledSpace size={16}>
          <InputSearch
            allowClear
            disabled={disabledSearch}
            value={value.search}
            onSearch={onChangeByKey('search')}
            placeholder="Search segment by name"
          />
          <Button
            type={openFilter ? 'primary' : 'ghost'}
            className={openFilter ? 'active-filter' : ''}
            icon={<Icon component={Filter} />}
            onClick={() => setOpenFilter?.(!openFilter)}
            {...filterButtonProps}>
            Filter
          </Button>
          <DropdownCheckbox
            options={columns}
            value={value?.columns}
            onOk={onChangeByKey('columns')}
            placement="bottomRight">
            <StyledButton
              type="ghost"
              icon={<Icon component={Column} />}
              className="btnColumn"
            />
          </DropdownCheckbox>
          <StyledButton
            onClick={onCreate}
            type="primary"
            icon={<Icon component={Plus} />}
            className="btnImport"
            {...createButtonProps}>
            New Segment
          </StyledButton>
        </StyledSpace>
      </StyledCol>
      <StyledCol span={24} className="mt-4">
        <Alert
          showIcon
          type="warning"
          message="You can view the status of each segment, click to publish your draft segment to create its view. Your Public Segments will be available in many features for you to select and use while creating campaigns and workflows."
        />
      </StyledCol>
    </StyledRow>
  )
}

export default SegmentTableHeader
