import { Alert } from 'antd'
import styled from 'styled-components'

export const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`

export const StyledCampaignLandingPageContainer = styled.div`
  padding: 16px 24px;
  &.container_1,
  &.container_2,
  &.container_3 {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .ant-drawer {
      z-index: 1;
      position: absolute;
    }
  }
`
