import { Skeleton } from 'antd'
import styled from 'styled-components'

export const StyledSkeletonFormItem = styled(Skeleton)``

export const StyledDictionaryForm = styled.div`
  .ant-form-item-control-input {
    min-height: auto;
  }
`
