import { StyleLogoutMessage } from './Auth.styled'
import { Modal } from 'antd'
import { LoadingFullScreen } from 'components/atoms/loading'
import { COOKIE_AUTH_TOKEN } from 'constants/common'
import { SITE_DOMAINS } from 'constants/site'
import Cookies from 'js-cookie'
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { getProfile, logout } from 'services/user'
import { plausibleAPI } from 'utils/plausibleTracking'

type AuthStatus = 'idle' | 'loading' | 'authorized' | 'unauthorized'

export interface AuthContextProps {
  status: AuthStatus
  userAuth: UserAuth | null
  signout?: () => void
  fetchUserProfile: () => void
}

const AuthContext = createContext<AuthContextProps>({
  status: 'idle',
  userAuth: null,
  fetchUserProfile: () => null
})
export default AuthContext

export const getToken = () => {
  return Cookies.get(COOKIE_AUTH_TOKEN)
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export const useAuthProvider = () => {
  const location = useLocation()
  const [status, setStatus] = useState<AuthStatus>('idle')
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null)

  const getUserProfile = useCallback(async () => {
    try {
      const profile = await getProfile()
      setUserProfile(profile)
      setStatus('authorized')
    } catch (err) {
      Cookies.remove(COOKIE_AUTH_TOKEN)
      setStatus('unauthorized')
    }
  }, [setStatus])

  const fetchUserProfile = useCallback(async () => {
    try {
      const profile = await getProfile()
      setUserProfile(profile)
    } catch (err) {
      //
    }
  }, [])

  const signout = useCallback(() => {
    Modal.confirm({
      title: 'Log out',
      content: (
        <StyleLogoutMessage>
          Are you sure you want to logout?
        </StyleLogoutMessage>
      ),
      icon: null,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        plausibleAPI.trackEvent('log_out', {
          props: {
            uid: userProfile?.id || '',
            un: userProfile?.name || '',
            ue: userProfile?.email || ''
          }
        })
        logout().then(console.log).catch(console.log)
        Cookies.remove(COOKIE_AUTH_TOKEN)
        window.location.href = '/login'
      }
    })
  }, [userProfile?.email, userProfile?.id, userProfile?.name])

  useEffect(() => {
    if (status === 'idle' && location.pathname !== '/login') {
      if (!getToken()) {
        setStatus('unauthorized')
      } else {
        getUserProfile()
      }
    }

    // Remove cookie when redirect to `/login` and status unauthorized
    if (status === 'unauthorized' && location.pathname === '/login') {
      Cookies.remove(COOKIE_AUTH_TOKEN)
    }
  }, [status, getUserProfile, location.pathname])

  return useMemo(() => {
    if (!userProfile) {
      return {
        status,
        userAuth: null,
        signout,
        fetchUserProfile
      }
    }

    const userAuth: UserAuth = {
      id: userProfile.id,
      uid: userProfile.uid,
      avatar: userProfile.avatar,
      first_name: userProfile.first_name,
      last_name: userProfile.last_name,
      name: userProfile.name,
      email: userProfile.email,
      website: userProfile.website,
      roleName: '',
      auth: {
        rolesActive: [],
        sitesActive: [],
        permissionsActive: [],
        rolesActiveId: []
      }
    }

    Object.keys(userProfile.roles).map((k) => {
      const roleKey = k as keyof typeof userProfile.roles
      const role = userProfile.roles[roleKey]
      userAuth.roleName = role.display_name
      userAuth.auth.rolesActiveId.push(role.id)
      userAuth.auth.rolesActive.push(role.name)
      userAuth.auth.permissionsActive.push(...role.permissions)
    })

    userProfile.sites.forEach((site) => {
      if (site.name in SITE_DOMAINS) {
        const SITE_KEY =
          SITE_DOMAINS?.[site.name as keyof typeof SITE_DOMAINS] || ''
        userAuth.auth.sitesActive.push(SITE_KEY)
      }
    })

    const returnValue: AuthContextProps = {
      status,
      userAuth,
      signout,
      fetchUserProfile
    }

    return returnValue
  }, [signout, status, userProfile, fetchUserProfile])
}

export const AuthContextComponent: FC = ({ children }) => {
  const { status } = useAuth()
  if (status === 'loading' || status === 'idle') {
    return <LoadingFullScreen />
  } else if (status === 'authorized') {
    return <>{children}</>
  }

  return <Navigate to={`/login?redirect=${window.location.href}`} replace />
}
