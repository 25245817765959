import { Carousel } from 'antd'
import { Box } from 'components/atoms/box'
import { memo } from 'react'

const FeatureCarousel = () => {
  return (
    <Box className="w-[40%] h-[100vh] min-w-0 relative hidden md:block">
      <Carousel
        effect="fade"
        autoplay
        autoplaySpeed={1500}
        draggable
        dotPosition="bottom"
        dots={{ className: '!bottom-[30px]' }}>
        <Box className="h-[100vh] p-[8px] overflow-hidden">
          <img
            src="/images/login-slider/login-bg-01.jpg"
            alt=""
            className="rounded-[16px] w-full h-full object-cover max-h-[100vh] object-left-top"
          />
        </Box>
        <Box className="h-[100vh] p-[8px] overflow-hidden">
          <img
            src="/images/login-slider/login-bg-02.jpg"
            alt=""
            className="rounded-[16px] w-full h-full object-cover max-h-[100vh] object-left-top"
          />
        </Box>
        <Box className="h-[100vh] p-[8px] overflow-hidden">
          <img
            src="/images/login-slider/login-bg-03.jpg"
            alt=""
            className="rounded-[16px] w-full h-full object-cover max-h-[100vh] object-left-top"
          />
        </Box>
      </Carousel>
    </Box>
  )
}

export default memo(FeatureCarousel)
