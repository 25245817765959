import { StyleDescription } from './index.styled'
import { Form, Input } from 'antd'
import { ModalEmpty } from 'components/atoms/modal'
import { FC } from 'react'

interface ModalSendEmailProps {
  open: boolean
  setOpenSendEmail?: (value: boolean) => void
  onSendEmail?: (email: string) => void
}

export const ModalSendEmail: FC<ModalSendEmailProps> = ({
  open,
  onSendEmail,
  setOpenSendEmail
}) => {
  const [form] = Form.useForm()
  return (
    <ModalEmpty
      centered
      open={open}
      title="Send test email"
      cancelText="Cancel"
      width={460}
      okText="Send"
      afterClose={() => {
        form && form.setFieldsValue({ email: null })
      }}
      onOk={() => {
        form &&
          form.validateFields().then((values) => {
            onSendEmail && onSendEmail(values.email)
          })
      }}
      onCancel={() => setOpenSendEmail && setOpenSendEmail(false)}>
      <StyleDescription>
        Send a test email to this address. Ensure to select a template before
        send a test email.
      </StyleDescription>
      <br />
      <Form form={form}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'This field is required.'
            },
            {
              type: 'email',
              message: 'Email is wrong.'
            }
          ]}>
          <Input placeholder="Enter your email" />
        </Form.Item>
      </Form>
    </ModalEmpty>
  )
}
