import {
  AnalyticWidgetItemProps,
  WIDGET_ITEM_HIGHCHARTS_EMPTY_PROPS,
  usePeriodLabel
} from './AnalyticWidgetItem.helpers'
import { Select } from 'antd'
import { HighchartsLine } from 'components/atoms/Highcharts'
import { theme } from 'constants/theme'
import { FC, useState } from 'react'

const TOP_VIEW_CATEGORIES_OPTIONS = [
  { label: 'Category 1 level 1', value: 'category_1' },
  { label: 'Category 1 level 2', value: 'category_2' },
  { label: 'Category 1 level 3', value: 'category_3' }
  // { label: 'Category 2 level 1', value: 'most_visit_category_2_en_1' },
  // { label: 'Category 2 level 2', value: 'most_visit_category_2_en_2' },
  // { label: 'Category 2 level 3', value: 'most_visit_category_2_en_3' },
  // { label: 'Category 3 level 1', value: 'most_visit_category_3_en_1' },
  // { label: 'Category 3 level 2', value: 'most_visit_category_3_en_2' },
  // { label: 'Category 3 level 3', value: 'most_visit_category_3_en_3' }
]

export const AnalyticWidgetItemTopViewCategorise: FC<
  AnalyticWidgetItemProps
> = (props) => {
  const { data, item } = props
  const [cat, setCat] = useState('category_1')
  const label = usePeriodLabel(item?.query?.period)
  const highchartsOptions = formatTopViewCategorise(data, cat)

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between">
        <h4 className="period mb-0 text-gray600">{label}</h4>
        <div className="flex items-center justify-start gap-2">
          <strong>Category</strong>
          <Select
            size="small"
            options={TOP_VIEW_CATEGORIES_OPTIONS}
            value={cat}
            onChange={setCat}
          />
        </div>
      </div>
      <div className="flex-1 min-h-0">
        <HighchartsLine
          options={highchartsOptions}
          className="h-full"
          emptyProps={WIDGET_ITEM_HIGHCHARTS_EMPTY_PROPS}
        />
      </div>
    </div>
  )
}

export function formatTopViewCategorise(
  data: any,
  cat = 'category_1'
): Highcharts.Options {
  const highchartsOptions: Highcharts.Options = {
    xAxis: {
      labels: { enabled: true },
      lineWidth: 1,
      crosshair: true
    },
    yAxis: {
      labels: { enabled: true },
      lineWidth: 1,
      gridLineDashStyle: 'Dot'
    },
    series: [],
    tooltip: {
      headerFormat: '{point.x}: ',
      pointFormat: `<strong>{point.y}%</strong>`
    },
    plotOptions: {
      bar: {
        pointWidth: 16
      }
    }
  }

  try {
    const dataCat = data?.[cat] || {}
    if (Object.keys(dataCat).length) {
      const cat = Object.keys(dataCat)
        .map((key) => ({
          value: parseInt(dataCat[key] || 0),
          label: key
        }))
        .sort(({ value: valA }: any, { value: valB }: any) => {
          return valA > valB ? -1 : 0
        })
        .slice(0, 10)
      const maxNumber = Math.max(...cat.map((item) => item.value))
      const catPercentage = cat.map((item) => ({
        value: Number(((item.value / maxNumber) * 100).toFixed(2)),
        label: item.label
      }))

      ;(highchartsOptions.xAxis as any).categories = catPercentage.map(
        ({ label }: any) => (label || '') as string
      )
      highchartsOptions.series?.push({
        type: 'bar',
        color: theme?.colors?.blue600,
        data: catPercentage.map(({ value }: any) => value) || []
      })
    }
  } catch (error) {}
  return highchartsOptions
}

// const mockup = {
//   most_visit_category_1_en_1: {
//     ' Orthopedics/Musculoskeletal': 24,
//     Allergies: 18,
//     'Blood Disorders': 3,
//     'Brain and Nervous System': 18,
//     Cancer: 34,
//     Diabetes: 42,
//     'Digestive Health': 45,
//     'Drugs and Supplements': 112,
//     ENT: 39,
//     'Eye Health': 6,
//     Health: 33,
//     'Health Fitness': 14,
//     'Health Tools': 236,
//     'Healthy Aging': 4,
//     'Healthy Eating': 203,
//     'Healthy Habits': 34,
//     'Healthy Mind': 35,
//     'Healthy Sleep': 10,
//     'Heart Health': 130,
//     'Herbals and Alternatives': 30,
//     Home: 12,
//     'Infectious Diseases': 13,
//     "Men's Health": 21,
//     'Oral health': 15,
//     Parenting: 42,
//     Pregnancy: 167,
//     'Respiratory Health': 33,
//     'Sexual Wellness': 37,
//     'Skin Health': 110,
//     'Urological Health': 84,
//     "Women's Health": 54
//   },
//   most_visit_category_1_en_2: {
//     ' Pregnancy Weight Calculator': 13,
//     "A Good Night's Sleep": 3,
//     Acne: 3,
//     Addiction: 2,
//     Adolescents: 1,
//     'Aging Body': 3,
//     'Alternative Medicines': 1,
//     Anemia: 3,
//     Arthritis: 2,
//     Asthma: 1,
//     Atherosclerosis: 1,
//     'BMI Calculator': 52,
//     'BMR Calculator': 35,
//     Baby: 23,
//     'Baby Growth Chart': 4,
//     'Baby Vaccination Scheduler': 1,
//     'Balance and Flexibility': 1,
//     'Being Pregnant': 139,
//     'Bladder Problems': 6,
//     'Breast Cancer': 3,
//     Bronchitis: 2,
//     'Cervical Cancer': 4,
//     'Child Health': 13,
//     Cholesterol: 19,
//     'Colorectal Cancer': 5,
//     'Common cold': 6,
//     Contraception: 3,
//     'Coronary Artery Disease': 6,
//     Coronavirus: 2,
//     "Dementia and Alzheimer's Disease": 6,
//     Dermatitis: 8,
//     'Diabetes Complications': 5,
//     Diarrhea: 1,
//     'Diet and Weight Loss': 3,
//     'Due Date Calculator': 48,
//     'Ear Conditions': 21,
//     'Eating Disorders': 3,
//     Emphysema: 2,
//     'Erectile Dysfunction': 6,
//     'Exercise Safety Tips': 2,
//     'Eye Diseases': 3,
//     'Eye care': 2,
//     'First Aid': 13,
//     'Fitness motivation': 2,
//     'Foodborne Infections': 2,
//     'Fractures and Broken Bones': 1,
//     GERD: 7,
//     Gastroenteritis: 4,
//     'Gastrointestinal Screener': 1,
//     'General Health Knowledge': 12,
//     'General Hygiene': 11,
//     'Getting Pregnant': 12,
//     'Giving Birth': 8,
//     Gonorrhea: 1,
//     'Gum and Mouth Diseases': 9,
//     'HIV/AIDS': 1,
//     HPV: 1,
//     'Hair and Scalp Care': 6,
//     'Headaches and Migraines': 2,
//     'Health news': 2,
//     'Healthy Recipes': 5,
//     'Healthy Relationships': 1,
//     'Heart Arrhythmias': 19,
//     'Heart Disease': 15,
//     'Heart Failure': 10,
//     'Heart Infections': 1,
//     'Heart Valve Disease': 2,
//     'Herbal Medicines': 29,
//     Hypertension: 33,
//     'Infections from Insects': 4,
//     'Infectious Skin Diseases': 11,
//     'Inflammatory Bowel Disease': 1,
//     Influenza: 3,
//     Insomnia: 2,
//     'Irritable Bowel Syndrome': 1,
//     'Kidney Disease': 44,
//     Leukemia: 1,
//     'Liver Cancer': 2,
//     'Liver Diseases': 7,
//     'Lung Cancer': 7,
//     'Medical Procedures and Surgeries': 4,
//     'Medical Tests': 1,
//     "Men's Hormone Issues": 2,
//     Meningitis: 1,
//     Menopause: 3,
//     Menstruation: 15,
//     'Mood Disorder': 6,
//     'More STIs and STDs': 3,
//     Mothercare: 7,
//     'Musculoskeletal Pain': 12,
//     'Myoma and Cysts': 4,
//     'Nail care': 10,
//     'Nose Conditions': 10,
//     'Nose and Eye': 6,
//     'Nutrition Facts': 129,
//     'Oral care': 3,
//     'Other Allergies': 2,
//     'Other Cancers': 1,
//     'Other Cardiovascular Issues': 7,
//     'Other Digestive Health Issues': 20,
//     'Other Fitness Activities': 3,
//     'Other Healthy Eating Tips': 44,
//     'Other Joint and Tendon Diseases': 6,
//     "Other Men's Health Issues": 3,
//     'Other Mental Health Issues': 20,
//     'Other Oral Health Issues': 3,
//     'Other Respiratory Issues': 9,
//     'Other Skin Diseases': 51,
//     'Other Urological Issues': 4,
//     'Other bacterial infections': 4,
//     'Other bone diseases': 2,
//     'Ovulation Calculator': 55,
//     "Parkinson's Disease": 1,
//     'Pelvic Related Issue': 1,
//     'Penis Health': 8,
//     'Peripheral Nervous System Disorders': 3,
//     Pneumonia: 4,
//     'Prediabetic Screener': 2,
//     'Prostate Cancer': 2,
//     'Prostate Health': 4,
//     Psoriasis: 3,
//     'Quit Smoking': 1,
//     'Safe Sex': 4,
//     'Seizure Disorders': 3,
//     'Sex Tips': 23,
//     'Skin Allergies': 9,
//     'Skin Cancer': 2,
//     'Skin Care and Cleansing': 11,
//     'Sleep Disorders': 4,
//     'Special Diets': 5,
//     'Sports Nutrition': 2,
//     'Stomach Cancer': 2,
//     'Strength Training': 2,
//     'Stress Management': 2,
//     'Stroke and Aneurysm': 3,
//     Symptoms: 14,
//     'Target Heart Rate Calculator': 13,
//     'Throat Conditions': 8,
//     'Toddler and Preschooler': 5,
//     Tuberculosis: 1,
//     'Type 1 Diabetes': 3,
//     'Type 2 Diabetes': 8,
//     UTI: 7,
//     'Urinary Incontinence': 4,
//     'Urinary Tract Stones': 5,
//     'Weight Loss Exercises': 3,
//     "Women's Health Issues": 22,
//     chlamydia: 1,
//     'chronic-bronchitis': 1
//   },
//   most_visit_category_1_en_3: {
//     'Baby Care': 13,
//     'Baby First Year': 6,
//     'Behavioral and Developmental Disorders': 1,
//     Breastfeeding: 3,
//     'First trimester': 75,
//     'Labor and Delivery': 5,
//     Malnutrition: 1,
//     'Mental Health': 1,
//     'Milestones and Development': 1,
//     'My Story': 1,
//     'Other Child Health Issues': 4,
//     'Other Conception Topics': 1,
//     'Postpartum Period': 3,
//     'Postpartum Selfcare': 2,
//     'Pregnancy Problems': 5,
//     'Premature babies': 1,
//     'Prenatal Care': 24,
//     Preparing: 3,
//     'Preparing to Get Pregnant': 11,
//     'Second trimester': 22,
//     'Skin Diseases in Children': 4,
//     'Third Trimester': 9,
//     'Toddler and Preschooler Growth and Development': 4,
//     'Toddler and Preschooler Milestones': 1,
//     Vaccines: 3
//   },
//   most_visit_category_2_en_1: {
//     ' Orthopedics/Musculoskeletal': 12,
//     Allergies: 8,
//     'Blood Disorders': 5,
//     'Brain and Nervous System': 11,
//     Cancer: 31,
//     Diabetes: 28,
//     'Digestive Health': 40,
//     'Drugs and Supplements': 75,
//     ENT: 20,
//     'Eye Health': 6,
//     Health: 14,
//     'Health Fitness': 7,
//     'Health Tools': 123,
//     'Healthy Aging': 2,
//     'Healthy Eating': 106,
//     'Healthy Habits': 15,
//     'Healthy Mind': 16,
//     'Healthy Sleep': 9,
//     'Heart Health': 82,
//     'Herbals and Alternatives': 12,
//     Home: 12,
//     'Infectious Diseases': 10,
//     "Men's Health": 10,
//     'Oral health': 11,
//     Parenting: 40,
//     Pregnancy: 150,
//     'Respiratory Health': 22,
//     'Sexual Wellness': 22,
//     'Skin Health': 59,
//     'Urological Health': 51,
//     "Women's Health": 41
//   },
//   most_visit_category_2_en_2: {
//     ' Common Aging Conditions': 1,
//     ' Pregnancy Weight Calculator': 6,
//     "A Good Night's Sleep": 3,
//     Acne: 4,
//     'Alternative Medicines': 1,
//     Anemia: 2,
//     'Anxiety Disorder': 3,
//     Arthritis: 1,
//     Asthma: 2,
//     Atherosclerosis: 1,
//     'BMI Calculator': 28,
//     'BMR Calculator': 26,
//     Baby: 23,
//     'Baby Growth Chart': 2,
//     'Baby Vaccination Scheduler': 1,
//     'Being Pregnant': 131,
//     'Bladder Problems': 7,
//     'Bloating and Gas': 5,
//     'Brain and Spine Disorders': 1,
//     'Breast Cancer': 3,
//     Bronchitis: 2,
//     'Cardio and Endurance': 1,
//     'Cervical Cancer': 3,
//     'Child Health': 14,
//     Cholesterol: 10,
//     'Colorectal Cancer': 3,
//     'Common cold': 3,
//     Contraception: 2,
//     'Coronary Artery Disease': 2,
//     Coronavirus: 3,
//     "Dementia and Alzheimer's Disease": 5,
//     Dermatitis: 8,
//     'Diabetes Complications': 1,
//     'Diet and Weight Loss': 2,
//     'Due Date Calculator': 25,
//     'Ear Conditions': 10,
//     'Eating Disorders': 2,
//     Emphysema: 1,
//     'Erectile Dysfunction': 2,
//     'Exercise Safety Tips': 2,
//     'Eye Diseases': 4,
//     'Eye care': 1,
//     'First Aid': 9,
//     'Fitness motivation': 1,
//     'Foodborne Infections': 2,
//     GERD: 3,
//     Gastroenteritis: 5,
//     'General Health Knowledge': 6,
//     'General Hygiene': 2,
//     'Getting Pregnant': 8,
//     'Giving Birth': 5,
//     'Gum and Mouth Diseases': 8,
//     'Hair and Scalp Care': 3,
//     'Headaches and Migraines': 2,
//     'Health news': 2,
//     'Healthy Recipes': 3,
//     'Heart Arrhythmias': 10,
//     'Heart Disease': 13,
//     'Heart Failure': 11,
//     Hemophilia: 1,
//     'Herbal Medicines': 11,
//     Hypertension: 16,
//     'Infections from Insects': 2,
//     'Infectious Skin Diseases': 5,
//     'Inflammatory Bowel Disease': 1,
//     Influenza: 2,
//     Insomnia: 2,
//     'Kidney Disease': 25,
//     Leukemia: 3,
//     'Liver Cancer': 2,
//     'Liver Diseases': 6,
//     'Lung Cancer': 4,
//     'Medical Procedures and Surgeries': 2,
//     "Men's Hormone Issues": 2,
//     Menopause: 1,
//     Menstruation: 6,
//     Mothercare: 5,
//     'Musculoskeletal Pain': 4,
//     'Myoma and Cysts': 6,
//     'Nail care': 5,
//     'Nose Conditions': 4,
//     'Nose and Eye': 4,
//     'Nutrition Facts': 58,
//     'Other Blood Disorders': 1,
//     'Other Brain and Nervous System Issues': 1,
//     'Other Cancers': 5,
//     'Other Cardiovascular Issues': 13,
//     'Other Digestive Health Issues': 15,
//     'Other Fitness Activities': 3,
//     'Other Healthy Eating Tips': 24,
//     'Other Joint and Tendon Diseases': 4,
//     "Other Men's Health Issues": 2,
//     'Other Mental Health Issues': 7,
//     'Other Oral Health Issues': 3,
//     'Other Respiratory Issues': 8,
//     'Other Skin Diseases': 22,
//     'Other Urological Issues': 3,
//     'Other bacterial infections': 3,
//     'Other bone diseases': 1,
//     'Ovulation Calculator': 16,
//     PCOS: 1,
//     'Penis Health': 1,
//     'Peripheral Nervous System Disorders': 1,
//     'Platelet disorder': 1,
//     Pneumonia: 4,
//     'Prediabetic Screener': 2,
//     'Prostate Cancer': 1,
//     'Prostate Health': 2,
//     Psoriasis: 2,
//     'Safe Sex': 8,
//     'Seizure Disorders': 1,
//     'Sex Tips': 11,
//     'Skin Allergies': 3,
//     'Skin Cancer': 1,
//     'Skin Care and Cleansing': 4,
//     'Sleep Apnea': 1,
//     'Sleep Disorders': 1,
//     'Special Diets': 1,
//     'Sports Nutrition': 2,
//     'Stress Management': 2,
//     'Suicide Prevention': 1,
//     Symptoms: 4,
//     'Target Heart Rate Calculator': 5,
//     'Throat Conditions': 6,
//     'Toddler and Preschooler': 2,
//     'Type 1 Diabetes': 1,
//     'Type 2 Diabetes': 9,
//     UTI: 7,
//     'Urinary Incontinence': 4,
//     'Vision Impairment and Loss': 1,
//     "Women's Health Issues": 16
//   },
//   most_visit_category_2_en_3: {
//     'Baby Care': 12,
//     'Baby First Year': 3,
//     'Baby Nutrition': 3,
//     Breastfeeding: 5,
//     'First trimester': 72,
//     'Labor and Delivery': 4,
//     Malnutrition: 1,
//     'Mental Health': 2,
//     'Other Child Health Issues': 4,
//     'Other Conception Topics': 2,
//     'Postpartum Period': 3,
//     'Pregnancy Problems': 7,
//     'Prenatal Care': 19,
//     Preparing: 1,
//     'Preparing to Get Pregnant': 6,
//     'Second trimester': 15,
//     'Skin Diseases in Children': 2,
//     'Third Trimester': 11,
//     'Toddler and Preschooler Growth and Development': 2,
//     Vaccines: 6
//   },
//   most_visit_category_3_en_1: {
//     ' Orthopedics/Musculoskeletal': 7,
//     Allergies: 9,
//     'Blood Disorders': 2,
//     'Brain and Nervous System': 7,
//     Cancer: 21,
//     Diabetes: 22,
//     'Digestive Health': 29,
//     'Drugs and Supplements': 44,
//     ENT: 10,
//     'Eye Health': 5,
//     Health: 9,
//     'Health Fitness': 3,
//     'Health Tools': 62,
//     'Healthy Aging': 3,
//     'Healthy Eating': 56,
//     'Healthy Habits': 14,
//     'Healthy Mind': 6,
//     'Healthy Sleep': 4,
//     'Heart Health': 41,
//     'Herbals and Alternatives': 13,
//     Home: 12,
//     'Infectious Diseases': 4,
//     "Men's Health": 8,
//     'Oral health': 4,
//     Parenting: 19,
//     Pregnancy: 111,
//     'Respiratory Health': 14,
//     'Sexual Wellness': 17,
//     'Skin Health': 26,
//     'Urological Health': 24,
//     "Women's Health": 19
//   },
//   most_visit_category_3_en_2: {
//     ' Common Aging Conditions': 1,
//     ' Pregnancy Weight Calculator': 8,
//     'Active Aging': 1,
//     'Alternative Medicines': 1,
//     Anemia: 2,
//     'Anxiety Disorder': 2,
//     'BMI Calculator': 10,
//     'BMR Calculator': 7,
//     Baby: 15,
//     'Baby Vaccination Scheduler': 2,
//     "Baby's stool ": 1,
//     'Being Pregnant': 98,
//     'Bladder Problems': 1,
//     'Bloating and Gas': 3,
//     'Breast Cancer': 1,
//     Bronchitis: 1,
//     'Cervical Cancer': 1,
//     'Child Health': 4,
//     Cholesterol: 4,
//     'Colorectal Cancer': 3,
//     'Common cold': 1,
//     Contraception: 4,
//     'Coronary Artery Disease': 1,
//     Coronavirus: 1,
//     "Dementia and Alzheimer's Disease": 2,
//     'Diabetes Complications': 4,
//     'Diet and Weight Loss': 1,
//     'Due Date Calculator': 13,
//     'Ear Conditions': 2,
//     'Eating Disorders': 1,
//     Emphysema: 1,
//     'Erectile Dysfunction': 2,
//     'Eye Diseases': 3,
//     'Eye care': 1,
//     'First Aid': 6,
//     'Fitness motivation': 1,
//     GERD: 2,
//     Gastroenteritis: 6,
//     'Gastrointestinal Screener': 1,
//     'General Health Knowledge': 3,
//     'General Hygiene': 3,
//     'Getting Pregnant': 6,
//     'Gum and Mouth Diseases': 2,
//     'HIV/AIDS': 3,
//     'Hair and Scalp Care': 2,
//     'Healthy Recipes': 1,
//     'Heart Arrhythmias': 9,
//     'Heart Disease': 4,
//     'Heart Failure': 1,
//     'Heart Valve Disease': 2,
//     'Herbal Medicines': 12,
//     Hypertension: 13,
//     'Infections from Insects': 2,
//     'Infectious Skin Diseases': 1,
//     'Irritable Bowel Syndrome': 2,
//     'Kidney Disease': 15,
//     Leukemia: 1,
//     'Liver Cancer': 1,
//     'Liver Diseases': 1,
//     'Medical Procedures and Surgeries': 1,
//     "Men's Hormone Issues": 1,
//     Menopause: 1,
//     Menstruation: 7,
//     'Mood Disorder': 1,
//     'More STIs and STDs': 1,
//     Mothercare: 5,
//     'Musculoskeletal Pain': 1,
//     'Myoma and Cysts': 1,
//     'Nail care': 5,
//     'Nose Conditions': 5,
//     'Nose and Eye': 4,
//     'Nutrition Facts': 34,
//     'Other Allergies': 1,
//     'Other Cancers': 1,
//     'Other Cardiovascular Issues': 3,
//     'Other Digestive Health Issues': 12,
//     'Other Healthy Eating Tips': 9,
//     'Other Joint and Tendon Diseases': 6,
//     "Other Men's Health Issues": 1,
//     'Other Mental Health Issues': 1,
//     'Other Oral Health Issues': 2,
//     'Other Respiratory Issues': 3,
//     'Other Skin Diseases': 15,
//     'Other Urological Issues': 1,
//     'Other viral infections': 1,
//     'Ovarian Cancer': 1,
//     'Ovulation Calculator': 11,
//     "Parkinson's Disease": 1,
//     'Penis Health': 4,
//     'Peripheral Nervous System Disorders': 2,
//     Pneumonia: 4,
//     'Prediabetic Screener': 3,
//     'Prostate Cancer': 1,
//     'Pulmonary Embolism': 2,
//     'Quit Smoking': 3,
//     'Safe Sex': 4,
//     'Seizure Disorders': 1,
//     'Sex Tips': 5,
//     'Skin Allergies': 4,
//     'Skin Cancer': 2,
//     'Sleep Apnea': 1,
//     'Sleep Disorders': 2,
//     'Special Diets': 2,
//     'Sports Nutrition': 2,
//     'Stomach Cancer': 1,
//     'Stress Management': 1,
//     'Stroke and Aneurysm': 1,
//     Symptoms: 5,
//     'Target Heart Rate Calculator': 4,
//     'Throat Conditions': 3,
//     'Type 1 Diabetes': 1,
//     'Type 2 Diabetes': 4,
//     UTI: 2,
//     'Urinary Incontinence': 1,
//     'Urinary Tract Stones': 1,
//     'Vision Impairment and Loss': 1,
//     'Weight Loss Exercises': 2,
//     "Women's Health Issues": 7
//   },
//   most_visit_category_3_en_3: {
//     'Baby Care': 7,
//     'Baby First Year': 4,
//     'Baby Nutrition': 1,
//     Breastfeeding: 2,
//     'First trimester': 51,
//     'Mental Health': 2,
//     'Other Child Health Issues': 2,
//     'Other Conception Topics': 1,
//     'Postpartum Period': 2,
//     'Postpartum Selfcare': 1,
//     'Pregnancy Problems': 10,
//     'Premature babies': 1,
//     'Prenatal Care': 18,
//     'Preparing to Get Pregnant': 5,
//     'Respiratory Diseases': 1,
//     'Second trimester': 9,
//     'Third Trimester': 8,
//     Vaccines: 1
//   }
// }
