import { ConditionBaseProps } from '..'
import {
  StyledCondition,
  StyledConditionContent,
  StyledConditionLabel,
  StyledConditionSelect
} from '../index.styled'
import { SelectProps } from 'antd'
import cn from 'classnames'
import { OptionType } from 'interfaces'
import { useEffect, useState } from 'react'

export type ConditionSelectComponentProps = Pick<
  SelectProps,
  'optionLabelProp' | 'placeholder' | 'allowClear' | 'onBlur'
> &
  ConditionBaseProps<string | number | boolean | undefined> & {
    options?: OptionType[]
  }
export const ConditionSelectComponent = (
  props: ConditionSelectComponentProps
) => {
  const {
    className,
    options,
    placeholder,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    ...restProps
  } = props
  const [value, setValue] = useState<string | number | boolean | undefined>(
    defaultValue || valueProps
  )

  const onChange = (val: unknown) => {
    const newValue = val as string | number | boolean | undefined
    setValue(newValue)
    onChangeProps && onChangeProps(newValue)
  }

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  return (
    <StyledConditionSelect
      options={options}
      value={value}
      onChange={onChange}
      allowClear
      className={cn(className, 'full--width')}
      placeholder={placeholder}
      {...restProps}
    />
  )
}

export type ConditionSelectProps = ConditionSelectComponentProps & {
  label?: string
}
export const ConditionSelect = (props: ConditionSelectProps) => {
  const { label, className, ...restProps } = props

  return (
    <StyledCondition className={className}>
      {label && <StyledConditionLabel>{label}</StyledConditionLabel>}
      <StyledConditionContent>
        <ConditionSelectComponent {...restProps} />
      </StyledConditionContent>
    </StyledCondition>
  )
}
