import { useQuery } from '@tanstack/react-query'
import { DEFAULT_GET_ALL_RECORDS } from 'constants/common'
import { TagQueryParams } from 'interfaces/tag'
import { useCallback, useMemo, useState } from 'react'
import { tagServices } from 'services/segment'

export function useTags({
  page = DEFAULT_GET_ALL_RECORDS.page,
  per_page = DEFAULT_GET_ALL_RECORDS.per_page,
  ...restProps
}: TagQueryParams = {}) {
  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState<any[]>([])

  useQuery({
    queryKey: ['getTags', page, per_page],
    queryFn: async () => {
      try {
        setLoading(true)
        const response = await tagServices.getTagsList({
          page,
          per_page,
          ...restProps
        })
        if (!response.data || response._message) {
          throw new Error(JSON.stringify(response))
        }
        setTags(response.data.data)
        return { data: response.data, errors: [] }
      } catch (error) {
        console.log('** useTags Error :', error)
      } finally {
        setLoading(false)
      }
    }
    // enabled:
  })

  const createTag = useCallback(
    async ({ name, type }: { name: string; type: string }) => {
      try {
        setLoading(true)
        const response: any = await tagServices.createTag({ name, type })
        if (!response.data || response._message) {
          throw new Error(JSON.stringify(response))
        }

        return { data: response.data, errors: [] }
      } catch (error) {
        console.log('** useTags Error create :', error)
      } finally {
        setLoading(false)
      }
      return { data: null, errors: [{ errors: ['Somthing went wrong'] }] }
    },
    []
  )

  return useMemo(
    () => ({ loading, setLoading, tags, setTags, createTag }),
    [createTag, loading, tags]
  )
}
