import { Result, Skeleton } from 'antd'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

export const StyledStepSitesLabel = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
`

export const StyledStepSitesTitle = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.2px;
`

export const StyledStepSitesResult = styled(Result)`
  &.no_data {
    padding: 142px 0;
    .ant-result-icon {
      margin-bottom: 16px;
      font-size: 80px;
      line-height: 0;
      > .anticon {
        font-size: inherit;
      }
    }
    .ant-result-subtitle {
      color: ${({ theme }) => theme?.colors?.gray600};
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: -0.2px;
    }
  }
`

export const StyledStepSitesSkeleton = styled(Skeleton)`
  &.form_item {
  }
`

export const StyledStepSitesLeftWrapper = styled.div`
  flex: 1;
  min-height: 0;
  overflow: auto;
  padding: 8px 24px;
  ${cssScrollBar}
`

export const StyledStepSitesRightHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledStepSitesRightWrapper = styled.div`
  flex: 1;
  min-height: 0;
`

export const StyledStepSitesCol = styled.div`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  border-radius: 8px;
  &.left {
    display: flex;
    flex-direction: column;
    width: 28%;
    max-width: 330px;
    padding-block: 8px;
    ${StyledStepSitesTitle}, ${StyledStepSitesLabel} {
      padding: 8px 24px;
    }
  }
  &.right {
    flex: 1;
    min-width: 0;
    padding: 16px;
    .input_search {
      max-width: 250px;
    }
  }
`

export const StyledStepSitesRow = styled.div`
  display: flex;
  gap: 8px;
  height: 100%;
`

export const StyledStepSites = styled.div`
  flex: 1;
  min-height: 0;
  .ant-form {
    height: 100%;
  }
`
