import { CampaignStepForm } from '..'
import { CampaignStepCheckboxSites } from './CampaignStepCheckboxSites'
import {
  StyledStepSites,
  StyledStepSitesCol,
  StyledStepSitesLabel,
  StyledStepSitesLeftWrapper,
  StyledStepSitesResult,
  StyledStepSitesRightHeader,
  StyledStepSitesRightWrapper,
  StyledStepSitesRow,
  StyledStepSitesSkeleton,
  StyledStepSitesTitle
} from './CampaignStepSites.styled'
import Icon from '@ant-design/icons'
import { Form, FormInstance, notification } from 'antd'
import { InputSearch } from 'components/atoms/input'
import { NoDataFolder } from 'icons'
import { Ref, forwardRef, useEffect, useImperativeHandle } from 'react'

export type CampaignStepSitesValue = {
  site_ids?: number[]
  // TODO
  flow_id?: number[]
  client_ids?: number[]
}
export type CampaignStepSitesProps = CampaignStepForm<CampaignStepSitesValue>

const CampaignStepSitesWithoutRef = (
  props: CampaignStepSitesProps,
  ref?: Ref<FormInstance<CampaignStepSitesValue>>
) => {
  const [form] = Form.useForm<CampaignStepSitesValue>()
  const {
    loading,
    name,
    disabled,
    initialValues,
    onValuesChange,
    onFinish,
    onFinishFailed
  } = props

  const handleValuesChange = (valChanged: any, valsChanged: any) => {
    onValuesChange?.(valChanged, valsChanged)
  }

  const handleFinish = (valuesFinish: CampaignStepSitesValue) => {
    onFinish?.(valuesFinish)
  }

  const handleFinishFailed: CampaignStepSitesProps['onFinishFailed'] = (
    errorInfo
  ) => {
    onFinishFailed?.(errorInfo)
    if (errorInfo.errorFields.length) {
      errorInfo.errorFields.forEach((errorField) => {
        if (errorField.errors.length) {
          notification.error({
            message: errorField.errors.join('\n')
          })
        }
      })
    }
  }

  useEffect(() => {
    form.setFieldsValue({ ...initialValues })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  useImperativeHandle(ref, () => form, [form])

  return (
    <StyledStepSites>
      <Form
        form={form}
        name={name}
        disabled={disabled}
        initialValues={{
          ...initialValues
        }}
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        onValuesChange={handleValuesChange}>
        <StyledStepSitesRow>
          <StyledStepSitesCol className="left">
            <StyledStepSitesTitle>Select platform</StyledStepSitesTitle>
            <StyledStepSitesLabel>WEBSITE</StyledStepSitesLabel>
            <StyledStepSitesLeftWrapper>
              <StyledStepSitesSkeleton
                active
                loading={loading}
                className="form_item">
                <Form.Item
                  name="site_ids"
                  rules={[
                    {
                      required: true,
                      message: 'You should choose a site for next step'
                    }
                  ]}
                  noStyle>
                  <CampaignStepCheckboxSites />
                </Form.Item>
              </StyledStepSitesSkeleton>
            </StyledStepSitesLeftWrapper>
          </StyledStepSitesCol>
          <StyledStepSitesCol className="right">
            <StyledStepSitesRightHeader>
              <InputSearch
                placeholder="Search Client"
                className="input_search"
              />
            </StyledStepSitesRightHeader>
            <StyledStepSitesRightWrapper>
              <StyledStepSitesResult
                icon={<Icon component={NoDataFolder} />}
                className="no_data"
                subTitle="No data available"
              />
            </StyledStepSitesRightWrapper>
          </StyledStepSitesCol>
        </StyledStepSitesRow>
      </Form>
    </StyledStepSites>
  )
}
export const CampaignStepSites = forwardRef(CampaignStepSitesWithoutRef)
