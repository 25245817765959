import { Button, ButtonProps } from 'antd'
import styled from 'styled-components'
import { cssPadding, cssTitle } from 'styles'

export const Title = styled.h2`
  ${cssTitle}
`

export const StyleButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`

export const StyleHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`

export const StyleHeadContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme?.colors?.white};
  position: sticky;
  top: 65px;
  z-index: 2;
  ${cssPadding}
`

export const StyledButton = styled(Button)<ButtonProps>`
  font-weight: 600;
  font-size: 14px;
`
