import {
  StyledActions,
  StyledPreviewBox,
  StyledWrap,
  StyledWrapImage
} from './index.styled'
import { Android, Apple, MobileApp } from 'icons'
import { useState } from 'react'

export type AppPushPreview = {
  title: string
  push_message: string
  image: string
}

type Props = {
  preview: AppPushPreview
}

export function PreviewAppPush({ preview }: Props) {
  const [type, setType] = useState<'ios' | 'android'>('ios')

  return (
    <StyledWrap>
      <StyledWrapImage>
        {type === 'ios' ? (
          <img src="/images/builder/ScreenApple.png" alt="" />
        ) : (
          <img src="/images/builder/ScreenAndroid.png" alt="" />
        )}
        <StyledPreviewBox>
          <div className="wrapper-preview">
            <div className="logo-app">
              <MobileApp />
            </div>
            <div className="app-infor">
              <p className="title">{preview.title}</p>
              <p className="description">{preview.push_message}</p>
            </div>
            <div className="app-time">
              <p className="time">now</p>
              <div className="wrap-image">
                <img
                  src={preview.image || '/images/builder/Media.png'}
                  alt="app push"
                />
              </div>
            </div>
          </div>
        </StyledPreviewBox>
      </StyledWrapImage>
      <StyledActions>
        <li
          onClick={() => setType('ios')}
          className={type === 'ios' ? 'active' : ''}>
          <Apple />
        </li>
        <li
          onClick={() => setType('android')}
          className={type === 'android' ? 'active' : ''}>
          <Android />
        </li>
      </StyledActions>
    </StyledWrap>
  )
}
