import { SelectProps } from '..'
import { StyledSelect } from './index.styled'
import { useApp } from 'context/App'

export type SelectTagProps = SelectProps
const SelectTag = (props: SelectTagProps) => {
  const { options: optionsProps, ...restProps } = props
  const { tags: tagsOptions } = useApp()
  const options = optionsProps || tagsOptions

  return <StyledSelect options={options} {...restProps} />
}

export { SelectTag }
