import {
  ANALYTIC_WIDGET_ITEM_KEY,
  ANALYTIC_WIDGET_MUURI_OPTIONS,
  AnalyticWidgetMuuriContext,
  DEFAULT_ANALYTIC_WIDGET_ITEMS_DATA,
  getQuerykey
} from './AnalyticWidgetMuuri.helpers'
import { StyledAnalyticWidgetMuuri } from './AnalyticWidgetMuuri.styled'
import { AnalyticWidgetMuuriItem } from './AnalyticWidgetMuuriItem'
import { getAnalyticTemplateWidgetItemData } from './AnalyticWidgetMuuriItem/AnalyticWidgetMuuriItem.helpers'
import { AnalyticWidgetMuuriRemoveModal } from './AnalyticWidgetMuuriRemoveModal'
import Icon from '@ant-design/icons'
import { Empty, Spin } from 'antd'
import { Box } from 'components/atoms/box'
import { useLatest } from 'hooks/useLatest'
import { NoDataFolder } from 'icons'
import {
  AnalyticTemplateWidgetItem,
  AnalyticTemplateWidgetItemDataParams
} from 'interfaces/analytic'
import { MuuriComponent } from 'muuri-react'
import { DecoratedGrid, DecoratedItem } from 'muuri-react/dist/types/interfaces'
import {
  CSSProperties,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

export { ANALYTIC_WIDGET_ITEM_KEY, DEFAULT_ANALYTIC_WIDGET_ITEMS_DATA }
export type AnalyticWidgetMuuriRef = DecoratedGrid & {
  resizeHandler?: () => void
}
export type AnalyticWidgetMuuriProps = {
  className?: string
  style?: CSSProperties
  loading?: boolean
  defaultValue?: AnalyticTemplateWidgetItem[]
  value?: AnalyticTemplateWidgetItem[]
  onChange?: (value: AnalyticTemplateWidgetItem[]) => void
  onEditItem?: (id: number) => void
  onRemoveItem?: (id: number) => Promise<boolean>
  segment_sites?: any
} & Pick<
  AnalyticTemplateWidgetItemDataParams,
  'segment_id' | 'segment_type' | 'period' | 'start_date' | 'end_date'
>
const AnalyticWidgetMuuriWithoutRef = (
  props: AnalyticWidgetMuuriProps,
  ref?: Ref<AnalyticWidgetMuuriRef>
) => {
  const {
    className,
    style,
    loading: loadingProps,
    defaultValue,
    value: valueProps,
    onChange,
    onEditItem,
    onRemoveItem,
    period,
    segment_id,
    segment_type,
    segment_sites,
    start_date,
    end_date
  } = props
  const timeoutResizeRef = useRef<NodeJS.Timeout | null>(null)
  const muuriRef = useRef<AnalyticWidgetMuuriRef>()
  const [value, setValue] = useState(defaultValue || valueProps || [])
  const [removeId, setRemoveId] = useState<number>()
  const [dataQuery, setDataQuery] = useState<Record<string, any>>({})
  const [loading, setLoading] = useState(true)

  const removeWidgetTitle = useMemo(() => {
    return value?.find((val) => val.id === removeId)?.data?.title || ''
  }, [removeId, value])

  const resizeHandlerFn = useCallback(() => {
    const grid = muuriRef.current as any
    grid?._resizeHandler()
  }, [])
  const resizeHandler = useLatest(resizeHandlerFn)
  const handleChange = useCallback(
    (values: AnalyticTemplateWidgetItem[], dispatchChange = true) => {
      const newValues = values.map((value, index) => ({
        ...value,
        query: { ...value.query, period, segment_id, segment_type },
        data: { ...value.data, order: index }
      }))
      setValue(newValues)
      if (dispatchChange) onChange?.(newValues)
    },
    [onChange, period, segment_id, segment_type]
  )

  const handleChangeItem = useCallback(
    (item: AnalyticTemplateWidgetItem) => {
      const newValues = value?.map((val) => {
        if (val?.id === item?.id) {
          return {
            ...val,
            ...item,
            data: { ...val.data, ...item.data },
            query: { ...val.query, ...item.query }
          }
        }
        return val
      })
      resizeHandler.current()
      handleChange(newValues)
    },
    [handleChange, resizeHandler, value]
  )

  const handleRemoveItem = useCallback(
    (idRemoved: number) => {
      const indexRemove = value.findIndex((item) => item.id === idRemoved)
      if (indexRemove !== -1) {
        const newValues = value
          .slice(0, indexRemove)
          .concat(value.slice(indexRemove + 1))
        handleChange(newValues)
      }
    },
    [handleChange, value]
  )

  const handleDragEnd = useCallback(
    (item: DecoratedItem) => {
      const items = item.getGrid().getItems()
      const newValues: AnalyticTemplateWidgetItem[] = []
      items.forEach((item, index) => {
        const data: any = item.getData()
        const valueItem = data?.item
        newValues[index] = valueItem
      })
      handleChange(newValues)
    },
    [handleChange]
  )

  const handleClickDropdown = (
    key: string,
    analyticItem?: AnalyticTemplateWidgetItem
  ) => {
    if (!analyticItem?.id) {
      return
    }
    switch (key) {
      case 'edit': {
        onEditItem?.(analyticItem.id)
        break
      }
      case 'remove': {
        setRemoveId(analyticItem.id)
        break
      }
    }
  }

  useEffect(() => {
    handleChange(valueProps || [], false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProps])

  useEffect(() => {
    function windowOnResize() {
      resizeHandler.current()
    }
    window.addEventListener('resize', windowOnResize)
    return () => {
      window.removeEventListener('resize', windowOnResize)
    }
  }, [resizeHandler])

  useEffect(() => {
    ;(async () => {
      const listResponses = await Promise.all(
        value.map((valueItem) => {
          return getAnalyticTemplateWidgetItemData({
            period,
            segment_id,
            segment_type,
            start_date,
            end_date,
            widget: valueItem.widget
          })
        })
      )

      const isValidVisitByLocation = segment_sites?.length === 1

      listResponses.forEach((response, idx: number) => {
        if (!response.errors.length) {
          const widget = value[idx].widget
          const isValid = DEFAULT_ANALYTIC_WIDGET_ITEMS_DATA[widget].isValid?.(
            response.data
          )

          // HHS-2091 - [FE][Analytics] Group the widget relies on multiple sites
          if (
            widget === ANALYTIC_WIDGET_ITEM_KEY.VISIT_BY_LOCATION &&
            !isValidVisitByLocation
          ) {
            return
          }

          const debug = localStorage.getItem('analytic_debug') === 'true'

          if (isValid || debug) {
            const dataQueryKey = getQuerykey(
              widget,
              segment_id,
              start_date,
              end_date
            )

            setDataQuery?.((query) => ({
              ...query,
              [dataQueryKey]: response.data
            }))

            timeoutResizeRef.current && clearTimeout(timeoutResizeRef.current)
            timeoutResizeRef.current = setTimeout(() => {
              resizeHandler.current()
            }, 100)
          } else {
            console.log(`${widget}: There is no data`, response)
          }
        }
      })

      setTimeout(() => {
        setLoading(false)
      }, 1000)
    })()
  }, [
    end_date,
    period,
    resizeHandler,
    segment_id,
    segment_sites?.length,
    segment_type,
    start_date,
    value
  ])

  useImperativeHandle(
    ref,
    () => {
      return {
        ...muuriRef.current,
        resizeHandler: resizeHandler.current
      } as AnalyticWidgetMuuriRef
    },
    [resizeHandler]
  )

  const ctxValue = useMemo(() => {
    return {
      query: {
        period,
        segment_id,
        segment_type,
        start_date,
        end_date
      },
      dataQuery,
      setDataQuery,
      onChangeItem: handleChangeItem,
      segment_sites
    }
  }, [
    dataQuery,
    end_date,
    handleChangeItem,
    period,
    segment_id,
    segment_sites,
    segment_type,
    start_date
  ])

  const valueHasData = value.filter(
    (value) =>
      dataQuery[getQuerykey(value.widget, segment_id, start_date, end_date)]
  )
  const isLoading = loadingProps || loading

  return (
    <AnalyticWidgetMuuriContext.Provider value={ctxValue}>
      <StyledAnalyticWidgetMuuri className={className} style={style}>
        {!valueHasData?.length && !isLoading && (
          <Empty
            image={<Icon component={NoDataFolder} className="text-[80px]" />}
            imageStyle={{ height: 'auto' }}
            description="There is no data analytics"
            className="py-60"
          />
        )}
        {!!valueHasData?.length && (
          <MuuriComponent
            {...ANALYTIC_WIDGET_MUURI_OPTIONS}
            onDragEnd={handleDragEnd}
            onMount={(gridRef: DecoratedGrid) => {
              muuriRef.current = gridRef
            }}>
            {valueHasData.map((item, index) => {
              return (
                <AnalyticWidgetMuuriItem
                  key={`${item.id}-${index}`}
                  item={item}
                  onChangeItem={handleChangeItem}
                  onClickDropdown={handleClickDropdown}
                />
              )
            })}
          </MuuriComponent>
        )}
        {isLoading && (
          <Box className="min-h-[300px] flex w-full justify-center items-center">
            <Spin />
          </Box>
        )}
      </StyledAnalyticWidgetMuuri>
      <AnalyticWidgetMuuriRemoveModal
        open={typeof removeId === 'number'}
        title="Are you sure?"
        description={
          <span>
            You will NOT see this <strong>{removeWidgetTitle}</strong> widget
            anymore. Do you want to remove this widget?
          </span>
        }
        onCancel={() => setRemoveId(undefined)}
        onOk={async () => {
          if (removeId) {
            try {
              if (onRemoveItem) {
                const isRemoved = await onRemoveItem(removeId)
                if (isRemoved) {
                  handleRemoveItem(removeId)
                  setRemoveId(undefined)
                }
              } else {
                handleRemoveItem(removeId)
                setRemoveId(undefined)
              }
            } catch (errors) {}
          }
        }}
      />
    </AnalyticWidgetMuuriContext.Provider>
  )
}
export const AnalyticWidgetMuuri = forwardRef(AnalyticWidgetMuuriWithoutRef)
