import { DatePicker } from 'components/atoms/datePicker'
import { FORMAT_DATE_TIME_DAYJS } from 'constants/common'
import dayjs from 'dayjs'
import { Ref, forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'

const StyledTimePicker = styled(DatePicker.TimePicker)`
  width: 190px;
`

const timePickerPropsToState = (value?: string) => {
  if (!value) {
    return null
  }

  const dayjsValue = dayjs(value, FORMAT_DATE_TIME_DAYJS, true)
  if (dayjsValue.isValid()) {
    return dayjsValue
  }

  return null
}

const timePicketStateToProps = (val: dayjs.Dayjs | null) => {
  if (!val) {
    return null
  }

  if (val.isValid()) {
    return val.format(FORMAT_DATE_TIME_DAYJS)
  }
  return null
}

export type CampaignStepTimePickerProps = {
  disabled?: boolean
  placeholder?: string
  defaultValue?: string
  value?: string
  onChange?: (value: string | null) => void
  [key: string]: any
}

const CampaignStepTimePickerWithoutRef = (
  props: CampaignStepTimePickerProps,
  ref?: Ref<any>
) => {
  const {
    disabled,
    defaultValue,
    value: valueProps,
    onChange,
    ...restProps
  } = props
  const [value, setValue] = useState(
    timePickerPropsToState(defaultValue || valueProps)
  )

  const handleChange = (val: dayjs.Dayjs | null, valString: string) => {
    setValue(val)
    onChange?.(timePicketStateToProps(val))
  }

  useEffect(() => {
    setValue(timePickerPropsToState(valueProps))
  }, [valueProps])

  return (
    <StyledTimePicker
      {...restProps}
      ref={ref}
      disabled={disabled}
      value={value}
      onChange={handleChange}
    />
  )
}

export const CampaignStepTimePicker = forwardRef(
  CampaignStepTimePickerWithoutRef
)
