import { Button, ButtonProps, Checkbox } from 'antd'
import { CheckboxGroupProps } from 'antd/lib/checkbox'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

export const StyledDropdownCheckboxGroup = styled(
  Checkbox.Group
)<CheckboxGroupProps>`
  overflow: auto;
  width: 100%;
  max-height: 50vh;
  ${cssScrollBar}
  > .ant-checkbox-wrapper {
    display: flex;
    padding: 12px 20px;
    margin: 0;
    color: ${({ theme }) => theme?.colors?.gray800 || ''};
  }
`

export const StyledDropdownCheckboxTitle = styled.h4`
  margin-bottom: 0;
  color: ${({ theme }) => theme?.colors?.gray500 || ''};
  font-weight: 600;
  line-height: 1.5;
`
export const StyledDropdownCheckboxDescription = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme?.colors?.gray500 || ''};
  font-size: 12px;
  line-height: 1.5;
`

export const StyledDropdownCheckboxHeader = styled.div`
  padding: 10px 20px 4px;
`

export const StyledDropdownCheckboxFooter = styled.div`
  padding: 10px 20px 4px;
  border-top: 1px solid ${({ theme }) => theme?.colors?.neutral100 || ''};
`

export const StyledDropdownCheckbox = styled.div`
  min-width: 100%;
  padding: 8px 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px #0000000a, 0px 2px 8px 0px #0000000a,
    0px 10px 16px 0px #0000000a;
`

export const StyledDropdownCheckboxButton = styled(Button)<ButtonProps>`
  &.ant-btn-lg {
    position: relative;
    min-width: 120px;
    height: auto;
    padding: 9px 30px 9px 16px;
    border-color: ${({ theme }) => theme?.colors?.gray200 || ''};
    color: ${({ theme }) => theme?.colors?.gray800 || ''};
    line-height: 1.5;
    text-align: left;
    &:hover,
    &:focus {
      color: ${({ theme }) => theme?.colors?.gray800 || ''};
    }
    > .anticon {
      position: absolute;
      top: 50%;
      right: 10px;
      display: flex;
      align-items: center;
      color: inherit;
      font-size: 20px;
      transform: translateY(-50%);
      + span {
        margin: 0;
      }
    }
    > span {
      + .anticon {
        margin: 0;
      }
    }
    &.ant-btn-dangerous {
      border-color: ${({ theme }) => theme?.colors?.red400 || '#ff4d4f'};
    }
    &:active,
    &:focus {
      > .anticon {
        position: absolute;
      }
    }
  }
`

export const StyleDrodownWrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`

export const StyledDropdownButtonCancel = styled.div`
  color: ${({ theme }) => theme?.colors?.gray600 || ''};
  cursor: pointer;
  font-weight: 600;
  line-height: 22px;
`

export const StyledDropdownButtonApply = styled(Button)`
  background: ${({ theme }) => theme.colors.blue600};
  color: ${({ theme }) => theme.colors.white};
`
