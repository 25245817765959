import Icon from '@ant-design/icons'
import { RefSelectProps, Select, SelectProps } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import cn from 'classnames'
import { Avatar, AvatarProps } from 'components/atoms/avatar'
import { Search } from 'icons'
import { OptionType } from 'interfaces'
import { Ref, forwardRef, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { toLowerNonAccentCaseSearch } from 'utils/search'

const StyledSelectDropdown = styled.div`
  .rc-virtual-list-holder-inner {
    .ant-select-item {
      padding-inline: 16px;
      padding-block: 8px;
    }
  }
  .rc-virtual-list-scrollbar {
    width: 4px !important;
    .rc-virtual-list-scrollbar-thumb {
      background-color: ${({ theme }) => theme?.colors?.gray200} !important;
    }
  }
`

const StyledSelectContainer = styled.div`
  position: relative;
  .icon {
    position: absolute;
    z-index: 1;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: ${({ theme }) => theme?.colors?.gray600};
  }
  .placeholder {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    opacity: 0;
    color: ${({ theme }) => theme?.colors?.gray400};
    pointer-events: none;
  }
  &.isSearch {
    .placeholder {
      opacity: 0;
    }
  }
  &.hasValue {
    .placeholder {
      opacity: 0;
    }
  }
  .ant-select {
    width: 100%;

    &.ant-select-single {
      .ant-select-selector {
        padding-left: 40px;
      }
      .ant-select-selection-search {
        left: 40px;
      }
      .ant-select-selection-placeholder {
        color: ${({ theme }) => theme?.colors?.gray600};
      }
    }
  }
`

export type SelectAvatarRef = RefSelectProps
export type SelectAvatarProps = {
  options?: OptionType<Partial<AvatarProps>>[]
  placeholder?: string
} & Pick<
  SelectProps,
  | 'defaultValue'
  | 'value'
  | 'onChange'
  | 'onBlur'
  | 'suffixIcon'
  | 'prefixCls'
  | 'className'
  | 'style'
>

const SelectAvatarWithoutRef = (
  props: SelectAvatarProps,
  ref?: Ref<SelectAvatarRef>
) => {
  const {
    options: optionsProps,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    placeholder,
    suffixIcon,
    className,
    style,
    ...restProps
  } = props
  const [value, setValue] = useState(defaultValue || valueProps)
  const [search, setSearch] = useState('')
  const optionsRef = useRef(optionsProps)
  const [options, setOptions] = useState(optionsProps)

  const onChange = (
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ) => {
    setValue(value)
    onChangeProps?.(value, option)
  }

  const onSearch = (value: string) => {
    setSearch(value)
    setOptions(
      optionsRef.current?.filter((option) => {
        if (typeof option.label !== 'string') {
          return true
        }

        return (
          toLowerNonAccentCaseSearch(option?.label.toString(), value) ||
          toLowerNonAccentCaseSearch(option?.value.toString(), value)
        )
      })
    )
  }

  const onDropdownVisibleChange = () => {
    setSearch('')
    setOptions(optionsRef.current)
  }

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])
  useEffect(() => {
    setOptions(optionsProps)
    optionsRef.current = optionsProps
  }, [optionsProps])

  return (
    <StyledSelectContainer
      className={cn(className, { isSearch: !!search, hasValue: !!value })}
      style={style}>
      <Icon component={Search} className="icon" />
      <div className="placeholder">{placeholder}</div>

      <Select
        {...restProps}
        ref={ref}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onDropdownVisibleChange={onDropdownVisibleChange}
        placeholder={placeholder}
        showArrow={false}
        showSearch
        searchValue={search}
        onSearch={onSearch}
        filterOption={false}
        popupClassName="rounded-2xl !py-2"
        dropdownRender={(dropdownChild) => (
          <StyledSelectDropdown>{dropdownChild}</StyledSelectDropdown>
        )}
        getPopupContainer={(select) =>
          (select as HTMLDivElement).parentElement as HTMLElement
        }>
        {options?.map((option) => {
          return (
            <Select.Option
              value={option.value}
              key={`${option.label}-${option.value}`}>
              <Avatar
                {...option.data}
                size={32}
                name={option?.data?.name || option.label || ''}
                email={option?.data?.email}
              />
            </Select.Option>
          )
        })}
      </Select>
    </StyledSelectContainer>
  )
}
export const SelectAvatar = forwardRef(SelectAvatarWithoutRef)
