import Icon from '@ant-design/icons'
import { Empty } from 'antd'
import { NoDataFolder } from 'icons'
import { CSSProperties, ReactNode } from 'react'

export type HighchartsEmptyProps = {
  iconClassName?: string
  description?: ReactNode
  className?: string
  style?: CSSProperties
}
export const HighchartsEmpty = (props: HighchartsEmptyProps) => {
  const {
    iconClassName = 'text-[30px]',
    description = 'No data chart',
    className,
    style
  } = props
  return (
    <Empty
      image={<Icon component={NoDataFolder} className={iconClassName} />}
      imageStyle={{ height: 'auto' }}
      description={description}
      className={className}
      style={style}
    />
  )
}
