import Icon from '@ant-design/icons'
import { Button, Dropdown, MenuProps, TableColumnType, Tooltip } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import cn from 'classnames'
import {
  TableCellTitle,
  TableScroll,
  TableScrollProps
} from 'components/atoms/table'
import { TagGroup } from 'components/atoms/tag'
import { menuRoutes } from 'components/molecules/layout'
import { ROLES } from 'constants/role'
import { theme } from 'constants/theme'
import { useAuth } from 'context/Auth'
import {
  DuplicateFile,
  Edit,
  Insight,
  MoreOptions,
  Plus,
  Share,
  TrashBin
} from 'icons/V2'
import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

const analyticTemplateTableColumns: TableColumnType<any>[] = [
  {
    dataIndex: 'name',
    title: 'Name',
    className: 'font-semibold text-gray800',
    width: 80,
    render(name: string, record: any) {
      return (
        <div className="flex">
          <Insight className="text-lg mr-4" />
          <Link
            to={`${menuRoutes.analytic}/${record?.id}`}
            className="m-0 mt-[2px] flex-1 min-w-0 text-inherit text-ellipsis overflow-hidden">
            <Tooltip title={name}>{name}</Tooltip>
          </Link>
        </div>
      )
    }
  },
  {
    dataIndex: 'description',
    title: 'Description',
    width: 80,
    render(description: string) {
      return (
        <Tooltip title={description}>
          <span className="m-0 mt-[2px] text-ellipsis overflow-hidden line-clamp-2">
            {description}
          </span>
        </Tooltip>
      )
    }
  },
  {
    dataIndex: 'created_by',
    title: 'Author',
    width: 60,
    render(author) {
      return (
        <TableCellTitle
          title={`${author?.first_name} ${author?.last_name}`}
          subTitle={author?.email}
        />
      )
    }
  },
  {
    dataIndex: 'tags',
    title: 'Label',
    width: 80,
    render(tags: any) {
      return (
        <TagGroup
          tags={tags?.map((tag: any) => ({
            label: tag,
            color: theme.colors.blue50,
            className: 'text-blue900 bg-blue50'
          }))}
          maxTagsCount="responsive"
        />
      )
    }
  },
  {
    dataIndex: 'created_at',
    title: 'Created date',
    width: 60,
    render(createdDate) {
      return <p className="mb-0">{createdDate}</p>
    }
  },
  {
    dataIndex: 'actions',
    title: '',
    width: 10
  }
]
const analyticTemplateDropdownItem = [
  {
    label: 'Share',
    icon: <Share className="mr-4 text-[18px]" />,
    key: 'share',
    className: 'py-2 px-4'
  },
  {
    label: 'Duplicate',
    icon: <DuplicateFile className="mr-4 text-[18px]" />,
    key: 'duplicate',
    className: 'py-2 px-4'
  },
  {
    label: 'Edit',
    icon: <Edit className="mr-4 text-[18px]" />,
    key: 'edit',
    className: 'py-2 px-4'
  },
  {
    label: 'Delete',
    icon: <TrashBin className="mr-4 text-[18px]" />,
    key: 'delete',
    className: 'py-2 px-4'
  }
].filter(Boolean) as ItemType[]

export type AnalyticTemplateTableProps = Pick<
  TableScrollProps,
  'loading' | 'dataSource'
> & {
  onClickCreate?: () => void
  onClickDropdownItem?: (key: string, record: any) => void
}
export const AnalyticTemplateTable = (props: AnalyticTemplateTableProps) => {
  const { loading, dataSource, onClickCreate, onClickDropdownItem } = props
  const { userAuth } = useAuth()

  const handleOnClickDropdownItem = useCallback(
    (record: any) => {
      return ((item) => {
        onClickDropdownItem?.(item.key, record)
      }) as MenuProps['onClick']
    },
    [onClickDropdownItem]
  )
  console.log('userAuth', userAuth)
  const columns = useMemo(() => {
    return analyticTemplateTableColumns.map((col) => {
      if (col.dataIndex === 'actions') {
        return {
          ...col,
          render(_: any, record: any) {
            const isOwner = record?.created_by?.email === userAuth?.email
            const items = analyticTemplateDropdownItem.map((item) => {
              return { ...item, disabled: !isOwner }
            }) as MenuProps['items']
            return (
              <Dropdown
                trigger={['click']}
                menu={{
                  items,
                  onClick: handleOnClickDropdownItem(record),
                  className: 'min-w-[140px] p-0 overflow-hidden'
                }}>
                <Button
                  type="text"
                  icon={<Icon component={MoreOptions} />}
                  className="w-[24px] h-[24px] p-0 *:text-lg"
                />
              </Dropdown>
            )
          }
        }
      }
      return col
    })
  }, [handleOnClickDropdownItem, userAuth?.email])

  return (
    <>
      <TableScroll
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => `${record?.id}`}
        title={() => (
          <div className="flex items-center justify-between">
            <h3 className="m-0 text-[18px] font-bold leading-7">
              {dataSource?.length} Analytics Dashboard
            </h3>
            <div className="flex gap-3">
              {/* <div className="flex items-center gap-4">
                <span className="text-[14px] text-gray-600 font-semibold">
                  Sort by
                </span>
                <Select
                  options={[{ label: 'Last viewed', key: 'last_viewed' }]}
                  placeholder="Sort by"
                  className="bg-transparent"
                />
              </div>
              <Dropdown trigger={['click']} menu={{ items: [] }}>
                <Button
                  type="default"
                  className="text-gray-600 bg-transparent"
                  icon={<Icon component={Filter} className="text-[16px]" />}>
                  Filter
                </Button>
              </Dropdown> */}
              <Button
                type="primary"
                className="px-3"
                onClick={onClickCreate}
                disabled={userAuth?.auth.rolesActive?.includes(ROLES.read_only)}
                icon={<Icon component={Plus} />}>
                New Dashboard
              </Button>
            </div>
          </div>
        )}
        components={{
          header: {
            cell(props: any) {
              const { children, className, ...restProps } = props
              return (
                <th
                  {...restProps}
                  className={cn(
                    className,
                    '!px-4 !py-3',
                    '!font-normal !text-gray-400 text-sm'
                  )}>
                  {children}
                </th>
              )
            }
          },
          body: {
            cell(props: any) {
              const { children, className, ...restProps } = props
              return (
                <td {...restProps} className={cn(className, '!px-4 !py-3')}>
                  {children}
                </td>
              )
            }
          }
        }}
        // Limit 10 items
        footer={() => null}
        pagination={{ position: [], current: 1, pageSize: 1000000 }}
      />
    </>
  )
}
