import {
  CAMPAIGN_STEP_KEYS,
  CampaignForm
} from '../../molecules/campaign/CampaignForm'
import {
  CampaignFormProps,
  CampaignFormRef,
  CampaignFormValue,
  formatCampaignFormValue
} from '../../molecules/campaign/CampaignForm/CampaignForm.helpers'
import { notification } from 'antd'
import { menuRoutes } from 'components/molecules'
import { CAMPAIGN_STATUS } from 'constants/campaign'
import { PERMISSIONS } from 'constants/permission'
import { TEMPLATE_TYPE } from 'constants/template'
import { useCheckAuth } from 'hooks/useCheckAuth'
import { TEMPLATE_MESSAGE } from 'pages/campaign/[template_type]'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CampaignServices } from 'services/campaign'

export type CampaignPageEditProps = {
  template_type?: string
}
export const CampaignPageEdit: FC<CampaignPageEditProps> = (props) => {
  const { template_type } = props
  const { id } = useParams()
  const navigate = useNavigate()
  const campaignRef = useRef<CampaignFormRef | null>(null)
  const notFound = useRef(false)
  const [value, setValue] = useState<CampaignFormValue>({})
  const [loading, setLoading] = useState(false)

  const isAuth = useCheckAuth({
    permissions: [
      PERMISSIONS.campaign_app_push_edit,
      PERMISSIONS.campaign_web_push_edit,
      PERMISSIONS.campaign_email_edit
    ]
  })

  const label = useMemo(() => {
    return TEMPLATE_MESSAGE?.[template_type as string] || ''
  }, [template_type])
  const disabled = useMemo(() => {
    switch (value?.status) {
      case CAMPAIGN_STATUS.ready:
      case CAMPAIGN_STATUS.draft:
        return false
      case CAMPAIGN_STATUS.done:
      case CAMPAIGN_STATUS.cancel:
      case CAMPAIGN_STATUS.running:
      case CAMPAIGN_STATUS.schedule:
        return true
      default:
        if (typeof id !== 'undefined') {
          return true
        }
        return false
    }
  }, [id, value?.status])
  const disabledReady = useMemo(() => {
    return Boolean(value?.status === CAMPAIGN_STATUS.ready)
  }, [value?.status])

  const handleSave: NonNullable<CampaignFormProps['onSave']> = async (
    values,
    isExit
  ) => {
    if (!id) {
      return
    }
    if (disabled) {
      notification.error({
        message: `Campaign with id ${id} must be either in Ready or Draft status to be updated`
      })
      return
    }
    setLoading(true)
    const isDraft = values.status === CAMPAIGN_STATUS.draft
    try {
      const response: any = await CampaignServices.updateCampaign(id, {
        name: values?.name || '',
        description: values?.description || '',
        approach: values?.approach || 1,
        client_ids: values?.client_ids || [],
        start_time: values?.start_time || '',
        end_time: values?.end_time || '',
        flow_id: values?.flow_id || null,
        segments_ids: values?.segments_ids || [],
        site_ids: values?.site_ids || [],
        template_id: values?.template_id || null,
        time_zone: values?.time_zone || '',
        type: values?.type || '',
        status: CAMPAIGN_STATUS.draft,
        template_type: values?.template_type || template_type,
        days_of_week: values?.days_of_week || undefined,
        is_recurring: values?.is_recurring || undefined,
        recurring_cycle: values?.recurring_cycle || undefined,
        trigger_time: values?.trigger_time || undefined,
        threshold: values?.threshold || 1,
        push_type: values?.push_type === 'broadcast' ? values.push_type : '',
        by_pass_frequency: values?.by_pass_frequency || false
      } as any)

      if (response?._message || !response.data) {
        throw new Error(JSON.stringify(response))
      }

      const successMessage = isDraft
        ? `The ${label} campaign was saved draft successfully`
        : `The ${label} campaign was updated completely`
      notification.success({ message: successMessage })
      setValue(values)
      if (isExit) {
        navigate(menuRoutes.campaign)
      }
    } catch (error: any) {
      console.log('** ERROR CampaignLandingPageEdit.tsx 46 : ', error)
      if (error?.message && typeof error.message === 'string') {
        const errorObj = JSON.parse(error.message)
        if (errorObj?._message) {
          const fields: any[] = []
          const message = errorObj?._message
          if (message.includes('name')) {
            campaignRef.current?.onChangeStep(CAMPAIGN_STEP_KEYS.overviews)
            fields.push({
              name: 'name',
              value: values.name,
              errors: ['Name is existing. Please input another name.']
            })
          }
          const messageArr = message.split(':')
          if (messageArr?.[0].includes('end_time')) {
            campaignRef.current?.onChangeStep(CAMPAIGN_STEP_KEYS.overviews)
            fields.push({
              name: messageArr?.[0],
              value: values.end_time,
              errors: [messageArr?.[1]]
            })
          }
          // Forsure exists overviewsForm
          setTimeout(() => {
            campaignRef.current?.overviewsForm?.setFields(fields)
          }, 250)
        }
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!id) {
      return
    }
    ;(async () => {
      setLoading(true)
      try {
        const response: any = await CampaignServices.getById(id)
        if (response?._message || !response?.data) {
          notFound.current = true
          throw new Error(JSON.stringify(response))
        }
        if (
          response?.data &&
          response.data?.template_type !== template_type &&
          ![TEMPLATE_TYPE.TWILIO, TEMPLATE_TYPE.VNPT].includes(
            response.data?.template_type
          )
        ) {
          notFound.current = true
        }
        setValue(formatCampaignFormValue(response.data))
      } catch (error) {
        console.log('** ERROR CampaignLandingPageEdit.tsx 30  : ', error)
      }
      setLoading(false)
    })()
  }, [id, template_type])

  return (
    <CampaignForm
      ref={campaignRef}
      title="Edit Campaign"
      loading={loading}
      disabled={!isAuth || disabled}
      disabledReady={disabledReady}
      notFound={notFound.current}
      template_type={template_type}
      defaultValue={value}
      value={value}
      onSave={handleSave}
      permissions={[
        PERMISSIONS.campaign_view,
        PERMISSIONS.campaign_email_edit,
        PERMISSIONS.campaign_app_push_edit,
        PERMISSIONS.campaign_web_push_edit,
        PERMISSIONS.campaign_whatsapp_edit
      ]}
      key={value.id}
    />
  )
}
