import { CampaignStepForm } from '..'
import { CampaignStepSelectTemplate } from './CampaignStepSelectTemplate'
import { StyledStepTemplates } from './CampaignStepTemplates.styled'
import { Form, FormInstance, Input, notification } from 'antd'
import { Ref, forwardRef, useEffect, useImperativeHandle } from 'react'

export type CampaignStepTemplatesValue = {
  template_id?: number | null
}

export type CampaignStepTemplatesProps =
  CampaignStepForm<CampaignStepTemplatesValue>

const CampaignStepTemplatesWithoutRef = (
  props: CampaignStepTemplatesProps,
  ref?: Ref<FormInstance<CampaignStepTemplatesValue>>
) => {
  const {
    site,
    template_type,
    loading,
    name,
    disabled,
    initialValues,
    onFinish,
    onFinishFailed,
    onValuesChange
  } = props
  const [form] = Form.useForm()

  const handleFinishFailed: CampaignStepTemplatesProps['onFinishFailed'] = (
    errorInfo
  ) => {
    onFinishFailed?.(errorInfo)
    if (errorInfo.errorFields.length) {
      errorInfo.errorFields.forEach((errorField) => {
        if (errorField.errors.length) {
          notification.error({
            message: errorField.errors.join('\n')
          })
        }
      })
    }
  }

  useEffect(() => {
    form.setFieldsValue({ ...initialValues })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  useImperativeHandle(ref, () => form, [form])

  return (
    <StyledStepTemplates>
      <Form
        initialValues={{ ...initialValues }}
        name={name}
        form={form}
        disabled={disabled}
        onFinish={onFinish}
        onFinishFailed={handleFinishFailed}
        onValuesChange={onValuesChange}>
        <Form.Item name="template_type" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="template_id"
          rules={[
            {
              required: true,
              message: 'You should choose a template for next step'
            }
          ]}
          noStyle>
          <CampaignStepSelectTemplate
            site={site}
            type={template_type}
            disabled={disabled}
            loading={loading}
          />
        </Form.Item>
      </Form>
    </StyledStepTemplates>
  )
}
export const CampaignStepTemplates = forwardRef(CampaignStepTemplatesWithoutRef)
