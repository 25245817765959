import { CalendarBlue, CheckedCircleGreen, CloseOrange, PlayBlue } from 'icons'
import { v4 as uuidv4 } from 'uuid'

export const LOCAL_STORAGE_AUTH_TOKEN = 'auth:access_token'

export const COOKIE_AUTH_TOKEN = 'hhg-olympus-token-v2'

export const FORMAT_DATE_DAYJS = 'MMM DD, YYYY HH:mm'

export const FORMAT_DATE = 'MMM DD, YYYY'

export const FORMAT_DATE_TIME_DAYJS = 'YYYY-MM-DD HH:mm:ss'

export const FORMAT_DATE_DAYJS_API = 'YYYY-MM-DD'

export const FORMAT_DATE_YYYYDDMM = 'YYYY-DD-MM'

export const FORMAT_TIME = 'HH:mm:ss'

export const FORMAT_DAY_MONTH_YEAR_HOUR = 'DD/MM/YYYY, hh:mm A'

export const FORMAT_DAY = 'DD/MM/YYYY'

export const TIME_ONE_DAY = 86400000

export const TIME_SECOND_ONE_DAY = 86400

export const TIME_BEGIN_DAY = '00:00:00'

export const TIME_FINISH_DAY = '23:59:59'

export const FILE_UPLOAD_SIZE = 10

export const FILE_UPLOAD_TYPE = 'text/csv'

export enum RES_STATUS {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL'
}

export const RESPONSE_MESSAGE = {
  [RES_STATUS.FAIL]: 'Sorry, something went wrong.',
  403: 'Permission Deny. You have not permission to restore campaign',
  404: 'Sorry, the page you visited does not exist.',
  500: 'Sorry, something went wrong.'
}

export enum sort {
  descend = 'DESC',
  ascend = 'ASC'
}

export const page_size_options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 50, label: 50 },
  { value: 100, label: 100 }
]

export const default_page_size = 10
export const default_current_page = 1
export const default_page_size_all = 1_000_000

export const markersSite = [
  { name: 'Vietnam', coordinates: [108.2772, 14.0583] },
  { name: 'Thailand', coordinates: [100.9925, 15.87] },
  { name: 'Malaysia', coordinates: [101.9758, 4.2105] },
  { name: 'Myanmar', coordinates: [95.956, 21.9162] },
  { name: 'Indonesia', coordinates: [113.9213, 0.7893] },
  { name: 'Cambodia', coordinates: [104.991, 12.5657] },
  { name: 'Philippines', coordinates: [121.774, 12.8797] },
  { name: 'India', coordinates: [78.9629, 20.5937] },
  { name: 'Taiwan', coordinates: [120.9605, 23.6978] }
]

export const localeGlobal = [
  { label: 'Asia', value: 'asia' },
  { label: 'Europe', value: 'europe' },
  { label: 'Americas', value: 'americas' }
]

interface statusType {
  [name: string]: any
}

export const status_key: statusType = {
  schedule: CalendarBlue,
  enable: PlayBlue,
  disable: CloseOrange,
  done: CheckedCircleGreen
}

export const LIST_COLOR_AVATAR = [
  '#FFCB37',
  '#00C7E6',
  '#00CB8A',
  '#FF6F4D',
  '#FD72A7',
  '#AF6D74',
  '#BB82E1',
  '#45A4FF',
  '#BFC5CC',
  '#595959'
]

export const DEFAULT_GET_ALL_RECORDS = {
  page: default_current_page,
  per_page: default_page_size_all
}

export const SESSION_UUID = uuidv4()
