import { Spinner } from '../../spinner'
import { Divider, Skeleton } from 'antd'
import styled from 'styled-components'

const StyledWrapper = styled.div<{ $isFixed?: boolean }>`
  display: flex;
  overflow: auto;
  min-height: 100vh;

  ${(props) => {
    if (props.$isFixed) {
      return `
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 2;
      `
    }

    return ''
  }}
`

const StyledDivider = styled(Divider)`
  &.ant-divider-horizontal {
    width: 50%;
    min-width: unset;
    border-color: ${({ theme }) => theme?.colors?.neutral500 || ''};
    margin: 20px 0;
    opacity: 0.3;
  }
`

const StyledSkeletonIcon = styled(Skeleton.Avatar)`
  .ant-skeleton-avatar-square {
    border-radius: 2px;
  }

  &.avatar {
    margin-bottom: 32px;
  }

  &.menu {
    + .menu {
      margin-top: 24px;
    }
  }
`

const StyledMenuSkeleton = styled.div`
  display: flex;
  width: 64px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 10px;
  background-color: ${({ theme }) => theme?.colors?.secondaryDark || ''};
`

const StyledSpinnerWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`
export type LoadingFullScreenProps = { isShowMenu?: boolean; isFixed?: boolean }
export function LoadingFullScreen({
  isShowMenu,
  isFixed
}: LoadingFullScreenProps) {
  return (
    <StyledWrapper $isFixed={isFixed}>
      {!!isShowMenu && (
        <StyledMenuSkeleton>
          <StyledSkeletonIcon
            active
            size={24}
            shape="square"
            className="logo"
          />
          <StyledDivider orientationMargin="16" orientation="center" />
          <StyledSkeletonIcon
            active
            size={40}
            shape="circle"
            className="avatar"
          />
          <StyledSkeletonIcon
            active
            size={24}
            shape="square"
            className="menu"
          />
          <StyledSkeletonIcon
            active
            size={24}
            shape="square"
            className="menu"
          />
          <StyledSkeletonIcon
            active
            size={24}
            shape="square"
            className="menu"
          />
          <StyledSkeletonIcon
            active
            size={24}
            shape="square"
            className="menu"
          />
          <StyledSkeletonIcon
            active
            size={24}
            shape="square"
            className="menu"
          />
          <StyledSkeletonIcon
            active
            size={24}
            shape="square"
            className="menu"
          />
          <StyledDivider orientationMargin="16" orientation="center" />
          <StyledSkeletonIcon
            active
            size={24}
            shape="square"
            className="menu"
          />
          <StyledSkeletonIcon
            active
            size={24}
            shape="square"
            className="menu"
          />
          <StyledSkeletonIcon
            active
            size={24}
            shape="square"
            className="menu"
          />
          <StyledSkeletonIcon
            active
            size={24}
            shape="square"
            className="menu"
          />
          <StyledSkeletonIcon
            active
            size={24}
            shape="square"
            className="menu"
          />
        </StyledMenuSkeleton>
      )}
      <StyledSpinnerWrapper>
        <Spinner />
      </StyledSpinnerWrapper>
    </StyledWrapper>
  )
}
