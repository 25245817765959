import { Tabs } from 'antd'
import { InputSearch } from 'components/atoms/input'
import styled from 'styled-components'
import { cssLimitLines } from 'styles'

export const StyledStepSelectCell = styled.div`
  &.name {
    max-width: 320px;
    .title {
      margin-bottom: 0;
      color: ${({ theme }) => theme?.colors?.gray800};
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      ${cssLimitLines(1)}
    }
    .id {
      color: ${({ theme }) => theme?.colors?.gray600};
      font-size: 12px;
      line-height: 1.5;
      margin-bottom: 0;
    }
  }
`

export const StyledStepSelectTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    display: inline-flex;
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding-top: 2px;
          padding-bottom: 8px;
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: -0.2px;
        }
      }
    }

    .ant-tabs-nav-operations,
    .ant-tabs-content-holder {
      display: none;
    }
  }
`

export const StyledStepSelectInputSearch = styled(InputSearch)`
  max-width: 240px;
  .ant-input-affix-wrapper {
    padding-block: 5px;
  }
`

export const StyledStepSelectHeaderTabs = styled.div``

export const StyledStepSelectHeaderSearch = styled.div``

export const StyledStepSelectHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledSelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  .ant-table {
    td {
      background-color: #fff;
    }
    .ant-table-title {
      padding: 0 0 12px;
      border: none;
    }
    .ant-table-footer {
      padding-inline: 0;
      border: none;
    }

    &.ant-table-bordered {
      background-color: transparent;
      > .ant-table-container {
        border-radius: 8px;
        > .ant-table-header {
          > table {
            border-top: 0;
            border-right: 0;
            border-top-left-radius: 8px !important;
            border-top-right-radius: 8px !important;
            > thead {
              > tr {
                > th {
                  border-top: 1px solid
                    ${({ theme }) => theme?.colors?.neutral100};
                  border-right: 0;
                  &:first-child {
                    border-top-left-radius: 8px;
                  }
                  &:last-child {
                    border-top-right-radius: 8px !important;
                    border-right: 1px solid
                      ${({ theme }) => theme?.colors?.neutral100};
                  }
                }
              }
            }
          }
        }
        > .ant-table-body {
          > table {
            > tbody {
              > tr {
                &:last-child {
                  > td {
                    &:first-child {
                      border-bottom-left-radius: 8px;
                    }
                  }
                }
                > td {
                  border-right: 0;
                  &:last-child {
                    border-right: 1px solid
                      ${({ theme }) => theme?.colors?.neutral100};
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-message {
  }
`
