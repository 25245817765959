import { Skeleton } from 'antd'
import styled from 'styled-components'

export const StyledSegmentFormItemInput = styled.div`
  &.custom_range {
    max-width: 300px;
  }
`

export const StyledSegmentFormSkeleton = styled(Skeleton)`
  &.form_item {
    .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
      margin-top: 8px;
    }
    .ant-skeleton-content .ant-skeleton-paragraph > li + li {
      margin-top: 4px;
    }
    & + & {
      margin-top: 16px;
    }
  }
`
