import { Col, Divider, Form, Radio, Skeleton } from 'antd'
import styled from 'styled-components'

export const StyledStepOverviewsSkeleton = styled(Skeleton)`
  &.title {
    .ant-skeleton-content {
      display: flex;
      align-items: center;
      .ant-skeleton-title {
        width: 100% !important;
        margin-bottom: 0;
      }
      .ant-skeleton-paragraph {
        display: none;
      }
    }
  }
  &.form_item {
    .ant-skeleton-content {
      display: flex;
      align-items: center;
      .ant-skeleton-title {
        width: 25% !important;
        margin-bottom: 0;
      }
      .ant-skeleton-paragraph {
        flex: 1;
        min-width: 0;
        margin: 0;
        margin-left: 24px;
        li {
          + li {
            display: none;
          }
        }
      }
    }

    &.paragraph {
      .ant-skeleton-paragraph {
        li {
          + li {
            display: block;
          }
        }
      }
    }
  }

  & + & {
    margin-top: 16px;
  }
`

export const StyledStepOverviewsDivider = styled(Divider)`
  &.divider {
    border-color: ${({ theme }) => theme?.colors?.neutral100};
    margin-top: 24px;
    margin-bottom: 24px;
  }
`

export const StyledStepOverviewsTitle = styled.h2`
  margin-bottom: 32px;
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.2px;
`

export const StyledStepOverviewsRadio = styled(Radio)`
  width: 100%;
  align-items: flex-start;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.2px;

  .label {
    color: ${({ theme }) => theme?.colors?.gray800};
    margin-top: 2px;
  }
  .description {
    margin: 0;
    color: ${({ theme }) => theme?.colors?.gray600};
    font-style: italic;
  }
`

export const StyledStepOverviewsRadioGroup = styled(Radio.Group)`
  ${StyledStepOverviewsRadio} {
    + ${StyledStepOverviewsRadio} {
      margin-top: 16px;
    }
  }
`

export const StyledStepOverviewsFormItem = styled(Form.Item)`
  .ant-form-item-row {
    align-items: flex-start;
    .ant-form-item-label {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.5;
      > label {
        height: auto;
        i {
          margin-left: 2px;
          font-weight: 400;
        }
      }
    }
  }
`

export const StyledStepOverviewsCol = styled(Col)`
  &.form_label {
    max-width: 360px;
  }
  &.form_input {
    max-width: 540px;
  }
`

export const StyledStepOverviews = styled.div`
  padding: 16px 24px 24px;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  border-radius: 8px;
`
