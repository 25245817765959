import { TemplateStatus, TemplateType } from '../landingPage/helpers'
import { initTemplateBodyJson } from '../mockData/email'
import HTMLMinifier from './htmlminifier.esm.bundle'
import { PremiumNewsletter } from './premium-newsletter'
import { templateBuilderToHTML } from '@luctc/template-builder'
import { isProduction } from 'constants/env'
import { EMAIL_DOMAINS_VALID } from 'constants/site'
import html2canvas from 'html2canvas'
import { OptionTypeSite } from 'interfaces'
import { TemplateServices } from 'services/template'

export type EmailSettingsCommon = {
  name: string
  message_type: string
  from_email: string
  from_name: string
  rep_to_mail: string
  subject_line: string
  pre_header_text: string
  country_code: string
  site?: string | number
  site_name: string
  thumbnail?: string
}

export type EmailSettingsForm = EmailSettingsCommon & {
  cc: string
  bcc: string
}

export type EmailSettingsParams = EmailSettingsCommon & {
  type: TemplateType
  status: TemplateStatus

  cc: string[]
  bcc: string[]
  body_html: string
  body_json: any
  // start_date: string
  // end_date: string
}

export type EmailSettingsAPI = EmailSettingsParams & {
  templateId: number
  settingId: number
  created_at: string
  updated_at: string
}

export type TemplateData = EmailSettingsAPI | undefined

export function trasnformResponseToSettingsAPI(respData: any) {
  const data: EmailSettingsAPI = {
    templateId: respData.template_id,
    settingId: respData.id,
    created_at: respData.created_at,
    updated_at: respData.updated_at,

    name: respData.template.name,
    type: respData.template?.type || 'email',
    status: respData.template?.status || 'draft',
    body_html: respData.template?.body_html || '',
    body_json: respData.template?.body_json || initTemplateBodyJson,
    thumbnail: respData.template.thumbnail || '',

    message_type: respData.message_type || '',
    from_email: respData.from_email || '',
    from_name: respData.from_name || '',
    rep_to_mail: respData.rep_to_mail || '',
    subject_line: respData.subject_line || '',
    pre_header_text: respData.pre_header_text || '',
    country_code: getCountryCodeByFromEmail(respData.from_email),
    cc: respData.cc || [],
    bcc: respData.bcc || [],
    site_name: respData.site_name || ''
  }
  return data
}

export const transformEmailApiToSettingsForm = (respData: any) => {
  const data: EmailSettingsForm = {
    name: respData.template?.name || '',
    message_type: respData.message_type || '',
    from_email: respData.from_email || '',
    from_name: respData.from_name || '',
    country_code: getCountryCodeByFromEmail(respData.from_email),
    rep_to_mail: respData.rep_to_mail || '',
    subject_line: respData.subject_line || '',
    pre_header_text: respData.pre_header_text || '',
    cc: (respData.cc || []).join(',') || '',
    bcc: (respData.bcc || []).join(',') || '',
    site_name: respData.site_name || ''
  }

  return data
}

export const transformSettingsApiToParams = (data: EmailSettingsAPI) => {
  const returnData: EmailSettingsParams = {
    name: data.name,
    message_type: data.message_type,
    from_email: data.from_email,
    from_name: data.from_name,
    country_code: getCountryCodeByFromEmail(data.from_email),
    rep_to_mail: data.rep_to_mail,
    subject_line: data.subject_line,
    pre_header_text: data.pre_header_text,
    cc: data.cc,
    bcc: data.bcc,

    type: data.type,
    status: data.status,
    body_html: data.body_html,
    body_json: data.body_json,
    site_name: data.site_name || ''
  }

  return returnData
}

export const getCountryCodeByFromEmail = (
  fromEmail: string,
  useLocale?: boolean
) => {
  const mapSiteToCountryCode: Record<string, string> = {
    'hellobacsi.com': useLocale ? 'vi-VN' : 'VN',
    'hellosehat.com': useLocale ? 'id-ID' : 'ID',
    'hellodoktor.com': useLocale ? 'ms-MY' : 'MY',
    'hellokrupet.com': useLocale ? 'km-KH' : 'KH',
    'hellodoctor.com.ph': useLocale ? 'en-PH' : 'PH',
    'hellokhunmor.com': useLocale ? 'th-TH' : 'TH',
    'hellosayarwon.com': useLocale ? 'my-MM' : 'MM',
    'helloyishi.com.tw': useLocale ? 'zh-TW' : 'TW',
    'helloswasthya.com': useLocale ? 'hi-IN' : 'IN'
  }

  if (!fromEmail) {
    return mapSiteToCountryCode['hellobacsi.com']
  }

  for (const domain in mapSiteToCountryCode) {
    if (fromEmail.includes(domain)) {
      return mapSiteToCountryCode[domain]
    }
  }

  return mapSiteToCountryCode['hellobacsi.com']
}

export const getSiteIdByFormEmail = (
  sites: OptionTypeSite[],
  fromEmail: string
) => {
  let domainSite = ''
  const mapFormEmailToSite: Record<string, string> = {
    'hello@hellobacsi.com': 'hellobacsi.com',
    'hello@hellosehat.com': 'hellosehat.com',
    'hello@hellodoctor.com.ph': 'hellodoctor.com.ph',
    'hello@hellodoctor.com.ph/fil': 'hellodoctor.com.ph/fil',
    'hello@hellodoktor.com': 'hellodoktor.com',
    'hello@hellokhunmor.com': 'hellokhunmor.com',
    'hello@hellokrupet.com': 'hellokrupet.com',
    'hello@helloyishi.com.tw': 'helloyishi.com.tw',
    'hello@hellosayarwon.com': 'hellosayarwon.com',
    'hello@helloswasthya.com': 'helloswasthya.com',
    'hello@marrybaby.vn': 'www.marrybaby.vn',
    'newsletter@hellobacsi.com': 'hellobacsi.com',
    'no-reply@hellohealthgroup.com': 'hellohealthgroup.com',
    'admin@hellohealthgroup.com': 'hellohealthgroup.com',
    'product@hellohealthgroup.com': 'hellohealthgroup.com',
    'all@hellohealthgroup.com': 'hellohealthgroup.com',
    'doan.le@hellohealthgroup.com': 'hellohealthgroup.com'
  }
  domainSite = mapFormEmailToSite[fromEmail]
  const res = sites.find((site) => site.domain === domainSite)
  if (!res) {
    return ''
  }
  return res.value.toString()
}

export const getSiteNameByFormEmail = (
  sites: OptionTypeSite[],
  fromEmail: string
) => {
  let domainSite = ''
  const mapFormEmailToSite: Record<string, string> = {
    'hello@hellobacsi.com': 'hellobacsi.com',
    'hello@hellosehat.com': 'hellosehat.com',
    'hello@hellodoctor.com.ph': 'hellodoctor.com.ph',
    'hello@hellodoctor.com.ph/fil': 'hellodoctor.com.ph/fil',
    'hello@hellodoktor.com': 'hellodoktor.com',
    'hello@hellokhunmor.com': 'hellokhunmor.com',
    'hello@hellokrupet.com': 'hellokrupet.com',
    'hello@helloyishi.com.tw': 'helloyishi.com.tw',
    'hello@hellosayarwon.com': 'hellosayarwon.com',
    'hello@helloswasthya.com': 'helloswasthya.com',
    'hello@marrybaby.vn': 'www.marrybaby.vn',
    'no-reply@hellohealthgroup.com': 'hellohealthgroup.com',
    'product@hellohealthgroup.com': 'hellohealthgroup.com',
    'all@hellohealthgroup.com': 'hellohealthgroup.com',
    'doan.le@hellohealthgroup.com': 'hellohealthgroup.com'
  }
  domainSite = mapFormEmailToSite[fromEmail]
  return domainSite || 'hellobacsi.com'
}

export const minifyHtmlFormat = (rawHtml: string) => {
  return HTMLMinifier.minify(rawHtml, {
    removeRedundantAttributes: true,
    collapseInlineTagWhitespace: false,
    collapseWhitespace: true,
    minifyCSS: true,
    minifyURLs: false,
    preventAttributesEscaping: true,
    processConditionalComments: true,
    removeAttributeQuotes: true,
    removeComments: true,
    removeEmptyAttributes: true,
    removeEmptyElements: false,
    removeOptionalTags: false,
    removeScriptTypeAttributes: false,
    removeTagWhitespace: true,
    decodeEntities: false
  })
}

export const templateToHTMLWithPreHeader = async (
  body_json: any,
  preheader: string,
  isPremiumNewsletter: boolean
) => {
  const rawOriginalHtml = isPremiumNewsletter
    ? PremiumNewsletter
    : templateBuilderToHTML(body_json)

  const rawHtml = rawOriginalHtml.replace(
    `</head>`,
    `
    <div style="font-size:0px;line-height:1px;mso-line-height-rule:exactly;display:none;max-width:0px;max-height:0px;opacity:0;overflow:hidden;mso-hide:all;">
      ${preheader}
    </div>
    </head>
  `
  )

  const rawHtmlMinify: string = await minifyHtmlFormat(rawHtml)
  // console.log('rawHtmlMinify', rawHtmlMinify)
  return rawHtmlMinify
}

export const getDomainBySite = (
  sites: OptionTypeSite[],
  id: string | number
) => {
  const result = sites.find((site) => site.value === id)
  if (!result) {
    return ''
  }
  return result.domain
}

export const isValidEmailTest = (email: string) => {
  if (!email) {
    return false
  }

  const arrParts = email.split('@')

  if (arrParts.length !== 2) {
    return false
  }

  const domainPart = arrParts[1]

  return EMAIL_DOMAINS_VALID.includes(domainPart)
}

export const getScreenshotByHtml = async (html: string, id: number) => {
  let root = document.querySelector('#screenshot-area') as HTMLElement

  if (!root) {
    root = document.createElement('div')
    root.id = 'screenshot-area'
    root.style.zIndex = '-1000'
    root.style.pointerEvents = 'none'
    root.style.position = 'relative'
    root.style.left = '-100vw'
    document.body.append(root)
  } else {
    root.innerHTML = ''
  }

  try {
    const container = document.createElement('div')
    container.style.width = `${500}px`
    container.style.height = `${Math.ceil(500 * 0.66)}px`
    container.style.position = 'fixed'
    container.style.top = '0'
    container.style.left = '0'
    container.style.background = '#fff'
    // container.style.zIndex = '999'
    // container.style.opacity = '0'
    // container.style.pointerEvents = 'none'

    container.innerHTML = html

    root.appendChild(container)

    const templateContainer = container.querySelector(
      '.nl-container'
    ) as HTMLElement

    const actualWidth = templateContainer.clientWidth
    const actualHeight = Math.ceil(actualWidth * 0.66)

    container.style.width = `${actualWidth}px`
    container.style.height = `${actualHeight}px`

    const canvas = await html2canvas(container, {
      width: actualWidth,
      height: actualHeight,
      windowWidth: actualWidth,
      windowHeight: actualHeight,
      allowTaint: true,
      // proxy: 'http://localhost:3001/api/img-canvas-proxy'
      proxy: `https://${
        isProduction ? '' : 'staging-'
      }subot.hellohealthgroup.com/api/img-canvas-proxy`
    })

    // const img = document.createElement('img')
    // img.style.width = `${actualWidth}px`
    // img.style.height = `${actualHeight}px`
    // img.src = canvas.toDataURL()

    container.innerHTML = ''
    container.append(canvas)

    const blob = await new Promise<Blob | null>((resolve) => {
      canvas.toBlob(
        (result) => {
          resolve(result)
        },
        'image/jpeg',
        0.5
      )
    })

    // // root.innerHTML = ''

    if (!blob) {
      return ''
    }

    const fileName = isProduction
      ? `prod_template_${id}.png`
      : `staging_template_${id}.png`
    const file = new File([blob], fileName, { type: 'image/png' })
    return (await TemplateServices.uploadMedia(file, true)).data.URL || ''
  } catch (err) {
    // root.innerHTML = ''
    return ''
  }
}

export const PREMIUM_NEWSLETTER_IDS = isProduction ? 327 : 234
