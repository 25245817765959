import { Select } from '..'
import Icon from '@ant-design/icons'
import styled from 'styled-components'

export const StyledIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  line-height: 0;
  vertical-align: middle;

  svg {
    display: inline-block !important;
  }
`

export const StyledSelectItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledSelect = styled(Select)`
  width: 100%;
`
