import { Button, ButtonProps, Row, Space, SpaceProps } from 'antd'
import styled from 'styled-components'
import { cssButton } from 'styles'

export const StyledCampaignButton = styled(Button)<ButtonProps>`
  display: flex;
  align-items: center;
  svg {
    margin-right: 7px;
  }
  &.ant-btn-lg {
    border-radius: ${({ theme }) => theme?.borderRadiusBase || ''};
    .anticon {
      font-size: 20px;
      vertical-align: middle;
    }
    &.expand {
      border-color: ${({ theme }) => theme?.colors?.blue800 || ''};
      background-color: ${({ theme }) => theme?.colors?.blue800 || ''};
      color: ${({ theme }) => theme?.colors?.white || ''};
    }
  }
  &.btn--create {
    ${cssButton}
  }

  &.btn--insight {
    justify-content: center;
    .anticon {
      svg {
        margin-right: 0;
      }
    }
  }
`

export const StyledCampaignTableSpace = styled(Space)<SpaceProps>`
  .btn-insight {
    border: 1px solid ${({ theme }) => theme?.colors?.blue600 || ''};
    border-radius: ${({ theme }) => theme?.borderRadiusBase};
    color: ${({ theme }) => theme?.colors?.blue600 || ''};
    svg {
      margin-right: 10px;
    }
  }
`

export const StyledContainerHeader = styled(Row)`
  .header--right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .header--left {
    padding: 5px 0;

    .ant-tabs-top {
      .ant-tabs-nav {
        margin: 0;
      }
      .ant-tabs-tab {
        color: ${({ theme }) => theme?.colors?.gray500 || ''};
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
      }
    }
  }
`

export const StyledCol = styled.div`
  flex: 1;
  .ant-select {
    width: 100%;
  }
`

export const StyleWrapperItem = styled.div`
  margin-bottom: 20px;

  .full-width {
    width: 100%;
  }
`

export const StyleLabel = styled.div`
  margin-bottom: 10px;
  color: ${({ theme }) => theme?.colors?.gray600};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`
