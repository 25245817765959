import { Select, SelectProps } from '..'
import { fetchSegmentList } from './SelectSegment.helpers'
import { sort } from 'constants/common'
import { SEGMENT_TYPE } from 'constants/segment'
import { OptionType } from 'interfaces'
import { SegmentQueryParams } from 'interfaces/segment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { segmentServices } from 'services/segment'
import styled from 'styled-components'

const StyledSelect = styled(Select)`
  width: 100%;
`

export type SelectSegmentState = SegmentQueryParams & {
  total_page: number
}
const initParams: SelectSegmentState = {
  page: 1,
  per_page: 20,
  total_page: 0
}
export type SelectSegmentProps = Omit<SelectProps, 'mode'> & {
  site?: string | number
  type?: string
}
export const SelectSegment = (props: SelectSegmentProps) => {
  const {
    site,
    type,
    value: valueProps,
    defaultValue,
    loading: loadingProps,
    onPopupScroll: onPopupScrollProps,
    options: optionsProps,
    ...restProps
  } = props
  const debounce = useRef<NodeJS.Timeout | null>(null)
  const [value, setValue] = useState(defaultValue || valueProps)
  const [valueOptions, setValueOptions] = useState<OptionType>({
    value: valueProps,
    label: ''
  })
  const [loading, setLoading] = useState(loadingProps)
  const [params, setParams] = useState<SelectSegmentState>({
    ...initParams,
    type: type,
    site_id: site
  })
  const [optionsObject, setOptionsObject] = useState<
    Record<number, OptionType[]>
  >({
    1: []
  })

  const options = useMemo(() => {
    const newOptions = Object.values(optionsObject).flatMap((x) => [...x])
    if (
      !valueOptions.label ||
      newOptions.some(({ value }) => valueOptions?.value === value)
    ) {
      return newOptions
    }

    return newOptions.concat(valueOptions)
  }, [optionsObject, valueOptions])

  const getSegment = useCallback(async (params: SegmentQueryParams) => {
    setLoading(true)
    // API for CSV does not filter by site_id
    if (params.type === SEGMENT_TYPE.CSV) {
      delete params.site_id
    }
    const { data, meta } = await fetchSegmentList(params)
    if (data) {
      setOptionsObject((options) => {
        return {
          ...options,
          [params.page]: data.map((segment: any) => {
            return { value: segment.id, label: segment.name }
          })
        }
      })
    } else if (!data && params.page === 1 && params.search !== '') {
      setOptionsObject({ 1: [] })
    }
    setParams({ ...params, total_page: meta.total_page })
    setLoading(false)
  }, [])

  const onPopupScroll = useCallback(
    (e: any) => {
      const isEndOfList = e.target.scrollTop >= (3 / 5) * e.target.scrollHeight
      if (isEndOfList) {
        if (debounce.current) {
          clearTimeout(debounce.current)
        }
        debounce.current = setTimeout(() => {
          if (params.page < params.total_page) {
            getSegment({ ...params, page: params.page + 1 })
          }
        }, 250)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.page, params.total_page, params.search]
  )

  const onSearch = useCallback(
    (s: string) => {
      setParams({ ...params, page: 1, search: s })
      if (debounce.current) {
        clearTimeout(debounce.current)
      }
      debounce.current = setTimeout(() => {
        setOptionsObject({ 1: [] })
        getSegment({ ...params, page: 1, search: s })
      }, 250)
    },
    [getSegment, params]
  )

  useEffect(() => {
    getSegment({
      page: params.page,
      per_page: params.per_page,
      site_id: type === 'csv' ? undefined : params.site_id,
      type: params.type,
      sort_by: 'created_at',
      sort_type: sort.descend
    })
  }, [params.type, params.site_id])

  useEffect(() => {
    setParams((prs) => ({ ...prs, type, site_id: site, page: 1 }))
    setOptionsObject({})
  }, [site, type])

  useEffect(() => {
    setValue(valueProps)
    ;(async () => {
      if (valueProps) {
        try {
          const { data: segment } = await segmentServices.getSegment(valueProps)
          if (!segment) throw new Error('Not have')
          setValueOptions({ label: segment.name, value: segment.id })
        } catch (error) {
          console.log('** Error : ', error)
        }
      }
    })()
  }, [valueProps])

  return (
    <StyledSelect
      {...restProps}
      defaultValue={defaultValue}
      value={value}
      loading={loading}
      options={options}
      virtual={false}
      filterOption={false}
      showSearch
      onSearch={onSearch}
      onPopupScroll={onPopupScroll}
    />
  )
}
