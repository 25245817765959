import {
  FetchFieldOptionsParams,
  OPERATOR_OPTIONS_BY_TYPE,
  SEGMENT_TYPE_LABEL,
  SegmentConditionFieldOption,
  fetchFieldOptions,
  getFieldOption,
  segmentConditionFieldRules,
  validatorSegmentCondition
} from './SegmentCondition.helpers'
import {
  StyledSegmentConditionButton,
  StyledSegmentConditionCol,
  StyledSegmentConditionRow,
  StyledSegmentConditionSelect
} from './SegmentCondition.styled'
import { SegmentConditionDictionary } from './SegmentConditionDictionary'
import { SegmentConditionValue } from './SegmentConditionValue'
import Icon from '@ant-design/icons'
import { Form, Input } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { defaultValueSegmentPrediction } from 'components/molecules/segment/SegmentPredictionDrawer'
import { SEGMENT_STATUS_KEYS } from 'components/molecules/segment/SegmentStatus'
import { SEGMENT_CONDITION_OPERATOR, SEGMENT_TYPE } from 'constants/segment'
import { Copy2, DataDictionary, TrashBin } from 'icons'
import { OptionType } from 'interfaces'
import { ValidatorRule } from 'rc-field-form/lib/interface'
import { FC, Fragment, ReactNode, useCallback, useMemo, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { filterOption } from 'utils'
import { FormNamePath, getFormNamePath } from 'utils/form'

export type SegmentConditionProps = {
  size?: SizeType
  disabled?: boolean
  site_domain?: string[]
  segment_type: string
  segment_status?: number
  event_id?: number
  rootName?: FormNamePath
  name?: FormNamePath
  rules?: ValidatorRule[]
  children?: ReactNode
}

export const SegmentCondition: FC<SegmentConditionProps> = (props) => {
  const {
    disabled,
    size = 'small',
    site_domain,
    segment_type,
    segment_status,
    event_id,
    rootName,
    name = 'conditions',
    rules,
    children
  } = props
  const [fieldOptions, setFieldOptions] = useState<
    SegmentConditionFieldOption[]
  >([])
  const [loadingFieldOptions, setLoadingFieldOptions] = useState(false)
  const form = Form.useFormInstance()
  const rootCondsName = getFormNamePath(rootName, name)
  const condsValue = Form.useWatch(rootCondsName, form)
  const [open, setOpen] = useState<number>()

  const isPublish = useMemo(
    () => segment_status === SEGMENT_STATUS_KEYS.PUBLIC,
    [segment_status]
  )
  const segmentType = useMemo(
    () => SEGMENT_TYPE_LABEL?.[segment_type || ''] || '',
    [segment_type]
  )
  const optionsField = fieldOptions
    ?.map((fieldOption) => {
      if (fieldOption?.status === 'disable' && !isPublish) {
        return
      }

      return {
        value: fieldOption.field,
        label: fieldOption.name,
        disabled: fieldOption?.status === 'disable'
      }
    })
    .filter(Boolean) as OptionType[]

  const fetchOptions = useCallback(async (input: FetchFieldOptionsParams) => {
    if (!input.segment_type) {
      return
    }
    setLoadingFieldOptions(true)
    const options = await fetchFieldOptions(input)
    setFieldOptions(options)
    setLoadingFieldOptions(false)
  }, [])

  const handleRowItemClick = useCallback(
    (record?: SegmentConditionFieldOption, index?: number) => {
      if (typeof index === 'undefined' || typeof record === 'undefined') {
        return
      }
      const indexValue = condsValue[index]
      if (indexValue) {
        const fieldOption = getFieldOption({
          field: record.field,
          fieldOptions
        })
        form.setFields([
          {
            name: getFormNamePath(rootCondsName, index),
            value: {
              ...indexValue,
              field: record.field,
              type: fieldOption?.type,
              data_type: fieldOption?.data_type,
              operator: undefined,
              value: undefined
            },
            touched: true
          }
        ])
        setOpen(undefined)
      }
    },
    [condsValue, fieldOptions, form, rootCondsName]
  )

  const handleChangeConditionField = (index: number) => {
    return (value: any) => {
      const indexValue = condsValue[index]
      if (indexValue) {
        const fieldOption = getFieldOption({
          field: value,
          fieldOptions
        })
        setTimeout(() => {
          const dataType = getFormNamePath(rootCondsName, [index, 'data_type'])
          const type = getFormNamePath(rootCondsName, [index, 'type'])
          form.setFields([
            {
              name: dataType,
              value: fieldOption?.data_type,
              touched: true
            },
            {
              name: type,
              value: fieldOption?.type,
              touched: true
            }
          ])
        }, 0)
      }
    }
  }

  const handleChangeOperator = (index: number) => {
    return (value: any) => {
      const indexValue = condsValue[index]
      if (
        indexValue &&
        [
          SEGMENT_CONDITION_OPERATOR.EMPTY,
          SEGMENT_CONDITION_OPERATOR.NOT_EMPTY
        ].some((key) => key === value)
      ) {
        const valueName = getFormNamePath(rootCondsName, [index, 'value'])
        setTimeout(() => {
          form.setFields([{ name: valueName, value, touched: true }])
        }, 0)
      }
    }
  }

  useDeepCompareEffect(() => {
    fetchOptions({ segment_type, event_id, site_domain })
  }, [segment_type, event_id, site_domain])

  return (
    <Form.List name={name} rules={rules}>
      {(conds, condsOperation, condsMeta) => {
        return (
          <Fragment>
            {conds.map((cond) => {
              const conditionValue = condsValue?.[cond.name]
              const isSegmentPredictionField = Boolean(
                conditionValue?.field === defaultValueSegmentPrediction.field
              )
              const fieldOption = getFieldOption({
                field: conditionValue?.field,
                fieldOptions
              })
              const valueOptions =
                fieldOption?.values?.map((option) => ({
                  value: option.key,
                  label: option.value
                })) || []
              const operatorOptions =
                OPERATOR_OPTIONS_BY_TYPE?.[
                  fieldOption?.type as keyof typeof OPERATOR_OPTIONS_BY_TYPE
                ]

              if (isSegmentPredictionField) {
                return null
              }

              return (
                <StyledSegmentConditionRow key={cond.key} className="cond_row">
                  <Form.Item name={[cond.name, 'data_type']} hidden>
                    <Input />
                  </Form.Item>
                  <StyledSegmentConditionCol className="cond_left">
                    <div className="col_form form_field">
                      <Form.Item
                        name={[cond.name, 'field']}
                        rules={segmentConditionFieldRules}>
                        <StyledSegmentConditionSelect
                          size={size}
                          options={optionsField}
                          showSearch
                          filterOption={filterOption as any}
                          filterSort={(optionA, optionB) =>
                            optionA.label > optionB.label ? 1 : -1
                          }
                          placeholder="Field"
                          loading={loadingFieldOptions}
                          open={loadingFieldOptions ? false : undefined} // Prevent open dropdown when fetch options loading
                          onChange={handleChangeConditionField(cond.name)}
                        />
                      </Form.Item>
                    </div>
                    <div className="col_form form_operator">
                      <Form.Item
                        name={[cond.name, 'operator']}
                        rules={segmentConditionFieldRules}>
                        <StyledSegmentConditionSelect
                          size={size}
                          options={operatorOptions}
                          showSearch
                          filterOption={filterOption as any}
                          placeholder="Operator"
                          onChange={handleChangeOperator(cond.name)}
                        />
                      </Form.Item>
                    </div>
                    <div className="col_form form_value">
                      <Form.Item
                        name={[cond.name, 'value']}
                        rules={segmentConditionFieldRules}>
                        <SegmentConditionValue
                          disabled={disabled}
                          size={size}
                          site={site_domain}
                          type={fieldOption?.type || conditionValue?.type}
                          operator={conditionValue?.operator}
                          options={valueOptions}
                        />
                      </Form.Item>
                      {segment_type === SEGMENT_TYPE.EVENT_CUSTOM &&
                        !!conditionValue?.field && (
                          <span className="text-gray500">days</span>
                        )}
                    </div>
                  </StyledSegmentConditionCol>
                  <StyledSegmentConditionCol className="cond_right">
                    <StyledSegmentConditionButton
                      onClick={() =>
                        condsOperation.add({ ...conditionValue }, cond.name)
                      }
                      icon={<Icon component={Copy2} />}
                      className="cond_copy"
                      size={size}
                    />
                    <StyledSegmentConditionButton
                      onClick={() => setOpen(cond.name)}
                      icon={<Icon component={DataDictionary} />}
                      className="cond_dictionary"
                      size={size}
                    />
                    <StyledSegmentConditionButton
                      onClick={() => condsOperation.remove(cond.name)}
                      icon={<Icon component={TrashBin} />}
                      className="cond_delete"
                      size={size}
                    />
                  </StyledSegmentConditionCol>
                  {children && (
                    <StyledSegmentConditionCol className="cond_children">
                      {children}
                    </StyledSegmentConditionCol>
                  )}
                </StyledSegmentConditionRow>
              )
            })}
            <StyledSegmentConditionButton
              onClick={() => {
                condsOperation.add({
                  field: undefined,
                  operator: undefined,
                  value: undefined
                })
              }}
              type="link"
              size="small"
              className="cond_add">
              {!segmentType && `Add condition`}
              {!!segmentType && `Add ${segmentType} condition`}
            </StyledSegmentConditionButton>
            <SegmentConditionDictionary
              index={open}
              activeTab={segment_type}
              fieldOptions={fieldOptions}
              onClose={() => setOpen(undefined)}
              onRowItemClick={handleRowItemClick}
            />
          </Fragment>
        )
      }}
    </Form.List>
  )
}

export {
  fetchFieldOptions,
  segmentConditionFieldRules,
  validatorSegmentCondition
}
export type { FetchFieldOptionsParams, SegmentConditionFieldOption }
