import { minifyHtmlFormat } from './emailTemplate/helpers'
import { DownloadOutlined, MobileOutlined } from '@ant-design/icons'
import { download } from '@luctc/template-builder'
import { Button } from 'antd'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

const StyledWrap = styled.div`
  position: fixed;
  top: 68px;
  left: 240px;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;

  .layout-sider-hidden & {
    left: 80px;
    transition: 0.3s ease left;
  }
`

const StyledPreviewIframe = styled.div`
  position: relative;
  height: 100%;

  iframe {
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: 1;
    top: 0;
    left: 0px;
  }
  svg {
    position: absolute;
    left: 50%;
    top: 30px;
    transform: translate(-50%, 0);
  }

  &.mobile-view {
    padding-top: 90px;
    iframe {
      border: 1px solid #c3c3c3;
    }
  }
`

const StyledFloatActions = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  gap: 8px;
  z-index: 2;
`

type Props = {
  htmlTemplate: string
}

type TemplateBuilderPreviewIframeProps = {
  isMobile?: boolean
  className?: string
  htmlTemplate: string
}
export function TemplateBuilderPreviewIframe(
  props: TemplateBuilderPreviewIframeProps
) {
  const { htmlTemplate, isMobile, className } = props

  return (
    <StyledPreviewIframe
      className={cn(className, {
        'mobile-view': isMobile,
        'desktop-view': !isMobile
      })}>
      {isMobile && <MobileBackground />}
      <iframe
        title="Preview" //
        style={{
          width: isMobile ? '320px' : '100%', //
          height: isMobile ? '480px' : '100%',
          border: 0
        }}
        srcDoc={htmlTemplate.replace(
          '<style>',
          `<style>
                  body {
                    overflow: overlay;
                  }
                  body::-webkit-scrollbar {
                    width: 6px;
                  }
                  
                  body::-webkit-scrollbar-track {
                    background-color: transparent;
                  }
                  
                  body::-webkit-scrollbar-thumb {
                    border-radius: 6px;
                    background-color: rgba(0, 0, 0, 0.4);
                  }`
        )}
      />
    </StyledPreviewIframe>
  )
}
function TemplateBuilderPreview({ htmlTemplate }: Props) {
  const [isMobile, setIsMobile] = useState(false)
  const [loading, setLoading] = useState(false)
  const [htmlFormatted, setHtmlFormatted] = useState(htmlTemplate)

  useEffect(() => {
    setLoading(true)
    minifyHtmlFormat(htmlTemplate).then((result: string) => {
      setHtmlFormatted(result)
      setLoading(false)
    })
  }, [htmlTemplate])

  return (
    <StyledWrap>
      <TemplateBuilderPreviewIframe
        isMobile={isMobile}
        htmlTemplate={htmlTemplate}
      />
      <StyledFloatActions>
        <Button
          size="large"
          type="default"
          shape="circle"
          icon={<MobileOutlined />}
          onClick={() => setIsMobile(!isMobile)}
        />
        <Button
          size="large"
          type="primary"
          shape="circle"
          icon={<DownloadOutlined />}
          disabled={loading}
          onClick={() => {
            try {
              const time = Date.now()
              download('Minify_template_' + time + '.html', htmlFormatted)
              download('Template_' + time + '.html', htmlTemplate)
            } catch (err) {}
          }}
        />
      </StyledFloatActions>
    </StyledWrap>
  )
}

function MobileBackground() {
  return (
    <svg width="342px" height="612px" viewBox="0 0 342 612">
      <defs />
      <g
        id="Page-1"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd">
        <g id="Artboard-1" transform="translate(-469.000000, -144.000000)">
          <g
            id="Rectangle-22-+-Oval-4-+-Rectangle-23-+-Oval-5"
            transform="translate(470.000000, 145.000000)">
            <rect
              id="Rectangle-22"
              stroke="#979797"
              fill="#FFFFFF"
              x={0}
              y={0}
              width={340}
              height={610}
              rx={33}
            />
            <circle
              id="Oval-4"
              stroke="#C4C4C4"
              fill="#FBFBFB"
              cx={170}
              cy={577}
              r={25}
            />
            <g
              id="Rectangle-23-+-Oval-5"
              transform="translate(126.000000, 27.000000)">
              <circle
                id="Oval-5"
                stroke="#C4C4C4"
                fill="#FBFBFB"
                cx="4.5"
                cy="4.5"
                r="4.5"
              />
              <path
                d="M23.5032382,0 L64.4967618,0 C66.9838316,0 69,2.01977567 69,4.5 C69,6.98528137 66.9780138,9 64.4967618,9 L23.5032382,9 C21.0161684,9 19,6.98022433 19,4.5 C19,2.01471863 21.0219862,0 23.5032382,0 Z M23.5032382,2 L64.4967618,2 C65.8774903,2 67,3.12257327 67,4.5 C67,5.87798373 65.8761743,7 64.4967618,7 L23.5032382,7 C22.1225097,7 21,5.87742673 21,4.5 C21,3.12201627 22.1238257,2 23.5032382,2 Z"
                id="Rectangle-23"
                fill="#C4C4C4"
              />
              <path
                d="M22.6120746,1 L65.3879254,1 C66.8286855,1 68,2.57160257 68,4.5 C68,6.42917723 66.8273124,8 65.3879254,8 L22.6120746,8 C21.1713145,8 20,6.42839743 20,4.5 C20,2.57082277 21.1726876,1 22.6120746,1 Z"
                id="Path"
                fill="#FBFBFB"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default TemplateBuilderPreview
