import { isProduction } from 'constants/env'

const unsubscribeUrl = isProduction
  ? 'https://id.hellobacsi.com/user/subscriptions'
  : 'https://staging-id.hellobacsi.com/user/subscriptions'
export const PremiumNewsletter = `
<!DOCTYPE html>
<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">

<head>
  <title></title>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <meta name="viewport" content="width=device-width,initial-scale=1">
  <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
  <!--[if !mso]><!-->
  <link rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&amp;display=swap">
  <!--<![endif]-->
  <style>
    * {
      box-sizing: border-box;
      font-family: 'Inter', sans-serif;
    }

    body {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-family: 'Inter', sans-serif;
    }

    a[href] {
      color: #2D87F3;
      text-decoration: none;
    }

    a[x-apple-data-detectors] {
      color: #2D87F3 !important;
      text-decoration: inherit !important
    }

    #MessageViewBody a {
      color: inherit;
      text-decoration: none
    }

    p {
      line-height: inherit;
      font-family: 'Inter', sans-serif;
    }

    .desktop_hide,
    .desktop_hide table {
      mso-hide: all;
      display: none;
      max-height: 0;
      overflow: hidden
    }
    
    @media (min-width:641px) {
      /* .community-tags {
        padding-left: 56px !important;
      } */

      .desktop-px-32 {
        padding-left: 32px !important;
        padding-right: 32px !important;
      }

      .desktop-col-120 {
        width: 120px !important
      }

      .desktop-img-120 {
        width: 120px !important;
        height: 120px !important;
        max-width: 120px !important;
      }
    }

    @media (max-width:640px) {

      .image_block img.big,
      .row-content {
        width: 100% !important
      }

      .mobile_hide {
        display: none
      }

      .stack .column {
        width: 100%;
        display: block
      }

      .mobile_hide {
        min-height: 0;
        max-height: 0;
        max-width: 0;
        overflow: hidden;
        font-size: 0
      }

      .desktop_hide,
      .desktop_hide table {
        display: table !important;
        max-height: none !important
      }
    }
  </style>
</head>

<body
  style="margin:0;padding:0;-webkit-text-size-adjust:none;text-size-adjust:none;background-color: #ffffff;background-image: none;background-position: top left;background-repeat: no-repeat;background-size: auto;">
  <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
    style="mso-table-lspace:0;mso-table-rspace:0;background-color:#E4E8EC">
    <tbody>
      <tr>
        <td>
          <!-- Logo Row -->
          <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;background-size: auto;">
            <tbody>
              <tr>
                <td>
                  <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;background-color: #ffffff;background-image: none;background-position: top left;background-repeat: no-repeat;color: #000000;background-size: auto;width: 640px;"
                    width="640">
                    <tbody>
                      <tr>
                        <td class="column column-1" width="100.000%"
                          style="mso-table-lspace:0;mso-table-rspace:0;vertical-align:top;background-color: transparent;border-bottom: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-top: 0px solid transparent;padding-bottom: 0px;padding-left: 0px;padding-right: 0px;padding-top: 0px;">

                          <table class="spacer_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td style="padding-top: 24px;"></td>
                            </tr>
                          </table>

                          <table class="image_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td
                                style="width: 100%;padding-top: 0px;padding-right: 0px;padding-bottom: 0px;padding-left: 0px;">
                                <div align="center">
                                  <img class="center fixedwidth"
                                    src="https://hhg-common.hellobacsi.com/common/logo/hellobacsi.png" alt=""
                                    style="display:block;height:auto;border:0;width:123px;max-width:100%">
                                </div>
                              </td>
                            </tr>
                          </table>

                          <table class="spacer_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td style="padding-top: 18px;"></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- END Logo Row -->

          <!-- Welcome section -->
          <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;background-size: auto;">
            <tbody>
              <tr>
                <td>
                  <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;background-color: #e3f1ff;background-image: none;background-position: top left;background-repeat: no-repeat;color: #000000;background-size: auto;width: 640px;"
                    width="640">
                    <tbody>
                      <tr>
                        <td class="column desktop-px-32" width="100.000%"
                          style="mso-table-lspace:0;mso-table-rspace:0;vertical-align:top;background-color: transparent;border-top: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-left: 0px solid transparent;padding-top: 40px;padding-right: 16px;padding-bottom: 40px;padding-left: 16px;">

                          <table class="title_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td
                                style="width: 100%;text-align: center;padding-top: 0px;padding-right: 0px;padding-bottom: 0px;padding-left: 0px;">
                                <h2
                                  style="color: #262626;font-size: 26px;font-family: inherit;line-height: 34px;text-align: left;direction: ltr;font-weight: 700;letter-spacing: 0px;margin: 0;padding: 0;">
                                  <span style="display: block;">Xin chào {{.first_name}}! 👋</span>
                                </h2>
                              </td>
                            </tr>
                          </table>

                          <table class="paragraph_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td
                                style="width: 100%;text-align: left;padding-top: 10px;padding-right: 0px;padding-bottom: 10px;padding-left: 0px;">
                                <div
                                  style="font-size: 16px;font-family: inherit;line-height: 28px;letter-spacing: -0.2px;text-align: left;direction: ltr;letter-spacing: 0px;color: #262626;">
                                  <p style="margin:0;">{{.welcome_message}}</p>
                                </div>
                              </td>
                            </tr>
                          </table>

                          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                            style="mso-table-lspace:0;mso-table-rspace:0;" width="100%">
                            <tbody>
                              <tr>
                                <td width="56px" style="mso-table-lspace:0;mso-table-rspace:0;vertical-align:top;">
                                  <table class="image_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                                    role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                                    <tbody>
                                      <tr>
                                        <td
                                          style="width: 100%;padding-top: 0px;padding-right: 0px;padding-bottom: 0px;padding-left: 0px;">
                                          <div align="center">
                                            <a href="https://hellobacsi.com"
                                              style="text-decoration: none;display: block;color: inherit;"
                                              rel="noopener noreferrer nofollow" target="_blank">
                                              <img
                                                src="https://cdn-together.hellohealthgroup.com/2023/06/1686302745_6482f019884976.55502593.png"
                                                alt="https://hellobacsi.com/community"
                                                style="border-radius: 100%;display:block;height:56px;border:0;width:56px;max-width:56px;word-break: break-all;object-fit: cover;" />
                                            </a>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width="12px" style="mso-table-lspace:0;mso-table-rspace:0;vertical-align:top;">
                                  <div style="height: 14px;"></div>
                                </td>
                                <td style="mso-table-lspace:0;mso-table-rspace:0;vertical-align:middle;">
                                  <h3
                                    style="font-size: 14px;font-weight: 600; line-height: 22px; margin: 0; padding: 0;color: #262626;">
                                    <span style="display: inline-block; vertical-align: middle;">Hello Bacsi AI
                                    </span>
                                    <img style="display: inline-block; vertical-align: middle; width: 16px; height: 16px;" src="https://cdn-hermes.hellohealthgroup.com/2023/06/e2126cf9cd029bd1519157291d72069e6597003cc4d59e762d3fa4b2fe363230.png" />
                                  </h3>
                                  <p
                                    style="margin: 0; padding: 0;font-size: 12px;font-weight: 400;line-height: 18px;color: #595959;">
                                    Trợ lý sức khoẻ</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- End welcome section -->

          <!-- Picks For You Title -->
          <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;background-size: auto;">
            <tbody>
              <tr>
                <td>
                  <table class="row-content stack desktop-px-32" align="center" border="0" cellpadding="0" cellspacing="0"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;background-size: auto;padding-right: 16px;padding-bottom: 16px;padding-left: 16px;width: 640px;background-color:#fff;"
                    width="640">
                    <tbody>
                      <tr>
                        <td class="column column-1" width="100.000%"
                          style="mso-table-lspace:0;mso-table-rspace:0;vertical-align:top;background-color: transparent;border-top: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-left: 0px solid transparent;padding-top: 0px;padding-right: 0px;padding-bottom: 0px;padding-left: 0px;">

                          <table class="spacer_block" width="100%" border="0" cellpadding="0"
                            cellspacing="0" role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td style="padding-top: 16px;"></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Picks For You Title -->

          <!-- Health tool section -->
          {{.health_tool_html}}
          <!-- End Health tool section -->

          <!-- Articles from outside -->
          {{.articles_outside_html}}
          <!-- End Articles from outside -->

          <!-- Pick For You HelloBacSi's articles -->
          {{.articles_hhg_html}}
          <!-- End Pick For You HelloBacSi's articles -->

          <!-- Community section -->
          {{.community_section_html}}
          <!-- End Community section -->


          <!-- Find this Newsletter Helpful? -->
          <table class="row row-8" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;background-size: auto;">
            <tbody>
              <tr>
                <td>
                  <table class="row-content stack desktop-px-32" align="center" border="0" cellpadding="0" cellspacing="0"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;padding-right: 16px;padding-left: 16px;padding-top: 40px;padding-bottom:40px;width: 640px;background-color:#fff"
                    width="640">
                    <tbody>
                      <tr>
                        <td class="column column-1" width="100%"
                          style="mso-table-lspace:0;mso-table-rspace:0;vertical-align:top;background-color: #FFF8E2;border-top: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-left: 0px solid transparent;padding-top: 24px;padding-right: 16px;padding-bottom: 24px;padding-left: 16px;border-radius: 8px;">

                          <table class="title_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td
                                style="width: 100%;text-align: center;padding-top: 0px;padding-right: 0px;padding-bottom: 0px;padding-left: 0px;">
                                <h3
                                  style="color: #262626;font-size: 20px;font-family: inherit;line-height: 26px;text-align: center;direction: ltr;font-weight: 700;letter-spacing: 0px;margin: 0;padding: 0;letter-spacing: -0.7px;">
                                  <span style="display: block;">Bản tin này có hữu ích với bạn không?</span>
                                </h3>
                              </td>
                            </tr>
                          </table>

                          <table class="paragraph_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td
                                style="width: 100%;text-align: left;padding-top: 8px;padding-right: 10px;padding-bottom: 8px;padding-left: 10px;">
                                <div
                                  style="font-size: 14px;font-family: inherit;line-height: 22px;letter-spacing: -0.2px;text-align: center;direction: ltr;color: #595959;"
                                  align="center">
                                  <p style="margin:0;max-width: 300px;display:inline-block;">Hãy chia sẻ cảm nhận và đánh giá của bạn bằng cách chọn một biểu tưởng cảm xúc bên dưới!</p>
                                </div>
                              </td>
                            </tr>
                          </table>

                          <div align="center">
                            <table
                              width="300px"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block"
                            >
                              <tbody>
                                <tr>
                                  <td style="padding: 0 6px 0 6px">
                                    <a href="{{.ratingUrl1}}" target="_blank" style="display:block;text-decoration:none;"
                                      ><img
                                        src="https://cdn-hermes.hellohealthgroup.com/thumbnail/rating_1.png"
                                        width="48"
                                        height="48"
                                        style="height: auto; display: block; border: 0"
                                    /></a>
                                  </td>
                                  <td style="padding: 0 6px 0 6px">
                                    <a href="{{.ratingUrl2}}" target="_blank" style="display:block;text-decoration:none;"
                                      ><img
                                        src="https://cdn-hermes.hellohealthgroup.com/thumbnail/rating_2.png"
                                        width="48"
                                        height="48"
                                        style="height: auto; display: block; border: 0"
                                    /></a>
                                  </td>
                                  <td style="padding: 0 6px 0 6px">
                                    <a href="{{.ratingUrl3}}" target="_blank" style="display:block;text-decoration:none;"
                                      ><img
                                        src="https://cdn-hermes.hellohealthgroup.com/thumbnail/rating_3.png"
                                        width="48"
                                        height="48"
                                        style="height: auto; display: block; border: 0"
                                    /></a>
                                  </td>
                                  <td style="padding: 0 6px 0 6px">
                                    <a href="{{.ratingUrl4}}" target="_blank" style="display:block;text-decoration:none;"
                                      ><img
                                        src="https://cdn-hermes.hellohealthgroup.com/thumbnail/rating_4.png"
                                        width="48"
                                        height="48"
                                        style="height: auto; display: block; border: 0"
                                    /></a>
                                  </td>
                                  <td style="padding: 0 6px 0 6px">
                                    <a href="{{.ratingUrl5}}" target="_blank" style="display:block;text-decoration:none;"
                                      ><img
                                        src="https://cdn-hermes.hellohealthgroup.com/thumbnail/rating_5.png"
                                        width="48"
                                        height="48"
                                        style="height: auto; display: block; border: 0"
                                    /></a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Find this Newsletter Helpful? -->


          <!-- Footer -->
          <table class="row row-7" align="center" width="100%" cellpadding="0" cellspacing="0" role="presentation"
            style="mso-table-lspace:0;mso-table-rspace:0;color: #595959;border-top: 1px solid #E4E8EC">
            <tbody>
              <tr>
                <td>
                  <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;width: 640px;background-color:#fff;"
                    width="640">
                    <tbody>
                      <tr>
                        <td class="column column-1 desktop-px-32" width="100.000%"
                          style="mso-table-lspace:0;mso-table-rspace:0;vertical-align:top;padding: 0 16px;">

                          <table class="image_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td
                                style="width: 100%;padding: 0;">
                                <div align="center">
                                  <img class="center autowidth"
                                    src="https://cdn-hermes.hellohealthgroup.com/logo_hhg_circle.png" alt=""
                                    style="display:block;height:auto;border:0;width:auto;max-width:100%">
                                </div>
                              </td>
                            </tr>
                          </table>

                          <table class="paragraph_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td
                                style="width: 100%;text-align: left;padding: 0;">
                                <div
                                  style="font-size: 12px;line-height: 180%;text-align: center;direction: ltr;letter-spacing: 0px;">
                                  <p style="margin:0;">
                                    Bạn đã nhận được email này vì bạn đã đăng ký nhận bản tin cao cấp của Hello Bacsi. Tuy nhiên, nếu bạn không muốn nhận nữa, bạn có thể <a target="_blank" rel="noopener noreferrer nofollow" href="${unsubscribeUrl}">huỷ đăng ký</a> tại đây. Hẹn gặp lại bạn sớm!
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </table>

                          <table class="spacer_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td style="padding-top: 24px;"></td>
                            </tr>
                          </table>

                          <table class="social_block" width="100%" cellpadding="0" cellspacing="0" role="presentation">
                            <tbody>
                              <tr>
                                <td
                                  style="color: #000000;font-family: inherit;font-size: 14px;padding-bottom: 0px;padding-left: 0px;padding-right: 0px;padding-top: 0px;text-align: center;">
                                  <table class="social-table" cellpadding="0" cellspacing="0" role="presentation"
                                    align="center">
                                    <tbody>
                                      <tr>

                                        <td style="padding: 0px 10px;">
                                          <a rel="noreferrer" href="https://www.facebook.com/hellobacsi/"
                                            target="_blank"><img
                                              src="https://cdn-hermes.hellohealthgroup.com/Facebook.png" width="20"
                                              height="20" alt="Facebook" title="Facebook"
                                              style="display: block; height: auto; border: 0px;"></a>
                                        </td>

                                        <td style="padding: 0px 10px;">
                                          <a rel="noreferrer" href="https://www.instagram.com/hellobacsi/"
                                            target="_blank"><img
                                              src="https://cdn-hermes.hellohealthgroup.com/Instagram.png" width="20"
                                              height="20" alt="Instagram" title="Instagram"
                                              style="display: block; height: auto; border: 0px;"></a>
                                        </td>

                                        <td style="padding: 0px 10px;">
                                          <a rel="noreferrer" href="https://oa.zalo.me/hellobacsi" target="_blank"><img
                                              src="https://cdn-hermes.hellohealthgroup.com/Zalo.png" width="20"
                                              height="20" alt="Zalo" title="Zalo"
                                              style="display: block; height: auto; border: 0px;"></a>
                                        </td>

                                        <td style="padding: 0px 10px;">
                                          <a rel="noreferrer" href="https://www.tiktok.com/@hellobacsi"
                                            target="_blank"><img
                                              src="https://cdn-hermes.hellohealthgroup.com/Tiktok.png" width="20"
                                              height="20" alt="TikTok" title="TikTok"
                                              style="display: block; height: auto; border: 0px;"></a>
                                        </td>

                                        <td style="padding: 0px 10px;">
                                          <a rel="noreferrer" href="https://www.youtube.com/c/Hellobacsi_HHG"
                                            target="_blank"><img
                                              src="https://cdn-hermes.hellohealthgroup.com/Youtube.png" width="20"
                                              height="20" alt="YouTube" title="YouTube"
                                              style="display: block; height: auto; border: 0px;"></a>
                                        </td>

                                        <td style="padding: 0px 10px;">
                                          <a rel="noreferrer"
                                            href="https://www.linkedin.com/company/hello-health-group/"
                                            target="_blank"><img
                                              src="https://cdn-hermes.hellohealthgroup.com/Linkedin.png" width="20"
                                              height="20" alt="LinkedIn" title="LinkedIn"
                                              style="display: block; height: auto; border: 0px;"></a>
                                        </td>

                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table class="spacer_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td style="padding-top: 24px;"></td>
                            </tr>
                          </table>

                          <table class="paragraph_block" width="100%" border="0" cellpadding="0" cellspacing="0"
                            role="presentation" style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word">
                            <tr>
                              <td
                                style="width: 100%;text-align: left;padding-top: 0px;padding-right: 10px;padding-bottom: 0px;padding-left: 10px;">
                                <div
                                  style="font-size: 12px;font-family: inherit;line-height: 180%;text-align: center;direction: ltr;letter-spacing: 0px;">
                                  <p style="margin:0;"><strong class="hhg-bold">Hello Health Group</strong></p>
                                  <p style="margin:0;margin-top:0">Tầng 05, 15 Lê Thánh Tôn, Phường Bến Nghé, Quận 01, TP. Hồ Chí Minh, Việt Nam</p>
                                  <p style="margin:0;margin-top:0">© 2023 Bản quyền các bài viết thuộc tập đoàn Hello Health Group.</p>
                                </div>
                              </td>
                            </tr>
                          </table>

                          <table class="spacer_block" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
                            style="mso-table-lspace:0;mso-table-rspace:0">
                            <tr>
                              <td style="padding-top: 16px;"></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- End of Footer -->
        </td>
      </tr>
    </tbody>
  </table><!-- End -->
</body>

</html>
`
