import {
  StyledFooter,
  StyledPageSize,
  StyledPagination,
  StyledSelect,
  StyledTable
} from './index.styled'
import { Table, TablePaginationConfig, TableProps } from 'antd'
import { useTableScroll } from 'hooks/useTableScroll'
import { useEffect, useMemo, useRef, useState } from 'react'

export type TableScrollProps = TableProps<any>

export const pageSizeOption = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 50, label: 50 },
  { value: 100, label: 100 }
]
export const pageSizeDefault = 10
export const paginationDefault = {
  position: [],
  current: 1,
  pageSize: pageSizeDefault
}
type TableScrollFooterProps = {
  dataSource: any[]
  pagination: TablePaginationConfig
  onChange: TableProps<any>['onChange']
}
export const TableScrollFooter = (props: TableScrollFooterProps) => {
  const { dataSource, pagination, onChange } = props

  const selectValue = useMemo(() => {
    if (typeof pagination === 'boolean') {
      return pageSizeDefault
    }

    if (pagination && pagination.pageSize) {
      return pagination.pageSize
    }

    return pageSizeDefault
  }, [pagination])

  return (
    <StyledFooter>
      <StyledPageSize>
        <span>Display</span>
        <StyledSelect
          size="small"
          value={selectValue}
          options={pageSizeOption}
          onChange={(pageSize: any) => {
            onChange &&
              onChange(
                { ...pagination, pageSize, current: 1 },
                {},
                {},
                { action: 'paginate', currentDataSource: dataSource }
              )
          }}
        />
      </StyledPageSize>
      <StyledPagination
        showQuickJumper
        defaultCurrent={1}
        defaultPageSize={pageSizeDefault}
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total || dataSource?.length || 0}
        showSizeChanger={false}
        onChange={(current, pageSize) => {
          onChange &&
            onChange(
              { ...pagination, current, pageSize },
              {},
              {},
              { action: 'paginate', currentDataSource: dataSource }
            )
        }}
      />
    </StyledFooter>
  )
}
export const TableScroll = (props: TableScrollProps) => {
  const {
    loading,
    dataSource,
    scroll: scrollProps,
    pagination: paginationProps,
    footer: footerProps,
    onChange: onChangeProps,
    ...restProps
  } = props
  const data = typeof dataSource === 'undefined' ? [] : (dataSource as any[])
  const tableWrapRef = useRef<HTMLDivElement | null>(null)
  const { scrollTableVal } = useTableScroll(tableWrapRef, data)
  const [pagination, setPagination] = useState<TablePaginationConfig>(
    paginationProps || paginationDefault
  )

  const scroll = scrollProps || {
    y: scrollTableVal
  }

  const onChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sort,
    extra
  ) => {
    setPagination(pagination)
    onChangeProps && onChangeProps(pagination, filters, sort, extra)
  }

  const footer =
    footerProps ||
    (() => (
      <TableScrollFooter
        dataSource={data}
        pagination={pagination}
        onChange={onChange}
      />
    ))

  useEffect(() => {
    setPagination(paginationProps || paginationDefault)
  }, [paginationProps])

  return (
    <StyledTable ref={tableWrapRef} className="tableScroll">
      <Table
        loading={loading}
        dataSource={dataSource}
        scroll={scroll}
        pagination={pagination}
        footer={footer}
        onChange={onChange}
        {...restProps}
      />
    </StyledTable>
  )
}
