import { FormNamePath, getFormNamePath } from '../form/index.helpers'
import { Form, FormListFieldData, FormListOperation, Select } from 'antd'
import { BaseOptionType } from 'antd/lib/select'
import { useEffect, useState } from 'react'
import { sidisBaseAxios } from 'utils/api'

type Props = {
  rootName: FormNamePath
  formListFieldData: FormListFieldData
  formListOperation: FormListOperation
}

const CustomConditionsForm = ({
  rootName,
  formListFieldData: { name: itemName }
}: Props) => {
  const form = Form.useFormInstance()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<BaseOptionType[]>([])

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      try {
        const response = await sidisBaseAxios.get(
          '/api/custom-event-conditions'
        )
        if (response.data.length) {
          setOptions(
            response.data.map((str: string) => ({
              label: str,
              value: str
            }))
          )
        }
      } catch (err) {
        //
      }
      setLoading(false)
    })()
  }, [])

  const onChangeCustomEventConditions = (value: any, _: BaseOptionType) => {
    const customConditionsKey = form.getFieldValue(
      getFormNamePath(rootName, 'customConditionsValue')
    )
    console.log(customConditionsKey)
    form.setFieldValue(
      getFormNamePath(rootName, 'customConditionsValue'),
      value
    )
  }

  return (
    <div className="min-w-[350px] max-w-[70%]">
      <Form.Item
        label=""
        className="item-field"
        name={[itemName, 'customConditionsValue']}
        rules={[
          {
            required: true,
            message: 'Please choose custom conditions'
          }
        ]}>
        <Select
          onChange={onChangeCustomEventConditions}
          options={options}
          placeholder="Custom Conditions"
          showSearch
          optionFilterProp="label"
          mode="multiple"
          loading={loading}
        />
      </Form.Item>
    </div>
  )
}

export default CustomConditionsForm
