import { FIELD_CATEGORY } from 'constants/fieldAttributes'
import {
  SEGMENT_CONDITION_OPERATOR,
  SEGMENT_CONDITION_OPERATOR_LABEL,
  SEGMENT_CONDITION_TYPE,
  SEGMENT_TYPE,
  SegmentConditionOperatorKeys
} from 'constants/segment'
import { OptionType } from 'interfaces'
import { FieldEventServices } from 'services/fieldAttribute'

export type SegmentConditionFieldOption = {
  id: string | number
  name: string
  description?: string
  field: string
  type: string
  data_type: number
  values: { key: string; value: string }[]
  status?: string
}

export type SegmentConditionItem = {
  field?: string
  operator?: string
  value: any
}

export const segmentConditionFieldRules = [
  { required: true, message: 'This field is required' }
]

export const SEGMENT_TYPE_LABEL = {
  [SEGMENT_TYPE.ATTRIBUTE]: 'attribute',
  [SEGMENT_TYPE.MOBILE]: 'mobile',
  [SEGMENT_TYPE.EVENT]: 'event',
  [SEGMENT_TYPE.EVENT_CUSTOM]: 'event',
  [SEGMENT_TYPE.CSV]: 'csv'
}

export const OPERATOR_BY_TYPE: Record<string, SegmentConditionOperatorKeys[]> =
  {
    [SEGMENT_CONDITION_TYPE.array]: [
      'EQUAL',
      'IN',
      'NOT_IN',
      'EMPTY',
      'NOT_EMPTY'
    ],
    [SEGMENT_CONDITION_TYPE.bool]: ['EQUAL'],
    [SEGMENT_CONDITION_TYPE.text]: [
      'EQUAL',
      'NOT_EQUAL',
      'CONTAINS',
      'DOES_NOT_CONTAINS'
    ],
    [SEGMENT_CONDITION_TYPE.number]: [
      'LESS',
      'GREATER',
      'EQUAL',
      'NOT_EQUAL',
      'LESS_OR_EQUAL',
      'GREATER_OR_EQUAL',
      'BETWEEN'
    ],
    [SEGMENT_CONDITION_TYPE.date]: ['LESS', 'GREATER', 'BETWEEN', 'IN_THE_LAST']
  }
export const OPERATOR_OPTIONS_BY_TYPE: Record<string, OptionType[]> = {
  [SEGMENT_CONDITION_TYPE.array]: OPERATOR_BY_TYPE['array'].map((key) => ({
    value: SEGMENT_CONDITION_OPERATOR[key],
    label: SEGMENT_CONDITION_OPERATOR_LABEL[key]
  })),
  [SEGMENT_CONDITION_TYPE.bool]: OPERATOR_BY_TYPE['bool'].map((key) => ({
    value: SEGMENT_CONDITION_OPERATOR[key],
    label: SEGMENT_CONDITION_OPERATOR_LABEL[key]
  })),
  [SEGMENT_CONDITION_TYPE.text]: OPERATOR_BY_TYPE['text'].map((key) => ({
    value: SEGMENT_CONDITION_OPERATOR[key],
    label: SEGMENT_CONDITION_OPERATOR_LABEL[key]
  })),
  [SEGMENT_CONDITION_TYPE.number]: OPERATOR_BY_TYPE['number'].map((key) => ({
    value: SEGMENT_CONDITION_OPERATOR[key],
    label: SEGMENT_CONDITION_OPERATOR_LABEL[key]
  })),
  [SEGMENT_CONDITION_TYPE.date]: OPERATOR_BY_TYPE['date'].map((key) => {
    if (key === 'LESS') {
      return {
        value: SEGMENT_CONDITION_OPERATOR[key],
        label: 'Before'
      }
    }
    if (key === 'GREATER') {
      return {
        value: SEGMENT_CONDITION_OPERATOR[key],
        label: 'After'
      }
    }
    return {
      value: SEGMENT_CONDITION_OPERATOR[key],
      label: SEGMENT_CONDITION_OPERATOR_LABEL[key]
    }
  })
}
type GetFieldOptionInput = {
  field?: string
  fieldOptions?: SegmentConditionFieldOption[]
}
export const getFieldOption = (
  input: GetFieldOptionInput
): SegmentConditionFieldOption | undefined => {
  const { field, fieldOptions } = input

  if (!field || !fieldOptions?.length) {
    return
  }

  const fieldInOptions = fieldOptions.find(
    (fieldOption) => fieldOption.field === field
  )
  if (fieldInOptions && fieldInOptions.type in OPERATOR_OPTIONS_BY_TYPE) {
    return fieldInOptions as SegmentConditionFieldOption
  }
  return
}

export const SEGMENT_TYPE_TO_CATEGORY = {
  [SEGMENT_TYPE.ATTRIBUTE]: FIELD_CATEGORY.ATTRIBUTE,
  [SEGMENT_TYPE.EVENT]: FIELD_CATEGORY.EVENT,
  [SEGMENT_TYPE.EVENT_CUSTOM]: FIELD_CATEGORY.EVENT_CUSTOM,
  [SEGMENT_TYPE.MOBILE]: FIELD_CATEGORY.MOBILE
}

export type FetchFieldOptionsParams = {
  event_id?: number
  site_domain?: string[]
  segment_type: string
}
export const fetchFieldOptions = async (params: FetchFieldOptionsParams) => {
  try {
    const query: any = {
      ...params,
      category: SEGMENT_TYPE_TO_CATEGORY?.[params?.segment_type] || ''
    }

    if (params?.segment_type !== SEGMENT_TYPE.EVENT) {
      query.site = params?.site_domain
    }

    if (params?.segment_type === SEGMENT_TYPE.EVENT) {
      if (!params?.event_id) {
        return []
      }
      query.event_id = params.event_id || ''
    }

    const response: any = await FieldEventServices.getListFields(query)
    if (response._message || !response.data) {
      throw new Error(JSON.stringify(response))
    }
    return response.data?.data
  } catch (error) {
    console.log('** RYAN SegmentCondition.helpers.ts 150 error : ', error)
  }
}

export const validatorSegmentCondition = async (
  _: any,
  vals: string | any[],
  __: any
) => {
  if (!vals || !vals.length) {
    return Promise.reject('Conditions is required')
  }
  return true
}
