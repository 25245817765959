import { DatePicker, Form, Input as InputBase, Radio, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ModalEmpty } from 'components/atoms/modal'
import { SelectSite, SelectTag } from 'components/atoms/select'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import dayjs from 'dayjs'
import { SegmentCreateParams } from 'interfaces/segment'
import { Segment, SegmentCreateForm } from 'interfaces/segment'
import { FC, useState } from 'react'
import { segmentServices } from 'services/segment'
import styled from 'styled-components'
import { cssInputXL } from 'styles'
import { useResetFormOnCloseModal } from 'utils/modal'

const ModalStyled = styled(ModalEmpty)`
  .ant-modal-body {
    .ant-form-item-label {
      padding-bottom: 6px;
    }
  }

  .ant-modal-footer {
    .ant-btn {
      padding-right: 24px;
      padding-left: 24px;
      border-radius: ${(props) => props.theme?.borderRadiusBase};
    }
  }
`

const Input = styled(InputBase)`
  ${cssInputXL}
`

const StyledFormContainer = styled.div`
  .ant-form-item-has-error {
    .filter--dropdown-btn {
      border-color: #ff4d4f;
    }
  }
`

export interface SegmentCreateModalProps {
  visible?: boolean
  onOk?: (value: Segment) => void
  onCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const SegmentCreateModal: FC<SegmentCreateModalProps> = ({
  visible,
  onOk,
  onCancel
}) => {
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const [selectedTimeRange, setSelectedTimeRange] = useState('custom_range')

  const handleOnFieldsChange = (changedFields: any, _allFields: any) => {
    setLoading(false)
    if (changedFields && changedFields.length) {
      const field = changedFields[0]
      const key = field?.name[0] || ''
      const value = field.value
      if (key === 'type_time_range') {
        setSelectedTimeRange(value)
      }
    }
  }

  const getRangeDateConfig = (values: SegmentCreateForm) => {
    if (values.type_time_range === 'custom_range') {
      return {
        start_date: values.custom_time_range
          ? values.custom_time_range[0].format(FORMAT_DATE_DAYJS_API)
          : '',
        end_date: values.custom_time_range
          ? values.custom_time_range[1].format(FORMAT_DATE_DAYJS_API)
          : ''
      }
    }
    if (values.default_time_range) {
      const currentMonth = dayjs().month()
      const startDate = dayjs().month(currentMonth - values.default_time_range)
      const endDate = dayjs()
      return {
        start_date: startDate.format(FORMAT_DATE_DAYJS_API),
        end_date: endDate.format(FORMAT_DATE_DAYJS_API)
      }
    }
  }

  const handleOnOk = () => {
    form &&
      form.validateFields().then(async (values: SegmentCreateForm) => {
        const rangeDate = getRangeDateConfig(values)
        const resBody = {
          name: values.name,
          filters: ['conditions'],
          tag_ids: values.tag_ids ? values.tag_ids : [],
          site_ids: values.site_ids ? values.site_ids : [],
          start_date: rangeDate?.start_date || '',
          end_date: rangeDate?.end_date || ''
        } as SegmentCreateParams
        setLoading(true)
        const res = await segmentServices.createSegment(resBody)
        if (!res.data) {
          message.error({ content: res._message })
          return
        }
        message
          .success('Create Completed. Redirect to edit page!', 0.3)
          .then(() => {
            onOk && onOk(res.data)
          })
        setLoading(false)
      })
  }

  const handleOnCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setLoading(false)
    onCancel && onCancel(e)
  }

  useResetFormOnCloseModal({ form, visible: Boolean(visible) })

  return (
    <ModalStyled
      open={visible}
      closable={false}
      maskClosable={false}
      title="New segment"
      okText="Continue"
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      okButtonProps={{ loading }}
      width={480}>
      <StyledFormContainer>
        <Form
          form={form}
          onFieldsChange={handleOnFieldsChange}
          initialValues={{
            type_time_range: 'custom_range'
          }}
          layout="vertical">
          <Form.Item
            name="name"
            label="Segment name"
            rules={[
              {
                required: true,
                message: 'Please input the name of segment!'
              },
              {
                whitespace: true,
                message: 'Please input segment name with characters'
              },
              {
                max: 255,
                message: 'Maximum 255 characters for segment name'
              }
            ]}>
            <Input placeholder="Segment name" />
          </Form.Item>
          {/* Current to hide */}
          {/* <Form.Item
            name="filters"
            label="Filter"
            rules={[
              {
                required: true,
                message: 'Please select the filters of segment!'
              }
            ]}>
            <SelectCheckboxCategory maxTagCount="responsive" />
          </Form.Item> */}
          <Form.Item name="tag_ids" label="Label">
            <SelectTag
              allowClear
              showSearch
              optionFilterProp="label"
              mode="multiple"
              maxTagCount="responsive"
            />
          </Form.Item>
          <Form.Item
            name="site_ids"
            label="Site"
            rules={[
              {
                required: true,
                message: 'Please select sites for segment!'
              }
            ]}>
            <SelectSite
              hasRole
              allowClear
              showSearch
              optionFilterProp="label"
              mode="multiple"
              maxTagCount="responsive"
            />
          </Form.Item>
          <Form.Item
            label="Time range"
            rules={[
              {
                required: true,
                message: 'Please select type time range'
              }
            ]}
            name="type_time_range">
            <Radio.Group>
              <Radio.Button value="custom_range">Custom range</Radio.Button>
              <Radio.Button value="default_range">Default range</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {selectedTimeRange === 'custom_range' && (
            <Form.Item
              name="custom_time_range"
              label=""
              rules={[
                {
                  required: true,
                  message: 'Please select time range for segment!'
                }
              ]}>
              <DatePicker.RangePicker />
            </Form.Item>
          )}

          {selectedTimeRange === 'default_range' && (
            <Form.Item
              name="default_time_range"
              label=""
              rules={[
                {
                  required: true,
                  message: 'Please select time range for segment!'
                }
              ]}>
              <Radio.Group>
                <Radio.Button value={1}>1 Month</Radio.Button>
                <Radio.Button value={2}>2 Months</Radio.Button>
                <Radio.Button value={3}>3 Months</Radio.Button>
                <Radio.Button value={6}>6 Months</Radio.Button>
              </Radio.Group>
            </Form.Item>
          )}
        </Form>
      </StyledFormContainer>
    </ModalStyled>
  )
}
