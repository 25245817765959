import { CampaignMessagingInsightFilterValue } from '../CampaignMessagingInsightFilter'
import { generateHeatmapList } from './CampaignMessagingInsightTabAudience.helpers'
import {
  StyledCampaignMessagingInsightTabAudience,
  StyledCampaignMessagingInsightTabAudienceButton,
  StyledTitle
} from './CampaignMessagingInsightTabAudience.styled'
import {
  CampaignMessagingInsightTabAudienceHeatmap,
  CampaignMessagingInsightTabAudienceHeatmapRef
} from './CampaignMessagingInsightTabAudienceHeatmap'
import { HeatMapComponent } from './HeatMapComponent'
import Icon from '@ant-design/icons'
import cn from 'classnames'
import { useApp } from 'context/App'
import { Download, Kebab } from 'icons'
import {
  Ref,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react'

export type CampaignMessagingInsightTabAudienceProps = {
  params?: CampaignMessagingInsightFilterValue
}
export type CampaignMessagingInsightTabAudienceRef = {
  refresh?: (value: CampaignMessagingInsightFilterValue) => void
}
export const CampaignMessagingInsightTabAudienceWithoutRef = (
  props: CampaignMessagingInsightTabAudienceProps,
  ref?: Ref<CampaignMessagingInsightTabAudienceRef>
) => {
  const { params } = props
  const { sites: sitesApp } = useApp()
  const heatmapsRef = useRef<
    Record<string, CampaignMessagingInsightTabAudienceHeatmapRef>
  >({})
  const heatmapList = useMemo(() => {
    if (!params?.sites?.length) {
      return []
    }
    return generateHeatmapList(params.sites, sitesApp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.sites, sitesApp])

  const refresh: NonNullable<
    CampaignMessagingInsightTabAudienceRef['refresh']
  > = useCallback((params) => {
    if (!params?.sites || !params.sites?.length) {
      return
    }
    const heatmapList = generateHeatmapList(params.sites, sitesApp)
    heatmapList.forEach((item) => {
      if (
        heatmapsRef.current?.[item.name] &&
        heatmapsRef.current?.[item.name]?.refresh &&
        item.timezones?.[0].value
      ) {
        heatmapsRef.current[item.name].refresh?.(
          params as CampaignMessagingInsightFilterValue,
          item.timezones?.[0].value as string
        )
      }
    })
  }, [])

  useImperativeHandle(ref, () => ({ refresh }), [refresh])
  return (
    <StyledCampaignMessagingInsightTabAudience>
      {!heatmapList?.length && (
        <div className="w-full p-4 bg-white border border-solid border-neutral100 rounded-lg">
          <div className="audienceHead flex items-center justify-between">
            <StyledTitle>Clicks by Time of Day</StyledTitle>
            <div className="audienceHead-actions flex gap-4">
              <StyledCampaignMessagingInsightTabAudienceButton
                disabled
                type="link"
                icon={<Icon component={Download} />}
              />
              <StyledCampaignMessagingInsightTabAudienceButton
                disabled
                type="link"
                icon={<Icon component={Kebab} />}
              />
            </div>
          </div>
          <div className="audienceBody w-full">
            <HeatMapComponent />
          </div>
        </div>
      )}
      {heatmapList?.map((itemHeatmap, index) => {
        return (
          <CampaignMessagingInsightTabAudienceHeatmap
            key={`${index}`}
            ref={(ref) => {
              heatmapsRef.current[itemHeatmap.name] =
                ref as CampaignMessagingInsightTabAudienceHeatmapRef
            }}
            params={params}
            site={itemHeatmap}
            className={cn({ 'mt-4': !!index })}
          />
        )
      })}

      {/* <ClickTableBySegment /> */}
    </StyledCampaignMessagingInsightTabAudience>
  )
}

export const CampaignMessagingInsightTabAudience = forwardRef(
  CampaignMessagingInsightTabAudienceWithoutRef
)
