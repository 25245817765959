import { InsightOverviewFilter } from './InsightOverviewFilter'
import {
  StyledOverviewButton,
  StyledOverviewHeader,
  StyledOverviewTitle
} from './WorkflowInsightOverview.styled'
import { WorkflowInsightOverviewTotal } from './WorkflowInsightTabOverviewTotal'
// import mockData from './mockData'
import Icon from '@ant-design/icons'
import { Empty, Space } from 'antd'
import { Download, Filter } from 'icons'
import { WorkflowInsight } from 'interfaces/workflow'
import { FC, useCallback, useState } from 'react'

const filterDefault: InsightOverviewFilter = {
  channel_ids: ['EMAIL', 'WEB_PUSH', 'APP_PUSH', 'WHATSAPP']
}
export type WorkflowInsightOverviewProps = { data?: WorkflowInsight }
const WorkflowInsightOverview: FC<WorkflowInsightOverviewProps> = (props) => {
  const { data } = props
  // const data = mockData as any
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false)
  const [filter, setFilter] = useState(filterDefault)

  const handleFinishFilter = (value: any) => {
    setOpenDrawerFilter(false)
    setFilter(value)
  }

  const onReset = useCallback(() => {
    setFilter(filterDefault)
    setOpenDrawerFilter(false)
  }, [])

  if (!data) {
    return null
  }

  return (
    <>
      <StyledOverviewHeader>
        <StyledOverviewTitle>Overview</StyledOverviewTitle>
        <Space size={24}>
          <StyledOverviewButton
            size="small"
            icon={<Icon component={Filter} />}
            onClick={() => setOpenDrawerFilter(true)}
            className="filterBtn">
            Filter
          </StyledOverviewButton>
          <StyledOverviewButton
            size="small"
            type="link"
            icon={<Icon component={Download} />}
            className="downloadBtn">
            Download
          </StyledOverviewButton>
        </Space>
      </StyledOverviewHeader>

      {data?.insights ? (
        <WorkflowInsightOverviewTotal
          channel_ids={filter.channel_ids}
          nodes={data?.nodes || []}
          insights={data?.insights || {}}
          total={data?.totalInsights || {}}
          totalDetail={data?.totalInsightsDetail || {}}
        />
      ) : (
        <Empty description="No data insights for this workflow" />
      )}

      {/* Filter */}
      <InsightOverviewFilter
        initialValues={filterDefault}
        open={openDrawerFilter}
        onClose={() => setOpenDrawerFilter(false)}
        onReset={onReset}
        onFinish={handleFinishFilter}
      />
    </>
  )
}

export { WorkflowInsightOverview }
