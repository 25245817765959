import { Drawer, Tabs } from 'antd'
import styled from 'styled-components'

export const StyledCopilotTitle = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 600;
`

export const StyledSettings = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 65px;
  padding: 16px;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.white};
  max-height: calc(100% - 65px);
  overflow: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);

  .setting-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    display: block;
    margin-bottom: 4px;
    color: ${(props) => props.theme.colors.gray600};

    & ~ .setting-title {
      margin-top: 24px;
    }
  }

  .setting-group {
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
  }

  .setting-group .ant-btn {
    font-weight: 400;
    text-transform: capitalize;

    &,
    &:focus {
      box-shadow: none;
      color: ${(props) => props.theme.colors.gray800};
      border-color: ${(props) => props.theme.colors.neutral200};
      background-color: ${(props) => props.theme.colors.white};
    }

    &.active {
      color: ${(props) => props.theme.colors.primary};
      background-color: ${(props) => props.theme.colors.blue50};
      border-color: ${(props) => props.theme.colors.blue50};
    }
  }

  .group-btn-footer {
    margin-top: 24px;
    display: flex;
    gap: 8px;
  }
`

export const StyledSelectedContent = styled.div`
  padding: 8px 12px;
  margin-top: 16px;
  background-color: ${(props) => props.theme.colors.gray50};

  .selection-head {
    color: ${(props) => props.theme.colors.gray400};
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .tiptap {
    padding: 0 !important;
  }
`

export const StyledSelectedType = styled.div`
  text-align: right;
  pointer-events: none;
  margin-top: 24px;
`

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  > svg {
    cursor: pointer;
  }
`

export const StyledActionsBottom = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;

  .ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 1em;
      height: 1em;

      path {
        fill: currentColor;
      }
    }
  }
`

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 0;
  }
`
export const StyledDrawerMain = styled.div`
  flex: 1;
  overflow: auto;
  padding: 16px;
  padding-top: 0;
`
export const StyledDrawerPreview = styled.div`
  height: ${Math.max(window.innerHeight / 2 - 64, 350)}px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral100};
  border-bottom: 4px solid #45a4ff;
  border-radius: 0px 0px 7px 7px;

  .preview-head {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 5px;

    > svg {
      fill: #cdd4db;
      background-color: #f4f6f8;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        fill: #566b7c;
      }
    }
  }

  .preview-content {
    flex: 1;
    overflow: auto;
    border: 1px solid ${({ theme }) => theme.colors.neutral100};

    .recommended-content {
      margin-top: 0;
      background-color: transparent;
    }
  }

  .preview-footer {
    height: 42px;
    padding-top: 10px;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;

    svg {
      width: 1em;
      height: 1em;

      path {
        fill: currentColor;
      }
    }
  }
`

export const StyledTabs = styled(Tabs)`
  &.ant-tabs {
    color: ${({ theme }) => theme.colors.gray400};

    .tiptap {
      color: #000;

      .toolbars {
        display: none !important;
      }
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
    color: ${({ theme }) => theme.colors.gray800};
  }
  .ant-tabs-nav {
    margin: 0 -16px;
    padding: 0 16px;
    padding-top: 10px;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }
  .ant-tabs-tab {
    font-size: 12px;
    & + .ant-tabs-tab {
      margin-left: 16px;
    }
  }
  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.gray800};
  }
  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 1px;
  }
`
export const StyledTabsTemplate = styled.div`
  padding-top: 16px;

  .ant-form-item-control-input {
    min-height: 32px;
  }
  .ant-form-vertical .ant-form-item .ant-form-item-label {
    padding-bottom: 4px;
    font-size: 12px;
  }
`

export const StyledBubbleIcon = styled.div`
  position: fixed;
  top: 100px;
  right: -24px;
  width: 48px;
  height: 48px;
  background-color: #e3f2ff;
  border-radius: 24px 0px 0px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e3f2ff;
  border-right: 0;
  transition: 0.3s ease;
  cursor: pointer;
  z-index: 1;

  &:hover {
    border-color: #2d87f3;
    right: 0;
  }
`
