import styled, { createGlobalStyle, css } from 'styled-components'

export const cssLimitLines = (lines?: number) => `
  overflow: hidden;
	text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${lines || 1};
  line-clamp: ${lines || 1};
  -webkit-box-orient: vertical;
`

export const cssDropdownMenuTable = css`
  &.menuDropdownTable {
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
    box-shadow: none;
    .ant-dropdown-menu-item {
      padding: 10px 16px;
      line-height: 1.5;
      &.ant-dropdown-menu-item-selected {
        background-color: ${({ theme }) => theme?.colors?.gray50 || ''};
        color: ${({ theme }) => theme?.colors?.gray800 || ''};
      }
      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
`

export const cssDropdownOverlay = css`
  min-width: 180px;
  background-color: ${({ theme }) => theme?.colors?.white || ''};
  border-radius: ${({ theme }) => theme?.borderRadiusBase};
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
`

export const cssScrollBar = `
	&::-webkit-scrollbar {
		width: 6px;
		height: 6px
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.4);
		border-radius: 6px;
	}
`

export const cssPadding = `
	padding: 20px 24px;
`

export const cssTitle = `
	margin-bottom: 0;
	font-size: 18px;
	font-weight: 700;
	line-height: 1.4;
`

export const cssMenuDropdown = css`
  &.ant-dropdown-menu {
    min-width: 260px;
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid ${({ theme }) => theme?.colors?.gray200 || ''};
    border-radius: ${({ theme }) => theme?.borderRadiusBase || ''};
    .ant-dropdown-menu-item {
      padding: 10px 16px;
      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      .anticon {
        font-size: 16px;
      }
      &.ant-dropdown-menu-item-disabled {
        .ant-dropdown-menu-item-icon {
          opacity: 0.4;
        }
      }
    }
  }
`

export const cssButton = css`
  &.ant-btn-lg {
    display: flex;
    align-items: center;
    padding: 9px 24px;
    font-weight: 700;
    line-height: 1.5;
    .anticon {
      display: flex;
      font-size: inherit;
      line-height: 1;
    }
  }
`

export const cssInputXL = css`
  &.ant-input-lg {
    padding: 11px 16px;
    border-radius: ${({ theme }) => theme?.borderRadiusBase || ''};
    font-size: 16px;
    line-height: 1.5;
  }
`

export const cssSelectSizeXL = css`
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: ${({ theme }) => theme?.borderRadiusBase || ''};
    }
  }

  &.ant-select-lg {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 48px;
        padding: 12px 16px;
        &:after {
          line-height: inherit;
        }
        > .ant-select-selection-search {
          left: 16px;
          display: flex;
          align-items: center;
          .ant-select-selection-search-input {
            width: 100%;
            height: auto;
          }
        }
        .ant-select-dropdown {
          .ant-select-item {
            padding: 12px 16px;
          }
        }
        .ant-select-selection-placeholder {
          line-height: inherit;
        }
        .ant-select-selection-item {
          line-height: inherit;
        }
      }
    }
    &.ant-select-multiple {
      .ant-select-selector {
        height: auto;
        padding: 7px 16px;
        line-height: 1.5;
        &:after {
          line-height: inherit;
        }
        .ant-select-selection-overflow {
          .ant-select-selection-overflow-item {
            .ant-select-selection-item {
              display: flex;
              height: 28px;
              align-items: center;
              padding: 2px 12px;
              margin-top: 2px;
              margin-bottom: 2px;
              background-color: #fff;
              border-radius: 32px;
              font-size: 16px;
              line-height: 1.5;
              .ant-select-selection-item-content {
                margin-right: 8px;
              }
              .ant-select-selection-item-remove {
                display: flex;
                width: 18px;
                height: 18px;
                align-items: center;
                justify-content: center;
                .anticon {
                  display: flex;
                  width: 15px;
                  height: 15px;
                  align-items: center;
                  justify-content: center;
                  background-color: ${({ theme }) =>
                    theme?.colors?.gray600 || ''};
                  border-radius: 50%;
                  color: #fff;
                }
              }
            }
            .ant-select-selection-search {
              left: 0;
              margin-left: 0;
              line-height: 1;
              .ant-select-selection-search-input,
              .ant-select-selection-search-mirror {
                height: 100%;
                line-height: 1;
              }
            }
          }
        }
        > .ant-select-selection-search {
          height: auto;
          line-height: 1;
        }
      }
      .ant-select-selection-placeholder {
        left: 16px;
      }
      &.ant-select-allow-clear {
        .ant-select-selector {
          padding-right: 24px;
        }
      }
    }
  }
`

export const cssButtonXL = css`
  &.ant-btn-lg {
    position: relative;
    min-width: 120px;
    height: auto;
    padding: 11px 30px 11px 16px;
    border-color: ${({ theme }) => theme?.colors?.gray200 || ''};
    color: ${({ theme }) => theme?.colors?.gray800 || ''};
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    &:hover,
    &:focus {
      color: ${({ theme }) => theme?.colors?.gray800 || ''};
    }
    > .anticon {
      position: absolute;
      top: 50%;
      right: 10px;
      display: flex;
      align-items: center;
      color: inherit;
      font-size: 20px;
      transform: translateY(-50%);
      + span {
        margin: 0;
      }
    }
    > span {
      + .anticon {
        margin: 0;
      }
    }
    &.ant-btn-dangerous {
      border-color: ${({ theme }) => theme?.colors?.red400 || '#ff4d4f'};
    }
    &:active,
    &:focus {
      > .anticon {
        position: absolute;
      }
    }
  }
`

export const GlobalStyled = createGlobalStyle<{ theme: ThemeProps }>`
// Menu Dropdown
.dropdown_menu {
	&.ant-dropdown-menu {
    min-width: 180px;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 0px 0px 1px 0px #0000000a, 0px 2px 8px 0px #0000000a,
      0px 10px 16px 0px #0000000a;
    .ant-dropdown-menu-item {
      padding: 9px 16px;
      font-size: 14px;
      line-height: 1.5;
      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}

// Notification
.ant-notification-notice{ 
	&.notificationNoIcon {
		width: 445px;
		padding: 24px;
		margin-bottom: 2px;
		background-color: ${({ theme }) => theme?.colors?.neutral50 || ''};
		.ant-notification-notice-close {
			top: 24px;
		}
		.ant-notification-notice-message {
			color: inherit;
		}
	}
}
// Tag
.ant-tag {
	padding: 1px 12px;
}
// Select Checkbox
.ant-select {
	&.selectCheckbox, &.select {
		.ant-select-selection-overflow-item {
			&.ant-select-selection-overflow-item-rest {
				.ant-select-selection-item {
					height: auto;
					padding: 0;
					border: none;
					margin: 0;
					background-color: transparent;
					line-height: inherit;
					.ant-select-selection-item-content {
						line-height: inherit;
					}
				}
			}
		}
	}
}
// Segmented
.ant-segmented {
  padding: 2px;
  .ant-segmented-item {
    font-size: 12px;
    font-weight: 700;
    &.ant-segmented-item-selected {
      box-shadow: 0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A;
    }
    .ant-segmented-item-label {
      line-height: 28px;
      min-height: 28px;
    }
  }
}

.ant-checkbox-inner {
  border-color: ${(props) => props.theme.colors.gray300}
}
.ant-picker {
	width: 100%;
}
.ant-tabs-tab-btn {
  font-weight: 600;
}
.ant-form-item-with-help {
  margin-bottom: 32px;
}
.ant-form-item-with-help .ant-form-item-explain {
  padding-top: 4px;
  color: ${(props) => props.theme.colors.gray500};
	font-size: 12px;
}
.ant-form-vertical {
	.ant-form-item {
		.ant-form-item-label {
			padding-bottom: 6px;
			> label {
				color: ${(props) => props.theme?.colors?.gray600};
				font-size: 14px;
				font-weight: 700;
			}
		}
	}
}

// Tooltip 
.ant-tooltip-content {
	.ant-tooltip-inner {
		padding: 16px;
	}
}

// Divider
.ant-divider-horizontal {
	margin: 12px 0;
}

.ant-table-thead > tr > th {
  font-weight: 700
}

.ant-modal-close-x {
	font-size: 24px;
}

// For Modal
.modalWrap--center {
	.ant-modal-content {
		border-radius: ${({ theme }) => theme?.borderRadiusBase || ''};
		.ant-modal-confirm-body-wrapper {
			.ant-modal-confirm-body {
				display: flex;
				flex-direction: column;
				align-items: center;
				.anticon {
					margin-right: 0;
					margin-bottom: 16px;
					font-size: 2em;
				}
				.ant-modal-confirm-content {
					margin: 8px 0 0;
					font-style: italic;
				}
			}
			.ant-modal-confirm-btns {
				margin-top: 16px;
				text-align: center;
			}
		}
	}
}

// For Modal Confirm
.modalWrap--confirm {
	.ant-modal-content {
		.ant-modal-confirm-body-wrapper {
			.ant-modal-confirm-title {
				margin-bottom: 0;
				color: ${({ theme }) => theme?.colors?.gray800};
				font-size: 22px;
				font-weight: 600;
				line-height: 1.45;
				+ .ant-modal-confirm-content {
					margin-top: 24px;
				}
			}
			.ant-modal-confirm-btns {
				margin-top: 44px;
				.ant-btn {
					border-width: 1.5px;
				}
			}
		}
	}
}

.modalWrap--delete {
  .ant-modal-content {
    .item-delete {
      color: ${(props) => props.theme?.colors?.gray600};
      font-weight: 600;
    }
  }
}
// For pagination
.ant-pagination {
  .ant-pagination-item-active {
    a {
      color: ${({ theme }) => theme?.colors?.white};
    }
  }
  .ant-pagination-options {
    .ant-pagination-options-quick-jumper {
      input {
        height: 32px;
      }
    }
  }
}

// For Select
.ant-select-dropdown {
  .ant-select-item-option-content {
    display: flex;
    align-items: center;

    svg {
      display: none;
    }
  }
}

// For Select Checkbox
.selectCheckbox {
	.ant-select-selection-overflow-item {
		&.ant-select-selection-overflow-item-rest {
			.ant-select-selection-item {
				background-color: #fff;
				border-radius: 36px;
			}
		}
	}
}
// For popover avatar group
.ant-avatar-group {
  .ant-avatar-circle {
    display: flex;
    align-items: center;
    border: 1px solid;
    background: ${({ theme }) => theme?.colors?.white};
    cursor: pointer;
    text-align: center;
      ${({ theme }) => theme?.colors?.blue100};
    .ant-avatar-string {
      color: ${({ theme }) => theme?.colors?.blue600};
      font-size: 12px;
      font-weight: 700;
    }
    &.avatar-group-item {
			border: 1px solid
			${({ theme }) => theme?.colors?.neutral100};
			cursor: default;
    }
  }
}

.ant-avatar-group-popover {
  .ant-popover-inner-content {
    display: flex;
    .types-group-item {
      display: flex;
      align-items: center;
      border: 1px solid #bfbfbf;
      background: #ffffff;
      border-radius: 50%;
      .ant-avatar-string {
        display: flex;
        align-items: center;
      }
    }
  }
}

// For table
.ant-table {
  .ant-table-thead {
    .ant-table-cell {
      &::before {
        display: none;
      }
    }
    > tr {
      > th.ant-table-cell {
        &:first-child {
          padding-left: 24px;
        }
      }
    }
  }
  .ant-table-tbody {
    td.ant-table-cell {
      &:first-child {
        padding-left: 24px;
      }
    }
  }
  .ant-table-footer {
    padding: 12px 24px;
  }
}

// For Toggle Button Switch
// .ant-switch {
//   min-width: 40px;
//   height: 24px;
//   background-image: none;
//   background-color: ${({ theme }) => theme?.colors?.neutral300};
//   &.ant-switch-checked {
//     background-color: ${({ theme }) => theme?.colors?.blue600};
//   }
//   .ant-switch-handle {
//     width: 16px;
//     height: 16px;
//     top: 4px;
//     left: 4px;
//   }
//   &.ant-switch-checked {
//     .ant-switch-handle {
//       left: calc(100% - 20px);
//     }
//   }
// }

// For statistic
.ant-statistic {
  .ant-statistic-content-value {
    color: ${({ theme }) => theme?.colors?.gray800};
    font-size: 14px;
  }
}

// For radio group
.ant-radio-group {
  .ant-radio-button-wrapper-checked {
    &:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${({ theme }) => theme?.colors?.blue600};
      color: ${({ theme }) => theme?.colors?.white};
    }
  }
}

// For checkbox
.ant-checkbox {
  + span {
    padding-right: 12px;
    padding-left: 12px;
  }
}

// For checkbox group
.ant-checkbox-group {
  &.vertical-options {
    .ant-checkbox-group-item {
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

// For button active filter
.ant-btn {
  &.active-filter {
    border-radius: 4px;
    background: ${({ theme }) => theme?.colors?.blue50};
    border-color: ${({ theme }) => theme?.colors?.blue50};
    box-shadow: none;
    text-shadow: none;
    color: ${({ theme }) => theme?.colors?.blue600};
  }
}
`

export const FormItemErrorContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`

export const FormLogTitle = styled.h2`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
`

export const FormLogContainer = styled.div`
  width: 100%;
  .ant-form-item {
    margin-bottom: 32px;
    transition: none;
    .ant-form-item-label {
      padding-bottom: 6px;
      font-size: 12px;
      font-weight: 700;
    }
    .ant-input {
      padding: 12px 16px;
      border-radius: ${(props) => props.theme?.borderRadiusBase};
    }
    .ant-input-affix-wrapper {
      padding: 12px 16px;
      border-radius: ${(props) => props.theme?.borderRadiusBase};
      .ant-input {
        padding: 0;
      }
    }
    .ant-btn {
      height: auto;
      padding: 12px 24px;
      border-radius: ${(props) => props.theme?.borderRadiusBase};
    }
  }
  .ant-form-item.has-error {
    margin-bottom: 10px;
  }
`
