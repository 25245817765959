import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getElementOuterHeight } from 'utils'

const MIN_SCROLL = 200

export function useTableScroll(
  ref: React.RefObject<HTMLDivElement>,
  trigger: any[]
) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [scrollTableVal, setScrollTableVal] = useState<undefined | number>()

  const calculate = useCallback(() => {
    const tableEl = ref.current

    if (!tableEl) {
      return
    }
    const handler = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        try {
          const heightWrap = tableEl.clientHeight

          const heightTableHeader =
            getElementOuterHeight(
              tableEl.querySelector('.ant-table-thead') || undefined
            ) || 0
          const heightTablePaging =
            getElementOuterHeight(
              tableEl.querySelector('.ant-table-pagination') || undefined
            ) || 0
          const heightTableHeaderTitle =
            getElementOuterHeight(
              tableEl.querySelector('.ant-table-title') || undefined
            ) || 0
          const heightTableHeaderFooter =
            getElementOuterHeight(
              tableEl.querySelector('.ant-table-footer') || undefined
            ) || 0

          const newVal = Math.floor(
            heightWrap -
              heightTableHeader -
              heightTablePaging -
              heightTableHeaderTitle -
              heightTableHeaderFooter -
              2
          )

          setScrollTableVal(newVal >= MIN_SCROLL ? newVal : MIN_SCROLL)
        } catch (err) {}
      }, 100)
    }

    handler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, trigger])

  useEffect(() => {
    calculate()
    window.addEventListener('resize', calculate)
    return () => {
      window.removeEventListener('resize', calculate)
    }
  }, [calculate])

  return { scrollTableVal, calculate }
}
