import { Button, Dropdown, Menu } from 'antd'
import styled from 'styled-components'

export const StyledMenu = styled(Menu)`
  min-width: 158px;
  padding: 0;
  .ant-dropdown-menu-item {
    padding: 9px 16px;
    .anticon {
      font-size: 16px;
    }
    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }
  .red600 {
    color: ${({ theme }) => theme?.colors?.red600};
    * {
      color: inherit;
    }
  }
`

export const StyledButton = styled(Button)`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme?.colors?.gray400};
  .anticon {
    font-size: 20px;
    line-height: 1;
  }
`

export const StyledDropdown = styled(Dropdown)``
