import { StyledTabs } from './index.styled'
import cls from 'classnames'

export type PanelTabItem = {
  key: string
  label: string
  disabled?: boolean
}
type Props = {
  listTabs: PanelTabItem[]
  activeTab: string
  onChangeTab: (activeKey: string) => void
  className?: string
}

export function PanelTabs({
  listTabs,
  activeTab,
  onChangeTab,
  className
}: Props) {
  return (
    <StyledTabs
      className={cls('panel-tabs', className)}
      defaultActiveKey={activeTab ? activeTab : listTabs[0]?.key}
      activeKey={activeTab ? activeTab : listTabs[0]?.key}
      onChange={onChangeTab}
      items={listTabs.map((tab) => ({
        key: tab.key,
        label: tab.label,
        disabled: tab.disabled
      }))}
    />
  )
}
