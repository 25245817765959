import { listTempateStatus, listTemplateTabs } from './index.helpers'
import {
  ListTemplateButton,
  ListTemplateCheckbox,
  ListTemplateContainer,
  ListTemplateInfoItem,
  ListTemplateInfoList,
  ListTemplateInner,
  ListTemplateInputContainer,
  ListTemplateItem,
  ListTemplateItemCol1,
  ListTemplateItemCol2,
  ListTemplateSpace,
  ListTemplateTableCell,
  ListTemplateTabs,
  ListTemplateWrapper,
  ListTemplateWrapperHeader
} from './index.styled'
import Icon from '@ant-design/icons'
import { Divider, Skeleton, Spin } from 'antd'
import cn from 'classnames'
import { Avatar } from 'components/atoms/avatar'
import { InputSearch } from 'components/atoms/input'
import { TEMPLATE_TYPE } from 'constants/template'
import { ChevronDown, ChevronUp } from 'icons'
import { TemplateBuilderListParams } from 'interfaces/template'
import { nanoid } from 'nanoid'
import { TemplateStatus } from 'pages/templateBuilderCreate/landingPage/helpers'
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { TemplateServices } from 'services/template'

const TEMPLATE_PER_PAGE = 20

type RenderItemProps = {
  item: any
  isActive?: boolean
  onChange?: () => void
}
const RenderItem = (props: RenderItemProps) => {
  const { item, isActive, onChange } = props
  const [loading, setLoading] = useState(false)
  const [collapsed, setCollapsed] = useState(true)
  const [info, setInfo] = useState<any>({})

  const getTemplate = useCallback(async () => {
    if (!collapsed) {
      return
    }
    try {
      setLoading(true)
      const templateRes = await TemplateServices.getById(item.id)
      const { data: template } = templateRes
      if (!template.data) {
        throw new Error(templateRes.data.error)
      }

      setLoading(false)
      return template.data
    } catch (error: any) {
      console.log('** Error id: ', item.id, ' => ', error.message)
      setLoading(false)
      return {}
    }
  }, [collapsed, item.id])

  const onCollapsed = useCallback(async () => {
    const newCollapsed = !collapsed
    setCollapsed(newCollapsed)
    if (!info.id && !newCollapsed) {
      const templateInfo = await getTemplate()
      setInfo(templateInfo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTemplate])

  return (
    <Fragment key={item.id}>
      <ListTemplateItem>
        <ListTemplateItemCol1>
          <ListTemplateSpace size={8} className="col1">
            <ListTemplateSpace size={7}>
              <ListTemplateButton
                type="link"
                onClick={onCollapsed}
                icon={<Icon component={collapsed ? ChevronDown : ChevronUp} />}
              />
              <ListTemplateCheckbox checked={isActive} onChange={onChange} />
            </ListTemplateSpace>
            <ListTemplateTableCell
              title={item.name}
              titleLimitLines={1}
              subTitle={`ID: ${item.id}`}
              className="tableCell"
            />
          </ListTemplateSpace>
        </ListTemplateItemCol1>
        <ListTemplateItemCol2>
          <Avatar
            avatar={item.created_by_avatar}
            name={item.created_by}
            email={item.created_by_email}
          />
        </ListTemplateItemCol2>
      </ListTemplateItem>
      {!collapsed && (
        <ListTemplateItem className="extend">
          <ListTemplateItemCol1>
            {loading ? (
              <Skeleton paragraph={{ rows: 2, width: '100%' }} />
            ) : (
              <ListTemplateInfoList>
                <ListTemplateInfoItem>
                  <div className="label">From Name</div>
                  <div className="value">{info?.from_name}</div>
                </ListTemplateInfoItem>
                {Boolean(info?.subject_line) && (
                  <ListTemplateInfoItem>
                    <div className="label">Subject line</div>
                    <div className="value">{info?.subject_line}</div>
                  </ListTemplateInfoItem>
                )}
                {Boolean(info?.pre_header_text) && (
                  <ListTemplateInfoItem>
                    <div className="label">Pre-header</div>
                    <div className="value">{info.pre_header_text}</div>
                  </ListTemplateInfoItem>
                )}
              </ListTemplateInfoList>
            )}
          </ListTemplateItemCol1>
        </ListTemplateItem>
      )}
    </Fragment>
  )
}

export type ListTemplateProps = {
  type?: string
  status?: TemplateStatus
  site?: string | number
  per_page?: number
  value?: string | number | null
  defaultValue?: string | number | null
  onChange?: (val: string | number | null) => void
}
export function ListTemplate(props: ListTemplateProps) {
  const {
    site,
    type = TEMPLATE_TYPE.EMAIL,
    status = listTempateStatus[type || TEMPLATE_TYPE.EMAIL],
    per_page = TEMPLATE_PER_PAGE,
    value: valueProps,
    defaultValue,
    onChange: onChangeProps
  } = props
  const refContainer = useRef<HTMLDivElement | null>(null)
  const debounce = useRef<NodeJS.Timeout | null>(null)
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState<any[]>([])
  const [params, setParams] = useState({
    site,
    type: type || 'all',
    status,
    keyword: '',
    message_type: listTemplateTabs[type || TEMPLATE_TYPE.EMAIL]?.[0]?.key,
    per_page,
    total: 0,
    page: 1
  })
  const [value, setValue] = useState(defaultValue || valueProps)
  const listTemplateId = nanoid()

  const hasMore = useMemo(() => {
    const totalPage = Math.round(params.total / params.per_page)
    if (totalPage === 0) {
      return false
    }

    return params.page < totalPage
  }, [params.page, params.per_page, params.total])

  const onChange = useCallback(
    (val: string | number | null) => {
      return () => {
        setValue(val)
        onChangeProps?.(val)
      }
    },
    [onChangeProps]
  )

  const getTemplate = useCallback(
    async (params) => {
      const newParams: TemplateBuilderListParams = {
        site: params?.site,
        type: params?.type,
        status:
          params?.status || listTempateStatus[type || TEMPLATE_TYPE.EMAIL],
        keyword: params?.keyword || '',
        message_type: params?.message_type,
        page: params?.page || 1,
        per_page: params?.per_page || TEMPLATE_PER_PAGE
      }
      try {
        const { data: templateData } = await TemplateServices.getList(newParams)

        if (templateData.data) {
          if (params?.page === 1) {
            setDataSource(templateData.data)
          } else {
            setDataSource([...dataSource, ...templateData.data])
          }
          setParams({
            ...params,
            page: templateData.current_page,
            total: templateData.total
          })
        } else {
          setDataSource([])
        }
      } catch (error) {
        console.log('** Error listTemplate : ', error)
      }
    },
    [params, dataSource]
  )

  const onChangeTabs = useCallback(
    async (key: string) => {
      setLoading(true)
      await getTemplate({
        ...params,
        page: 1,
        total: 0,
        message_type: key
      })
      setLoading(false)
      if (refContainer?.current) {
        refContainer?.current?.scrollTo(0, 0)
      }
    },
    [getTemplate, params]
  )

  const next = useCallback(() => {
    if (debounce.current) {
      clearTimeout(debounce.current)
    }

    if (!hasMore) {
      return
    }
    debounce.current = setTimeout(() => {
      getTemplate({ ...params, page: params.page + 1 })
    }, 250)
  }, [getTemplate, hasMore, params])

  const onSearch = useCallback(
    (s: string) => {
      if (debounce.current) {
        clearTimeout(debounce.current)
      }
      debounce.current = setTimeout(async () => {
        setLoading(true)
        await getTemplate({ ...params, keyword: s, page: 1, total: 0 })
        setLoading(false)
        if (refContainer?.current) {
          refContainer?.current?.scrollTo(0, 0)
        }
      }, 250)
    },
    [getTemplate, params]
  )

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await getTemplate({ ...params, type, site, page: 1 })
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  return (
    <ListTemplateContainer
      className={cn('listTemplate--container', {
        noTabs: !listTemplateTabs[type]?.length
      })}>
      {!!listTemplateTabs[type]?.length && (
        <ListTemplateTabs
          items={listTemplateTabs[type]}
          onChange={onChangeTabs}
        />
      )}
      <ListTemplateInputContainer className="listTemplate--input">
        <InputSearch
          loading={loading}
          placeholder="Search"
          onSearch={onSearch}
        />
      </ListTemplateInputContainer>
      <ListTemplateWrapper>
        <Spin spinning={loading}>
          <ListTemplateInner ref={refContainer} id={listTemplateId}>
            <InfiniteScroll
              dataLength={dataSource.length}
              hasMore={hasMore}
              next={next}
              scrollableTarget={listTemplateId}
              loader={
                <ListTemplateItem>
                  <ListTemplateItemCol1>
                    <Skeleton
                      title
                      active
                      avatar={{ size: 20, style: { marginTop: 10 } }}
                      paragraph={false}
                    />
                  </ListTemplateItemCol1>
                  <ListTemplateItemCol2>
                    <Skeleton
                      title
                      active
                      avatar={{ size: 40, shape: 'circle' }}
                      paragraph={false}
                    />
                  </ListTemplateItemCol2>
                </ListTemplateItem>
              }
              endMessage={
                <ListTemplateItem className="extend">
                  <ListTemplateItemCol2>
                    <Divider plain>It is all, nothing more 🤐</Divider>
                  </ListTemplateItemCol2>
                </ListTemplateItem>
              }>
              <ListTemplateWrapperHeader>
                <ListTemplateItemCol1>Name</ListTemplateItemCol1>
                <ListTemplateItemCol2>Last modified</ListTemplateItemCol2>
              </ListTemplateWrapperHeader>
              {dataSource.map((item) => {
                return (
                  <RenderItem
                    key={item.id}
                    item={item}
                    isActive={Boolean(value === item.id)}
                    onChange={onChange(item.id)}
                  />
                )
              })}
            </InfiniteScroll>
          </ListTemplateInner>
        </Spin>
      </ListTemplateWrapper>
    </ListTemplateContainer>
  )
}
