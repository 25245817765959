import Icon from '@ant-design/icons'
import { Empty } from 'antd'
import { theme } from 'constants/theme'
import Highcharts, { Chart } from 'highcharts'
import Accessibility from 'highcharts/modules/accessibility'
import HightchartsHeatmap from 'highcharts/modules/heatmap'
import { NoDataFolder } from 'icons'
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react'
import styled from 'styled-components'
import { formatNumberToCurrency } from 'utils'

const StyledContainer = styled.div`
  .highcharts-xaxis-grid,
  .highcharts-yaxis-grid {
    > path {
      &:nth-last-child(-n + 2) {
        stroke-width: 0;
      }
    }
  }
  .highcharts-axis-line {
    stroke-width: 0;
  }
`

export type HeatmapRef = {
  refresh?: () => void
}
export type HeatmapProps = {
  dataSource?: Record<string, Record<string, number>>
}
const HeatmapWithoutRef = (props: HeatmapProps, ref?: Ref<HeatmapRef>) => {
  const { dataSource } = props
  const refContainer = useRef(null)
  const refHeatmap = useRef<Chart>()
  const { xCategories, yCategories, seriesData } = useHeatmapChart(
    transferHeatmapData(dataSource || {})
  )

  useEffect(() => {
    if (refContainer.current) {
      HightchartsHeatmap(Highcharts)
      Accessibility(Highcharts)
      refHeatmap.current = Highcharts.chart(refContainer.current, {
        chart: { type: 'heatmap' },
        plotOptions: { type: 'heatmap' },
        title: { text: '' },
        credits: { enabled: false },

        legend: {
          margin: 0,
          align: 'right',
          layout: 'vertical',
          verticalAlign: 'top',
          symbolWidth: 16,
          symbolHeight: 347
        },

        colorAxis: {
          y: 0,
          minColor: theme.colors.blue50,
          maxColor: theme.colors.blue500
        },

        xAxis: { categories: xCategories },

        yAxis: { categories: yCategories, title: null, reversed: true },

        tooltip: {
          headerFormat: '',
          pointFormatter() {
            let formatString = `<b>${formatNumberToCurrency(
              (this as any)?.value || 0
            )}</b> at `
            formatString += `<b>${
              (this as any)?.series?.yAxis?.categories[(this as any)?.y]
            }</b> `
            formatString += `<b>${
              (this as any)?.series?.xAxis?.categories[(this as any)?.x]
            }</b>`

            return formatString
          }
        },

        series: [
          {
            name: '',
            borderWidth: 2,
            borderColor: '#fff',
            data: seriesData,
            dataLabels: { enabled: false }
          }
        ]
      } as any)
    }
  }, [seriesData, xCategories, yCategories])

  useImperativeHandle(
    ref,
    () => ({
      refresh() {
        refHeatmap.current?.redraw()
      }
    }),
    []
  )

  if (!Object.keys(dataSource || {})?.length) {
    return (
      <Empty
        image={<Icon component={NoDataFolder} className="text-[80px]" />}
        imageStyle={{ height: 'auto' }}
        description="No data"
        className="min-h-[320px] flex flex-col items-center justify-center"
      />
    )
  }

  return <StyledContainer ref={refContainer} />
}

export const Heatmap = forwardRef(HeatmapWithoutRef)

export const transferHeatmapData = (
  data: any,
  step = 2
): Record<string, Record<string, number>> => {
  const newData: Record<string, Record<string, number>> = {}
  if (!data) {
    return newData
  }

  try {
    Object.keys(data).forEach((key) => {
      const Key = key.slice(0, 1).toUpperCase() + key.slice(1)
      const numberHours = data[key]
      newData[Key] = {}
      if (numberHours && Array.isArray(numberHours)) {
        const arrayStep = new Array(24 / step).fill(undefined)
        arrayStep.forEach((_, index) => {
          const value = numberHours?.[index] || 0
          const hour = (index + 1) * step
          const KeyHour = hour > 12 ? `${hour - 12}pm` : `${hour}am`
          newData[Key][KeyHour] = value
        })
      }
    })
    return newData
  } catch (error) {
    console.log(
      '** ERROR MessagingInsightsAudience.helpers.ts 25 error : ',
      error
    )
  }

  return {}
}

const useHeatmapChart = (
  dataSource: Record<string, Record<string, number>>
) => {
  return useMemo(() => {
    const xCategories: string[] = []
    const yCategories: string[] = []
    const seriesData: [number, number, number][] = []
    Object.keys(dataSource).forEach((xKey, xIndex) => {
      const value = dataSource[xKey as keyof typeof dataSource]
      xCategories.push(xKey)
      Object.keys(value).forEach((yKey, yIndex) => {
        if (xIndex === 0 || !yCategories.includes(yKey)) {
          yCategories.push(yKey)
        }

        seriesData.push(
          [xIndex, yIndex, value[yKey as keyof typeof value]] || 0
        )
      })
    })
    return {
      xCategories,
      yCategories,
      seriesData
    }
  }, [dataSource])
}
