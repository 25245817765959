import Icon from '@ant-design/icons'
import { Collapse, Empty } from 'antd'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import cn from 'classnames'
import { ChevronDown, ChevronRight } from 'icons'
import { OptionType } from 'interfaces'
import {
  AriaAttributes,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled from 'styled-components'
import { getElementOuterHeight } from 'utils'
import { toLowerNonAccentCaseSearch } from 'utils/search'

const StyledCheckboxTitle = styled.h5`
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;

  &.fw600 {
    font-weight: 600;
  }
`

const StyledCheckboxDescription = styled.p`
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.2px;
`

const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 8px;
  .ant-checkbox {
    margin: 3px;
    + span {
      flex: 1;
      min-width: 0;
      margin-top: 4px;
      padding-inline: 8px;
    }
  }
`

const StyledCheckboxGroup = styled(Checkbox.Group)`
  ${StyledCheckbox} {
    + ${StyledCheckbox} {
      margin-left: 0;
    }
  }
`

const StyledContainerMore = styled.div`
  overflow: hidden;
  transition: 0.25s linear all;
  transition-delay: 0.025s;
`

const StyledButton = styled(Button)`
  height: auto;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: ${({ theme }) => theme?.colors?.gray500};
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  &:focus {
    color: ${({ theme }) => theme?.colors?.gray500};
  }

  &.button-more {
    margin-top: 8px;
    color: ${({ theme }) => theme?.colors?.primary};
    font-size: 12px;
    font-weight: 400;
  }
`

const StyledHeaderTitle = styled.h6`
  flex: 1;
  min-width: 0;
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray500};
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
`
const StyledCollapsePanel = styled(Collapse.Panel)`
  &.ant-collapse-item {
    > .ant-collapse-header {
      width: 100%;
      display: flex;
      align-items: center;
      line-height: 1;
      padding: 0;
      padding-top: 8px;
      padding-bottom: 4px;
      > .ant-collapse-expand-icon {
        line-height: 0;
        .anticon {
          margin-right: 8px;
          font-size: 24px;
          line-height: 0;
        }
      }
    }
    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
`

const StyledCollapse = styled(Collapse)``

export type CheckboxGroupComponentProps = {
  number?: number
  search?: string
  title?: string
  options: OptionType[]
  defaultValue?: CheckboxValueType[]
  value?: CheckboxValueType[]
  onChange?: (value: CheckboxValueType[]) => void
} & AriaAttributes
export const CheckboxGroupComponentWithoutRef = (
  props: CheckboxGroupComponentProps,
  ref?: Ref<HTMLDivElement>
) => {
  const {
    number = 3,
    search,
    title,
    options: optionsProps,
    defaultValue,
    value: valueProps,
    onChange,
    ...restProps
  } = props
  const [value, setValue] = useState(defaultValue || valueProps)

  const options = useMemo(() => {
    if (search) {
      return optionsProps.filter(({ label }) => {
        if (typeof label === 'string') {
          return toLowerNonAccentCaseSearch(label, search)
        }
        return false
      })
    }
    return optionsProps
  }, [search, optionsProps])

  const container = useRef<HTMLDivElement>(null)
  const optionRef = useRef<number[]>([])
  const [more, setMore] = useState(false)
  const [moreHeight, setMoreHeight] = useState(0)

  const clickButtonText = useMemo(() => {
    if (!options.length) {
      return 'Select all'
    }
    if (value && value?.length === options.length) {
      return 'Unselect all'
    }
    return 'Select all'
  }, [value])
  const clickButtonMore = useMemo(() => {
    if (more) return 'Show less'
    return 'Show more'
  }, [more])

  const handleClickButton = (e: any) => {
    e.stopPropagation()
    if (!options.length) {
      handleChange([])
      return
    }
    if (value?.length === options.length) {
      handleChange([])
    } else {
      handleChange(options.map(({ value }) => value))
    }
  }

  const handleClickMore = () => {
    const newMore = !more
    const height = optionRef.current
      .slice(0, newMore ? (search ? options.length : undefined) : number)
      .reduce((a, b) => a + b, 0)
    setMore(newMore)
    setMoreHeight(height)
  }

  const handleChange = (val: CheckboxValueType[]) => {
    setValue(val)
    onChange?.(val)
  }

  const installMoreHeight = useCallback(() => {
    if (container.current) {
      const children = container.current.getElementsByClassName(
        'ant-checkbox-wrapper'
      )
      for (let i = 0; i < children.length; i++) {
        if (optionRef.current) {
          const height = getElementOuterHeight(
            children.item(i) as Element,
            true
          )
          optionRef.current[i] = height
        }
      }
      setMoreHeight(
        optionRef.current
          .slice(0, more ? (search ? options.length : undefined) : number)
          .reduce((a, b) => a + b, 0)
      )
    }
  }, [search, options, value, more])

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  useEffect(() => {
    installMoreHeight()
  }, [installMoreHeight])

  return (
    <div ref={ref} {...restProps}>
      <StyledCollapse
        ghost
        onChange={() => {
          setTimeout(installMoreHeight, 5)
        }}
        expandIcon={(pannelProps) => {
          const { isActive } = pannelProps
          return <Icon component={isActive ? ChevronDown : ChevronRight} />
        }}>
        <StyledCollapsePanel
          key=""
          forceRender
          header={<StyledHeaderTitle>{title}</StyledHeaderTitle>}
          extra={
            <StyledButton size="small" onClick={handleClickButton}>
              {clickButtonText}
            </StyledButton>
          }>
          <StyledContainerMore
            ref={container}
            style={{
              height: Boolean(options?.length > number) ? moreHeight : 'auto'
            }}>
            {!options.length ? (
              <Empty />
            ) : (
              <StyledCheckboxGroup value={value} onChange={handleChange}>
                {options.map((option, index) => {
                  return (
                    <StyledCheckbox
                      value={option.value}
                      key={`${option.value}_${index}`}>
                      <StyledCheckboxTitle
                        className={cn({ fw600: !!option.description })}>
                        {option.label}
                      </StyledCheckboxTitle>
                      {!!option.description && (
                        <StyledCheckboxDescription>
                          {option.description}
                        </StyledCheckboxDescription>
                      )}
                    </StyledCheckbox>
                  )
                })}
              </StyledCheckboxGroup>
            )}
          </StyledContainerMore>
          {Boolean(options?.length > number) && (
            <StyledButton
              size="small"
              onClick={handleClickMore}
              className="button-more">
              {clickButtonMore}
            </StyledButton>
          )}
        </StyledCollapsePanel>
      </StyledCollapse>
    </div>
  )
}
export const CheckboxGroupComponent = forwardRef(
  CheckboxGroupComponentWithoutRef
)
