import { SegmentConditionFieldOption } from '../SegmentCondition.helpers'
import {
  StyledSegmentConditionTable,
  StyledSegmentConditionTableCell,
  StyledSegmentConditionTabs
} from './SegmentConditionDictionary.styled'
import Tooltip from 'antd/lib/tooltip'
import { InputSearch } from 'components/atoms/input'
import { ModalEmpty } from 'components/atoms/modal'
import { SEGMENT_TYPE } from 'constants/segment'
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { toLowerNonAccentCaseSearch } from 'utils/search'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 25,
    render: (v: any, record: any) => {
      return (
        <Tooltip title={record.name}>
          <StyledSegmentConditionTableCell>
            {record.name}
          </StyledSegmentConditionTableCell>
        </Tooltip>
      )
    }
  },
  // HHS-2222
  // {
  //   title: 'Key',
  //   dataIndex: 'field',
  //   width: 20,
  //   render: (field: any) => {
  //     return (
  //       <Tooltip title={field}>
  //         <StyledSegmentConditionTableCell>
  //           {field}
  //         </StyledSegmentConditionTableCell>
  //       </Tooltip>
  //     )
  //   }
  // },
  // {
  //   title: 'Data Type',
  //   dataIndex: 'type',
  //   width: 15
  // },
  {
    title: 'Description',
    dataIndex: 'description',
    width: 20,
    render: (description: any) => {
      return (
        <Tooltip title={description}>
          <StyledSegmentConditionTableCell>
            {description}
          </StyledSegmentConditionTableCell>
        </Tooltip>
      )
    }
  }
]

export type SegmentConditionDictionaryProps = {
  activeTab?: string
  index?: number
  onClose?: () => void
  fieldOptions?: SegmentConditionFieldOption[]
  onRowItemClick?: (
    record?: SegmentConditionFieldOption,
    number?: number | undefined
  ) => void
}
export const SegmentConditionDictionary = (
  props: SegmentConditionDictionaryProps
) => {
  const { activeTab, index, onClose, onRowItemClick, fieldOptions } = props
  const debounce = useRef<NodeJS.Timeout>()
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [dataSource, setDataSource] = useState(fieldOptions || [])

  const fetch = useCallback(
    async ({ search = '' }: { search?: string } = {}) => {
      setLoading(true)
      setDataSource(
        fieldOptions?.filter(({ name }) =>
          toLowerNonAccentCaseSearch(name || '', search)
        ) || []
      )
      setTimeout(() => {
        setLoading(false)
      }, 250)
    },
    [fieldOptions]
  )

  const onSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newSearch = e.target.value
    setSearch(newSearch)
    if (debounce.current) clearTimeout(debounce.current)
    debounce.current = setTimeout(() => {
      fetch({ search: newSearch })
    }, 300)
  }

  const onRow = useCallback(
    (record: any) => {
      return {
        onClick() {
          onRowItemClick?.(record, index)
        }
      }
    },
    [onRowItemClick, index]
  )

  const items = useMemo(
    () => [
      {
        key: SEGMENT_TYPE.EVENT,
        label: 'Event',
        disabled: activeTab !== SEGMENT_TYPE.EVENT,
        children: (
          <StyledSegmentConditionTable
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{ y: 300 }}
            onRow={onRow}
          />
        )
      },
      {
        key: SEGMENT_TYPE.ATTRIBUTE,
        label: 'Attributes',
        disabled: activeTab !== SEGMENT_TYPE.ATTRIBUTE,
        children: (
          <StyledSegmentConditionTable
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{ y: 300 }}
            onRow={onRow}
          />
        )
      },
      {
        key: SEGMENT_TYPE.MOBILE,
        label: 'Mobile',
        disabled: activeTab !== SEGMENT_TYPE.MOBILE,
        children: (
          <StyledSegmentConditionTable
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{ y: 300 }}
            onRow={onRow}
          />
        )
      }
    ],
    [activeTab, dataSource, loading, onRow]
  )

  useEffect(() => {
    setDataSource(fieldOptions || [])
  }, [fieldOptions])

  return (
    <ModalEmpty
      title="Data Dictionary"
      open={typeof index !== 'undefined'}
      onCancel={onClose}
      cancelText="Cancel"
      okText="Continue"
      onOk={onClose}
      width={720}
      destroyOnClose>
      <InputSearch value={search} onChange={onSearch} />
      <StyledSegmentConditionTabs activeKey={activeTab} items={items} />
    </ModalEmpty>
  )
}
