import { Progress } from 'antd'
import { FC, useMemo } from 'react'
import styled from 'styled-components'
import { formatNumberToCurrency } from 'utils'

const StyleContainer = styled.div`
  display: flex;
  align-items: baseline;
  border-left: 1px solid ${(props) => props.theme.colors.neutral100};
  padding: 24px;
`

const StyleUserInfor = styled.div`
  margin-left: 20px;
`

const StyleTotalUser = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.6px;
  color: ${(props) => props.theme.colors.gray800};
`

const StylePercentlUser = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.gray600};
`

interface UserCounterProps {
  total: number
  numOfUsers: number
}

export const SegmentUserCounter: FC<UserCounterProps> = ({
  total: totalProps,
  numOfUsers
}) => {
  const percentReachUser = useMemo(() => {
    if (totalProps === 0 || !totalProps) {
      return 0
    }

    const percent = (numOfUsers / totalProps) * 100
    return Number(percent.toFixed(2))
  }, [numOfUsers, totalProps])
  return (
    <StyleContainer>
      <Progress
        type="circle"
        percent={percentReachUser}
        width={100}
        showInfo={false}
        strokeWidth={10}
        strokeColor="#00AECD"
        strokeLinecap="square"
        trailColor="#D9D9D9"
      />
      <StyleUserInfor>
        <StyleTotalUser>{formatNumberToCurrency(totalProps)}</StyleTotalUser>
        <StylePercentlUser>{percentReachUser}% of contacts</StylePercentlUser>
      </StyleUserInfor>
    </StyleContainer>
  )
}
