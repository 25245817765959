import {
  WORKFLOW_TABLE_COLUMNS,
  WORKFLOW_TABLE_COLUMNS_KEYS_SORT,
  WORKFLOW_TABLE_COLUMNS_KEYS_SORT_ITEMS,
  WORKFLOW_TABLE_DROPDOWN_ITEMS,
  WORKFLOW_TABLE_STATUS_OPTIONS,
  WORKFLOW_TABLE_TABS,
  WORKFLOW_TABLE_TABS_KEYS,
  generateWorkflowTableDropdownItems
} from './WorkflowTable.helpers'
import { StyledWorkflowTableTabs } from './WorkflowTable.styled'
import Icon from '@ant-design/icons'
import { Button, Dropdown, MenuProps } from 'antd'
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table'
import classNames from 'classnames'
import { DropdownCheckbox, DropdownSort } from 'components/atoms/dropdown'
import { InputSearch } from 'components/atoms/input'
import { TableScroll } from 'components/atoms/table'
import { isProduction } from 'constants/env'
import { PERMISSIONS } from 'constants/permission'
import { useAuth } from 'context/Auth'
import { checkAuth } from 'hooks/useCheckAuth'
import { CaretDown } from 'icons'
import { Kebab, Plus } from 'icons/V2'
import { useCallback, useEffect, useMemo, useState } from 'react'

export { WORKFLOW_TABLE_STATUS_OPTIONS, WORKFLOW_TABLE_TABS_KEYS }
export type WorkflowTableState = {
  type?: string
  status?: string[]
  search?: string
  sort_by?: string
  sort_type?: string
  created_from?: string
  created_to?: string
  page: number
  per_page: number
  total?: number
}
export const initialWorkflowTableState: WorkflowTableState = {
  page: 1,
  per_page: 10
}
export type WorkflowTableProps = {
  loading?: boolean
  dataSource?: any[]
  initialState?: WorkflowTableState
  state?: WorkflowTableState
  onChangeState?: (state: WorkflowTableState) => void
  onClick?: (type: string) => void
  onClickDropdownItem?: (key: string, record?: any) => void
}
export const WorkflowTable = (props: WorkflowTableProps) => {
  const {
    loading,
    dataSource,
    initialState,
    state: stateProps,
    onChangeState,
    onClick,
    onClickDropdownItem
  } = props
  const [state, setState] = useState(
    initialState || stateProps || initialWorkflowTableState
  )
  const { userAuth } = useAuth()

  const disabledCreate = !checkAuth(
    { permissions: [PERMISSIONS.workflow_create] },
    userAuth?.auth || {}
  )
  const disabledSearch = !checkAuth(
    { permissions: [PERMISSIONS.workflow_search] },
    userAuth?.auth || {}
  )

  const handleClickDropdownItem = useCallback(
    (record?: any) => {
      return ((item) => {
        onClickDropdownItem?.(item.key, record)
      }) as MenuProps['onClick']
    },
    [onClickDropdownItem]
  )

  const columns = useMemo(() => {
    const type = state?.type || WORKFLOW_TABLE_TABS_KEYS.WORKFLOW
    const columns = WORKFLOW_TABLE_COLUMNS[type]
    const dropdownItems = WORKFLOW_TABLE_DROPDOWN_ITEMS[type]
    return columns
      .map((col) => {
        if (!dropdownItems.length && col.dataIndex === 'actions') {
          return
        }
        const newCol = {
          ...col,
          className: `${col.dataIndex} ${col?.className || ''}`
        }
        switch (col.dataIndex) {
          case 'actions': {
            return {
              ...newCol,
              render(_: any, record: any) {
                const items = generateWorkflowTableDropdownItems(
                  dropdownItems,
                  record,
                  userAuth?.auth
                )

                return (
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items,
                      onClick: handleClickDropdownItem(record),
                      rootClassName: 'min-w-[158px] py-0 overflow-hidden'
                    }}>
                    <Button
                      type="link"
                      icon={<Kebab className="text-[20px] leading-[0]" />}
                      className="p-0 w-[20px] h-[20px] flex items-center justify-center text-gray800"
                    />
                  </Dropdown>
                )
              }
            }
          }
          default:
            return newCol
        }
      })
      .filter(Boolean) as ColumnProps<any>[]
  }, [handleClickDropdownItem, state?.type, userAuth?.auth])

  const searchPlaceholder = useMemo(() => {
    if (state?.type === WORKFLOW_TABLE_TABS_KEYS.WORKFLOW) {
      return 'Search workflow name'
    }
    return 'Search folder name'
  }, [state?.type])

  const handleClick = (key: string) => {
    return () => {
      onClick?.(key)
    }
  }

  const handleChangeState = useCallback(
    (key: keyof WorkflowTableState) => (val: string | string[] | any) => {
      const newState = { ...state, [key]: val }
      if (['search', 'type', 'status'].includes(key)) {
        newState.page = initialWorkflowTableState.page
        newState.per_page = initialWorkflowTableState.per_page
      }
      if (['type'].includes(key)) {
        newState.search = undefined
        newState.status = undefined
        newState.sort_by = undefined
        newState.sort_type = undefined
      }
      setState(newState)
      onChangeState?.(newState)
    },
    [onChangeState, state]
  )

  const handleChangeSort = useCallback(
    (key: string) => {
      return (value: string) => {
        const newState = { ...state, sort_by: key, sort_type: value }
        if (state.sort_type === value && state.sort_by === key) {
          newState.sort_by = ''
          newState.sort_type = ''
        }
        newState.page = initialWorkflowTableState.page
        newState.per_page = initialWorkflowTableState.per_page
        setState(newState)
        onChangeState?.(newState)
      }
    },
    [onChangeState, state]
  )

  const handleChangeTable = useCallback(
    (pagination: TablePaginationConfig) => {
      const newState = {
        ...state,
        page: pagination.current || state.page,
        per_page: pagination.pageSize || state.per_page
      }
      setState(newState)
      onChangeState?.(newState)
    },
    [onChangeState, state]
  )

  useEffect(() => {
    setState(stateProps || initialWorkflowTableState)
  }, [stateProps])

  return (
    <TableScroll
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowKey={(row) => row.id}
      onChange={handleChangeTable}
      pagination={{
        position: [],
        current: state.page,
        pageSize: state.per_page,
        total: state.total || 0
      }}
      title={() => (
        <div className="WorkflowTable-header flex justify-between">
          <StyledWorkflowTableTabs
            activeKey={state?.type}
            onChange={handleChangeState('type')}
            items={WORKFLOW_TABLE_TABS}
            className="WorkflowTable-header_left"
          />
          <div className="WorkflowTable-header_right w-1/2 flex gap-4">
            <InputSearch
              allowClear
              value={state?.search}
              onSearch={handleChangeState('search')}
              placeholder={searchPlaceholder}
              disabled={disabledSearch}
            />
            <Button
              ghost
              type="primary"
              onClick={handleClick(WORKFLOW_TABLE_TABS_KEYS.FOLDER)}
              disabled={isProduction}>
              New folder
            </Button>
            <Button
              disabled={disabledCreate}
              type="primary"
              onClick={handleClick(WORKFLOW_TABLE_TABS_KEYS.WORKFLOW)}
              icon={<Icon component={Plus} />}>
              New workflow
            </Button>
          </div>
        </div>
      )}
      components={{
        header: {
          cell(compProps: any) {
            const { children, ...restProps } = compProps
            const key = WORKFLOW_TABLE_COLUMNS_KEYS_SORT.find((key) =>
              restProps.className.includes(key)
            )

            if (restProps.className.includes('status')) {
              return (
                <th {...restProps}>
                  <div className="flex items-center">
                    <span>{children}</span>
                    <DropdownCheckbox
                      title="FILTER STATUS"
                      description="Select status to filter"
                      placement="bottom"
                      options={WORKFLOW_TABLE_STATUS_OPTIONS}
                      value={state.status}
                      onOk={handleChangeState('status')}>
                      <Icon
                        component={CaretDown}
                        className={classNames(
                          'ml-4 w-[24px] h-[24px] rounded-md text-[20px] transition',
                          'flex items-center justify-center',
                          'hover:bg-gray-100 [&.active]:bg-gray-100 [&.ant-dropdown-open]:bg-gray-100',
                          { active: !!state.status?.length }
                        )}
                      />
                    </DropdownCheckbox>
                  </div>
                </th>
              )
            }

            if (typeof key === 'string') {
              const isSorted = key === state.sort_by
              const sortItems = WORKFLOW_TABLE_COLUMNS_KEYS_SORT_ITEMS?.[key]
              return (
                <th {...restProps}>
                  <div className="flex items-center">
                    <span>{children}</span>
                    <DropdownSort
                      sorted={isSorted}
                      items={sortItems}
                      value={isSorted ? state.sort_type : undefined}
                      onChange={handleChangeSort(key)}
                    />
                  </div>
                </th>
              )
            }
            return <th {...restProps}>{children}</th>
          }
        }
      }}
    />
  )
}
