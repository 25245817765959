import {
  StyleButton,
  StyleTemplateOrder,
  StyleTemplateRow
} from '../CampaignFormAutomation.styled'
import Icon from '@ant-design/icons'
import {
  CheckboxOptionType,
  Form,
  FormListFieldData,
  FormListOperation,
  Select,
  notification
} from 'antd'
import {
  FormNamePath,
  getFormNamePath
} from 'components/condition/conditions/form/index.helpers'
import { Link, TrashBin } from 'icons'
import { FC } from 'react'
import { clickAndOpenInNewTab, getSiteToDisplayWithPrefix } from 'utils'

interface TemplateItemFieldsProps {
  formListFieldData: FormListFieldData
  formListOperation: FormListOperation
  indexTemplate: number
  lengthFields: number
  templates: any[]
  templateOptions: CheckboxOptionType[]
  onChangeField: (name: FormNamePath) => (value: any) => void
}

const TemplateItemFields: FC<TemplateItemFieldsProps> = ({
  formListFieldData: { name: itemName },
  formListOperation,
  indexTemplate,
  lengthFields,
  templates,
  templateOptions,
  onChangeField
}) => {
  const form = Form.useFormInstance()

  const fieldTemplate = Form.useWatch(
    getFormNamePath('templates', [itemName, 'template']),
    form
  )

  const handleOnChangeFieldValue = (
    value: any,
    name: FormNamePath,
    key: string
  ) => {
    onChangeField(getFormNamePath(['templates'], name))(value)
  }
  const reviewTemplate = () => {
    if (fieldTemplate) {
      let url = ''
      const result = templates.find(
        (template: any) => template.template_id === fieldTemplate
      )
      if (result) {
        if (result.type === 'ld_page') {
          url =
            getSiteToDisplayWithPrefix(result.page_url) +
            result.slug_name +
            '?preview=true'
        }
        if (result.type === 'app_push') {
          url = `/template-builder/app-push/${result.template_id}`
        }
        if (result.type === 'email') {
          url = `/template-builder/email/${result.template_id}`
        }
        clickAndOpenInNewTab(url)
      }
    } else {
      notification.error({ message: 'Please select the template to review' })
    }
  }
  return (
    <StyleTemplateRow>
      <StyleTemplateOrder>{indexTemplate + 1}.</StyleTemplateOrder>
      <Form.Item
        label=""
        name={[itemName, 'template']}
        className="template"
        rules={[
          {
            required: true,
            message: 'Please choose template'
          }
        ]}>
        <Select
          options={templateOptions}
          showSearch
          optionFilterProp="label"
          placeholder="Template name"
          onChange={(value) =>
            handleOnChangeFieldValue(
              value,
              getFormNamePath([itemName, 'template']),
              'template'
            )
          }
        />
      </Form.Item>
      {lengthFields > 1 && (
        <StyleButton
          icon={<Icon component={TrashBin} />}
          onClick={() => formListOperation.remove(itemName)}
        />
      )}
      <StyleButton onClick={reviewTemplate} icon={<Icon component={Link} />} />
    </StyleTemplateRow>
  )
}

export default TemplateItemFields
