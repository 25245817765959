import { getIconMedia } from '../helpers'
import { Box } from 'components/atoms/box'
import { replaceAll } from 'pages/templateBuilderCreate/templateCopilotDrawer/CopilotDrawerPreview'
import { useMemo } from 'react'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  /* background-color: rgba(17, 27, 33, 0.4); 111b21 */

  &::before {
    background: url('https://static.xx.fbcdn.net/rsrc.php/v3/yb/r/rmr3BrOAAd8.png');
    background-size: 366.5px 666px;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.06;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .preview-after::after {
    background: url('https://static.xx.fbcdn.net/rsrc.php/v3/yT/r/cTpzmA9a2VH.png')
      50% 50% no-repeat;
    background-size: contain;
    content: '';
    height: 31px;
    left: -12px;
    position: absolute;
    top: -6px;
    width: 12px;
  }

  .preview-box {
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
  }
`

export type ViewProps = {
  bodyText: string
  bodyVariable: string[]
  bodyExample: string[]
  headerVariable: string[]
  headerExample: string[]
  headerFormat: string
  headerText: string
  footerText: string
  isMedia: boolean
  headerHandle: string | File | null
  buttons: any[]
}

const View = ({
  bodyText,
  bodyVariable,
  bodyExample,
  headerVariable,
  headerExample,
  headerFormat,
  headerText,
  footerText,
  isMedia,
  headerHandle,
  buttons
}: ViewProps) => {
  const IconSvg = getIconMedia(headerFormat)

  const previewBodyText = useMemo(() => {
    let text = bodyText || ''
    bodyVariable.forEach((variableName: string, idx: number) => {
      text = text.replace(`{{.${variableName}}}`, bodyExample[idx] || '')
    })
    return text
  }, [bodyText, bodyVariable, bodyExample])

  const previewHeaderText = useMemo(() => {
    let text = headerText || ''
    headerVariable.forEach((variableName: string, idx: number) => {
      text = text.replace(`{{.${variableName}}}`, headerExample[idx] || '')
    })
    return text
  }, [headerText, headerVariable, headerExample])

  const previewObjectFile = useMemo(() => {
    if (headerFormat === 'IMAGE' && headerHandle instanceof File) {
      return URL.createObjectURL(headerHandle)
    }
    return ''
  }, [headerFormat, headerHandle])

  const renderPreviewHeader = () => {
    if (
      headerFormat === 'IMAGE' &&
      typeof headerHandle === 'object' &&
      previewObjectFile
    ) {
      return (
        <img
          alt=""
          className="w-full h-full object-cover object-center cursor-pointer"
          onClick={() => window.open(previewObjectFile)}
          src={previewObjectFile}
        />
      )
    }
    if (headerFormat === 'IMAGE' && typeof headerHandle === 'string') {
      return (
        <img
          alt=""
          className="w-full h-full object-cover object-center cursor-pointer"
          onClick={() => window.open(headerHandle)}
          src={headerHandle}
        />
      )
    }
    if (headerFormat === 'VIDEO' && typeof headerHandle === 'string') {
      return (
        <video
          controls
          className="w-full h-full object-cover object-center cursor-pointer"
          src={headerHandle}
        />
      )
    }

    return <IconSvg width={50} height={50} />
  }

  return (
    <StyledBox className="p-4 relative bg-[#e5ddd5]">
      <Box className="max-w-[280px] relative preview-after mx-auto">
        <Box className="preview-box bg-white rounded-[7.5px] rounded-tl-none break-words min-h-[20px]">
          {isMedia && (
            <Box className="px-[3px] pt-[3px]">
              <Box className="bg-[#E3F2FF] flex items-center justify-center aspect-video">
                {renderPreviewHeader()}
              </Box>
              {headerFormat === 'LOCATION' && (
                <Box className="py-[8px] pl-[7px] pr-[9px] bg-black/[0.04]">
                  <p
                    className="m-0 font-bold text-[#32373b] text-[10px]"
                    dangerouslySetInnerHTML={{
                      __html: '{{Location name}}'
                    }}
                  />
                  <p
                    className="m-0 text-[#32373b] text-[9px] pt-[2px]"
                    dangerouslySetInnerHTML={{ __html: '{{Address}}' }}></p>
                </Box>
              )}
            </Box>
          )}
          {headerFormat === 'TEXT' && (
            <h3 className="m-0 text-black/75 font-bold text-[15px] pt-[6px] pr-[7px] pl-[9px]">
              {previewHeaderText}
            </h3>
          )}
          <p
            className="text-[#262626] text-[13.3px] pt-[7px] pr-[7px] pb-[6px] pl-[9px] m-0 min-h-[32px]"
            dangerouslySetInnerHTML={{
              __html: replaceAll(previewBodyText, '\\n', '<br />')
            }}
          />
          {footerText && (
            <p className="text-black/40 text-[13px] leading-[17px] pt-0 pr-[7px] pb-[8px] pl-[9px] m-0">
              {footerText}
            </p>
          )}
          <p className="text-black/40 text-[11px] leading-[15px] text-right m-0 pr-[7px] pb-[3px]">
            12:21 AM
          </p>
          {buttons.length > 0 && (
            <Box className="px-2">
              {buttons.map((buttonItem) => {
                if (buttonItem.type === 'URL') {
                  return (
                    <Box className="font-medium text-center text-[#00a5f4] border-t border-b-0 border-l-0 border-r-0 border-solid border-[#dadde1] py-[11.5px]">
                      <img
                        className="w-4"
                        src="https://static.xx.fbcdn.net/rsrc.php/v3/y0/r/OzOCQC-ukqH.png"
                        alt=""
                      />
                      &nbsp;
                      <span>{buttonItem.text}</span>
                    </Box>
                  )
                }
                return null
              })}
            </Box>
          )}
        </Box>
      </Box>
    </StyledBox>
  )
}

export default View
