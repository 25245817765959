import { Button, InputNumber, Slider } from 'antd'
import styled, { css } from 'styled-components'

const cssBackgroundBorder = css`
  border-color: ${({ theme }) => theme?.colors?.gray100 || '#F2F2F2'};
  background-color: ${({ theme }) => theme?.colors?.gray100 || '#F2F2F2'};
  &:hover {
    background-color: ${({ theme }) => theme?.colors?.gray100 || '#F2F2F2'};
  }
`

export const StyledInput = styled(InputNumber)`
  width: auto;
  max-width: 114px;
  border-color: ${({ theme }) => theme?.colors?.gray400 || '#8C8C8C'};
  color: ${({ theme }) => theme?.colors?.gray400 || '#8C8C8C'};
  ${cssBackgroundBorder}
`

export const StyledButton = styled(Button)`
  &.ant-btn {
    padding-right: 0;
    padding-left: 0;
    margin-right: 12px;
    margin-left: 12px;
    color: ${({ theme }) => theme?.colors?.gray600 || '#595959'};
    ${cssBackgroundBorder}
  }
`

export const StyledSlider = styled(Slider)`
  min-width: 250px;
`

export const StyledSliderContainer = styled.div`
  display: flex;
  min-width: 300px;
  align-items: center;
  &.from {
    .ant-slider-handle-1 {
      border-color: ${({ theme }) => theme?.colors?.blue200 || '#91CAFF'};
    }
  }
  &.to {
    .ant-slider-handle-2 {
      border-color: ${({ theme }) => theme?.colors?.blue200 || '#91CAFF'};
    }
  }
`
