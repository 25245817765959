import { Button, Drawer } from 'antd'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

export const StyledSegmentPredictionButtonChildren = styled.div`
  &.disabled {
    cursor: not-allowed;
  }
`

export const StyledSegmentPredictionButton = styled(Button)`
  .anticon {
    svg {
      color: inherit;
    }
  }
`

export const StyleSegmentPredictionDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    .ant-drawer-body {
      padding: 0;
      overflow: hidden;
      .segmentPrediction {
        height: 100%;
        .segmentPredictionHead {
          padding: 14px 24px 0 24px;
        }
        .segmentPredictionBody {
          padding: 0 24px 14px 24px;
          ${cssScrollBar}
        }
      }
    }
  }
`
