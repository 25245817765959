export const TEMPLATE_TYPE = {
  EMAIL: 'email',
  APP_PUSH: 'app_push',
  WEB_PUSH: 'web_push',
  LP_PAGE: 'ld_page',
  WHATSAPP: 'whatsapp',
  VNPT: 'vnpt',
  TWILIO: 'twilio',
  ZALO: 'zalo'
}

export const TEMPLATE_STATUS = {
  // LandingPage, WebPush, AppPush
  RELEASE: 'release',
  DRAFT: 'draft',
  // WhatsApp
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED'
}
