import {
  StyleButtonWrapper,
  StyleHeadContainer,
  StyleHeadWrapper,
  StyledButton,
  Title
} from './CampaignFormAutomationHeader.styled'
import { menuRoutes } from 'components/molecules'
import { ChevronLeft } from 'icons'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

export interface HeadProps {
  title?: string
  loading: boolean
  onSave?: () => void
}

export const AutoCampaignHeader: FC<HeadProps> = ({
  title,
  loading,
  onSave
}) => {
  const navigate = useNavigate()
  const onBack = () => {
    navigate(menuRoutes.campaign)
  }
  const handleOnSave = () => {
    onSave && onSave()
  }
  return (
    <StyleHeadContainer>
      <StyleHeadWrapper>
        <ChevronLeft onClick={onBack} />
        <Title>{title}</Title>
      </StyleHeadWrapper>
      <StyleButtonWrapper>
        <StyledButton type="default" size="small" onClick={onBack}>
          Cancel
        </StyledButton>
        &nbsp;&nbsp;
        <StyledButton
          onClick={handleOnSave}
          loading={loading}
          size="small"
          type="primary">
          Save
        </StyledButton>
      </StyleButtonWrapper>
    </StyleHeadContainer>
  )
}
