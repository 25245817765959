import Icon from '@ant-design/icons'
import { Avatar as AvatarBase, Dropdown, Menu as MenuBase } from 'antd'
import { SourcesKeys, sources } from 'constants/segment'
import { FC } from 'react'
import styled from 'styled-components'

const StyledTitle = styled.h2`
  padding: 9px 16px;
  margin-bottom: 0px;
  color: ${({ theme }) => theme?.colors?.gray500 || ''};
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
`

const StyledOverlayContainer = styled.div`
  min-width: 180px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
`

const Menu = styled(MenuBase)`
  &.ant-dropdown-menu {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    .ant-dropdown-menu-item {
      padding: 9px 16px;
      color: ${({ theme }) => theme?.colors?.gray800 || ''};
      &:last-child {
        border-radius: 0 0 6px 6px;
      }
    }
  }
`

const Avatar = styled(AvatarBase)<{ zindex?: number }>`
  position: relative;
  z-index: ${({ zindex }) => zindex};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme?.colors?.gray300 || ''};
  background-color: #fff;
  color: ${({ theme }) => theme?.colors?.gray600 || ''};
  &.ant--avatar-number {
    font-size: 14px !important;
    font-weight: 500;
    line-height: 1;
  }
`

const StyledAvatarGroup = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  ${Avatar} {
    + ${Avatar} {
      margin-left: -8px;
    }
  }
`

const defaultAvatarGroupSourceCount = 3

export type AvatarGroupSourceProps = {
  sources: SourcesKeys[]
  maxCount?: number
}

export const AvatarGroupSource: FC<AvatarGroupSourceProps> = ({
  sources: sourcesProps,
  maxCount: maxCountProps
}) => {
  const maxCount = maxCountProps || defaultAvatarGroupSourceCount
  if (!sourcesProps || !sourcesProps.length) {
    return null
  }

  const sourceShow = sourcesProps.slice(0, maxCount)
  const sourceHide = sourcesProps.slice(maxCount).map((source) => {
    const Icon = sources[source].icon
    return {
      key: source,
      icon: Icon ? <Icon /> : null,
      label: sources[source].label,
      type: undefined
    }
  })

  return (
    <StyledAvatarGroup>
      {sourceShow.map((source_item, index) => {
        return (
          <Avatar
            key={source_item}
            size={40}
            icon={<Icon component={sources[source_item].icon} />}
            zindex={-index}
          />
        )
      })}
      {sourceHide.length && (
        <Dropdown
          overlay={
            <StyledOverlayContainer>
              <StyledTitle>SOURCES</StyledTitle>
              <Menu items={sourceHide} />
            </StyledOverlayContainer>
          }>
          <Avatar
            size={40}
            shape="circle"
            className="ant--avatar-number"
            zindex={-sourceShow.length}>
            +{sourceHide.length}
          </Avatar>
        </Dropdown>
      )}
    </StyledAvatarGroup>
  )
}
