import { TemplateStatus, TemplateType } from '../landingPage/helpers'

export type WebPushSettingsCommon = {
  name: string
  message_type: string
  title: string
  description: string
  message_icon: string
  image: string
  message_link: string
  custom_link_url: string
  site: string | number
  site_name: string
  country_code: string
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_content?: string
  utm_term?: string
}

export type WebPushSettingsForm = WebPushSettingsCommon

export type WebPushSettingsParams = WebPushSettingsForm & {
  type: TemplateType
  status: TemplateStatus
}

export type WebPushSettingsAPI = WebPushSettingsParams & {
  templateId: number
  settingId: number
  created_at: string
  updated_at: string
}

export type TemplateData = WebPushSettingsAPI | undefined

export const webPushKeysChange = [
  'title',
  'description',
  'image',
  'custom_link_url',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term'
]

export const webPushUTMKeys = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term'
]

export const defaultIconMessage =
  'https://cdn-hermes.hellohealthgroup.com/Default%20icon.jpg'

export function trasnformResponseToSettingsAPI(resp: any) {
  const data: TemplateData = {
    templateId: resp.template_id,
    settingId: resp.id,
    created_at: resp.created_at,
    updated_at: resp.updated_at,
    type: resp.template.type,
    status: resp.template.status,
    name: resp.template.name,
    message_type: resp.message_type,
    title: resp.title,
    description: resp.description,
    message_icon: resp.message_icon,
    image: resp.image,
    message_link: resp.message_link,
    custom_link_url: resp.custom_link_url,
    site: resp.template.site ? Number(resp.template.site) : '',
    country_code: resp.template.country_code,
    site_name: resp.template.site_name
  }

  return data
}

export function transformSettingsApiToParams(data: WebPushSettingsAPI) {
  const returnValue: WebPushSettingsParams = {
    type: data.type,
    status: data.status,
    name: data.name,
    message_type: data.message_type,
    title: data.title,
    description: data.description,
    message_icon: data.message_icon,
    image: data.image,
    message_link: data.message_link,
    custom_link_url: data.custom_link_url,
    utm_source: data.utm_source,
    utm_medium: data.utm_medium,
    utm_campaign: data.utm_campaign,
    utm_content: data.utm_content,
    utm_term: data.utm_term,
    site: data.site.toString(),
    country_code: data.country_code,
    site_name: data.site_name
  }

  return returnValue
}

export function transformWebPushApiToSettingsForm(resp: any) {
  const linkUrlSearchParams = (() => {
    try {
      return new URL(resp.custom_link_url).searchParams
    } catch (err) {
      return { get: (key: string) => null }
    }
  })()

  const data: WebPushSettingsForm = {
    name: resp.template.name,
    message_type: resp.message_type,
    title: resp.title,
    description: resp.description,
    message_icon: resp.message_icon,
    image: resp.image,
    message_link: resp.message_link,
    custom_link_url: resp.custom_link_url,
    utm_source: linkUrlSearchParams.get('utm_source') || resp.utm_source,
    utm_medium: linkUrlSearchParams.get('utm_medium') || resp.utm_medium,
    utm_campaign: linkUrlSearchParams.get('utm_campaign') || resp.utm_campaign,
    utm_content: linkUrlSearchParams.get('utm_content') || resp.utm_content,
    utm_term: linkUrlSearchParams.get('utm_term') || resp.utm_term,
    site: resp.template.site ? Number(resp.template.site) : '',
    country_code: resp.template.country_code,
    site_name: resp.template.site_name
  }

  return data
}
