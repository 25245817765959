import { CampaignMessagingInsightFilterValue } from '../CampaignMessagingInsightFilter'
import { CampaignMessagingInsightTabAudienceHeatmapSite } from './CampaignMessagingInsightTabAudienceHeatmap'
import { optionsTimezone } from 'components/atoms/select'
import { OptionTypeSite } from 'interfaces'
import { CampaignServices } from 'services/campaign'

export const generateHeatmapList: (
  sites: number[],
  sitesApp: OptionTypeSite[]
) => CampaignMessagingInsightTabAudienceHeatmapSite[] = (sites, sitesApp) => {
  return sites
    .map((site_id) => {
      const site = sitesApp.find(({ value }) => value === site_id)
      if (site) {
        const timezones = optionsTimezone.filter(({ siteKeys }) =>
          siteKeys.includes(site.name)
        )
        if (timezones.length) {
          return {
            ...site,
            timezones
          }
        }
        return false
      }
      return false
    })
    .filter(Boolean) as CampaignMessagingInsightTabAudienceHeatmapSite[]
}

export const transferAudienceInsightByFilter = (
  data: any,
  step = 2
): Record<string, Record<string, number>> => {
  const newData: Record<string, Record<string, number>> = {}
  if (!data) {
    return newData
  }

  try {
    Object.keys(data).forEach((key) => {
      const Key = key.slice(0, 1).toUpperCase() + key.slice(1)
      const numberHours = data[key]
      newData[Key] = {}
      if (numberHours && Array.isArray(numberHours)) {
        const arrayStep = new Array(24 / step).fill(undefined)
        arrayStep.forEach((_, index) => {
          const value = numberHours?.[index] || 0
          const hour = (index + 1) * step
          const KeyHour = hour > 12 ? `${hour - 12}pm` : `${hour}am`
          newData[Key][KeyHour] = value
        })
      }
    })
    return newData
  } catch (error) {
    console.log(
      '** ERROR MessagingInsightsAudience.helpers.ts 25 error : ',
      error
    )
  }

  return {}
}

export const getCampaignMessagingInsightAudience = async (
  params: CampaignMessagingInsightFilterValue & {
    audience_type_chart: 'Click' | 'Open'
    time_zone: string
  }
) => {
  const { date_recorded, campaigns, audience_type_chart, time_zone } = params
  if (
    !date_recorded ||
    !date_recorded?.[0].isValid() ||
    !date_recorded?.[1].isValid() ||
    !campaigns?.length ||
    !time_zone ||
    !audience_type_chart
  ) {
    return { data: null }
  }
  try {
    const response: any = await CampaignServices.insightByFilter({
      from: date_recorded[0].valueOf(),
      to: date_recorded[1].valueOf(),
      list_campaign: campaigns.map(String),
      get_insights_key: ['audience'],
      audience_type_chart,
      time_zone
    })

    if (response._message || !response.data?.audience) {
      throw new Error(JSON.stringify(response._message))
    }
    return { data: transferAudienceInsightByFilter(response.data.audience) }
  } catch (error) {
    console.log(
      '** ERROR MessagingInsightsAudience.helpers.ts 28 error : ',
      error
    )
    return { data: null }
  }
}
