import Icon from '@ant-design/icons'
import {
  Button,
  ButtonProps,
  Checkbox,
  Divider,
  Drawer,
  DrawerProps,
  Form,
  FormInstance,
  FormProps,
  Row
} from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { Close } from 'icons'
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

const StyledDrawerFooter = styled(Row)``

const StyledDivider = styled(Divider)`
  margin: 0;
  border-color: ${({ theme }) => theme?.colors?.neutral100};
`

const StyledCheckboxGroup = styled(Checkbox.Group)`
  margin: -12px;
  .ant-checkbox-wrapper {
    width: 100%;
    padding: 12px;
    margin: 0 !important;
  }

  &.half {
    .ant-checkbox-wrapper {
      width: 50%;
    }
  }
`

const StyledDrawer = styled(Drawer)`
  .ant-drawer-mask {
    display: none;
  }

  .ant-drawer-header {
    display: block;
    padding: 16px 16px 0;
    border-bottom: 0;

    .ant-drawer-header-title {
      display: flex;
      flex-direction: row-reverse;

      .ant-drawer-title {
        font-size: 16px;
        line-height: 1.5;
      }
      .ant-drawer-close {
        margin-right: 0;
        margin-left: 8px;
        color: ${({ theme }) => theme?.colors?.gray600};
        font-size: 24px;
        line-height: 0;
        padding: 0;
      }
    }
    .ant-drawer-extra {
      margin-top: 8px;
    }
  }

  .ant-drawer-body {
    padding: 16px;
    ${cssScrollBar}

    .ant-form-item {
      margin-bottom: 24px;
      .ant-form-item-label {
        padding-bottom: 12px;
      }
    }
  }

  .ant-drawer-footer {
    padding: 16px 16px 24px;
    border-top: none;
  }
`

export type InsightOverviewFilter = {
  channel_ids?: CheckboxValueType[]
  status?: CheckboxValueType[]
}
export type InsightOverviewFilterProps = Pick<
  DrawerProps,
  'open' | 'onClose' | 'getContainer'
> &
  Pick<FormProps, 'initialValues' | 'onFinish' | 'onChange'> & {
    submitProps?: Omit<ButtonProps, 'onClick'>
    onReset?: () => void
  }
const InsightOverviewFilterWithoutRef: ForwardRefRenderFunction<
  FormInstance<InsightOverviewFilter>,
  InsightOverviewFilterProps
> = (props, ref) => {
  const {
    open: openProps,
    onClose,
    getContainer,
    initialValues,
    onFinish,
    onReset,
    onChange,
    submitProps
  } = props
  const [form] = Form.useForm()
  const [open, setOpen] = useState(openProps)

  const handleFinish = (value: any) => {
    onFinish?.(value)
  }

  const handleReset = () => {
    form.resetFields()
    onReset?.()
  }

  useEffect(() => {
    setOpen(openProps)
  }, [openProps])

  useImperativeHandle(ref, () => form, [form])
  return (
    <StyledDrawer
      open={open}
      maskClosable={false}
      onClose={onClose}
      closeIcon={<Icon component={Close} />}
      getContainer={getContainer}
      extra={<StyledDivider />}
      title="Filter"
      footer={
        <StyledDrawerFooter justify="space-between">
          <Button onClick={handleReset}>Reset</Button>
          <Button type="primary" onClick={form.submit} {...submitProps}>
            Result
          </Button>
        </StyledDrawerFooter>
      }>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleFinish}
        onChange={onChange}
        layout="vertical">
        <Form.Item label="Channels" name="channel_ids">
          <StyledCheckboxGroup
            className="half"
            options={[
              { label: 'Email', value: 'EMAIL' },
              // { label: 'Landing Page', value: 'LANDING_PAGE' },
              { label: 'App Push', value: 'APP_PUSH' },
              { label: 'Web Push', value: 'WEB_PUSH' },
              { label: 'WhatsApp', value: 'WHATSAPP' }
            ]}
          />
        </Form.Item>
        <Form.Item label="Status" name="status">
          <StyledCheckboxGroup
            options={[
              { label: 'On going', value: 'on_going' },
              { label: 'Release', value: 'release' },
              { label: 'Draft', value: 'draft' }
            ]}
          />
        </Form.Item>
      </Form>
    </StyledDrawer>
  )
}

export const InsightOverviewFilter = forwardRef(InsightOverviewFilterWithoutRef)
