import { CampaignMessagingInsightFilterValue } from '../CampaignMessagingInsightFilter'
import { TabsProps } from 'antd'
import { theme } from 'constants/theme'
import { InsightByFilterParams } from 'interfaces/campaign'
import { CampaignServices } from 'services/campaign'

export const TIME_SERIES_CHART_KEY = {
  combine_rate: 'combine_rate',
  click_rate: 'click_rate',
  open_rate: 'open_rate',
  delivery_rate: 'delivery_rate',
  click_through_rate: 'click_through_rate',
  impression_rate: 'impression_rate',

  click: 'click',
  click_through: 'click_through',
  delivery: 'delivery',
  open: 'open'
}

export const TIME_SERIES_CHART_LABEL = {
  [TIME_SERIES_CHART_KEY.combine_rate]: 'Total opens',
  [TIME_SERIES_CHART_KEY.click_rate]: 'Click rate',
  [TIME_SERIES_CHART_KEY.open_rate]: 'Open rate',
  [TIME_SERIES_CHART_KEY.delivery_rate]: 'Delivery rate',
  [TIME_SERIES_CHART_KEY.click_through_rate]: 'Click through rate',
  [TIME_SERIES_CHART_KEY.impression_rate]: 'Impression rate',
  [TIME_SERIES_CHART_KEY.click]: 'Click rate',
  [TIME_SERIES_CHART_KEY.open]: 'Open rate',
  [TIME_SERIES_CHART_KEY.delivery]: 'Delivery rate',
  [TIME_SERIES_CHART_KEY.click_through]: 'Click through rate'
}
// {
//     value: 'delivery_rate',
//     label: 'Delivery Rate',
//     color: theme.colors.primary,
//     unit: '%'
//   },
//   {
//     value: 'click_rate',
//     label: 'Click Rate',
//     color: theme.colors.yellow800,
//     unit: '%'
//   },
//   {
//     value: 'impression_rate',
//     label: 'Impression Rate',
//     color: theme.colors.green800,
//     unit: '%'
//   },
//   {
//     value: 'open_rate',
//     label: 'Open Rate',
//     color: theme.colors.red800,
//     unit: '%'
//   },
//   {
//     value: 'click_through_rate',
//     label: 'CTR',
//     color: theme.colors.violet600,
//     unit: '%'
//   },
//   {
//     value: 'ctor',
//     label: 'CTOR',
//     color: theme.colors.pink600,
//     unit: '%'
//   }

export const TIME_SERIES_CHART_COLOR = {
  [TIME_SERIES_CHART_KEY.delivery_rate]: theme.colors.primary,
  [TIME_SERIES_CHART_KEY.click_rate]: theme.colors.yellow800,
  [TIME_SERIES_CHART_KEY.impression_rate]: theme.colors.green800,
  [TIME_SERIES_CHART_KEY.open_rate]: theme.colors.red800,
  [TIME_SERIES_CHART_KEY.click_through_rate]: theme.colors.violet600,
  [TIME_SERIES_CHART_KEY.delivery]: theme.colors.primary,
  [TIME_SERIES_CHART_KEY.click]: theme.colors.yellow800,
  [TIME_SERIES_CHART_KEY.open]: theme.colors.red800,
  [TIME_SERIES_CHART_KEY.click_through]: theme.colors.violet600
}

export const TIME_SERIES_CHART_TABS: TabsProps['items'] = [
  {
    key: TIME_SERIES_CHART_KEY.combine_rate,
    label: 'Compound'
  },
  {
    key: TIME_SERIES_CHART_KEY.delivery_rate,
    label: 'Delivery rate'
  },
  {
    key: TIME_SERIES_CHART_KEY.click_rate,
    label: 'Click rate'
  },
  {
    key: TIME_SERIES_CHART_KEY.open_rate,
    label: 'Open rate'
  }
]

export type CampaignMessagingInsightTabTimeSeriesState = {
  chart_type?: string
}
export const getCampaignMessagingInsightTimeSeries = async (
  input: CampaignMessagingInsightFilterValue &
    CampaignMessagingInsightTabTimeSeriesState
): Promise<{
  data: Highcharts.Options
  errors: { name?: string; errors: string[] }[]
}> => {
  if (
    !input.date_recorded?.[0].isValid() ||
    !input.date_recorded?.[1].isValid()
  ) {
    return {
      data: {},
      errors: [{ errors: ['Please select date recorded for insights'] }]
    }
  }

  if (!input?.chart_type) {
    input.chart_type = TIME_SERIES_CHART_KEY.combine_rate
  }

  if (!input.campaigns || !input.campaigns?.length) {
    return {
      data: {},
      errors: [{ errors: ['Please select campaigns for insights'] }]
    }
  }
  try {
    const params: InsightByFilterParams = {
      get_insights_key: ['time_series'],
      time_series_type_chart: [input.chart_type],
      from: input.date_recorded?.[0].valueOf(),
      to: input.date_recorded?.[1].valueOf(),
      list_campaign: input.campaigns.map(String)
    }
    const res: any = await CampaignServices.insightByFilter(params)
    if (!res.data || res._message) {
      throw new Error(JSON.stringify(res))
    }
    return {
      data: formatTimeSeriesInsightHighchartsOptions(
        res.data,
        input.chart_type
      ),
      errors: []
    }
  } catch (error) {
    return { data: {}, errors: [{ errors: ['Something went wrong'] }] }
  }
}

export function formatTimeSeriesInsightHighchartsOptions(
  data: any,
  type_chart: string
) {
  const options: Highcharts.Options = {}
  const optionsCategories: string[] = []
  const recordSeries: Record<string, number[]> = {}
  const recordColors: Record<string, string | undefined> = {}
  const timeSeries = data?.time_series || {}

  try {
    if (timeSeries?.[type_chart]) {
      Object.keys(timeSeries[type_chart]).forEach((time) => {
        const timeSeriesVal = timeSeries[type_chart]?.[time]

        const name = TIME_SERIES_CHART_LABEL[type_chart]
        const item = recordSeries[name]

        if (typeof timeSeriesVal === 'number') {
          optionsCategories.push(time)

          recordColors[name] = TIME_SERIES_CHART_COLOR[type_chart]
          if (item) {
            item.push(timeSeriesVal)
          } else {
            recordSeries[name] = [timeSeriesVal]
          }
        }

        if (timeSeriesVal instanceof Object) {
          // Rules PM said that remove delivery
          const { delivery, ...restTimeSeriesVal } = timeSeriesVal
          if (Object.values(restTimeSeriesVal).some((val) => val !== 0)) {
            optionsCategories.push(time)
            Object.keys(restTimeSeriesVal).forEach((timeSeriesItemKey) => {
              const name = TIME_SERIES_CHART_LABEL[timeSeriesItemKey]
              const item = recordSeries[name]
              const timeSeriesItemValue = timeSeriesVal?.[timeSeriesItemKey]

              recordColors[name] = TIME_SERIES_CHART_COLOR[timeSeriesItemKey]
              if (item) {
                item.push(timeSeriesItemValue)
              } else {
                recordSeries[name] = [timeSeriesItemValue]
              }
            })
          }
        }
      })
    }
  } catch (error) {
    console.log(
      '** RYAN CampaignMessagingInsightTabTimeSeries.helpers.ts 128 error : ',
      error
    )
  }

  return {
    ...options,
    xAxis: {
      categories: optionsCategories,
      labels: { enabled: true },
      gridLineWidth: 0.5,
      gridLineDashStyle: 'Dot'
    },
    yAxis: {
      labels: { enabled: true },
      gridLineWidth: 0.5,
      gridLineDashStyle: 'Dash'
    },
    scrollbar: { enabled: true },
    series: Object.keys(recordSeries).map((key, index) => ({
      type: 'column',
      name: key,
      data: recordSeries[key],
      tooltip: {
        valueSuffix: '%'
      },
      color: recordColors[key],
      pointWidth: 30
    }))
  } as Highcharts.Options
}
