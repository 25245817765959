import { Steps } from 'antd'
import styled from 'styled-components'

export const StyledWrap = styled.div`
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

export const StyledNav = styled.div`
  a {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    gap: 8px;
    color: ${({ theme }) => theme.colors.secondaryDark};
  }
`

export const StyledSteps = styled(Steps)`
  display: inline-flex;
  width: auto;

  .ant-steps-item {
    padding-right: 40px;
  }

  &.ant-steps-small .ant-steps-item-title {
    font-weight: 700;
  }
`

export const StyledActions = styled.div`
  gap: 8px;
  display: flex;
`
