import { ZaloTemplatePicked } from 'pages/templateBuilderCreate/zalo/types'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { TemplateServices } from 'services/template'

type ReturnValue = {
  listZaloTemplate: ZaloTemplatePicked[]
  setListZaloTemplate: Dispatch<SetStateAction<ZaloTemplatePicked[]>>
  refetchListZaloTemplate: () => void
}

export const useZaloTemplates = (): ReturnValue => {
  const [refetchId, setRefetchId] = useState(0)
  const [listZaloTemplate, setListZaloTemplate] = useState<
    ZaloTemplatePicked[]
  >([])

  useEffect(() => {
    TemplateServices.getListZaloTemplates().then((res) => {
      if (res.data?.data?.length) {
        setListZaloTemplate(res.data.data)
      }
    })
  }, [refetchId])

  const refetchListZaloTemplate = () => {
    setRefetchId((prev) => prev + 1)
  }

  return {
    listZaloTemplate,
    setListZaloTemplate,
    refetchListZaloTemplate
  }
}
