import { SITE_KEYS } from 'constants/site'

export const optionsTimezone: {
  label: string
  value: string
  siteKeys: string[]
}[] = [
  {
    value: 'Asia/Saigon',
    label: 'Ho Chi Minh/ Vietnam (GTM+7)',
    siteKeys: [
      SITE_KEYS.HELLO_BAC_SI,
      SITE_KEYS.MARRY_BABY,
      SITE_KEYS.HELLO_HEALTH_GROUP
    ]
  },
  {
    value: 'Asia/Bangkok',
    label: 'Bangkok/ Thailand (GTM+7)',
    siteKeys: [SITE_KEYS.HELLO_KHUNMOR]
  },
  {
    value: 'Asia/Phnom_Penh',
    label: 'Phnom Penh/ Cambodia (GTM+7)',
    siteKeys: [SITE_KEYS.HELLO_KRUPET]
  },
  {
    value: 'Asia/Yangon',
    label: 'Naypyidaw/ Myanmar (GTM+6:30)',
    siteKeys: [SITE_KEYS.HELLO_SAYARWON]
  },
  {
    value: 'Asia/Kuala_Lumpur',
    label: 'Kuala Lumpur/ Malaysia (GMT+8)',
    siteKeys: [SITE_KEYS.HELLO_DOKTOR]
  },
  {
    value: 'Asia/Jakarta',
    label: 'Jakarta/ Indonesia (GTM+7)',
    siteKeys: [SITE_KEYS.HELLO_SEHAT]
  },
  {
    value: 'Asia/Makassar',
    label: 'Bali/ Indonesia (GMT+8)',
    siteKeys: [SITE_KEYS.HELLO_SEHAT]
  },
  {
    value: 'Asia/Jayapura',
    label: 'Jayapura/ Indonesia (GMT+9)',
    siteKeys: [SITE_KEYS.HELLO_SEHAT]
  },
  {
    value: 'Asia/Manila',
    label: 'Manila/ Philippines (GMT+8)',
    siteKeys: [SITE_KEYS.HELLO_DOCTOR, SITE_KEYS.HELLO_FIL]
  },
  {
    value: 'Asia/Taipei',
    label: 'Taipei/ Taiwan (GMT+8)',
    siteKeys: [SITE_KEYS.HELLO_YISHI]
  },
  {
    value: 'Asia/Calcutta',
    label: 'New Delhi/ India (GTM+5:30)',
    siteKeys: [SITE_KEYS.HELLO_SWATHYA]
  }
]
