import styled from 'styled-components'

export const StyledTableHeaderCell = styled.div`
  &.name {
    display: flex;
    align-items: center;
    .btnSort {
      margin-left: 8px;
    }
  }
`

export const StyledTableCell = styled.div`
  &.cell--name {
    display: flex;
    .wrapper-segment-name {
      min-width: 0;
      flex: 1;

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        color: ${({ theme }) => theme?.colors?.gray700};
      }
      .description {
        font-size: 12px;
        color: ${({ theme }) => theme.colors?.gray500};
      }
    }
  }
`

export const StyledSegment = styled.div`
  display: flex;
  height: 100%;
`
