import styled from 'styled-components'

export const StyledAnalyticWidgetMuuriItem = styled.div`
  padding: 12px;
  width: calc(50% - 1px);
  height: 260px;
  box-sizing: border-box;
  overflow: hidden;

  &.fullHeight {
    height: 520px;
  }
  &.fullWidth {
    width: calc(100% - 1px);
  }

  .analyticWidgetMuuriItem--container {
    padding: 16px;
    position: absolute;
    inset: 12px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  }

  .analyticWidgetMuuriItem--title {
    color: ${({ theme }) => theme?.colors?.gray800};
    font-size: 18px;
    line-height: 1.5;
  }

  .analyticWidgetMuuriItem--handle {
    cursor: move;
  }

  .analyticWidgetMuuriItem--button {
    width: 24px;
    height: 24px;
    padding: 0;
    color: ${({ theme }) => theme?.colors?.gray600};
    .anticon {
      font-size: 24px;
    }
  }

  &.muuri-item-placeholder {
    z-index: 2;
    margin: 0;
    opacity: 0.5;

    .bg-placeholder {
      opacity: 1;
      background-color: ${({ theme }) => theme?.colors?.neutral50};
      z-index: 3;
      border-radius: 6px;
      border: 1px dashed ${({ theme }) => theme?.colors?.gray600};
    }
  }

  &.muuri-item-dragging,
  &.muuri-item-releasing {
    z-index: 100;
  }
  &.muuri-item-dragging {
    cursor: move;
  }
`
