import styled from 'styled-components'

export const StyledSegmentFormCol = styled.div`
  &.form_container {
    flex: 1;
    min-width: 0;
    padding-right: 32px;
  }
  &.chart_container {
    padding: 24px;
    border-left: 1px solid ${({ theme }) => theme?.colors?.neutral100};
    > div {
      padding: 0;
      border: none;
    }
  }
`

export const StyledSegmentFormRow = styled.div`
  display: flex;
`

export const StyledSegmentFormBox = styled.div`
  padding: 24px;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  border-radius: 6px;
  height: 100%;

  .ant-form-item {
    .ant-form-item-label {
      > label {
        color: ${({ theme }) => theme?.colors?.gray600};
      }
    }
  }

  &.analytics_container {
    padding: 24px;
    background-color: #fff;
    border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
    border-radius: 8px;
    > div:not(.ant-alert) {
      gap: 24px;
    }
    .analytic-card {
      background-color: #fff;
    }
  }
`

export const StyledSegmentForm = styled.div`
  padding: 24px;
  position: relative;
`
