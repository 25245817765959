import { SegmentConditionValueARRAY } from './SegmentConditionValueARRAY'
import { SegmentConditionValueBOOL } from './SegmentConditionValueBOOL'
import { SegmentConditionValueDATE } from './SegmentConditionValueDATE'
import { SegmentConditionValueNUMBER } from './SegmentConditionValueNUMBER'
import { SegmentConditionValueTEXT } from './SegmentConditionValueTEXT'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { SEGMENT_CONDITION_TYPE } from 'constants/segment'
import { OptionType } from 'interfaces'
import { FC, Ref, forwardRef, useMemo } from 'react'

export const SegmentConditionValueComponents: Record<
  string,
  FC<SegmentConditionValueProps>
> = {
  [SEGMENT_CONDITION_TYPE.text]: SegmentConditionValueTEXT,
  [SEGMENT_CONDITION_TYPE.number]: SegmentConditionValueNUMBER,
  [SEGMENT_CONDITION_TYPE.bool]: SegmentConditionValueBOOL,
  [SEGMENT_CONDITION_TYPE.array]: SegmentConditionValueARRAY,
  [SEGMENT_CONDITION_TYPE.date]: SegmentConditionValueDATE
}

export type SegmentConditionValueRef = HTMLDivElement
export type SegmentConditionValueProps<T = any> = {
  size?: SizeType
  site?: string[]
  type?: string
  operator?: string | number
  options?: OptionType[]
  defaultValue?: T
  value?: T
  onChange?: (value: T) => void
  disabled?: boolean
}

const SegmentConditionValueWithoutRef = (
  props: SegmentConditionValueProps,
  ref?: Ref<SegmentConditionValueRef>
) => {
  const { type, operator, site, ...restProps } = props

  const SegmentConditionValueComp = useMemo(() => {
    if (type && type in SegmentConditionValueComponents) {
      return SegmentConditionValueComponents?.[
        type as keyof typeof SegmentConditionValueComponents
      ]
    }
    return null
  }, [type])

  return (
    <div ref={ref}>
      {!!SegmentConditionValueComp && (
        <SegmentConditionValueComp
          {...restProps}
          type={type}
          site={site}
          operator={operator}
        />
      )}
    </div>
  )
}
export const SegmentConditionValue = forwardRef(SegmentConditionValueWithoutRef)
