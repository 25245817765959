import { SegmentFormProps } from '../SegmentForm.helpers'
import { StyledSegmentFormSkeleton } from './SegmentFormOverview.styled'
import { Form, Input } from 'antd'
import { SelectSite, SelectTag } from 'components/atoms/select'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { filterOption } from 'utils'

export type SegmentFormOverviewValue = {
  id?: string | number
  name?: string
  description?: string
  site_ids?: number[]
  tag_ids?: number[]
  date_type?: string
  start_date?: string
  end_date?: string
  segment_type?: string
  // From this is for form render
  site_domain?: string[]
  type_time_range?: 'custom_range' | 'default_range'
  custom_time_range?: dayjs.Dayjs[]
  default_time_range?: string
  status?: number
}

export type SegmentFormOverviewProps =
  SegmentFormProps<SegmentFormOverviewValue>
export const SegmentFormOverview = (props: SegmentFormOverviewProps) => {
  const {
    form: formProps,
    disabledKeys,
    disabled,
    loading,
    name,
    initialValues,
    onValuesChange,
    onFinish,
    onFinishFailed
  } = props
  const [form] = Form.useForm(formProps)
  const handleValuesChange = (valChanged: any, valsChanged: any) => {
    const newValChanged = { ...valChanged }
    const newValuesChanged = { ...valsChanged }
    if (valChanged.hasOwnProperty('type_time_range')) {
      newValuesChanged.date_type = undefined
      newValuesChanged.start_date = undefined
      newValuesChanged.end_date = undefined
      newValuesChanged.custom_time_range = undefined
      newValuesChanged.default_time_range = undefined
    }
    if (valChanged.hasOwnProperty('custom_time_range')) {
      const startDate = valChanged.custom_time_range?.[0]
      const endDate = valChanged.custom_time_range?.[1]
      if (startDate?.isValid() && endDate?.isValid()) {
        newValuesChanged.date_type = ''
        newValuesChanged.start_date = startDate.format(FORMAT_DATE_DAYJS_API)
        newValuesChanged.end_date = endDate.format(FORMAT_DATE_DAYJS_API)
      } else {
        newValuesChanged.date_type = ''
        newValuesChanged.start_date = undefined
        newValuesChanged.end_date = undefined
      }
    }
    if (valChanged.hasOwnProperty('default_time_range')) {
      const endDate = dayjs()
      const startDate = endDate.subtract(
        parseInt(valChanged.default_time_range),
        'month'
      )
      if (startDate.isValid()) {
        newValuesChanged.date_type = valChanged.default_time_range
        newValuesChanged.start_date = startDate.format(FORMAT_DATE_DAYJS_API)
        newValuesChanged.end_date = endDate.format(FORMAT_DATE_DAYJS_API)
      }
    }
    form.setFieldsValue(newValuesChanged)
    onValuesChange?.(newValChanged, newValuesChanged)
  }

  useEffect(() => {
    form.setFieldsValue({ ...initialValues })
  }, [initialValues])

  return (
    <Form
      disabled={disabled}
      form={form}
      layout="vertical"
      name={name}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      {/* Group Form.Item hidden */}
      <Form.Item name="segment_type" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="start_date" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="end_date" hidden>
        <Input />
      </Form.Item>
      {/* END - Group Form.Item hidden */}
      <StyledSegmentFormSkeleton
        active
        loading={loading}
        paragraph={{ rows: 1, width: '100%' }}
        className="form_item">
        <Form.Item
          name="name"
          label="Segment name"
          rules={[
            {
              required: true,
              message: 'Please input segment name'
            },
            {
              whitespace: true,
              message: 'Please input segment name with characters'
            },
            {
              max: 255,
              message: 'Maximum 255 characters for segment name'
            }
          ]}>
          <Input placeholder="Segment name" />
        </Form.Item>
      </StyledSegmentFormSkeleton>
      <StyledSegmentFormSkeleton
        active
        loading={loading}
        paragraph={{ rows: 2, width: '100%' }}
        className="form_item">
        <Form.Item name="description" label="Description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>
      </StyledSegmentFormSkeleton>
      <StyledSegmentFormSkeleton
        active
        loading={loading}
        paragraph={{ rows: 1, width: '100%' }}
        className="form_item">
        <Form.Item
          name="site_ids"
          label="Site"
          rules={[{ required: true, message: 'This field is required' }]}>
          <SelectSite
            disabled={disabledKeys?.includes('site_ids')}
            mode="multiple"
            placeholder="- Select Site -"
          />
        </Form.Item>
      </StyledSegmentFormSkeleton>
      <StyledSegmentFormSkeleton
        active
        loading={loading}
        paragraph={{ rows: 1, width: '100%' }}
        className="form_item">
        <Form.Item name="tag_ids" label="Label">
          <SelectTag
            showSearch
            filterOption={filterOption as any}
            mode="multiple"
            maxTagCount={3}
            placeholder="- Select Tags -"
          />
        </Form.Item>
      </StyledSegmentFormSkeleton>
    </Form>
  )
}
