import { FormNamePath } from '../form/index.helpers'
import { StyleHorizantalField } from '../group/index.styled'
import {
  CheckboxOptionType,
  Form,
  FormListFieldData,
  FormListOperation,
  Select
} from 'antd'
import { ConditionBase } from 'components/condition/base/'
import { ConditionDataField } from 'components/condition/base/conditionOperator'
import { ConditionRangeDate } from 'components/condition/base/conditionRangeDate'
import { operationDataType } from 'components/condition/base/index.helpers'
import { getFormNamePath } from 'components/condition/conditions/form/index.helpers'
import { OptionType } from 'interfaces'
import {
  ConditionDataType,
  ConditionValueOperator,
  OperatorKeys,
  operatorLabel,
  operatorValue,
  optionsConditionBoolean
} from 'interfaces/condition'
import { FC, Fragment, useMemo } from 'react'

interface AttributeConditionProps {
  site: string
  rootName: FormNamePath
  attributeFields: ConditionDataType[]
  formListFieldData: FormListFieldData
  formListOperation: FormListOperation
}

const AttributeConditionForm: FC<AttributeConditionProps> = ({
  site,
  rootName,
  attributeFields,
  formListFieldData: { name: itemName }
}) => {
  const form = Form.useFormInstance()
  const conditionKey = Form.useWatch(
    getFormNamePath(rootName, 'conditionKey'),
    form
  )
  const fieldOperator = Form.useWatch(
    getFormNamePath(rootName, 'fieldOperator'),
    form
  )

  const onChangeFieldKey = (value: string) => {
    const resultField = attributeFields.find((field) => field.id === value)
    if (resultField) {
      let resetFieldValue = null
      if (resultField.dataType === 'API' && resultField.type === 'MANY') {
        resetFieldValue = []
      }
      form.setFieldValue(
        getFormNamePath(rootName, 'fieldValue'),
        resetFieldValue
      )
      form.setFieldValue(getFormNamePath(rootName, 'fieldOperator'), null)
    }
  }

  const selectedCondition = useMemo(() => {
    const condition = attributeFields?.find(({ id }) => id === conditionKey)
    return condition || null
  }, [attributeFields, conditionKey])

  const optionsOperation = useMemo(() => {
    const options = [] as CheckboxOptionType[]
    if (!selectedCondition) {
      return []
    }

    const operationKey = operationDataType[
      selectedCondition.dataType as keyof typeof ConditionDataField
    ] as string[]

    Object.keys(operatorLabel).forEach((k) => {
      const key = k as OperatorKeys
      if (operationKey.includes(key)) {
        options.push({
          value: operatorValue[key],
          label: operatorLabel[key]
        })
      }
    })
    return options
  }, [selectedCondition])

  const fieldKeyOptions = useMemo<OptionType[]>(
    () =>
      attributeFields?.map((attr: any) => ({
        label: attr.name,
        value: attr.id
      })),
    [attributeFields]
  )

  const RenderConditionFieldComponent = useMemo(() => {
    if (!selectedCondition) {
      return Fragment
    }
    const moreProps = {} as any
    if (selectedCondition.dataType === 'BOOLEAN') {
      moreProps.options = optionsConditionBoolean
    }
    if (
      selectedCondition.dataType === 'DATE' &&
      fieldOperator === ConditionValueOperator.BETWEEN
    ) {
      return (
        <Form.Item
          name={[itemName, 'fieldValue']}
          className="item-field"
          rules={[
            {
              required: true,
              message: 'Value is required'
            }
          ]}>
          <ConditionRangeDate />
        </Form.Item>
      )
    }
    if (
      selectedCondition.dataType === 'NUMBER' &&
      fieldOperator === ConditionValueOperator.BETWEEN
    ) {
      return (
        <ConditionBase.ConditionNumberRange
          itemName={itemName}
          rootName="attributes"
          className="condition-field"
        />
      )
    }
    const ConditionComponent =
      ConditionDataField[
        selectedCondition.dataType as keyof typeof ConditionDataField
      ]
    return (
      <Form.Item
        name={[itemName, 'fieldValue']}
        className="item-field"
        rules={[
          {
            required: true,
            message: 'Value is required'
          }
        ]}>
        <ConditionComponent
          pathUrl={selectedCondition.value[0] || ''}
          site={site}
          placeholder="Value"
          type={selectedCondition.type}
          {...moreProps}
        />
      </Form.Item>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCondition, site, fieldOperator])

  return (
    <>
      <StyleHorizantalField>
        <Form.Item
          label=""
          className="item-field"
          name={[itemName, 'conditionKey']}
          rules={[
            {
              required: true,
              message: 'Please choose attribute'
            }
          ]}>
          <Select
            options={fieldKeyOptions}
            onChange={onChangeFieldKey}
            placeholder="Field"
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
      </StyleHorizantalField>
      {conditionKey && (
        <>
          <StyleHorizantalField>
            <Form.Item
              label=""
              className="item-field"
              name={[itemName, 'fieldOperator']}
              rules={[
                {
                  required: true,
                  message: 'Please choose operator'
                }
              ]}>
              <Select options={optionsOperation} placeholder="Operator" />
            </Form.Item>
          </StyleHorizantalField>
          <StyleHorizantalField>
            {RenderConditionFieldComponent}
          </StyleHorizantalField>
        </>
      )}
    </>
  )
}

export default AttributeConditionForm
