import { UserOutlined } from '@ant-design/icons'
import { Avatar as AntAvatar, Tooltip } from 'antd'
import { CSSProperties, MouseEventHandler, ReactNode } from 'react'
import styled from 'styled-components'

const StyledAvatarWrapper = styled.div`
  flex: 1;
  transition: 0.25s all linear;
`

const StyleProfileInfor = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 1.5;
  &.email {
    color: ${({ theme }) => theme.colors.gray400};
  }

  &.role {
    color: ${(props) => props.theme?.colors?.gray300};
    text-transform: capitalize;
  }

  &.name {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray800};
  }
`

const StyledAvatar = styled.div<{ collapsed?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'flex-start')};
  align-items: center;
  transition: 0.25s all linear;
  ${StyledAvatarWrapper} {
    margin-left: ${({ collapsed }) => (collapsed ? '' : '12px')};
    overflow: hidden;
    flex: ${({ collapsed }) => (collapsed ? '0' : '1')};
  }
`

const StyleAvatar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
`

const StyledProfileImage = styled.div<{ size: number }>`
  position: relative;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
`

const DEFAULT_AVATAR_SIZE = 40
const DEFAULT_RATIO_FONTSIZE_AVT = 2.5
const DEFAULT_BACKGROUND_COLOR_AVT = '#BFC5CC'

export type AvatarProps = {
  collapsed?: boolean
  name?: string | ReactNode
  email?: string | ReactNode
  avatar?: string
  role?: string
  className?: string
  size?: number
  color?: string
  background?: string
  colorTootip?: string
  style?: CSSProperties
  onClick?: MouseEventHandler<HTMLDivElement>
}
export const Avatar = (props: AvatarProps) => {
  const {
    collapsed,
    name,
    avatar,
    role,
    email,
    size,
    background,
    color,
    colorTootip,
    ...restProps
  } = props
  const firstCharacter =
    typeof name === 'string' ? name?.charAt(0) : name?.toString().charAt(0)
  const sizeAvt = size ? size : DEFAULT_AVATAR_SIZE
  const fontSizeAvt = size ? size / DEFAULT_RATIO_FONTSIZE_AVT : 13
  const styleTooltip = {
    color: colorTootip
  }
  const overlayTooltip = (
    <StyledAvatarWrapper>
      <StyleProfileInfor className="name" style={styleTooltip}>
        {name}
      </StyleProfileInfor>
      {role && (
        <StyleProfileInfor className="role" style={styleTooltip}>
          {role}
        </StyleProfileInfor>
      )}
      {email && (
        <StyleProfileInfor className="email" style={styleTooltip}>
          {email}
        </StyleProfileInfor>
      )}
    </StyledAvatarWrapper>
  )
  return (
    <Tooltip overlay={collapsed && overlayTooltip} placement="right">
      <StyledAvatar {...restProps} collapsed={collapsed}>
        {avatar ? (
          <AntAvatar size={sizeAvt} src={avatar} icon={<UserOutlined />} />
        ) : (
          <StyledProfileImage size={sizeAvt}>
            <StyleAvatar
              style={{
                backgroundColor: background
                  ? background
                  : DEFAULT_BACKGROUND_COLOR_AVT,
                fontSize: fontSizeAvt,
                color: color
              }}>
              {firstCharacter}
            </StyleAvatar>
          </StyledProfileImage>
        )}
        {overlayTooltip}
      </StyledAvatar>
    </Tooltip>
  )
}
