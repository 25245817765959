import { CAMPAIGN_MESSAGING_INSIGHT_OVERVIEW_ITEMS } from '../CampaignMessagingInsightTabOverview'
import { CampaignMessagingInsightFilterValue } from './CampaignMessagingInsightFilter'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import { OptionType } from 'interfaces'
import { useMemo } from 'react'
import { CampaignServices } from 'services/campaign'

export const CAMPAIGN_MESSAGING_INSIGHT_GET_FILTER_NAME = [
  'campaigns',
  'audience',
  'delivery',
  'engagement',
  'retention',
  'conversions',
  'workflows',
  'labels',
  'approaches',
  'campaign_channels',
  'campaign_status'
]

export const CAMPAIGN_INSIGHT_DATE_RECORDED_FORMATED = 'DD/MM/YYYY'

export const CAMPAIGN_INSIGHT_TYPE_OPTIONS: OptionType[] = [
  { value: 'email', label: 'Email' },
  { value: 'app_push', label: 'App Push' },
  { value: 'web_push', label: 'Web push' },
  { value: 'automation', label: 'Automation' },
  { value: 'sms', label: 'SMS' },
  { value: 'whatsapp', label: 'WhatsApp' }
]

export const CAMPAIGN_INSIGHT_STATUS_OPTIONS: OptionType[] = [
  {
    value: 'campaign_status',
    label: 'Status',
    children: [
      { value: 'Done', label: 'Done' },
      { value: 'Running', label: 'Running' },
      { value: 'Schedule', label: 'Schedule' },
      { value: 'Cancel', label: 'Cancel' },
      { value: 'Failed', label: 'Failed' }
    ]
  },
  {
    value: 'approaches',
    label: 'Approach',
    children: [
      { value: 'blast', label: 'Blast' },
      { value: 'triggered', label: 'Triggered' },
      { value: 'journey_triggered', label: 'Journey Triggered' }
    ]
  },
  {
    value: 'workflows',
    label: 'Workflows'
  },
  {
    value: 'labels',
    label: 'Labels'
  }
]

export const useCampaignInsightFieldValueOptions = ({
  fieldKey,
  filterOptions
}: {
  fieldKey?: string
  filterOptions?: Record<string, any>
}) => {
  return useMemo(() => {
    if (!fieldKey) {
      return []
    }
    const fieldValueOption = CAMPAIGN_INSIGHT_STATUS_OPTIONS.find(
      ({ value: valueOpt }) => valueOpt === fieldKey
    )
    if (fieldValueOption && fieldValueOption.children?.length) {
      return fieldValueOption.children
    }
    if (filterOptions?.[fieldKey]) {
      return filterOptions[fieldKey].map(({ id, name }: any) => ({
        value: id,
        label: name
      }))
    }
    return []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldKey, filterOptions?.workflows, filterOptions?.labels])
}

/**
 *
 * filter_conditions:Array<{
 * 	fieldKey: campaign_channels | mode | campaign_status | approaches | labels | workflows | sites,
 * 	fieldValue: ""
 * }>
 */
export const getCampaignInsightFilterData = async (
  value: CampaignMessagingInsightFilterValue
) => {
  try {
    const filter_conditions: any[] = []

    if (
      !value.date_recorded?.[0].isValid() ||
      !value.date_recorded?.[1].isValid()
    ) {
      return {}
    }
    if (value.fieldKey && typeof value.fieldValue !== 'undefined') {
      filter_conditions.push({
        field: value.fieldKey,
        value: [value.fieldValue]
      })
    }
    if (
      (Array.isArray(value.sites) && value.sites.length) ||
      typeof value.sites !== 'undefined'
    ) {
      filter_conditions.push({
        field: 'sites',
        value: Array.isArray(value.sites) ? value.sites : [value.sites]
      })
    }
    if (value.campaign_channels && value.campaign_channels?.length) {
      filter_conditions.push({
        field: 'campaign_channels',
        value: [value.campaign_channels]
      })
    }
    const { data } = await CampaignServices.insightFilterData({
      get_filter_name: CAMPAIGN_MESSAGING_INSIGHT_GET_FILTER_NAME,
      filter_conditions,
      date_from: value.date_recorded[0].format(FORMAT_DATE_DAYJS_API),
      date_to: value.date_recorded[1].format(FORMAT_DATE_DAYJS_API)
    })
    return data
  } catch (error) {
    console.log(error)
    return {}
  }
}

export const useCampaignInsightFilterOptions = ({
  filterOptions,
  filterKey
}: {
  filterOptions: Record<string, any>
  filterKey: string
}) => {
  return useMemo<OptionType[]>(() => {
    if (filterOptions?.[filterKey]) {
      const valueKeys = filterOptions[filterKey] as string[]
      if (valueKeys.length) {
        return valueKeys
          .map((valueKey) => {
            if (valueKey in CAMPAIGN_MESSAGING_INSIGHT_OVERVIEW_ITEMS) {
              const optionInsight =
                CAMPAIGN_MESSAGING_INSIGHT_OVERVIEW_ITEMS[
                  valueKey as keyof typeof CAMPAIGN_MESSAGING_INSIGHT_OVERVIEW_ITEMS
                ]
              return {
                value: valueKey,
                label: optionInsight.label || '',
                description: optionInsight.description
              }
            }
            // eslint-disable-next-line array-callback-return
            return
          })
          .filter(Boolean) as OptionType[]
      }
    }
    return []
  }, [filterKey, filterOptions])
}
