import { User } from 'interfaces'

export const WorkflowStep = {
  Overview: 0,
  Flow: 1
}

export const WorkflowStatus = {
  Draft: 1,
  Ready: 2,
  InProgress: 3,
  Cancel: 4,
  Finish: 5
}

export type WorkflowUser = Omit<User, 'phone'>

export type WorkflowNode = {
  id?: string
  label?: string
  type?: string
  data: Record<string, any>
  position: {
    x: number
    y: number
  }
}

export type WorkflowEdge = {
  id?: string
  source: string
  target: string
  sourceHandle?: string | null
  targetHandle?: string | null
  source_handle?: string | null
  target_handle?: string | null
}

export type WorkflowEdgeCreate = Omit<
  WorkflowEdge,
  'sourceHanlde' | 'targetHandle'
> & {
  source_handle?: string | null
  target_handle?: string | null
}

export type WorkflowTag = { id: string | number; name: string }
export type WorkflowSite = { id: string | number; name: string }

export type Workflow = {
  id: string | number
  name?: string
  description?: string
  status?: number
  tag?: WorkflowTag[]
  site?: WorkflowSite[]
  timezone?: string
  nodes: WorkflowNode[]
  edges: WorkflowEdge[]
  created_at?: string
  updated_at?: string
  created_by?: WorkflowUser
}

export type WorkflowUpdate = Workflow & {
  site_ids?: number[]
  tag_ids?: number[]
  edges: WorkflowEdgeCreate[]
}

export type WorkflowCreate = Omit<WorkflowUpdate, 'id'>

export type WorkflowInsightItem = {
  delivery_rate: number
  click_rate: number
  impression_rate: number
  open_rate: number
  click_through_rate: number
  [k: string]: number
}
export type WorkflowInsightNode<T> = {
  label: string
  type: string
  id: string
  data: T
}

export type WorkflowInsightNodeWebPush = WorkflowInsightNode<{
  campaignId: number
  campaignInsightRate: Record<string, number>
  campaignStatus: number
  sending: string
}>

export type WorkflowInsightNodeEmail = WorkflowInsightNode<{
  campaignId: number
  campaignInsightRate: Record<string, number>
  campaignStatus: number
}>

export type WorfklowInsightParams = {
  id: string | number
  status_id?: string[]
  channel_ids?: string[]
  start_date?: number
  end_date?: number
  heatmap_type?: 'click' | 'open'
}

export type WorkflowInsightNodes = Array<
  WorkflowInsightNodeWebPush | WorkflowInsightNodeEmail
>

export type WorkflowInsight = {
  id: number
  filter?: { end_date?: number; start_date?: number }
  name?: string
  description?: string
  status?: number
  nodes: WorkflowInsightNodes
  tag: WorkflowTag[]
  created_at?: string
  updated_at?: string
  insights: Record<string, WorkflowInsightItem> // {[date]: {[key_of_insight]: [number]}}
  totalInsights: Record<string, number>
  totalInsightsDetail: Record<string, number>
  heatmap: Record<string, Record<string, number[]>>
}

export type WorkflowListParams = {
  search?: string
  sort_by?: string
  sort_type?: string
  created_from?: string
  created_to?: string
  status?: string
  page?: number
  per_page?: number
}

export type WorkflowFolderListParams = {
  search?: string
  sort_by?: string
  sort_type?: string
  created_from?: string
  created_to?: string
  status?: string
  page?: number
  per_page?: number
}
