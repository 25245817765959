import { Form, Input, ModalProps, message } from 'antd'
import cn from 'classnames'
import { ModalEmpty } from 'components/atoms/modal'
import { useCallback, useEffect, useState } from 'react'
import { WorkflowFolderServices } from 'services/workflow'
import styled from 'styled-components'
import { useResetFormOnCloseModal } from 'utils/modal'

const StyledModal = styled(ModalEmpty)``

export type WorkflowModalFolderProps = Omit<ModalProps, 'onOk'> & {
  onOk?: (value: any) => void
}
export function WorkflowFolderCreateModal(props: WorkflowModalFolderProps) {
  const { open, onCancel, onOk: onOkProps, ...restProps } = props
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(Boolean(open))
  const [loading, setLoading] = useState(false)

  const onOk = useCallback(
    async (values: any) => {
      if (!values?.folder_name || typeof values?.folder_name !== 'string') {
        return
      }
      setLoading(true)
      try {
        const workflowCreateRes: any =
          await WorkflowFolderServices.createWorkflowFolder(values.folder_name)
        const { data: workflowCreate } = workflowCreateRes

        if (!workflowCreate && workflowCreateRes?._message) {
          throw new Error(workflowCreateRes._message)
        }
        setLoading(false)
        message.success('Workflow folder was created successfully!')
        onOkProps?.(values)
      } catch (error: any) {
        console.log('** Error WorkflowFolderCreateModal : ', error)
        form.setFields([{ name: 'folder_name', errors: [error.message] }])
        setLoading(false)
      }
    },
    [form, onOkProps]
  )

  useEffect(() => {
    setVisible(Boolean(open))
  }, [open])

  useResetFormOnCloseModal({ form, visible })

  return (
    <StyledModal
      open={open}
      title="New folder"
      onCancel={onCancel}
      cancelButtonProps={{
        ...restProps.okButtonProps,
        className: cn('cancelBtn', restProps.okButtonProps?.className)
      }}
      onOk={form.submit}
      okButtonProps={{
        ...restProps.okButtonProps,
        loading,
        className: cn('okBtn', restProps.okButtonProps?.className)
      }}
      okText="Create folder">
      <Form form={form} onFinish={onOk} layout="vertical">
        <Form.Item
          name="folder_name"
          label="Folder name"
          rules={[
            { required: true, message: 'This field is required!' },
            { max: 255, message: '255 characters maximum' },
            {
              whitespace: true,
              message: 'Please input folder name with characters'
            }
          ]}>
          <Input placeholder="Folder name xyz" />
        </Form.Item>
      </Form>
    </StyledModal>
  )
}
