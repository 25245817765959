import { Button, Select } from 'antd'
import styled from 'styled-components'

export const StyledSegmentConditionButton = styled(Button)`
  &.cond_add {
    height: auto;
    padding: 0;
    margin-top: 8px;
  }
`

export const StyledSegmentConditionSelect = styled(Select)``

export const StyledSegmentConditionCol = styled.div`
  &.cond_left {
    flex: 1;
    min-width: 0;
    display: flex;
    gap: 10px 16px;
    flex-wrap: wrap;
    .col_form {
      min-width: 180px;
    }
  }
  &.cond_right {
    display: flex;
    gap: 8px;
  }
`

export const StyledSegmentConditionRow = styled.div`
  &.cond_row {
    display: flex;
    gap: 24px;
    padding-top: 16px;
    &:first-child {
      padding-top: 0;
    }
  }

  .col_form.form_value {
    display: flex;
    align-items: center;
    gap: 4px;

    .ant-form-item {
      flex: 1;
      min-width: 0;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
    .ant-form-item-control-input {
      min-height: 0;
    }
  }
`
