import Icon from '@ant-design/icons'
import { Checkbox, Select } from 'antd'
import styled from 'styled-components'

export const StyledIcon = styled(Icon)`
  &.anticon-label {
    margin-right: 8px;
  }
`

export const StyledDropdownCheckbox = styled(Checkbox)`
  padding: 7px 4px;
  font-weight: 400;
  pointer-events: none;
`

export const StyledDropdownTitle = styled.h4`
  padding: 0 16px;
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray500 || '#737373'};
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
`

export const StyledDropdownWrapper = styled.div``

export const StyledDropdown = styled.div``

export const StyledTagClose = styled.span`
  display: flex;
  width: 15px;
  height: 15px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme?.colors?.gray400};
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 10px;
  line-height: 1;
  transition: 0.25s linear all;
  &:hover {
    background-color: ${({ theme }) => theme?.colors?.gray700};
  }
`

export const StyledTagLabel = styled.span`
  color: ${({ theme }) => theme?.colors?.gray700 || '#404040'};
  flex: 1;
  minw-width: 0;
`

export const StyledTag = styled.span`
  position: relative;
  z-index: 111;
  display: flex;
  align-items: center;
  padding: 2px 8px 2px 12px;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100 || '#E4E8EC'};
  margin-top: 2px;
  margin-right: 8px;
  margin-bottom: 2px;
  border-radius: 32px;
  ${StyledTagLabel} {
    + ${StyledTagClose} {
      margin-left: 8px;
    }
  }
`

export const StyledSelect = styled(Select)`
  .ant-select-selection-search {
    margin-inline-start: 2px;
  }
`
