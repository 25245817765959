import {
  StyledLink,
  StyledThumbnailEmpty,
  StyledThumbnailWrap,
  StyledWrap
} from './index.styled'
import { Col, Row } from 'antd'
import { DefaultAvatar, Image } from 'icons'
import {
  DataType,
  renderTemplateStatus
} from 'pages/templateBuilderList/helpers'

type Props = {
  templateItem: DataType
}

const mapType: Record<string, string> = {
  email: 'Email',
  ld_page: 'Landing Page',
  app_push: 'App Push',
  web_push: 'Web Push',
  whatsapp: 'WhatsApp'
}

const mapLink: Record<string, string> = {
  email: 'email',
  ld_page: 'landing-page',
  app_push: 'app-push',
  web_push: 'web-push',
  whatsapp: 'whats-app'
}

export function TemplateGridCard({ templateItem }: Props) {
  return (
    <StyledWrap>
      <div className="template-thumbnail">
        <StyledThumbnailWrap>
          <StyledThumbnailEmpty>
            {templateItem.thumbnail ? (
              <img src={templateItem.thumbnail} alt="" />
            ) : (
              <Image />
            )}
          </StyledThumbnailEmpty>
          {/* <Checkbox className="template-checkbox" /> */}
        </StyledThumbnailWrap>
      </div>
      <div className="template-content">
        <span className="template-type">
          {mapType[templateItem.type] || templateItem.type}
        </span>
        <h3 className="template-name">
          <StyledLink
            to={`/template-builder/${mapLink[templateItem.type]}/${
              templateItem.key
            }`}>
            {templateItem.name}
          </StyledLink>
        </h3>
        <Row justify="space-between" align="middle">
          <Col>
            <Row align="middle" className="template-author">
              <DefaultAvatar width={32} height={32} />
              <span>{templateItem.lastModified}</span>
            </Row>
          </Col>
          <Col>{renderTemplateStatus(templateItem)}</Col>
        </Row>
      </div>
    </StyledWrap>
  )
}
