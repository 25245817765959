import { postCreateSegment } from '.'
import { notification } from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import { menuRoutes } from 'components/molecules'
import {
  SegmentForm,
  SegmentFormContainerProps,
  SegmentFormContainerRef,
  SegmentFormValue
} from 'components/molecules/segment'
import { PERMISSIONS } from 'constants/permission'
import { useCheckAuth } from 'hooks/useCheckAuth'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export type FieldData = {
  touched?: boolean
  validating?: boolean
  errors?: string[]
  warnings?: string[]
  value?: any
  validated?: boolean
  name: NamePath
}

export const SegmentPageCreate = (props: SegmentFormContainerProps) => {
  const { segment_type, roles, permissions, onCreateSegment, siteId } = props
  const navigate = useNavigate()
  const segmentFormRef = useRef<SegmentFormContainerRef>(null)
  const [loading, setLoading] = useState(false)

  const isSave = !useCheckAuth({
    permissions: [PERMISSIONS.segment_create]
  })

  const handleSave = useCallback(async (values: SegmentFormValue) => {
    setLoading(true)
    const { data, errors } = await postCreateSegment(values)
    const fieldOverview: FieldData[] = []
    if (errors.length) {
      errors.forEach((error) => {
        if (
          typeof error.name === 'string' &&
          ['name'].some((name) => name === error.name)
        ) {
          fieldOverview.push({
            name: [error.name],
            errors: error.errors
          })
        } else {
          fieldOverview.push({
            name: error.name as NamePath,
            errors: error.errors
          })
        }
      })
      if (fieldOverview.length && fieldOverview?.[0]?.name) {
        segmentFormRef.current?.overviewForm?.setFields(fieldOverview)
        segmentFormRef.current?.overviewForm?.scrollToField(
          fieldOverview[0].name,
          { behavior: 'smooth', block: 'end' }
        )
        setLoading(false)
        return
      } else {
        fieldOverview.forEach(({ errors }) => {
          notification.error({ message: errors })
        })
        setLoading(false)
        return
      }
    }

    if (data?.id) {
      notification.success({
        message: 'Your segment is saved successfully'
      })
      setLoading(false)
      return data
    }
    setLoading(false)
  }, [])

  const handleOkModal = () => {
    navigate(`${menuRoutes.segment}`)
  }

  useEffect(() => {
    if (siteId) {
      segmentFormRef.current?.overviewForm?.setFieldValue('site_ids', [siteId])
    }
  }, [siteId])

  return (
    <SegmentForm
      ref={segmentFormRef}
      siteId={siteId}
      segment_type={segment_type}
      loading={loading}
      saveButtonProps={{ disabled: isSave }}
      onSave={async (value: SegmentFormValue) => {
        const data = await handleSave(value)
        if (!onCreateSegment && data?.id) {
          navigate(`${menuRoutes.segment}/${data.id}`)
        }
        if (onCreateSegment) {
          onCreateSegment(data)
        }
      }}
      confirmModalProps={{
        onOk: handleOkModal
      }}
      roles={roles}
      permissions={permissions}
    />
  )
}
