import {
  Cambodia,
  India,
  Indonesia,
  Malaysia,
  Myanmar,
  Phillipines,
  Taiwan,
  Thailand,
  Vietnam
} from 'icons'
import { OptionType } from 'interfaces'

export const EMAIL_DOMAINS_VALID = [
  'hellobacsi.com',
  'hellosehat.com',
  'hellodoktor.com',
  'hellokhunmor.com',
  'helloyishi.com.tw',
  'hellokrupet.com',
  'hellosayarwon.com',
  'helloswasthya.com',
  'hellodoctor.com',
  'hellohealthgroup.com'
]

export const SITE_KEYS = {
  HELLO_BAC_SI: 'HELLO_BAC_SI',
  MARRY_BABY: 'MARRY_BABY',
  HELLO_SEHAT: 'HELLO_SEHAT', // Indonesia
  HELLO_KHUNMOR: 'HELLO_KHUNMOR', // Thailand
  HELLO_DOKTOR: 'HELLO_DOKTOR', // Malaysia
  HELLO_YISHI: 'HELLO_YISHI', // Taiwan
  HELLO_KRUPET: 'HELLO_KRUPET', // Cambodia
  HELLO_SAYARWON: 'HELLO_SAYARWON', // Myanmar
  HELLO_SWATHYA: 'HELLO_SWATHYA', // India
  HELLO_DOCTOR: 'HELLO_DOCTOR', // Philippines
  HELLO_FIL: 'HELLO_FIL', // Philippines - Fil
  HELLO_HEALTH_GROUP: 'HELLO_HEALTH_GROUP'
}

export const SITE_KEYS_DB = {
  hello_bacsi: SITE_KEYS.HELLO_BAC_SI,
  hello_doctor: [SITE_KEYS.HELLO_DOCTOR, SITE_KEYS.HELLO_FIL],
  hello_doktor: SITE_KEYS.HELLO_DOKTOR,
  hello_healthgroup: SITE_KEYS.HELLO_HEALTH_GROUP,
  hello_khunmor: SITE_KEYS.HELLO_KHUNMOR,
  hello_krupet: SITE_KEYS.HELLO_KRUPET,
  hello_sayarwon: SITE_KEYS.HELLO_SAYARWON,
  hello_sehat: SITE_KEYS.HELLO_SEHAT,
  hello_swasthya: SITE_KEYS.HELLO_SWATHYA,
  hello_yishi: SITE_KEYS.HELLO_YISHI,
  'marry_baby-vn': SITE_KEYS.MARRY_BABY
}

export const SITE_DOMAINS_PRODUCTION = {
  'hellohealthgroup.com': SITE_KEYS.HELLO_HEALTH_GROUP,
  'hellobacsi.com': SITE_KEYS.HELLO_BAC_SI,
  'marrybaby.vn': SITE_KEYS.MARRY_BABY,
  'www.marrybaby.vn': SITE_KEYS.MARRY_BABY,
  'hellosehat.com': SITE_KEYS.HELLO_SEHAT,
  'hellokhunmor.com': SITE_KEYS.HELLO_KHUNMOR,
  'hellodoktor.com': SITE_KEYS.HELLO_DOKTOR,
  'helloyishi.com.tw': SITE_KEYS.HELLO_YISHI,
  'hellokrupet.com': SITE_KEYS.HELLO_KRUPET,
  'hellosayarwon.com': SITE_KEYS.HELLO_SAYARWON,
  'helloswasthya.com': SITE_KEYS.HELLO_SWATHYA,
  'hellodoctor.com.ph': SITE_KEYS.HELLO_DOCTOR,
  'hellodoctor.com.ph/fil': SITE_KEYS.HELLO_FIL
}

export const SITE_DOMAINS_STAGING = {
  'discover.hellobacsi.com': SITE_KEYS.HELLO_BAC_SI,
  'fe.marrybaby.vn': SITE_KEYS.MARRY_BABY,
  'discover.hellosehat.com': SITE_KEYS.HELLO_SEHAT,
  'discover.hellokhunmor.com': SITE_KEYS.HELLO_KHUNMOR,
  'discover.hellodoktor.com': SITE_KEYS.HELLO_DOKTOR,
  'discover.helloyishi.com.tw': SITE_KEYS.HELLO_YISHI,
  'discover.hellokrupet.com': SITE_KEYS.HELLO_KRUPET,
  'discover.hellosayarwon.com': SITE_KEYS.HELLO_SAYARWON,
  'discover.helloswasthya.com': SITE_KEYS.HELLO_SWATHYA,
  'discover.hellodoctor.com.ph': SITE_KEYS.HELLO_DOCTOR,
  'discover.hellodoctor.com.ph/fil': SITE_KEYS.HELLO_FIL,
  'staging.hellohealthgroup.com': SITE_KEYS.HELLO_HEALTH_GROUP
}

export const SITE_DOMAINS = {
  // Production
  ...SITE_DOMAINS_PRODUCTION,
  // Staging
  ...SITE_DOMAINS_STAGING
}

export const SITE_NATIONS = {
  [SITE_KEYS.HELLO_BAC_SI]: 'VN',
  [SITE_KEYS.MARRY_BABY]: 'VN',
  [SITE_KEYS.HELLO_SEHAT]: 'ID', // Indonesia
  [SITE_KEYS.HELLO_KHUNMOR]: 'TH', // Thailand
  [SITE_KEYS.HELLO_DOKTOR]: 'MY', // Malaysia
  [SITE_KEYS.HELLO_YISHI]: 'TW', // Taiwan
  [SITE_KEYS.HELLO_KRUPET]: 'KH', // Cambodia
  [SITE_KEYS.HELLO_SAYARWON]: 'MM', // Myanmar
  [SITE_KEYS.HELLO_SWATHYA]: 'IN', // India
  [SITE_KEYS.HELLO_DOCTOR]: 'PH', // Philippines
  [SITE_KEYS.HELLO_FIL]: 'PH', // Philippines - Fil
  [SITE_KEYS.HELLO_HEALTH_GROUP]: 'HHG'
}

export const WEB_PAGE_PRODUCTION = {
  'hellobacsi.com': 'Hello Bacsi',
  'marrybaby.vn': 'Marry Baby-VN',
  'www.marrybaby.vn': 'Marry Baby-VN',
  'hellosehat.com': 'Hello Sehat',
  'hellokhunmor.com': 'Hello Khunmor',
  'hellodoktor.com': 'Hello Doktor',
  'helloyishi.com.tw': 'Hello Yishi',
  'hellokrupet.com': 'Hello Krupet',
  'hellosayarwon.com': 'Hello Sayarwon',
  'helloswasthya.com': 'Hello Swasthya',
  'hellodoctor.com.ph': 'Hello Doctor',
  'hellodoctor.com.ph/fil': 'Hello Doctor Fil',
  'hellohealthgroup.com': 'Hello Health Group'
}

export const WEB_PAGE_STAGING = {
  'discover.hellobacsi.com': 'Hello Bacsi',
  'fe.marrybaby.vn': 'Marry Baby-VN',
  'discover.hellosehat.com': 'Hello Sehat',
  'discover.hellokhunmor.com': 'Hello Khunmor',
  'discover.hellodoktor.com': 'Hello Doktor',
  'discover.helloyishi.com.tw': 'Hello Yishi',
  'discover.hellokrupet.com': 'Hello Krupet',
  'discover.hellosayarwon.com': 'Hello Sayarwon',
  'discover.helloswasthya.com': 'Hello Swasthya',
  'discover.hellodoctor.com.ph': 'Hello Doctor',
  'discover.hellodoctor.com.ph/fil': 'Hello Doctor Fil',
  'staging.hellohealthgroup.com': 'Hello Health Group'
}

export const WEB_PAGE = {
  ...WEB_PAGE_PRODUCTION,
  ...WEB_PAGE_STAGING
}

export const SITES: Record<string, Omit<OptionType, 'value'>> = {
  [SITE_KEYS.HELLO_BAC_SI]: { label: 'Vietnam - HelloBacsi', icon: Vietnam },
  [SITE_KEYS.MARRY_BABY]: { label: 'Vietnam - Marrybaby', icon: Vietnam },
  [SITE_KEYS.HELLO_SEHAT]: { label: 'Indonesia', icon: Indonesia },
  [SITE_KEYS.HELLO_KHUNMOR]: { label: 'Thailand', icon: Thailand },
  [SITE_KEYS.HELLO_DOKTOR]: { label: 'Malaysia', icon: Malaysia },
  [SITE_KEYS.HELLO_YISHI]: { label: 'Taiwan', icon: Taiwan },
  [SITE_KEYS.HELLO_KRUPET]: { label: 'Cambodia', icon: Cambodia },
  [SITE_KEYS.HELLO_SAYARWON]: { label: 'Myanmar', icon: Myanmar },
  [SITE_KEYS.HELLO_SWATHYA]: { label: 'India', icon: India },
  [SITE_KEYS.HELLO_DOCTOR]: { label: 'Phillipines', icon: Phillipines },
  [SITE_KEYS.HELLO_FIL]: { label: 'Phillipines - Fil', icon: Phillipines },
  [SITE_KEYS.HELLO_HEALTH_GROUP]: { label: 'Hello Health Group', icon: Vietnam }
}

export const SITE_FLAGS = {
  [SITE_KEYS.HELLO_BAC_SI]: '/images/flag/vietnam.svg',
  [SITE_KEYS.MARRY_BABY]: '/images/flag/vietnam.svg',
  [SITE_KEYS.HELLO_SEHAT]: '/images/flag/indonesia.svg',
  [SITE_KEYS.HELLO_KHUNMOR]: '/images/flag/thailand.svg',
  [SITE_KEYS.HELLO_DOKTOR]: '/images/flag/malaysia.svg',
  [SITE_KEYS.HELLO_YISHI]: '/images/flag/taiwan.svg',
  [SITE_KEYS.HELLO_KRUPET]: '/images/flag/cambodia.svg',
  [SITE_KEYS.HELLO_SAYARWON]: '/images/flag/myanmar.svg',
  [SITE_KEYS.HELLO_SWATHYA]: '/images/flag/india.svg',
  [SITE_KEYS.HELLO_DOCTOR]: '/images/flag/phillipines.svg',
  [SITE_KEYS.HELLO_FIL]: '/images/flag/phillipines.svg',
  [SITE_KEYS.HELLO_HEALTH_GROUP]: '/images/flag/hhg.png'
}

export const SITE_PREFIX_LANDING_PAGE = 'lp'
