import { useQuery } from '@tanstack/react-query'
import { DEFAULT_GET_ALL_RECORDS } from 'constants/common'
import { SITES, SITE_DOMAINS, SITE_KEYS_DB, WEB_PAGE } from 'constants/site'
import { OptionType, OptionTypeSite } from 'interfaces'
import { ConditionDataType } from 'interfaces/condition'
import { formatOperatorOptions } from 'pages/campaign/automation/create/CampaignAutomationCreatePage.helpers'
import { useEffect, useMemo, useRef, useState } from 'react'
import { EventServices } from 'services/event'
import { siteServices, tagServices } from 'services/segment'
import {
  segmentBuilderServices,
  segmentConditonBuilderServices
} from 'services/segmentBuilder'

export const useTagOptions = () => {
  const { data: tags = [], refetch: refetchTags } = useQuery<OptionType[]>({
    queryKey: [
      'getTags',
      DEFAULT_GET_ALL_RECORDS.page,
      DEFAULT_GET_ALL_RECORDS.per_page
    ],
    queryFn: async () => {
      try {
        const res = await tagServices.getTagsList(DEFAULT_GET_ALL_RECORDS)
        if (!res.data) {
          throw new Error()
        }
        const options = res.data.data.map(
          (tab: { name: string; id: number }) => ({
            label: tab.name,
            value: tab.id
          })
        )
        return options
      } catch (error) {
        console.log('** Error useTagOptions : ', error)
        return []
      }
    }
  })

  return useMemo(() => ({ tags, refetchTags }), [tags, refetchTags])
}

export const useSiteOptions = () => {
  const isMountedSites = useRef(false)
  const [sites, setSites] = useState<OptionTypeSite[]>([])

  useEffect(() => {
    ;(async () => {
      if (!isMountedSites.current) {
        try {
          const { data } = await siteServices.getSiteList()
          if (!data) {
            throw new Error()
          }
          const options = data.map(
            ({ name, id }: { name: string; id: number }) => {
              if (name in SITE_DOMAINS) {
                const key = name as keyof typeof SITE_DOMAINS
                const siteKey = SITE_DOMAINS[key]
                const site = SITES[siteKey]
                const webPage = WEB_PAGE[key]

                const option: OptionTypeSite = {
                  value: id,
                  label: site.label,
                  icon: site.icon,
                  name: siteKey,
                  domain: name,
                  webPage: webPage
                }

                return option
              }

              return false
            }
          )
          setSites(options)
          isMountedSites.current = true
        } catch (error) {
          console.log('** Error useSiteOptions : ', error)
        }
      }
    })()
  }, [])

  return sites
}

export const useCategoryOptions = () => {
  const isMoutedCategories = useRef(false)
  const [categories, setCategories] = useState<OptionType[]>([])

  useEffect(() => {
    ;(async () => {
      if (!isMoutedCategories.current) {
        try {
          const { data } = await segmentBuilderServices.getSegmentBuilder()
          if (!data) {
            throw new Error()
          }
          const options = data.data
          setCategories(options)
          isMoutedCategories.current = true
        } catch (error) {
          console.log('** Error useCategoryOptions : ', error)
        }
      }
    })()
  }, [])

  return categories
}

export const useCategoryDetailOptions = (props: {
  id: string | number
  category: string
}) => {
  const [categoryDetails, setCategoryDetails] = useState<OptionType[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await segmentBuilderServices.getSegmentBuilderDetail(
          props
        )

        if (!data) {
          throw new Error()
        }

        const options = data.data
        setCategoryDetails(options)
      } catch (error) {
        console.log('** Error useCategoryDetailOptions : ', error)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.category, props.id])

  return categoryDetails
}

export const useAttributeFields = () => {
  const isMountedSites = useRef(false)
  const [attributes, setAttributes] = useState<ConditionDataType[]>([])

  useEffect(() => {
    ;(async () => {
      if (!isMountedSites.current) {
        try {
          const { data } = await segmentConditonBuilderServices.getConditions()
          if (!data.data) {
            throw new Error()
          }
          setAttributes(data.data)
          isMountedSites.current = true
        } catch (error) {
          console.log('** Error useAttributeOptions : ', error)
        }
      }
    })()
  }, [])

  return attributes
}

export const useAttributeOptions = () => {
  const isMountedSites = useRef(false)
  const [attributes, setAttributes] = useState<OptionType[]>([])

  useEffect(() => {
    ;(async () => {
      if (!isMountedSites.current) {
        try {
          const { data } =
            await segmentConditonBuilderServices.getConditionBaseBuilder()
          if (!data.data) {
            throw new Error()
          }
          const options = data.data
          setAttributes(options)
          isMountedSites.current = true
        } catch (error) {
          console.log('** Error useAttributeOptions : ', error)
        }
      }
    })()
  }, [])

  return attributes
}

export const useEventFieldOptions = () => {
  const isMountedSites = useRef(false)
  const [eventFields, setEventFields] = useState<OptionType[]>([])

  useEffect(() => {
    ;(async () => {
      if (!isMountedSites.current) {
        try {
          const { data } = await EventServices.getEventConditions()
          if (!data.data) {
            throw new Error()
          }
          const options = data.data.map((item: any) => {
            return {
              value: item.name,
              label: item.name,
              type: item.type
            }
          })
          setEventFields(options)
          isMountedSites.current = true
        } catch (error) {
          console.log('** Error useEventFieldOptions : ', error)
        }
      }
    })()
  }, [])

  return eventFields
}

export const useEventOptions = () => {
  const isMountedSites = useRef(false)
  const [events, setEvents] = useState<OptionType[]>([])

  useEffect(() => {
    ;(async () => {
      if (!isMountedSites.current) {
        try {
          const { data } = await EventServices.getList(DEFAULT_GET_ALL_RECORDS)
          if (!data.data) {
            throw new Error()
          }
          const options = data.data.map((item: any) => {
            return {
              value: item.id,
              label: item.name
            }
          })
          setEvents(options)
          isMountedSites.current = true
        } catch (error) {
          console.log('** Error useEventFieldOptions : ', error)
        }
      }
    })()
  }, [])

  return events
}

export const useEventOperationOptions = () => {
  const isMountedSites = useRef(false)
  const [eventOperators, setEventOperators] = useState<OptionType[]>([])

  useEffect(() => {
    ;(async () => {
      if (!isMountedSites.current) {
        try {
          const { data } = await EventServices.getEventOperators()
          if (!data.data) {
            throw new Error()
          }
          const operatorOptions = formatOperatorOptions(data.data)
          setEventOperators(operatorOptions)
          isMountedSites.current = true
        } catch (error) {
          console.log('** Error useEventFieldOptions : ', error)
        }
      }
    })()
  }, [])

  return eventOperators
}

export const useCountAllSite = (sites: OptionTypeSite[]) => {
  const isMount = useRef(false)
  const [countAllSites, setCountAllSites] = useState<Record<string, number>>({})
  useEffect(() => {
    if (!isMount.current && sites?.length) {
      ;(async () => {
        try {
          const response: any =
            await segmentConditonBuilderServices.getCountUserAllSite()

          if (response?._message && !response?.data) {
            throw new Error(JSON.stringify(response))
          }

          const { data } = response
          if (Array.isArray(data.data)) {
            const countAllSites: Record<string, number> = {}
            data.data.forEach(({ site: siteOnDB, count }: any) => {
              if (siteOnDB in SITE_KEYS_DB) {
                const name = SITE_KEYS_DB[siteOnDB as keyof typeof SITE_KEYS_DB]
                const sitesFilter = sites.filter((site) =>
                  name.includes(site.name)
                )
                if (sitesFilter.length) {
                  sitesFilter.forEach((site) => {
                    countAllSites[site.name] = parseInt(count || '0')
                  })
                }
              }
            })
            setCountAllSites(countAllSites)
            isMount.current = true
          }
        } catch (error) {
          isMount.current = false
        }
      })()
    }
  }, [sites])
  return useMemo(() => {
    return { countAllSites }
  }, [countAllSites])
}
