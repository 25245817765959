import { Tabs } from 'antd'
import styled from 'styled-components'

export const StyledWorkflowTableTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    .ant-tabs-tab {
      color: ${({ theme }) => theme?.colors?.gray400};
      + .ant-tabs-tab {
        margin-left: 24px;
      }
    }
  }
`
