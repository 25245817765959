import { TEMPLATE_TYPE } from 'constants/template'

export const getTemplateRoute = (id?: string | number, type?: string) => {
  switch (type) {
    case TEMPLATE_TYPE.EMAIL: {
      return `/template-builder/email/${id}`
    }
    case TEMPLATE_TYPE.APP_PUSH: {
      return `/template-builder/app-push/${id}`
    }
    case TEMPLATE_TYPE.WEB_PUSH: {
      return `/template-builder/web-push/${id}`
    }
    case TEMPLATE_TYPE.WHATSAPP: {
      return `/template-builder/whats-app/${id}`
    }
    case TEMPLATE_TYPE.LP_PAGE:
      return `/template-builder/landing-page/${id}`
    case TEMPLATE_TYPE.TWILIO:
    case TEMPLATE_TYPE.VNPT:
      return `/template-builder/${type}/${id}`
    default: {
      return `/template-builder/${type}/${id}`
    }
  }
}
