import ConditionRow from '../conditionRow'
import { FormNamePath, getFormNamePath } from '../form/index.helpers'
import {
  StyleGroupCondition,
  StyleHeaderGroup,
  StyleHorizantalField,
  StyleText,
  StyleWrapperFields
} from './index.styled'
import Icon from '@ant-design/icons'
import {
  Button,
  Form,
  FormListFieldData,
  FormListOperation,
  Select
} from 'antd'
import cls from 'classnames'
import { ConditionsRelation } from 'components/condition/conditions/relation'
import { TrashBin } from 'icons'
import { optionsConditionRelation } from 'interfaces/condition'
import { ConditionDataType } from 'interfaces/condition'
import { FC, useEffect } from 'react'

interface ConditionGroupProps {
  site: string
  rootName: FormNamePath
  attributeFields: ConditionDataType[]
  eventFields: any[]
  operatorEventOpts: any[]
  formListFieldData: FormListFieldData
  formListOperation: FormListOperation
}

const ConditionGroup: FC<ConditionGroupProps> = (props) => {
  const form = Form.useFormInstance()
  const {
    site,
    rootName,
    attributeFields,
    eventFields,
    operatorEventOpts,
    formListFieldData: { name: itemName },
    formListOperation
  } = props
  const fieldRelationGroup = Form.useWatch(
    getFormNamePath(rootName, [itemName, 'relationGroup']),
    form
  )
  const conditionType = Form.useWatch(
    getFormNamePath(rootName, [itemName, 'conditions', '0', 'conditionType']),
    form
  )

  useEffect(() => {
    if (fieldRelationGroup) {
      const conditionValues = form.getFieldValue(
        getFormNamePath(rootName, [itemName, 'conditions'])
      )
      // Init select type condition
      if (!conditionValues) {
        form.setFieldValue(
          getFormNamePath(rootName, [itemName, 'conditions']),
          [
            {
              conditionType: null
            }
          ]
        )
      }
    }
  }, [fieldRelationGroup, rootName, itemName, form])

  return (
    <StyleGroupCondition className="wrapper-form-list">
      <StyleHeaderGroup>
        <StyleText>
          Segment for people in <strong>{site}</strong>, where
        </StyleText>
        <Button
          icon={<Icon component={TrashBin} />}
          size="small"
          onClick={() => formListOperation.remove(itemName)}
        />
      </StyleHeaderGroup>
      <StyleHorizantalField>
        <Form.Item name={[itemName, 'relationGroup']} className="item-field">
          <Select options={optionsConditionRelation} />
        </Form.Item>
        <StyleText>of the following condition match</StyleText>
      </StyleHorizantalField>
      <Form.List name={[itemName, 'conditions']}>
        {(conditions, operations) => {
          return (
            <>
              {conditions.map((condition, index) => {
                return (
                  <StyleWrapperFields
                    key={`${condition.key}-${index}`}
                    className="items-end relative">
                    <div
                      className={cls(
                        'absolute top-[-20px] left-[30px] w-[1px] bg-[#E4E8EC]',
                        {
                          'bottom-[20px]':
                            !conditionType ||
                            conditionType === 'custom_conditions',
                          'bottom-[41px]':
                            conditionType &&
                            conditionType !== 'custom_conditions'
                        }
                      )}
                    />
                    <ConditionsRelation
                      value={fieldRelationGroup}
                      className={cls('relation-condition z-[1]', {
                        '!mb-[20px]':
                          !conditionType ||
                          conditionType === 'custom_conditions',
                        '!mb-[41px]':
                          conditionType && conditionType !== 'custom_conditions'
                      })}
                      disabled
                    />
                    <ConditionRow
                      key={condition.key}
                      attributeFields={attributeFields}
                      eventFields={eventFields}
                      operatorEventOpts={operatorEventOpts}
                      rootName={getFormNamePath(rootName, [
                        itemName,
                        'conditions',
                        condition.name
                      ])}
                      formListFieldData={condition}
                      formListOperation={operations}
                      site={site}
                    />
                  </StyleWrapperFields>
                )
              })}
              {conditions.length === 0 && (
                <Button onClick={() => operations.add()} size="small">
                  Add condition or group
                </Button>
              )}
            </>
          )
        }}
      </Form.List>
    </StyleGroupCondition>
  )
}

export default ConditionGroup
