import { DataWhatsAppAPI, FormWhatsAppData, FormWhatsAppPayload } from './types'
import { DocumentSvg, ImageSvg, LocationSvg, VideoSvg } from 'icons'

export const getIconMedia = (headerFormat: string) => {
  const mapIcon: Record<
    string,
    React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  > = {
    IMAGE: ImageSvg,
    VIDEO: DocumentSvg,
    LOCATION: LocationSvg,
    DOCUMENT: VideoSvg
  }
  return mapIcon[headerFormat] || ImageSvg
}

export const isSelectMedia = (computedHeaderFormat: string) => {
  return computedHeaderFormat === 'MEDIA(IMAGE|VIDEO|DOCUMENT|LOCATION)'
}

export const scrollToError = () => {
  document.querySelector('.ant-form-item-explain-error')?.scrollIntoView({
    behavior: 'smooth'
  })
}

// Convert data
export const convertFormDataToPayload = (
  formData: FormWhatsAppData
): FormWhatsAppPayload => {
  const [siteId, siteDomainName, countryCode] =
    formData.siteCountryCode.split('|')
  const returnValue: FormWhatsAppPayload = {
    name: formData.name,
    site_id: siteId,
    site_name: siteDomainName,
    language_code: countryCode,
    category: formData.category,
    sub_category: formData.sub_category,
    header_format: formData.header_format,
    header_text: formData.header_text || '',
    header_example: formData.computed_header_data.header_example || [],
    header_variable: formData.computed_header_data.header_variable || [],
    header_handle:
      typeof formData.header_handle_file === 'string'
        ? null
        : formData.header_handle_file || null,
    body_text: formData.body_text || '',
    body_example: formData.computed_body_data.body_example || [],
    body_variable: formData.computed_body_data.body_variable || [],
    footer_text: formData.footer_text,
    allow_category_change: true,
    buttons: formData.buttons || [],
    type: 'whatsapp'
  }

  return returnValue
}

const convertToCumputedHeaderFormat = (headerFormat: string) => {
  if (!headerFormat) return ''
  if (headerFormat === 'TEXT') return 'TEXT'
  if (['IMAGE', 'DOCUMENT', 'LOCATION', 'VIDEO'].includes(headerFormat)) {
    return 'MEDIA(IMAGE|VIDEO|DOCUMENT|LOCATION)'
  }
  return ''
}

export const convertFromAPIToFormData = (
  data: DataWhatsAppAPI
): FormWhatsAppData => {
  const returnValue: FormWhatsAppData = {
    name: data.template.name,
    category: data.category,
    sub_category: data.sub_category,
    siteCountryCode: `${data.template.site}|${data.template.site_name}|${data.template.country_code}`,
    computed_header_format: convertToCumputedHeaderFormat(data.header_format),
    header_format: data.header_format,
    header_text: data.header_text,
    header_handle_file: data.header_handle,
    body_text: data.body_text,
    footer_text: data.footer || '',
    computed_body_data: {
      body_example: data.body_example || [],
      body_variable: data.body_variable || []
    },
    computed_header_data: {
      header_example: data.header_example || [],
      header_variable: data.header_variable || []
    },
    buttons: data.buttons || []
  }

  return returnValue
}
