import {
  StyledInputSearch,
  StyledList,
  StyledListItem,
  StyledListItemMeta
} from './index.styled'
import Icon from '@ant-design/icons'
import { ListProps } from 'antd'
import { OptionType } from 'interfaces'
import { MouseEvent, useCallback, useEffect, useState } from 'react'

export type ListSearchProps = ListProps<OptionType> & {
  placeholder?: string
  onFilter?: (value?: string) => void
  filterOption?: (input: string, item: OptionType) => void
  onClick?: (
    item: OptionType,
    index?: number,
    event?: MouseEvent<HTMLDivElement>
  ) => void
}
export const ListSearch = (props: ListSearchProps) => {
  const {
    size,
    dataSource: dataSourceProps,
    renderItem: renderItemProps,
    header: headerProps,
    // Extend Props
    placeholder,
    onFilter: onFilterProps,
    filterOption: filterOptionProps,
    onClick,
    ...restProps
  } = props
  const [filter, setFilter] = useState<string | undefined>()
  const [dataSource, setDataSource] = useState<OptionType[]>(
    dataSourceProps || []
  )

  const renderItem =
    renderItemProps || ((...params) => renderItemDefault(...params, onClick))
  const header = headerProps || (
    <StyledInputSearch
      allowClear
      onSearch={onFilter}
      placeholder={placeholder}
      // size={size === 'default' ? 'medium' : size}
    />
  )
  const filterOption = useCallback(
    (item: OptionType, index: number) => {
      if (!filter) {
        return true
      }
      if (filterOptionProps) {
        return filterOptionProps(filter, item)
      }
      if (
        typeof item.label === 'string' &&
        item.label.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      ) {
        return true
      }
      return false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, filterOptionProps]
  )

  function onFilter(value: string) {
    setFilter(value)
    onFilterProps && onFilterProps(value)
  }

  useEffect(() => {
    setDataSource(() => {
      return (dataSourceProps || [])?.filter(filterOption)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption])

  useEffect(() => {
    setDataSource(dataSourceProps || [])
  }, [dataSourceProps])

  return (
    <StyledList<any>
      dataSource={dataSource}
      header={header}
      size={size}
      renderItem={renderItem}
      {...restProps}
    />
  )
}

const renderItemDefault = (
  item: OptionType,
  index: number,
  onClickProps: ListSearchProps['onClick']
) => {
  const ListItemMetaAvatar = item?.icon ? (
    <Icon component={item.icon} />
  ) : undefined
  const onClick = (item: OptionType, index?: number) => {
    return (e?: MouseEvent<HTMLDivElement>) => {
      onClickProps && onClickProps(item, index, e)
    }
  }
  return (
    <StyledListItem onClick={onClick(item, index)} key={item.value.toString()}>
      <StyledListItemMeta avatar={ListItemMetaAvatar} title={item.label} />
    </StyledListItem>
  )
}
