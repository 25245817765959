import { StyledIcon, StyledSelect, StyledSelectItem } from './index.styled'
import { Select, SelectProps } from 'antd'
import { useApp } from 'context/App'
import { useAuth } from 'context/Auth'

export type SelectSiteProps = SelectProps & {
  hasRole?: boolean
  disabled?: boolean
}
const placeholderDefault = '- Select -'
export const SelectSite = (props: SelectSiteProps) => {
  const {
    options: optionsProps,
    placeholder = placeholderDefault,
    hasRole = false,
    ...restProps
  } = props
  const { userAuth } = useAuth()
  const { sites: siteOptions } = useApp()
  const options = optionsProps || siteOptions

  return (
    <StyledSelect<any> {...restProps} placeholder={placeholder}>
      {options.map(({ value, label, name, icon }, index) => {
        if (hasRole && !userAuth?.auth.sitesActive?.includes(name)) {
          return null
        }
        return (
          <Select.Option key={index.toString()} value={value}>
            <StyledSelectItem>
              {icon && <StyledIcon component={icon} />}
              {label}
            </StyledSelectItem>
          </Select.Option>
        )
      })}
    </StyledSelect>
  )
}
