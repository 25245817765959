import styled, { css } from 'styled-components'
import { cssLimitLines, cssScrollBar } from 'styles'

const cssResetInput = css`
  padding: 0;
  margin-bottom: 12px;
  color: ${({ theme }) => theme?.colors?.gray800};
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none !important;
  box-shadow: none !important;
  &[disabled] {
    cursor: default;
  }
  &::placeholder {
    color: ${({ theme }) => theme?.colors?.gray300};
  }
`
export const StyledFormHeader = styled.div`
  display: flex;
  .formHeader--content {
    flex: 1;
    min-width: 0;
    max-width: 85%;
  }
  .formHeader--title {
    ${cssResetInput}
    font-size: 32px;
    font-weight: bold;
    line-height: 1.3;
    ${cssLimitLines(1)}
  }
  .formHeader--tags {
    ${cssResetInput}
  }
  .formHeader--description {
    ${cssResetInput}
    font-size: 14px;
    margin-bottom: 0;
    height: auto;
    min-height: 0;
    resize: none;
    ${cssScrollBar}
  }
`
