import { menuRoutes } from '../MenuSidebar.helpers'
import { StyledMenuSidebarProfileDropdownMenu } from './MenuSidebarProfile.styled'
import { Avatar, DropDownProps, Dropdown, MenuProps } from 'antd'
import cn from 'classnames'
import { Kebab } from 'icons/V2'
import { Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'

export type MenuSidebarProfileProps = {
  avatar?: string
  name?: string
  description?: string
  collapse?: boolean
  menu?: Pick<MenuProps, 'items' | 'onClick'>
  className?: string
}

export const MenuSidebarProfile = (props: MenuSidebarProfileProps) => {
  const { avatar, name, description, collapse, menu, className } = props

  const DropdownComp = useMemo(() => {
    if (!menu || !menu?.items?.length) {
      return Fragment
    }
    return Dropdown
  }, [menu])
  const dropdownCompProps = useMemo<DropDownProps | Record<string, any>>(() => {
    if (!menu || !menu?.items?.length) {
      return {}
    }
    return {
      menu: {
        ...menu,
        mode: 'inline',
        inlineCollapsed: collapse,
        className: cn('p-0 bg-gray-700', { 'min-w-[200px]': !collapse })
      },
      align: { offset: [36, -15] },
      trigger: ['click'],
      placement: 'topRight',
      dropdownRender: (MenuComp) => {
        return (
          <StyledMenuSidebarProfileDropdownMenu
            className={cn({ isCollapse: collapse })}>
            {MenuComp}
          </StyledMenuSidebarProfileDropdownMenu>
        )
      }
    }
  }, [collapse, menu])

  const AvatarComp = (
    <Avatar
      size={32}
      src={
        <img
          src={
            avatar?.includes('avatar-default')
              ? '/images/profile-default.png'
              : avatar
          }
          alt={name}
        />
      }
      className={cn('sidebarProfile-avatar w-[32px] relative', {
        'cursor-pointer': !!collapse
      })}
    />
  )

  return (
    <div
      className={cn(
        'sidebarProfile flex items-center h-[40px] relative',
        className
      )}>
      {collapse ? (
        <DropdownComp {...dropdownCompProps}>{AvatarComp}</DropdownComp>
      ) : (
        AvatarComp
      )}
      <div
        className={cn(
          'sidebarProfile-container flex items-center overflow-hidden transition-all',
          {
            'flex-1 min-w-0 ml-4': !collapse,
            'flex-0 w-0': !!collapse
          }
        )}>
        <div className="sidebarProfile-wrapper flex-1 min-w-0">
          <Link to={menuRoutes.profile}>
            <h3 className="sidebarProfile-name text-[14px] text-white font-semibold leading-[1.5em] whitespace-nowrap m-0">
              {name}
            </h3>
          </Link>
          <p className="sidebarProfile-description text-[12px] text-gray-400 leading-[1.2rem] whitespace-nowrap m-0">
            {description}
          </p>
        </div>
        <DropdownComp {...dropdownCompProps}>
          <Kebab className="sidebarProfile-kebab w-6 h-6 cursor-pointer" />
        </DropdownComp>
      </div>
    </div>
  )
}
