import { HighchartsCompProps, HighchartsCompRef } from '.'
import { HighchartsEmpty } from './HighchartsEmpty'
import cn from 'classnames'
import Highcharts from 'highcharts'
import Accessibility from 'highcharts/modules/accessibility'
import SeriesLabel from 'highcharts/modules/series-label'
import Stock from 'highcharts/modules/stock'
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

export type HighchartsComponentRef = HighchartsCompRef
export type HighchartsComponentProps = HighchartsCompProps

const formatHighchartsOptionsDefault = (
  options?: Highcharts.Options
): Highcharts.Options => {
  const newOptions = {
    ...options,
    title: { text: '', ...options?.title },
    yAxis: {
      title: { text: null },
      ...options?.yAxis
    },
    xAxis: {
      title: { text: null },
      ...options?.xAxis
    },
    legend: { enabled: false, ...options?.legend },
    credits: { enabled: false, ...options?.credits }
  }

  return newOptions
}
const HighchartsComponentRenderFunc = (
  props: HighchartsComponentProps,
  ref?: Ref<HighchartsComponentRef>
) => {
  const {
    options,
    className,
    style,
    emptyProps,
    onInit,
    onBeforeRedraw: onBeforeRedrawProps,
    onRedraw: onRedrawProps,
    onRender: onRenderProps,
    onResize: onResizeProps,
    onEndResize: onEndResizeProps
  } = props

  const $highchart = useRef<HTMLDivElement>(null)
  const [highcharts, setHighcharts] = useState<Highcharts.Chart>()
  const debounce = useRef<NodeJS.Timeout>()

  const optionsFormated = useMemo(
    () => formatHighchartsOptionsDefault(options),
    [options]
  )

  const onLoad = useCallback(() => {
    if (debounce.current) {
      clearTimeout(debounce.current)
    }
    debounce.current = setTimeout(() => {
      if (!$highchart.current) {
        return
      }
      Stock(Highcharts)
      SeriesLabel(Highcharts)
      Accessibility(Highcharts)
      Highcharts.chart($highchart.current, optionsFormated, (chart) => {
        onInit?.(chart)
        setHighcharts(chart)
      })
    }, 10)
  }, [onInit, optionsFormated])

  const onBeforeRedraw = useCallback(() => {
    onBeforeRedrawProps?.(highcharts as Highcharts.Chart)
  }, [highcharts, onBeforeRedrawProps])

  const onRedraw = useCallback(() => {
    onRedrawProps?.(highcharts as Highcharts.Chart)
  }, [highcharts, onRedrawProps])

  const onRender = useCallback(() => {
    onRenderProps?.(highcharts as Highcharts.Chart)
  }, [highcharts, onRenderProps])

  const onResize = useCallback(() => {
    onResizeProps?.(highcharts as Highcharts.Chart)
  }, [highcharts, onResizeProps])

  const onEndResize = useCallback(() => {
    onEndResizeProps?.(highcharts as Highcharts.Chart)
  }, [highcharts, onEndResizeProps])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  useEffect(() => {
    if (highcharts) Highcharts.addEvent(highcharts, 'render', onRender)
    return () => {
      if (highcharts) Highcharts.removeEvent(highcharts, 'render', onRender)
    }
  }, [highcharts, onRender])

  useEffect(() => {
    if (highcharts)
      Highcharts.addEvent(highcharts, 'beforeRedraw', onBeforeRedraw)
    return () => {
      if (highcharts)
        Highcharts.removeEvent(highcharts, 'beforeRedraw', onBeforeRedraw)
    }
  }, [highcharts, onBeforeRedraw])

  useEffect(() => {
    if (highcharts) Highcharts.addEvent(highcharts, 'redraw', onRedraw)
    return () => {
      if (highcharts) Highcharts.removeEvent(highcharts, 'redraw', onRedraw)
    }
  }, [highcharts, onRedraw])

  useEffect(() => {
    if (highcharts) Highcharts.addEvent(highcharts, 'resize', onResize)
    return () => {
      if (highcharts) Highcharts.removeEvent(highcharts, 'resize', onResize)
    }
  }, [highcharts, onResize])

  useEffect(() => {
    if (highcharts) Highcharts.addEvent(highcharts, 'endResize', onEndResize)
    return () => {
      if (highcharts)
        Highcharts.removeEvent(highcharts, 'endResize', onEndResize)
    }
  }, [highcharts, onEndResize])

  useImperativeHandle(ref, () => highcharts as Highcharts.Chart, [highcharts])

  return (
    <div className={cn('highchartsComponent', className)} style={style}>
      {!options?.series?.length ? (
        <HighchartsEmpty {...emptyProps} />
      ) : (
        <div
          ref={$highchart}
          className="highchartsComponent--container h-full"
        />
      )}
    </div>
  )
}
export const HighchartsComponent = forwardRef(HighchartsComponentRenderFunc)
