import cls from 'classnames'
import { CSSProperties } from 'react'

type Props = {
  previewUrl: string
  className?: string
  style?: CSSProperties
}

const ZaloPreview = ({ previewUrl, className, style }: Props) => {
  return (
    <iframe
      src={previewUrl}
      title="Zalo Preview"
      scrolling="no"
      className={cls(
        'w-[400px] h-[400px] overflow-hidden border-0 rounded-[12px]',
        className
      )}
      style={style}
    />
  )
}

export default ZaloPreview
