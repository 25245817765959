import { menuItemsSort } from './index.helpers'
import {
  StyledIconContainer,
  StyledSortDropdownOverlay,
  StyledSortDropdownTitle
} from './index.styled'
import { Dropdown, MenuProps } from 'antd'
import { default as cn } from 'classnames'
import { SortKeys } from 'constants/segment'
import { CaretDown } from 'icons'
import { ReactNode, useCallback, useEffect, useState } from 'react'

export type TableColumnSortProps = {
  disabled?: boolean
  value?: SortKeys
  onChange?: (value: SortKeys) => void
  className?: string
}
export const TableColumnSort = (props: TableColumnSortProps) => {
  const {
    disabled,
    value: valueProps,
    onChange: onChangeProps,
    className
  } = props
  const [value, setValue] = useState(valueProps)

  const onClick: MenuProps['onClick'] = useCallback(
    (info) => {
      const val = info.key as SortKeys
      setValue(val)
      onChangeProps && onChangeProps(val)
    },
    [onChangeProps]
  )

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        activeKey: value,
        items: menuItemsSort,
        onClick,
        className: 'menuDropdownTable'
      }}
      dropdownRender={(menuComp: ReactNode) => {
        return (
          <StyledSortDropdownOverlay>
            <StyledSortDropdownTitle>SORT BY</StyledSortDropdownTitle>
            {menuComp}
          </StyledSortDropdownOverlay>
        )
      }}
      disabled={disabled}>
      <StyledIconContainer
        className={cn(className, { active: Boolean(value) })}>
        <CaretDown />
      </StyledIconContainer>
    </Dropdown>
  )
}

export default TableColumnSort
