import {
  StyledActions,
  StyledNav,
  StyledWrap
} from '../topbarWithSteps/index.styled'
import { Button, Tooltip } from 'antd'
import { menuRoutes } from 'components/molecules'
import { PERMISSIONS } from 'constants/permission'
import { ROLES } from 'constants/role'
import { useCheckAuth } from 'hooks/useCheckAuth'
import { ChevronLeft } from 'icons'
import { Link } from 'react-router-dom'

type Props = {
  name: string
  onContinue: () => void
  onSaveAsDraft: () => void
  // showSaveAsDraft: boolean
  btnContinueText?: string
  btnSaveDraftText?: string
  btnContinueLoading?: boolean
  btnContinueDisabled?: boolean
  btnSaveDraftLoading?: boolean
  btnSaveDraftDisabled?: boolean
  tooltip?: string
  hideNavigation?: boolean
}

export function TopbarWithNoSteps({
  name,
  onContinue,
  onSaveAsDraft,
  btnContinueText = 'Publish Template',
  btnContinueLoading,
  btnContinueDisabled,
  btnSaveDraftText = 'Save as draft',
  btnSaveDraftLoading,
  btnSaveDraftDisabled,
  tooltip,
  hideNavigation
}: Props) {
  const isEdit = !useCheckAuth({
    roles: [ROLES.admin, ROLES.campaign_manager],
    permissions: [
      PERMISSIONS.template_email_edit,
      PERMISSIONS.template_landing_page_edit,
      PERMISSIONS.template_web_push_edit,
      PERMISSIONS.template_app_push_edit,
      PERMISSIONS.template_whatsapp_edit
    ]
  })
  const renderedBtnContinue = (
    <Button
      type="primary"
      onClick={onContinue}
      size="small"
      loading={btnContinueLoading}
      disabled={isEdit || !!btnContinueDisabled}>
      {btnContinueText}
    </Button>
  )
  return (
    <StyledWrap>
      {!hideNavigation ? (
        <StyledNav>
          <Link to={menuRoutes.template}>
            <ChevronLeft />
            <span>{name}</span>
          </Link>
        </StyledNav>
      ) : (
        <div />
      )}
      <StyledActions>
        {btnSaveDraftText && (
          <Button
            type="ghost"
            onClick={onSaveAsDraft}
            size="small"
            loading={btnSaveDraftLoading}
            disabled={isEdit || !!btnSaveDraftDisabled}>
            {btnSaveDraftText}
          </Button>
        )}
        {tooltip ? (
          <Tooltip title={tooltip} placement="bottomLeft">
            {renderedBtnContinue}
          </Tooltip>
        ) : (
          renderedBtnContinue
        )}
      </StyledActions>
    </StyledWrap>
  )
}
