import axios from 'axios'
import { capitalizeStr } from 'pages/templateBuilderCreate/templateCopilotDrawer'
import { copilotBaseAxios } from 'utils/api'

const CancelToken = axios.CancelToken

type CopilotParams = {
  feature: 'campaign_segment' | 'subject' | 'content' | 'preheader'
  task: {
    /** summarize_text, persuasive_bullet_points, email_subject_lines, content_improver, personalized_cold_emails, generate_subject_line, subject_line_improver, generate_body_copy, generate_preheader, preheader_improver, generate_preheader_copy */
    name: string
    input: string
    /** [auto, English, Bahasa, Chinese, Vietnamese, Phillipino] */
    language: string
    /** [auto, formal, casual, friendly, professional, funny, witty, relax] */
    tone: string
    /** [auto, short, medium, long] */
    length?: string
    /** [auto, email, subject line, message, paragraph, outline, idea] */
    format?: string
    /** [inbound_marketing, outbound_marketing, healthcare_general, healthcare_pharmaceutical] */
    industry?: string
  }
  session_uid: string
}

export const CopilotServices = {
  generate: (params: CopilotParams) => {
    const source = CancelToken.source()

    params.task.language =
      params.task.language === 'auto'
        ? params.task.language
        : capitalizeStr(params.task.language)

    return {
      promise: copilotBaseAxios.post('/v1/copilot/gen', params, {
        cancelToken: source.token
      }),
      cancelRequest: () => {
        source.cancel()
      }
    }
  }
}
