import { Drawer } from 'antd'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

export const StyledSegmentAnalyticContainer = styled(Drawer)`
  .ant-drawer-header {
    padding: 4px 24px;
  }

  .ant-drawer-body {
    ${cssScrollBar}
    padding: 24px;
  }

  .muuri-item {
    width: 100%;
  }

  &.full {
    .ant-drawer-body {
      background-color: ${({ theme }) => theme?.colors?.neutral50};
      padding-inline: 40px;
    }
    .muuri-item:not(.fullWidth) {
      width: calc(50% - 1px);
    }
  }
`
