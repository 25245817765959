import { NOTIFICATION_PATHS } from 'constants/apiPaths'
import { NotificationItem } from 'context/Notifications/helpers'
import { ResponseType } from 'interfaces'
import {
  AppPushTestSendEmailParams,
  TemplateTestSendEmailParams,
  TemplateTestSendWhatsappParams
} from 'interfaces/notification'
import { ActivityNotificationItem } from 'pages/setting/notifications/helpers'
import { copilotBaseAxios, segmentBaseAxios, sidisBaseAxios } from 'utils/api'

type NotificationParams = {
  size?: number
  next?: string
  fromInSeconds?: number
  toInSeconds?: number
}

export const NotificationServices = {
  templateTestSendEmail: (
    data: TemplateTestSendEmailParams | TemplateTestSendWhatsappParams
  ) => {
    return sidisBaseAxios.post<any, ResponseType<any>>(
      NOTIFICATION_PATHS.template_test_send_email,
      data
    )
  },
  appPushTestSendEmail: (data: AppPushTestSendEmailParams) => {
    return sidisBaseAxios.post<any, ResponseType<any>>(
      NOTIFICATION_PATHS.app_push_test_send_email,
      data
    )
  },
  activityNotifications: () => {
    return segmentBaseAxios.get(NOTIFICATION_PATHS.activify_notifications)
  },
  updateActivityNoti: (listData: ActivityNotificationItem[]) => {
    return segmentBaseAxios.put(
      NOTIFICATION_PATHS.activify_notifications,
      listData
    )
  },
  // Team Approval Notification
  messages: (
    { size, next, fromInSeconds, toInSeconds }: NotificationParams = {
      size: 100
    }
  ) => {
    return copilotBaseAxios.get('/api/notification/message', {
      params: {
        size,
        next,
        from: fromInSeconds ? parseInt(String(fromInSeconds)) : fromInSeconds,
        to: toInSeconds ? parseInt(String(toInSeconds)) : toInSeconds
      }
    })
  },
  markAsRead: (ids: number[]) => {
    return copilotBaseAxios.put('/api/notification/message', {
      ids,
      status: 'read'
    })
  },
  approveFlow: (action: string, data: NotificationItem['data']) => {
    return copilotBaseAxios.post('/api/user-approve-flow/action', {
      action,
      ...data
    })
  }
}
