import { SegmentConditionValueProps } from './SegmentConditionValue'
import {
  StyledSegmentConditionValueDatePicker,
  StyledSegmentConditionValueNumber
} from './SegmentConditionValue.styled'
import { RangePickerDateProps } from 'antd/lib/date-picker/generatePicker'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import { SEGMENT_CONDITION_OPERATOR } from 'constants/segment'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

type RangeDatePicker = RangePickerDateProps<Dayjs>['value']

const formatSegmentConditionValueDATES = (
  value?: string[] | string | null | number
): RangeDatePicker | null => {
  if (typeof value === 'number') return null

  if (!value) {
    return null
  }

  if (Array.isArray(value)) {
    const startDate = dayjs(value?.[0], FORMAT_DATE_DAYJS_API, true)
    const endDate = dayjs(value?.[1], FORMAT_DATE_DAYJS_API, true)
    if (startDate.isValid() && endDate.isValid()) {
      return [startDate, endDate]
    }
  }

  return null
}

const formatSegmentConditionValueDATE = (
  value?: string | string[] | null | number
) => {
  if (typeof value === 'number') return null

  if (!value) {
    return null
  }

  if (typeof value === 'string') {
    const date = dayjs(value, FORMAT_DATE_DAYJS_API, true)
    if (date.isValid()) {
      return date
    }
  }

  return null
}

export type SegmentConditionValueDATEProps = Omit<
  SegmentConditionValueProps<number | string | [string, string] | null>,
  'type'
>

export const SegmentConditionValueDATE = (
  props: SegmentConditionValueDATEProps
) => {
  const {
    size,
    defaultValue,
    value: valueProps,
    onChange,
    operator,
    disabled
  } = props

  const [values, setValues] = useState<RangeDatePicker | null>(
    formatSegmentConditionValueDATES(defaultValue || valueProps || null)
  )
  const [value, setValue] = useState<Dayjs | null>(
    formatSegmentConditionValueDATE(defaultValue || valueProps || null)
  )
  const [valueDays, setValueDays] = useState<number | null>(
    Number(defaultValue || valueProps) || null
  )

  const handleChange = (
    value: Dayjs | RangeDatePicker | null,
    formatString: string | string[]
  ) => {
    if (!value || value === null) {
      setValue(null)
      setValues(null)
      setValueDays(null)
      onChange?.(value || null)
      return
    }
    if (Array.isArray(value)) {
      if (value?.[0]?.isValid() && value?.[1]?.isValid()) {
        const startDate = value[0].format(FORMAT_DATE_DAYJS_API)
        const endDate = value[1].format(FORMAT_DATE_DAYJS_API)
        setValues(value)
        onChange?.([startDate, endDate])
      }
      return
    }

    if (value.isValid()) {
      const dateString = value.format(FORMAT_DATE_DAYJS_API)
      setValue(value)
      onChange?.(dateString)
      return
    }
  }

  useEffect(() => {
    const newValues = formatSegmentConditionValueDATES(valueProps || null)
    const newValue = formatSegmentConditionValueDATE(valueProps || null)
    setValues(newValues)
    setValue(newValue)
    setValueDays(Number(valueProps) || null)
  }, [valueProps])

  if (operator === SEGMENT_CONDITION_OPERATOR.BETWEEN) {
    return (
      <StyledSegmentConditionValueDatePicker.RangePicker
        value={values}
        onChange={handleChange}
        format={FORMAT_DATE_DAYJS_API}
        size={size}
        disabled={disabled}
      />
    )
  }

  if (operator === SEGMENT_CONDITION_OPERATOR.IN_THE_LAST) {
    return (
      <div className="flex items-center gap-[8px]">
        <div className="w-[80px]">
          <StyledSegmentConditionValueNumber
            value={valueDays}
            onChange={(num) => {
              setValueDays(num as any)
              onChange?.(num || null)
            }}
            placeholder=""
            min={0}
            size={size}
            disabled={disabled}
          />
        </div>
        <span>Days</span>
      </div>
    )
  }

  return (
    <StyledSegmentConditionValueDatePicker
      value={value}
      onChange={handleChange}
      placeholder="- Select date -"
      format={FORMAT_DATE_DAYJS_API}
      size={size}
      disabled={disabled}
    />
  )
}
