import { Button } from 'antd'
import styled from 'styled-components'
import { cssDropdownOverlay } from 'styles'

export const StyledDropdownOverlay = styled.div`
  ${cssDropdownOverlay}
  .ant-dropdown-menu-item {
    &.delete {
      color: ${({ theme }) => theme?.colors.red600 || '#F44D2C'};
      &.ant-dropdown-menu-item-disabled {
        opacity: 0.7;
      }
    }
  }
`

export const StyledButton = styled(Button)`
  &.btnDetail {
    padding: 0;
    color: ${({ theme }) => theme?.colors?.gray600 || '#595959'};
    .anticon {
      font-size: 20px;
    }
  }
`
