import { LoadingFullScreen } from 'components/atoms/loading'
import { useAuth } from 'context/Auth'
import { Navigate } from 'react-router-dom'

export function RootPage() {
  const { status } = useAuth()
  if (status !== 'authorized' && status !== 'unauthorized') {
    return <LoadingFullScreen isShowMenu />
  }
  if (status === 'authorized') {
    return <Navigate to={'/segment'} />
  }
  return <Navigate to={'/login'} />
}
