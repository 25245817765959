import { ConditionBaseProps } from '..'
import { ConditionNumber } from '../conditionNumber'
import { Form } from 'antd'
import { getFormNamePath } from 'components/condition/conditions/form/index.helpers'

export type ConditionOperatorProps = ConditionBaseProps<number | null> & {
  itemName?: any
  rootName?: any
  label?: string
  placeholder?: string
  size?: string
}
export const ConditionNumberRange = (props: ConditionOperatorProps) => {
  const { itemName, rootName, ...restProps } = props

  return (
    <>
      <Form.Item
        name={[itemName, 'fieldValueFrom']}
        rules={[
          {
            required: true,
            message: 'Value is required'
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const fieldValueTo = getFieldValue(
                getFormNamePath(rootName, [itemName, 'fieldValueTo'])
              )
              if (!value || !fieldValueTo || fieldValueTo >= value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error('This value is less or equal than field to')
              )
            }
          })
        ]}>
        <ConditionNumber
          placeholder="From"
          className="condition-field"
          {...restProps}
        />
      </Form.Item>
      <Form.Item
        name={[itemName, 'fieldValueTo']}
        rules={[
          {
            required: true,
            message: 'Value is required'
          },
          ({ getFieldValue }) => ({
            async validator(_, value) {
              const fieldValueFrom = getFieldValue(
                getFormNamePath(rootName, [itemName, 'fieldValueFrom'])
              )
              if (!value || !fieldValueFrom || fieldValueFrom <= value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error('This value is greater or equal than field from')
              )
            }
          })
        ]}>
        <ConditionNumber
          placeholder="To"
          className="condition-field"
          {...restProps}
        />
      </Form.Item>
    </>
  )
}
