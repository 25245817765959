import {
  getCountryCodeByFromEmail,
  getDomainBySite
} from '../emailTemplate/helpers'
import { TemplateStatus } from '../landingPage/helpers'
import GeneralCopilotDrawer from '../templateCopilotDrawer/GeneralCopilotDrawer'
import {
  TemplateData,
  WebPushSettingsAPI,
  WebPushSettingsForm,
  WebPushSettingsParams,
  defaultIconMessage,
  transformSettingsApiToParams,
  transformWebPushApiToSettingsForm,
  trasnformResponseToSettingsAPI,
  webPushKeysChange
} from './helpers'
import {
  StyleContentReview,
  StyleHeader,
  StyledWebPushBuilder,
  StyledWebPushDetail,
  StyledWebPushPreview
} from './index.styled'
import { notification } from 'antd'
import { LoadingFullScreen } from 'components/atoms/loading'
import { MainHeader } from 'components/molecules'
import FormSettingWebPush, {
  HandleRef
} from 'components/molecules/form/formSettingWebPush'
import {
  PanelHeaderTopBar,
  PreviewWebPush,
  TopbarWithNoSteps
} from 'components/molecules/templateBuilder'
import { FORMAT_DATE } from 'constants/common'
import { useApp } from 'context/App'
import dayjs from 'dayjs'
import Page404 from 'pages/404'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { TemplateServices } from 'services/template'

type Props = {
  siteId?: string | number
  onCreateTemplate?: (templateData: any) => void
}

function TemplateBuilderCreate({ onCreateTemplate, siteId }: Props) {
  const { sites } = useApp()
  const params = useParams()
  const location = useLocation()

  /** Display the form to create webpush inside the campaign create flow */
  const isCampaignPage = location.pathname.startsWith('/campaign/web_push/')

  const formRef = useRef<HandleRef | undefined>()
  const [isFetchError, setIsFetchError] = useState(false)
  const [btnSaveLoading, setBtnSaveLoading] = useState(false)
  const [btnSaveDraftLoading, setBtnSaveDraftLoading] = useState(false)
  const [templateData, setTemplateData] = useState<TemplateData>()
  const [settingValues, setSettingValues] = useState<WebPushSettingsForm>({
    name: '',
    message_type: '',
    title: '',
    description: '',
    message_icon: 'default',
    image: '',
    message_link: 'custom',
    custom_link_url: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    utm_term: '',
    site: siteId || '',
    country_code: '',
    site_name: ''
  })
  const isRelease = templateData?.status === 'release'

  useEffect(() => {
    if (params.id && !isCampaignPage) {
      TemplateServices.getById(params.id)
        .then((res) => {
          if (res?.data?.data) {
            const initSettingValues = transformWebPushApiToSettingsForm(
              res.data.data
            )
            const initTemplateData = trasnformResponseToSettingsAPI(
              res.data.data
            )

            setSettingValues(initSettingValues)
            setTemplateData(initTemplateData)
          }
        })
        .catch((err) => {
          notification.error({
            message: 'Something went wrong while fetching this template data'
          })
          setIsFetchError(true)
        })
    }
  }, [params.id, isCampaignPage])

  const headerComponent = (
    <TopbarWithNoSteps
      name="New Web Push"
      onContinue={onClickPublish}
      btnContinueLoading={btnSaveLoading}
      btnSaveDraftLoading={btnSaveDraftLoading}
      btnSaveDraftDisabled={isRelease}
      onSaveAsDraft={() => {
        if (!templateData) {
          handleCreateSettings('draft')
        } else {
          handleUpdateSettings('draft')
        }
      }}
      btnSaveDraftText={isCampaignPage ? '' : 'Save as draft'}
      hideNavigation={isCampaignPage}
    />
  )

  async function handleCreateSettings(status: TemplateStatus = 'draft') {
    try {
      status === 'draft' && setBtnSaveDraftLoading(true)
      status === 'release' && setBtnSaveLoading(true)

      const formValues: WebPushSettingsForm =
        await formRef.current?.form.validateFields()

      const domainName = getDomainBySite(sites, formValues.site)

      const params: WebPushSettingsParams = {
        ...formValues,
        image:
          formValues.message_icon === 'default'
            ? defaultIconMessage
            : formValues.image,
        type: 'web_push',
        site_name: domainName,
        site: formValues.site.toString(),
        country_code: getCountryCodeByFromEmail(domainName),
        status: status
      }

      const response = await TemplateServices.createTemplate(params)

      if (!response?.data?.data) {
        throw new Error('')
      }

      if (!isCampaignPage) {
        setSettingValues(formValues)
        setTemplateData(trasnformResponseToSettingsAPI(response.data.data))
      }
      notification.success({ message: 'Create template successfully!' })

      if (isCampaignPage && onCreateTemplate) {
        formRef.current?.form.resetFields()
        onCreateTemplate(response.data.data)
      }
    } catch (err: any) {
      notification.error({
        message:
          err.response?.data?.errors?.message ||
          'Something went wrong. Please try again!'
      })
    }
    status === 'draft' && setBtnSaveDraftLoading(false)
    status === 'release' && setBtnSaveLoading(false)
  }

  async function handleUpdateSettings(status: TemplateStatus = 'draft') {
    if (!templateData || btnSaveLoading) {
      return
    }
    try {
      setBtnSaveLoading(true)
      const formValues: WebPushSettingsForm =
        await formRef.current?.form.validateFields()

      const domainName = getDomainBySite(sites, formValues.site)

      const newTemplateData = {
        ...templateData,
        ...formValues,
        country_code: getCountryCodeByFromEmail(domainName),
        site: formValues.site.toString(),
        site_name: domainName,
        image:
          formValues.message_icon === 'default'
            ? defaultIconMessage
            : formValues.image
      }

      setSettingValues(formValues)
      setTemplateData(newTemplateData)
      handleSaveAppBuilder(status, newTemplateData)
    } catch (err: any) {
      notification.error({
        message:
          err.response?.data?.errors?.message ||
          'Something went wrong. Please try again!'
      })
    }
    setBtnSaveLoading(false)
  }

  function handleSaveAppBuilder(
    status: TemplateStatus = 'draft',
    newTemplateData?: WebPushSettingsAPI
  ) {
    if (!templateData || btnSaveDraftLoading || btnSaveLoading) {
      return
    }

    status === 'draft' && setBtnSaveDraftLoading(true)
    status === 'release' && setBtnSaveLoading(true)

    const params: WebPushSettingsParams = {
      ...transformSettingsApiToParams(newTemplateData || templateData),
      status: status
    }

    TemplateServices.updateTemplate(templateData.templateId, params)
      .then((res) => {
        notification.success({ message: 'Update template successfully!' })
      })
      .catch((err: any) => {
        notification.error({
          message:
            err.response?.data?.errors?.message || 'Update template failed'
        })
      })
      .finally(() => {
        status === 'draft' && setBtnSaveDraftLoading(false)
        status === 'release' && setBtnSaveLoading(false)
      })
  }

  function onClickPublish() {
    if (!templateData) {
      handleCreateSettings('release')
    }
    if (templateData) {
      handleUpdateSettings('release')
    }
  }

  function handleChangeForm(evt: any) {
    const key = evt.target.id
    const value = evt.target.value

    if (webPushKeysChange.includes(key)) {
      setSettingValues((prev) => ({
        ...prev,
        [key]: value
      }))
    }
  }

  if (!isCampaignPage && params.id && !templateData) {
    if (isFetchError) {
      return <Page404 />
    }

    return <LoadingFullScreen />
  }

  return (
    <MainHeader
      headerTitle="New Web Push"
      bgColor="#F7F9FC"
      stickyHeader={!isCampaignPage}
      headerComponent={headerComponent}>
      {!isCampaignPage && (
        <PanelHeaderTopBar
          name={templateData?.name || 'N/A'}
          type="Web Push"
          id={templateData?.templateId}
          created_at={
            templateData
              ? dayjs(templateData.created_at).format(FORMAT_DATE)
              : 'N/A'
          }
        />
      )}
      <StyledWebPushBuilder>
        <StyledWebPushDetail>
          <StyleHeader>Settings</StyleHeader>
          <FormSettingWebPush
            ref={formRef}
            initialValues={settingValues}
            onChange={handleChangeForm}
            setSettingValues={setSettingValues}
            fixedSiteId={isCampaignPage && siteId ? siteId : undefined}
          />
        </StyledWebPushDetail>
        <StyledWebPushPreview>
          <StyleContentReview>
            <StyleHeader>Preview</StyleHeader>
            <PreviewWebPush preview={settingValues} sites={sites} />
          </StyleContentReview>
        </StyledWebPushPreview>
      </StyledWebPushBuilder>
      <GeneralCopilotDrawer />
    </MainHeader>
  )
}

export default TemplateBuilderCreate
