import { Checkbox, Typography } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { OptionType } from 'interfaces'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

const StyledCheckboxTypo = styled(Typography)`
  &.label {
    color: ${({ theme }) => theme?.color?.gray600};
    font-size: 14px;
  }
  &.number {
    color: ${({ theme }) => theme?.colors?.gray800};
    font-size: 18px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`

const StyledCheckbox = styled(Checkbox)<{ color?: string }>`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  .ant-checkbox {
    + span {
      width: 100%;
      display: block;
    }
  }
  .ant-checkbox-inner {
    ${({ color }) => color && `border-color: ${color};`}
  }
  .ant-checkbox-checked {
    &:after {
      ${({ color }) => color && `border-color: ${color};`}
    }
    .ant-checkbox-inner {
      ${({ color }) => color && `background-color: ${color};`}
    }
  }
  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    ${({ color }) => color && `border-color: ${color};`}
  }
`
const StyledCheckboxGroup = styled(Checkbox.Group)`
  width: 100%;
  display: flex;
`

export type InsightOverviewCheckboxGroupOptions = Array<
  OptionType & { description?: string; unit?: string }
>
export type InsightOverviewCheckboxGroupProps = {
  options?: InsightOverviewCheckboxGroupOptions
  defaultValue?: Array<CheckboxValueType>
  value?: Array<CheckboxValueType>
  onChange?: (checkedValue: Array<CheckboxValueType>) => void
}
export const InsightOverviewCheckboxGroup: FC<
  InsightOverviewCheckboxGroupProps
> = (props: InsightOverviewCheckboxGroupProps) => {
  const {
    options,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps
  } = props

  const [value, setValue] = useState(defaultValue || valueProps)

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  const onChange = (val: Array<CheckboxValueType>) => {
    setValue(val)
    onChangeProps?.(val)
  }

  if (!options || !options?.length) return null

  return (
    <StyledCheckboxGroup value={value} onChange={onChange}>
      {options.map((option, index) => {
        return (
          <StyledCheckbox
            key={`${option.value}_${index}`}
            value={option.value}
            color={option.color}>
            <StyledCheckboxTypo as="div" className="label">
              {option.label}
            </StyledCheckboxTypo>
            <StyledCheckboxTypo as="div" className="number">
              {option.description}
            </StyledCheckboxTypo>
          </StyledCheckbox>
        )
      })}
    </StyledCheckboxGroup>
  )
}
