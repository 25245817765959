import {
  OPTION_FILTER_APPROACH,
  OPTION_FILTER_COLUMNS,
  OPTION_FILTER_STATUS,
  resetFilterCampaignParams
} from '../CampaignTable/CampaignTable.helpers'
import {
  CampaignHeaderState,
  CampaignHeaderValue,
  campaignHeaderPropsToState,
  campaignHeaderStateToProps,
  menuCreateCampaign,
  tabCampaign
} from './CampaignHeader.helpers'
import {
  StyleLabel,
  StyleWrapperItem,
  StyledCampaignButton,
  StyledCampaignTableSpace,
  StyledContainerHeader
} from './CampaignHeader.styled'
import Icon, { BarChartOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Select, Tabs } from 'antd'
import cls from 'classnames'
import { Box } from 'components/atoms/box'
import { DatePicker } from 'components/atoms/datePicker'
import { DrawerFilter } from 'components/atoms/drawer'
import { DropdownCheckbox } from 'components/atoms/dropdown'
import { InputSearch } from 'components/atoms/input'
import { SelectSite } from 'components/atoms/select'
import { menuRoutes } from 'components/molecules'
import { StyledModal } from 'components/molecules/campaign/CampaignForm/CampaignStepHeader/CampaignStepHeader.styled'
import { SelectTemplateItem } from 'components/molecules/templateBuilder'
import { PERMISSIONS } from 'constants/permission'
import { TEMPLATE_TYPE } from 'constants/template'
import { useAuth } from 'context/Auth'
import dayjs from 'dayjs'
import { checkAuth } from 'hooks/useCheckAuth'
import { CheckedCircle, Column, Filter, Plus } from 'icons'
import {
  TransitionEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useNavigate } from 'react-router'
import { plausibleAPI } from 'utils/plausibleTracking'

export type CampaignHeaderProps = {
  value?: CampaignHeaderValue
  defaultValue?: CampaignHeaderValue
  onChange?: (value: CampaignHeaderValue) => void
  onTransitionEnd?: TransitionEventHandler<HTMLDivElement>
}
export default function CampaignHeader(props: CampaignHeaderProps) {
  const {
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    onTransitionEnd: onTransitionEndProps
  } = props
  const navigate = useNavigate()
  const { userAuth } = useAuth()
  const debouceRef = useRef<NodeJS.Timeout>()
  const [value, setValue] = useState<CampaignHeaderState>(() =>
    campaignHeaderPropsToState(defaultValue || valueProps || {})
  )
  const [openFilter, setOpenFilter] = useState(false)
  const [selectedTemplate, setSelectedTemplate] =
    useState<SelectTemplateItem | null>(null)
  const [isOpenPopupSelectTemplate, setIsOpenPopupSelectTemplate] =
    useState(false)

  const isSearch = checkAuth(
    { permissions: [PERMISSIONS.campaign_search] },
    userAuth?.auth || {}
  )

  const itemsCreateCampaign = useMemo(() => {
    return menuCreateCampaign.map((item) => {
      const disabled = !checkAuth(
        { permissions: item.permissions },
        userAuth?.auth || {}
      )
      return {
        ...item,
        disabled
      }
    })
  }, [userAuth?.auth])

  const onChange = (key: keyof CampaignHeaderValue | string) => {
    return (val: any) => {
      const newValue = {
        ...value,
        [key]: val
      }
      setValue(newValue)

      const isColumnFieldUpdated =
        JSON.stringify(newValue.columns?.sort()) !==
        JSON.stringify(value.columns?.sort())

      if (isColumnFieldUpdated) {
        onChangeProps?.(campaignHeaderStateToProps(newValue))
        return
      }

      const isSearchFieldUpdated = value.search !== newValue.search
      if (debouceRef.current) clearTimeout(debouceRef.current)
      if (isSearchFieldUpdated) {
        debouceRef.current = setTimeout(() => {
          onChangeProps?.(campaignHeaderStateToProps(newValue))
        }, 300)
      }
    }
  }

  const onChangeTab = useCallback(
    (val: string) => {
      console.log('** RYAN CampaignHeader.tsx 115 value : ', value)
      const newValue = {
        ...value
      }
      delete newValue.template_types
      if (val === 'automation') {
        newValue.type = val
        delete newValue.template_type
      } else {
        newValue.template_type = val
        delete newValue.type
      }

      if (val === 'sms') {
        delete newValue.type
        newValue.template_type = val
        newValue.template_types = [TEMPLATE_TYPE.VNPT, TEMPLATE_TYPE.TWILIO]
      }
      setValue(newValue)
      onChangeProps?.(campaignHeaderStateToProps(newValue))
    },
    [onChangeProps, value]
  )

  const onTransitionEnd: TransitionEventHandler<HTMLDivElement> = (e) => {
    onTransitionEndProps?.(e)
  }

  const onSubmitFilter = useCallback(() => {
    onChangeProps?.(campaignHeaderStateToProps(value))
  }, [onChangeProps, value])

  const onResetFilter = useCallback(() => {
    setOpenFilter(false)
    const valueReset = {
      ...value,
      ...resetFilterCampaignParams
    }
    setValue(valueReset)
    onChangeProps?.(campaignHeaderStateToProps(valueReset))
  }, [onChangeProps, value])

  useEffect(() => {
    const value = campaignHeaderPropsToState(valueProps || {})
    setValue(value)
  }, [valueProps])

  return (
    <StyledContainerHeader onTransitionEnd={onTransitionEnd}>
      <Col span={12} className="header--left">
        <Tabs
          items={tabCampaign}
          activeKey={value.template_type || value.type}
          onChange={onChangeTab}
        />
      </Col>
      <Col span={12} className="header--right">
        <StyledCampaignTableSpace size={16} className="Table--right">
          <InputSearch
            disabled={!isSearch}
            value={value.search}
            onSearch={onChange('search')}
            allowClear={true}
            placeholder="Search campaign"
          />
          <Button
            disabled={!isSearch}
            type={openFilter ? 'primary' : 'ghost'}
            icon={<Icon component={Filter} />}
            className={openFilter ? 'active-filter' : ''}
            onClick={() => setOpenFilter(true)}>
            Filter
          </Button>
          <DropdownCheckbox
            title="Edit column"
            description="You can display columns on Campaign"
            options={OPTION_FILTER_COLUMNS}
            value={value.columns}
            onOk={onChange('columns')}>
            <Button
              type="ghost"
              icon={<Icon component={Column} />}
              className="btnColumn"
            />
          </DropdownCheckbox>
          <StyledCampaignButton
            icon={<BarChartOutlined />}
            onClick={() => navigate(menuRoutes.campaign_messaging_insights)}
            type="ghost"
            className="btn--insight"
          />
          <StyledCampaignButton
            icon={<Plus />}
            type="primary"
            onClick={() => {
              setIsOpenPopupSelectTemplate(true)
              setSelectedTemplate(itemsCreateCampaign[0])
            }}
            className="btn--create">
            New Campaign
          </StyledCampaignButton>
          {/* <Dropdown
            trigger={['click']}
            placement="bottomRight"
            menu={{
              className: 'dropdown_menu',
              items: itemsCreateCampaign,
              onClick(item) {
                plausibleAPI.trackEvent('campaign_create', {
                  props: {
                    campaign_type: item.key.split('/')[2],
                    uid: userAuth?.id || '',
                    un: userAuth?.name || '',
                    ue: userAuth?.email || ''
                  }
                })
                navigate(item.key)
              }
            }}>
          
          </Dropdown> */}
        </StyledCampaignTableSpace>
      </Col>
      <DrawerFilter
        title="Filter"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        width={360}
        mask={false}
        onReset={onResetFilter}
        onSubmit={onSubmitFilter}>
        <StyleWrapperItem>
          <StyleLabel>Site</StyleLabel>
          <SelectSite
            value={value.site_id}
            onChange={onChange('site_id')}
            placeholder="- Select site -"
            allowClear
          />
        </StyleWrapperItem>

        <StyleWrapperItem>
          <StyleLabel>Status</StyleLabel>
          <Checkbox.Group
            value={value.status}
            onChange={onChange('status')}
            options={OPTION_FILTER_STATUS}
            className="vertical-options"
          />
        </StyleWrapperItem>

        <StyleWrapperItem>
          <StyleLabel>Sent time</StyleLabel>
          <DatePicker.RangePicker
            value={value.send_time || null}
            onChange={onChange('send_time')}
            placeholder={['Sent time from', 'Sent time to']}
            disabledDate={(current) => {
              return current && current > dayjs()
            }}
          />
        </StyleWrapperItem>
        <StyleWrapperItem>
          <StyleLabel>Created Date</StyleLabel>
          <DatePicker.RangePicker
            value={value.created || null}
            onChange={onChange('created')}
            placeholder={['Created from', 'Created to']}
            disabledDate={(current) => {
              return current && current > dayjs()
            }}
          />
        </StyleWrapperItem>
        <StyleWrapperItem>
          <StyleLabel>Approach</StyleLabel>
          <Select
            value={value.approach}
            onChange={onChange('approach')}
            options={OPTION_FILTER_APPROACH}
            className="full-width"
            placeholder="Approach"
            allowClear
          />
        </StyleWrapperItem>
      </DrawerFilter>
      <StyledModal
        width={709}
        open={isOpenPopupSelectTemplate}
        okText="Create Campaign"
        onCancel={() => {
          setIsOpenPopupSelectTemplate(false)
          setSelectedTemplate(null)
        }}
        onOk={() => {
          if (selectedTemplate) {
            plausibleAPI.trackEvent('campaign_create', {
              props: {
                campaign_type: selectedTemplate.key.split('/')[2],
                uid: userAuth?.id || '',
                un: userAuth?.name || '',
                ue: userAuth?.email || ''
              }
            })
            navigate(selectedTemplate.key)
          }
        }}>
        <h2 className="pt-2 pb-4 text-[22px] leading-[32px] tracking-[-0.6px] font-semibold text-[##262626]">
          New Campaign
        </h2>
        <p className="text-base text-[#595959]">
          Select the campaign you want to create...
        </p>
        <Box className="flex flex-row gap-4">
          <Box className="flex flex-col flex-1 gap-1">
            {itemsCreateCampaign.map((item) => {
              const isActive = item === selectedTemplate
              return (
                <Box
                  key={item.key}
                  onClick={() => setSelectedTemplate(item)}
                  className={cls(
                    'flex flex-row gap-4 items-center p-2 border border-solid rounded-[12px] cursor-pointer',
                    {
                      'border-[#F7F9FC]': !isActive,
                      'border-[#BCDEFF]': isActive
                    }
                  )}>
                  <Box className={cls(item.iconBg, 'p-2 rounded-[8px]')}>
                    {item.icon}
                  </Box>
                  <p className="m-0 text-[#111111]">{item.label}</p>
                  {isActive && (
                    <CheckedCircle className="text-[#2D87F3] w-[19px] h-[19px] ml-auto mr-1" />
                  )}
                </Box>
              )
            })}
          </Box>
          <Box className="flex-1">{selectedTemplate?.thumbnail}</Box>
        </Box>
      </StyledModal>
    </StyledContainerHeader>
  )
}
