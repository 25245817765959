import { Space } from 'antd'
import styled from 'styled-components'

export const StyleContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin 0 auto;
  margin-top: 30px;
`

export const StyleCard = styled.div`
  background-color: ${({ theme }) => theme?.colors?.white};
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
`

export const StyleText = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme?.colors?.gray400};
  margin-top: 8px;
`

export const StyleTextBtn = styled.div`
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  text-decoration: underline;
`

export const StyleWrapperFields = styled.div`
  display: flex;
  gap: 15px;

  .relation-condition {
    margin-top: 5px;
  }
`

export const StyleGroupCondition = styled.div`
  &.wrapper-form-list {
    .ant-select {
      .ant-select-selector {
        height: 32px;
        input {
          height: 30px;
        }
        .ant-select-selection-item {
          line-height: 30px;
        }

        .ant-select-selection-placeholder {
          line-height: 30px;
        }
      }
      &.ant-select-multiple {
        .ant-select-selector {
          padding: 0px 10px;
          height: auto;

          .ant-select-selection-item {
            line-height: 24px;
          }
        }
      }
    }
    .ant-input,
    .ant-input-number-input,
    .ant-picker {
      padding: 4px 10px;
      height: 30px;
    }
  }
`

export const StyleHorizantalField = styled.div`
  margin-right: 15px;
  display: flex;
  gap: 15px;

  &.full-width {
    flex: 0 0 100%;
    width: 100%;
  }

  &:last-child {
    margin-right: 0;
  }
  .ant-form-item {
    .ant-row {
      flex-direction: row;
      align-items: flex-start;
      flex-flow: nowrap;
      gap: 15px;

      .ant-form-item-label {
        padding: 0;
        overflow: inherit;
        white-space: nowrap;
        display: flex;
        height: 40px;

        > label {
          color: ${({ theme }) => theme.colors.gray800};
          font-size: 14px;
          font-weight: 400;

          &:before {
            display: none;
          }
        }
      }
    }

    &.time-field {
      .ant-form-item-control {
        width: 150px;
      }
    }

    &.select-field,
    &.item-field {
      .ant-form-item-control {
        min-width: 150px;
        max-width: 250px;

        .ant-select-selection-overflow {
          flex-wrap: wrap;
        }
      }
    }
  }
`

export const StyleHeaderGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyleConditionActions = styled(Space)`
  min-width: 120px;
  justify-content: flex-end;
  margin: 0 24px;
  align-items: flex-start;
`

export const StyleConditionFields = styled.div`
  min-width: 0;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;

  &.condition-margin--20 {
    margin-left: 20px;
  }

  &.condition-margin--40 {
    margin-left: 40px;
  }
`

export const StyleConditionList = styled.div`
  flex: 1;
`

export const StyleConditionRow = styled.div`
  display: flex;
  flex: 1;

  &:last-child {
    border: none;
  }
`
