import { ConditionBaseProps } from '..'
import { StyledConditionSelect } from '../index.styled'
import { CheckboxOptionType, SelectProps } from 'antd'
import cn from 'classnames'
import { OptionType } from 'interfaces'
import { useEffect, useState } from 'react'
import { segmentConditonBuilderServices } from 'services/segmentBuilder'

export type ConditionApiComponentProps = Pick<
  SelectProps,
  'optionLabelProp' | 'placeholder' | 'allowClear' | 'onBlur'
> &
  ConditionBaseProps<string | number | boolean | undefined> & {
    options?: OptionType[]
    pathUrl?: string
    site?: string
    type?: string
  }
export const ConditionApiComponent = (props: ConditionApiComponentProps) => {
  const {
    className,
    placeholder,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    pathUrl,
    site,
    type,
    ...restProps
  } = props
  const [value, setValue] = useState<string | number | boolean | undefined>(
    defaultValue || valueProps
  )
  const [options, setOptions] = useState<CheckboxOptionType[]>([])

  const onChange = (val: unknown) => {
    const newValue = val as string | number | boolean | undefined
    setValue(newValue)
    onChangeProps && onChangeProps(newValue)
  }

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  useEffect(() => {
    if (!pathUrl) {
      return
    }
    ;(async function () {
      try {
        const resBody = {
          site: site ? site : ''
        }
        const res = await segmentConditonBuilderServices.fetchApiConditionField(
          pathUrl,
          resBody
        )
        if (!res.data) {
          throw new Error('')
        }
        const formatOptions = res.data.data.map((item: string) => {
          return {
            label: item,
            value: item
          }
        })
        setOptions(formatOptions)
      } catch (error) {}
    })()
  }, [pathUrl, site])

  return (
    <StyledConditionSelect
      {...restProps}
      options={options}
      value={value}
      onChange={onChange}
      className={cn(className, 'full--width')}
      placeholder={placeholder}
      mode={type === 'MANY' ? 'multiple' : undefined}
      maxTagCount={10}
    />
  )
}
