export const CAMPAIGN_STATUS = {
  ready: 'Ready',
  done: 'Done',
  running: 'Running',
  schedule: 'Schedule',
  draft: 'Draft',
  cancel: 'Cancel',
  fail: 'Failed',
  approve: 'Approve',
  waiting_approve: 'Waiting Approve'
}

export const CAMPAIGN_WEEKLY_OPTIONS = [
  {
    label: 'Mon',
    value: 'Mon',
    key: 1
  },
  {
    label: 'Tue',
    value: 'Tue',
    key: 2
  },
  {
    label: 'Wed',
    value: 'Wed',
    key: 3
  },
  {
    label: 'Thu',
    value: 'Thu',
    key: 4
  },
  {
    label: 'Fri',
    value: 'Fri',
    key: 5
  },
  {
    label: 'Sat',
    value: 'Sat',
    key: 6
  },
  {
    label: 'Sun',
    value: 'Sun',
    key: 0
  }
]

export const CAMPAIGN_APPROACH_OPTIONS = [
  {
    value: 1,
    label: 'Blast',
    description:
      'Sent to a list of users at one time (e.g., a weekly newsletter)'
  },
  {
    value: 2,
    label: 'Triggered',
    description:
      'Sent after a triggering event has occurred (e.g., an order confirmation email)'
  }
]

export const CAMPAIGN_TARGET_AUDIENCE_OPTIONS = [
  {
    value: 'segment',
    label: 'Segment',
    description:
      'Target the users based on their existing attributes or previous interactions you made in Segmentation page'
  },
  {
    value: 'broadcast',
    label: 'Broadcast',
    description:
      'Broadcasts are sent to multiple people at once, at a time you specify'
  }
]

export const CAMPAIGN_TIMEFRAME_OPTIONS = [
  { value: 'manual', label: 'Start now / On going to' },
  { value: 'schedule', label: 'Select range' }
]

export const KEY_SITES_LIST = [
  {
    key: 'hellobacsi.com',
    fromEmail: 'hello@hellobacsi.com',
    label: 'Hellobacsi'
  },
  {
    key: 'www.marrybaby.vn',
    fromEmail: 'hello@marrybaby.vn',
    label: 'Marrybaby'
  },
  {
    key: 'hellosehat.com',
    fromEmail: 'hello@hellosehat.com',
    label: 'Hello Sehat'
  },
  {
    key: 'hellokhunmor.com',
    fromEmail: 'hello@hellokhunmor.com',
    label: 'Hello Khunmor'
  },
  {
    key: 'hellodoktor.com',
    fromEmail: 'hello@hellodoktor.com',
    label: 'Hello Doktor'
  },
  {
    key: 'hellodoctor.com.ph',
    fromEmail: 'hello@hellodoctor.com.ph',
    label: 'Hello Doctor'
  },
  {
    key: 'hellodoctor.com.ph/fil',
    fromEmail: 'hello@hellodoctor.com.ph/fil',
    label: 'Hello Doctor Fil'
  },
  {
    key: 'hellokrupet.com',
    fromEmail: 'hello@hellokrupet.com',
    label: 'Hello Krupet'
  },
  {
    key: 'helloyishi.com.tw',
    fromEmail: 'hello@helloyishi.com.tw',
    label: 'Hello Yishi'
  },
  {
    key: 'hellosayarwon.com',
    fromEmail: 'hello@hellosayarwon.com',
    label: 'Hello Sayarwon'
  },
  {
    key: 'helloswasthya.com',
    fromEmail: 'hello@helloswasthya.com',
    label: 'Hello Swasthya'
  },
  {
    key: 'hellohealthgroup.com',
    fromEmail: 'all@hellohealthgroup.com',
    label: 'Hello Health Group'
  }
]

export const HHG_FROM_EMAILS = [
  'newsletter@hellobacsi.com',
  'all@hellohealthgroup.com',
  'doan.le@hellohealthgroup.com',
  'product@hellohealthgroup.com',
  'no-reply@hellohealthgroup.com',
  'admin@hellohealthgroup.com'
]
