import { Checkbox, CheckboxProps, Row } from 'antd'
import styled from 'styled-components'

export const StyleContainer = styled.div`
  margin-bottom: 20px;
`

export const StyleWrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyleSelectAll = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme?.colors?.gray500};
  cursor: pointer;
`

export const StyleGroupTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: ${({ theme }) => theme?.colors?.gray500};
`

export const StyleRowOption = styled(Row)`
  margin: 10px 0;
`

export const StyleCheckbox = styled(Checkbox)<CheckboxProps>`
  .ant-checkbox {
    + span {
      padding-right: 0;
    }
  }
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border: 2px solid ${({ theme }) => theme?.colors?.gray300};
    border-radius: 2px;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border: 2px solid ${({ theme }) => theme?.colors?.blue600};
    }
  }
`

export const StyleOptionLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme?.colors?.gray800};
`
