import { FormNamePath } from 'interfaces'

export type { FormNamePath }
export function getFormNamePath(
  name?: FormNamePath,
  namePath?: FormNamePath
): (string | number)[] {
  const newPath: (string | number)[] = []

  if (typeof name === 'string' || typeof name === 'number') {
    newPath.unshift(name)
  }
  if (Array.isArray(name)) {
    newPath.unshift(...name)
  }

  if (typeof namePath === 'string' || typeof namePath === 'number') {
    newPath.push(namePath)
  }
  if (Array.isArray(namePath)) {
    newPath.push(...namePath)
  }

  return newPath
}
