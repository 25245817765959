import {
  FORMAT_DATE_DAYJS_API,
  FORMAT_DATE_TIME_DAYJS,
  FORMAT_TIME
} from 'constants/common'
import dayjs from 'dayjs'
import { OptionTypeSite } from 'interfaces'
import { operatorKeyEvent, operatorLabel } from 'interfaces/condition'

export const formatOptions = (data: any) => {
  return data.map((record: any) => {
    return {
      label: record.name,
      value: record.id
    }
  })
}

export const formatOptionsSites = (data: OptionTypeSite[]) => {
  return data.map((record: OptionTypeSite) => {
    return {
      label: record.label,
      value: record.value
    }
  })
}

export const formatTemplateOptions = (data: any) => {
  return data.map((record: any) => {
    return {
      label: `${record.template.name} - Created by: ${record.template.created_by}`,
      value: record.template_id
    }
  })
}

export const formatFieldsOptions = (data: any) => {
  return data.map((record: any) => {
    return {
      label: record.name,
      value: record.name,
      type: record.type
    }
  })
}

export const formatOperatorOptions = (data: any) => {
  return data.map((item: string) => {
    const indexOf = Object.values(operatorKeyEvent).indexOf(
      item as unknown as operatorKeyEvent
    )
    const key = Object.keys(operatorKeyEvent)[indexOf]
    const labelOperator = operatorLabel[key as keyof typeof operatorLabel]
    return {
      label: labelOperator,
      value: item
    }
  })
}

export const formatConditionCreate = (groups: any[]) => {
  const eventConditions = [] as any
  groups.forEach((group: any) => {
    if (group.conditions) {
      group.conditions.forEach((condition: any) => {
        const { fields = [] } = condition
        const conditonFields = fields.map((field: any) => {
          return {
            field: field.fieldKey,
            operator: field.fieldOperator,
            value: field.fieldValue
          }
        })
        const submittedFrom = condition.conditionRangeDate
          ? dayjs(condition.conditionRangeDate[0]).format(FORMAT_DATE_DAYJS_API)
          : ''
        const submittedTo = condition.conditionRangeDate
          ? dayjs(condition.conditionRangeDate[1]).format(FORMAT_DATE_DAYJS_API)
          : ''
        eventConditions.push({
          event_id: condition.conditionKey,
          submitted_from: submittedFrom,
          submitted_to: submittedTo,
          conditions: conditonFields,
          operator: group?.relationGroup || 'AND'
        })
      })
    }
  })
  return eventConditions
}

export const formatAutoCampaignCreate = (dataForm: any) => {
  const { templates = [], groups = [] } = dataForm
  const eventConditions = formatConditionCreate(groups)
  const templateConditions = templates.map((item: any, index: number) => {
    return {
      template_id: item.template,
      order: index
    }
  })

  const startDate = dayjs(dataForm.start_date).format(FORMAT_DATE_DAYJS_API)
  const endDate = dayjs(dataForm.end_date).format(FORMAT_DATE_DAYJS_API)
  const startTime = dayjs(dataForm.start_time).format(FORMAT_TIME)
  const endTime = dayjs(dataForm.end_time).format(FORMAT_TIME)
  const triggerTime = dataForm.trigger_time
    ? dayjs(dataForm.trigger_time).format(FORMAT_DATE_TIME_DAYJS)
    : null

  const recurringConfig = dataForm.recurring
    ? {
        is_recurring: dataForm.recurring,
        recurring_cycle: dataForm.replication,
        days_of_week: dataForm.schedule_on,
        trigger_time: triggerTime
      }
    : {}

  return {
    name: dataForm.name,
    description: dataForm.description,
    approach: 2,
    time_zone: dataForm.time_zone,
    segments_ids: [],
    type: 'automation',
    template_id: null,
    template_type: '',
    client_ids: [],
    site_ids: [dataForm.sites],
    flow_id: null,
    start_time: `${startDate} ${startTime}` || '',
    end_time: `${endDate} ${endTime}` || '',
    events_conditions: eventConditions,
    template_conditions: templateConditions,
    ...recurringConfig
  }
}

export const formatValueFormCondition = (eventsConditions: any) => {
  return eventsConditions.map((eventCondition: any) => {
    const fieldsCondition =
      eventCondition.conditions?.map((field: any) => {
        return {
          fieldKey: field.field,
          fieldOperator: field.operator,
          fieldValue: field.value
        }
      }) || []
    const startTime = eventCondition.submitted_from
      ? dayjs(eventCondition.submitted_from)
      : null
    const endTime = eventCondition.submitted_to
      ? dayjs(eventCondition.submitted_to)
      : null
    return {
      conditionKey: eventCondition.event.id,
      conditionRangeDate: [startTime, endTime],
      conditionType: 'event',
      conditonPerform: 'has',
      fields: fieldsCondition,
      relationField: 'AND'
    }
  })
}

export const formatValueFormCampaign = (campaign: any) => {
  const startDateTime = campaign.start_datetime
    ? dayjs(campaign.start_datetime)
    : null
  const endDateTime = campaign.end_datetime
    ? dayjs(campaign.end_datetime)
    : null
  const triggerTime = campaign.trigger_time
    ? dayjs(campaign.trigger_time)
    : null
  const templateConditions = campaign.template_conditions.map(
    (condition: any) => {
      return {
        template: condition.template_id
      }
    }
  )
  const eventConditions = formatValueFormCondition(campaign.events_conditions)
  return {
    name: campaign.name,
    description: campaign.description,
    approach: campaign.approach,
    time_zone: campaign.time_zone,
    sites: campaign.sites[0] || null,
    start_date: startDateTime,
    start_time: startDateTime,
    end_date: endDateTime,
    end_time: endDateTime,
    recurring: campaign.is_recurring,
    replication: campaign.recurring_cycle,
    schedule_on: campaign.days_of_week,
    trigger_time: triggerTime,
    status: campaign.status,
    groups: [
      {
        conditions: eventConditions,
        relationGroup: 'AND'
      }
    ],
    templates: templateConditions
  }
}
