import Icon from '@ant-design/icons'
import Modal, { ModalProps } from 'antd/lib/modal'
import { Close, Warning } from 'icons'
import { ReactNode } from 'react'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .ant-modal-close {
    top: 15px;
    right: 20px;
    .ant-modal-close-x {
      width: 24px;
      height: 24px;
    }
  }
  .ant-modal-body {
    padding: 32px;
    text-align: center;
  }
  .ant-modal-footer {
    display: flex;
    gap: 12px;
    padding: 0 32px 32px;
    border-top: none;
  }
`
export type ModalConfirmationProps = Pick<
  ModalProps,
  | 'open'
  | 'title'
  | 'onCancel'
  | 'okButtonProps'
  | 'cancelButtonProps'
  | 'okText'
  | 'cancelText'
> & {
  onOk?: () => void
  highlight?: ReactNode
  description?: ReactNode
}
export const ModalConfirmation = (props: ModalConfirmationProps) => {
  const {
    open,
    title,
    highlight,
    description,
    onCancel,
    onOk,
    okButtonProps,
    okText,
    cancelButtonProps,
    cancelText
  } = props
  const handleOk = async () => {
    await onOk?.()
  }
  return (
    <StyledModal
      width={480}
      open={open}
      title={false}
      destroyOnClose
      onOk={handleOk}
      okText={okText || 'Confirm'}
      okButtonProps={{
        block: true,
        danger: true,
        ghost: true,
        ...okButtonProps
      }}
      onCancel={onCancel}
      cancelButtonProps={{ block: true, ...cancelButtonProps }}
      cancelText={cancelText}
      closeIcon={<Icon component={Close} />}>
      <Icon
        component={Warning}
        className="analyticWidgetMuuriRemoveModal--icon text-[80px] leading-none mb-2"
      />
      <h3 className="analyticWidgetMuuriRemoveModal--title leading-7 text-[22px] text-gray800 font-bold mb-2">
        {title}
      </h3>
      {!!highlight && (
        <div className="analyticWidgetMuuriRemoveModal--highlight mb-2 text-[16px] text-gray600 font-semibold">
          {highlight}
        </div>
      )}
      <div className="analyticWidgetMuuriRemoveModal--description">
        {description}
      </div>
    </StyledModal>
  )
}
