import { Checkbox, DatePicker, Input, InputNumber, Radio, Select } from 'antd'
import { SelectCheckbox } from 'components/atoms/select'
import { SliderRangeInput } from 'components/atoms/slider'
import styled, { css } from 'styled-components'

export const cssSelect = css`
  min-width: 120px;
  &.full--width {
    flex: 1;

    // For slider
    .ant-slider {
      flex: 1;
    }
  }
`

export const cssSubTitle = css`
  color: ${({ theme }) => theme?.colors?.neutral500 || '#9AA2AC'};
  font-weight: 700 !important;
`

export const StyledConditionRadio = styled(Radio)`
  min-width: 196px;
  padding: 8px;
  margin: 0;
  .ant-radio {
    top: 0.3em;
  }
`

export const StyledConditionRadioGroup = styled(Radio.Group)`
  margin: -8px;
  ${StyledConditionRadio} {
    + ${StyledConditionRadio} {
      margin-left: 0;
    }
    &:last-child {
      width: auto;
    }
  }
`

export const StyledConditionCheckbox = styled(Checkbox)`
  min-width: 196px;
  padding: 8px;
  margin: 0;
`

export const StyledConditionCheckboxGroup = styled(Checkbox.Group)`
  margin: -8px;
  ${StyledConditionCheckbox} {
    + ${StyledConditionCheckbox} {
      margin-left: 0;
    }
    &:last-child {
      width: auto;
    }
  }
`

export const StyledConditionSelect = styled(Select)`
  ${cssSelect}

  .ant-select-selection-overflow {
    flex-wrap: wrap !important;
  }
`

export const StyledConditionSelectCheckbox = styled(SelectCheckbox)`
  ${cssSelect}
`

export const StyledConditionInput = styled(Input)`
  flex: 1;
`

export const StyledConditionDatePicker = styled(DatePicker)`
  ${cssSelect}
`

export const StyledConditionInputNumber = styled(InputNumber)`
  ${cssSelect}
`

export const StyledConditionSliderRange = styled(SliderRangeInput)`
  ${cssSelect}
`

const cssContent = css`
  ${StyledConditionSelect} {
    + ${StyledConditionInput}, + ${StyledConditionInputNumber} {
      flex: 1;
      margin-left: 16px;
    }
  }
`

export const StyledConditionOperator = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  ${cssContent}

  .condition-field {
    padding: 0;
    display: flex;
    align-items: center;
    ${cssSelect}
  }
`

export const StyledConditionContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  ${cssContent}
`

export const StyledConditionLabel = styled.div`
  width: 33%;
  max-width: 360px;
  color: ${({ theme }) => theme?.colors?.gray800 || '#262626'};
  font-weight: 600;
  &.subTitle {
    ${cssSubTitle}
  }
`

export const StyledCondition = styled.div`
  display: flex;
  gap: 20px;
  &.hasChild {
    ${StyledConditionLabel} {
      max-width: 200px;
    }
    > ${StyledConditionContent} {
      flex-wrap: wrap;
      padding-left: 8px;
    }
  }

  &.isChild {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    ${StyledConditionLabel} {
      max-width: 160px;
      font-weight: 400;
    }
    ${StyledConditionContent} {
      padding-left: 16px;
    }
    + .isChild {
      padding-top: 16px;
    }

    &.subTitleLabel {
      ${StyledConditionLabel} {
        ${cssSubTitle}
      }
    }
  }
`
