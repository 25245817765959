import {
  CAMPAIGN_MESSAGING_INSIGHT_TAB,
  getCampaignMessagingInsightFilter,
  getCampaignMessagingInsightMetricKey,
  postInsightExport
} from './CampaignMessagingInsight.helpers'
import {
  StyledTab,
  StyledTabContent,
  StyledTabContentInner,
  StyledTabContentWrapper
} from './CampaignMessagingInsight.styled'
import {
  CampaignMessagingInsightFilter,
  CampaignMessagingInsightFilterValue
} from './CampaignMessagingInsightFilter'
import {
  CampaignMessagingInsightTabAudience,
  CampaignMessagingInsightTabAudienceRef
} from './CampaignMessagingInsightTabAudience'
import CampaignMessagingInsightTabDevices, {
  CampaignMessagingInsightTabDevicesRef
} from './CampaignMessagingInsightTabDevices'
import {
  CampaignMessagingInsightTabOverview,
  CampaignMessagingInsightTabOverviewRef
} from './CampaignMessagingInsightTabOverview'
import {
  CampaignMessagingInsightTabPerformance,
  CampaignMessagingInsightTabPerformanceRef
} from './CampaignMessagingInsightTabPerformance'
import {
  CampaignMessagingInsightTabTimeSeries,
  CampaignMessagingInsightTabTimeSeriesRef
} from './CampaignMessagingInsightTimeSeries'
import Icon from '@ant-design/icons'
import { Button, notification } from 'antd'
import { ResultUnderContruction } from 'components/atoms/result'
import dayjs from 'dayjs'
import { Download } from 'icons'
import { useCallback, useRef, useState } from 'react'
import { downloadFile } from 'utils'

export {
  CAMPAIGN_MESSAGING_INSIGHT_TAB,
  getCampaignMessagingInsightFilter,
  getCampaignMessagingInsightMetricKey
}
export const CampaignMessagingInsight = () => {
  const formFilter = useRef(null)
  const [tab, setTab] = useState(CAMPAIGN_MESSAGING_INSIGHT_TAB.OVERVIEW)
  const [params, setParams] = useState<CampaignMessagingInsightFilterValue>()
  const [loadingDownload, setLoadingDownload] = useState(false)

  const overviewRef = useRef<CampaignMessagingInsightTabOverviewRef>(null)
  const performanceRef = useRef<CampaignMessagingInsightTabPerformanceRef>(null)
  const audienceRef = useRef<CampaignMessagingInsightTabAudienceRef>(null)
  const devicesRef = useRef<CampaignMessagingInsightTabDevicesRef>(null)
  const timeSeriesRef = useRef<CampaignMessagingInsightTabTimeSeriesRef>(null)

  const handleFinishFilter = useCallback(
    (values: CampaignMessagingInsightFilterValue, currentTab?: string) => {
      setParams(values)
      const activeTab = currentTab || tab
      switch (activeTab) {
        case CAMPAIGN_MESSAGING_INSIGHT_TAB.OVERVIEW: {
          overviewRef.current?.refresh?.(values)
          break
        }
        case CAMPAIGN_MESSAGING_INSIGHT_TAB.PERFORMANCE: {
          performanceRef?.current?.refresh?.(values)
          break
        }
        case CAMPAIGN_MESSAGING_INSIGHT_TAB.AUDIENCE: {
          audienceRef?.current?.refresh?.(values)
          break
        }
        case CAMPAIGN_MESSAGING_INSIGHT_TAB.DEVICES: {
          devicesRef?.current?.refresh?.(values)
          break
        }
        case CAMPAIGN_MESSAGING_INSIGHT_TAB.TIME_SERIES: {
          timeSeriesRef?.current?.refresh?.(values)
          break
        }
      }
    },
    [tab]
  )

  const handleChangeTab = useCallback(
    (tab: string) => {
      setTab(tab)
      handleFinishFilter(params as CampaignMessagingInsightFilterValue, tab)
    },
    [handleFinishFilter, params]
  )

  const handleDownload = useCallback(async () => {
    setLoadingDownload(true)
    const { data, errors } = await postInsightExport(params)
    if (errors?.length) {
      notification.error({ message: 'Your download has something went wrong' })
      setLoadingDownload(false)
      return
    }
    setLoadingDownload(false)
    downloadFile(data, `report-analytics-${dayjs().valueOf()}.xlsx`)
  }, [params])

  return (
    <div className="flex h-full">
      <CampaignMessagingInsightFilter
        ref={formFilter}
        tab={tab}
        onFinish={handleFinishFilter}
        className="messagingInsighsTab_form w-[405px] bg-white border-0 border-solid border-r border-gray-100 "
      />
      <div className="messagingInsighsTab_container flex-1 min-w-0">
        <StyledTab
          activeKey={tab}
          onChange={handleChangeTab}
          tabBarExtraContent={
            <Button
              size="small"
              type="link"
              icon={<Icon component={Download} />}
              disabled={!params?.campaigns?.length}
              onClick={handleDownload}
              loading={loadingDownload}
              className="p-0">
              Download
            </Button>
          }
          items={[
            {
              label: 'Overview',
              key: CAMPAIGN_MESSAGING_INSIGHT_TAB.OVERVIEW,
              children: (
                <StyledTabContent>
                  <StyledTabContentWrapper>
                    <CampaignMessagingInsightTabOverview
                      ref={overviewRef}
                      params={params}
                    />
                  </StyledTabContentWrapper>
                </StyledTabContent>
              )
            },
            {
              label: 'Performance',
              key: CAMPAIGN_MESSAGING_INSIGHT_TAB.PERFORMANCE,
              children: (
                <StyledTabContent className="overflowHidden">
                  <StyledTabContentWrapper style={{ paddingBottom: 16 }}>
                    <StyledTabContentInner>
                      <CampaignMessagingInsightTabPerformance
                        ref={performanceRef}
                        params={params}
                      />
                    </StyledTabContentInner>
                  </StyledTabContentWrapper>
                </StyledTabContent>
              )
            },
            {
              label: 'Audience',
              key: CAMPAIGN_MESSAGING_INSIGHT_TAB.AUDIENCE,
              children: (
                <StyledTabContent>
                  <StyledTabContentWrapper>
                    <CampaignMessagingInsightTabAudience
                      ref={audienceRef}
                      params={params}
                    />
                  </StyledTabContentWrapper>
                </StyledTabContent>
              )
            },
            {
              label: 'Time series',
              key: CAMPAIGN_MESSAGING_INSIGHT_TAB.TIME_SERIES,
              children: (
                <StyledTabContent>
                  <StyledTabContentWrapper>
                    <CampaignMessagingInsightTabTimeSeries
                      ref={timeSeriesRef}
                      params={params}
                    />
                  </StyledTabContentWrapper>
                </StyledTabContent>
              )
            },
            {
              label: 'Location',
              key: CAMPAIGN_MESSAGING_INSIGHT_TAB.LOCATION,
              children: (
                <StyledTabContent>
                  <StyledTabContentWrapper>
                    <ResultUnderContruction />
                  </StyledTabContentWrapper>
                </StyledTabContent>
              )
            },
            {
              label: 'Devices',
              key: CAMPAIGN_MESSAGING_INSIGHT_TAB.DEVICES,
              children: (
                <StyledTabContent>
                  <StyledTabContentWrapper>
                    <CampaignMessagingInsightTabDevices
                      ref={devicesRef}
                      params={params}
                    />
                  </StyledTabContentWrapper>
                </StyledTabContent>
              )
            }
          ]}
        />
      </div>
    </div>
  )
}
