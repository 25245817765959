import { Button } from 'antd'
import styled from 'styled-components'

export const StyledWrap = styled.div`
  // padding: 16px 24px;
  padding: 0 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.neutral50};
`

export const StyledRight = styled.div`
  gap: 16px;
  display: flex;
`

export const StyleButton = styled(Button)`
  display: flex;
  align-items: center;
  svg {
    margin-right: 7px;
  }
`
