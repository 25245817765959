import {
  StyledSwitch,
  StyledSwitchContainer,
  StyledSwitchLabel
} from './index.styled'
import { SwitchProps } from 'antd'
import { CSSProperties } from 'react'

export type SwitchLabelProps = SwitchProps & {
  placeholder?: string
  containerStyle?: CSSProperties
  containerClassname?: string
}
export const SwitchLabel = (props: SwitchLabelProps) => {
  const { placeholder, containerStyle, containerClassname, ...restProps } =
    props
  return (
    <StyledSwitchContainer
      style={containerStyle}
      className={containerClassname}>
      <StyledSwitch {...restProps} />
      {placeholder && (
        <StyledSwitchLabel className="label">{placeholder}</StyledSwitchLabel>
      )}
    </StyledSwitchContainer>
  )
}
