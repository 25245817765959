import {
  StyleDrodownWrapperButton,
  StyledDropdownButtonApply,
  StyledDropdownButtonCancel,
  StyledDropdownCheckbox,
  StyledDropdownCheckboxButton,
  StyledDropdownCheckboxDescription,
  StyledDropdownCheckboxFooter,
  StyledDropdownCheckboxGroup,
  StyledDropdownCheckboxHeader,
  StyledDropdownCheckboxTitle
} from './index.styled'
import Icon from '@ant-design/icons'
import { ButtonProps, Dropdown, DropdownProps } from 'antd'
import { CheckboxGroupProps } from 'antd/lib/checkbox/Group'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { ReactComponent as ChevronDownSvg } from 'icons/ChevronDown.svg'
import { FC, ReactNode, useEffect, useState } from 'react'

export interface DropdownCheckboxProps extends CheckboxGroupProps {
  placement?: DropdownProps['placement']
  visible?: boolean
  onVisible?: (visible: boolean) => void
  onOk?: (selected: CheckboxValueType[]) => void
  onCancel?: () => void
  placeholder?: string
  title?: string
  description?: string
  cancelText?: string
  okText?: string
  headerComponent?: ReactNode
  footerComponent?: (
    selected: CheckboxValueType[],
    setSeleted?: (visible: boolean) => void
  ) => ReactNode
  buttonProps?: Omit<ButtonProps, 'icon' | 'block' | 'children' | 'loading'>
}

export const DropdownCheckbox: FC<DropdownCheckboxProps> = ({
  placement,
  visible: visibleProps,
  onVisible: onVisibleProps,
  onOk,
  onCancel,
  onChange,
  value,
  placeholder,
  title,
  description,
  cancelText,
  okText,
  headerComponent,
  footerComponent,
  buttonProps,
  children,
  ...restProps
}) => {
  const [selected, setSeleted] = useState<any>(value)
  const [visible, setVisible] = useState(visibleProps)

  const handleVisible = (newVisible: boolean) => {
    if ((onOk || footerComponent) && !newVisible) {
      setSeleted(value)
    }
    if (typeof visibleProps !== 'undefined') {
      onVisibleProps && onVisibleProps(newVisible)
      return
    }
    setVisible(newVisible)
  }

  const handleOnChange = (value: CheckboxValueType[]) => {
    setSeleted(value)
    onChange && !onOk && onChange(value)
  }

  const handleOnOk = () => {
    setVisible(false)
    if (selected) {
      onOk && onOk(selected)
    }
  }

  const handleOnCancel = () => {
    setVisible(false)
    setSeleted(value)
    onCancel && onCancel()
  }

  useEffect(() => {
    setSeleted(value)
  }, [value])

  return (
    <Dropdown
      open={visible}
      onOpenChange={handleVisible}
      placement={placement}
      trigger={['click']}
      dropdownRender={() => (
        <StyledDropdownCheckbox>
          {/* Show headerComponent */}
          {headerComponent && (
            <StyledDropdownCheckboxHeader>
              {headerComponent}
            </StyledDropdownCheckboxHeader>
          )}
          {/* Show title or description when not have headerComponent */}
          {(title || description) && !headerComponent && (
            <StyledDropdownCheckboxHeader>
              <StyledDropdownCheckboxTitle>{title}</StyledDropdownCheckboxTitle>
              <StyledDropdownCheckboxDescription>
                {description}
              </StyledDropdownCheckboxDescription>
            </StyledDropdownCheckboxHeader>
          )}
          <StyledDropdownCheckboxGroup
            {...restProps}
            value={selected}
            onChange={handleOnChange}
          />

          {/* Show custom footerComponent */}
          {footerComponent && (
            <StyledDropdownCheckboxFooter>
              {footerComponent(selected, setVisible)}
            </StyledDropdownCheckboxFooter>
          )}

          {/* Show default footerComponent */}
          {onOk && !footerComponent && (
            <StyledDropdownCheckboxFooter>
              <StyleDrodownWrapperButton>
                <StyledDropdownButtonCancel onClick={handleOnCancel}>
                  {cancelText ? cancelText : 'Cancel'}
                </StyledDropdownButtonCancel>
                <StyledDropdownButtonApply onClick={handleOnOk} type="primary">
                  {okText ? okText : 'Apply'}
                </StyledDropdownButtonApply>
              </StyleDrodownWrapperButton>
            </StyledDropdownCheckboxFooter>
          )}
        </StyledDropdownCheckbox>
      )}>
      {children ? (
        children
      ) : (
        <StyledDropdownCheckboxButton
          block
          size="large"
          icon={<Icon component={ChevronDownSvg} />}
          {...buttonProps}>
          {placeholder || 'Select'}
        </StyledDropdownCheckboxButton>
      )}
    </Dropdown>
  )
}
