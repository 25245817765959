import {
  StyledButton,
  StyledDropdown,
  StyledHeader,
  StyledHeaderButtons,
  StyledHeaderSkeleton,
  StyledHeaderSteps,
  StyledHeaderTitle,
  StyledModal,
  StyledTitle
} from './CampaignStepHeader.styled'
import Icon from '@ant-design/icons'
import {
  ButtonProps,
  DropdownProps,
  ModalProps,
  StepProps,
  Steps,
  StepsProps
} from 'antd'
import classNames from 'classnames'
import { ChevronLeft, Kebab } from 'icons'

export const CAMPAIGN_STEP_KEYS = {
  overviews: 0,
  sites: 1,
  segments: 2,
  templates: 3
}
export const CAMPAIGN_STEP_ITEMS: Array<StepProps & { key: number }> = [
  { title: 'Settings', key: CAMPAIGN_STEP_KEYS.overviews },
  { title: 'Sites', key: CAMPAIGN_STEP_KEYS.sites },
  { title: 'Segments', key: CAMPAIGN_STEP_KEYS.segments },
  { title: 'Templates', key: CAMPAIGN_STEP_KEYS.templates }
]

export type CampaignStepHeaderProps = {
  loading?: boolean
  title?: string
  modalOpen?: boolean
  modalOnClose?: () => void
  modalOnOk?: ModalProps['onOk']
  modalOnCancel?: ModalProps['onCancel']
  stepCurrent?: StepsProps['current']
  stepItems?: StepsProps['items']
  stepOnChange?: StepsProps['onChange']
  isShowDropdown?: boolean
  dropdownMenuItems?: NonNullable<DropdownProps['menu']>['items']
  dropdownMenuOnClick?: NonNullable<DropdownProps['menu']>['onClick']
  onBack?: () => void
  onCancel?: () => void
  cancelText?: string
  cancelButtonProps?: Omit<ButtonProps, 'onClick' | 'children'>
  onOk?: () => void
  okText?: string
  okButtonProps?: Omit<ButtonProps, 'onClick' | 'children'>
}

export const CampaignStepHeader = (props: CampaignStepHeaderProps) => {
  const {
    loading,
    title,
    modalOpen,
    modalOnClose,
    modalOnOk,
    modalOnCancel,
    stepItems,
    stepCurrent,
    stepOnChange,
    isShowDropdown = true,
    dropdownMenuItems,
    dropdownMenuOnClick,
    onBack,
    onCancel,
    cancelText,
    cancelButtonProps,
    onOk,
    okText,
    okButtonProps
  } = props

  return (
    <StyledHeader>
      <StyledHeaderTitle>
        <StyledButton
          size="small"
          type="text"
          icon={<Icon component={ChevronLeft} />}
          className="btn_back"
          onClick={onBack}
        />

        <StyledTitle>
          {!!loading && (
            <StyledHeaderSkeleton
              active
              title={{ width: '100%' }}
              paragraph={{ rows: 0 }}
            />
          )}
          {!loading && (title || 'Add Campaign')}
        </StyledTitle>
      </StyledHeaderTitle>
      <StyledHeaderSteps>
        {!!loading && (
          <StyledHeaderSkeleton
            active
            title={{ width: '100%' }}
            paragraph={{ rows: 0 }}
          />
        )}
        {!loading && (
          <Steps
            responsive
            size="small"
            items={stepItems}
            current={stepCurrent}
            onChange={stepOnChange}
          />
        )}
      </StyledHeaderSteps>
      <StyledHeaderButtons>
        <StyledButton
          size="small"
          {...cancelButtonProps}
          loading={loading}
          onClick={onCancel}
          className={classNames(cancelButtonProps?.className, 'btn_cancel')}>
          {cancelText || 'Cancel'}
        </StyledButton>
        <StyledButton
          size="small"
          {...okButtonProps}
          loading={loading}
          onClick={onOk}
          type="primary"
          className={classNames(okButtonProps?.className, 'btn_ok')}>
          {okText || 'Save'}
        </StyledButton>
        {isShowDropdown &&
          !!dropdownMenuItems?.length &&
          !!dropdownMenuOnClick && (
            <StyledDropdown
              trigger={['click']}
              menu={{ items: dropdownMenuItems, onClick: dropdownMenuOnClick }}
              getPopupContainer={(html) => html}
              destroyPopupOnHide>
              <StyledButton
                loading={loading}
                size="small"
                type="text"
                icon={<Icon component={Kebab} />}
              />
            </StyledDropdown>
          )}
      </StyledHeaderButtons>
      <StyledModal
        open={modalOpen}
        title="Save before leaving?"
        okText="Save & exit"
        onOk={modalOnOk}
        onCancel={modalOnClose}
        cancelButtonProps={{
          onClick: modalOnCancel
        }}
        cancelText="Leave without saving">
        If you leave, any changes you’ve made will not be saved.
      </StyledModal>
    </StyledHeader>
  )
}
