import { Empty } from 'antd'
import cn from 'classnames'
import { Heatmap } from 'components/atoms/heatmap'
import { CSSProperties, useMemo } from 'react'

const HEATMAP_LABELS = {
  open: 'Open by Time of Day',
  click: 'Click by Time of Day',
  impression: 'Impression by Time of Day',
  read: 'Read by Time of Day'
}

export type WorkflowInsightTabHeatmapProps = {
  data?: Record<string, Record<string, number[]>>
  className?: string
  style?: CSSProperties
}

export const WorkflowInsightTabHeatmap = (
  props: WorkflowInsightTabHeatmapProps
) => {
  const { data: dataProps, className, style } = props

  const dataArray = useMemo(() => {
    const data = dataProps || {}
    return Object.keys(data)
      .map((key) => {
        if (key in HEATMAP_LABELS) {
          return {
            label: HEATMAP_LABELS[key as keyof typeof HEATMAP_LABELS],
            data: data[key as keyof typeof data] as Record<string, number[]>
          }
        }

        return
      })
      .filter(Boolean)
  }, [dataProps])

  console.log('** RYAN WorkflowInsightTabHeatmap.tsx 23 data : ', dataProps)

  return (
    <div className={cn('w-full', className)} style={style}>
      <div className="flex -m-2">
        {!dataArray.length && (
          <div className="w-full p-2">
            <Empty description="No data for this heatmap" className="py-20" />
          </div>
        )}
        {dataArray.map((d, idx) => {
          return (
            <div className="w-1/2 p-2" key={`${idx}`}>
              <div className="p-4 bg-white border border-solid border-neutral100 rounded-xl">
                <h2 className="title text-[18px] font-bold mb-1">{d?.label}</h2>
                <div className="body">
                  <Heatmap dataSource={(d?.data || {}) as any} />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
