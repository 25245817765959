import { Tabs } from 'antd'
import styled from 'styled-components'

export const StyledTabs = styled(Tabs)`
  display: inline-flex;
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-tab {
    color: ${({ theme }) => theme.colors.gray500};
    font-weight: 600;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 24px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
  }
  .ant-tabs-nav-operations,
  .ant-tabs-content-holder {
    display: none !important;
  }
`
