import { InsightOverviewCheckboxGroupOptions } from '../InsightOverviewCheckboxGroup'
import { theme } from 'constants/theme'
import { OptionType } from 'interfaces'

export const WORKFLOW_INSIGHT_KEYS = {
  delivery_rate: 'delivery_rate',
  click_rate: 'click_rate',
  impression_rate: 'impression_rate',
  open_rate: 'open_rate',
  click_through_rate: 'click_through_rate',
  CTOR: 'ctor',

  delivery: 'delivery',
  click: 'click',
  impression: 'impression',
  open: 'open',
  sends: 'sends',
  read: 'read'
}

export const WORKFLOW_INSIGHT_LABEL = {
  [WORKFLOW_INSIGHT_KEYS.delivery_rate]: 'Delivery Rate',
  [WORKFLOW_INSIGHT_KEYS.click_rate]: 'Click Rate',
  [WORKFLOW_INSIGHT_KEYS.impression_rate]: 'Impression Rate',
  [WORKFLOW_INSIGHT_KEYS.open_rate]: 'Open Rate',
  [WORKFLOW_INSIGHT_KEYS.click_through_rate]: 'CTR',
  [WORKFLOW_INSIGHT_KEYS.CTOR]: 'CTOR',

  [WORKFLOW_INSIGHT_KEYS.delivery]: 'Delivery',
  [WORKFLOW_INSIGHT_KEYS.click]: 'Click',
  [WORKFLOW_INSIGHT_KEYS.impression]: 'Impression',
  [WORKFLOW_INSIGHT_KEYS.open]: 'Open',
  [WORKFLOW_INSIGHT_KEYS.sends]: 'Sends',
  [WORKFLOW_INSIGHT_KEYS.read]: 'Read'
}

export const WORKFLOW_INSIGHT_OPTIONS: Record<
  string,
  OptionType<{ color?: string; unit?: string }>
> = {
  [WORKFLOW_INSIGHT_KEYS.delivery_rate]: {
    value: WORKFLOW_INSIGHT_KEYS.delivery_rate,
    label: WORKFLOW_INSIGHT_LABEL[WORKFLOW_INSIGHT_KEYS.delivery_rate],
    data: {
      color: theme.colors.primary,
      unit: '%'
    }
  },
  [WORKFLOW_INSIGHT_KEYS.click_rate]: {
    value: WORKFLOW_INSIGHT_KEYS.click_rate,
    label: WORKFLOW_INSIGHT_LABEL[WORKFLOW_INSIGHT_KEYS.click_rate],
    data: {
      color: theme.colors.yellow800,
      unit: '%'
    }
  },
  [WORKFLOW_INSIGHT_KEYS.impression_rate]: {
    value: WORKFLOW_INSIGHT_KEYS.impression_rate,
    label: WORKFLOW_INSIGHT_LABEL[WORKFLOW_INSIGHT_KEYS.impression_rate],
    data: {
      color: theme.colors.green800,
      unit: '%'
    }
  },
  [WORKFLOW_INSIGHT_KEYS.open_rate]: {
    value: WORKFLOW_INSIGHT_KEYS.open_rate,
    label: WORKFLOW_INSIGHT_LABEL[WORKFLOW_INSIGHT_KEYS.open_rate],
    data: {
      color: theme.colors.red800,
      unit: '%'
    }
  },
  [WORKFLOW_INSIGHT_KEYS.click_through_rate]: {
    value: WORKFLOW_INSIGHT_KEYS.click_through_rate,
    label: WORKFLOW_INSIGHT_LABEL[WORKFLOW_INSIGHT_KEYS.click_through_rate],
    data: {
      color: theme.colors.violet600,
      unit: '%'
    }
  },
  [WORKFLOW_INSIGHT_KEYS.CTOR]: {
    value: WORKFLOW_INSIGHT_KEYS.CTOR,
    label: WORKFLOW_INSIGHT_LABEL[WORKFLOW_INSIGHT_KEYS.CTOR],
    data: {
      color: theme.colors.pink600,
      unit: '%'
    }
  },
  [WORKFLOW_INSIGHT_KEYS.delivery]: {
    value: WORKFLOW_INSIGHT_KEYS.delivery,
    label: WORKFLOW_INSIGHT_LABEL[WORKFLOW_INSIGHT_KEYS.delivery],
    data: {
      color: theme.colors.primary
    }
  },
  [WORKFLOW_INSIGHT_KEYS.click]: {
    value: WORKFLOW_INSIGHT_KEYS.click,
    label: WORKFLOW_INSIGHT_LABEL[WORKFLOW_INSIGHT_KEYS.click],
    data: {
      color: theme.colors.yellow800
    }
  },
  [WORKFLOW_INSIGHT_KEYS.impression]: {
    value: WORKFLOW_INSIGHT_KEYS.impression,
    label: WORKFLOW_INSIGHT_LABEL[WORKFLOW_INSIGHT_KEYS.impression],
    data: {
      color: theme.colors.green800
    }
  },
  [WORKFLOW_INSIGHT_KEYS.open]: {
    value: WORKFLOW_INSIGHT_KEYS.open,
    label: WORKFLOW_INSIGHT_LABEL[WORKFLOW_INSIGHT_KEYS.open],
    data: {
      color: theme.colors.red800
    }
  },
  [WORKFLOW_INSIGHT_KEYS.sends]: {
    value: WORKFLOW_INSIGHT_KEYS.sends,
    label: WORKFLOW_INSIGHT_LABEL[WORKFLOW_INSIGHT_KEYS.sends],
    data: {
      color: theme.colors.neutral800
    }
  },
  [WORKFLOW_INSIGHT_KEYS.read]: {
    value: WORKFLOW_INSIGHT_KEYS.read,
    label: WORKFLOW_INSIGHT_LABEL[WORKFLOW_INSIGHT_KEYS.read],
    data: {
      color: theme.colors.redViolet800
    }
  }
}

export const WORKFLOW_INSIGHT_MAPING_HIGHCHARTS_KEYS = {
  [WORKFLOW_INSIGHT_KEYS.delivery_rate]: WORKFLOW_INSIGHT_KEYS.delivery_rate,
  [WORKFLOW_INSIGHT_KEYS.click_rate]: WORKFLOW_INSIGHT_KEYS.click_rate,
  [WORKFLOW_INSIGHT_KEYS.impression_rate]:
    WORKFLOW_INSIGHT_KEYS.impression_rate,
  [WORKFLOW_INSIGHT_KEYS.open_rate]: WORKFLOW_INSIGHT_KEYS.open_rate,
  [WORKFLOW_INSIGHT_KEYS.click_through_rate]:
    WORKFLOW_INSIGHT_KEYS.click_through_rate,

  [WORKFLOW_INSIGHT_KEYS.delivery]: WORKFLOW_INSIGHT_KEYS.delivery_rate,
  [WORKFLOW_INSIGHT_KEYS.click]: WORKFLOW_INSIGHT_KEYS.click_rate,
  [WORKFLOW_INSIGHT_KEYS.impression]: WORKFLOW_INSIGHT_KEYS.impression_rate,
  [WORKFLOW_INSIGHT_KEYS.open]: WORKFLOW_INSIGHT_KEYS.open_rate
}

export const WORKFLOW_INSIGHT_OPTIONS_RATE: InsightOverviewCheckboxGroupOptions =
  [
    WORKFLOW_INSIGHT_KEYS.delivery_rate,
    WORKFLOW_INSIGHT_KEYS.click_rate,
    WORKFLOW_INSIGHT_KEYS.impression_rate,
    WORKFLOW_INSIGHT_KEYS.open_rate,
    WORKFLOW_INSIGHT_KEYS.click_through_rate,
    WORKFLOW_INSIGHT_KEYS.CTOR
  ].map((value) => ({
    value,
    label: WORKFLOW_INSIGHT_LABEL[value],
    unit: WORKFLOW_INSIGHT_OPTIONS[value]?.data?.unit || '',
    color: WORKFLOW_INSIGHT_OPTIONS[value]?.data?.color
  }))

export const WORKFLOW_INSIGHT_OPTIONS_NUMBER: InsightOverviewCheckboxGroupOptions =
  [
    WORKFLOW_INSIGHT_KEYS.delivery,
    WORKFLOW_INSIGHT_KEYS.click,
    WORKFLOW_INSIGHT_KEYS.impression,
    WORKFLOW_INSIGHT_KEYS.open,
    WORKFLOW_INSIGHT_KEYS.sends,
    WORKFLOW_INSIGHT_KEYS.read
  ].map((value) => ({
    value,
    label: WORKFLOW_INSIGHT_LABEL[value],
    unit: WORKFLOW_INSIGHT_OPTIONS[value]?.data?.unit || '',
    color: WORKFLOW_INSIGHT_OPTIONS[value]?.data?.color
  }))
