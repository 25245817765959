import { AnalyticTemplateWidgetItemDataParams } from 'interfaces/analytic'
import { AnalyticServices } from 'services/analytic'

export const getAnalyticTemplateWidgetItemData = async (
  input: AnalyticTemplateWidgetItemDataParams
) => {
  try {
    const response: any =
      await AnalyticServices.getAnalyticTemplateWidgetItemData(input)
    if (!response?.data || response._message) {
      throw new Error(JSON.stringify(response))
    }

    return { data: response.data?.data, errors: [] }
  } catch (error) {
    return { data: {}, errors: [{ errors: ['Something went wrong'] }] }
  }
}
