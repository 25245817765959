import {
  getBase64,
  isImageValid
} from 'components/molecules/form/formSettingLandingPage/ImageUploader'
import { useState } from 'react'

function useImageUploader() {
  const [file, setFile] = useState<File | null>(null)
  function handleChangeFile(file?: File) {
    if (!file) {
      return
    }

    const isValid = isImageValid(file)

    if (!isValid) {
      return
    }

    getBase64(file, (base64) => {
      setFile(file)
    })
  }

  function handleClick() {
    const $input = document.createElement('input')
    $input.type = 'file'
    $input.accept = 'image/*'
    $input.addEventListener('change', () => {
      handleChangeFile($input.files?.[0])
    })

    $input.click()
  }

  return {
    uploadFile: handleClick,
    file: file
  }
}

export default useImageUploader
