import { Button, Input } from 'antd'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  width: 16px;
  height: 16px;
  padding: 0;
  border-width: 0;
  color: ${({ theme }) => theme?.colors?.gray400 || '#8C8C8C'};
  .anticon {
    font-size: 16px;
  }
`

export const StyledInputSearch = styled(Input.Search)`
  &.ant-input-search {
    .ant-input-group {
      .ant-input-prefix {
        margin-right: 8px;
        + .ant-input {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
      .ant-input-affix-wrapper {
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        + .ant-input-group-addon {
          display: none;
        }
      }
    }
  }
  &.ant-input-search-small {
    .ant-input-group {
      .ant-input-prefix {
        ${StyledButton} {
          width: 12px;
          height: 12px;
          .anticon {
            font-size: 12px;
          }
        }
      }
      .ant-input-affix-wrapper {
        padding-left: 12px;
      }
    }
  }
  &.ant-input-search-medium {
    .ant-input-group {
      .ant-input-affix-wrapper {
        padding-left: 16px;
      }
    }
  }
  &.ant-input-search-large {
    .ant-input-group {
      .ant-input-prefix {
        ${StyledButton} {
          width: 20px;
          height: 20px;
          .anticon {
            font-size: 20px;
          }
        }
      }
      .ant-input-affix-wrapper {
        padding-left: 20px;
      }
    }
  }
`
