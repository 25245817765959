/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnalyticWidgetMuuriContext } from '../../AnalyticWidgetMuuri/AnalyticWidgetMuuri.helpers'
import {
  AnalyticWidgetItemProps,
  usePeriodLabel
} from '../AnalyticWidgetItem.helpers'
import { AnalyticWidgetItemUnderConstruction } from '../AnalyticWidgetItemUnderConstruction'
import {
  checkIsIndoSite,
  checkIsMalaysiaSite,
  checkIsPhilippinesSite,
  checkIsTaiwanSite,
  checkIsVietnamSite,
  formatVisitNumber,
  mapToCorrectLocationName
} from './helpers'
// import mockVN from './mockVN'
import { Spin } from 'antd'
// import mockTw from './mockTw'
import { HighchartsEmpty } from 'components/atoms/Highcharts/HighchartsEmpty'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsMap from 'highcharts/modules/map'
import {
  FC,
  memo,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div``

const Container: FC<AnalyticWidgetItemProps> = ({
  downloadImage,
  ...props
}) => {
  const label = usePeriodLabel(props?.item?.query?.period)
  const { segment_sites: segmentSitesCtx } = useContext(
    AnalyticWidgetMuuriContext
  )
  const segment_sites = useMemo(() => {
    return props.segmentSites || segmentSitesCtx
  }, [segmentSitesCtx, props.segmentSites])
  const currentSite = useMemo(() => segment_sites?.[0], [segment_sites])

  const isVietnamSite = useMemo(
    () => checkIsVietnamSite(segment_sites),
    [segment_sites]
  )
  const isTaiwanSite = useMemo(
    () => checkIsTaiwanSite(segment_sites),
    [segment_sites]
  )
  const isIndonesiaSite = useMemo(
    () => checkIsIndoSite(segment_sites),
    [segment_sites]
  )
  const isMalaysiaSite = useMemo(
    () => checkIsMalaysiaSite(segment_sites),
    [segment_sites]
  )
  const isPhilipSite = useMemo(
    () => checkIsPhilippinesSite(segment_sites),
    [segment_sites]
  )

  const divRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<any>(null)
  const [isShow, setIsShow] = useState(false)
  const [topology, setTopology] = useState<any>(null)
  const [mapNameToLatLong, setMapNameToLatLong] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    HighchartsMap(Highcharts)
    HighchartsExporting(Highcharts)
    setTimeout(() => {
      setIsShow(true)
    }, 300)
  }, [])

  const data = useMemo(() => {
    return props.data
  }, [props.data])

  useEffect(() => {
    if (!currentSite) return
    ;(async () => {
      try {
        setLoading(true)
        const [result, resultModule] = await Promise.all([
          import(`./topology/${currentSite.name.replace('/fil', '')}.json`),
          import(`./lat-and-lon/${currentSite.name.replace('/fil', '')}.ts`)
        ])
        setTopology(result)
        setMapNameToLatLong(resultModule.default)
      } catch (err) {
        setTopology(null)
        setMapNameToLatLong(null)
        console.error(err)
      }
      setLoading(false)
    })()
  }, [currentSite])

  useEffect(() => {
    if (downloadImage) {
      const now = new Date().getTime()
      containerRef.current?.chart?.exportChart({
        filename: props.item?.data?.title
          ? `${props.item?.data?.title}_${now}`
          : `Visit by location_${now}`
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadImage])

  const countryCode = useMemo(() => {
    return Object.keys(data?.visit_city || {})[0] || ''
  }, [data])

  const dataFormatted = useMemo(() => {
    try {
      if (!mapNameToLatLong || !countryCode) return []
      return Object.keys(data.visit_city?.[countryCode] || {})
        .map((name) => {
          return {
            name: mapToCorrectLocationName[name] || name,
            lat: mapNameToLatLong[name]?.lat || 0,
            lon: mapNameToLatLong[name]?.lon || 0,
            visitNumber: data.visit_city[countryCode][name],
            visitNumberFormat: formatVisitNumber(
              data.visit_city[countryCode][name]
            ),
            z: data.visit_city[countryCode][name]
          }
        })
        .filter((item) => item.lat && item.lon)
        .reduce((result, item) => {
          const found = result.find((i) => i.name === item.name)
          if (found) {
            found.visitNumber = found.visitNumber + item.visitNumber
            found.visitNumberFormat = formatVisitNumber(found.visitNumber)
            return result
          }
          result.push(item)
          return result
        }, [] as { name: string; lat: number; lon: number; visitNumber: number; z: number; visitNumberFormat: string | number }[])
    } catch (err) {
      return []
    }
  }, [data, mapNameToLatLong, countryCode])

  const mapOptions: Highcharts.Options = useMemo(() => {
    return {
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{point.name} <br>Number: {point.visitNumberFormat}'
      },
      xAxis: {
        crosshair: {
          zIndex: 5,
          dashStyle: 'Dot',
          snap: false,
          color: 'gray'
        }
      },

      yAxis: {
        crosshair: {
          zIndex: 5,
          dashStyle: 'Dot',
          snap: false,
          color: 'gray'
        }
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom'
        }
      },
      series: [
        {
          type: 'map',
          name: 'Basemap',
          mapData: topology,
          accessibility: {
            exposeAsGroupOnly: true
          },
          borderColor: '#606060',
          nullColor: 'rgba(200, 200, 200, 0.2)',
          showInLegend: false
        },
        {
          type: 'mapbubble',
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          },
          accessibility: {
            point: {
              valueDescriptionFormat:
                '{point.name}, Visit Number {point.visitNumber}. '
            }
          },
          name: 'Visit by Location in ' + currentSite?.name,
          data: dataFormatted,
          maxSize: '12%',
          color: '#2D87F3'
        }
      ]
    }
  }, [topology, dataFormatted, currentSite])

  useEffect(() => {
    let resizeObserver: ResizeObserver | null = null
    const intervalID = setInterval(() => {
      const latestDivEl = divRef.current
      if (latestDivEl) {
        resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            // console.log('Element resized:', entry.target)
            // console.log(
            //   'New dimensions:',
            //   entry.contentRect.width,
            //   entry.contentRect.height,
            //   containerRef.current?.chart
            // )
            setTimeout(() => {
              containerRef.current?.chart?.reflow()
            }, 300)
          }
        })

        setTimeout(() => {
          containerRef.current?.chart?.reflow()
        }, 1000)

        resizeObserver.observe(latestDivEl)
        clearInterval(intervalID)
      }
    }, 500)

    const divEl = divRef.current
    return () => {
      clearInterval(intervalID)
      divEl && resizeObserver?.unobserve(divEl)
    }
  }, [])

  const emptyLayout = (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between">
        <h4 className="period mb-0 text-gray600">{label}</h4>
      </div>
      <HighchartsEmpty
        className="h-full flex-1 min-h-[400px] flex flex-col justify-center"
        iconClassName="text-[80px]"
      />
    </div>
  )

  if (!segment_sites?.length) {
    return emptyLayout
  }

  if (
    !isVietnamSite &&
    !isTaiwanSite &&
    !isIndonesiaSite &&
    !isMalaysiaSite &&
    !isPhilipSite
  )
    return (
      <AnalyticWidgetItemUnderConstruction
        description={`The widget for site ${segment_sites[0].name} is under construction`}
      />
    )

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spin />
      </div>
    )
  }

  if (!dataFormatted.length) {
    return emptyLayout
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between">
        <h4 className="period mb-0 text-gray600">{label}</h4>
      </div>
      <StyledWrapper className="visit-by-location" ref={divRef}>
        <div style={{ maxWidth: divRef.current?.clientWidth, minWidth: 300 }}>
          {isShow && topology && mapNameToLatLong && (
            <HighchartsReact
              options={mapOptions}
              constructorType="mapChart"
              highcharts={Highcharts}
              ref={containerRef}
              // key={currentSite?.name}
            />
          )}
        </div>
      </StyledWrapper>
    </div>
  )
}

export const AnalyticWidgetItemVisitByLocation = memo(Container)
AnalyticWidgetItemVisitByLocation.displayName =
  'AnalyticWidgetItemVisitByLocation'
