import { fetchSegmentList } from 'components/atoms/select/selectSegment/SelectSegment.helpers'
import { SEGMENT_TYPE } from 'constants/segment'
import { Delete, Download, Edit } from 'icons/V2'
import { SegmentQueryParams } from 'interfaces/segment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AnalyticServices } from 'services/analytic'

export const ANALYTIC_TEMPLATE_PERIOD_OPTIONS = [
  { label: 'Yesterday', value: 1 },
  // { label: 'Today', value: 'today' },
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 14 days', value: 14 },
  { label: 'Last 30 days', value: 30 },
  { label: 'Last 60 days', value: 60 },
  { label: 'Last 90 days', value: 90 }
  // { label: 'Custom Range', value: 'custom' }
]

export const ANALYTIC_TEMPLATE_DROPDOWN_ITEMS = [
  {
    key: 'edit',
    icon: <Edit className="text-[18px]" />,
    label: 'Edit Dashboard'
  },
  {
    key: 'download',
    icon: <Download className="text-[18px]" />,
    label: 'Download PDF'
  },
  {
    key: 'delete',
    icon: <Delete className="text-[18px]" />,
    label: 'Delete',
    className: 'text-red800'
  }
]

export const useSegmentList = (query: Partial<SegmentQueryParams>) => {
  const [segmentList, setSegmentList] = useState([])
  const debounce = useRef<NodeJS.Timeout | null>(null)
  const fetch = useCallback((query: Partial<SegmentQueryParams>) => {
    if (debounce.current) {
      clearTimeout(debounce.current)
    }
    debounce.current = setTimeout(() => {
      try {
        const params: SegmentQueryParams = {
          page: query?.page || 1,
          per_page: query?.per_page || 100000,
          types: query?.types || SEGMENT_TYPE.ATTRIBUTE,
          not_count_user: query?.analytics || true,
          sort_type: query?.sort_type || 'ASC',
          sort_by: query?.sort_by || 'name',
          multi_site: query?.multi_site || true
        }
        if (query.types === SEGMENT_TYPE.EVENT) {
          params.types = `${SEGMENT_TYPE.EVENT},${SEGMENT_TYPE.EVENT_CUSTOM}`
        }
        fetchSegmentList(params).then((res) => {
          setSegmentList(res.data || [])
        })
      } catch (error) {}
    }, 1000)
  }, [])

  useEffect(() => {
    fetch(query)
  }, [query])

  return useMemo(() => ({ segmentList }), [segmentList])
}

export const deleteAnalyticTemplateWidget = async ({
  template_id,
  widget_id
}: {
  template_id: number
  widget_id: number
}) => {
  try {
    const response: any = await AnalyticServices.deleteAnalyticTemplateWidget(
      template_id,
      widget_id
    )
    if (response._statusRes !== 200) {
      throw new Error(JSON.stringify(response))
    }
    return { data: true, errors: [] }
  } catch (errors) {
    return { data: false, errors: [{ errors: ['Something went wrong'] }] }
  }
}
