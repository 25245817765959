import styled from 'styled-components'

export const StyledAnalyticWidgetMuuri = styled.div`
  position: relative;
  .analyticWidgetMuuri--container {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;
    border-box: border-box;
  }
`
