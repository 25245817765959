import { SegmentFormQueryValue } from './SegmentFormQuery'
import { FormListProps, Rule } from 'antd/lib/form'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import { SEGMENT_RELATION, SEGMENT_TYPE } from 'constants/segment'
import dayjs from 'dayjs'

export const optionSegmentOperator = [
  { value: SEGMENT_RELATION.AND, label: 'All' },
  { value: SEGMENT_RELATION.OR, label: 'At least once' }
]
export const optionSegmentType = [
  { value: SEGMENT_TYPE.ATTRIBUTE, label: 'Attribute' },
  { value: SEGMENT_TYPE.MOBILE, label: 'Mobile' },
  { value: SEGMENT_TYPE.EVENT, label: 'Event' },
  {
    value: SEGMENT_TYPE.EVENT_CUSTOM,
    label: 'Custom conditions'
  }
]
export const defaultSegmentFormQueryListItem = {
  operator: SEGMENT_RELATION.AND,
  segment_type: SEGMENT_TYPE.ATTRIBUTE,
  conditions: []
}

// Source Discover => visit_site => ID === 22
export const segmentEventIdVisitSite = 22

export const segmentFormQueryRules: Record<
  keyof SegmentFormQueryValue | string,
  FormListProps['rules'] | Rule[]
> = {
  list: [
    {
      async validator(rule, value, callback) {
        if (!value || !value.length) {
          return Promise.reject('Conditions is required')
        }
        return true
      }
    }
  ],
  operator: [{ required: true, message: 'Relation is required' }],
  segment_type: [{ required: true, message: 'Type of segment is required' }],
  event_id: [{ required: true, message: 'Event is required' }]
}
export const formatSegmentFormQueryATTRIBUTEConditions = (value: {
  attribute_conditions: any[]
  segment_type: string
}) => {
  const { segment_type, attribute_conditions } = value
  if (!attribute_conditions || !attribute_conditions?.length) {
    return [{ segment_type, operator: SEGMENT_RELATION.AND, conditions: [] }]
  }
  return value.attribute_conditions?.map((attr) => {
    return {
      segment_type: value.segment_type,
      operator: attr.operator,
      conditions:
        attr.conditions?.map((cond: any) => {
          return {
            field: cond.field,
            operator: cond?.operator,
            value: cond?.value,
            type: cond?.type
          }
        }) || []
    }
  })
}
export const formatSegmentFormQueryEVENTConditions = (value: {
  events_conditions: any[]
  segment_type: string
}) => {
  const { segment_type, events_conditions } = value
  if (!events_conditions || !events_conditions?.length) {
    return [{ segment_type, operator: SEGMENT_RELATION.AND, conditions: [] }]
  }
  return events_conditions.map((event: any) => {
    let submitted = null
    if (event?.submitted_from && event?.submitted_to) {
      const startDate = dayjs(event.submitted_from, FORMAT_DATE_DAYJS_API, true)
      const endDate = dayjs(event.submitted_to, FORMAT_DATE_DAYJS_API, true)
      if (startDate.isValid() && endDate.isValid()) {
        submitted = [startDate, endDate]
      }
    }
    return {
      segment_type: value.segment_type,
      operator: event.operator,
      event_id: event.event?.id,
      submitted,
      conditions:
        event.conditions?.map((cond: any) => {
          return {
            field: cond.field,
            operator: cond?.operator,
            value: cond?.value,
            type: cond?.type
          }
        }) || []
    }
  })
}
export const formatSegmentFormQuery = (initValue: any) => {
  const newValue = { ...initValue }
  try {
    newValue.segment_type = initValue?.segment_type || SEGMENT_TYPE.ATTRIBUTE
    newValue.operator = initValue?.operator || SEGMENT_RELATION.OR
    switch (newValue.segment_type) {
      case SEGMENT_TYPE.ATTRIBUTE: {
        newValue.list = formatSegmentFormQueryATTRIBUTEConditions({
          attribute_conditions:
            newValue?.attribute_conditions || newValue?.list || [],
          segment_type: newValue.segment_type
        })
        break
      }
      case SEGMENT_TYPE.EVENT: {
        newValue.list = formatSegmentFormQueryEVENTConditions({
          events_conditions:
            newValue?.events_conditions || newValue?.list || [],
          segment_type: newValue.segment_type
        })
        break
      }
      case SEGMENT_TYPE.MOBILE: {
        newValue.list = formatSegmentFormQueryATTRIBUTEConditions({
          attribute_conditions:
            newValue?.mobile_conditions || newValue?.list || [],
          segment_type: newValue.segment_type
        })
        break
      }
      case SEGMENT_TYPE.EVENT_CUSTOM: {
        newValue.list = formatSegmentFormQueryATTRIBUTEConditions({
          attribute_conditions:
            newValue?.custom_conditions || newValue?.list || [],
          segment_type: newValue.segment_type
        })
        break
      }
    }
  } catch (error) {}
  return newValue
}
