import styled from 'styled-components'

export const StyledWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

export const StyledActions = styled.ul`
  position: absolute;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -60px);
  margin: 0;
  padding: 4px;
  list-style: none;
  gap: 10px;
  display: inline-flex;
  border-radius: 54px;
  background: ${(props) => props.theme.colors.gray50};
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04),
    0px 2px 8px 0px rgba(0, 0, 0, 0.04), 0px 10px 16px 0px rgba(0, 0, 0, 0.04);

  > li {
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.white};
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.active {
      color: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.primary};
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
`
