import { RelationKeys } from '../relation'
import { OperatorKeys } from 'components/condition/base/conditionOperator'

export type FormNamePath = string | number | (string | number)[]

export type FormFieldValue =
  | string
  | number
  | boolean
  | undefined
  | (string | number | boolean | undefined)[]

export type FormFilterValue = {
  relation: RelationKeys
  filterKey: string
  fields: {
    fieldKey: string
    fieldValue: FormFieldValue | OperatorKeys
  }[]
}

export type FormValue = {
  relation: RelationKeys
  filters: FormFilterValue[]
}

export const defaultRelation: RelationKeys = 'AND'

export function getFormNamePath(
  name?: FormNamePath,
  namePath?: FormNamePath
): (string | number)[] {
  const newPath: (string | number)[] = []

  if (typeof name === 'string' || typeof name === 'number') {
    newPath.unshift(name)
  }
  if (Array.isArray(name)) {
    newPath.unshift(...name)
  }

  if (typeof namePath === 'string' || typeof namePath === 'number') {
    newPath.push(namePath)
  }
  if (Array.isArray(namePath)) {
    newPath.push(...namePath)
  }

  return newPath
}

export function getChangedValuesWithFormNamePath(
  name: FormNamePath,
  value: any
): any {
  if (typeof name === 'string' || typeof name === 'number') {
    return { [name]: value }
  }

  if (Array.isArray(name)) {
    try {
      let tmp: any = { [name[name.length - 1]]: value }
      for (let i = name.length - 2; i >= 0; i--) {
        const valueTmp = Array.isArray(tmp) ? [...tmp] : { ...tmp }
        if (typeof name[i] === 'string') {
          tmp = {}
          tmp[name[i]] = valueTmp
        }

        if (typeof name[i] === 'number') {
          tmp = Array(name[i])
          tmp[name[i]] = valueTmp
        }
      }
      return tmp
    } catch (error) {
      return {}
    }
  }

  return {}
}
