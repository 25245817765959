import { StyledIcon, StyledLabel, StyledStatus } from './index.styled'
import Icon from '@ant-design/icons'
import { theme } from 'constants/theme'
import { AlertSuccess, Check, CloseOrange, Draft, Runing } from 'icons'
import { WorkflowStatus } from 'interfaces/workflow'
import { useMemo } from 'react'

export type StatusProps<T> = {
  status: T
}

export const statusWf = {
  [WorkflowStatus.InProgress]: {
    icon: <Icon component={Runing} />,
    label: 'In Progress',
    color: theme.colors.redViolet400
  },
  [WorkflowStatus.Ready]: {
    icon: <Icon component={Check} />,
    label: 'Ready',
    color: theme.colors.green600
  },
  [WorkflowStatus.Draft]: {
    icon: <Icon component={Draft} />,
    label: 'Draft',
    color: theme.colors.gray400
  },
  [WorkflowStatus.Cancel]: {
    icon: <Icon component={CloseOrange} />,
    label: 'Cancel',
    color: theme.colors.red400
  },
  [WorkflowStatus.Finish]: {
    icon: <Icon component={AlertSuccess} />,
    label: 'Finish',
    color: theme.colors.green600
  }
}
export type StatusWorkflowProps = StatusProps<number>
export function StatusWorkflow(props: StatusWorkflowProps) {
  const { status = WorkflowStatus.InProgress } = props
  const statusConfig = useMemo(() => {
    // This is required for fucking grammar
    if (status === WorkflowStatus.Finish) {
      return {
        ...statusWf[status],
        label: 'Finished'
      }
    }
    return statusWf[status]
  }, [status])
  return (
    <StyledStatus>
      <StyledIcon>{statusConfig.icon}</StyledIcon>
      <StyledLabel>{statusConfig.label}</StyledLabel>
    </StyledStatus>
  )
}
