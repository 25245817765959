import EventListFields from '../eventFields'
import { FormNamePath, getFormNamePath } from '../form/index.helpers'
import {
  StyleHorizantalField,
  StyleText,
  StyleTextBtn
} from '../group/index.styled'
import {
  CheckboxOptionType,
  DatePicker,
  Form,
  FormListFieldData,
  FormListOperation,
  Select
} from 'antd'
import { default_current_page, default_page_size_all } from 'constants/common'
import { optionsConditionRelation } from 'interfaces/condition'
import { FC, useEffect, useState } from 'react'
import { FieldEventServices } from 'services/fieldAttribute'

interface EventConditionProps {
  site: string
  rootName: FormNamePath
  eventFields: any[]
  operatorEventOpts: any[]
  formListFieldData: FormListFieldData
  formListOperation: FormListOperation
}

const optionsPerform = [
  {
    label: 'has not been performed',
    value: 'has_not',
    disabled: true
  },
  {
    label: 'has been performed',
    value: 'has'
  }
]

const EventConditionForm: FC<EventConditionProps> = ({
  rootName,
  eventFields,
  operatorEventOpts,
  formListFieldData: { name: itemName }
}) => {
  const form = Form.useFormInstance()
  const [fieldOptions, setFieldOptions] = useState<CheckboxOptionType[]>([])
  const fieldConditionKey = Form.useWatch(
    getFormNamePath(rootName, 'conditionKey'),
    form
  )
  const fieldRelation = Form.useWatch(
    getFormNamePath(rootName, 'relationField'),
    form
  )
  const fetchListFields = async (id: string | number) => {
    try {
      const { data } = await FieldEventServices.getListFields({
        event_id: id.toString(),
        page: default_current_page,
        per_page: default_page_size_all
      })
      if (data.data) {
        const formatOptions = data.data.map((field: any) => {
          return {
            value: field.field,
            label: field.name,
            type: field.type
          }
        })
        setFieldOptions(formatOptions)
      } else {
        setFieldOptions([])
      }
    } catch (error) {
      setFieldOptions([])
    }
  }
  const onChangeEvent = () => {
    const fieldValues = form.getFieldValue(getFormNamePath(rootName, 'fields'))
    if (fieldValues) {
      const newValues = fieldValues.map((field: any) => {
        if (field) {
          return {
            fieldKey: null,
            fieldOperator: null,
            fieldValue: null
          }
        }
        return field
      })
      form.setFieldValue(getFormNamePath(rootName, 'fields'), newValues)
    }
  }
  useEffect(() => {
    if (fieldConditionKey) {
      fetchListFields(fieldConditionKey)
    }
  }, [fieldConditionKey, rootName, itemName, form])

  useEffect(() => {
    if (fieldRelation) {
      const fieldValues = form.getFieldValue(
        getFormNamePath(rootName, 'fields')
      )
      // Init select field event
      if (!fieldValues) {
        form.setFieldValue(getFormNamePath(rootName, 'fields'), [
          {
            fieldKey: null
          }
        ])
      }
    }
  }, [fieldRelation, rootName, itemName, form])
  return (
    <>
      <StyleHorizantalField>
        <Form.Item
          label=""
          className="item-field"
          name={[itemName, 'conditionKey']}
          rules={[
            {
              required: true,
              message: 'Please choose event field'
            }
          ]}>
          <Select
            onChange={onChangeEvent}
            options={eventFields}
            placeholder="Event"
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label=""
          className="item-field"
          name={[itemName, 'conditonPerform']}
          rules={[
            {
              required: true,
              message: 'Please choose perform'
            }
          ]}>
          <Select options={optionsPerform} placeholder="Performed" />
        </Form.Item>
      </StyleHorizantalField>
      <StyleHorizantalField className="full-width">
        <Form.Item
          label=""
          className="item-field"
          name={[itemName, 'conditionRangeDate']}
          rules={[
            {
              required: true,
              message: 'Please choose range date'
            }
          ]}>
          <DatePicker.RangePicker />
        </Form.Item>
      </StyleHorizantalField>
      <StyleHorizantalField className="full-width">
        <StyleText>with</StyleText>
        <Form.Item
          label=""
          className="item-field"
          name={[itemName, 'relationField']}
          rules={[
            {
              required: true,
              message: 'Please choose operator'
            }
          ]}>
          <Select
            options={optionsConditionRelation}
            placeholder="Relation field"
          />
        </Form.Item>
        <StyleText>of the following event attributes</StyleText>
      </StyleHorizantalField>
      <Form.List name={[itemName, 'fields']}>
        {(fields, operations) => {
          return (
            <div className="pl-[43px]">
              {fields.map((field, index) => {
                return (
                  <EventListFields
                    key={`${field.key}-${index}`}
                    rootName={getFormNamePath(rootName, 'fields')}
                    operatorEventOpts={operatorEventOpts}
                    fieldOptions={fieldOptions}
                    formListFieldData={field}
                    formListOperation={operations}
                  />
                )
              })}
              <StyleHorizantalField className="full-width">
                <StyleTextBtn onClick={() => operations.add()}>
                  Add event data filter
                </StyleTextBtn>
              </StyleHorizantalField>
            </div>
          )
        }}
      </Form.List>
    </>
  )
}

export default EventConditionForm
