import {
  CampaignFormProps,
  CampaignFormRef,
  formatCampaignFormValue
} from '../CampaignForm.helpers'
import {
  CampaignStepOverviewsLandingPageValue,
  CampaignStepSegmentsValue,
  CampaignStepSitesValue,
  CampaignStepTemplatesValue
} from '../CampaignStep'

export type CampaignLandingPageFormRef = CampaignFormRef

export type CampaignLandingPageFormValue =
  CampaignStepOverviewsLandingPageValue &
    CampaignStepSitesValue &
    CampaignStepSegmentsValue &
    CampaignStepTemplatesValue & {
      status?: string
    }

export type CampaignLandingPageFormProps =
  CampaignFormProps<CampaignLandingPageFormValue>

export const transferCampaignFormLandingPageValue = (
  input: any
): CampaignLandingPageFormValue => {
  const formValue: CampaignLandingPageFormValue = formatCampaignFormValue({
    ...input
  }) as CampaignLandingPageFormValue

  return formValue
}
