import {
  AnalyticWidgetItemProps,
  WIDGET_ITEM_HIGHCHARTS_EMPTY_PROPS,
  WIDGET_ITEM_KEY,
  WIDGET_ITEM_LABEL,
  usePeriodLabel
} from './AnalyticWidgetItem.helpers'
import { HighchartsSpider } from 'components/atoms/Highcharts'
import { theme } from 'constants/theme'
import { FC } from 'react'
import { mathRound } from 'utils'

const breakdownByAgeAndGenderKeys = [
  // WIDGET_ITEM_KEY.UNKNOWN,
  WIDGET_ITEM_KEY.FEMALE,
  WIDGET_ITEM_KEY.MALE
]
const breakdownByAgeAndGenderTheme = {
  [WIDGET_ITEM_KEY.MALE]: { color: theme.colors.blue600, unit: '' },
  [WIDGET_ITEM_KEY.FEMALE]: { color: theme.colors.pink600, unit: '' }
  // [WIDGET_ITEM_KEY.UNKNOWN]: { color: theme.colors.yellow600, unit: '' }
}
const breakdownByAgeAndGenderOrder = [
  '18-24',
  '25-34',
  '35-44',
  '45-54',
  '55-64',
  '+65'
]

export const AnalyticWidgetItemBreakdownByAgeAndGender: FC<
  AnalyticWidgetItemProps
> = (props) => {
  const { data, item } = props
  const label = usePeriodLabel(item?.query?.period)
  const highchartsOptions = formatBreakdownByAgeAndGender(data)

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between">
        <h4 className="period mb-0 text-gray600">{label}</h4>
      </div>
      <div className="flex-1 min-h-0">
        <HighchartsSpider
          options={highchartsOptions}
          className="h-full"
          emptyProps={WIDGET_ITEM_HIGHCHARTS_EMPTY_PROPS}
        />
      </div>
    </div>
  )
}

export function formatBreakdownByAgeAndGender(
  data: any,
  keys = breakdownByAgeAndGenderKeys
): Highcharts.Options {
  const { detail: details, ...total } = data || {}
  const highchartsOptions: Highcharts.Options = {
    chart: {
      marginLeft: 300
    },
    xAxis: {
      labels: { enabled: true },
      lineWidth: 0,
      crosshair: true
    },
    yAxis: {
      labels: { enabled: true },
      gridLineDashStyle: 'Dot'
    },
    series: [],
    plotOptions: {
      bar: {
        pointWidth: 16,
        grouping: true,
        centerInCategory: true
      },
      pie: {
        size: 220,
        innerSize: '70%',
        center: [-200, 95],
        states: {
          hover: {
            halo: {
              size: 3
            }
          }
        },
        dataLabels: {
          enabled: true,
          crop: false,
          format: '{point.y}%',
          distance: '-15%',
          style: {
            color: '#fff',
            fontFamily: 'sans-serif',
            textOutline: 'none'
          }
        }
      }
    },
    legend: { enabled: true }
  }

  if (!total || !Object.keys(total).length || !details || !details?.length) {
    return highchartsOptions
  }

  const xCategories: string[] = []
  const seriesCircleSum = keys.reduce((sum: number, key) => {
    if (typeof total?.[key] === 'number') {
      return sum + total[key]
    }
    return sum
  }, 1)
  const seriesPie: Highcharts.SeriesPieOptions = {
    type: 'pie',
    data: [],
    tooltip: {
      pointFormat: '<strong>{tooltip.valuePrefix}{point.y}%</strong>'
    }
  }
  const seriesBars: Highcharts.SeriesBarOptions[] = []

  try {
    keys.forEach((key, index) => {
      if (typeof total?.[key] === 'number' && total[key] !== 0) {
        let pointY = mathRound((total[key] / seriesCircleSum) * 100, 0)
        if (index === keys.length) {
          pointY =
            100 -
            seriesPie.data?.reduce(
              (itemA: any, itemB: any) => itemA.y + itemB.y,
              0
            )
        }
        seriesPie.data = [
          ...(seriesPie.data as Highcharts.PointOptionsObject[]),
          {
            y: pointY,
            color: breakdownByAgeAndGenderTheme?.[key]?.color,
            name: WIDGET_ITEM_LABEL[key]
          }
        ]
      }
    })

    details
      // .sort((a: any, b: any) => {
      //   const maxA = Math.max(...keys.map((key) => a?.[key] || 0))
      //   const maxB = Math.max(...keys.map((key) => b?.[key] || 0))
      //   if (maxA <= maxB) {
      //     return 1
      //   }
      //   return -1
      // })
      .sort((itemA: any, itemB: any) => {
        if (
          breakdownByAgeAndGenderOrder.indexOf(itemA.age) === -1 ||
          breakdownByAgeAndGenderOrder.indexOf(itemB.age) === -1
        ) {
          return 0
        }
        return (
          breakdownByAgeAndGenderOrder.indexOf(itemA.age) -
          breakdownByAgeAndGenderOrder.indexOf(itemB.age)
        )
      })
      .forEach((detail: any) => {
        xCategories.push(detail.age)
        keys.forEach((key) => {
          const seriesBarsItemName = WIDGET_ITEM_LABEL[key]
          const seriesBarsItem = seriesBars.find(
            ({ name }) => name === seriesBarsItemName
          )
          const seriesBarsItemValue = parseFloat(detail?.[key] || '0') || null

          if (seriesBarsItem) {
            seriesBarsItem.data?.push(seriesBarsItemValue)
          } else {
            const seriesBarsItemTheme = breakdownByAgeAndGenderTheme?.[key]
            seriesBars.push({
              type: 'bar',
              name: seriesBarsItemName,
              data: [seriesBarsItemValue],
              color: seriesBarsItemTheme?.color,
              tooltip: {
                valueSuffix: seriesBarsItemTheme?.unit
              }
            })
          }
        })
      })

    if (
      xCategories.length &&
      seriesBars.length &&
      seriesBars.some(({ data }) =>
        data?.some((item) => typeof item === 'number')
      ) &&
      seriesPie.data?.length
    ) {
      highchartsOptions.xAxis = {
        ...highchartsOptions.xAxis,
        categories: xCategories
      }
      highchartsOptions.series?.push(seriesPie, ...seriesBars)
    }
  } catch (error) {}
  return highchartsOptions
}

// const mockup = {
//   detail: [
//     {
//       age: '18-24',
//       female: 63298,
//       male: 15127,
//       unknown: 0
//     },
//     {
//       age: '25-34',
//       female: 127412,
//       male: 47545,
//       unknown: 0
//     },
//     {
//       age: '35-44',
//       female: 108968,
//       male: 2512,
//       unknown: 0
//     },
//     {
//       age: '45-54',
//       female: 45343,
//       male: 2504,
//       unknown: 0
//     },
//     {
//       age: '55-64',
//       female: 20565,
//       male: 2066,
//       unknown: 0
//     },
//     {
//       age: '+65',
//       female: 8288,
//       male: 1436,
//       unknown: 0
//     }
//   ],
//   female: 373874,
//   male: 71190,
//   unknown: 0
// }
