import { Input, InputNumber } from 'antd'
import { DatePicker } from 'components/atoms/datePicker'
import { Select } from 'components/atoms/select'
import styled from 'styled-components'

export const StyledSegmentConditionValueDatePicker = styled(DatePicker)``

export const StyledSegmentConditionValueSelect = styled(Select)`
  &.ant-select {
    &.ant-select-sm {
      &.ant-select-in-form-item {
        .ant-select-selector {
          padding-block: 1px;
          height: auto;
          .ant-select-selection-overflow {
            min-height: 32px;
          }
          .ant-select-selection-search {
            display: flex;
            align-items: center;
          }
          .ant-select-selection-item {
            height: 32px;
          }
          &:after {
            line-height: 1;
          }
        }
      }
    }
    &.ant-select-lg {
      &.ant-select-in-form-item {
        .ant-select-selector {
          padding-block: 1px;
          height: auto;
          .ant-select-selection-overflow {
            min-height: 32px;
          }
        }
      }
    }

    &.ant-select-in-form-item {
      .ant-select-selector {
        padding-block: 1px;
        height: auto;
        .ant-select-selection-overflow {
          min-height: 36px;
        }
      }
    }
  }
`
export const StyledSegmentConditionValueInput = styled(Input)``
export const StyledSegmentConditionValueNumber = styled(InputNumber)`
  width: 100%;
`

export const StyledSegmentConditionValueContainer = styled.div`
  display: flex;
  gap: 8px;
  ${StyledSegmentConditionValueNumber} {
    flex: 1;
    min-width: 0;
    max-width: 100px;
  }
`
