import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledLink = styled(Link)<{ disabled?: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

export const StyledThumbnailWrap = styled.div`
  position: relative;
  border-radius: 6px;
  overflow: hidden;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

  .template-checkbox {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;

    > .ant-checkbox {
      display: block;
    }
  }
`

export const StyledWrap = styled.div`
  border-radius: 6px;
  transition: all 0.3s ease;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.colors.neutral100};

  &:hover {
    .template-thumbnail::after {
      opacity: 1;
    }
  }

  .template-name,
  .template-name a {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.gray800};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .template-type {
    border-radius: 2px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray800};
    background-color: ${(props) => props.theme.colors.gray100};
    padding: 1px 14px;
    display: inline-block;

    & + .template-name {
      margin-top: 8px;
    }
  }

  .template-author {
    span {
      padding-left: 12px;
      color: ${(props) => props.theme.colors.gray500};
    }
  }

  .template-content {
    padding: 16px;
  }

  .template-thumbnail {
    position: relative;
    padding: 16px 16px 16px 16px;
    background-color: ${(props) => props.theme.colors.gray100};

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.3);
      transition: all 0.3s ease;
      border-radius: 6px 6px 0 0;
    }
  }
`

export const StyledThumbnailEmpty = styled.div`
  padding-top: 66%;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    color: ${({ theme }) => theme.colors.gray200};
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
