import Icon from '@ant-design/icons'
import { ModalEmpty } from 'components/atoms/modal'
import { Close, Download } from 'icons'
import { Segment } from 'interfaces/segment'
import { FC, useCallback, useEffect, useState } from 'react'
import { segmentServices } from 'services/segment'
import { segmentBuilderServices } from 'services/segmentBuilder'
import styled from 'styled-components'
import { downloadFile, formatNumberToCurrency } from 'utils'

const Description = styled.div`
  color: ${({ theme }) => theme?.colors?.gray600 || ''};
  font-size: 14px;
  line-height: 1.5;
`

const Label = styled.label`
  display: flex;
`

const fileExtend = '.xlsx'
export interface SegmentExportModalProps {
  id: string
  onCancel?: () => void
  onOk?: (id: string) => void
}
export const SegmentExportModal: FC<SegmentExportModalProps> = ({
  id,
  onCancel,
  onOk: onOkProps
}) => {
  const [loading, setLoading] = useState(false)
  const [segment, setSegment] = useState<Segment>()

  const onOk = useCallback(async () => {
    onOkProps && onOkProps(id)
    setLoading(true)
    const { data: exportFile } =
      await segmentBuilderServices.exportSegmentUsers(id)
    const name = segment?.name
      ? `${segment.name}-${new Date().getTime()}${fileExtend}`
      : `users-${new Date().getTime()}${fileExtend}`
    setLoading(false)
    downloadFile(exportFile as Blob, name)
  }, [onOkProps, id, segment?.name])

  useEffect(() => {
    if (!id) {
      return
    }

    ;(async function () {
      const { data } = await segmentServices.getSegment(id)
      if (!data) {
        return
      }

      setSegment(data)
    })()
  }, [id])

  return (
    <ModalEmpty
      open={!!id}
      maskClosable={false}
      closeIcon={<Icon component={Close} />}
      title="Export users"
      okText="Export"
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{
        size: 'large',
        icon: <Icon component={Download} />,
        loading
      }}
      cancelButtonProps={{ size: 'large' }}
      width={460}
      destroyOnClose>
      <Label>
        <Description>
          Selected: {formatNumberToCurrency(segment?.num_of_users || 0)} users
          (exclude)
        </Description>
      </Label>
    </ModalEmpty>
  )
}
