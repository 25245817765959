import Icon from '@ant-design/icons'
import { DrawerProps } from 'antd'
import {
  StyledDrawer,
  StyledDrawerExtra
} from 'components/atoms/drawer/DrawerPreviewTemplate/DrawerPreviewTemplate.styled'
import { Close } from 'icons'
import TemplateBuilderCreate from 'pages/templateBuilderCreate/webPush'

type Props = Pick<DrawerProps, 'open' | 'onClose' | 'getContainer'> & {
  onCreateTemplate: (data: any) => void
  siteId?: string | number
}

const DrawerCreateTemplate = ({
  onClose,
  open,
  getContainer,
  onCreateTemplate,
  siteId
}: Props) => {
  return (
    <StyledDrawer
      open={open}
      width="75%"
      onClose={onClose}
      getContainer={getContainer}
      extra={
        <StyledDrawerExtra>
          <Icon component={Close} onClick={onClose} />
        </StyledDrawerExtra>
      }>
      <TemplateBuilderCreate
        onCreateTemplate={onCreateTemplate}
        siteId={siteId}
      />
    </StyledDrawer>
  )
}

export default DrawerCreateTemplate
