import styled from 'styled-components'

export const StyleContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const StyleSegmentAnalyticCard = styled.div`
  background-color: ${({ theme }) => theme?.colors?.white};
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  border-radius: 8px;
  padding: 24px;
`

export const StyleWrapperAnalytic = styled.div`
  display: flex;
  flex-direction: column;
  &.total-user {
    flex: 0 0 150px;
  }

  &.reachability-user {
    flex: 1;
  }

  .title {
    color: ${(props) => props.theme?.colors?.gray700};
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .analytic-card {
    margin-bottom: 0;

    &.card-user {
      display: flex;
      align-items: center;
    }
  }
`

export const StyleAnalyticItem = styled.div`
  flex: 1 1 135px;
  .label {
    font-size: 12px;
    line-height: 18px;
    color: ${(props) => props.theme?.colors?.gray400};
  }
  .item-value {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${(props) => props.theme?.colors?.gray800};
  }
`

export const StyleWrapperListItem = styled.div`
  display: flex;
  gap: 10px;
`

export const StyledCard = styled.div`
  border: 1px solid ${(props) => props.theme?.colors?.neutral100};
  border-radius: 6px;
  padding: 24px;
  margin-bottom: 20px;
  flex: 1;
  min-width: 0;
`
