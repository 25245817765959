import styled from 'styled-components'

export const StyledCampaignInsightFilterFormContainer = styled.div``

export const StyledCampaignInsightFilterFormTitle = styled.h4`
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 16px;
  font-weight: 600;
`
