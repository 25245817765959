import { CampaignStepForm } from '..'
import { StyledStepSegments } from './CampaignStepSegments.styled'
import { CampaignStepSelectSegments } from './CampaignStepSelectSegments'
import { Form, FormInstance, notification } from 'antd'
import { Ref, forwardRef, useEffect, useImperativeHandle } from 'react'

export type CampaignStepSegmentsValue = {
  segments_ids?: number[]
}
export type CampaignStepSegmentsProps =
  CampaignStepForm<CampaignStepSegmentsValue>

const CampaignStepSegmentsWithoutRef = (
  props: CampaignStepSegmentsProps,
  ref?: Ref<FormInstance<CampaignStepSegmentsValue>>
) => {
  const [form] = Form.useForm<CampaignStepSegmentsValue>()
  const {
    site,
    loading,
    disabled,
    name,
    template_type,
    initialValues,
    onValuesChange,
    onFinish,
    onFinishFailed
  } = props

  const handleFinishFailed: CampaignStepSegmentsProps['onFinishFailed'] = (
    error
  ) => {
    onFinishFailed?.(error)
    if (error.errorFields.length) {
      error.errorFields.forEach((errorField) => {
        notification.error({ message: errorField.errors.join('\n') })
      })
    }
  }

  useEffect(() => {
    form.setFieldsValue({ ...initialValues })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  useImperativeHandle(ref, () => form, [form])

  return (
    <StyledStepSegments>
      <Form
        name={name}
        form={form}
        disabled={disabled}
        initialValues={{ ...initialValues }}
        onFinish={onFinish}
        onFinishFailed={handleFinishFailed}
        onValuesChange={onValuesChange}>
        <Form.Item
          name="segments_ids"
          rules={[
            {
              required: true,
              message: 'You should choose a segment for the next step'
            }
          ]}
          noStyle>
          <CampaignStepSelectSegments
            site={site}
            template_type={template_type}
            loading={loading}
            disabled={disabled}
          />
        </Form.Item>
      </Form>
    </StyledStepSegments>
  )
}
export const CampaignStepSegments = forwardRef(CampaignStepSegmentsWithoutRef)
