import { SegmentAnalyticDrawer } from '../SegmentAnalyticDrawer'
import { getSegmentPredictionSliderValue } from '../SegmentPrediction'
import {
  SegmentPredictionDrawer,
  defaultValueSegmentPrediction
} from '../SegmentPredictionDrawer'
import { SegmentReachable } from '../SegmentReachable'
import { SEGMENT_STATUS_KEYS } from '../SegmentStatus'
import { SegmentUserCounter } from '../SegmentUserCounter'
import {
  FetchQueryAnalyticsInput,
  SEGMENT_REACHABLE_DEFAULT,
  SegmentFormContainerProps,
  SegmentFormContainerRef,
  SegmentFormValue,
  defaultSegmentFormValue,
  formatCountDataToReachable,
  formatSegmentForm,
  useSegmentAnalyticsDrawer,
  useSegmentForm,
  useSegmentPredictionDrawer,
  useSegmentReachable
} from './SegmentForm.helpers'
import {
  StyledSegmentForm,
  StyledSegmentFormBox,
  StyledSegmentFormCol,
  StyledSegmentFormRow
} from './SegmentForm.styled'
import { SegmentFormComparison } from './SegmentFormComparison'
import { SegmentFormHeader } from './SegmentFormHeader'
import {
  SegmentFormOverview,
  SegmentFormOverviewValue
} from './SegmentFormOverview'
import {
  SegmentFormQuery,
  SegmentFormQueryListItem,
  SegmentFormQueryValue
} from './SegmentFormQuery'
import { Alert, Col, Form, Row, Spin } from 'antd'
import cn from 'classnames'
import { ModalEmpty } from 'components/atoms/modal'
import { MainHeader, menuRoutes } from 'components/molecules'
import { SEGMENT_TYPE } from 'constants/segment'
import Page404 from 'pages/404'
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const SegmentFormWithoutRef = (
  props: SegmentFormContainerProps,
  ref?: Ref<SegmentFormContainerRef>
) => {
  const location = useLocation()
  const navigate = useNavigate()
  /** Display the form to create segment for webpush inside the campaign create flow */
  const isCampaignWebpushPage = location.pathname.startsWith(
    '/campaign/web_push/'
  )

  const {
    siteId,
    segment_type,
    title,
    loading,
    disabled,
    disabledKeys,
    notFound,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    saveButtonProps,
    onSave,
    confirmModalProps,
    roles,
    permissions
  } = props
  const initValue = useRef<SegmentFormValue | null>(
    defaultValue || { ...defaultSegmentFormValue, segment_type, ...valueProps }
  )
  const [modal, setModal] = useState(false)
  const [value, setValue] = useState<SegmentFormValue>(
    defaultValue || { ...defaultSegmentFormValue, ...valueProps }
  )
  const [overviewForm] = Form.useForm<SegmentFormOverviewValue>()
  const [queryForm] = Form.useForm<SegmentFormQueryValue>()
  const isPublic = useMemo(() => {
    return value?.status === SEGMENT_STATUS_KEYS.PUBLIC
  }, [value?.status])

  const {
    loading: loadingPrediction,
    disabled: disabledPrediction,
    data: prediction,
    onChange: onChangePrediction,
    defaultValue: defaultValuePrediction,
    open: openPrediction,
    setOpen: setOpenPrediction
  } = useSegmentPredictionDrawer({ value })
  const { loading: loadingAnalytic, onSave: onSaveSegment } =
    useSegmentAnalyticsDrawer({ value, setValue })
  const {
    loading: loadingReachable,
    data: reachable,
    setData: setReachable,
    fetchQueryAnalyticsFrequency
  } = useSegmentReachable({ value, overviewForm, queryForm })
  const { sites, sitesTotal } = useSegmentForm({ value, overviewForm, siteId })

  const handleBack = () => {
    navigate(menuRoutes.segment)
  }
  const onOk = useCallback(async () => {
    try {
      const overviewValue = await overviewForm.validateFields()
      const queryValue = await queryForm.validateFields()
      const newValue = {
        ...value,
        ...overviewValue,
        ...queryValue,
        site_domain: sites.map(({ domain }) => domain) || []
      }

      const data: any = await onSave?.(newValue)
      if (data?.count_data) {
        setValue(data)
        setReachable(
          formatCountDataToReachable(data.count_data) ||
            SEGMENT_REACHABLE_DEFAULT
        )
      }
    } catch (error: any) {
      if (Array.isArray(error?.errorFields)) {
        error.errorFields.forEach((error: any) => {
          if (Array.isArray(error?.name) && error?.message?.length) {
            overviewForm.scrollToField(error.name, {
              behavior: 'smooth',
              block: 'center'
            })
          }
        })
      }
    } finally {
      setModal(false)
    }
  }, [onSave, overviewForm, queryForm, setReachable, sites, value])

  const onCancel = () => {
    if (JSON.stringify(value) !== JSON.stringify(initValue.current)) {
      setModal(true)
      return
    }
    handleBack()
  }

  const onValuesChange = useCallback(
    (valChanged: any, valsChanged: any) => {
      const newVals = { ...value, ...valsChanged }
      if (valChanged?.list?.[0]?.segment_type) {
        newVals.segment_type = valChanged?.list?.[0]?.segment_type
      }
      if (valChanged?.site_ids) {
        newVals.list = []
      }
      setReachable(SEGMENT_REACHABLE_DEFAULT)
      setValue(newVals)
      onChangeProps?.(newVals)
    },
    [onChangeProps, setReachable, value]
  )

  const onFinishFormQuery = useCallback(
    async (values: any) => {
      const type = values?.list?.[0]?.segment_type || SEGMENT_TYPE.ATTRIBUTE
      const params = {
        type,
        start_date: value?.start_date,
        end_date: value?.end_date,
        site_code: sites.map(({ domain }) => domain),
        list: values?.list || []
      } as FetchQueryAnalyticsInput
      await fetchQueryAnalyticsFrequency(params)
    },
    [value?.start_date, value?.end_date, sites, fetchQueryAnalyticsFrequency]
  )

  const onChangeSegmentPrediction = useCallback(
    async (val) => {
      try {
        const type = overviewForm.getFieldValue('segment_type')
        const formQueryValue = await queryForm.validateFields()
        const params: Partial<FetchQueryAnalyticsInput> = {
          type,
          site_code: sites.map(({ domain }) => domain) || [],
          operator: formQueryValue?.operator,
          list: formQueryValue?.list
            ?.slice(0)
            .map((item: SegmentFormQueryListItem) => ({
              ...item,
              conditions: item.conditions
                ?.filter(
                  (cond) => cond.field !== defaultValueSegmentPrediction.field
                )
                .concat(val)
            }))
        }

        onChangePrediction(params)
      } catch (error) {
        console.log('** ERROR SegmentForm.tsx : ', error)
      }
    },
    [onChangePrediction, overviewForm, queryForm, sites]
  )

  const onFinishSegmentPrediction = useCallback(
    async (val) => {
      try {
        const formQueryValue = await queryForm.validateFields()
        const newValue: SegmentFormValue = {
          ...value,
          ...formQueryValue,
          list: formQueryValue?.list
            ?.slice(0)
            .map((item: SegmentFormQueryListItem) => ({
              ...item,
              conditions: item.conditions
                ?.filter(
                  (cond) => cond.field !== defaultValueSegmentPrediction.field
                )
                .concat(val)
            }))
        }

        setValue(newValue)
        queryForm.setFieldsValue(newValue as any)
        setOpenPrediction(false)
        setTimeout(() => {
          onOk()
        }, 10)
      } catch (error: any) {
        console.log('** SegmentForm.tsx ERROR : ', error)
      } finally {
        setModal(false)
      }
    },
    [onOk, queryForm, setOpenPrediction, value]
  )

  useEffect(() => {
    const value = { ...defaultSegmentFormValue, segment_type, ...valueProps }
    setValue(value)
    initValue.current = value
  }, [valueProps, segment_type])

  useImperativeHandle(
    ref,
    () => ({
      overviewForm,
      queryForm,
      openConfirm: modal,
      setOpenConfirm: setModal
    }),
    [modal, overviewForm, queryForm]
  )

  useEffect(() => {
    return () => {
      setModal(false)
    }
  }, [])

  if (notFound) {
    return (
      <MainHeader
        headerComponent={
          <SegmentFormHeader title={title} onBack={handleBack} />
        }
        bgColor="#F7F9FC"
        stickyHeader
        roles={roles}
        permissions={permissions}>
        <Page404 />
      </MainHeader>
    )
  }

  return (
    <MainHeader
      headerComponent={
        <SegmentFormHeader
          title={title}
          onBack={handleBack}
          onOk={onOk}
          okButtonProps={saveButtonProps}
          onCancel={isCampaignWebpushPage ? undefined : onCancel}
          hideNavigation={isCampaignWebpushPage}
        />
      }
      bgColor="#F7F9FC"
      stickyHeader
      roles={roles}
      permissions={permissions}>
      <StyledSegmentForm>
        <Row gutter={[24, 24]}>
          {/* {isPublic && (
            <Col span={24}>
              <Alert
                type="warning"
                message="You can view the status of each segment, click to publish your draft segment to create its view. Your Public Segments will be available in many products for you to select and use while creating campaigns and workflows."
              />
            </Col>
          )} */}
          <Col span={12}>
            <StyledSegmentFormBox>
              <SegmentFormOverview
                loading={loading}
                form={overviewForm}
                segment_type={segment_type}
                disabled={disabled}
                disabledKeys={disabledKeys}
                initialValues={value}
                onValuesChange={onValuesChange}
              />
            </StyledSegmentFormBox>
          </Col>
          <Col span={12}>
            <StyledSegmentFormBox>
              <SegmentFormComparison
                segmentId={parseInt(`${value?.id}`)}
                disabledComparison
              />
            </StyledSegmentFormBox>
          </Col>
          <Col span={24}>
            <StyledSegmentFormBox>
              <StyledSegmentFormRow>
                <StyledSegmentFormCol className="form_container">
                  <SegmentFormQuery
                    form={queryForm}
                    disabled={disabled}
                    disabledKeys={
                      isPublic && disabledKeys
                        ? [...disabledKeys, 'list']
                        : disabledKeys
                    }
                    segment_type={segment_type}
                    segment_status={value?.status}
                    sites={sites}
                    initialValues={value}
                    onValuesChange={onValuesChange}
                    onFinish={onFinishFormQuery}
                  />
                </StyledSegmentFormCol>
                <StyledSegmentFormCol className="chart_container">
                  <SegmentUserCounter
                    numOfUsers={reachable?.totalCount || 0}
                    total={sitesTotal || 0}
                  />
                </StyledSegmentFormCol>
              </StyledSegmentFormRow>
            </StyledSegmentFormBox>
          </Col>
          <Col span={24}>
            <StyledSegmentFormBox className="analytics_container">
              {defaultValuePrediction?.value?.length && (
                <Alert
                  showIcon
                  type="warning"
                  className="mb-4"
                  message={
                    <span>
                      Results are based on predictions within the range of&nbsp;
                      <strong>
                        {getSegmentPredictionSliderValue(
                          defaultValuePrediction.value
                        )
                          .map((s) => `${s}%`)
                          .join(' - ')}
                      </strong>
                      .
                    </span>
                  }
                />
              )}
              <SegmentReachable
                loading={loadingReachable}
                numOfUsers={reachable?.totalCount || 0}
                reachableData={{
                  AppPushCount: `${reachable?.appPushCount}`,
                  EmailCount: `${reachable?.email}`,
                  // ExcludeFacebookCount: `${analytics?.}`,
                  // FacebookCount: `${analytics?.FacebookCount}`,
                  PhoneCount: `${reachable?.phoneCount}`,
                  TotalCount: `${reachable?.totalCount}`,
                  WebPushCount: `${reachable?.webPushCount}`,
                  WhatsAppCount: `${reachable?.whatsappCount}`
                }}
              />
              <div className="mt-4 flex gap-3">
                <SegmentPredictionDrawer
                  loading={loadingPrediction}
                  data={prediction}
                  disabled={disabledPrediction}
                  open={openPrediction}
                  onClose={setOpenPrediction}
                  defaultValue={defaultValuePrediction}
                  onChange={onChangeSegmentPrediction}
                  onFinish={onFinishSegmentPrediction}
                />
                <SegmentAnalyticDrawer
                  loading={loadingAnalytic}
                  segmentName={value?.name}
                  segmentId={value?.id}
                  segmentType={value?.segment_type}
                  segmentSitesID={sites?.map(({ value, domain }) => ({
                    id: parseInt(value.toString()),
                    name: domain
                  }))}
                  segmentStatus={value?.status}
                  onSaveSegment={onSaveSegment}
                />
              </div>
            </StyledSegmentFormBox>
          </Col>
        </Row>
        <div
          className={cn(
            'absolute inset-0 z-0 w-full h-full pointer-events-none opacity-0 flex items-center justify-center',
            {
              'z-[99]': loading,
              'opacity-100': loading,
              'pointer-events-auto': loading
            }
          )}>
          <div className="w-full h-full bg-neutral100 absolute inset-0 opacity-50" />
          <Spin spinning />
        </div>
      </StyledSegmentForm>
      <ModalEmpty
        open={modal}
        title="Leaving without save?"
        onOk={confirmModalProps?.onOk}
        okButtonProps={{ danger: true }}
        okText="Confirm"
        onCancel={() => setModal(false)}
        cancelButtonProps={{
          onClick: () => setModal(false)
        }}
        cancelText="Exit">
        If you leave, any changes you’ve made will not be saved.
      </ModalEmpty>
    </MainHeader>
  )
}

export { formatCountDataToReachable, formatSegmentForm }
export type {
  FetchQueryAnalyticsInput,
  SegmentFormContainerProps,
  SegmentFormContainerRef,
  SegmentFormValue
}
export const SegmentForm = forwardRef(SegmentFormWithoutRef)
