import { Button, Col, Dropdown, Row, Tabs } from 'antd'
import styled from 'styled-components'
import { cssLimitLines } from 'styles'

export const StyledDictionaryParagraph = styled.p`
  &.tooltip {
    ${cssLimitLines(1)}
    margin-bottom: 0;
  }
`

export const StyledDictionaryDropdown = styled(Dropdown)`
  .dropdown_overlay_action {
    min-width: 140px !important;
  }
`

export const StyledDictionaryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &.filter {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.25s linear all;
    &:hover {
      background-color: ${({ theme }) => theme?.colors?.gray100};
    }
    &.active {
      background-color: ${({ theme }) => theme?.colors?.gray100};
    }
  }
`

export const StyledDictionaryButton = styled(Button)`
  &.action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px !important;
    height: 32px !important;
    padding: 0;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.25s linear all;
    &:hover {
      background-color: ${({ theme }) => theme?.colors?.gray100};
    }
    &.ant-dropdown-open {
      background-color: ${({ theme }) => theme?.colors?.gray100};
    }
  }
`

export const StyledDictionaryCell = styled.div`
  display: flex;
  align-items: center;
`

export const StyledDictionaryTabs = styled(Tabs)`
  .ant-tabs-tab-btn {
    color: ${({ theme }) => theme?.colors?.gray500};
    font-weight: 700;
  }
  &.table_tabs {
    > .ant-tabs-nav {
      margin: 0;
    }
  }
`

export const StyledDictionarySeachContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  .add {
    padding-inline: 16px;
  }
`

export const StyledDictionaryCol = styled(Col)``

export const StyledDictionaryRow = styled(Row)``
