import styled from 'styled-components'

export const StyledWebPushBuilder = styled.div`
  flex: 1;
  display: flex;
  background-color: ${(props) => props.theme.colors.white};
`

export const StyledWebPushDetail = styled.div`
  flex: 1;
  padding: 32px 24px;
`
export const StyledWebPushPreview = styled.div`
  flex: 1;
  padding: 32px 24px;
`

export const StyleContentReview = styled.div`
  max-width: 450px;
  margin: 0 auto;
`

export const StyleHeader = styled.h2`
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.6px;
  color: ${(props) => props.theme.colors.gray800};
  margin-bottom: 24px;
`
