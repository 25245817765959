import styled, { keyframes } from 'styled-components'

const spinner = keyframes`
  to { transform: rotate(360deg) }
`

const StyledWrapper = styled.div`
  display: flex;
  width: 88px;
  height: 56px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: -1px 1px 2px rgba(67, 70, 74, 0.0001),
    -2px 2px 5px rgba(67, 86, 100, 0.123689);
`

const StyledSpinner = styled.div`
  width: 24px;
  height: 24px;
  animation: ${spinner} 0.7s linear infinite;
  background-image: url(/images/spinner.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
`

export function Spinner() {
  return (
    <StyledWrapper>
      <StyledSpinner />
    </StyledWrapper>
  )
}
