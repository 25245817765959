import {
  AnalyticWidgetItemProps,
  WIDGET_ITEM_HIGHCHARTS_EMPTY_PROPS,
  WIDGET_ITEM_KEY,
  WIDGET_ITEM_LABEL,
  usePeriodLabel
} from './AnalyticWidgetItem.helpers'
import { HighchartsSpider } from 'components/atoms/Highcharts'
import { theme } from 'constants/theme'
import { FC, useMemo } from 'react'

const reachabilityByChannelsKeys = [
  WIDGET_ITEM_KEY.EMAIL,
  WIDGET_ITEM_KEY.WEB,
  WIDGET_ITEM_KEY.PHONE,
  WIDGET_ITEM_KEY.APP,
  WIDGET_ITEM_KEY.WHATSAPP
]
export const AnalyticWidgetItemReachabilityByChannels: FC<
  AnalyticWidgetItemProps
> = (props) => {
  const { data, item } = props
  const label = usePeriodLabel(item?.query?.period)
  const highchartsOptions = useMemo(() => {
    return formatReachabilityByChannels(data)
  }, [data])

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between">
        <h4 className="period mb-0 text-gray600">{label}</h4>
      </div>

      <div className="flex-1 min-h-0">
        <HighchartsSpider
          options={highchartsOptions}
          className="h-full"
          emptyProps={WIDGET_ITEM_HIGHCHARTS_EMPTY_PROPS}
        />
      </div>
    </div>
  )
}

export function formatReachabilityByChannels(data: any): Highcharts.Options {
  const highchartsOptions: Highcharts.Options = {
    chart: {
      polar: true
    },
    xAxis: {
      labels: {
        enabled: false
      },
      lineColor: theme.colors.neutral100,
      gridLineWidth: 1,
      gridLineColor: theme.colors.neutral100,
      min: 0,
      max: 360,
      tickInterval: 120
    },
    yAxis: {
      labels: {
        enabled: false
      },
      gridLineWidth: 1,
      gridLineColor: theme.colors.neutral100,
      min: 0,
      max: 10000,
      tickInterval: 4000,
      margin: 40
    },
    legend: { enabled: true },
    plotOptions: {
      variablepie: {
        opacity: 0.9,
        center: ['50%', '50%'],
        dataLabels: {
          connectorWidth: 1,
          distance: 15,
          connectorPadding: 15
        },
        tooltip: {
          headerFormat: '',
          pointFormat: '{point.name}: <strong>{point.z}</strong>'
        },
        states: {
          hover: {
            opacity: 1,
            halo: {
              size: 3
            }
          }
        }
      }
    },
    series: []
  }
  const lineSeries: NonNullable<Highcharts.SeriesLineOptions> = {
    type: 'line',
    showInLegend: false
  }
  const variablepieSeries: NonNullable<Highcharts.SeriesVariablepieOptions> = {
    type: 'variablepie',
    data: [],
    showInLegend: true,
    colors: [
      theme.colors.blue600,
      theme.colors.pink600,
      theme.colors.yellow600,
      theme.colors.redViolet600,
      theme.colors.green600,
      theme.colors.violet600
    ] as string[]
  }
  try {
    reachabilityByChannelsKeys.forEach((key, index) => {
      if (key in data) {
        const value = data[key as keyof typeof data]
        if (value) {
          variablepieSeries.data?.push({
            name: WIDGET_ITEM_LABEL[key],
            y: 10000,
            z: value
          })
          lineSeries.data?.push(value)
        }
      }
    })
  } catch (error) {}
  if (variablepieSeries.data?.length) {
    highchartsOptions.series?.push(variablepieSeries)
    highchartsOptions.series?.push(lineSeries)
    ;(
      highchartsOptions.xAxis as NonNullable<Highcharts.XAxisOptions>
    ).tickInterval = 360 / variablepieSeries.data.length
  }
  return highchartsOptions
}

// const mockup = {
//   app_token: 143,
//   detail: [
//     {
//       app_token: 143,
//       date: '2024-06-14',
//       email: 400,
//       phone: 360,
//       web_token: 13,
//       whatsapp: 1
//     }
//   ],
//   email: 400,
//   phone: 360,
//   web_token: 13,
//   whatsapp: 1
// }
