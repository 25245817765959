import { TEMPLATE_MESSAGE } from '../[template_type]'
import { notification } from 'antd'
import {
  CAMPAIGN_STEP_KEYS,
  CampaignFormLandingPage,
  CampaignLandingPageFormProps,
  CampaignLandingPageFormRef,
  CampaignLandingPageFormValue,
  transferCampaignFormLandingPageValue
} from 'components/molecules/campaign'
import { CAMPAIGN_STATUS } from 'constants/campaign'
import { RESPONSE_MESSAGE } from 'constants/common'
import { PERMISSIONS } from 'constants/permission'
import { TEMPLATE_TYPE } from 'constants/template'
import { useCheckAuth } from 'hooks/useCheckAuth'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CampaignServices } from 'services/campaign'

export const CampaignLandingPageEdit = () => {
  const { id } = useParams()
  const campaignRef = useRef<CampaignLandingPageFormRef | null>(null)
  const [value, setValue] = useState<CampaignLandingPageFormValue>({})
  const notFound = useRef(false)
  const [loading, setLoading] = useState(false)

  const isAuth = useCheckAuth({
    permissions: [PERMISSIONS.campaign_landing_page_edit]
  })

  const disabled = useMemo(() => {
    switch (value?.status) {
      case CAMPAIGN_STATUS.ready:
      case CAMPAIGN_STATUS.draft:
        return false
      case CAMPAIGN_STATUS.done:
      case CAMPAIGN_STATUS.cancel:
      case CAMPAIGN_STATUS.running:
      case CAMPAIGN_STATUS.schedule:
        return true
      default:
        if (typeof id !== 'undefined') {
          return true
        }
        return false
    }
  }, [id, value?.status])
  const disabledReady = useMemo(() => {
    return Boolean(value.status === CAMPAIGN_STATUS.ready)
  }, [value?.status])
  const label = useMemo(() => {
    return TEMPLATE_MESSAGE[TEMPLATE_TYPE.LP_PAGE]
  }, [])

  const handleSave: NonNullable<
    CampaignLandingPageFormProps['onSave']
  > = async (values) => {
    if (!id) {
      return
    }
    if (!isAuth) {
      notification.error({
        message: RESPONSE_MESSAGE[403]
      })
      return
    }
    if (disabled) {
      notification.error({
        message: `Campaign with id ${id} must be either in Ready or Draft status to be updated`
      })
      return
    }
    setLoading(true)
    const isDraft = values.status === CAMPAIGN_STATUS.draft
    try {
      const response: any = await CampaignServices.updateCampaign(id, {
        name: values?.name || '',
        description: values?.description || '',
        approach: values?.approach || 1,
        client_ids: values?.client_ids || [],
        start_time: values?.start_time || '',
        end_time: values?.end_time || '',
        flow_id: values?.flow_id || null,
        segments_ids: values?.segments_ids || [],
        site_ids: values?.site_ids || [],
        template_id: values?.template_id || null,
        time_zone: values?.time_zone || '',
        type: values?.type || '',
        status: CAMPAIGN_STATUS.draft,
        template_type: values?.template_type || ''
      })

      if (response?._message || !response.data) {
        throw new Error(JSON.stringify(response))
      }

      const successMessage = isDraft
        ? `The ${label} campaign was saved draft successfully`
        : `The ${label} campaign was updated completely`
      notification.success({ message: successMessage })
      setValue(values)
    } catch (error: any) {
      console.log('** ERROR CampaignLandingPageEdit.tsx 46 : ', error)
      if (error?.message && typeof error.message === 'string') {
        const errorObj = JSON.parse(error.message)
        if (errorObj?._message) {
          const fields: any[] = []
          const message = errorObj?._message
          if (message.includes('name')) {
            campaignRef.current?.onChangeStep(CAMPAIGN_STEP_KEYS.overviews)
            fields.push({
              name: 'name',
              value: values.name,
              errors: ['Name is existing. Please input another name.']
            })
          }
          const messageArr = message.split(':')
          if (messageArr?.[0].includes('end_time')) {
            campaignRef.current?.onChangeStep(CAMPAIGN_STEP_KEYS.overviews)
            fields.push({
              name: messageArr?.[0],
              value: values.end_time,
              errors: [messageArr?.[1]]
            })
          }
          // Forsure exists overviewsForm
          setTimeout(() => {
            campaignRef.current?.overviewsForm?.setFields(fields)
          }, 250)
        }
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!id) {
      return
    }
    ;(async () => {
      setLoading(true)
      try {
        const response: any = await CampaignServices.getById(id)
        if (response?._message || !response?.data) {
          notFound.current = true
          throw new Error(JSON.stringify(response))
        }
        if (response?.data && response.data?.template_type !== 'ld_page') {
          notFound.current = true
        }
        setValue(transferCampaignFormLandingPageValue(response.data))
      } catch (error) {
        console.log('** ERROR CampaignLandingPageEdit.tsx 30  : ', error)
      }
      setLoading(false)
    })()
  }, [id])

  return (
    <CampaignFormLandingPage
      ref={campaignRef}
      title="Edit Campaign"
      loading={loading}
      disabled={!isAuth || disabled}
      disabledReady={disabledReady}
      notFound={notFound.current}
      template_type="ld_page"
      defaultValue={value}
      value={value}
      onSave={handleSave}
      permissions={[
        PERMISSIONS.campaign_view,
        PERMISSIONS.campaign_landing_page_edit
      ]}
    />
  )
}
