/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, notification } from 'antd'
import axios from 'axios'
import { Box } from 'components/atoms/box'
import { COOKIE_AUTH_TOKEN } from 'constants/common'
import { getToken } from 'context/Auth'
import Cookies from 'js-cookie'
import FeatureCarousel from 'pages/login/FeatureCarousel'
import LoginFooter from 'pages/login/LoginFooter'
import { FC, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { login } from 'services/user'

const LoginPage: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (getToken()) {
      navigate('/')
    }
  }, [navigate])

  const onSubmitLogin = async (params: { email: string; password: string }) => {
    if (loading) return

    try {
      setLoading(true)
      const response = await login(params)

      const loginUrl = response['Sidis'] || response['Sidis Sandbox']
      const responseUrlOauth = await axios.get(loginUrl)

      const tokenUrl = responseUrlOauth.data.data.url
      const responseToken = await axios.get(tokenUrl)
      let { access_token, expire_time } = responseToken.data.data

      // Step to approve
      if (responseToken.data.data.url_approve) {
        const { auth_token, url_approve } = responseToken.data.data
        const responseApproved = await axios.post(url_approve, {
          auth_token
        })
        access_token = responseApproved.data.data.access_token
        expire_time = responseApproved.data.data.expire_time
      }

      Cookies.set(COOKIE_AUTH_TOKEN, access_token, {
        expires:
          (new Date(expire_time).getTime() - new Date().getTime()) /
          1000 /
          60 /
          60 /
          24
      })

      const redirectUrl = new URLSearchParams(location.search).get('redirect')

      if (redirectUrl) {
        window.location.href = redirectUrl
      } else {
        window.location.href = '/'
        return
      }
    } catch (err) {
      console.log('[Login] error', err)
      notification.error({
        message: 'Oops!',
        description:
          'Something went wrong. Please try again or contact support.',
        placement: 'topLeft'
      })
    }

    setLoading(false)
  }

  return (
    <Box className="flex flex-row md:h-[100vh] bg-[#FAFAFA]">
      <Box className="flex flex-1 h-full flex-col justify-center items-center relative overflow-y-auto">
        <Box className="text-center max-w-full w-[440px] p-[40px] bg-[#fff] rounded-[16px] my-auto">
          <img
            src="/images/sidis-logo.png"
            alt="Sidis"
            className="w-[96px] h-auto block mb-[40px] mx-auto"
          />
          <h2 className="text-[#262626] font-bold text-[22px] leading-[32px] tracking-[-0.6px]">
            Log in to SIDIS
          </h2>
          <p className="text-base mt-[8px] mb-[24px] text-[#595959]">
            Enter your credentials to access your account.
          </p>

          <Form
            layout="vertical"
            className="text-left"
            onFinish={onSubmitLogin}>
            <Form.Item
              label="Email"
              name="email"
              requiredMark="optional"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Please input a valid email' }
              ]}>
              <Input
                size="large"
                className="!py-[12px]"
                placeholder="Enter your email here"
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              requiredMark="optional"
              rules={[
                { required: true, message: 'Please input your password!' }
              ]}>
              <Input.Password
                size="large"
                className="!py-[12px]"
                placeholder="Enter your password here"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={loading}
                className="w-full block">
                Continue
              </Button>
            </Form.Item>
          </Form>

          <p className="text-[#737373] text-[14px] leading-[22px] mr-[24px tracking-[-0.2px]">
            Forget your password?{' '}
            <Link to="/reset-password" className="text-[#2D87F3] font-semibold">
              Reset password
            </Link>
          </p>
        </Box>
        <LoginFooter />
      </Box>
      <FeatureCarousel />
    </Box>
  )
}

export default LoginPage
