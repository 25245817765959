import App from './pages'
import './styles/antd.less'
import './styles/globals.scss'
import './styles/tailwind.scss'
import '@luctc/template-builder/dist/index.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import 'codemirror/lib/codemirror.css'
import { theme } from 'constants/theme'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

const queryClient = new QueryClient()

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <ConfigProvider>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ConfigProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
