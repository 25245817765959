import { Button, Col, Row } from 'antd'
import styled from 'styled-components'

export const StyleRow = styled(Row)`
  gap: 20px;

  &.row-adjust-item {
    .ant-col {
      flex: 0 0 calc(25% - 10px);
    }
  }
`

export const StyleCol = styled(Col)`
  &.col-visible {
    .ant-form-item-label {
      visibility: hidden;
    }
  }

  &.col-recurring {
    .ant-form-item-row {
      flex-flow: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .ant-form-item-control {
        flex: 1;
      }

      .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`

export const StyleTemplateRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 20px;

  .ant-form-item {
    margin: 0;
  }

  .created-by {
    flex: 0 0 250px;
    overflow: hidden;
  }

  .template {
    flex: 1;
    overflow: hidden;
  }
`
export const StyleTemplateOrder = styled.div`
  flex: 0 0 20px;
  height: 40px;
  display: flex;
  align-items: center;
`
export const StyleTemplateLink = styled(Button)`
  justify-content: center;
  display: flex;
  align-items: center;
`

export const StyleButton = styled(Button)``

export const StyleWrapperButton = styled.div`
  margin-left: 40px;
`

export const StyleWrapperQuery = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;

  .user {
    line-height: 22px;
    color: ${(props) => props.theme?.colors?.gray800};
  }
`

export const StyleCard = styled.div`
  background-color: ${({ theme }) => theme?.colors?.white};
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
`

export const StyleCardTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme?.colors?.gray800};
  margin-bottom: 20px;
`
