import styled from 'styled-components'

export const StyledLabel = styled.div`
  flex: 1;
  min-width: 0;
  margin-left: 8px;
`

export const StyledIcon = styled.div`
  font-size: 16px;
  line-height: 1;
`

export const StyledStatus = styled.div`
  display: flex;
  align-items: center;
`
