import {
  CampaignStepOverviewsLandingPage,
  CampaignStepSegments,
  CampaignStepSites,
  CampaignStepTemplates
} from '../CampaignStep'
import {
  CAMPAIGN_STEP_ITEMS,
  CAMPAIGN_STEP_KEYS,
  CampaignStepHeader
} from '../CampaignStepHeader'
import {
  CampaignLandingPageFormProps as CampaignFormLandingPageProps,
  CampaignLandingPageFormRef,
  CampaignLandingPageFormValue
} from './CampaignLandingPageForm.helpers'
import {
  StyledAlert,
  StyledCampaignLandingPageContainer
} from './CampaignLandingPageForm.styled'
import Icon from '@ant-design/icons'
import { FormInstance } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { menuRoutes } from 'components/molecules/layout'
import MainHeader from 'components/molecules/layout/mainHeader'
import { CAMPAIGN_STATUS } from 'constants/campaign'
import { useSessionStorage } from 'hooks/useSessionStorage'
import { Close, Warning } from 'icons'
import Page404 from 'pages/404'
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'

const CampaignFormLandingPageWithoutRef = (
  props: CampaignFormLandingPageProps,
  ref?: Ref<CampaignLandingPageFormRef>
) => {
  const navigate = useNavigate()
  const {
    title,
    notFound,
    loading,
    disabled,
    disabledReady,
    defaultValue,
    value,
    template_type,
    onSave,
    roles,
    permissions
  } = props
  const overviews = useRef<FormInstance>(null)
  const sites = useRef<FormInstance>(null)
  const segments = useRef<FormInstance>(null)
  const templates = useRef<FormInstance>(null)
  const [step, setStep] = useSessionStorage(
    '$campaignStep',
    CAMPAIGN_STEP_KEYS.overviews
  )
  const [values, setValues] = useState(defaultValue || value)
  const [valuesChanged, setValuesChanged] = useState(defaultValue || value)
  const [modalOpen, setModalOpen] = useState(false)

  const items = useMemo(() => {
    return CAMPAIGN_STEP_ITEMS.map((stepItem) => {
      let disabled = step <= stepItem.key
      switch (stepItem.key) {
        case CAMPAIGN_STEP_KEYS.sites:
          disabled = !Boolean(valuesChanged?.name)
          break
        case CAMPAIGN_STEP_KEYS.segments:
          disabled = !Boolean(
            valuesChanged?.site_ids && valuesChanged.site_ids?.length
          )
          break
        case CAMPAIGN_STEP_KEYS.templates:
          disabled = !Boolean(
            valuesChanged?.segments_ids && valuesChanged.segments_ids?.length
          )
          break
      }
      return { ...stepItem, disabled }
    })
  }, [
    step,
    valuesChanged?.name,
    valuesChanged?.segments_ids,
    valuesChanged?.site_ids
  ])
  const site = useMemo(() => {
    if (valuesChanged?.site_ids && valuesChanged?.site_ids?.[0]) {
      return valuesChanged?.site_ids?.[0]
    }
    return ''
  }, [valuesChanged])
  const okText = useMemo(() => {
    if (step < CAMPAIGN_STEP_KEYS.templates) {
      return 'Next'
    }
    return 'Save'
  }, [step])

  const handleValuesChange = (
    _valueChanged: any,
    _valuesChanged: CampaignLandingPageFormValue
  ) => {
    const newValuesChanged = { ...valuesChanged, ..._valuesChanged }
    if (
      _valueChanged.hasOwnProperty('site_ids') &&
      JSON.stringify(values?.site_ids) !==
        JSON.stringify(newValuesChanged.site_ids)
    ) {
      newValuesChanged.segments_ids = undefined
      newValuesChanged.template_id = undefined
      setValues(newValuesChanged)
    }
    setValuesChanged(newValuesChanged)
  }
  const handleFinish = useCallback(
    (vals: CampaignLandingPageFormValue) => {
      const newValues = { ...values, ...vals }
      setValues(newValues)
      setValuesChanged(newValues)
      if (step === items[items.length - 1].key) {
        onSave?.({ ...newValues, status: CAMPAIGN_STATUS.ready })
      }
    },
    [items, onSave, step, values]
  )
  const handleChangeStep = useCallback(
    async (stepChanged: number) => {
      if (stepChanged < step) {
        setStep(stepChanged)
        return
      }
      try {
        let vals = {}
        let nextStep = stepChanged
        switch (step) {
          case CAMPAIGN_STEP_KEYS.overviews: {
            vals = await overviews.current?.validateFields()
            break
          }
          case CAMPAIGN_STEP_KEYS.sites: {
            vals = await sites.current?.validateFields()
            break
          }
          case CAMPAIGN_STEP_KEYS.segments: {
            vals = await segments.current?.validateFields()
            break
          }
          case CAMPAIGN_STEP_KEYS.templates: {
            vals = await templates.current?.validateFields()
            nextStep = CAMPAIGN_STEP_KEYS.templates
            break
          }
        }
        handleFinish(vals)
        setStep(nextStep)
      } catch (error: any) {
        if (error?.errorFields && error?.errorFields?.length) {
          error.errorFields.forEach((errorField: any) => {
            if (errorField?.name && errorField.name?.length) {
              if (errorField.name.includes('site_ids')) {
                sites.current?.submit()
              }
              if (errorField.name.includes('segments_ids')) {
                segments.current?.submit()
              }
              if (errorField.name.includes('template_id')) {
                templates.current?.submit()
              }
            }
          })
        }
      }
      return
    },
    [handleFinish, step]
  )

  const handleOk = useCallback(
    (nextStep: number) => {
      return async () => {
        await handleChangeStep(nextStep)
      }
    },
    [handleChangeStep]
  )

  const handleOnBack = useCallback(() => {
    if (JSON.stringify(values) === JSON.stringify(valuesChanged)) {
      navigate(`${menuRoutes.campaign}`)
      return
    }
    setModalOpen(true)
  }, [navigate, values, valuesChanged])
  const handleModalOnClose = useCallback(() => {
    setModalOpen(false)
  }, [])
  const handleModalOnCancel = () => {
    handleModalOnClose()
    navigate(`${menuRoutes.campaign}`)
  }
  const handleModalOnOk = useCallback(() => {
    onSave?.({ ...valuesChanged, status: CAMPAIGN_STATUS.draft })
    handleModalOnClose()
  }, [handleModalOnClose, onSave, valuesChanged])

  const handleDropdownOnClickItem = (item: ItemType) => {
    if (item?.key === 'draft') {
      onSave?.({ ...valuesChanged, status: CAMPAIGN_STATUS.draft })
    }
  }

  useEffect(() => {
    setValues(value)
    setValuesChanged(value)
  }, [value])

  useImperativeHandle(
    ref,
    () => {
      return {
        overviewsForm: overviews.current,
        sitesForm: sites.current,
        segmentsForm: segments.current,
        templatesForm: templates.current,
        onChangeStep: handleChangeStep,
        modalOnClose: handleModalOnClose
      }
    },
    [handleChangeStep, handleModalOnClose]
  )

  if (notFound) {
    return (
      <MainHeader
        headerComponent={
          <CampaignStepHeader
            onBack={() => navigate(`${menuRoutes.campaign}`)}
            onCancel={() => navigate(`${menuRoutes.campaign}`)}
          />
        }
        bgColor="#F7F9FC">
        <Page404 />
      </MainHeader>
    )
  }

  return (
    <MainHeader
      headerComponent={
        <CampaignStepHeader
          title={title}
          onBack={handleOnBack}
          stepCurrent={step}
          stepItems={items}
          stepOnChange={handleChangeStep}
          modalOpen={modalOpen}
          modalOnClose={handleModalOnClose}
          modalOnCancel={handleModalOnCancel}
          modalOnOk={handleModalOnOk}
          isShowDropdown={!disabledReady ? !disabled : false}
          dropdownMenuItems={[
            {
              key: 'draft',
              label: 'Save changed'
            }
          ]}
          dropdownMenuOnClick={handleDropdownOnClickItem}
          onCancel={handleOnBack}
          okText={okText}
          okButtonProps={{
            loading: loading,
            disabled: step === CAMPAIGN_STEP_KEYS.templates ? disabled : false
          }}
          onOk={handleOk(step + 1)}
        />
      }
      bgColor="#F7F9FC"
      stickyHeader
      roles={roles}
      permissions={permissions}>
      <StyledCampaignLandingPageContainer className={`container_${step}`}>
        {disabled && !loading && (
          <StyledAlert
            closable
            closeIcon={<Icon component={Close} />}
            showIcon
            icon={<Icon component={Warning} />}
            type="warning"
            description="Since you have already launched your campaign, you can't edit it."
          />
        )}
        {Boolean(step === CAMPAIGN_STEP_KEYS.overviews) && (
          <CampaignStepOverviewsLandingPage
            name="overviews"
            ref={overviews}
            loading={loading}
            disabled={disabledReady || disabled}
            template_type={template_type}
            initialValues={values}
            onValuesChange={handleValuesChange}
            onFinish={handleFinish}
          />
        )}
        {Boolean(step === CAMPAIGN_STEP_KEYS.sites) && (
          <CampaignStepSites
            name="sites"
            ref={sites}
            loading={loading}
            disabled={disabledReady || disabled}
            template_type={template_type}
            initialValues={values}
            onValuesChange={handleValuesChange}
            onFinish={handleFinish}
          />
        )}
        {Boolean(step === CAMPAIGN_STEP_KEYS.segments) && (
          <CampaignStepSegments
            name="segments"
            ref={segments}
            site={site}
            loading={loading}
            disabled={disabledReady || disabled}
            template_type={template_type}
            initialValues={values}
            onValuesChange={handleValuesChange}
            onFinish={handleFinish}
          />
        )}
        {Boolean(step === CAMPAIGN_STEP_KEYS.templates) && (
          <CampaignStepTemplates
            name="templates"
            ref={templates}
            site={site}
            loading={loading}
            disabled={!disabledReady ? disabled : false}
            template_type={template_type}
            initialValues={values}
            onValuesChange={handleValuesChange}
            onFinish={handleFinish}
          />
        )}
      </StyledCampaignLandingPageContainer>
    </MainHeader>
  )
}
export const CampaignFormLandingPage = forwardRef(
  CampaignFormLandingPageWithoutRef
)
