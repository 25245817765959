import { OptionType } from 'interfaces'

export function sortOptionTypeArray(optionsProps?: OptionType[]): OptionType[] {
  return (
    optionsProps?.sort((optA, optB) => {
      if (typeof optA.label === 'string' && typeof optB.label === 'string') {
        return optA.label > optB.label ? 1 : -1
      }
      return 0
    }) || []
  )
}
