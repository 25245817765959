import { CampaignMessagingInsightFilterValue } from '../CampaignMessagingInsightFilter'
import { CAMPAIGN_MESSAGING_INSIGHT_OVERVIEW_ITEMS } from '../CampaignMessagingInsightTabOverview'
import {
  GetCampaignMessagingInsightPerformanceOutput,
  getCampaignMessagingInsightPerformance
} from './CampaignMessagingInsightTabPerformance.helpers'
import { TableColumnProps, TablePaginationConfig } from 'antd'
import { TableScroll } from 'components/atoms/table'
import dayjs from 'dayjs'
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import styled from 'styled-components'
import { formatNumberToCurrency } from 'utils'

const StyledTableScroll = styled(TableScroll)`
  .ant-table-footer {
    padding-bottom: 0;
  }
`

const columnsBase: TableColumnProps<any>[] = [
  { title: 'Campain', dataIndex: 'campaign_name', width: 300, fixed: true },
  { title: 'Type', dataIndex: 'campaign_type', width: 100 },
  {
    title: 'Launch date',
    dataIndex: 'launch_date',
    width: 180,
    render(value) {
      const date = dayjs(value, 'YYYY-MM-DD', true)
      if (date.isValid()) {
        return date.format('DD/MM/YYYY')
      }
      return null
    }
  },
  { title: '', dataIndex: 'total_send', width: 140 },
  { title: '', dataIndex: 'delivery_rate', width: 140 },
  { title: '', dataIndex: 'click_rate', width: 140 },
  { title: '', dataIndex: 'total_opens', width: 140 },
  { title: '', dataIndex: 'unique_email_sends', width: 140 },
  { title: '', dataIndex: 'unique_email_delivery', width: 140 },
  { title: '', dataIndex: 'click_divide_opens', width: 140 }
]

export type CampaignMessagingInsightTabPerformanceRef = {
  refresh?: (
    value: CampaignMessagingInsightFilterValue,
    pagination?: GetCampaignMessagingInsightPerformanceOutput['meta']
  ) => void
}
export type CampaignMessagingInsightTabPerformanceProps = {
  params?: CampaignMessagingInsightFilterValue
}
type CampaignMessagingInsightTabPerformanceState = {
  pagination: GetCampaignMessagingInsightPerformanceOutput['meta']
  dataSource: any[]
}
export const CampaignMessagingInsightTabPerformanceWithoutRef = (
  props: CampaignMessagingInsightTabPerformanceProps,
  ref?: Ref<CampaignMessagingInsightTabPerformanceRef>
) => {
  const { params } = props
  const [loading, setLoading] = useState(false)
  const [tableProps, setTableProps] =
    useState<CampaignMessagingInsightTabPerformanceState>({
      pagination: {
        current_page: 1,
        per_page: 10,
        total_items: 0,
        total_page: 0
      },
      dataSource: []
    })

  const columns = useMemo(() => {
    return columnsBase.map((column) => {
      const { dataIndex } = column
      const key = dataIndex?.toString() || ''
      if (key in CAMPAIGN_MESSAGING_INSIGHT_OVERVIEW_ITEMS) {
        const option = CAMPAIGN_MESSAGING_INSIGHT_OVERVIEW_ITEMS[key]

        return {
          ...column,
          title: option.label,
          render(value) {
            return (
              <>
                {formatNumberToCurrency((value || 0) as number)}
                {option.unit}
              </>
            )
          }
        } as TableColumnProps<any>
      }

      return column
    })
  }, [])

  const refresh: NonNullable<
    CampaignMessagingInsightTabPerformanceRef['refresh']
  > = useCallback(async (params, pagi) => {
    const { date_recorded, campaigns } = params
    const { current_page = 1, per_page = 10 } = pagi || {}
    if (
      !date_recorded ||
      (date_recorded?.length &&
        !date_recorded?.[0].isValid() &&
        !date_recorded?.[1].isValid())
    ) {
      return
    }
    setLoading(true)
    const { data, meta } = await getCampaignMessagingInsightPerformance({
      from: date_recorded[0].valueOf(),
      to: date_recorded[1].valueOf(),
      list_campaign: campaigns?.map(String) as [],
      page: current_page,
      per_page
    })
    setTableProps({
      dataSource: data,
      pagination: {
        ...meta
      }
    })
    setLoading(false)
  }, [])

  const handleChange = useCallback(
    (pagination: TablePaginationConfig) => {
      refresh(params as CampaignMessagingInsightFilterValue, {
        current_page: pagination.current || 1,
        per_page: pagination.pageSize || 10,
        total_items: 0
      })
    },
    [params, refresh]
  )

  useEffect(() => {
    refresh(params as CampaignMessagingInsightFilterValue)
  }, [])

  useImperativeHandle(ref, () => ({ refresh }), [refresh])
  return (
    <StyledTableScroll
      loading={loading}
      columns={columns}
      dataSource={tableProps.dataSource || []}
      rowKey={(record) => `_${record.campaign_name}`}
      pagination={{
        total: tableProps.pagination.total_items,
        current: tableProps.pagination.current_page,
        pageSize: tableProps.pagination.per_page,
        position: []
      }}
      onChange={handleChange}
    />
  )
}

export const CampaignMessagingInsightTabPerformance = forwardRef(
  CampaignMessagingInsightTabPerformanceWithoutRef
)
