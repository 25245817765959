import {
  StyledDrawer,
  StyledDrawerExtra,
  StyledDrawerIframe
} from './DrawerPreviewTemplate.styled'
import Icon, { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { DrawerProps } from 'antd'
import { Box } from 'components/atoms/box'
import { ResultUnderContruction } from 'components/atoms/result'
import {
  PreviewAppPush,
  PreviewEmail,
  PreviewWebPush
} from 'components/molecules/templateBuilder'
import { TEMPLATE_TYPE } from 'constants/template'
import { useApp } from 'context/App'
import { useZaloTemplates } from 'hooks/useZaloTemplates'
import { Close, Edit } from 'icons'
import View from 'pages/templateBuilderCreate/whatsApp/Preview/view'
import PreviewZalo from 'pages/templateBuilderCreate/zalo/Preview'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { getTemplateRoute } from 'utils/template'

export type DrawerPreviewTemplateProps = Pick<
  DrawerProps,
  'open' | 'onClose' | 'getContainer'
> & {
  data?: any
}

export const DrawerPreviewTemplate = (props: DrawerPreviewTemplateProps) => {
  const { open: openProps, onClose, getContainer, data } = props
  const [fullWidth, setFullWidth] = useState(false)
  const [open, setOpen] = useState(!!openProps || !!data?.id)
  const { sites } = useApp()
  const navigate = useNavigate()
  const { listZaloTemplate } = useZaloTemplates()

  console.log('data', data)
  console.log('** RYAN DrawerPreviewTemplate.tsx 36 data : ', data)
  const previewComp = useMemo(() => {
    if (!data) {
      return <ResultUnderContruction />
    }
    if (data?.template?.type === TEMPLATE_TYPE.APP_PUSH) {
      const reviewData = {
        title: data?.title,
        push_message: data?.push_message,
        image: data?.image
      }
      return <PreviewAppPush preview={reviewData} />
    }
    if (data?.template?.type === TEMPLATE_TYPE.ZALO) {
      const found = listZaloTemplate.find(
        (zt) => zt.zns_template_id === data?.template?.zns_template_id
      )
      return !found ? (
        <ResultUnderContruction />
      ) : (
        <Box className="flex w-full justify-center items-center h-full">
          <PreviewZalo previewUrl={found.preview_url} />
        </Box>
      )
    }
    if (data?.template?.type === TEMPLATE_TYPE.WEB_PUSH) {
      const reviewData = {
        name: data?.template.name,
        message_type: data?.message_type,
        title: data?.title,
        description: data?.description,
        message_icon: data?.message_icon,
        image: data?.image,
        message_link: data?.message_link,
        custom_link_url: data?.custom_link_url,
        utm_source: data?.utm_source,
        utm_medium: data?.utm_medium,
        utm_campaign: data?.utm_campaign,
        utm_content: data?.utm_content,
        utm_term: data?.utm_term,
        site: data?.template.site,
        country_code: data?.template.country_code,
        site_name: data?.template.site_name
      }
      return <PreviewWebPush preview={reviewData} sites={sites} />
    }
    if (data?.template?.type === TEMPLATE_TYPE.EMAIL) {
      const reviewData = {
        htmlTemplate: data?.template?.body_html || ''
      }
      return <PreviewEmail preview={reviewData} />
    }
    if (
      [
        TEMPLATE_TYPE.WHATSAPP,
        TEMPLATE_TYPE.TWILIO,
        TEMPLATE_TYPE.VNPT
      ].includes(data?.template?.type)
    ) {
      return (
        <View
          bodyExample={data?.template?.body_example || ''}
          bodyText={
            data?.template?.body_text || data?.template?.body_html || ''
          }
          bodyVariable={data?.template?.body_variable || []}
          footerText={data?.template?.footer_text || ''}
          headerExample={data?.template?.header_example || ''}
          headerFormat={data?.template?.header_format || ''}
          headerHandle={data?.template?.header_handle || ''}
          headerText={data?.template?.header_text || ''}
          headerVariable={data?.template?.header_variable || []}
          buttons={data?.template?.buttons || []} // TODO
          isMedia={data?.template?.type === TEMPLATE_TYPE.WHATSAPP}
        />
      )
    }
    if (data.template?.type === TEMPLATE_TYPE.LP_PAGE) {
      return <StyledDrawerIframe src={data?.full_url_link} />
    }
    return <ResultUnderContruction />
  }, [data, sites])

  const IconFullscreenComp = useMemo(() => {
    if (fullWidth) {
      return ZoomOutOutlined
    }
    return ZoomInOutlined
  }, [fullWidth])

  const handleEdit = useCallback(() => {
    const id = data?.template_id || data?.template?.id
    navigate(getTemplateRoute(id, data?.template?.type))
  }, [data?.template_id, data?.template?.id, data?.template?.type, navigate])

  useEffect(() => {
    setOpen(!!openProps || !!data?.id)
  }, [openProps, data?.id])

  useEffect(() => {
    return () => {
      setFullWidth(false)
    }
  }, [])

  const width = useMemo(() => {
    if (fullWidth) return '100%'

    if (
      data?.template?.type &&
      [TEMPLATE_TYPE.EMAIL, TEMPLATE_TYPE.LP_PAGE].includes(
        data?.template?.type
      )
    ) {
      return 868
    }

    return 648
  }, [data?.template?.type, fullWidth])

  return (
    <StyledDrawer
      className={data?.template?.type}
      width={width}
      title={data?.template?.name}
      open={open}
      mask={false}
      closeIcon={null}
      onClose={onClose}
      getContainer={getContainer}
      extra={
        <StyledDrawerExtra>
          <Icon component={Edit} onClick={handleEdit} />
          <IconFullscreenComp onClick={() => setFullWidth(!fullWidth)} />
          <Icon component={Close} onClick={onClose} />
        </StyledDrawerExtra>
      }>
      {previewComp}
    </StyledDrawer>
  )
}
