import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { RESPONSE_MESSAGE, RES_STATUS } from 'constants/common'
import { COOKIE_AUTH_TOKEN } from 'constants/common'
import { ENV } from 'constants/env'
import Cookies from 'js-cookie'

export function callOlympusApi(
  endpoint: string,
  options: AxiosRequestConfig = { params: {} }
) {
  // const bodyFormData = options.data ? new FormData() : null
  // if (bodyFormData) {
  // 	for (const [k, v] of Object.entries(options.data)) {
  // 		bodyFormData.append(k, v as string)
  // 	}
  // }
  return axios({
    url: `${ENV.baseOlympusApi}${endpoint}`,
    ...options,
    params: {
      ...options.params,
      client_id: ENV.baseOlympusClientId
    }
  }).then((resp) => {
    if (resp.data.status === -1) {
      return Promise.reject(resp.data)
    }
    return resp.data.data
  })
}

function axiosOnFulfilled(response: AxiosResponse<any, any>) {
  return {
    _status: RES_STATUS.SUCCESS,
    _statusRes: response.status,
    _message: '',
    data: response.data
  }
}

function axiosReject(error: any) {
  try {
    const { response, message: messageNetwork } = error

    if (!response) {
      throw new Error(messageNetwork)
    }

    const { data, status: statusRes, statusText } = response
    if (!data) {
      throw new Error(statusText)
    }

    const message =
      data?.error ||
      messageNetwork ||
      RESPONSE_MESSAGE[statusRes as keyof typeof RESPONSE_MESSAGE] ||
      RESPONSE_MESSAGE[RES_STATUS.FAIL]

    return {
      _status: RES_STATUS.FAIL,
      _statusRes: statusRes,
      _message: message,
      data: null
    }
  } catch (err) {
    return {
      _status: RES_STATUS.FAIL,
      _statusRes: error.status,
      _message: RESPONSE_MESSAGE[RES_STATUS.FAIL],
      data: null
    }
  }
}

export function axiosConfigOlympus(config: AxiosRequestConfig<any>) {
  const authToken = Cookies.get(COOKIE_AUTH_TOKEN)
  return {
    ...config,
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    }
  }
}

function axiosInterceptorsRejected(error: any) {
  return Promise.reject(error)
}

export const segmentBaseAxios = axios.create({
  baseURL: ENV.baseSegmentApi
})
segmentBaseAxios.interceptors.request.use(
  axiosConfigOlympus,
  axiosInterceptorsRejected
)
segmentBaseAxios.interceptors.response.use(axiosOnFulfilled, axiosReject)

export const segmentBuilderBaseAxios = axios.create({
  baseURL: ENV.baseSegmentBuilderApi
})
segmentBuilderBaseAxios.interceptors.request.use(
  axiosConfigOlympus,
  axiosInterceptorsRejected
)
segmentBuilderBaseAxios.interceptors.response.use(axiosOnFulfilled, axiosReject)

export const leadBaseAxios = axios.create({
  baseURL: ENV.baseLeadApi
})

export const templateBaseAxios = axios.create({
  baseURL: ENV.baseTemplateApi
})
templateBaseAxios.interceptors.request.use(callbackToAddToken)

/** Copilot */
export const copilotBaseAxios = axios.create({
  baseURL: ENV.baseCopilotApi
})
copilotBaseAxios.interceptors.request.use(callbackToAddToken)

function callbackToAddToken(config: any) {
  const authToken = Cookies.get(COOKIE_AUTH_TOKEN)
  return {
    ...config,
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    }
  }
}

// Common Axios sidis
export const sidisBaseAxios = axios.create({
  baseURL: ENV.baseApi
})

sidisBaseAxios.interceptors.request.use(callbackToAddToken, function (error) {
  return Promise.reject(error)
})

sidisBaseAxios.interceptors.response.use(axiosOnFulfilled, axiosReject)

// Axios internal API dynamic URL endpoint
export const sidisInternalBaseAxios = axios.create()

sidisInternalBaseAxios.interceptors.request.use(
  callbackToAddToken,
  function (error) {
    return Promise.reject(error)
  }
)

sidisInternalBaseAxios.interceptors.response.use(axiosOnFulfilled, axiosReject)
