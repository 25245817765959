import { Button, Space } from 'antd'
import styled from 'styled-components'
import { cssPadding, cssTitle } from 'styles'

export const StyledButton = styled(Button)`
  &.btnBack {
    width: 24px;
    height: 24px;
    padding: 0;
    margin-right: 2px;
    font-size: 24px;
    color: ${({ theme }) => theme?.colors?.gray600};
    > .anticon {
      font-size: 24px;
      > * {
        line-height: 0;
      }
    }
  }
  &.btn {
    padding-inline: 24px;
  }
`

export const StyledHeaderTitle = styled.h1`
  ${cssTitle}
  flex: 1;
  min-width: 0;
`

export const StyledHeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 50%;
`

export const StyledHeaderSpace = styled(Space)``

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const StyledHeader = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme?.colors?.white};
  position: sticky;
  top: 65px;
  z-index: 2;
  ${cssPadding}
`
