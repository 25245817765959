import styled from 'styled-components'

export const StyledWrap = styled.div`
  img {
    display: block;
  }
`

export const StyledActions = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;

  > li {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.colors.gray200};

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      margin-right: -1px;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-left: -1px;
    }

    svg {
      display: block;
    }

    &.active {
      color: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.primary};
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
`

export const StyledWrapImage = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`

export const StyledPreviewBox = styled.div`
  width: 375px;
  background: ${(props) => props.theme.colors.gray50};
  border: 1px solid ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  margin: 30px 0;
  padding: 10px 15px;
`

export const StyleWrapperSystem = styled.div`
  display: flex;
  gap: 20px;
  .select-view {
    width: 200px;
  }
`

export const StyleFinalUrl = styled.div`
  .title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: ${(props) => props.theme.colors.gray800};
    margin: 0 0 5px;
  }

  .url {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.colors.gray600};
    margin: 0;
    line-break: anywhere;
  }
`

export const StyleBrowserWrapper = styled.div`
  .message-icon-large {
    margin-bottom: 10px;
    display: block;
    width: 100%;
  }
  .chrome-window {
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
  }
  .edge-android {
    flex: 0 0 22px;
    &.expand {
      visibility: hidden;
    }
  }
  .wrapper-image-icon {
    width: 32px;
    height: 32px;

    &--window {
      width: 64px;
      height: 64px;
    }

    &.expand-image {
      width: 100%;
      height: 180px;
      margin-bottom: 10px;
    }
    .image-icon {
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
`

export const StyleBrowserContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .message-infor {
    flex: 1;

    p {
      margin: 0;
    }
  }
  .chrome-window-message {
    width: 64px;
    height: 64px;
  }
  .edge-android-message {
    flex: 0 0 44px;
  }
  .android-infor-wrapper {
    display: flex;
    gap: 5px;
    .android-title {
      flex: 1 1 120px;
      max-width: 120px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`

export const StyleMessageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  .title {
    font-size: 12px;
    line-height: 18px;
    color: ${(props) => props.theme.colors.gray800};
  }
`

export const StyleTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.gray800};
`

export const StyleMessage = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.gray800};

  span {
    display: block;
  }
`

export const StyleEmailText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.gray600};
`

export const StyleAndroidPreviewWrap = styled.div`
  display: flex;
  gap: 5px;

  p {
    font-size: 12px;
    line-height: 18px;
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.colors.gray600};
    display: flex;
    align-items: center;
    &:before {
      content: '';
      width: 3px;
      height: 3px;
      display: inline-block;
      background-color: ${(props) => props.theme.colors.gray400};
      border-radius: 50%;
      margin-right: 5px;
    }
  }
`
