import { notification } from 'antd'
import { menuRoutes } from 'components/molecules'
import {
  CampaignForm,
  CampaignFormProps,
  CampaignFormRef
} from 'components/molecules/campaign'
import { CAMPAIGN_STEP_KEYS } from 'components/molecules/campaign/CampaignForm/CampaignStepHeader'
import { PERMISSIONS } from 'constants/permission'
import { FC, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { CampaignServices } from 'services/campaign'

export type CampaignPageCreateProps = {
  template_type?: string
}
export const CampaignPageCreate: FC<CampaignPageCreateProps> = (props) => {
  const { template_type } = props

  const navigate = useNavigate()
  const campaignRef = useRef<CampaignFormRef>(null)

  const handleSave: NonNullable<CampaignFormProps['onSave']> = useCallback(
    async (values) => {
      try {
        const response = await CampaignServices.createCampaign({
          name: values?.name || '',
          description: values?.description || '',
          approach: values?.approach || 1,
          client_ids: values?.client_ids || [],
          start_time: values?.start_time || '',
          end_time: values?.end_time || '',
          flow_id: values?.flow_id || null,
          segments_ids: values?.segments_ids || [],
          site_ids: values?.site_ids || [],
          template_id: values?.template_id || null,
          time_zone: values?.time_zone || '',
          type: values?.type || '',
          status: values?.status || '',
          template_type: values?.template_type || template_type,
          days_of_week: values?.days_of_week || undefined,
          is_recurring: values?.is_recurring || undefined,
          recurring_cycle: values?.recurring_cycle || undefined,
          trigger_time: values?.trigger_time || undefined,
          threshold: values?.threshold || 1,
          push_type: values?.push_type === 'broadcast' ? values.push_type : '',
          by_pass_frequency: values?.by_pass_frequency || false
        } as any)
        if (response._message && !response.data) {
          throw new Error(JSON.stringify(response))
        }

        if (response.data?.id) {
          campaignRef.current?.modalOnClose()
          notification.success({
            message: 'This campaign was saved successfully'
          })
          navigate(
            `${menuRoutes.campaign}/${template_type}/${response.data.id}`
          )
        }
      } catch (error: any) {
        console.log('** ERROR CampaignLandingPageCreate.tsx 46 error : ', error)
        if (error?.message && typeof error.message === 'string') {
          const errorObj = JSON.parse(error.message)
          if (errorObj?._message) {
            const fields: any[] = []
            const message = errorObj?._message
            if (message.includes('name')) {
              campaignRef.current?.onChangeStep(CAMPAIGN_STEP_KEYS.overviews)
              fields.push({
                name: 'name',
                value: values.name,
                errors: ['Name is existing. Please input another name.']
              })
            }
            const messageArr = message.split(':')
            if (messageArr?.[0].includes('end_time')) {
              campaignRef.current?.onChangeStep(CAMPAIGN_STEP_KEYS.overviews)
              fields.push({
                name: messageArr?.[0],
                value: values.end_time,
                errors: [messageArr?.[1]]
              })
            }
            // Forsure exists overviewsForm
            setTimeout(() => {
              campaignRef.current?.overviewsForm?.setFields(fields)
            }, 250)
          }
        }
      }
    },
    [navigate, template_type]
  )

  return (
    <CampaignForm
      ref={campaignRef}
      template_type={template_type}
      onSave={handleSave}
      permissions={[
        PERMISSIONS.campaign_email_create,
        PERMISSIONS.campaign_app_push_create,
        PERMISSIONS.campaign_web_push_create,
        PERMISSIONS.campaign_whatsapp_create
      ]}
    />
  )
}
