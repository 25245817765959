export const GEO_URL =
  'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json'

export const OLYMPUS_PATHS = {
  login: 'api/login',
  users: 'api/users',
  logout: 'api/logout',
  profile: 'api/profile',
  uploadAvatar: 'api/update-avatar',
  updateProfile: 'api/user',
  sendResetPasswordLink: 'api/password/email',
  resetPassword: 'api/password/reset'
}

export const SEGMENT_PATHS = {
  healthcheck: 'healthcheck',
  site: 'api/sites',
  tag: 'api/tags',
  segment: 'api/segments',
  segment_detail: 'api/segments/{id}',
  segment_filter: 'api/segments/{id}/{category}',

  // Segment List
  segment_list_import: 'api/segments/{id}/import',
  segment_list_template: 'api/segments/import/template',

  // Segment Builder
  segment_builder: 'api/segments/category',
  segment_builder_detail: 'api/segments/category/{category}?segment_id={id}',
  segment_count_users_by_condition: 'api/segments/count-users-by-condition',
  segment_get_users: 'api/segment/get-users',
  segment_contacts: 'api/segment/{id}/get-list-user',
  segment_export_contacts: 'api/segments/{id}/export-users',

  // Segment List and Builder Condition Base V2
  segment_v2: 'api/v2/segments',
  segment_v2_detail: 'api/v2/segments/{id}',
  segment_v2_analytics: 'api/v2/segments/{id}/analytic',
  segment_v2_count_by_condition: 'api/v2/segments/count-by-condition',
  segment_v2_get_condition_for_workflow:
    'api/v2/segments/get-condition-for-workflow',
  condition_base_builder: 'api/v2/segments/conditions',
  segment_condition_base_builder_create: 'api/v2/segments/{id}/add-condition',
  segment_condition_base_builder_detail: 'api/v2/segments/{id}/detail',
  count_user_by_segment_condition: 'api/v2/segments/count-user-by-segment',
  segment_condition_detail: 'api/v2/segments/{id}',
  count_user_all_site: 'api/v2/segments/get-count-all-site',
  count_user_reachable: 'api/v2/segments/{id}/reachable',
  run_compare_segment: 'api/v2/segments/{segmentId}/run-compare',
  get_compare_segment: 'api/v2/segments/{segmentId}/get-compare',
  create_segment_by_compare: 'api/v2/segments/create-segment-compare',
  exclude_email_compare: 'api/v2/segments/{id}/exclude-email-compare',
  count_by_condition: 'api/v2/segments/count-by-conditions',

  /** Facebook Conversions */
  // #region
  fb_conversions: '/api/facebook-conversions',
  fb_conversions_events: '/api/facebook-conversions/pixel-events',
  fb_conversions_fields: '/api/facebook-conversions/pixel-events/fields',
  // #endregion

  /** Facebook Ads Account */
  fb_ads_account: '/api/facebook-custom-audience-connector/ad-accounts',
  /** Facebook Ads Account -> Custom Audience */
  fb_custom_audience_connector: '/api/facebook-custom-audience-connector',
  fb_custom_audience: '/api/facebook-custom-audience',
  fb_custom_audience_sync: '/api/campaigns/sync-facebook-custom-audience'
}

export const CAMPAIGN_PATHS = {
  campaigns: '/api/campaigns',
  email_insight: '/api/campaign-insights',
  web_push_insight: '/api/campaign-insights/web-push/{id}',
  send_test_email: '/api/campaigns/send/test',
  insight_filter_data: '/api/campaigns/insight-filter-data',
  insight_by_filter: '/api/campaigns/insight-by-filter',
  insight_export: '/api/campaigns/insight/export'
}

export const SITE_PATHS = {
  sites: '/api/sites'
}

export const FIELD_PATHS = {
  event: '/api/events',
  field: '/api/events/fields',
  field_condition: 'api/events/conditions/field',
  field_operator: '/api/events/conditions/operator',
  field_data_type: '/api/events/conditions/field-type',
  count_user: '/api/events/conditions/count'
}

export const CUSTOM_EVENT_PATHS = {
  custom_event: 'api/custom-event-conditions'
}

export const ANALYTIC_PATHS = {
  metric: '/api/analytics/get-metric',
  user_city: '/api/analytics/get-users-city',
  metric_by_range: '/api/analytics/get-metric-by-range',

  templates: '/api/segment/analytics/templates',
  widgets: '/api/segment/analytics/templates/widgets',

  widgets_by_template_id: '/api/segment/analytics/templates/{id}/widgets',

  data_by_widget:
    '/api/segment/analytics/data/widget/{widget}?segment_id={segment_id}&segment_type={segment_type}&start_date={start_date}&end_date={end_date}',

  sharing_request_permissions_access:
    '/api/segment/analytics/templates/{template_id}/sharing/request-permissions-access',
  sharing_accept_permission_request:
    '/api/segment/analytics/templates/{template_id}/sharing/accept-permissions-request/{uuid}',
  sharing_authorization_check:
    '/api/segment/analytics/templates/{template_id}/sharing/authorization-check',
  sharing_grant_permissions:
    '/api/segment/analytics/templates/{template_id}/sharing/grant-permissions',
  sharing_revoke_permissions:
    '/api/segment/analytics/templates/{template_id}/sharing/revoke-permissions',
  sharing_list_authorized:
    '/api/segment/analytics/templates/{template_id}/sharing/list-user-authorized',
  sharing_list_unauthorized:
    '/api/segment/analytics/templates/{template_id}/sharing/list-user-unauthorized'
}

export const ATTRIBUTE_FIELD_PATHS = {
  field: 'api/v2/segments/conditions',
  detail_field: 'api/v2/segments/conditions/{id}/detail',
  update_field: 'api/v2/segments/conditions/{id}/update'
}

export const EVENT_FIELD_PATHS = {
  field: '/api/events/fields'
}

export const VARIABLE_PATHS = {
  variation: '/api/variation'
}

export const NOTIFICATION_PATHS = {
  template_test_send_email: '/api/campaigns/send/test',
  app_push_test_send_email: '/api/notification/send/test',
  activify_notifications: '/api/activity_notification'
}

export const AUTH_GOOGLE_PATHS = {
  get_auth_url: '/api/media/get-auth-url',
  auth_goole: '/api/media/set-auth',
  check_auth: '/api/media/check-auth',
  get_media: '/api/media',
  preview_file: '/api/media/preview'
}

export const HYGEIA_PATH = {
  FIELD_CATEGORY: '/api/fields/categories'
}
