import Icon from '@ant-design/icons'
import { Button, Form, FormListOperation, notification } from 'antd'
import { FormNamePath } from 'components/condition/conditions/form/index.helpers'
import ConditionGroup from 'components/condition/conditions/group'
import { AppContext, AppContextProps } from 'context/App'
import {
  useAttributeFields,
  useEventOperationOptions,
  useEventOptions
} from 'hooks/useSegment'
import { Plus } from 'icons'
import { FC, useContext, useMemo } from 'react'

interface ConditionListGroupsProps {
  settingValue: any
  onChangeField: (name: FormNamePath) => (value: any) => void
}

const ConditionListGroups: FC<ConditionListGroupsProps> = ({
  settingValue
}) => {
  const attributes = useAttributeFields()
  const events = useEventOptions()
  const { sites } = useContext<AppContextProps>(AppContext)
  const operatorEventOpts = useEventOperationOptions()
  const handleAddGroup = (operations: FormListOperation) => {
    if (!settingValue.sites) {
      notification.info({
        message:
          'Please choose a site before adding event or attribute condition!'
      })
      return
    }
    operations.add()
  }
  const siteSelected = useMemo(() => {
    if (settingValue.sites) {
      const result = sites.find(
        (item: any) => item.value === settingValue.sites
      )
      if (result) {
        return result.domain
      }
    }
    return ''
  }, [settingValue.sites, sites])
  return (
    <Form.List name="groups">
      {(groups, operations) => {
        return (
          <>
            {groups.map((group) => {
              return (
                <div key={group.key}>
                  <ConditionGroup
                    site={siteSelected}
                    rootName="groups"
                    attributeFields={attributes}
                    eventFields={events}
                    operatorEventOpts={operatorEventOpts}
                    formListFieldData={group}
                    formListOperation={operations}
                  />
                </div>
              )
            })}
            {groups.length === 0 && (
              <div>
                <Button
                  icon={<Icon component={Plus} />}
                  size="small"
                  onClick={() => handleAddGroup(operations)}>
                  Add new
                </Button>
              </div>
            )}
          </>
        )
      }}
    </Form.List>
  )
}

export default ConditionListGroups
