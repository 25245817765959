import Icon from '@ant-design/icons'
import styled from 'styled-components'

export const StyledLabel = styled.div`
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 44px;
`

export const StyledIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  font-size: 20px;
`

export const StyledSelect = styled.div`
  .ant-select {
    display: flex;
    align-items: flex-end;
    .ant-select-selector {
      flex: 1;
      min-width: 0;
      padding: 26px 40px 8px 44px;
      border-color: transparent;
      background-color: ${({ theme }) => theme?.colors?.neutral50};
      border-radius: 4px;
      height: auto;
      font-size: 12px;
      line-height: 1.5;
      &:after {
        line-height: 1.5;
      }
      .ant-select-selection-search {
        inset: 0;
        padding: 26px 40px 8px 44px;
        .ant-select-selection-search-input {
          height: auto;
        }
      }
      .ant-select-selection-item {
        padding: 0;
        line-height: 18px;
        font-size: 12px;
        font-weight: 700;
      }
    }
    .ant-select-selection-placeholder {
      font-size: 12px;
      line-height: 1.5;
    }
    .ant-select-arrow {
      color: ${({ theme }) => theme?.colors?.gray600};
      font-size: 16px;
    }
    &.ant-select-focused:not(.ant-select-disabled) {
      .ant-select-selector {
        box-shadow: none;
      }
    }
  }
`

export const StyledContainer = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 1.5;
`
