import { Button, Select, Spin } from 'antd'
import cn from 'classnames'
import { Box } from 'components/atoms/box'
import { AnalyticWidgetItem } from 'interfaces/analytic'
import { CSSProperties } from 'react'

export type AnalyticWidgetItemProps = AnalyticWidgetItem & {
  name: string
  type?: string
  src?: string
  description?: string
  className?: string
  disabled?: boolean
}
export type AnalyticWidgetListProps = {
  loading?: boolean
  dataSource: AnalyticWidgetItemProps[]
  onClickItem?: (item: AnalyticWidgetItemProps) => void
  style?: CSSProperties
  className?: string
}
export const AnalyticWidgetList = (props: AnalyticWidgetListProps) => {
  const { loading, dataSource, onClickItem, style, className } = props
  const handleClickItem = (item: AnalyticWidgetItemProps) => {
    return () => {
      onClickItem?.(item)
    }
  }
  return (
    <div className={className} style={style}>
      <div className="mb-6">
        <Select
          defaultValue="all"
          options={[
            {
              label: 'All Widget Types',
              value: 'all'
            }
          ]}
          className="min-w-[196px]"
        />
      </div>
      <Box className="relative">
        {loading && (
          <Box className="absolute flex justify-center pt-[100px] bg-white/10 top-0 left-0 right-0 bottom-0 z-[1]">
            <Spin />
          </Box>
        )}
        <Box className="flex flex-wrap ml-[-8px] mr-[-8px] gap-y-[16px]">
          {dataSource.map((item) => {
            return (
              <Box key={item.title} className="w-[25%] min-w-[25%] px-[8px]">
                <div
                  className={cn(
                    'analyticWidgetItem h-full',
                    'p-2 bg-white group',
                    'rounded-lg border border-solid border-neutral100',
                    item?.className,
                    { 'pointer-events-none opacity-50': item?.disabled }
                  )}>
                  <div
                    className={cn(
                      'analyticWidgetItem--feature',
                      'bg-neutral-50',
                      'relative overflow-hidden pb-[60%] rounded-[4px]'
                    )}>
                    <img
                      alt=""
                      src={item?.src}
                      className={cn(
                        'analyticWidgetItem--feature-image',
                        'absolute inset-0 z-[1] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                        'border-none',
                        'max-w-full max-h-full',
                        'object-contain object-center'
                      )}
                    />
                    <div
                      className={cn(
                        'analyticWidgetItem--feature-backdrop',
                        'absolute inset-0 z-[2]',
                        'before:content-[""] before:block before:bg-gray800 before:absolute before:inset-0 before:opacity-80 ',
                        'flex items-center justify-center transition',
                        'opacity-0 pointer-events-none',
                        'group-[&:hover]:opacity-100 group-[&:hover]:pointer-events-auto'
                      )}>
                      <Button
                        disabled={item?.disabled}
                        ghost
                        type="ghost"
                        onClick={handleClickItem(item)}>
                        Select
                      </Button>
                    </div>
                  </div>
                  <div
                    className={cn('analyticWidgetItem--content', 'py-4 px-2')}>
                    <h3 className="analyticWidgetItem--title mb-1 font-semibold text-gray800 text-[18px] leading-[1.5]">
                      {item?.title}
                    </h3>
                    <p className="analyticWidgetItem--description m-0 text-[14px]">
                      {item?.description}
                    </p>
                  </div>
                </div>
              </Box>
            )
          })}
        </Box>
      </Box>
    </div>
  )
}
