import { Menu, MenuProps } from 'antd'
import styled from 'styled-components'

export const StyledSortDropdownOverlay = styled.div`
  width: 180px;
  background-color: ${({ theme }) => theme?.colors?.white || ''};
  border-radius: ${({ theme }) => theme?.borderRadiusBase};
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  .title {
    padding: 10px 16px;
    margin: 0;
    color: ${({ theme }) => theme?.colors?.gray500 || ''};
    font-weight: 700;
  }
`

export const StyledSortDropdownMenu = styled(Menu)<MenuProps>`
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
  box-shadow: none;
  .ant-dropdown-menu-item {
    padding: 10px 16px;
    line-height: 1.5;
    &.ant-dropdown-menu-item-selected {
      background-color: ${({ theme }) => theme?.colors?.gray50 || ''};
      color: ${({ theme }) => theme?.colors?.gray800 || ''};
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }
`

export const StyledSortIcon = styled.div`
  &.section--sort {
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    background-color: ${({ theme }) => theme?.colors?.white || ''};
    border-radius: ${({ theme }) => theme?.borderRadiusBase};
    cursor: pointer;
    transition: 0.2s linear all;
    .anticon {
      font-size: 22px;
    }
    &:hover,
    &.sorted {
      background-color: ${({ theme }) => theme?.colors?.gray100 || ''};
    }
  }
`
