import styled from 'styled-components'

export const StyledMenuSidebarProfileDropdownMenu = styled.div`
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      padding: 8px;
      background-color: transparent !important;
      color: #fff;
      .ant-dropdown-menu-item-icon {
        margin-right: 0;
      }

      .ant-dropdown-menu-title-content {
        margin-left: 24px;
      }
      &:hover {
        color: ${({ theme }) => theme?.colors?.primary};
      }
    }
  }

  &.isCollapse {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        .ant-dropdown-menu-title-content {
          display: none;
        }
      }
    }
  }
`
