import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledTableWrap = styled.div`
  flex: 1;
  min-height: 0;

  .ant-table-wrapper {
    height: 100%;
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  .ant-table-thead > tr > th {
    background: #fff;
    padding-top: 12px;
    padding-bottom: 12px;
    &:before {
      content: none !important;
    }
    &:first-child {
      padding-left: 24px;
    }
  }
  .ant-table-tbody > tr > td {
    &:first-child {
      padding-left: 24px;
    }
  }
  .ant-table-cell {
    vertical-align: middle;
  }
`

export const StyledTemplateName = styled(Link)<{ disabled?: boolean }>`
  display: block;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  color: inherit;
  padding-right: 12px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  @media only screen and (min-width: 1400px) {
    max-width: 330px;
  }

  @media only screen and (min-width: 1600px) {
    max-width: 400px;
  }
`

export const StyledTemplateId = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.gray400};
`

export const StyledTemplateImage = styled.div`
  position: relative;
  width: 60px;
  min-width: 60px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.neutral100};

  &::before {
    content: '';
    display: block;
    padding-top: 66%;
  }

  > svg,
  > img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
