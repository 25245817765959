import dayjs from 'dayjs'
import {
  WorfklowInsightParams,
  WorkflowCreate,
  WorkflowFolderListParams,
  WorkflowListParams,
  WorkflowUpdate
} from 'interfaces/workflow'
import { sidisBaseAxios } from 'utils/api'

const workflowAxios = sidisBaseAxios
const baseWorkflowPath = '/api/workflows'
const baseWorkflowFolderPath = '/api/workflow-folder'

export const WorkflowServices = {
  getList: (params: WorkflowListParams) => {
    return workflowAxios.get(baseWorkflowPath, { params })
  },
  create: (input: Partial<WorkflowCreate>) => {
    return workflowAxios.post(baseWorkflowPath, input)
  },
  read: (id: string | number) => {
    return workflowAxios.get(`${baseWorkflowPath}/${id}`)
  },
  update: (input: Partial<WorkflowUpdate>) => {
    const { id, ...restInput } = input
    return workflowAxios.put(`${baseWorkflowPath}/${id}`, restInput)
  },
  delete: (id: string | number) => {
    return workflowAxios.delete(`${baseWorkflowPath}/${id}`)
  },
  action: (id: string | number, action: 'start' | 'stop' = 'start') => {
    return workflowAxios.post(`${baseWorkflowPath}/${id}/action`, {
      action
    })
  },
  insight: ({
    id = '',
    status_id = [],
    channel_ids = [],
    start_date = 0,
    end_date = 0,
    ...restProps
  }: WorfklowInsightParams) => {
    // channel_ids = ['WEB_PUSH', 'EMAIL', 'APP_PUSH']
    if (start_date === 0 || end_date === 0) {
      const today = dayjs()
      start_date = today.subtract(7, 'days').valueOf()
      end_date = today.valueOf()
    }
    return workflowAxios.get(`${baseWorkflowPath}/${id}/insight`, {
      params: {
        status_id,
        channel_ids,
        start_date,
        end_date,
        ...restProps
      }
    })
  }
}

export const WorkflowFolderServices = {
  getList: (params: WorkflowFolderListParams) => {
    return workflowAxios.get(baseWorkflowFolderPath, { params })
  },
  getListById: ({
    id = undefined,
    search = '',
    sort_by = '',
    sort_type = '',
    created_from = '',
    created_to = '',
    page = 1,
    per_page = 20
  } = {}) => {
    return workflowAxios.get(`${baseWorkflowFolderPath}/${id}`, {
      params: {
        search,
        sort_by,
        sort_type,
        created_from,
        created_to,
        page,
        per_page
      }
    })
  },
  createWorkflowFolder: (name: string) => {
    return workflowAxios.post(baseWorkflowFolderPath, { name })
  }
}
