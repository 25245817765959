import { Rule } from 'antd/lib/form'

export const MaxPushTitle = 60
export const MaxPushMessage = 220

export const rules: Record<string, Rule[]> = {
  name: [
    { required: true, message: 'Please enter template name' },
    {
      whitespace: true,
      message: 'Please input template name with characters'
    },
    {
      max: 120,
      message: 'Maximum 120 characters for name web push'
    }
  ],
  message_type: [{ required: true, message: 'Please select message type' }],
  site: [{ required: true, message: 'Please choose site' }],
  title: [
    { required: true, message: 'Please input title' },
    {
      max: MaxPushTitle,
      message: 'Title is too long'
    },
    {
      whitespace: true,
      message: 'Please input title with characters'
    }
  ],
  push_message: [
    { required: true, message: 'Please input push message' },
    {
      max: MaxPushMessage,
      message: `The maximum length of push messages is ${MaxPushMessage} characters`
    },
    {
      whitespace: true,
      message: 'Please input messages with characters'
    }
  ],
  custom_link_url: [
    { required: true, message: 'Please input message link' },
    (formRef) => ({
      validator(_, newCustomLinkUrl) {
        if (!newCustomLinkUrl) return Promise.resolve()
        try {
          new URL(newCustomLinkUrl)
          return Promise.resolve()
        } catch (err) {
          return Promise.reject('Invalid URL')
        }
        // try {
        //   const urlParams = new URL(newCustomLinkUrl).searchParams
        //   const newUtmSource = urlParams.get('utm_source') || ''
        //   const newUtmMedium = urlParams.get('utm_medium') || ''
        //   const newUtmCampaign = urlParams.get('utm_campaign') || ''
        //   const newUtmContent = urlParams.get('utm_content') || ''
        //   const newUtmTerm = urlParams.get('utm_term') || ''

        //   if (formRef.getFieldValue('utm_source') !== newUtmSource) {
        //     formRef.setFieldValue('utm_source', newUtmSource)
        //   }
        //   if (formRef.getFieldValue('utm_medium') !== newUtmMedium) {
        //     formRef.setFieldValue('utm_medium', newUtmMedium)
        //   }
        //   if (formRef.getFieldValue('utm_campaign') !== newUtmCampaign) {
        //     formRef.setFieldValue('utm_campaign', newUtmCampaign)
        //   }
        //   if (formRef.getFieldValue('utm_content') !== newUtmContent) {
        //     formRef.setFieldValue('utm_content', newUtmContent)
        //   }
        //   if (formRef.getFieldValue('utm_term') !== newUtmTerm) {
        //     formRef.setFieldValue('utm_term', newUtmTerm)
        //   }

        //   return Promise.resolve()
        // } catch (err) {
        //   return Promise.resolve()
        // }
      }
    })
  ],
  message_link: [{ required: true, message: 'Please input message link' }],
  message_icon: [{ required: true, message: 'Please upload message icon' }],
  image_upload: [{ required: true, message: 'Please upload image' }]
}

export const mockDynamicLink = [
  {
    label: 'https://cdn-hermes.hellohealthgroup.com/content1.png',
    value: 'https://cdn-hermes.hellohealthgroup.com/content1.png'
  },
  {
    label: 'https://cdn-hermes.hellohealthgroup.com/content2.png',
    value: 'https://cdn-hermes.hellohealthgroup.com/content2.png'
  },
  {
    label: 'https://cdn-hermes.hellohealthgroup.com/content3.png',
    value: 'https://cdn-hermes.hellohealthgroup.com/content3.png'
  }
]

export const setUtmParams = (formRef: any, key: string, value: any) => {
  try {
    const url = new URL(formRef.getFieldValue('custom_link_url'))
    value && url.searchParams.set(key, value)
    !value && url.searchParams.delete(key)
    formRef.setFieldValue('custom_link_url', url.href)
    return Promise.resolve()
  } catch (err) {
    return Promise.resolve()
  }
}
