import { SegmentConditionFieldOption } from 'components/molecules/segment/SegmentForm/SegmentFormQuery/SegmentCondition'
import { FIELD_CATEGORY } from 'constants/fieldAttributes'
import { SEGMENT_TYPE } from 'constants/segment'
import { ResponseHook } from 'interfaces'
import { useCallback, useMemo, useState } from 'react'
import { FieldEventServices } from 'services/fieldAttribute'

/** Mapping segment_type to category of field */
export const MAP_SGM_TYPE_TO_CAT = {
  [SEGMENT_TYPE.ATTRIBUTE]: FIELD_CATEGORY.ATTRIBUTE,
  [SEGMENT_TYPE.EVENT]: FIELD_CATEGORY.EVENT,
  [SEGMENT_TYPE.EVENT_CUSTOM]: FIELD_CATEGORY.EVENT_CUSTOM,
  [SEGMENT_TYPE.MOBILE]: FIELD_CATEGORY.MOBILE
}

export type FetchFieldsParams = {
  event_id?: number | string
  page?: number
  per_page?: number
  search?: string
  sort_type?: string
  sort_key?: string
  // filter_data_type?: never[]
  category?: string
  site?: string
  segment_type?: string
}
export function useFields() {
  const [loading, setLoading] = useState(false)

  const fetchFields = useCallback<
    (params: FetchFieldsParams) => Promise<ResponseHook>
  >(async (params: FetchFieldsParams) => {
    setLoading(true)
    try {
      if (typeof params?.segment_type === 'string') {
        params.category = MAP_SGM_TYPE_TO_CAT[params.segment_type]
      }

      const response: any = await FieldEventServices.getListFields({
        ...params,
        event_id: params?.event_id?.toString()
      })
      if (response._message || !response.data) {
        throw new Error(JSON.stringify(response))
      }
      return {
        data: response.data?.data as SegmentConditionFieldOption[],
        errors: []
      }
    } catch (error) {
      console.log('** RYAN SegmentCondition.helpers.ts 150 error : ', error)
      return { data: null, errors: [{ errors: ['Something went wrong'] }] }
    } finally {
      setLoading(false)
    }
  }, [])

  return useMemo(
    () => ({ loading, setLoading, fetchFields }),
    [fetchFields, loading]
  )
}
