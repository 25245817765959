import { ResultProps } from 'antd'
import Result, { ResultStatusType } from 'antd/lib/result'
import { AlertSuccess } from 'icons'
import { PageNotFound, Permission, UnderMaintenance } from 'icons/V2'
import { FC, useMemo } from 'react'
import styled from 'styled-components'

const StyledResult = styled(Result)`
  padding-block: 170px;

  .ant-result-icon {
    font-size: 180px;
    line-height: 0;
    margin-bottom: 24px;
  }

  .ant-result-title {
    margin-bottom: 8px;
    color: ${({ theme }) => theme?.colors?.gray800};
    font-size: 26px;
    font-weight: 700;
    line-height: 1.4;
  }

  .ant-result-subtitle {
    color: ${({ theme }) => theme?.colors?.gray600};
    font-size: 16px;
    line-height: 1.5;
  }
`

const RESULT_CUSTOM_DEFAULT: Record<
  ResultStatusType,
  { icon: AntIconComponent; title: string; subTitle: string }
> = {
  '403': {
    icon: Permission,
    title: 'Access Denied',
    subTitle: 'Sorry! You don’t have permission to access this page.'
  },
  '404': {
    icon: PageNotFound,
    title: '404',
    subTitle: 'Sorry! The page you visited does not exist.'
  },
  '500': {
    icon: PageNotFound,
    title: '500',
    subTitle: 'Sorry! The page you visited does not exist.'
  },
  error: { icon: PageNotFound, title: '', subTitle: '' },
  info: { icon: PageNotFound, title: '', subTitle: '' },
  success: { icon: AlertSuccess, title: '', subTitle: '' },
  warning: {
    icon: UnderMaintenance,
    title: 'This page is under construction',
    subTitle: 'We’re working on it'
  }
}

export type ResultCustomProps = Pick<
  ResultProps,
  'title' | 'subTitle' | 'status' | 'children' | 'extra'
>
export const ResultCustom: FC<ResultCustomProps> = (props) => {
  const {
    status = '404',
    title: titleProps,
    subTitle: subTitleProps,
    children,
    extra
  } = props

  const {
    icon: IconComp,
    title,
    subTitle
  } = useMemo(() => RESULT_CUSTOM_DEFAULT[status], [status])

  return (
    <StyledResult
      icon={<IconComp />}
      title={titleProps || title}
      subTitle={subTitleProps || subTitle}
      extra={extra}>
      {children}
    </StyledResult>
  )
}
