import { StyledButton, StyledInputSearch } from './index.styled'
import Icon from '@ant-design/icons'
import { SearchProps } from 'antd/lib/input'
import { Search } from 'icons'
import { FC, useCallback, useEffect, useState } from 'react'

type InputSearchPropsEvent =
  | React.ChangeEvent<HTMLInputElement>
  | React.KeyboardEvent<HTMLInputElement>
  | React.MouseEvent<HTMLElement, MouseEvent>
export const InputSearch: FC<Omit<SearchProps, 'enterButton' | 'prefix'>> = ({
  defaultValue,
  value: valueProps,
  onChange: onChangeProps,
  onSearch: onSearchProps,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue || valueProps)

  const onClick = useCallback(
    (e?: InputSearchPropsEvent) => {
      if (Array.isArray(value)) {
        return
      }
      onSearchProps && onSearchProps(value?.toString() || '', e)
    },
    [value, onSearchProps]
  )

  const onSearch = (value: string, event?: InputSearchPropsEvent) => {
    event?.preventDefault()
    onSearchProps && onSearchProps(value, event)
  }

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value)
    onChangeProps && onChangeProps(e)
    if (!e.target.value) {
      onSearch(e.target.value, e)
    }
  }

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  return (
    <StyledInputSearch
      {...props}
      value={value}
      onChange={onChange}
      onSearch={onSearch}
      prefix={
        <StyledButton
          type="link"
          onClick={onClick}
          icon={<Icon component={Search} />}
        />
      }
      enterButton={null}
    />
  )
}
