import Tabs from 'antd/lib/tabs'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

export const StyledTabContentInner = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

export const StyledTabContentWrapper = styled.div`
  padding: 24px;
`

export const StyledTabContent = styled.div`
  overflow: auto;
  flex: 1;
  min-height: 0;
  ${cssScrollBar};
  &.overflowHidden {
    display: flex;
    height: 100%;
    overflow: hidden;
    > div {
      flex: 1;
      min-height: 0;
      max-height: 100%;
      overflow: hidden;
    }
  }
`

export const StyledTab = styled(Tabs)`
  display: flex;
  height: 100%;
  flex-direction: column;
  .ant-tabs-nav {
    margin-top: 24px;
    margin-inline: 24px;
    margin-bottom: 0;
    &:before {
      display: none;
    }
    .ant-tabs-nav-list {
      border-bottom: 1px solid ${({ theme }) => theme?.colors?.neutral100};
      .ant-tabs-tab {
        padding-bottom: 8px;
        .ant-tabs-tab-btn {
          color: ${({ theme }) => theme?.colors?.gray400};
        }
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: ${({ theme }) => theme?.colors?.primary};
          }
        }
        + .ant-tabs-tab {
          margin-left: 24px;
        }
      }
    }
  }
  .ant-tabs-content-holder {
    overflow: hidden;
    .ant-tabs-content {
      display: flex;
      height: 100%;
      flex-direction: column;
      .ant-tabs-tabpane {
        flex: 1;
        min-width: 0;
        min-height: 0;
        &.ant-tabs-tabpane-active {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
`

export const StyledTabContainer = styled.div`
  overflow: hidden;
  flex: 1;
  min-width: 0;
  max-height: 100%;
  ${cssScrollBar};
`
