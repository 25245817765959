import { StyledDescription } from './SegmentFormQuery.styled'
import cn from 'classnames'

export type SegmentFormQueryHeaderProps = {
  domain?: string
  className?: string
}

export const SegmentFormQueryHeader = (props: SegmentFormQueryHeaderProps) => {
  const { domain, className } = props
  return (
    <StyledDescription className={cn('gray400', className)}>
      {!domain && 'Segment for people'}
      {domain && (
        <>
          Segment for people in <strong>{domain}</strong>, where
        </>
      )}
    </StyledDescription>
  )
}
