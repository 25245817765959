import EditorContent from './EditorContent'
import { StyledDrawerPreview } from './index.styled'
import { Content as JSONContent } from '@tiptap/core'
import { Editor } from '@tiptap/react'
import { Button, notification } from 'antd'
import { ArrowLeft, ArrowRight, Copy2, Prompt } from 'icons'
import { copyToClipboard } from 'utils'

type ParamsCopilot = {
  style: Record<string, string>
  moduleBlockStyle: Record<string, string>
  computedStyle: Record<string, string>
}

type Props = {
  recommendsContent: string[]
  currentPreviewIdx: number
  setCurrentPreviewIdx: React.Dispatch<React.SetStateAction<number>>
  dataStyle: ParamsCopilot | null
  editorType: string
  onChangeEditor: (_: string, __: string, ___: JSONContent) => void
  onClickSubmit: () => void
  editor: React.MutableRefObject<Editor | null>
  isHideSubmit?: boolean
}

export function replaceAll(
  str: string,
  searchValue: string,
  replaceValue: string
) {
  const regex = new RegExp(searchValue, 'g')
  return str.replace(regex, replaceValue)
}

function CopilotDrawerPreview({
  recommendsContent,
  currentPreviewIdx,
  setCurrentPreviewIdx,
  dataStyle,
  editorType,
  editor,
  onChangeEditor,
  onClickSubmit,
  isHideSubmit
}: Props) {
  if (!recommendsContent.length) {
    return null
  }

  return (
    <StyledDrawerPreview>
      <div className="preview-head">
        <span>Preview</span>
        <ArrowLeft
          onClick={() => {
            setCurrentPreviewIdx((idx) => (idx > 0 ? idx - 1 : 0))
          }}
        />
        <span>
          {currentPreviewIdx + 1} / {recommendsContent.length}
        </span>
        <ArrowRight
          onClick={() => {
            const max = recommendsContent.length - 1
            setCurrentPreviewIdx((idx) => (idx < max ? idx + 1 : max))
          }}
        />
      </div>
      <div className="preview-content">
        {recommendsContent[currentPreviewIdx] && (
          <EditorContent
            label=""
            key={currentPreviewIdx}
            dataStyle={dataStyle}
            htmlContent={recommendsContent[currentPreviewIdx]}
            editorType={editorType}
            className="recommended-content"
            onChange={onChangeEditor}
            editable={false}
          />
        )}
      </div>
      <div className="preview-footer">
        {!isHideSubmit && (
          <Button
            size="small"
            type="primary"
            icon={<Prompt />}
            onClick={onClickSubmit}>
            Submit
          </Button>
        )}
        <Button
          size="small"
          icon={<Copy2 />}
          onClick={() => {
            const text = replaceAll(
              recommendsContent[currentPreviewIdx],
              '<br />',
              '\n'
            )
            copyToClipboard(text).then(() => {
              notification.info({
                message: 'Copied to clipboard'
              })
            })
          }}>
          Copy
        </Button>
      </div>
    </StyledDrawerPreview>
  )
}

export default CopilotDrawerPreview
