import { TabsProps } from 'antd'
import { TEMPLATE_STATUS, TEMPLATE_TYPE } from 'constants/template'
import { MESSAGE_TYPE } from 'interfaces/template'

export const listTemplateTabs: Record<string, TabsProps['items']> = {
  [TEMPLATE_TYPE.EMAIL]: [
    { label: 'Transactional', key: MESSAGE_TYPE.TRANSACTIONAL },
    { label: 'Internal', key: MESSAGE_TYPE.INTERNAL },
    { label: 'Marketing', key: MESSAGE_TYPE.MARKETING }
  ],
  [TEMPLATE_TYPE.APP_PUSH]: [
    { label: 'Transactional', key: MESSAGE_TYPE.TRANSACTIONAL },
    { label: 'Internal', key: MESSAGE_TYPE.INTERNAL },
    { label: 'Marketing', key: MESSAGE_TYPE.MARKETING },
    { label: 'Push Marketing', key: MESSAGE_TYPE.PUSH_MARKETING }
  ],
  [TEMPLATE_TYPE.WEB_PUSH]: [
    { label: 'Transactional', key: MESSAGE_TYPE.TRANSACTIONAL },
    { label: 'Internal', key: MESSAGE_TYPE.INTERNAL },
    { label: 'Marketing', key: MESSAGE_TYPE.MARKETING },
    { label: 'Push Marketing', key: MESSAGE_TYPE.PUSH_MARKETING }
  ],
  [TEMPLATE_TYPE.LP_PAGE]: [],
  [TEMPLATE_TYPE.WHATSAPP]: [],
  [TEMPLATE_TYPE.TWILIO]: []
}

export const listTempateStatus: Record<string, string> = {
  [TEMPLATE_TYPE.EMAIL]: TEMPLATE_STATUS.RELEASE,
  [TEMPLATE_TYPE.WEB_PUSH]: TEMPLATE_STATUS.RELEASE,
  [TEMPLATE_TYPE.APP_PUSH]: TEMPLATE_STATUS.RELEASE,
  [TEMPLATE_TYPE.LP_PAGE]: TEMPLATE_STATUS.RELEASE,
  [TEMPLATE_TYPE.WHATSAPP]: TEMPLATE_STATUS.APPROVED
}
