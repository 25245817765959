import styled from 'styled-components'

export const StyleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  flex: 0 0 72px;
  padding: 0 24px;
  background: ${({ theme }) => theme.colors.neutral50};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral100};
`

export const StyleTitleFilter = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  flex: 0 0 auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray800};
`

export const StyleWrapperFilter = styled.div`
  display: flex;
  align-items: center;

  .ant-picker {
    min-width: 300px;
  }
`

export const StyleWrapperInfor = styled.div``

export const StyleName = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray800};
`
export const StyleType = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.blue600};
  background: ${({ theme }) => theme.colors.blue50};
  border-radius: 2px;
  padding: 2px 10px;
  margin-left: 10px;
`

export const StyleDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyleDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.gray400};
  margin-left: 10px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.gray400};
    border-radius: 50%;
    margin-right: 5px;
  }
`

export const StyleIdText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray400};
`
