import { getCampaignMessagingInsightMetricKey } from '../CampaignMessagingInsight'
import { CampaignMessagingInsightFilterValue } from '../CampaignMessagingInsightFilter'
import { CampaignServices } from 'services/campaign'

export const defaultDevicesData = {
  android: 0,
  ios: 0,
  linux: 0,
  mac: 0,
  other: 0,
  windows: 0
}

export const getCampaignMessagingInsightDevices = async (
  params: CampaignMessagingInsightFilterValue
) => {
  try {
    const { date_recorded, campaigns } = params
    if (
      !date_recorded ||
      !date_recorded?.[0].isValid() ||
      !date_recorded?.[1].isValid()
    ) {
      return {
        data: null,
        errors: [
          { name: 'date_recorded', errors: ['Date recorded field is required'] }
        ]
      }
    }

    if (!campaigns?.length) {
      return {
        data: null,
        errors: [
          { name: 'campaigns', errors: ['Campaign name field is required'] }
        ]
      }
    }

    const get_metrics_key = getCampaignMessagingInsightMetricKey(params)
    const { data } = await CampaignServices.insightByFilter({
      from: date_recorded[0].valueOf(),
      to: date_recorded[1].valueOf(),
      list_campaign: campaigns?.map(String) || [],
      get_insights_key: ['devices'],
      get_metrics_key: get_metrics_key.length ? get_metrics_key : null
    })

    return {
      data: {
        ...defaultDevicesData,
        ...(data.devices || {})
      },
      error: []
    }
  } catch (error) {
    return { data: null, errors: [{ errors: ['Something went wrong'] }] }
  }
}
