import {
  StyledDropdown,
  StyledDropdownCheckbox,
  StyledDropdownTitle,
  StyledDropdownWrapper,
  StyledIcon,
  StyledSelect,
  StyledTag,
  StyledTagClose,
  StyledTagLabel
} from './index.styled'
import { Select, SelectProps } from 'antd'
import cn from 'classnames'
import { Close } from 'icons'
import { OptionType } from 'interfaces'
import { useEffect, useState } from 'react'

export const defaultSelectCheckboxPlaceholder = '- Select -'
export type SelectCheckboxProps = Omit<
  SelectProps,
  | 'options'
  | 'defaultValue'
  | 'value'
  | 'onChange'
  | 'mode'
  | 'menuItemSelectedIcon'
  | 'optionLabelProp'
  | 'children'
> & {
  title?: string
  options?: OptionType[]
  value?: (string | number | boolean)[]
  defaultValue?: (string | number | boolean)[]
  onChange?: (value: (string | number | boolean)[]) => void
}
export const SelectCheckbox = (props: SelectCheckboxProps) => {
  const {
    title,
    options,
    placeholder,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    tagRender: tagRenderProps,
    dropdownRender: dropdownRenderProps,
    maxTagPlaceholder: maxTagPlaceholderProps,
    ...restProps
  } = props
  const [value, setValue] = useState<(string | number | boolean)[]>(
    defaultValue || valueProps || []
  )

  const handleChange = (val: (string | number | boolean)[]) => {
    setValue(val)
    onChangeProps && onChangeProps(val)
  }

  useEffect(() => {
    setValue(valueProps || [])
  }, [valueProps])

  const tagRender =
    tagRenderProps ||
    ((tagProps) => {
      const { label, onClose, closable } = tagProps
      const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault()
        event.stopPropagation()
      }
      return (
        <StyledTag>
          <StyledTagLabel>{label}</StyledTagLabel>
          {closable && (
            <StyledTagClose onMouseDown={onPreventMouseDown} onClick={onClose}>
              <StyledIcon component={Close} className="anticon-close" />
            </StyledTagClose>
          )}
        </StyledTag>
      )
    })

  const dropdownRender =
    dropdownRenderProps ||
    ((dropdownNode) => {
      return (
        <StyledDropdown>
          {title && <StyledDropdownTitle>{title}</StyledDropdownTitle>}
          <StyledDropdownWrapper>{dropdownNode}</StyledDropdownWrapper>
        </StyledDropdown>
      )
    })

  const maxTagPlaceholder =
    maxTagPlaceholderProps ||
    ((omittedValues: []) => {
      const title = `+ ${omittedValues.length} ...`
      return (
        <StyledTag>
          <StyledTagLabel>{title}</StyledTagLabel>
        </StyledTag>
      )
    })

  return (
    <StyledSelect<any>
      mode="multiple"
      optionLabelProp="name"
      placeholder={placeholder || defaultSelectCheckboxPlaceholder}
      menuItemSelectedIcon={null}
      value={value}
      onChange={(val: (string | number | boolean)[]) => handleChange(val)}
      tagRender={tagRender}
      dropdownRender={dropdownRender}
      maxTagPlaceholder={maxTagPlaceholder}
      {...restProps}
      className={cn('selectCheckbox', restProps?.className)}
      popupClassName={cn(
        'selectCheckbox--dropdown',
        restProps?.dropdownClassName
      )}>
      {options?.map((option, index) => {
        const isChecked = !value.length
          ? false
          : Boolean(value.some((val) => val === option.value))
        const IconElement = option?.icon
        return (
          <Select.Option
            key={`${index}-${option.value}`}
            value={option.value}
            name={option.label}>
            <StyledDropdownCheckbox value={option.value} checked={isChecked}>
              {IconElement && (
                <StyledIcon component={IconElement} className="anticon-label" />
              )}
              {option.label}
            </StyledDropdownCheckbox>
          </Select.Option>
        )
      })}
    </StyledSelect>
  )
}
