import Icon from '@ant-design/icons'
import { DrawerProps } from 'antd'
import {
  StyledDrawer,
  StyledDrawerExtra
} from 'components/atoms/drawer/DrawerPreviewTemplate/DrawerPreviewTemplate.styled'
import { SegmentPageCreate } from 'components/pages'
import { ROLES } from 'constants/role'
import { SEGMENT_TYPE } from 'constants/segment'
import { Close } from 'icons'

type Props = Pick<DrawerProps, 'open' | 'onClose' | 'getContainer'> & {
  onCreateSegment: (data: any) => void
  siteId?: string | number
}

const DrawerCreateSegment = ({
  onClose,
  open,
  getContainer,
  onCreateSegment,
  siteId
}: Props) => {
  return (
    <StyledDrawer
      open={open}
      width="75%"
      onClose={onClose}
      getContainer={getContainer}
      extra={
        <StyledDrawerExtra>
          <Icon component={Close} onClick={onClose} />
        </StyledDrawerExtra>
      }>
      <SegmentPageCreate
        siteId={siteId}
        onCreateSegment={onCreateSegment}
        segment_type={SEGMENT_TYPE.EVENT}
        roles={[ROLES.admin, ROLES.campaign_manager]}
      />
    </StyledDrawer>
  )
}

export default DrawerCreateSegment
