import { StyledFormRelation, StyledSegmented } from './index.styled'
import { SegmentedProps } from 'antd'

export type ConditionsRelationProps = Partial<SegmentedProps>
export const ConditionsRelation = (props: ConditionsRelationProps) => {
  const { className, defaultValue, value, onChange, options, disabled } = props

  return (
    <StyledFormRelation className={className}>
      <StyledSegmented
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={disabled ? 'pointer-events-none' : ''}
        options={options || relationOptions}
      />
    </StyledFormRelation>
  )
}

export type RelationKeys = 'AND' | 'OR'

// UI show AND to get all segment by condition
// Back end use value OR to get all segment by condition
export const relationOptions = [
  {
    label: 'AND',
    value: 'AND'
  },
  {
    label: 'OR',
    value: 'OR'
  }
]
