import {
  MAP_KEY_TO_TASK_NAME,
  MAP_KEY_TO_TEXT,
  capitalizeStr,
  headerStyle
} from '.'
import CopilotDrawerPreview, { replaceAll } from './CopilotDrawerPreview'
import CopilotTabsTemplate from './CopilotTabsTemplate'
import EditorContent, { fakeDataStyle } from './EditorContent'
import {
  StyledActions,
  StyledActionsBottom,
  StyledBubbleIcon,
  StyledCopilotTitle,
  StyledDrawer,
  StyledDrawerMain,
  StyledSelectedContent,
  StyledSelectedType,
  StyledSettings,
  StyledTabs
} from './index.styled'
import { useCopilotSettings } from './useCopilotSettings'
import { useTextExtension } from '@luctc/template-builder'
import { Content as JSONContent } from '@tiptap/core'
import { useEditor } from '@tiptap/react'
import { Button, Spin, notification } from 'antd'
import axios from 'axios'
import cls from 'classnames'
import { SESSION_UUID } from 'constants/common'
import { isProduction } from 'constants/env'
import { useLatest } from 'hooks/useLatest'
import { Close, Copilot, History, Regenerate, SettingOutlined } from 'icons'
import { memo, useEffect, useRef, useState } from 'react'
import { CopilotServices } from 'services/copilot'

function GeneralCopilotDrawer() {
  const timeoutId = useRef<NodeJS.Timeout | undefined>()
  const extensions = useTextExtension()
  const tiptapEditor = useEditor({
    content: `<p></p>`,
    extensions: extensions
  })
  const editor = useLatest(tiptapEditor)
  const cancelRequestRef = useRef(() => {
    //
  })
  const [openCopilot, setOpenCopilot] = useState(false)
  const editorType = 'paragraph'

  const {
    openSettings,

    settingTone,
    settingLength,
    settingLanguage,

    setOpenSettings,

    renderSettingTone,
    renderSettingLength,
    renderSettingLanguage
  } = useCopilotSettings()

  const [selectedKey, setSelectedKey] = useState('improve')
  const [loading, setLoading] = useState(false)
  const [currentPreviewIdx, setCurrentPreviewIdx] = useState(0)
  const [recommendsContent, setRecommendsContent] = useState<string[]>([])

  useEffect(() => {
    if (!openCopilot) {
      setTimeout(() => {
        setOpenSettings(false)
        setLoading(false)
        setRecommendsContent([])
        cancelRequestRef.current()
      }, 300)
    }
  }, [openCopilot, setOpenSettings])

  function generateText({
    input,
    selectedKey
  }: {
    input: string
    selectedKey: string
  }) {
    console.log('input', input)
    if (!input) return
    setLoading(true)
    const { promise, cancelRequest } = CopilotServices.generate({
      feature: 'content',
      task: {
        name: MAP_KEY_TO_TASK_NAME[selectedKey],
        input,
        tone: settingTone,
        length: settingLength,
        language:
          settingLanguage === 'auto'
            ? settingLanguage
            : capitalizeStr(settingLanguage)
      },
      session_uid: SESSION_UUID
    })
    cancelRequestRef.current = cancelRequest
    promise
      .then((res) => {
        if (res.data?.data?.content?.length) {
          setLoading(false)
          setRecommendsContent((prev) => {
            const newRecommends = [
              ...prev,
              replaceAll(res.data.data.content[0], '\\n', '<br />')
            ]
            setCurrentPreviewIdx(newRecommends.length - 1)
            return newRecommends
          })
        }
      })
      .catch((err) => {
        setLoading(false)
        if (axios.isCancel(err)) {
          return
        }
        notification.error({
          message:
            err.response?.data?.error ||
            'Something went wrong, please try again later.'
        })
      })
  }

  function onChangeEditor(_: string, __: string, rawJson: JSONContent) {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = setTimeout(() => {
      editor.current?.commands.setContent(rawJson, true)
    }, 300)
  }

  const htmlContent = editor.current?.getHTML()

  if (!editor.current) {
    return null
  }

  return (
    <>
      <StyledBubbleIcon onClick={() => setOpenCopilot(true)}>
        <Copilot />
      </StyledBubbleIcon>
      <StyledDrawer
        width={404}
        title={
          <StyledCopilotTitle>
            <Copilot /> AI Copilot Assistant
          </StyledCopilotTitle>
        }
        closable={false}
        headerStyle={headerStyle}
        extra={
          <StyledActions>
            <SettingOutlined onClick={() => setOpenSettings(!openSettings)} />
            <History />
            <Close onClick={() => setOpenCopilot(false)} />
          </StyledActions>
        }
        placement="right"
        onClose={() => setOpenCopilot(false)}
        open={openCopilot}
        closeIcon={null}>
        <StyledDrawerMain>
          <StyledTabs defaultActiveKey="Prompt">
            <StyledTabs.TabPane tab="Prompt" key="Prompt">
              <EditorContent
                label="Input your promt here"
                dataStyle={fakeDataStyle}
                htmlContent={htmlContent}
                editorType={editorType}
                onChange={onChangeEditor}
              />
              {selectedKey && (
                <StyledSelectedType>
                  <Button type="primary" size="small">
                    {MAP_KEY_TO_TEXT[selectedKey]}
                  </Button>
                </StyledSelectedType>
              )}
              {loading && (
                <StyledSelectedContent>
                  <Spin />
                </StyledSelectedContent>
              )}
              {!loading && (
                <StyledActionsBottom>
                  <Button
                    size="small"
                    type="default"
                    block
                    icon={<Regenerate />}
                    onClick={() => {
                      generateText({
                        input: editor.current?.getText() || '',
                        selectedKey
                      })
                    }}>
                    Generate text
                  </Button>
                </StyledActionsBottom>
              )}
            </StyledTabs.TabPane>
            {!isProduction && (
              <StyledTabs.TabPane tab="Template" key="Template">
                <CopilotTabsTemplate />
              </StyledTabs.TabPane>
            )}
          </StyledTabs>
        </StyledDrawerMain>
        {openSettings && (
          <StyledSettings>
            {/* Tone */}
            {renderSettingTone()}
            {/* Length */}
            {renderSettingLength()}
            {/* Language */}
            {renderSettingLanguage()}
            <span className="setting-title">Quick action</span>
            <div className="setting-group">
              {Object.keys(MAP_KEY_TO_TEXT).map((key) => (
                <Button
                  shape="round"
                  size="small"
                  key={key}
                  className={cls({ active: key === selectedKey })}
                  onClick={() => setSelectedKey(key)}>
                  {MAP_KEY_TO_TEXT[key]}
                </Button>
              ))}
            </div>
            <div className="group-btn-footer">
              <Button size="small" onClick={() => setOpenSettings(false)}>
                Close
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setOpenSettings(false)
                }}>
                Apply
              </Button>
            </div>
          </StyledSettings>
        )}
        <CopilotDrawerPreview
          recommendsContent={recommendsContent}
          currentPreviewIdx={currentPreviewIdx}
          setCurrentPreviewIdx={setCurrentPreviewIdx}
          dataStyle={fakeDataStyle}
          editorType={editorType}
          editor={editor}
          onChangeEditor={onChangeEditor}
          isHideSubmit={true}
          onClickSubmit={() => null}
        />
      </StyledDrawer>
    </>
  )
}

export default memo(GeneralCopilotDrawer)
