import { FormNamePath, getFormNamePath } from '../form/index.helpers'
// import { StyleTextBtn } from '../group/index.styled'
import { StyleHorizantalField } from '../group/index.styled'
import Icon from '@ant-design/icons'
import {
  Button,
  CheckboxOptionType,
  Form,
  FormListFieldData,
  FormListOperation,
  Input,
  Select
} from 'antd'
import { TrashBin } from 'icons'
import { FC, useMemo } from 'react'

interface EventListFieldsProps {
  rootName: FormNamePath
  fieldOptions: CheckboxOptionType[]
  operatorEventOpts: any[]
  formListFieldData: FormListFieldData
  formListOperation: FormListOperation
}

const EventListFields: FC<EventListFieldsProps> = ({
  rootName,
  fieldOptions,
  operatorEventOpts,
  formListFieldData: { name: itemName },
  formListOperation
}) => {
  const form = Form.useFormInstance()
  const fieldKey = Form.useWatch(
    getFormNamePath(rootName, [itemName, 'fieldKey']),
    form
  )
  const typeField = useMemo(() => {
    if (fieldKey) {
      const result = fieldOptions.find(
        (field) => field.value === fieldKey
      ) as any
      if (!result) {
        return ''
      }
      return result.type
    }
  }, [fieldOptions, fieldKey])
  return (
    <StyleHorizantalField>
      <Form.Item
        label=""
        className="item-field"
        name={[itemName, 'fieldKey']}
        rules={[
          {
            required: true,
            message: 'Please choose field event'
          }
        ]}>
        <Select options={fieldOptions} placeholder="Field" />
      </Form.Item>
      <Form.Item
        label=""
        name={[itemName, 'fieldOperator']}
        className="select-field"
        rules={[
          {
            required: true,
            message: 'Please choose operator'
          }
        ]}>
        <Select options={operatorEventOpts} placeholder="Operator" />
      </Form.Item>
      <Form.Item
        label=""
        name={[itemName, 'fieldValue']}
        className="select-field"
        rules={[
          {
            required: true,
            message: 'Please input value'
          }
        ]}>
        <Input placeholder="Value" type={typeField} />
      </Form.Item>
      <Form.Item>
        <Button
          icon={<Icon component={TrashBin} />}
          size="small"
          onClick={() => formListOperation.remove(itemName)}
        />
      </Form.Item>
    </StyleHorizantalField>
  )
}

export default EventListFields
