import AttributeConditionForm from '../attributeCondition'
import CustomConditionsForm from '../customConditions'
import EventConditionForm from '../eventCondition'
import { FormNamePath } from '../form/index.helpers'
import {
  StyleConditionActions,
  StyleConditionFields,
  StyleConditionList,
  StyleConditionRow,
  StyleHorizantalField
} from '../group/index.styled'
import Icon from '@ant-design/icons'
import {
  Button,
  Form,
  FormListFieldData,
  FormListOperation,
  Select
} from 'antd'
import { BaseOptionType } from 'antd/lib/select'
import { getFormNamePath } from 'components/condition/conditions/form/index.helpers'
import { TrashBin } from 'icons'
import { ConditionDataType } from 'interfaces/condition'
import { FC, Fragment, useMemo } from 'react'
import { useMatch } from 'react-router'

interface ConditionRowProps {
  site: string
  rootName?: FormNamePath
  attributeFields: ConditionDataType[]
  eventFields: any[]
  operatorEventOpts: any[]
  formListFieldData: FormListFieldData
  formListOperation: FormListOperation
}

const ConditionRow: FC<ConditionRowProps> = ({
  site,
  rootName,
  attributeFields,
  eventFields,
  operatorEventOpts,
  formListFieldData,
  formListOperation
}) => {
  const form = Form.useFormInstance()
  const itemName = formListFieldData.name
  const fieldConditionType = Form.useWatch(
    getFormNamePath(rootName, 'conditionType'),
    form
  )
  const isCreateCustomEventsSegmentPage = useMatch(
    '/segment/new-custom-events-segment'
  )
  const isEditCustomEventsSegmentPage = useMatch(
    '/segment/edit-custom-events-segment/:id'
  )
  const hasOptionCustomEvents =
    isCreateCustomEventsSegmentPage || isEditCustomEventsSegmentPage

  const RenderItemFields = useMemo(() => {
    if (!fieldConditionType) {
      return Fragment
    }
    if (fieldConditionType === 'event') {
      return (
        <EventConditionForm
          rootName={getFormNamePath(rootName)}
          eventFields={eventFields}
          operatorEventOpts={operatorEventOpts}
          formListFieldData={formListFieldData}
          formListOperation={formListOperation}
          site={site}
        />
      )
    }
    if (fieldConditionType === 'custom_conditions') {
      return (
        <CustomConditionsForm
          rootName={getFormNamePath(rootName)}
          formListFieldData={formListFieldData}
          formListOperation={formListOperation}
        />
      )
    }
    return (
      <AttributeConditionForm
        rootName={getFormNamePath(rootName)}
        attributeFields={attributeFields}
        formListFieldData={formListFieldData}
        formListOperation={formListOperation}
        site={site}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldConditionType, eventFields, attributeFields])

  const options = useMemo(
    () =>
      [
        {
          label: 'Attribute',
          value: 'attribute',
          disabled: true
        },
        {
          label: 'Event',
          value: 'event'
        },
        hasOptionCustomEvents
          ? {
              label: 'Custom conditions',
              value: 'custom_conditions'
            }
          : null
      ].filter(Boolean) as BaseOptionType[],
    [hasOptionCustomEvents]
  )

  return (
    <StyleConditionRow>
      <StyleConditionList>
        <StyleConditionFields>
          <StyleHorizantalField>
            <Form.Item
              label=""
              className="item-field"
              name={[itemName, 'conditionType']}
              rules={[
                {
                  required: true,
                  message: 'Please choose type segment'
                }
              ]}>
              <Select
                options={options}
                placeholder="Type condition"
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </StyleHorizantalField>
          {RenderItemFields}
        </StyleConditionFields>
      </StyleConditionList>
      <StyleConditionActions size={8}>
        <Button
          icon={<Icon component={TrashBin} />}
          size="small"
          onClick={() => formListOperation.remove(itemName)}
        />
      </StyleConditionActions>
    </StyleConditionRow>
  )
}

export default ConditionRow
