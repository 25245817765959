import { InputTimeState } from './'

export function numberToInputTime(num?: number | string): InputTimeState {
  const time: InputTimeState = {
    day: undefined,
    hour: undefined,
    minute: undefined
  }

  if (!num || num === 0 || num === '0') {
    return time
  }

  const number = Number(num) / 60 / 1000

  time.day = Math.floor(number / (24 * 60))
  time.hour = Math.floor(number / 60) % 24
  time.minute = number % 60

  return time
}

export function inputTimeToNumber(
  time: InputTimeState
): number | string | null {
  let num = 0
  if (time.day) {
    num += Number(time.day) * 24 * 60
  }

  if (time.hour) {
    num += Number(time.hour) * 60
  }

  if (time.minute) {
    num += Number(time.minute)
  }

  return num * 60 * 1000 || null
}
