import Icon from '@ant-design/icons'
import {
  Button as BaseButton,
  Menu as BaseMenu,
  ButtonProps,
  Dropdown,
  MenuProps
} from 'antd'
import { useAuth } from 'context/Auth'
import { checkAuth } from 'hooks/useCheckAuth'
import { Download, FilterList, Folder, Kebab, TrashBin } from 'icons'
import { FC } from 'react'
import styled from 'styled-components'
import { cssMenuDropdown } from 'styles'

const Button = styled(BaseButton)<ButtonProps>`
  color: ${({ theme }) => theme?.colors?.gray600 || ''};
`
const Menu = styled(BaseMenu)<MenuProps>`
  ${cssMenuDropdown}
`

export interface SegmentMenuDropdownProps {
  onDetail?: () => void
  onEdit?: () => void
  onExport?: () => void
  onDelete?: () => void
}

export const SegmentMenuDropdown: FC<SegmentMenuDropdownProps> = ({
  onDetail,
  onEdit,
  onExport,
  onDelete
}) => {
  const { userAuth } = useAuth()
  const isReview = !checkAuth(
    {
      roles: [
        'admin',
        'campaign_manager',
        'campaign_view',
        'visitor',
        'read_only'
      ],
      permissions: ['segment_view_analytics']
    },
    userAuth?.auth || {}
  )
  const isEdit = !checkAuth(
    {
      roles: [
        'admin',
        'campaign_manager',
        'campaign_view',
        'visitor',
        'read_only'
      ],
      permissions: ['segment_edit']
    },
    userAuth?.auth || {}
  )
  const isExport = !checkAuth(
    {
      roles: [
        'admin',
        'campaign_manager',
        'campaign_view',
        'visitor',
        'read_only'
      ],
      permissions: ['segment_export']
    },
    userAuth?.auth || {}
  )
  const isDelete = !checkAuth(
    {
      roles: [
        'admin',
        'campaign_manager',
        'campaign_view',
        'visitor',
        'read_only'
      ],
      permissions: ['segment_delete']
    },
    userAuth?.auth || {}
  )
  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu
          items={[
            {
              key: 'detail',
              label: 'Detail',
              onClick: onDetail,
              icon: <Icon component={Folder} />,
              disabled: isReview
            },
            {
              key: 'filter',
              label: 'Edit',
              onClick: onEdit,
              icon: <Icon component={FilterList} />,
              disabled: isEdit
            },
            {
              key: 'export',
              label: 'Export',
              onClick: onExport,
              icon: <Icon component={Download} />,
              disabled: isExport
            },
            {
              key: 'delete',
              label: 'Delete',
              onClick: onDelete,
              icon: <Icon component={TrashBin} />,
              disabled: isDelete
            }
          ]}
        />
      }>
      <Button
        type="link"
        className="segmentMenu--button"
        icon={<Icon component={Kebab} />}
      />
    </Dropdown>
  )
}
