import { ConditionBaseProps } from '..'
import {
  StyledCondition,
  StyledConditionContent,
  StyledConditionInputNumber,
  StyledConditionLabel
} from '../index.styled'
import { useEffect, useState } from 'react'

export type ConditionOperatorProps = ConditionBaseProps<number | null> & {
  label?: string
  placeholder?: string
}
export const ConditionNumber = (props: ConditionOperatorProps) => {
  const {
    label,
    className,
    placeholder,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    ...restProps
  } = props
  const [value, setValue] = useState(defaultValue || valueProps || null)

  const onChange = (val: number | null) => {
    const newValue = val
    setValue(newValue)
    onChangeProps && onChangeProps(newValue)
  }

  useEffect(() => {
    setValue(valueProps || null)
  }, [valueProps])

  return (
    <StyledCondition className={className}>
      {label && <StyledConditionLabel>{label}</StyledConditionLabel>}
      <StyledConditionContent>
        <StyledConditionInputNumber
          placeholder={placeholder}
          value={value || undefined}
          onChange={(val) => onChange(val as number)}
          {...restProps}
        />
      </StyledConditionContent>
    </StyledCondition>
  )
}
