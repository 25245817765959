import {
  CampaignPerformance,
  getCampaignPerformanceProps
} from '../CampaignPerformance'
import {
  fetchCampaignDrawerSegments,
  fetchCampaignDrawerTemplate,
  getCampaignDrawerSegments,
  getCampaignDrawerSettings,
  getCampaignDrawerSite,
  getCampaignDrawerTemplate
} from './CampaignDrawer.helpers'
import {
  StyledCampaignDrawer,
  StyledCampaignDrawerButton,
  StyledCampaignDrawerCollapse,
  StyledCampaignDrawerExtra,
  StyledCampaignDrawerIcon,
  StyledCampaignDrawerInfo,
  StyledCampaignDrawerItem,
  StyledCampaignDrawerItemSkeleton,
  StyledCampaignDrawerList,
  StyledCampaignDrawerTitle,
  StyledCampaignDrawerTitleContainer
} from './CampaignDrawer.styled'
import { Empty, Skeleton } from 'antd'
import { TEMPLATE_TYPE } from 'constants/template'
import { useApp } from 'context/App'
import { ChevronDown, ChevronRight, Close, Edit, Visible } from 'icons'
import { mapTypeToMedium } from 'pages/templateBuilderList/helpers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCampaignRoute } from 'utils/campaign'
import { getTemplateRoute } from 'utils/template'

export const CAMPAIGN_TYPE_NOT_INSIGHT = [
  TEMPLATE_TYPE.TWILIO,
  TEMPLATE_TYPE.VNPT,
  TEMPLATE_TYPE.ZALO,
  'automation'
]

export type CampaignDrawerProps = {
  data?: any
  onClose?: (id?: string | number) => void
}

export const CampaignDrawer = (props: CampaignDrawerProps) => {
  const navigate = useNavigate()
  const { data: dataProps, onClose } = props
  const { sites } = useApp()
  const [open, setOpen] = useState(!!dataProps?.id)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>(dataProps)

  const settings = useMemo(() => getCampaignDrawerSettings(data), [data])
  const site = useMemo(() => getCampaignDrawerSite(data, sites), [data, sites])
  const segments = useMemo(() => getCampaignDrawerSegments(data), [data])
  const template = useMemo(() => getCampaignDrawerTemplate(data), [data])

  const handleClose = () => {
    setOpen(!open)
    onClose?.(undefined)
  }

  const handleEdit = () => {
    navigate(getCampaignRoute(data?.id, data?.template_type))
  }

  const handlePreviewTemplate = useCallback(() => {
    if (data?.template) {
      if (data.template?.template) {
        navigate(
          getTemplateRoute(
            data.template.template?.id,
            data.template.template?.type
          )
        )
      }
    }
  }, [data?.template, navigate])

  useEffect(() => {
    setOpen(!!dataProps?.id)
    const dataInfo = { ...dataProps }
    setLoading(true)
    ;(async () => {
      try {
        dataInfo.template = await fetchCampaignDrawerTemplate(
          dataProps?.template_id
        )
        dataInfo.segment = await fetchCampaignDrawerSegments(
          dataProps?.segments?.map(({ id }: any) => id) || []
        )
        setData(dataInfo)
      } catch (error) {}
    })()
    setLoading(false)
  }, [dataProps])

  useEffect(() => {
    // Set unmount
    return () => {
      setOpen(false)
      setLoading(false)
      setData(undefined)
    }
  }, [])

  return (
    <StyledCampaignDrawer
      open={open}
      onClose={handleClose}
      closeIcon={null}
      width={600}
      title={
        <StyledCampaignDrawerTitleContainer>
          <StyledCampaignDrawerTitle>{data?.name}</StyledCampaignDrawerTitle>
          <StyledCampaignDrawerInfo>
            ID: <span>{data?.id}</span>
          </StyledCampaignDrawerInfo>
          <StyledCampaignDrawerInfo>
            Type: <span>{mapTypeToMedium[data?.template_type]?.label}</span>
          </StyledCampaignDrawerInfo>
        </StyledCampaignDrawerTitleContainer>
      }
      extra={
        <StyledCampaignDrawerExtra>
          <StyledCampaignDrawerIcon component={Edit} onClick={handleEdit} />
          <StyledCampaignDrawerIcon component={Close} onClick={handleClose} />
        </StyledCampaignDrawerExtra>
      }>
      <Skeleton loading={loading} title={false} paragraph={{ rows: 2 }} active>
        {!CAMPAIGN_TYPE_NOT_INSIGHT.includes(data?.template_type) && (
          <CampaignPerformance
            {...getCampaignPerformanceProps(data)}
            className="drawer--performance"
            hasTotal
          />
        )}
      </Skeleton>
      <StyledCampaignDrawerCollapse
        ghost
        collapsible="header"
        defaultActiveKey={['overviews', 'segments', 'site', 'template']}
        expandIcon={(panelProps) => {
          return (
            <StyledCampaignDrawerIcon
              className="collapse--icon"
              component={panelProps.isActive ? ChevronDown : ChevronRight}
            />
          )
        }}>
        <StyledCampaignDrawerCollapse.Panel key="overviews" header="Settings">
          <StyledCampaignDrawerList>
            {settings.map(({ label, content }, index) => {
              return (
                <StyledCampaignDrawerItemSkeleton
                  loading={loading}
                  title={false}
                  paragraph={{ rows: 1, width: '100%' }}
                  active
                  key={`${index}`}>
                  <StyledCampaignDrawerItem>
                    <span className="label">{label}</span>
                    <span className="content">{content}</span>
                  </StyledCampaignDrawerItem>
                </StyledCampaignDrawerItemSkeleton>
              )
            })}
          </StyledCampaignDrawerList>
        </StyledCampaignDrawerCollapse.Panel>
        <StyledCampaignDrawerCollapse.Panel key="segments" header="Segments">
          <StyledCampaignDrawerItemSkeleton
            loading={loading}
            title={false}
            paragraph={{ rows: 4, width: '100%' }}
            active>
            {!segments?.length ? (
              <Empty />
            ) : (
              segments.map(({ label, content }: any, index: number) => {
                return (
                  <StyledCampaignDrawerItem key={`${index}`}>
                    <span className="label">{label}</span>
                    <span className="content">{content}</span>
                  </StyledCampaignDrawerItem>
                )
              })
            )}
          </StyledCampaignDrawerItemSkeleton>
        </StyledCampaignDrawerCollapse.Panel>
        <StyledCampaignDrawerCollapse.Panel key="site" header="Site">
          {site.map(({ label, content }, index) => {
            return (
              <StyledCampaignDrawerItemSkeleton
                loading={loading}
                title={false}
                paragraph={{ rows: 1, width: '100%' }}
                active
                key={`${index}`}>
                <StyledCampaignDrawerItem>
                  <span className="label">{label}</span>
                  <span className="content">{content}</span>
                </StyledCampaignDrawerItem>
              </StyledCampaignDrawerItemSkeleton>
            )
          })}
        </StyledCampaignDrawerCollapse.Panel>
        <StyledCampaignDrawerCollapse.Panel
          key="template"
          header="Template"
          extra={
            <StyledCampaignDrawerItemSkeleton
              loading={loading}
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
              active>
              <StyledCampaignDrawerButton
                type="link"
                className="preview"
                icon={<StyledCampaignDrawerIcon component={Visible} />}
                onClick={handlePreviewTemplate}>
                Preview
              </StyledCampaignDrawerButton>
            </StyledCampaignDrawerItemSkeleton>
          }>
          <StyledCampaignDrawerItemSkeleton
            loading={loading}
            title={false}
            paragraph={{ rows: 4, width: '100%' }}
            active>
            {!template?.length ? (
              <Empty />
            ) : (
              template.map(({ label, content }, index) => {
                return (
                  <StyledCampaignDrawerItem key={`${index}`}>
                    <span className="label">{label}</span>
                    <span className="content">{content}</span>
                  </StyledCampaignDrawerItem>
                )
              })
            )}
          </StyledCampaignDrawerItemSkeleton>
        </StyledCampaignDrawerCollapse.Panel>
      </StyledCampaignDrawerCollapse>
    </StyledCampaignDrawer>
  )
}
