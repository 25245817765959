import { SegmentFormOverviewValue } from './SegmentFormOverview'

export const formatSegmentFormOverview = (
  initValue: any
): SegmentFormOverviewValue => {
  const newValue: SegmentFormOverviewValue = { ...initValue }
  try {
    if (initValue?.sites && Array.isArray(initValue?.sites)) {
      newValue.site_ids = initValue.sites
        .map((site: any) => site?.id || false)
        .filter(Boolean)
    }
    if (initValue?.tags) {
      newValue.tag_ids = initValue.tags.map(({ id }: any) => id)
    }
  } catch (error) {
    console.log('** ERROR SegmentForm.helpers.ts 56 : ', error)
  }

  return newValue
}
