import { CampaignStepSwitch } from './CampaignStepSwitch'
import { Select, SelectProps } from 'antd'
import { Ref, forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'

const StyledSelect = styled(Select)<SelectProps>`
  flex: 1;
  min-width: 0;
  max-width: 140px;
`

const StyledInner = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  &.label {
    color: ${({ theme }) => theme?.colors?.gray800};
    font-weight: 400;
    letter-spacing: -0.2px;
  }
  ${StyledSelect} {
    margin-left: 8px;
  }
`

const thresholdOptions = new Array(100).fill(1).map((value, index) => ({
  label: `${value + index}`,
  value: value + index
}))

export type CampaignStepSwitchThresholdRef = HTMLDivElement
export type CampaignStepSwitchThresholdProps = {
  disabled?: boolean
  defaultValue?: number
  value?: number
  onChange?: (value: number) => void
}

const CampaignStepSwitchThresholdWithoutRef = (
  props: CampaignStepSwitchThresholdProps,
  ref?: Ref<CampaignStepSwitchThresholdRef>
) => {
  const { disabled, defaultValue, value: valueProps, onChange } = props
  const [value, setValue] = useState(defaultValue || valueProps)

  const handleChange = (value: number) => {
    setValue(value)
    onChange?.(value)
  }

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  return (
    <CampaignStepSwitch
      ref={ref}
      isShowSwitch={false}
      disabled={disabled}
      title="Set a send rate limit"
      description="Predefine the rate limit to send per email">
      <StyledInner>
        <span className="label">Threshold Limit Offset</span>
        <StyledSelect<any>
          showSearch
          disabled={disabled}
          value={value}
          options={thresholdOptions}
          onChange={handleChange}
        />
      </StyledInner>
    </CampaignStepSwitch>
  )
}
export const CampaignStepSwitchThreshold = forwardRef(
  CampaignStepSwitchThresholdWithoutRef
)
