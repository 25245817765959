import {
  DictionaryFormValue,
  formatDictionaryFormValue
} from '../DictionaryForm'
import { notification } from 'antd'
import { ModalConfirmation } from 'components/atoms/modal/ModalConfirmation'
import { useEffect, useState } from 'react'
import { FieldServices } from 'services/field'

export type DictionaryModalConfirmationProps = {
  confirm?: DictionaryFormValue
  onConfirm?: (value: DictionaryFormValue | any) => void
  dictionary_type?: string
  onClose?: (open: boolean) => void
}

export const DictionaryModalConfirmation = (
  props: DictionaryModalConfirmationProps
) => {
  const { confirm, onConfirm, dictionary_type, onClose } = props
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(formatDictionaryFormValue(confirm))

  const handleCancel = () => {
    onClose?.(false)
  }

  const handleOk = async () => {
    if (typeof value?.id === 'number') {
      setLoading(true)
      FieldServices.updateField(value.id, {
        ...value,
        status: value?.status ? 'disable' : 'enable'
      })
        .then((res) => {
          if (res._statusRes === 500) {
            throw new Error(JSON.stringify(res))
          }
          if (res.data?.id) {
            notification.success({
              message: (
                <>
                  Update status for field <strong>{res.data?.name}</strong>{' '}
                  successfully
                </>
              )
            })
            onConfirm?.(res.data)
          }
        })
        .catch((error) => {
          console.log('** ERROR DictionaryPage.tsx: ', error)
          notification.error({
            message: (
              <>
                Cannot update status for this field{' '}
                <strong>{value?.name}</strong>
              </>
            )
          })
        })
        .finally(() => {
          setLoading(false)
          onClose?.(false)
        })
    }
  }

  useEffect(() => {
    setValue(formatDictionaryFormValue(confirm))
  }, [confirm])

  return (
    <ModalConfirmation
      open={!!value?.id}
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ loading }}
      description={
        <span>
          Your action would be impact directly in Segmentation tools. Field{' '}
          <strong>{value.name}</strong> will automatically{' '}
          {value.status ? 'removed' : 'display'} in{' '}
          {dictionary_type?.substring(0, 1).toUpperCase()}
          {dictionary_type?.substring(1)} list
        </span>
      }
    />
  )
}
