import { Select, SelectProps } from '../index'
import { useEffect, useState } from 'react'
import { FieldEventServices } from 'services/fieldAttribute'

export type SelectEventFieldProps = SelectProps & {
  event_id?: string | number
  disabled_event_fields?: Array<string | number>
}
export function SelectEventField(props: SelectEventFieldProps) {
  const {
    event_id,
    disabled_event_fields,
    options: optionsProps,
    ...restProps
  } = props
  const [options, setOptions] = useState(optionsProps || [])

  useEffect(() => {
    setOptions(optionsProps || [])
  }, [optionsProps])

  useEffect(() => {
    if (!event_id && (!optionsProps || !optionsProps?.length)) {
      setOptions([])
      return
    } else if (!event_id && optionsProps && optionsProps.length) {
      setOptions(
        optionsProps.map((option) => ({
          ...option,
          disabled: disabled_event_fields?.includes(option.value as any)
        }))
      )
      return
    }

    if (!event_id) {
      return
    }

    ;(async () => {
      try {
        const eventFieldsRes = await FieldEventServices.getListFields({
          event_id: event_id.toString()
        })

        if (!eventFieldsRes.data?.data) {
          throw new Error(eventFieldsRes.data.error)
        }

        const newOptions =
          eventFieldsRes.data.data?.map((event: any) => ({
            value: event.field,
            label: event.name,
            disabled: disabled_event_fields?.includes(event.field)
          })) || []

        setOptions(newOptions)
      } catch (error) {
        console.log('** Error SelectEventField : ', error)
      }
    })()
  }, [optionsProps, event_id, disabled_event_fields])

  return <Select options={options} {...restProps} />
}
