import { CampaignMessagingInsightFilterValue } from '../CampaignMessagingInsightFilter'
import { getCampaignMessagingInsightAudience } from './CampaignMessagingInsightTabAudience.helpers'
import {
  StyledCampaignMessagingInsightTabAudienceButton,
  StyledCampaignMessagingInsightTabAudienceTab
} from './CampaignMessagingInsightTabAudience.styled'
import { HeatMapComponent } from './HeatMapComponent'
import Icon from '@ant-design/icons'
import { Dropdown, MenuProps, Spin } from 'antd'
import cn from 'classnames'
import { SITE_FLAGS } from 'constants/site'
import { Kebab } from 'icons'
import { OptionTypeSite } from 'interfaces'
import {
  CSSProperties,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'

const DROPDOWN_ITEMS = [
  {
    key: 'Click',
    label: 'Clicks by time of day',
    className: 'py-3'
  },
  {
    key: 'Open',
    label: 'Open by time of day',
    className: 'py-3'
  },
  {
    key: 'Read',
    label: 'Read by time of day',
    className: 'py-3'
  }
]
type Heatmaps = Record<string, Record<string, Record<string, number>>>
export type CampaignMessagingInsightTabAudienceHeatmapSite = OptionTypeSite & {
  timezones?: { value: string; label: string; siteKeys: string[] }[]
}
export type CampaignMessagingInsightTabAudienceHeatmapRef = {
  refresh?: (
    value: CampaignMessagingInsightFilterValue,
    timezone?: string
  ) => void
}
export type CampaignMessagingInsightTabAudienceHeatmapProps = {
  activeTab?: string
  site?: CampaignMessagingInsightTabAudienceHeatmapSite
  params?: CampaignMessagingInsightFilterValue
  style?: CSSProperties
  className?: string
}
const CampaignMessagingInsightTabAudienceHeatmapWithoutRef = (
  props: CampaignMessagingInsightTabAudienceHeatmapProps,
  ref?: Ref<CampaignMessagingInsightTabAudienceHeatmapRef>
) => {
  const { activeTab: activeTabProps, site, params, style, className } = props
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTabs] = useState(
    activeTabProps || site?.timezones?.[0].value
  )
  const [heatmaps, setHeatmaps] = useState<Heatmaps>({})
  const [drodpownActive, setDropdownActive] = useState('Click')
  const items = useMemo(() => {
    let items = DROPDOWN_ITEMS
    if (params?.campaign_channels !== 'whatsapp') {
      items = items.filter((item) => item.key !== 'Read')
    }

    return items
  }, [params])

  const refresh: NonNullable<
    CampaignMessagingInsightTabAudienceHeatmapRef['refresh']
  > = useCallback(
    async (params, timezone) => {
      const time_zone = timezone || activeTab || ''
      if (!time_zone) {
        return
      }
      setLoading(true)
      const { data } = await getCampaignMessagingInsightAudience({
        ...params,
        audience_type_chart: (drodpownActive || 'Click') as 'Click' | 'Open',
        time_zone
      })
      const newHeatmaps = { ...heatmaps }
      newHeatmaps[time_zone] = data as Record<string, Record<string, number>>
      setHeatmaps(newHeatmaps)
      setActiveTabs(time_zone)
      setLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drodpownActive]
  )

  const handleChangeTab = (timezone: string) => {
    setActiveTabs(timezone)
    refresh(params as CampaignMessagingInsightFilterValue, timezone)
  }

  const handleClickDropdown: NonNullable<MenuProps['onClick']> = (menuInfo) => {
    setDropdownActive(menuInfo.key)
  }

  useEffect(() => {
    refresh(
      params as CampaignMessagingInsightFilterValue,
      site?.timezones?.[0].value
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  useEffect(() => {
    if (params?.campaign_channels !== 'whatsapp') {
      setDropdownActive('Click')
    }
  }, [params?.campaign_channels])

  useImperativeHandle(ref, () => ({ refresh }), [refresh])

  if (!site || !site.name || !site.timezones || !site.timezones?.length) {
    return (
      <div
        style={style}
        className={cn(
          'w-full p-4 bg-white ',
          'border border-solid border-neutral100 rounded-lg',
          className
        )}>
        <div className="audienceHead mb-4 flex items-center justify-between">
          <h3 className="m-0 text-lg font-bold text-gray800">
            {drodpownActive} by Time of Day
          </h3>
        </div>
        <div className="audienceBody w-full">
          <Spin spinning={loading}>
            <HeatMapComponent dataSource={{}} />
          </Spin>
        </div>
      </div>
    )
  }

  return (
    <div
      style={style}
      className={cn(
        'w-full p-4 bg-white ',
        'border border-solid border-neutral100 rounded-lg',
        className
      )}>
      <div className="audienceHead mb-4 flex items-center justify-between">
        <div className="flex gap-4">
          <h3 className="m-0 text-lg font-bold text-gray800">
            {drodpownActive} by Time of Day
          </h3>
          <div className="flex items-center gap-2 rounded-2xl bg-blue-50 py-1 px-4">
            <img alt="" src={SITE_FLAGS[site.name]} className="h-[16px]" />
            <span className="m-0">{site.label}</span>
          </div>
        </div>
        <div className="audienceHead-actions flex items-center gap-4">
          <StyledCampaignMessagingInsightTabAudienceTab
            activeKey={activeTab}
            onChange={handleChangeTab}
            items={site.timezones.map(({ label, value }) => ({
              key: value,
              label: label,
              children: null
            }))}
          />
          <Dropdown
            trigger={['click']}
            menu={{
              items,
              selectedKeys: [drodpownActive],
              onClick: handleClickDropdown
            }}>
            <StyledCampaignMessagingInsightTabAudienceButton
              type="link"
              icon={<Icon component={Kebab} />}
            />
          </Dropdown>
        </div>
      </div>
      <div className="audienceBody w-full">
        <Spin spinning={loading}>
          <HeatMapComponent dataSource={heatmaps?.[activeTab || ''] || {}} />
        </Spin>
      </div>
    </div>
  )
}
export const CampaignMessagingInsightTabAudienceHeatmap = forwardRef(
  CampaignMessagingInsightTabAudienceHeatmapWithoutRef
)
