import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { cssLimitLines } from 'styles'

export const StyledTableCellTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
`

export const StyledTableCellTitleLink = styled(Link)`
  color: inherit;
  &:hover {
    color: inherit;
  }
`

export const StyledTableCellTitleH5 = styled.h5<{ limit?: number }>`
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray800 || '#262626'};
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  ${({ limit }) => cssLimitLines(limit)}
`

export const StyledTableCellTitleP = styled.p<{ limit?: number }>`
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray400 || '#8C8C8C'};
  font-size: 12px;
  font-weight: inherit;
  line-height: inherit;
  ${({ limit }) => cssLimitLines(limit)}
`
