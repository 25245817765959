/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, notification } from 'antd'
import cls from 'classnames'
import { Box } from 'components/atoms/box'
import { getToken } from 'context/Auth'
import { CheckedCircle } from 'icons'
import FeatureCarousel from 'pages/login/FeatureCarousel'
import LoginFooter from 'pages/login/LoginFooter'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { changePassword } from 'services/user'

const ResetPasswordRedirectPage = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { token } = useParams()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [isChangedSuccessfully, setIsChangedSuccessfully] = useState(false)
  const email = new URLSearchParams(location.search).get('email')

  useEffect(() => {
    if (getToken()) {
      navigate('/')
    }
  }, [navigate])

  useEffect(() => {
    if (!email) {
      navigate('/login')
    }
  }, [navigate, email])

  const password = Form.useWatch('password', form)

  const isIncludeNumber = /[0-9]+/g.test(password)
  const isAtLeast8Chars = password?.length >= 8
  const isUpperAndLowerCase =
    /[A-Z]+/g.test(password) && /[a-z]+/g.test(password)

  const isEnabled = isIncludeNumber && isAtLeast8Chars && isUpperAndLowerCase

  const handleChangePassword = async (params: {
    password: string
    password_confirmation: string
  }) => {
    if (!isEnabled || loading || !email || !token) return
    try {
      setLoading(true)
      const response = await changePassword({
        email,
        password: params.password,
        password_confirmation: params.password_confirmation,
        token
      })
      setIsChangedSuccessfully(true)
    } catch (err) {
      console.log('[Login] error', err)
      notification.error({
        message: 'Oops!',
        description:
          'Something went wrong. Please try again or contact support.',
        placement: 'topLeft'
      })
    }
    setLoading(false)
  }

  return (
    <Box className="flex flex-row md:h-[100vh] bg-[#FAFAFA]">
      <Box className="flex flex-1 h-full flex-col justify-center items-center relative overflow-y-auto">
        <Box className="text-center max-w-full w-[440px] p-[40px] bg-[#fff] rounded-[16px] my-auto">
          {isChangedSuccessfully ? (
            <img
              src="/images/sidis-change-password-check.png"
              alt="Sidis"
              className="w-[80px] h-auto block mb-[40px] mx-auto"
            />
          ) : (
            <img
              src="/images/sidis-logo.png"
              alt="Sidis"
              className="w-[96px] h-auto block mb-[40px] mx-auto"
            />
          )}
          <h2 className="text-[#262626] font-bold text-[22px] leading-[32px] tracking-[-0.6px]">
            {isChangedSuccessfully
              ? 'Password update successful'
              : 'Enter your new password'}
          </h2>
          <p className="text-base mt-[8px] mb-[24px] text-[#595959]">
            {isChangedSuccessfully
              ? 'You can now login to your Sidis account with the new password'
              : 'Your new password must be different from previous used password'}
          </p>

          {isChangedSuccessfully ? (
            <Button
              type="primary"
              className="block w-full"
              size="large"
              onClick={() => navigate('/login')}>
              Back to Login
            </Button>
          ) : (
            <>
              <Form
                layout="vertical"
                className="text-left"
                form={form}
                onFinish={handleChangePassword}>
                <Form.Item
                  label="Password"
                  name="password"
                  requiredMark="optional"
                  rules={[
                    { required: true, message: 'Please input your password!' }
                  ]}>
                  <Input.Password
                    size="large"
                    className="!py-[12px]"
                    placeholder="Enter your new password here"
                  />
                </Form.Item>
                <Form.Item
                  label="Confirm New Password"
                  name="password_confirmation"
                  requiredMark="optional"
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!'
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(
                            'The two passwords that you entered do not match!'
                          )
                        )
                      }
                    })
                  ]}>
                  <Input.Password
                    size="large"
                    className="!py-[12px]"
                    placeholder="Enter your new password again to confirm"
                  />
                </Form.Item>
                <Box className="flex gap-[8px] flex-col mb-[24px]">
                  <Box className="flex items-center gap-[4px]">
                    <CheckedCircle
                      className={cls('w-[16px h-[16px]', {
                        'text-[#8C8C8C]': !isIncludeNumber,
                        'text-[#00B16A]': isIncludeNumber
                      })}
                      fill="currentColor"
                    />
                    <p className="m-0 text-[#262626] text-sm leading-[22px] tracking-[-0.2px]">
                      Include a number
                    </p>
                  </Box>
                  <Box className="flex items-center gap-[4px]">
                    <CheckedCircle
                      className={cls('w-[16px h-[16px]', {
                        'text-[#8C8C8C]': !isAtLeast8Chars,
                        'text-[#00B16A]': isAtLeast8Chars
                      })}
                      fill="currentColor"
                    />
                    <p className="m-0 text-[#262626] text-sm leading-[22px] tracking-[-0.2px]">
                      Must be at least 8 characters
                    </p>
                  </Box>
                  <Box className="flex items-center gap-[4px]">
                    <CheckedCircle
                      className={cls('w-[16px h-[16px]', {
                        'text-[#8C8C8C]': !isUpperAndLowerCase,
                        'text-[#00B16A]': isUpperAndLowerCase
                      })}
                      fill="currentColor"
                    />
                    <p className="m-0 text-[#262626] text-sm leading-[22px] tracking-[-0.2px]">
                      An uppercase and lower case letter.
                    </p>
                  </Box>
                </Box>
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                    disabled={!isEnabled}
                    className="w-full block">
                    Continue
                  </Button>
                </Form.Item>
              </Form>

              <p className="text-[#737373] text-[14px] leading-[22px] mr-[24px tracking-[-0.2px]">
                Have your password?{' '}
                <Link to="/login" className="text-[#2D87F3] font-semibold">
                  Login
                </Link>
              </p>
            </>
          )}
        </Box>
        <LoginFooter />
      </Box>
      <FeatureCarousel />
    </Box>
  )
}

export default ResetPasswordRedirectPage
