import Icon from '@ant-design/icons'
import { Result, ResultProps } from 'antd'
import { UnderContruction } from 'icons'
import styled from 'styled-components'

const StyledIcon = styled(Icon)`
  margin-bottom: 16px;
`

const StyledResult = styled(Result)`
  padding: 80px 16px;
  .ant-result-icon {
    ${StyledIcon} {
      font-size: 240px;
    }
    .anticon {
      margin-bottom: 0;
    }
  }
  .ant-result-title {
    margin-bottom: 6px;
    color: ${({ theme }) => theme?.colors?.gray800 || ''};
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
  }
  .ant-result-subTitle {
    color: ${({ theme }) => theme?.colors?.gray600 || ''};
  }
`

export type ResultUnderContructionProps = ResultProps
export const ResultUnderContruction = (props: ResultUnderContructionProps) => {
  const {
    icon = <StyledIcon component={UnderContruction} />,
    title = 'This page is under construction',
    subTitle = 'We’re working on it',
    ...restProps
  } = props
  return (
    <StyledResult
      icon={icon}
      title={title}
      subTitle={subTitle}
      {...restProps}
    />
  )
}
