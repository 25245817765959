import {
  Button,
  Form,
  FormInstance,
  FormProps,
  Input,
  notification
} from 'antd'
import { FormHeaderTags } from 'components/atoms/FormHeader'
import { useTagOptions } from 'hooks/useSegment'
import { Ref, forwardRef, useCallback, useImperativeHandle } from 'react'
import { tagServices } from 'services/segment'

export type AnalyticTemplateFormValue = {
  id?: number
  name?: string
  description?: string
  data?: {
    segment: string
    period: string
    tag_ids?: number[]
  }
  created_at?: string
  created_by?: {
    email: string
    first_name: string
    last_name: string
  }
}
export type AnalyticTemplateFormRef = FormInstance<AnalyticTemplateFormValue>
export type AnalyticTemplateFormProps = Pick<
  FormProps,
  'initialValues' | 'onValuesChange' | 'onFinish' | 'onFinishFailed'
>

const AnalyticTemplateFormWithoutRef = (
  props: AnalyticTemplateFormProps,
  ref?: Ref<AnalyticTemplateFormRef>
) => {
  const { initialValues, onFinish: onFinishProps, ...restProps } = props
  const [form] = Form.useForm()
  const { tags: tagsOption, refetchTags } = useTagOptions()

  const onFinish = (values: AnalyticTemplateFormValue) => {
    onFinishProps?.({
      ...initialValues,
      ...values,
      data: {
        ...initialValues?.data,
        ...values?.data
      }
    })
  }
  const onCreateTag = useCallback(
    async (tagName: string) => {
      try {
        const response = await tagServices.createTag({
          name: tagName,
          type: 'template'
        })
        const tagId = response.data.id
        if (tagId) {
          const tagIds = form.getFieldValue(['data', 'tag_ids']) || []
          refetchTags()

          setTimeout(() => {
            form.setFieldValue(['data', 'tag_ids'], [...tagIds, tagId])
          }, 10)
        }
      } catch (error) {
        notification.error({
          message:
            'Something went wrong while creating a new label. Please try again!'
        })
      }
    },
    [form, refetchTags]
  )
  useImperativeHandle(ref, () => form, [form])

  return (
    <Form
      {...restProps}
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      layout="vertical">
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name="name"
        label="Name"
        rules={[
          { required: true, message: 'This field is required' },
          {
            pattern: /^(?!\s).+(?<!\s)$/gm,
            message: 'Please input NO white space'
          }
        ]}>
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea placeholder="Description" />
      </Form.Item>
      <Form.Item name={['data', 'tag_ids']} label="Label">
        <FormHeaderTags
          showCreate
          size="small"
          options={tagsOption.map(
            (tag) =>
              ({
                value: tag?.value,
                label: tag?.label
              } as any)
          )}
          onCreate={onCreateTag}
          addButtonProps={{ children: 'Add Labels' }}
        />
      </Form.Item>
      {/* <Form.Item name={['data', 'segment']} label="Segment">
        <SelectSegment placeholder="Select segment" />
      </Form.Item>
      <Form.Item name={['data', 'period']}>
        <Select placeholder="Select period" />
      </Form.Item> */}
      <Button htmlType="submit" hidden />
    </Form>
  )
}
export const AnalyticTemplateForm = forwardRef(AnalyticTemplateFormWithoutRef)
