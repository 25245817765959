import { Table, TableColumnType } from 'antd'
import { WorkflowInsightNodes } from 'interfaces/workflow'
import { FC } from 'react'
import styled from 'styled-components'

const StyledNumber = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledTable = styled(Table)`
  .ant-table-thead {
    tr {
      th {
        border-top: 1px solid ${({ theme }) => theme?.colors?.neutral100};
        background-color: ${({ theme }) => theme?.colors?.neutral50};

        &:first-child {
          border-left: 1px solid ${({ theme }) => theme?.colors?.neutral100};
        }
        &:last-child {
          border-right: 1px solid ${({ theme }) => theme?.colors?.neutral100};
        }
      }
    }
  }
  .ant-table-tbody {
    tr {
      td {
        &:first-child {
          border-left: 1px solid ${({ theme }) => theme?.colors?.neutral100};
        }
        &:last-child {
          border-right: 1px solid ${({ theme }) => theme?.colors?.neutral100};
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 6px;
          }
          &:last-child {
            border-bottom-right-radius: 6px;
          }
        }
      }
    }
  }
`

const columns: TableColumnType<any>[] = [
  { dataIndex: 'label', title: 'Trigger', width: 90 },
  { dataIndex: 'type', title: 'Channel', width: 80 },
  {
    dataIndex: 'status',
    title: 'Status',
    width: 50,
    render(status) {
      return <></>
    }
  },
  {
    dataIndex: 'delivery_rate',
    title: 'Delivery Rate',
    width: 50,
    render(_, record: any) {
      if (
        typeof record?.data?.campaignInsightRate?.delivery_rate !== 'undefined'
      ) {
        return (
          <StyledNumber>
            {record.data.campaignInsightRate.delivery_rate}%
          </StyledNumber>
        )
      }
      return `N/A`
    }
  },
  {
    dataIndex: 'click_rate',
    title: 'Click Rate',
    width: 50,
    render(_, record: any) {
      if (
        typeof record?.data?.campaignInsightRate?.click_rate !== 'undefined'
      ) {
        return (
          <StyledNumber>
            {record.data.campaignInsightRate.click_rate}%
          </StyledNumber>
        )
      }
      return `N/A`
    }
  },
  {
    dataIndex: 'open_rate',
    title: 'Open Rate',
    width: 50,
    render(_, record: any) {
      if (typeof record?.data?.campaignInsightRate?.open_rate !== 'undefined') {
        return (
          <StyledNumber>
            {record.data.campaignInsightRate.open_rate}%
          </StyledNumber>
        )
      }
      return `N/A`
    }
  },
  {
    dataIndex: 'impression',
    title: 'Impression',
    width: 50,
    render(_, record: any) {
      if (
        typeof record?.data?.campaignInsightRate?.impression_rate !==
        'undefined'
      ) {
        return (
          <StyledNumber>
            {record.data.campaignInsightRate.impression_rate}%
          </StyledNumber>
        )
      }
      return `N/A`
    }
  },
  {
    dataIndex: 'ctr',
    title: 'CTR',
    width: 50,
    render(_, record: any) {
      if (
        typeof record?.data?.campaignInsightRate?.click_through_rate !==
        'undefined'
      ) {
        return (
          <StyledNumber>
            {record.data.campaignInsightRate.click_through_rate}%
          </StyledNumber>
        )
      }
      return `N/A`
    }
  },
  {
    dataIndex: 'ctor',
    title: 'CTOR',
    width: 50,
    render(_, record: any) {
      if (typeof record?.data?.campaignInsightRate?.ctor !== 'undefined') {
        return (
          <StyledNumber>{record.data.campaignInsightRate.ctor}%</StyledNumber>
        )
      }
      return `N/A`
    }
  }
]

export type InsightOverviewTableProps = {
  dataSource: WorkflowInsightNodes
}
export const InsightOverviewTable: FC<InsightOverviewTableProps> = (props) => {
  const { dataSource } = props

  return (
    <StyledTable
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowKey={({ id }: any) => id}
    />
  )
}
