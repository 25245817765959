import { useEffect, useState } from 'react'
import styled from 'styled-components'

const pattern = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'b',
  'a'
]

const KhabyImg = styled.img`
  position: absolute;
  z-index: 1000;
  right: 0;
  bottom: 0;
`

export function Easter() {
  const [current, setCurrent] = useState(0)
  useEffect(() => {
    const handler = (event: any) => {
      if (event.key === pattern[current]) {
        setCurrent((c) => c + 1)
      } else if (current < pattern.length) {
        if (event.key === pattern[0]) {
          setCurrent(1)
        } else {
          setCurrent(0)
        }
      }
    }
    document.addEventListener('keydown', handler)
    return () => document.removeEventListener('keydown', handler)
  }, [current, setCurrent])

  if (current !== pattern.length) {
    return null
  }

  return <KhabyImg src="/khaby.png" onClick={() => setCurrent(0)} />
}
