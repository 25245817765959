import { SegmentQueryParams } from 'interfaces/segment'
import { segmentServices } from 'services/segment'

export const fetchSegmentList = async (params: SegmentQueryParams) => {
  try {
    const response = await segmentServices.getSegmentList(params)
    if (response._message || !response.data) {
      throw new Error(JSON.stringify(response))
    }
    return { data: response.data?.data, meta: response.data?.meta }
  } catch (error) {
    console.log('** ERROR SelectSegment.helpers.ts : ', error)
    return { data: [], meta: { total_page: 0 } }
  }
}
