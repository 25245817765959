import { Button, Typography } from 'antd'
import styled from 'styled-components'

// Header
export const StyledOverviewButton = styled(Button)`
  &.filterBtn {
    padding-inline: 12px;
    border-color: ${({ theme }) => theme?.colors?.neutral200};
    background-color: transparent;

    &:hover {
      border-color: inherit;
    }
  }
  &.downloadBtn {
    padding-inline: 0;
    color: ${({ theme }) => theme?.colors?.gray800};
    &:hover {
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }
`

export const StyledOverviewTitle = styled.div`
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 22px;
  font-weight: 600;
`

export const StyledOverviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
`

// Box
export const StyledOverviewBoxTypo = styled(Typography)`
  &.title {
    margin: 0;
    color: ${({ theme }) => theme?.colors?.gray800};
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.5px;
  }
`

export const StyledOverviewBoxWrapper = styled.div`
  & + & {
    margin-top: 40px;
  }
`

export const StyledOverviewBoxHeader = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const StyledOverviewBox = styled.div`
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 8px;

  & + & {
    margin-top: 24px;
  }
`
