import {
  WORKFLOW_TABLE_STATUS_OPTIONS,
  WORKFLOW_TABLE_TABS_KEYS,
  WorkflowTableState,
  initialWorkflowTableState
} from 'components/molecules/workflow'
import { isProduction } from 'constants/env'
import { OptionTypeSite } from 'interfaces'
import Cookies from 'js-cookie'
import { WorkflowFolderServices, WorkflowServices } from 'services/workflow'
import { convertQueryParams } from 'utils'

export const COOKIES_WORKFLOW_TABLE = isProduction ? 'wf_tb_ck' : '_wf_tb_ck'
export type WorkflowTableInput = Omit<WorkflowTableState, 'status'> & {
  status?: string
}
export type WorkflowTableOutput = {
  data: any[]
  meta: Pick<WorkflowTableState, 'page' | 'per_page' | 'total'>
  errors: { errors: string[]; name?: string }[]
}
export const getWorkflow = async (
  input: WorkflowTableInput
): Promise<WorkflowTableOutput> => {
  try {
    const response: any = await WorkflowServices.getList(input)
    if (!response.data || response._message) {
      throw new Error(JSON.stringify(response))
    }
    const { data, meta } = response.data
    return {
      data: data || [],
      meta: {
        page: meta?.current_page || input.page,
        per_page: meta?.per_page || input.per_page,
        total: meta?.total_items || 0
      },
      errors: []
    }
  } catch (error) {
    return {
      data: [],
      meta: { page: input.page, per_page: input.per_page },
      errors: [{ errors: ['Something went wrong'] }]
    }
  }
}
export const getWorkflowFolder = async (
  input: WorkflowTableInput
): Promise<WorkflowTableOutput> => {
  try {
    const response: any = await WorkflowFolderServices.getList(input)
    if (!response.data || response._message) {
      throw new Error(JSON.stringify(response))
    }
    const { data, meta } = response.data
    return {
      data: data || [],
      meta: {
        page: meta?.current_page || input.page,
        per_page: meta?.per_page || input.per_page,
        total: meta?.total_items || 0
      },
      errors: []
    }
  } catch (error) {
    return {
      data: [],
      meta: { page: input.page, per_page: input.per_page },
      errors: [{ errors: ['Somethings went wrong'] }]
    }
  }
}

export const getWorkflowTable = async (
  input: WorkflowTableState
): Promise<WorkflowTableOutput> => {
  const newInput: WorkflowTableInput = {
    page: input.page,
    per_page: input.per_page,
    created_from: input.created_from,
    created_to: input.created_to,
    search: input.search,
    sort_by: input.sort_by,
    sort_type: input.sort_type
  }

  Object.keys(newInput).forEach((k) => {
    const key = k as keyof typeof newInput
    const value = newInput[key]
    if (!value || (Array.isArray(value) && !value.length)) {
      delete newInput[key]
    }
  })

  if (input?.status && input?.status?.length) {
    newInput.status = input.status
      .filter((status) => {
        // Validate status
        return WORKFLOW_TABLE_STATUS_OPTIONS.some(
          ({ value }) => value === status
        )
      })
      ?.join(',')
  }
  if (input.type === WORKFLOW_TABLE_TABS_KEYS.WORKFLOW) {
    return await getWorkflow(newInput)
  }

  if (input.type === WORKFLOW_TABLE_TABS_KEYS.FOLDER) {
    return await getWorkflowFolder(newInput)
  }

  return {
    data: [],
    meta: { page: input.page, per_page: input.per_page, total: 0 },
    errors: [{ errors: ['Somethings went wrong'] }]
  }
}

export const saveWorkflowTableToQueryString = (input: WorkflowTableState) => {
  const { total, ...restInput } = input
  Object.keys(restInput).forEach((k) => {
    const key = k as keyof typeof restInput
    const value = restInput[key]
    if (!value || (Array.isArray(value) && !value.length)) {
      delete restInput[key]
    }
  })
  Cookies.set(COOKIES_WORKFLOW_TABLE, JSON.stringify(restInput))
  return convertQueryParams(restInput)
}

export const getWorkflowTableFromQueryString = (
  searchParams: URLSearchParams
): WorkflowTableState => {
  let state: WorkflowTableState = { ...initialWorkflowTableState }
  try {
    const cookieState = JSON.parse(Cookies.get(COOKIES_WORKFLOW_TABLE) || '{}')
    state = { ...state, ...cookieState }
  } catch (error) {
    console.log('** RYAN WorkflowTablePage.helpers.tsx 125 error : ', error)
  }
  if (searchParams.get('page')) {
    state.page =
      parseInt(searchParams.get('page') || '0') ||
      initialWorkflowTableState.page
  }
  if (searchParams.get('per_page')) {
    state.per_page =
      parseInt(searchParams.get('per_page') || '0') ||
      initialWorkflowTableState.per_page
  }
  if (searchParams.get('search')) {
    state.search = searchParams.get('search') || state.search
  }
  if (searchParams.get('type')) {
    state.type = searchParams.get('type') || state.type
  }
  if (searchParams.get('sort_by')) {
    state.sort_by = searchParams.get('sort_by') || state.sort_by
  }
  if (searchParams.get('sort_type')) {
    state.sort_type = searchParams.get('sort_type') || state.sort_type
  }
  if (searchParams.getAll('status')?.length) {
    state.status = searchParams.getAll('status') || state.status
  }

  return state
}

export function transferWorkflowTableDataSource({
  data,
  sites
}: {
  data: any[]
  sites: OptionTypeSite[]
}): any[] {
  return data?.map((d) => {
    const sitesD = sites.filter((site) =>
      d.site?.some((s: any) => s.id === site.value)
    )
    return {
      ...d,
      sites: sitesD
    }
  })
}
