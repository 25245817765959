import { SegmentConditionValueProps } from './SegmentConditionValue'
import { StyledSegmentConditionValueNumber } from './SegmentConditionValue.styled'
import { ConditionNumberBetween } from 'components/condition/base/conditionNumberBetween'
import { SEGMENT_CONDITION_OPERATOR } from 'constants/segment'
import { useEffect, useState } from 'react'

type NumberValue = number | [number, number] | null | undefined
const formatSegmentConditionValueNUMBER = (
  value?: NumberValue,
  operator?: string | number
): NumberValue => {
  if (operator === SEGMENT_CONDITION_OPERATOR.BETWEEN) {
    if (Array.isArray(value) && value.every((n) => typeof n === 'number')) {
      return value
    } else {
      return null
    }
  }

  return typeof value === 'number' ? value : null
}

export type SegmentConditionValueNUMBERProps = Omit<
  SegmentConditionValueProps<NumberValue>,
  'type'
>
export const SegmentConditionValueNUMBER = (
  props: SegmentConditionValueNUMBERProps
) => {
  const {
    size,
    operator,
    defaultValue,
    value: valueProps,
    onChange,
    disabled
  } = props
  const [value, setValue] = useState<NumberValue>(
    formatSegmentConditionValueNUMBER(defaultValue || valueProps, operator)
  )

  const handleChange = (val: string | number | [number, number] | null) => {
    const newValue = typeof val === 'string' ? parseInt(val) : val
    setValue(newValue)
    onChange?.(newValue)
  }

  useEffect(() => {
    setValue(formatSegmentConditionValueNUMBER(valueProps, operator))
  }, [operator, valueProps])

  if (operator === SEGMENT_CONDITION_OPERATOR.BETWEEN) {
    return (
      <ConditionNumberBetween
        min={0}
        size={size}
        defaultValue={Array.isArray(defaultValue) ? defaultValue : null}
        value={Array.isArray(value) ? value : null}
        onChange={handleChange}
        containerClassName="segmentConditionValueNUMBER-container"
        disabled={disabled}
      />
    )
  }

  return (
    <StyledSegmentConditionValueNumber
      size={size}
      defaultValue={typeof defaultValue === 'number' ? defaultValue : undefined}
      value={typeof value === 'number' ? value : undefined}
      onChange={handleChange}
      placeholder="Value"
      disabled={disabled}
    />
  )
}
