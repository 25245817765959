import { PreviewMessageProps } from '../..'
import {
  StyleBrowserContent,
  StyleBrowserWrapper,
  StyleEmailText,
  StyleMessage,
  StyleMessageHeader,
  StyleTitle
} from '../../index.styled'
import { HhgIcon, MessageBrowser, MessageBrowserLarge } from 'icons'
import { FC } from 'react'

const PreviewWindow: FC<PreviewMessageProps> = ({
  preview,
  collapsed,
  domain
}) => {
  const renderImageExpand = () => {
    if (preview.image) {
      return (
        <div className="wrapper-image-icon expand-image">
          <img src={preview.image} alt="icon" className="image-icon" />
        </div>
      )
    }
    return <MessageBrowserLarge className="message-icon-large" />
  }

  return (
    <StyleBrowserWrapper>
      {collapsed === 'expand' && renderImageExpand()}

      <StyleMessageHeader>
        <HhgIcon className="chrome-window" />
        <div className="title">Google Chrome</div>
      </StyleMessageHeader>
      <StyleBrowserContent>
        {preview.image ? (
          <div className="wrapper-image-icon wrapper-image-icon--window">
            <img src={preview.image} alt="icon" className="image-icon" />
          </div>
        ) : (
          <MessageBrowser className="chrome-window-message" />
        )}
        <div className="message-infor">
          <StyleTitle>{preview.title}</StyleTitle>
          <StyleMessage>
            {preview.description
              .split('\n')
              .map((strMessage: string, idx: number) => {
                return <span key={idx}>{strMessage}</span>
              })}
          </StyleMessage>
          <StyleEmailText>{domain}</StyleEmailText>
        </div>
      </StyleBrowserContent>
    </StyleBrowserWrapper>
  )
}

export default PreviewWindow
