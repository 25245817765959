import { convertDayToSecondsTime } from './CampaignFormAutomation.helpers'
import {
  StyleCard,
  StyleCardTitle,
  StyleCol,
  StyleRow,
  StyleWrapperQuery
} from './CampaignFormAutomation.styled'
import TemplateListForm from './TemplateListForm/TemplateListForm'
import {
  Button,
  CheckboxOptionType,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  TimePicker
} from 'antd'
import { CheckboxButton } from 'components/atoms/checkbox'
import { DatePicker } from 'components/atoms/datePicker'
import { optionsTimezone } from 'components/atoms/select'
import {
  FormNamePath,
  getFormNamePath
} from 'components/condition/conditions/form/index.helpers'
import ConditionListGroups from 'components/condition/conditions/groupLists'
import { HandleRef } from 'components/molecules/form/formSettingEmail'
import { CAMPAIGN_WEEKLY_OPTIONS, KEY_SITES_LIST } from 'constants/campaign'
import { TIME_ONE_DAY, TIME_SECOND_ONE_DAY } from 'constants/common'
import AppContext, { AppContextProps } from 'context/App'
import dayjs from 'dayjs'
import { OptionTypeSite } from 'interfaces'
import {
  ForwardRefRenderFunction,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import { formatNumberToCurrency } from 'utils'

interface AutoCampaignProps {
  settingValue?: any
  loadingQuery: boolean
  numberUsers: number
  handleRunQuery: () => void
  setSettingValue?: (val: any) => void
}

const AutoCampaignCreateFormWithoutRef: ForwardRefRenderFunction<
  HandleRef,
  AutoCampaignProps
> = (
  { settingValue, loadingQuery, numberUsers, handleRunQuery, setSettingValue },
  ref
) => {
  const contextApp = useContext<AppContextProps>(AppContext)
  const [form] = Form.useForm()
  const [optionSites, setOptionSites] = useState<CheckboxOptionType[]>([])
  const [dayOfWeek, setDayOfWeek] = useState<number[]>([])
  const { sites } = contextApp
  const fieldListGroups = Form.useWatch(getFormNamePath('groups'), form)

  const onValuesChangeField = (name: FormNamePath) => {
    return (value: any) => {
      form.setFieldValue(name, value)
      setSettingValue && setSettingValue(form.getFieldsValue())
    }
  }

  useImperativeHandle(ref, () => {
    return {
      form
    }
  })

  const handleOnFieldsChange = (changedFields: any, _allFields: any) => {
    if (changedFields && changedFields.length) {
      const field = changedFields[0]
      const key = field?.name[0] || ''
      const value = field.value
      if (key === 'start_time') {
        if (
          settingValue.end_time &&
          dayjs(value) > dayjs(settingValue.end_time)
        ) {
          form.setFieldValue('end_time', null)
        }
      }
      if (key === 'recurring') {
        const { templates } = settingValue
        if (!value) {
          form.setFieldsValue({ templates: [templates[0]] })
          setSettingValue &&
            setSettingValue((pre: any) => {
              return {
                ...pre,
                templates: [templates[0]]
              }
            })
        }
      }
      if (key === 'sites') {
        let newTemplate = []
        if (settingValue.recurring) {
          newTemplate = settingValue.templates.map(() => {
            return {
              template: null
            }
          })
        } else {
          newTemplate.push({
            template: null
          })
        }
        form.setFieldsValue({
          templates: newTemplate
        })
      }
      if (!['templates', 'conditions'].includes(key)) {
        setSettingValue &&
          setSettingValue((pre: any) => {
            return {
              ...pre,
              [key]: value
            }
          })
      }
    }
  }

  const isEnableRunQuery = useMemo(() => {
    if (!fieldListGroups || !fieldListGroups.length) {
      return true
    }
    return false
  }, [fieldListGroups])

  useEffect(() => {
    if (settingValue.start_date && settingValue.end_date) {
      const startDateTime = convertDayToSecondsTime(
        dayjs(settingValue.start_date).startOf('day')
      )
      const endDateTime = convertDayToSecondsTime(
        dayjs(settingValue.end_date).endOf('day')
      )
      const numberRangeDay = Math.round(
        (endDateTime - startDateTime) / TIME_SECOND_ONE_DAY
      )
      const listDay: number[] = []
      for (let i = 0; i < numberRangeDay; i++) {
        const nextDateTime = startDateTime * 1000 + i * TIME_ONE_DAY
        const nextDay = dayjs(nextDateTime)
        const nextDayInWeek = nextDay.day()
        if (!listDay.includes(nextDayInWeek)) {
          listDay.push(nextDayInWeek)
        }
      }
      setDayOfWeek(listDay)
    } else {
      setDayOfWeek([])
    }
  }, [settingValue.start_date, settingValue.end_date])

  const optionDayInWeekly = useMemo(() => {
    return CAMPAIGN_WEEKLY_OPTIONS.map((day) => {
      return {
        ...day,
        disabled: !dayOfWeek.includes(day.key)
      }
    })
  }, [dayOfWeek])

  useEffect(() => {
    if (sites.length) {
      const newSites = [] as any
      KEY_SITES_LIST.forEach((item) => {
        const resultFind = sites.find((site: OptionTypeSite) => {
          return site.domain === item.key
        })
        if (resultFind) {
          newSites.push({
            label: resultFind.label,
            value: resultFind.value,
            fromEmail: item.fromEmail,
            key: resultFind.domain
          })
        }
      })
      setOptionSites(newSites)
    }
  }, [sites])
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={settingValue}
      onFieldsChange={handleOnFieldsChange}>
      <StyleCard>
        <StyleCardTitle>Campaign</StyleCardTitle>
        <Form.Item
          label="Campaign name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Input campaign name'
            },
            {
              whitespace: true,
              message: 'Please input campaign name with characters'
            },
            {
              max: 255,
              message: 'Maximum 255 characters for name'
            }
          ]}>
          <Input placeholder="Campaign name" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea
            autoSize={{
              minRows: 2,
              maxRows: 2
            }}
            maxLength={500}
            showCount
            placeholder="Description"
          />
        </Form.Item>
        <StyleRow>
          <StyleCol span={12}>
            <Form.Item
              label="Timezone"
              name="time_zone"
              rules={[
                {
                  required: true,
                  message: 'Select timezone'
                }
              ]}>
              <Select options={optionsTimezone} placeholder="Time zone" />
            </Form.Item>
          </StyleCol>
        </StyleRow>
        <StyleRow>
          <StyleCol span={12}>
            <Form.Item
              label="Sites"
              name="sites"
              rules={[
                {
                  required: true,
                  message: 'Select sites'
                }
              ]}>
              <Select options={optionSites} placeholder="Sites" />
            </Form.Item>
          </StyleCol>
        </StyleRow>
        <StyleRow className="row-adjust-item">
          <StyleCol span={6}>
            <Form.Item
              label="Timeframe"
              name="start_date"
              rules={[
                {
                  required: true,
                  message: 'Choice start date'
                }
              ]}>
              <DatePicker
                placeholder="Start date"
                disabledDate={(current) => {
                  return (
                    current &&
                    (current > dayjs(settingValue.end_date) ||
                      current < dayjs().startOf('day'))
                  )
                }}
              />
            </Form.Item>
          </StyleCol>
          <StyleCol span={6} className="col-visible">
            <Form.Item
              label="Start time"
              name="start_time"
              rules={[
                {
                  required: true,
                  message: 'Choice start time'
                }
              ]}>
              <TimePicker placeholder="Start time" />
            </Form.Item>
          </StyleCol>
        </StyleRow>
        {settingValue.recurring && (
          <StyleRow className="row-adjust-item">
            <StyleCol span={6}>
              <Form.Item
                label=""
                name="end_date"
                rules={[
                  {
                    required: true,
                    message: 'Choice end date'
                  }
                ]}>
                <DatePicker
                  placeholder="End date"
                  disabledDate={(current) => {
                    return current && current < dayjs(settingValue.start_date)
                  }}
                />
              </Form.Item>
            </StyleCol>
            <StyleCol span={6}>
              <Form.Item
                label=""
                name="end_time"
                rules={[
                  {
                    required: true,
                    message: 'Choice end time'
                  }
                ]}>
                <TimePicker
                  placeholder="End time"
                  disabledTime={() => {
                    return {
                      // Disable end time hour on timepicker
                      disabledHours: () => {
                        const startHour = dayjs(settingValue.start_time).hour()
                        const selectHours = []
                        // Loop 24h of one day
                        for (let i = 0; i < 24; i++) {
                          // Disable end hour less start hour
                          if (i < startHour) {
                            selectHours.push(i)
                          }
                        }
                        return selectHours
                      },
                      // Disable end time minutes on timepicker
                      disabledMinutes: (selectedHour: number) => {
                        const startHour = dayjs(settingValue.start_time).hour()
                        const startMinutes = dayjs(
                          settingValue.start_time
                        ).minute()
                        const selectMinutes = []
                        // Loop 60p of one hour
                        for (let i = 0; i < 60; i++) {
                          // Disable end minute less start minute
                          if (i < startMinutes && selectedHour === startHour) {
                            selectMinutes.push(i)
                          }
                        }
                        return selectMinutes
                      },
                      // Disable end time second on timepicker
                      disabledSeconds: (
                        selectedHour: number,
                        selectedMinute: number
                      ) => {
                        const startHour = dayjs(settingValue.start_time).hour()
                        const startMinutes = dayjs(
                          settingValue.start_time
                        ).minute()
                        const startSeconds = dayjs(
                          settingValue.start_time
                        ).second()
                        const selectSeconds = []
                        // Loop 60s of one minute
                        for (let i = 0; i < 60; i++) {
                          // Disable end second less start second
                          if (
                            i < startSeconds &&
                            selectedHour === startHour &&
                            selectedMinute === startMinutes
                          ) {
                            selectSeconds.push(i)
                          }
                        }
                        return selectSeconds
                      }
                    }
                  }}
                />
              </Form.Item>
            </StyleCol>
          </StyleRow>
        )}
        <StyleRow>
          <StyleCol span={12} className="col-recurring">
            <Form.Item
              label="Recurring"
              name="recurring"
              rules={[
                {
                  required: true,
                  message: 'Please choose timezone'
                }
              ]}>
              <Switch checked={settingValue.recurring} />
            </Form.Item>
          </StyleCol>
        </StyleRow>
        {settingValue.recurring && (
          <>
            <StyleRow>
              <StyleCol span={24}>
                <Form.Item
                  label="Replication"
                  name="replication"
                  rules={[
                    {
                      required: true,
                      message: 'Please choose replication'
                    }
                  ]}>
                  <Radio.Group>
                    <Radio.Button value="Daily">Daily</Radio.Button>
                    <Radio.Button value="Weekly">Weekly</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </StyleCol>
            </StyleRow>
            {settingValue.replication === 'Weekly' && (
              <>
                <StyleRow>
                  <StyleCol span={24}>
                    <Form.Item
                      label="Schedule on"
                      name="schedule_on"
                      rules={[
                        {
                          required: true,
                          message: 'Please choose schedule on'
                        }
                      ]}>
                      <CheckboxButton
                        options={optionDayInWeekly}
                        disabled={false}
                      />
                    </Form.Item>
                  </StyleCol>
                </StyleRow>
              </>
            )}
            <StyleRow>
              <StyleCol span={6}>
                <Form.Item
                  label="Trigger"
                  name="trigger_time"
                  rules={[
                    {
                      required: true,
                      message: 'Please input trigger time'
                    }
                  ]}>
                  <TimePicker placeholder="Trigger time" />
                </Form.Item>
              </StyleCol>
            </StyleRow>
          </>
        )}
      </StyleCard>
      <StyleCard>
        <StyleCardTitle>Target Audience</StyleCardTitle>
        <ConditionListGroups
          onChangeField={onValuesChangeField}
          settingValue={settingValue}
        />
        <StyleWrapperQuery>
          <Button
            type="primary"
            size="small"
            disabled={isEnableRunQuery}
            onClick={handleRunQuery}
            loading={loadingQuery}>
            Run query
          </Button>
          <div className="user">
            <strong>{formatNumberToCurrency(numberUsers)}</strong> users
          </div>
        </StyleWrapperQuery>
      </StyleCard>
      <StyleCard>
        <StyleCardTitle>Template</StyleCardTitle>
        <TemplateListForm
          onChangeField={onValuesChangeField}
          sites={optionSites}
        />
      </StyleCard>
    </Form>
  )
}

export const CampaignFormAutomation = forwardRef(
  AutoCampaignCreateFormWithoutRef
)
