import styled from 'styled-components'

export const StyledWrap = styled.div`
  width: 405px;
  margin: 0 auto;
  text-align: center;

  img {
    display: block;
  }
`

export const StyledActions = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  margin-top: 24px;

  > li {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.colors.gray200};

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }

    svg {
      display: block;
    }

    &.active {
      color: #fff;
      border-color: ${(props) => props.theme.colors.primary};
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
`

export const StyledWrapImage = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`

export const StyledPreviewBox = styled.div`
  position: absolute;
  top: 53%;
  width: 320px;
  z-index: 1;
  border-radius: 8px;
  text-align: left;
  padding: 16px;
  background: ${(props) => props.theme.colors.neutral50};
  left: 50%;
  transform: translateX(-50%);

  .wrapper-preview {
    display: flex;
    gap: 10px;
    .app-infor {
      flex: 1;
    }
    .logo-app {
      width: 40px;
    }
    .time {
      margin-bottom: 5px;
      font-size: 12px;
      line-height: 16px;
      color: ${(props) => props.theme.colors.gray600};
      text-align: right;
    }
    .description {
      font-size: 12px;
      line-height: 18px;
      color: ${(props) => props.theme.colors.gray800};
      margin-bottom: 0;
      word-break: break-all;
    }
    .title {
      font-size: 12px;
      font-weight: 600;
      color: ${(props) => props.theme.colors.gray800};
      line-height: 18px;
      margin-bottom: 0;
      word-break: break-all;
    }
    .wrap-image {
      width: 32px;
      height: 32px;

      img {
        width: 100%;
        height: 32px;
        border-radius: 6px;
      }
    }
  }
`
