import { DictionaryTableState } from 'components/molecules/dictionary'
import { FieldServices } from 'services/field'
import { FieldAttributeServices } from 'services/fieldAttribute'

export type DictionaryMeta = {
  page: number
  perPage: number
  total: number
}
export type DictionaryReturn = {
  data: any[] | null
  meta: DictionaryMeta
}

export async function getDictionariesATTRIBUTE(params: DictionaryTableState) {
  try {
    const response: any = await FieldAttributeServices.getListFields({
      page: params.pagination.current,
      perPage: params.pagination.pageSize,
      search: params.search,
      sort_by: params?.sort_by,
      sort_type: params?.sort_type,
      filter_data_type: params.filter_data_type
    })
    if (response?._message || !response.data.data) {
      throw new Error(JSON.stringify(response))
    }

    const {
      data,
      page = params.pagination.current || 1,
      perPage = params.pagination.pageSize || 10,
      totalPage = 1
    } = response.data
    return {
      data,
      meta: {
        total: totalPage * perPage || params.pagination.total || 0,
        page,
        perPage
      }
    }
  } catch (error) {
    return {
      data: null,
      meta: {
        total: params.pagination.total || 0,
        page: params.pagination.current || 1,
        perPage: params.pagination.pageSize || 10
      }
    }
  }
}
export async function getDictionariesEVENT(params: DictionaryTableState) {
  try {
    const response: any = await FieldServices.getFieldList({
      page: params.pagination.current || 1,
      per_page: params.pagination.pageSize || 10,
      filter_data_type: params.filter_data_type as string[],
      category: params.dictionary_type,
      search: params.search,
      sort_type: params.sort_type,
      sort_by: params.sort_by
    })
    if (response?._message || !response.data.data) {
      throw new Error(JSON.stringify(response))
    }

    const { data } = response
    return {
      data: data.data,
      meta: {
        total: data.meta?.total_items || params.pagination.total || 0,
        page: data.meta?.current_page || params.pagination.current || 1,
        perPage: data.meta?.per_page || params.pagination.pageSize || 20
      }
    }
  } catch (error) {
    return {
      data: null,
      meta: {
        total: params.pagination.total || 0,
        page: params.pagination.current || 1,
        perPage: params.pagination.pageSize || 20
      }
    }
  }
}
export async function getDictionaries(
  params: DictionaryTableState
): Promise<DictionaryReturn> {
  const newParams = {
    page: params.pagination.current,
    per_page: params.pagination.pageSize,
    filter_data_type: params.filter_data_type,
    filter_status: params.filter_status,
    category: params.dictionary_type,
    search: params.search,
    sort_type: params.sort_type,
    sort_by: params.sort_by
  }
  Object.keys(newParams).forEach((key) => {
    const value = newParams?.[key as keyof typeof newParams]
    if (
      typeof value === 'undefined' ||
      !value ||
      (Array.isArray(value) && !value?.length)
    ) {
      delete newParams[key as keyof typeof newParams]
    }
  })
  try {
    const response: any = await FieldServices.getFieldList(newParams)
    if (response?._message || !response.data.data) {
      throw new Error(JSON.stringify(response))
    }

    const { data } = response
    return {
      data: data.data,
      meta: {
        total: data.meta?.total_items || params.pagination.total || 0,
        page: data.meta?.current_page || params.pagination.current || 1,
        perPage: data.meta?.per_page || params.pagination.pageSize || 20
      }
    }
  } catch (error) {
    return {
      data: null,
      meta: {
        total: params.pagination.total || 0,
        page: params.pagination.current || 1,
        perPage: params.pagination.pageSize || 20
      }
    }
  }
}

export async function deleteDictionary(id?: number) {
  if (!id) {
    return { isDelete: false, errors: [{ errors: ['Field is NOT valid'] }] }
  }
  try {
    const response: any = await FieldServices.deleteField(id)

    return {
      isDelete: Boolean(response?._statusRes === 200),
      errors: [{ errors: ['Something went wrong'] }]
    }
  } catch (error) {
    return { isDelete: false, errors: [{ errors: ['Something went wrong'] }] }
  }
}

export * from './DictionaryPage'
