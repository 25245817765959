import { SegmentAnalytic } from '../SegmentAnalytic'
import { SegmentFormValue } from '../SegmentForm'
import { SEGMENT_STATUS_KEYS } from '../SegmentStatus'
import { StyledSegmentAnalyticContainer } from './SegmentAnalyticDrawer.styled'
import Icon from '@ant-design/icons'
import { Button } from 'antd'
import cn from 'classnames'
import { ModalEmpty } from 'components/atoms/modal'
import { ANALYTIC_TEMPLATE_PERIOD_OPTIONS } from 'components/organisms/analytic/AnalyticTemplateDetail/AnalyticTemplateDetail.helpers'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import { PERMISSIONS } from 'constants/permission'
import { ROLES } from 'constants/role'
import { SEGMENT_TYPE } from 'constants/segment'
import dayjs from 'dayjs'
import { useCheckAuth } from 'hooks/useCheckAuth'
import { Analytics, Close, Collapse, Expand } from 'icons'
import { useCallback, useMemo, useState } from 'react'

export type SegmentAnalyticDrawerProps = {
  loading?: boolean
  segmentName?: string
  segmentId?: string | number
  segmentType?: string
  segmentStatus?: number
  segmentSitesID?: { id: number; name: string }[]
  onSaveSegment?: (value: Partial<SegmentFormValue>) => Promise<boolean>
}
export const SegmentAnalyticDrawer = (props: SegmentAnalyticDrawerProps) => {
  const {
    loading,
    segmentName,
    segmentId,
    segmentType,
    segmentStatus,
    segmentSitesID,
    onSaveSegment: onSave
  } = props
  const [full, setFull] = useState(false)
  const [status, setStatus] = useState('')
  const [drawer, setDrawer] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [create, setCreate] = useState(false)

  const canCreate = !useCheckAuth({
    permissions: [PERMISSIONS.analytics_cr_view],
    roles: [ROLES.admin, ROLES.campaign_manager]
  })

  const showPreviewAnalytics = useMemo(() => {
    return !!segmentId && segmentType === SEGMENT_TYPE.ATTRIBUTE
  }, [segmentId, segmentType])

  const onCancel = useCallback(() => {
    if (loading) {
      return
    }
    setConfirm(false)
  }, [loading])
  const onClickPreview = useCallback(() => {
    if (segmentStatus === SEGMENT_STATUS_KEYS.DRAFT) {
      setConfirm(true)
      return
    }
    setDrawer(true)
  }, [segmentStatus])

  const onCancelConfirm = async () => {
    setStatus('publish')
    try {
      const saved = await onSave?.({
        id: segmentId,
        status: SEGMENT_STATUS_KEYS.PUBLIC
      })
      if (saved) {
        setConfirm(false)
        setDrawer(true)
      }
    } catch (error) {}
  }

  const onOkConfirm = async () => {
    setStatus('draft')
    try {
      const saved = await onSave?.({
        id: segmentId,
        status: SEGMENT_STATUS_KEYS.DRAFT
      })
      if (saved) {
        setConfirm(false)
      }
    } catch (error) {
    } finally {
      setConfirm(false)
    }
  }

  return (
    <>
      {showPreviewAnalytics && (
        <Button
          type="primary"
          icon={<Icon component={Analytics} />}
          onClick={onClickPreview}>
          Preview Analytics
        </Button>
      )}
      <ModalEmpty
        width={444}
        open={confirm}
        title="Preview Analytics"
        cancelText="Save & Publish Segment"
        cancelButtonProps={{
          onClick: onCancelConfirm,
          disabled: status === 'draft' && loading,
          loading: status === 'publish' && loading
        }}
        onCancel={onCancel}
        okText="Save Segment"
        okButtonProps={{
          disabled: status === 'publish' && loading,
          loading: status === 'draft' && loading
        }}
        onOk={onOkConfirm}>
        You can just Save or Save & Publish Segment to be able to Preview
        Analytics.
      </ModalEmpty>
      <StyledSegmentAnalyticContainer
        closable={false}
        open={drawer}
        onClose={() => setDrawer(false)}
        title={<h3 className="m-0 text-base font-bold">Preview Analytics</h3>}
        extra={
          <div className="flex gap-4 items-center">
            <Button
              type="primary"
              className="mr-2"
              disabled={canCreate}
              onClick={() => setCreate(true)}>
              Save Analytics
            </Button>
            <Button
              type="link"
              icon={<Icon component={full ? Collapse : Expand} />}
              onClick={() => setFull(!full)}
              className="!w-5 !h-5 !p-0 text-gray600"
            />
            <Button
              type="link"
              icon={<Icon component={Close} />}
              onClick={() => setDrawer(false)}
              className="!w-5 !h-5 !p-0 text-gray600"
            />
          </div>
        }
        width={full ? '100%' : 900}
        className={cn({ full })}>
        <SegmentAnalytic
          openCreate={create}
          onCancelCreate={() => setCreate(false)}
          isFull={full}
          segmentName={segmentName}
          segment_id={segmentId}
          segment_sites={segmentSitesID}
          segment_type={segmentType}
          start_date={dayjs().subtract(1, 'day').format(FORMAT_DATE_DAYJS_API)}
          end_date={dayjs().format(FORMAT_DATE_DAYJS_API)}
          period={ANALYTIC_TEMPLATE_PERIOD_OPTIONS[0].value}
        />
      </StyledSegmentAnalyticContainer>
    </>
  )
}
