import { Space, Tag } from 'antd'
import styled from 'styled-components'

export const StyledTag = styled(Tag)`
  border-color: transparent;
`

export const StyledTagsSpace = styled(Space)``

export const StyledTags = styled.div`
  &.tagGroup--overflow {
    overflow: hidden;
  }
`
