import Icon from '@ant-design/icons'
import { Empty } from 'antd'
import { UnderContruction } from 'icons'
import { FC } from 'react'

type Props = {
  description?: string
}

export const AnalyticWidgetItemUnderConstruction: FC<Props> = ({
  description
}) => {
  return (
    <Empty
      image={<Icon component={UnderContruction} className="text-[80px]" />}
      imageStyle={{ height: 'auto' }}
      description={description || 'This widget is under construction'}
      className="py-8 italic text-gray500 flex flex-col items-center justify-center h-full"
    />
  )
}
