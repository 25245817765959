import Icon from '@ant-design/icons'
import { Button, Collapse, Drawer, Skeleton } from 'antd'
import styled from 'styled-components'
import { cssLimitLines, cssScrollBar } from 'styles'

export const StyledCampaignDrawerIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  font-size: 20px;
  &.collapse--icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
`

export const StyledCampaignDrawerExtra = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`

export const StyledCampaignDrawerInfo = styled.span`
  color: ${({ theme }) => theme?.colors?.gray600};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  span {
    color: ${({ theme }) => theme?.colors?.gray800};
  }
`

export const StyledCampaignDrawerTitle = styled.h6`
  margin-bottom: 0;
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  width: 100%;
  ${cssLimitLines(1)}
`

export const StyledCampaignDrawerTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 16px;
  ${StyledCampaignDrawerInfo} {
    + ${StyledCampaignDrawerInfo} {
      margin-left: 16px;
    }
  }
`

export const StyledCampaignDrawerItemSkeleton = styled(Skeleton)``

export const StyledCampaignDrawerItem = styled.li`
  display: flex;
  padding-block: 7px;
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.neutral50};
  .label {
    display: block;
    flex: 1;
    max-width: 120px;
    padding-right: 16px;
    color: ${({ theme }) => theme?.colors?.gray600};
  }
  .content {
    flex: 1;
    min-width: 0;
    color: ${({ theme }) => theme?.colors?.gray800};
  }
`

export const StyledCampaignDrawerList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  ${StyledCampaignDrawerItem} {
    &:first-child {
      border-top: 1px solid ${({ theme }) => theme?.colors?.neutral50};
    }
  }
  ${StyledCampaignDrawerItemSkeleton} {
    + ${StyledCampaignDrawerItemSkeleton} {
      margin-top: 16px;
    }
  }
`

export const StyledCampaignDrawerButton = styled(Button)`
  &.preview {
    padding: 0;
    height: auto;
    font-size: 12px;
    line-height: 1;
    vertical-align: middle;
    ${StyledCampaignDrawerIcon} {
      width: 16px;
      height: 16px;
      font-size: 16px;
      + span {
        margin-left: 2px;
      }
    }
  }
`

export const StyledCampaignDrawerCollapse = styled(Collapse)`
  .ant-collapse-item {
    margin-top: 16px;
    font-size: 14px;
    line-height: 1.5;
    .ant-collapse-header {
      padding: 0;
      .collapse--icon {
        font-size: 24px;
        margin-right: 8px;
      }
      .ant-collapse-header-text {
        font-weight: 600;
      }
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;
        padding-left: 32px;
      }
    }
  }
`

export const StyledCampaignDrawer = styled(Drawer)`
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        padding: 16px;
        align-items: flex-start;
        .ant-drawer-close {
          display: none;
        }
      }
      .ant-drawer-body {
        padding: 16px;
        ${cssScrollBar}
        .drawer--performance {
          .perf--total {
            display: none;
          }
          .perf--legend {
            display: flex;
            flex-wrap: no-wrap;
            .perf--legend_item {
              flex: 1;
              width: 25%;
              min-width: 0;
              dislay: flex;
              flex-wrap: wrap;
              span {
                display: block;
                width: 100%;
              }
              &.perf--legend_item_number {
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
`
