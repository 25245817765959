import { StyledTag, StyledTags, StyledTagsSpace } from './TagGroup.styled'
import { TagProps, Tooltip } from 'antd'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getElementOuterWidth } from 'utils'

type AntTag = Pick<TagProps, 'color' | 'className' | 'style'> & {
  label: string
}
export type TagGroupProps = {
  tags?: AntTag[]
  maxTagsCount?: number | 'responsive'
}
export const TagGroup = (props: TagGroupProps) => {
  const $container = useRef<HTMLDivElement | null>(null)
  const { tags, maxTagsCount: maxTagsCountProps } = props
  const [maxTagsCount, setMaxTagsCount] = useState(() => {
    const isShowMaxTagsCount =
      typeof maxTagsCountProps === 'number' &&
      tags &&
      tags.length > maxTagsCountProps
    if (isShowMaxTagsCount) {
      return maxTagsCountProps
    }

    return tags?.length || 0
  })

  const isWrap =
    typeof maxTagsCountProps === 'number' ||
    typeof maxTagsCountProps === 'undefined'

  const tagsShow = useMemo(() => {
    return tags?.slice(0, maxTagsCount) || []
  }, [tags, maxTagsCount])

  const tagsHide = useMemo(() => {
    return tags?.slice(maxTagsCount) || []
  }, [tags, maxTagsCount])

  const tooltipOverlay = useMemo(() => {
    return <>{tagsHide.map(({ label }) => label).join(', ')}</>
  }, [tagsHide])

  const getMaxTagsCount = useCallback(() => {
    if (maxTagsCountProps === 'responsive') {
      if ($container.current) {
        let tmpWidth = 0
        const containerWidth = $container.current.clientWidth
        const $tags =
          $container.current.getElementsByClassName('tagGroup--item')

        for (let i = 0; i < $tags.length; i++) {
          const newMaxTagsCount = i - 2
          if (tmpWidth >= containerWidth) {
            setMaxTagsCount(newMaxTagsCount <= 0 ? 0 : newMaxTagsCount)
            break
          } else {
            const tagItemWidth = getElementOuterWidth($tags[i])
            tmpWidth += tagItemWidth
            if (tmpWidth >= containerWidth) {
              setMaxTagsCount(newMaxTagsCount <= 0 ? 0 : newMaxTagsCount)
              break
            }
          }
        }
      }
    }
  }, [maxTagsCountProps])

  useEffect(() => {
    getMaxTagsCount()
  }, [getMaxTagsCount])

  useEffect(() => {
    window.addEventListener('resize', getMaxTagsCount)
    return () => {
      window.removeEventListener('resize', getMaxTagsCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!tags || !tags?.length) {
    return null
  }

  return (
    <StyledTags
      ref={$container}
      className={classNames(!isWrap && 'tagGroup--overflow')}>
      <StyledTagsSpace size={[6, 6]} wrap={isWrap}>
        {tagsShow.map(({ label, color, className, style }, index) => {
          return (
            <StyledTag
              color={color}
              key={`${label}-${index}`}
              style={style}
              className={classNames('tagGroup--item', className)}>
              {label}
            </StyledTag>
          )
        })}
        {!!tagsHide?.length && (
          <Tooltip
            overlay={tooltipOverlay}
            overlayInnerStyle={{ padding: 8 }}
            placement="bottomRight">
            <StyledTag
              className={classNames(
                'tagGroup--item_more',
                tags?.[0]?.className
              )}
              style={tags?.[0]?.style}>
              +{tagsHide.length} ...
            </StyledTag>
          </Tooltip>
        )}
      </StyledTagsSpace>
    </StyledTags>
  )
}
