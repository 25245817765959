import { Drawer } from 'antd'
import styled from 'styled-components'
import { cssLimitLines, cssScrollBar } from 'styles'

export const StyledDrawerIframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
  flex: 1;
  min-height: 0;
  ${cssScrollBar}
`

export const StyledDrawerExtra = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  .anticon {
    font-size: 20px;
  }
`

export const StyledDrawer = styled(Drawer)`
  &.ant-drawer {
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          padding: 16px;
          .ant-drawer-header-title {
            .ant-drawer-close {
              display: none;
            }
            .ant-drawer-title {
              font-size: 16px;
              font-weight: 700;
              line-height: 1.5;
              ${cssLimitLines(1)}
              display: block;
            }
          }
        }
        .ant-drawer-body {
          padding: 16px;
          ${cssScrollBar}
        }
      }
    }
  }
  &.ld_page {
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-body {
          overflow: hidden !important;
        }
      }
    }
  }
`
