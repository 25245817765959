import message from 'antd/lib/message'
import { InsightByFilterParams } from 'interfaces/campaign'
import { CampaignServices } from 'services/campaign'

export const MESSAGING_PERFORMANCE_PER_PAGE = 20

export type GetCampaignMessagingInsightPerformanceOutput = {
  data: any[]
  meta: {
    total_items: number
    per_page: number
    current_page: number
    total_page?: number
  }
}
export const getCampaignMessagingInsightPerformance = async ({
  from = 0,
  to = 0,
  page = 1,
  per_page = MESSAGING_PERFORMANCE_PER_PAGE,
  list_campaign = []
} = {}): Promise<GetCampaignMessagingInsightPerformanceOutput> => {
  const outputDefault: GetCampaignMessagingInsightPerformanceOutput = {
    data: [],
    meta: {
      total_items: per_page,
      per_page: per_page,
      current_page: page
    }
  }
  try {
    if (!from || !to) {
      message.warning('Please select date filter for messaging insights')
      return outputDefault
    }

    const params: InsightByFilterParams = {
      get_insights_key: ['performance'],
      get_metrics_key: [],
      from,
      to,
      list_campaign:
        list_campaign.length && !list_campaign.some((key) => key === 'all')
          ? list_campaign
          : [],
      page,
      per_page
    }
    const res: any = await CampaignServices.insightByFilter(params)

    if (!res.data) {
      throw new Error(res?._message)
    }

    return {
      data: res.data?.performance?.data || outputDefault?.data,
      meta: res.data?.performance?.meta || outputDefault?.meta
    }
  } catch (error) {
    console.log('** Error MessagingInsightsPerformance.helpers.ts : ', error)
  }
  return outputDefault
}
