import { Button, Layout, Menu } from 'antd'
import { Logo } from 'icons'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

export const StyledExpandIcon = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  .anticon {
    font-size: 12px !important;
  }
`

export const StyledLogo = styled(Logo)`
  cursor: pointer;
`

export const StyledButton = styled(Button)`
  &.collapse--button {
    margin-right: 24px;
    color: #fff !important;
    .anticon {
      font-size: 18px;
    }
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1;
  svg {
    width: auto;
    height: 1em;
  }
`

export const StyledNavigationMenu = styled(Menu)`
  width: 100%;
  padding: 8px 16px;
  .ant-menu-item,
  .ant-menu-submenu-title {
    margin: 0 !important;
    padding: 9px !important;
    border-radius: 8px;
    border: 1px solid transparent;
    .ant-menu-title-content {
      margin-left: 24px;
    }
  }
  .ant-menu-item {
    &.ant-menu-item-selected {
      color: ${({ theme }) => theme?.colors?.blue600};
      border-color: ${({ theme }) => theme?.colors?.gray700};
    }
    &.ant-menu-submenu-selected {
      border-color: transparent;
    }
  }
  .ant-menu-sub {
    .ant-menu-item {
      .ant-menu-title-content {
        display: flex;
        align-items: center;
        margin-left: 0;
        &:before {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          content: '·';
          font-size: 18px;
          margin-right: 24px;
        }
      }
    }
  }
  &.ant-menu-inline,
  &.ant-menu-inline-collapsed {
    border-right: 0;
  }
  // Menu Top
  &.menu--top {
    min-height: 0px;
    flex: 1;
  }
  // Menu Bottom
  &.menu--bottom {
  }
`

export const StyledNavigationWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 0;
  flex: 1;
  flex-direction: column;

  .avatar-acount {
    .name {
      color: ${(props) => props.theme.colors.white};
    }
  }
`

export const StyledNavigation = styled(Layout.Sider)`
  min-height: 100vh;
  color: ${({ theme }) => theme?.colors?.white};
  background-color: ${({ theme }) => theme?.colors?.gray800};
  .divider {
    border-color: ${({ theme }) => theme?.colors?.gray700};
  }
  .ant-layout-sider-children {
    display: flex;
    height: auto;
    min-height: 100%;
  }
  &.ant-layout-sider-collapsed {
    ${StyledButton} {
      &.collapse--button {
        margin-right: 0;
      }
    }
    ${StyledLogo} {
      display: none;
    }
    ${LogoContainer} {
      justify-content: center;
    }
    ${StyledNavigationMenu} {
      padding-inline: 8px;

      .ant-menu-item,
      .ant-menu-submenu-title {
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        padding: 8px !important;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        .ant-menu-item-icon {
          line-height: 0;
        }
        .ant-menu-title-content {
          display: none;
        }
      }
      .ant-menu-submenu {
        .ant-menu-submenu-title {
          .ant-menu-title-content {
            display: none;
          }
        }
      }
    }
  }
`

export const StyledWrapper = styled(Layout)`
  background-color: white;
`

export const StyledContainer = styled(Layout)`
  display: flex;
  &.stickyMenu {
    ${StyledNavigation} {
      ${StyledNavigationWrapper} {
        position: sticky;
        top: 0;
      }
    }
  }

  &.layout-sidebar-fixed {
    height: 100vh;
    max-height: 100vh;
    .ant-layout-sider {
      overflow: overlay;
      max-height: 100%;
      ${cssScrollBar}
    }
    .layout-main-header {
      display: flex;
      overflow: overlay;
      min-height: 0;
      flex: 1;
      flex-direction: column;
      ${cssScrollBar}
    }
    .layout-main-body {
      display: flex;
      min-height: 0;
      flex: 1;
      flex-direction: column;
    }
  }
`
