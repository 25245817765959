import { HighchartsCompProps, HighchartsCompRef } from '.'
import { HighchartsEmpty } from './HighchartsEmpty'
import cn from 'classnames'
import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'
import Accessibility from 'highcharts/modules/accessibility'
import VariablePie from 'highcharts/modules/variable-pie'
import { Ref, forwardRef, useImperativeHandle, useRef } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

export type HighchartsSpiderRef = HighchartsCompRef
export type HighchartsSpiderProps = HighchartsCompProps

const HighchartsSpiderWithoutRef = (
  props: HighchartsSpiderProps,
  ref?: Ref<HighchartsSpiderRef>
) => {
  const { options, className, style, emptyProps } = props
  const debounce = useRef<NodeJS.Timeout>()
  const $highchart = useRef<HTMLDivElement>(null)
  const highchartRef = useRef<Highcharts.Chart>()

  useDeepCompareEffect(() => {
    if (debounce.current) clearTimeout(debounce.current)
    debounce.current = setTimeout(() => {
      if ($highchart.current) {
        Accessibility(Highcharts)
        More(Highcharts)
        VariablePie(Highcharts)
        Highcharts.chart(
          $highchart.current,
          {
            ...options,
            title: { text: '', ...options.title },
            yAxis: {
              title: { text: null },
              ...options.yAxis
            },
            xAxis: {
              title: { text: null },
              ...options.xAxis
            },
            legend: { enabled: false, ...options.legend },
            credits: { enabled: false, ...options.credits }
          },
          (chart) => {
            highchartRef.current = chart
          }
        )
      }
    }, 0)
  }, [options])

  useImperativeHandle(ref, () => highchartRef.current as Highcharts.Chart)

  if (!options?.series?.length) {
    return (
      <div className={cn('HighchartsSpider', className)} style={style}>
        <HighchartsEmpty {...emptyProps} />
      </div>
    )
  }

  return (
    <div className={cn('HighchartsSpider', className)} style={style}>
      <div ref={$highchart} className="HighchartsSpider--container" />
    </div>
  )
}
export const HighchartsSpider = forwardRef(HighchartsSpiderWithoutRef)
