import { dictionaryModalTitle, putUpdateDictionary } from '..'
import {
  DictionaryForm,
  DictionaryFormRef,
  DictionaryFormValue,
  formatDictionaryFormValue
} from '../../DictionaryForm'
import { notification } from 'antd'
import { ModalEmpty } from 'components/atoms/modal'
import { useCallback, useEffect, useRef, useState } from 'react'

export type DictionaryModalEditProps = {
  dictionary_type: string
  value?: Partial<DictionaryFormValue>
  onClose?: () => void
  onEdited?: (value: DictionaryFormValue) => void
}

export const DictionaryModalEdit = (props: DictionaryModalEditProps) => {
  const { dictionary_type, value: valueProps, onClose, onEdited } = props
  const form = useRef<DictionaryFormRef>(null)
  const [open, setOpen] = useState(!!valueProps?.id)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<Partial<DictionaryFormValue>>(
    valueProps || {}
  )

  const handleCancel = () => {
    setOpen(false)
    onClose?.()
  }

  const handleFinish = useCallback(
    async (values: DictionaryFormValue) => {
      setLoading(true)
      const { data, errors } = await putUpdateDictionary({
        ...values,
        id: valueProps?.id
      })

      if (errors.length) {
        const fieldData: any[] = []
        errors.forEach((error) => {
          if (
            typeof error.name === 'string' &&
            ['name', 'field'].includes(error.name)
          ) {
            fieldData.push({
              name: error.name,
              errors: error.errors,
              touched: true
            })
          }
        })
        if (fieldData.length) {
          setTimeout(() => {
            form.current?.setFields(fieldData)
            form.current?.scrollToField(fieldData?.[0]?.name, {
              behavior: 'smooth',
              block: 'center'
            })
          }, 0)
        } else {
          notification.error({
            message: errors.flatMap((error) => error.errors)
          })
        }
        setLoading(false)
        return
      }
      notification.success({
        message: `${dictionaryModalTitle[dictionary_type]} field is saved successfully`
      })
      setLoading(false)
      onEdited?.(formatDictionaryFormValue(data))
    },
    [onEdited, form.current, valueProps?.id]
  )

  useEffect(() => {
    setValue(valueProps || {})
    setOpen(!!valueProps?.id)
  }, [valueProps])

  useEffect(() => {
    return () => {
      if (!open) {
        form.current?.resetFields()
      }
    }
  }, [open])

  return (
    <ModalEmpty
      open={open}
      onCancel={handleCancel}
      title={`Edit Data Field ${dictionaryModalTitle[dictionary_type]}`}
      okText="Continue"
      onOk={() => form.current?.submit()}>
      <DictionaryForm
        disabled
        ref={form}
        loading={loading}
        type={dictionary_type}
        initialValues={value}
        onFinish={handleFinish}
      />
    </ModalEmpty>
  )
}
