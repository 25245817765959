import { StyleCheckbox, StyleContainer } from './CheckboxButton.styled'
import { CheckboxGroupProps } from 'antd/lib/checkbox'
import { OptionType } from 'interfaces'
import { Ref, forwardRef } from 'react'

interface CheckboxButtonProps
  extends Pick<
    CheckboxGroupProps,
    'defaultValue' | 'value' | 'disabled' | 'onChange' | 'className' | 'style'
  > {
  options: OptionType[]
}

const CheckboxButtonWithoutRef = (
  {
    options,
    value,
    defaultValue,
    disabled,
    onChange,
    ...restProps
  }: CheckboxButtonProps,
  ref?: Ref<HTMLInputElement>
) => {
  return (
    <StyleContainer>
      <StyleCheckbox
        {...restProps}
        ref={ref}
        options={options}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={onChange}
      />
    </StyleContainer>
  )
}

export const CheckboxButton = forwardRef(CheckboxButtonWithoutRef)
