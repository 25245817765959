import {
  StyledFormHeaderTags,
  StyledFormHeaderTagsDropdown,
  StyledFormHeaderTagsDropdownCheckboxGroup
} from './FormHeaderTags.styled'
import Icon from '@ant-design/icons'
import { Input } from 'antd'
import Button, { ButtonProps } from 'antd/lib/button'
import Checkbox, { CheckboxGroupProps } from 'antd/lib/checkbox'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import Dropdown from 'antd/lib/dropdown'
import cn from 'classnames'
import { Close, Plus } from 'icons/V2'
import { OptionType } from 'interfaces'
import {
  Fragment,
  ReactNode,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { filterOption } from 'utils'
import { sortOptionTypeArray } from 'utils/options'

export type FormHeaderTagsProps = Pick<
  CheckboxGroupProps,
  'disabled' | 'defaultValue' | 'value' | 'onChange' | 'className' | 'style'
> & {
  id?: string
  loading?: boolean
  options?: OptionType[]
  renderTagItem?(
    option: OptionType,
    index: number,
    onRemove: () => void
  ): ReactNode
  renderCheckboxItem?(option: OptionType, index: number): ReactNode
  dropdownRender?(originNode: ReactNode): ReactNode
  size?: SizeType
  showSearch?: boolean
  prefixContent?: ReactNode
  suffixContent?: ReactNode
  addButtonProps?: Omit<
    ButtonProps,
    'loading' | 'disabled' | 'size' | 'onClick'
  >
  showCreate?: boolean
  onCreate?(name: string): void
}
const FormHeaderTagsRenderFunc = (
  props: FormHeaderTagsProps,
  ref?: Ref<HTMLDivElement>
) => {
  const {
    id,
    loading,
    disabled,
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    className,
    style,
    options: optionsProps,
    renderTagItem: renderTagItemProps,
    renderCheckboxItem,
    dropdownRender: dropdownRenderProps,
    size,
    showSearch = true,
    prefixContent,
    suffixContent,
    addButtonProps = {},
    showCreate = false,
    onCreate: onCreateProps
  } = props
  const { children: addButtonChildren = 'Tags', ...restAddButtonProps } =
    addButtonProps
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(defaultValue || valueProps)
  const [search, setSearch] = useState<string>()
  const [options, setOptions] = useState(() =>
    sortOptionTypeArray(optionsProps)
  )

  const searchOptions = useMemo(() => {
    return options?.reduce(
      (searchOptions: OptionType[], option: OptionType) => {
        if (
          typeof option.label === 'string' &&
          filterOption(search || '', {
            value: option.label,
            label: option.label
          })
        ) {
          const newOption = { ...option }
          const s = search || ''
          if (s) {
            const label = newOption.label as string
            const index = label
              .toLocaleLowerCase()
              .indexOf(s.toLocaleLowerCase())
            const stringReplace = label.substring(index, index + s.length)
            newOption.label = (
              <span
                dangerouslySetInnerHTML={{
                  __html: `${(newOption.label as string)?.replace(
                    stringReplace,
                    `<strong>${stringReplace}</strong>`
                  )}`
                }}
              />
            )
          }
          searchOptions.push(newOption)
        }
        return searchOptions
      },
      []
    )
  }, [options, search])

  const onChange = useCallback(
    (vals) => {
      if (search) {
        const newValues = value
          ?.filter((val) => !vals.includes(val))
          .concat(vals)
        setValue(newValues)
        onChangeProps?.(newValues || [])
        setOpen(false)
        return
      }
      setValue(vals)
      onChangeProps?.(vals)
      setOpen(false)
    },
    [onChangeProps, search, value]
  )

  const onRemove = useCallback(
    (index: number) => {
      return () => {
        const newValues = value?.slice(0, index).concat(value?.slice(index + 1))
        setValue(newValues)
        onChangeProps?.(newValues || [])
      }
    },
    [onChangeProps, value]
  )

  const onCreate = useCallback(() => {
    setOpen(false)
    onCreateProps?.(search || '')
  }, [onCreateProps, search])

  const dropdownRender = useMemo(() => {
    return (
      <StyledFormHeaderTagsDropdown className="formHeaderTags--dropdown">
        {!!showSearch && (
          <div className="formHeaderTags--dropdown-search">
            <Input
              size={size}
              placeholder="Search tag"
              className="rounded-lg"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        )}
        <div className="formHeaderTags--dropdown-list">
          {!searchOptions?.length && showCreate && (
            <div className="formHeaderTags--dropdown-item">
              <Button
                block
                icon={<Icon component={Plus} />}
                onClick={onCreate}
                className="formHeaderTags--dropdown-add">
                Create ``<span className="text-primary">{search}</span>``
              </Button>
            </div>
          )}
          <StyledFormHeaderTagsDropdownCheckboxGroup
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}>
            {(searchOptions || options)?.map((opt, index) => {
              return (
                <div
                  key={`${opt.value}-${index}`}
                  className="formHeaderTags--dropdown-item">
                  <Checkbox value={opt.value}>
                    {renderCheckboxItem?.(opt, index) || opt.label}
                  </Checkbox>
                </div>
              )
            })}
          </StyledFormHeaderTagsDropdownCheckboxGroup>
        </div>
      </StyledFormHeaderTagsDropdown>
    )
  }, [
    defaultValue,
    onChange,
    onCreate,
    options,
    renderCheckboxItem,
    search,
    searchOptions,
    showCreate,
    showSearch,
    size,
    value
  ])

  const valueTags = useMemo(() => {
    return value
      ?.map((val) => options.find(({ value }) => value === val))
      .filter(Boolean) as OptionType[]
  }, [options, value])

  const isShowAdd = useMemo(() => {
    if (disabled) {
      return false
    }
    if (!valueTags || !valueTags?.length) {
      return true
    }
    if (!options || !options?.length) {
      return false
    }
    return options?.length > valueTags?.length
  }, [disabled, options, valueTags])

  useEffect(() => {
    if (!open) {
      setSearch(undefined)
    }
  }, [open])

  useEffect(() => {
    setOptions(() => sortOptionTypeArray(optionsProps))
  }, [optionsProps])

  useEffect(() => {
    setValue(valueProps || [])
  }, [valueProps])

  return (
    <StyledFormHeaderTags
      ref={ref}
      id={id}
      className={cn('formHeaderTags', className, { disabled })}
      style={style}>
      {prefixContent}
      {valueTags?.map((option: OptionType, index: number) => {
        return (
          <Fragment key={`${option.value}-${index}`}>
            {renderTagItemProps?.(option, index, onRemove(index)) || (
              <span className="formHeaderTags--item">
                <span className="formHeaderTags--item-label">
                  {option.label}
                </span>
                {!disabled && (
                  <Icon
                    component={Close}
                    onClick={onRemove(index)}
                    className="formHeaderTags--item-icon"
                  />
                )}
              </span>
            )}
          </Fragment>
        )
      })}
      {isShowAdd && (
        <Dropdown
          trigger={['click']}
          menu={{ items: [{ key: 'dropdown' }] }}
          placement="bottomLeft"
          open={open}
          onOpenChange={setOpen}
          dropdownRender={() =>
            dropdownRenderProps?.(dropdownRender) || dropdownRender
          }>
          <Button
            loading={loading}
            disabled={loading || disabled}
            size={size}
            icon={<Icon component={Plus} />}
            onClick={() => setOpen(!open)}
            {...restAddButtonProps}>
            {addButtonChildren}
          </Button>
        </Dropdown>
      )}
      {suffixContent}
    </StyledFormHeaderTags>
  )
}

export const FormHeaderTags = forwardRef(FormHeaderTagsRenderFunc)
