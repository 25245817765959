/* eslint-disable @typescript-eslint/no-unused-vars */
import { CampaignMessagingInsightFilterValue } from '../CampaignMessagingInsightFilter'
import {
  defaultDevicesData,
  getCampaignMessagingInsightDevices
} from './helpers'
import Icon from '@ant-design/icons'
import { Empty } from 'antd'
import { Box } from 'components/atoms/box'
import Highcharts from 'highcharts'
import { NoDataFolder } from 'icons'
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import styled from 'styled-components'

export type CampaignMessagingInsightTabDevicesProps = {
  params?: CampaignMessagingInsightFilterValue
}
export type CampaignMessagingInsightTabDevicesRef = {
  refresh?: (value: CampaignMessagingInsightFilterValue) => void
}

type KeyOfDevices = keyof typeof defaultDevicesData

const StyledChartElement = styled.div`
  /* background-color: red; */
  .highcharts-container {
    margin: 0 auto;
  }
  .highcharts-title {
    text-align: center;
    > * {
      margin: 0;
    }

    h3 {
      color: #262626;
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
    }
  }
`
const COLORS = [
  '#00B16A',
  '#FFB521',
  '#AF6D74',
  '#8F59EF',
  '#2D87F3',
  '#FD72A7'
]
const CampaignMessagingInsightTabDevicesWithoutRef = (
  props: CampaignMessagingInsightTabDevicesProps,
  ref?: Ref<CampaignMessagingInsightTabDevicesRef>
) => {
  const { params } = props
  const [loading, setLoading] = useState(false)
  const [devicesData, setDevicesData] = useState(defaultDevicesData)

  const container = useRef<HTMLDivElement>(null)

  const refresh = useCallback(
    async (input?: CampaignMessagingInsightFilterValue) => {
      if (loading) return
      setLoading(true)
      const { data } = await getCampaignMessagingInsightDevices(
        input || params || {}
      )
      if (data) {
        setDevicesData(data)
      }
      setLoading(false)
    },
    [loading, params]
  )

  useImperativeHandle(ref, () => ({ refresh }), [refresh])

  useEffect(() => {
    refresh()
  }, [])

  const listDevicesKey = useMemo(
    () => Object.keys(devicesData).sort(),
    [devicesData]
  )
  const [total, seriesData] = useMemo(() => {
    let computedTotal = 0
    const seriesData = listDevicesKey
      .map((key: string) => {
        const keyTyped = key as KeyOfDevices
        const valueNumber = devicesData[keyTyped]
        if (!valueNumber) return null
        computedTotal += valueNumber
        return [keyTyped, valueNumber]
      })
      .filter(Boolean) as [KeyOfDevices, number][]

    return [computedTotal, seriesData]
  }, [devicesData, listDevicesKey])

  useEffect(() => {
    if (container.current) {
      Highcharts.chart(
        container.current,
        {
          chart: {
            plotBackgroundColor: '',
            plotBorderWidth: 0,
            plotShadow: false,
            width: 350,
            height: 350
          },
          title: {
            text: `<h3>${total}</h3><p>Total</p>`,
            useHTML: true,
            align: 'center',
            verticalAlign: 'middle',
            // y: 60,
            style: {
              fontSize: '14px'
            }
          },
          tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>'
          },
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: true,
                distance: 0,
                style: {
                  fontWeight: 'bold',
                  color: 'white'
                }
              }
            }
          },
          colors: COLORS,
          series: [
            {
              type: 'pie',
              innerSize: '70%',
              data: seriesData
            }
          ]
        },
        console.log
      )
    }
  }, [total, seriesData])

  return (
    <Box className="shadow-[0px_4px_8px_0px_#10184014] bg-white">
      <Box className="py-4 px-6 border-0 border-b border-[#E4E8EC] border-solid">
        <p className="m-0 font-semibold text-base text-[#262626]">
          Device type breakdown
        </p>
      </Box>
      {seriesData.length === 0 && (
        <Empty
          image={<Icon component={NoDataFolder} className="text-[80px]" />}
          imageStyle={{ height: 'auto' }}
          className="py-28"
        />
      )}
      {seriesData.length > 0 && (
        <Box className="p-6">
          <StyledChartElement ref={container} />
          <Box className="mt-[35px]">
            <table className="w-[50%] mx-auto">
              <tr>
                <td className="text-sm leading-[22px] text-[#101840] font-semibold pb-2 border-0 border-b border-solid border-[#9AA2AC]">
                  Devices
                </td>
                <td className="text-sm leading-[22px] text-[#101840] font-semibold w-[100px] pb-2 border-0 border-b border-solid border-[#9AA2AC]">
                  User
                </td>
              </tr>
              {listDevicesKey.map((key, idx) => {
                const color =
                  COLORS[seriesData.findIndex((item) => item[0] === key)] ||
                  '#E4E8EC'
                return (
                  <tr key={idx}>
                    <td className="text-xs leading-[18px] text-[#474D66] p-2 border-0 border-t border-solid border-[#E4E8EC] capitalize">
                      <Box className="flex items-center gap-2">
                        <Box
                          className="w-[6px] h-[6px] rounded-full"
                          style={{ backgroundColor: color }}
                        />{' '}
                        {key}
                      </Box>
                    </td>
                    <td className="text-xs leading-[18px] text-[#474D66] p-2 border-0 border-t border-solid border-[#E4E8EC] capitalize">
                      {devicesData[key as KeyOfDevices]}
                    </td>
                  </tr>
                )
              })}
            </table>
          </Box>
        </Box>
      )}
    </Box>
  )
}

const CampaignMessagingInsightTabDevices = forwardRef(
  CampaignMessagingInsightTabDevicesWithoutRef
)

export default CampaignMessagingInsightTabDevices
