import { SegmentConditionValueProps } from './SegmentConditionValue'
import { StyledSegmentConditionValueSelect } from './SegmentConditionValue.styled'

export type SegmentConditionValueBOOLProps = Omit<
  SegmentConditionValueProps<boolean>,
  'type'
>

export const SegmentConditionValueBOOL = (
  props: SegmentConditionValueBOOLProps
) => {
  const { operator, options, ...restProps } = props

  return (
    <StyledSegmentConditionValueSelect
      placeholder="Value"
      {...restProps}
      options={[
        { value: true, label: 'True' },
        { value: false, label: 'False' }
      ]}
    />
  )
}
