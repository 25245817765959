import { CachedSystem } from './cachedSystem'
import { ANALYTIC_PATHS } from 'constants/apiPaths'
import {
  AnalyticQueryParams,
  AnalyticSharingAcceptPermissionsReq,
  AnalyticSharingAuthorizationCheckReq,
  AnalyticSharingAuthorizationCheckRes,
  AnalyticSharingGrantPermissionsReq,
  AnalyticSharingListAuthorizedReq,
  AnalyticSharingListUnauthorizedReq,
  AnalyticSharingPermissionItem,
  AnalyticSharingRequestPermissionsAccessReq,
  AnalyticSharingRequestPermissionsAccessRes,
  AnalyticSharingRevokePermissionsReq,
  AnalyticSharingUserItem,
  AnalyticTemplateItem,
  AnalyticTemplateWidgetCreate,
  AnalyticTemplateWidgetItemDataParams,
  AnalyticTemplateWidgetUpdate
} from 'interfaces/analytic'
import { getAsPathApi } from 'utils'
import { segmentBaseAxios, segmentBuilderBaseAxios } from 'utils/api'

const ANALYTIC_TEMPLATE_WIDGET_API = 'widgets'
export const AnalyticServices = {
  getMetric: (data: AnalyticQueryParams) => {
    return segmentBuilderBaseAxios.post(ANALYTIC_PATHS.metric, data)
  },
  getUserCity: (data: AnalyticQueryParams) => {
    return segmentBuilderBaseAxios.post(ANALYTIC_PATHS.user_city, data)
  },
  getMetricByRange: (data: AnalyticQueryParams) => {
    return segmentBuilderBaseAxios.post(ANALYTIC_PATHS.metric_by_range, data)
  },

  getWidgets: () => {
    return segmentBaseAxios.get(ANALYTIC_PATHS.widgets)
  },

  getAnalyticTemplates() {
    return segmentBaseAxios.get(ANALYTIC_PATHS.templates)
  },
  getAnalyticTemplate(id: string | number) {
    return segmentBaseAxios.get(`${ANALYTIC_PATHS.templates}/${id}`)
  },
  postAnalyticTemplates(data: Partial<AnalyticTemplateItem>) {
    return segmentBaseAxios.post(ANALYTIC_PATHS.templates, data)
  },
  putAnalyticTemplates(data: AnalyticTemplateItem) {
    const { id, ...restData } = data
    return segmentBaseAxios.put(`${ANALYTIC_PATHS.templates}/${id}`, restData)
  },
  deleteAnalyticTemplates(id: number) {
    return segmentBaseAxios.delete(`${ANALYTIC_PATHS.templates}/${id}`)
  },

  getAnalyticTemplateWidgets(id: string | number) {
    return segmentBaseAxios.get(
      `${ANALYTIC_PATHS.templates}/${id}/${ANALYTIC_TEMPLATE_WIDGET_API}`
    )
  },
  postAnalyticTemplateWidget(input: AnalyticTemplateWidgetCreate) {
    const { template_id, ...data } = input
    return segmentBaseAxios.post(
      `${ANALYTIC_PATHS.templates}/${template_id}/${ANALYTIC_TEMPLATE_WIDGET_API}`,
      data
    )
  },
  putAnalyticTemplateWidget(input: AnalyticTemplateWidgetUpdate) {
    const { template_id, widget_id, ...data } = input
    CachedSystem.clearCached(`AnalyticServices/${data.widget}/`)
    return segmentBaseAxios.put(
      `${ANALYTIC_PATHS.templates}/${template_id}/${ANALYTIC_TEMPLATE_WIDGET_API}/${widget_id}`,
      data
    )
  },
  deleteAnalyticTemplateWidget(
    template_id: string | number,
    widget_id: string | number
  ) {
    return segmentBaseAxios.delete(
      `${ANALYTIC_PATHS.templates}/${template_id}/${ANALYTIC_TEMPLATE_WIDGET_API}/${widget_id}`
    )
  },
  getAnalyticTemplateWidgetItemData(
    params: AnalyticTemplateWidgetItemDataParams
  ) {
    const paramsKey = {
      width: params.widget,
      segmentId: params.segment_id,
      segmentType: params.segment_type,
      startDate: params.start_date,
      endDate: params.end_date,
      period: params.period
    }
    return CachedSystem.withCached(
      `AnalyticServices/${params.widget}/${JSON.stringify(paramsKey)}`,
      () => {
        return segmentBuilderBaseAxios.get(
          getAsPathApi(ANALYTIC_PATHS.data_by_widget, params)
        )
      }
    )
  },

  getAnalyticWidgets() {
    return segmentBaseAxios.get(ANALYTIC_PATHS.widgets)
  },

  postAnalyticSharingRequestPermissionsAccess({
    template_id,
    ...data
  }: AnalyticSharingRequestPermissionsAccessReq) {
    const apiPath = getAsPathApi(
      ANALYTIC_PATHS.sharing_request_permissions_access,
      { template_id }
    )
    return segmentBaseAxios.post<AnalyticSharingRequestPermissionsAccessRes | null>(
      apiPath,
      data
    )
  },
  postAnalyticSharingAcceptPermission({
    template_id,
    uuid,
    ...data
  }: AnalyticSharingAcceptPermissionsReq) {
    const apiPath = getAsPathApi(
      ANALYTIC_PATHS.sharing_accept_permission_request,
      { template_id, uuid }
    )
    return segmentBaseAxios.post<AnalyticSharingRequestPermissionsAccessRes | null>(
      apiPath,
      data
    )
  },
  postAnalyticSharingAuthorizationCheck({
    template_id,
    ...data
  }: AnalyticSharingAuthorizationCheckReq) {
    const apiPath = getAsPathApi(ANALYTIC_PATHS.sharing_authorization_check, {
      template_id
    })
    return segmentBaseAxios.post<AnalyticSharingAuthorizationCheckRes | null>(
      apiPath,
      data
    )
  },
  postAnalyticSharingGrantPermissions({
    template_id,
    ...data
  }: AnalyticSharingGrantPermissionsReq) {
    const apiPath = getAsPathApi(ANALYTIC_PATHS.sharing_grant_permissions, {
      template_id
    })
    return segmentBaseAxios.post<AnalyticSharingPermissionItem[]>(apiPath, data)
  },
  postAnalyticSharingRevokePermissions({
    template_id,
    ...data
  }: AnalyticSharingRevokePermissionsReq) {
    const apiPath = getAsPathApi(ANALYTIC_PATHS.sharing_revoke_permissions, {
      template_id
    })
    return segmentBaseAxios.post(apiPath, data)
  },
  getAnalyticSharingListAuthorized({
    template_id,
    ...params
  }: AnalyticSharingListAuthorizedReq) {
    const apiPath = getAsPathApi(ANALYTIC_PATHS.sharing_list_authorized, {
      template_id
    })
    return segmentBaseAxios.get<{ data: null | AnalyticSharingUserItem[] }>(
      apiPath,
      { params }
    )
  },
  getAnalyticSharingListUnauthorized({
    template_id,
    ...params
  }: AnalyticSharingListUnauthorizedReq) {
    const apiPath = getAsPathApi(ANALYTIC_PATHS.sharing_list_unauthorized, {
      template_id
    })
    return segmentBaseAxios.get<{ data: null | AnalyticSharingUserItem[] }>(
      apiPath,
      { params }
    )
  }
}
