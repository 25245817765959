import { StyledSelectedContent } from './index.styled'
import { EditorParagraph, EditorTitle } from '@luctc/template-builder'
import { Editor, Content as JSONContent } from '@tiptap/core'

type ParamsCopilot = {
  style: Record<string, string>
  moduleBlockStyle: Record<string, string>
  computedStyle: Record<string, string>
}

type Props = {
  label: string
  dataStyle: ParamsCopilot | null
  htmlContent?: string
  editorType: string
  className?: string
  editable?: boolean
  onChange: (
    path: string,
    htmlContent: string,
    jsonContent: JSONContent,
    editor?: Editor
  ) => void
}

export const fakeDataStyle = {
  style: {},
  moduleBlockStyle: {},
  computedStyle: {}
}

function EditorContent({
  className,
  label,
  dataStyle,
  htmlContent,
  editorType,
  onChange,
  editable = true
}: Props) {
  return (
    <StyledSelectedContent className={className}>
      <span className="selection-head">{label}</span>
      {dataStyle && htmlContent && editorType === 'paragraph' && (
        <EditorParagraph
          key={editorType}
          initContent={htmlContent}
          editable={editable}
          style={dataStyle.style}
          computedStyle={dataStyle.computedStyle}
          moduleBlockStyle={dataStyle.moduleBlockStyle}
          rowIdx={0}
          uuid=""
          onChange={onChange}
        />
      )}
      {dataStyle && htmlContent && editorType === 'title' && (
        <EditorTitle
          title="h1"
          key={editorType}
          initContent={htmlContent}
          editable={editable}
          style={dataStyle.style}
          computedStyle={dataStyle.computedStyle}
          moduleBlockStyle={dataStyle.moduleBlockStyle}
          rowIdx={0}
          uuid=""
          onChange={onChange}
        />
      )}
    </StyledSelectedContent>
  )
}

export default EditorContent
