import { StyledContainer } from './ConditionNumberBetween.styled'
import { InputNumber, InputNumberProps } from 'antd'
import { CSSProperties, Ref, forwardRef, useEffect, useState } from 'react'

export type ConditionNumberBetweenProps = {
  defaultValue?: [number, number] | null
  value?: [number, number] | null
  onChange?: (value: [number, number] | null) => void
  containerClassName?: string
  containerStyle?: CSSProperties
} & Pick<
  InputNumberProps<number>,
  'size' | 'min' | 'max' | 'className' | 'style' | 'disabled'
>

export const ConditionNumberBetweenWithoutRef = (
  props: ConditionNumberBetweenProps,
  ref?: Ref<HTMLInputElement>
) => {
  const {
    defaultValue,
    value: valueProps,
    onChange,
    min,
    max,
    containerClassName,
    containerStyle,
    ...restProps
  } = props
  const [value, setValue] = useState<[number | null, number | null] | null>(
    defaultValue || valueProps || [null, null]
  )

  const handleChange = (index: number) => (val: number | null) => {
    const newValue =
      value && value.length ? [value?.[0], value?.[1]] : [null, null]
    newValue[index] = val
    setValue(newValue as [number, number])
    if (newValue.every((num) => num !== null)) {
      onChange?.(newValue as [number, number])
    } else {
      onChange?.(null)
    }
  }

  useEffect(() => {
    setValue(valueProps || value || [null, null])
  }, [valueProps])

  return (
    <StyledContainer className={containerClassName} style={containerStyle}>
      <InputNumber
        {...restProps}
        placeholder="From"
        value={value?.[0]}
        min={min}
        onChange={handleChange(0)}
        ref={ref}
      />
      <InputNumber
        {...restProps}
        placeholder="To"
        value={value?.[1]}
        min={value?.[0] || 0}
        max={max}
        onChange={handleChange(1)}
      />
    </StyledContainer>
  )
}

export const ConditionNumberBetween = forwardRef(
  ConditionNumberBetweenWithoutRef
)
