import { Button, Checkbox, Space, Tabs } from 'antd'
import { TableCellTitle } from 'components/atoms/table'
import styled from 'styled-components'
import { cssLimitLines, cssScrollBar } from 'styles'

export const ListTemplateTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 16px 0;
    padding-left: 16px;
    padding-right: 16px;
    &:beforer {
      border-color: ${({ theme }) => theme?.colors?.neutral100};
    }
    .ant-tabs-ink-bar {
      background-color: ${({ theme }) => theme?.colors?.gray800};
    }
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        padding-bottom: 16px;
        color: ${({ theme }) => theme?.colors?.gray400};
        font-size: 12px;
        font-weigth: 600;
        + .ant-tabs-tab {
          margin: 0 0 0 16px;
        }
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: ${({ theme }) => theme?.colors?.gray800};
          }
        }
      }
    }
  }
`

export const ListTemplateInputContainer = styled.div`
  padding: 0 16px;
  .ant-input-search {
    width: 50%;
  }
`

export const ListTemplateContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  &.noTabs {
    padding-top: 16px;
  }
`

export const ListTemplateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  line-height: 1;
  padding: 0;
  color: ${({ theme }) => theme?.colors?.gray400};
  font-size: 20px;
  .anticon {
    font-size: inherit;
  }
`

export const ListTemplateInfoItem = styled.div`
  .label {
    margin-bottom: 4px;
    color: ${({ theme }) => theme?.colors?.gray600};
    font-size: 12px;
    font-weight: 600;
  }
  .value {
    font-size: 14px;
    color: ${({ theme }) => theme?.colors?.gray800};
  }

  & + & {
    margin-top: 16px;
  }
`

export const ListTemplateInfoList = styled.div``

export const ListTemplateCheckbox = styled(Checkbox)``

export const ListTemplateSpace = styled(Space)`
  &.col1 {
    display: flex;
    width: 100%;
  }

  .ant-space-item:has(.tableCell) {
    flex: 1;
    min-width: 0;
    max-width: 100%;
    h5 {
      display: block;
    }
  }
`

export const ListTemplateTableCell = styled(TableCellTitle)`
  flex: 1;
  min-width: 0;
  ${cssLimitLines(1)}
`

export const ListTemplateItemCol1 = styled.div`
  flex: 312px 0 0;
  min-width: 0;
`

export const ListTemplateItemCol2 = styled.div`
  flex: 231px 0 0;
  min-width: 0;
`

export const ListTemplateItem = styled.div`
  display: flex;
  align-items: stretch;
  ${ListTemplateItemCol1} {
    padding: 8px;
    border-top: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  }
  ${ListTemplateItemCol2} {
    padding: 8px 16px;
    border-top: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  }
  &.extend {
    padding: 0;
    ${ListTemplateItemCol1} {
      flex-basis: 543px;
      padding: 16px 16px 16px 64px;
      border-top: none;
      background-color: ${({ theme }) => theme?.colors?.neutral50};
    }
    ${ListTemplateItemCol2} {
      flex-basis: 543px;
      padding: 0;
      border-top: none;
    }
  }
`

export const ListTemplateWrapperHeader = styled.div`
  display: flex;
  color: ${({ theme }) => theme?.colors?.gray600};
  font-size: 12px;
  font-weight: 700;
  ${ListTemplateItemCol1} {
    background-color: ${({ theme }) => theme?.colors?.gray50};
    padding: 11px 11px 11px 52px;
  }
  ${ListTemplateItemCol2} {
    background-color: ${({ theme }) => theme?.colors?.gray50};
    padding: 11px 16px;
  }
`

export const ListTemplateInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme?.colors?.neutral100};
  border-radius: 6px;
  overflow-x: scroll;
  overflow-y: scroll;
  ${cssScrollBar}
  .infinite-scroll-component__outerdiv {
    overflow: visible;
    .infinite-scroll-component {
      overflow: visible !important;
    }
  }

  ${ListTemplateItem} {
    &.extend {
      + ${ListTemplateItem} {
        ${ListTemplateItemCol1}, ${ListTemplateItemCol2} {
          border-top: none;
        }
      }
    }
  }
`

export const ListTemplateWrapper = styled.div`
  flex: 1;
  min-height: 0;
  overflow: hidden;
  padding: 16px;
  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;
    .ant-spin-container {
      width: 100%;
      height: 100%;
    }
  }
`
