import { PreviewMessageProps } from '../..'
import {
  StyleAndroidPreviewWrap,
  StyleBrowserContent,
  StyleBrowserWrapper,
  StyleMessage,
  StyleMessageHeader,
  StyleTitle
} from '../../index.styled'
import { HhgIcon, MessageBrowser, MessageBrowserLarge } from 'icons'
import { FC } from 'react'

const PreviewAndroid: FC<PreviewMessageProps> = ({
  preview,
  collapsed,
  domain
}) => {
  const renderImageCollapsed = () => {
    if (preview.image) {
      return (
        <div className="wrapper-image-icon">
          <img src={preview.image} alt="icon" className="image-icon" />
        </div>
      )
    }
    return <MessageBrowser />
  }
  return (
    <StyleBrowserWrapper>
      {collapsed === 'expand' && (
        <StyleMessageHeader>
          <HhgIcon className="chrome-window" />
          <div className="title">Chrome</div>
          <StyleAndroidPreviewWrap>
            <p>{domain}</p>
            <p>now</p>
          </StyleAndroidPreviewWrap>
          {preview.image && (
            <div className="wrapper-image-icon">
              <img src={preview.image} alt="icon" className="image-icon" />
            </div>
          )}
        </StyleMessageHeader>
      )}
      <StyleBrowserContent>
        <HhgIcon className={`edge-android ${collapsed}`} />
        <div className="message-infor">
          {collapsed === 'collapsed' && (
            <>
              <div className="android-infor-wrapper">
                <StyleTitle className="android-title">
                  {preview.title}
                </StyleTitle>
                <StyleAndroidPreviewWrap>
                  <p>{domain}</p>
                  <p>now</p>
                </StyleAndroidPreviewWrap>
              </div>
              <StyleMessage>
                {preview.description
                  .split('\n')
                  .map((strMessage: string, idx: number) => {
                    return <span key={idx}>{strMessage}</span>
                  })}
              </StyleMessage>
            </>
          )}
          {collapsed === 'expand' && (
            <div>
              <StyleTitle className="android-title">{preview.title}</StyleTitle>
              <StyleMessage>
                {preview.description
                  .split('\n')
                  .map((strMessage: string, idx: number) => {
                    return <span key={idx}>{strMessage}</span>
                  })}
              </StyleMessage>
              {preview.image ? (
                <div className="wrapper-image-icon expand-image">
                  <img src={preview.image} alt="icon" className="image-icon" />
                </div>
              ) : (
                <MessageBrowserLarge className="message-icon-large" />
              )}
            </div>
          )}
        </div>
        {collapsed === 'collapsed' && renderImageCollapsed()}
      </StyleBrowserContent>
    </StyleBrowserWrapper>
  )
}

export default PreviewAndroid
