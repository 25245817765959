import { Table, Tabs } from 'antd'
import styled from 'styled-components'
import { cssLimitLines, cssScrollBar } from 'styles'

export const StyledSegmentConditionTableCell = styled.div`
  ${cssLimitLines()}
`

export const StyledSegmentConditionTable = styled(Table)`
  .ant-table-body {
    ${cssScrollBar}
  }

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-container {
    border-top: 1px solid ${({ theme }) => theme?.colors?.neutral100};
    border-left: 1px solid ${({ theme }) => theme?.colors?.neutral100};
    .ant-table-header {
      tr {
        th:last-child {
          border-right: 1px solid ${({ theme }) => theme?.colors?.neutral100};
        }
      }
    }
    .ant-table-body {
      tr {
        td:last-child {
          border-right: 1px solid ${({ theme }) => theme?.colors?.neutral100};
        }
      }
    }
  }
`

export const StyledSegmentConditionTabs = styled(Tabs)``
