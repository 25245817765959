import { Segmented } from 'antd'
import styled from 'styled-components'

export const StyledSegmented = styled(Segmented)``

export const StyledFormRelation = styled.div`
  margin-bottom: 24px;
  &.isChild {
    margin-bottom: 12px;
  }
`
