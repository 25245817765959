import { OptionType } from 'interfaces'
import {
  OperatorKeys,
  operatorLabel,
  operatorValue
} from 'interfaces/condition'

export const DATA_TYPE = {
  API: 2,
  BOOLEAN: 3,
  TEXT: 0,
  NUMBER: 1,
  DATE: 4,
  CHECKBOX: 5
}

export type DataTypeKeys = keyof typeof DATA_TYPE
export const OPERATOR_BY_DATA_TYPE: Record<DataTypeKeys, OperatorKeys[]> = {
  API: ['eq', 'in', 'n_in'],
  BOOLEAN: ['eq'],
  TEXT: ['eq', 'n_eq', 'cont', 'n_cont'],
  NUMBER: ['lt', 'gt', 'eq', 'n_eq', 'lt_eq', 'gt_eq', 'bt'],
  DATE: ['lt', 'gt', 'eq', 'n_eq', 'lt_eq', 'gt_eq', 'bt'],
  CHECKBOX: ['eq']
}
export const OPERATOR_OPTIONS_BY_DATA_TYPE: Record<DataTypeKeys, OptionType[]> =
  {
    API: OPERATOR_BY_DATA_TYPE['API'].map((key) => ({
      value: operatorValue[key],
      label: operatorLabel[key]
    })),
    BOOLEAN: OPERATOR_BY_DATA_TYPE['BOOLEAN'].map((key) => ({
      value: operatorValue[key],
      label: operatorLabel[key]
    })),
    TEXT: OPERATOR_BY_DATA_TYPE['TEXT'].map((key) => ({
      value: operatorValue[key],
      label: operatorLabel[key]
    })),
    NUMBER: OPERATOR_BY_DATA_TYPE['NUMBER'].map((key) => ({
      value: operatorValue[key],
      label: operatorLabel[key]
    })),
    DATE: OPERATOR_BY_DATA_TYPE['DATE'].map((key) => ({
      value: operatorValue[key],
      label: operatorLabel[key]
    })),
    CHECKBOX: OPERATOR_BY_DATA_TYPE['CHECKBOX'].map((key) => ({
      value: operatorValue[key],
      label: operatorLabel[key]
    }))
  }

// Revamp Field
//#region
export const FIELD_CATEGORY = {
  ATTRIBUTE: 'attribute',
  MOBILE: 'mobile',
  EVENT: 'event',
  EVENT_CUSTOM: 'custom-event-conditions'
}
//#endregion
