import { SITE_DOMAINS, SITE_KEYS } from 'constants/site'

export const mapToCorrectLocationName: Record<string, string> = {
  'Ho Chi Minh City': 'Thành phố Hồ Chí Minh',
  'Thành phố Hồ Chí Minh': 'Thành phố Hồ Chí Minh',
  'Hồ Chí Minh': 'Thành phố Hồ Chí Minh',
  'Tp HCM': 'Thành phố Hồ Chí Minh',
  'Tp. Hồ Chí Minh': 'Thành phố Hồ Chí Minh',
  TpHCM: 'Thành phố Hồ Chí Minh',
  Tphcm: 'Thành phố Hồ Chí Minh',
  Hanoi: 'Hà Nội',
  'Hà Nội': 'Hà Nội',
  'Thành phố Hà Nội': 'Hà Nội',
  Haiphong: 'Hải Phòng',
  'Hai Phong': 'Hải Phòng',
  'Hải Phòng': 'Hải Phòng',
  'Thành phố Hải Phòng': 'Hải Phòng',
  'Bien Hoa': 'Biên Hoà',
  'Nam Djinh': 'Nam Định',
  'Da Nang': 'Đà Nẵng',
  'Đà Nẵng': 'Đà Nẵng',
  'đà nẵng': 'Đà Nẵng',
  'Hai Duong': 'Hải Dương',
  'Can Tho': 'Cần Thơ',
  'Cần Thơ': 'Cần Thơ',
  'Thành Phố Cần Thơ': 'Cần Thơ',
  Vinh: 'Vinh',
  'Mong Cai': 'Móng Cái',
  'Thanh Hoa': 'Thanh Hoá',
  'Son Tay': 'Sơn Tây',
  'Bac Ninh': 'Bắc Ninh',
  'Buon Ma Thuot': 'Đăk Lắk',
  'Thuan An': 'Thuận An',
  'Nha Trang': 'Nha Trang',
  'Vung Tau': 'Vũng Tàu',
  Dalat: 'Đà Lạt',
  'Di An': 'Dĩ An',
  'Quy Nhon': 'Quy Nhơn',
  'My Tho': 'Mỹ Tho',
  'Thai Binh': 'Thái Bình',
  Hue: 'Huế',
  'Hung Yen': 'Hưng Yên',
  'Bac Giang': 'Bắc Giang',
  'Long Xuyen': 'Long Xuyên',
  'Thai Nguyen': 'Thái Nguyên',
  'Quang Ngai': 'Quảng Ngãi',
  'Ben Tre': 'Bến Tre',
  'Tu Son': 'Từ Sơn',
  'Vinh Yen': 'Vĩnh Yên',
  'Tay Ninh': 'Tây Ninh',
  'Viet Tri': 'Việt Trì',
  Pleiku: 'Pleiku',
  'Rach Gia': 'Rạch Giá',
  'Phan Thiet': 'Phan Thiết',
  'Ca Mau': 'Cà Mau',
  'Phu Ly': 'Phủ Lý',
  'Ben Cat': 'Bến Cát',
  'Ninh Binh': 'Ninh Bình',
  'Ha Long': 'Hạ Long',
  'Tra Vinh': 'Trà Vinh',
  'Djong Hoi': 'Đồng Hới',
  'Soc Trang': 'Sóc Trăng',
  'Ha Tinh': 'Hà Tĩnh',
  'Tuy Hoa': 'Tuy Hoà',
  'Hoi An': 'Hội An',
  'Dong Xoai': 'Đồng Xoài',
  'Vinh Long': 'Vĩnh Long',
  'Cao Lanh': 'Cao Lãnh',
  'Tam Ky': 'Tam Kỳ',
  'Bac Lieu': 'Bạc Liêu',
  'Phan Rang–Thap Cham': 'Phan Rang - Tháp Chàm',
  'Lang Son': 'Lạng Sơn',
  'Lao Cai': 'Lào Cai',
  'Hoa Binh': 'Hoà Bình',
  'Thu Dau Mot': 'Thủ Dầu Một',
  'Binh Minh': 'Bình Minh',
  'Tuyen Quang': 'Tuyên Quang',
  'Bao Loc': 'Bảo Lộc',
  'An Nhon': 'An Nhơn',
  'Djong Ha': 'Đông Hà',
  'Phuc Yen': 'Phúc Yên',
  'Tan Uyen': 'Tân Uyên',
  'Yen Bai': 'Yên Bái',
  'Tan An': 'Tân An',
  'Ba Ria': 'Bà Rịa',
  'Son La': 'Sơn La',
  'Sa Djec': 'Sa Đéc',
  'Kon Tum': 'Kon Tum',
  'Gia Nghia': 'Gia Nghĩa',
  'Tan Chau': 'Tân Châu',
  'Cao Bang': 'Cao Bằng',
  'Vi Thanh': 'Vị Thanh',
  'Hoang Mai': 'Hoàng Mai',
  'Phu Quoc': 'Phú Quốc',
  'Binh Long': 'Bình Long',
  'Go Cong': 'Gò Công',
  'Buon Ho': 'Buôn Hồ',
  'Ha Giang': 'Hà Giang',
  'Ninh Hoa': 'Ninh Hoà',
  'Chi Linh': 'Chí Linh',
  'Nga Bay': 'Ngã Bảy',
  'Djien Bien Phu': 'Điện Biên Phủ',
  'Hong Ngu': 'Hồng Ngự',
  'Uong Bi': 'Uông Bí',
  'Chau Djoc': 'Châu Đốc',
  'Cam Pha': 'Cẩm Phả',
  'Bac Kan': 'Bắc Kạn',
  'La Gi': 'La Gi Bình Thuận',
  'Phu Tho': 'Phú Phọ',
  'Hong Linh': 'Hồng Lĩnh',
  'Huong Thuy': 'Hương Thuỷ',
  'Huong Tra': 'Hương Trà',
  'Long Khanh': 'Long Khánh',
  'Cam Ranh': 'Cam Ranh',
  'Tam Djiep': 'Tam Điệp',
  'Lai Chau': 'Lai Châu',
  'Thai Hoa': 'Thái Hoà',
  'Bim Son': 'Bỉm Sơn',
  'Song Cau': 'Sông Cầu',
  'Quang Tri': 'Quảng Trị',
  'Quang Yen': 'Quảng Yên',
  'Phuoc Long': 'Phước Long',
  'Song Cong': 'Sông Công',
  'Kien Tuong': 'Kiến Tường',
  'Cua Lo': 'Cửa Lò',
  'Sam Son': 'Sầm Sơn'
}

export const checkIsVietnamSite = (sites?: { id: number; name: string }[]) => {
  return !!sites?.find((item) => {
    const key = item.name as keyof typeof SITE_DOMAINS
    return (
      SITE_DOMAINS[key] === SITE_KEYS.HELLO_BAC_SI ||
      SITE_DOMAINS[key] === SITE_KEYS.MARRY_BABY ||
      SITE_DOMAINS[key] === SITE_KEYS.HELLO_HEALTH_GROUP
    )
  })
}

export const checkIsTaiwanSite = (sites?: { id: number; name: string }[]) => {
  return !!sites?.find((item) => {
    const key = item.name as keyof typeof SITE_DOMAINS
    return SITE_DOMAINS[key] === SITE_KEYS.HELLO_YISHI
  })
}

export const checkIsIndoSite = (sites?: { id: number; name: string }[]) => {
  return !!sites?.find((item) => {
    const key = item.name as keyof typeof SITE_DOMAINS
    return SITE_DOMAINS[key] === SITE_KEYS.HELLO_SEHAT
  })
}

export const checkIsMalaysiaSite = (sites?: { id: number; name: string }[]) => {
  return !!sites?.find((item) => {
    const key = item.name as keyof typeof SITE_DOMAINS
    return SITE_DOMAINS[key] === SITE_KEYS.HELLO_DOKTOR
  })
}

export const checkIsPhilippinesSite = (
  sites?: { id: number; name: string }[]
) => {
  return !!sites?.find((item) => {
    const key = item.name as keyof typeof SITE_DOMAINS
    return (
      SITE_DOMAINS[key] === SITE_KEYS.HELLO_FIL ||
      SITE_DOMAINS[key] === SITE_KEYS.HELLO_DOCTOR
    )
  })
}

export const formatVisitNumber = (num: number) => {
  try {
    return new Intl.NumberFormat('vi-VN').format(num)
  } catch (err) {
    return num
  }
}
