import {
  StyledIcon,
  StyledSelect,
  StyledTag,
  StyledTagClose,
  StyledTagLabel
} from './Select.styled'
import { SelectProps as AntSelectProps } from 'antd'
import cn from 'classnames'
import { Close } from 'icons'
import { OptionType } from 'interfaces'
import { ForwardRefRenderFunction, forwardRef } from 'react'

export const defaultSelectPlaceholder = '- Select -'
export type SelectProps = Omit<AntSelectProps, 'options'> & {
  options?: OptionType[]
}
const SelectForwardRef: ForwardRefRenderFunction<
  HTMLSelectElement,
  SelectProps
> = (props, ref) => {
  const {
    placeholder = defaultSelectPlaceholder,
    tagRender: tagRenderProps,
    maxTagPlaceholder: maxTagPlaceholderProps,
    ...restProps
  } = props

  const tagRender =
    tagRenderProps ||
    ((tagProps) => {
      const { label, onClose, closable } = tagProps
      const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault()
        event.stopPropagation()
      }
      return (
        <StyledTag>
          <StyledTagLabel>{label}</StyledTagLabel>
          {closable && (
            <StyledTagClose onMouseDown={onPreventMouseDown} onClick={onClose}>
              <StyledIcon component={Close} className="anticon-close" />
            </StyledTagClose>
          )}
        </StyledTag>
      )
    })

  const maxTagPlaceholder =
    maxTagPlaceholderProps ||
    ((omittedValues: []) => {
      const title = `+ ${omittedValues.length} ...`
      return (
        <StyledTag>
          <StyledTagLabel>{title}</StyledTagLabel>
        </StyledTag>
      )
    })

  return (
    <StyledSelect<any>
      {...restProps}
      ref={ref}
      placeholder={placeholder}
      tagRender={tagRender}
      maxTagPlaceholder={maxTagPlaceholder}
      className={cn('select', restProps?.className)}
    />
  )
}

const Select: any = forwardRef(SelectForwardRef)
Select.Option = StyledSelect.Option
export { Select }
