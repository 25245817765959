import Icon from '@ant-design/icons'
import { Modal, ModalProps } from 'antd'
import { Close } from 'icons'
import { FC } from 'react'
import styled from 'styled-components'

const StyledIcon = styled(Icon)``

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 1rem;
    .ant-modal-close {
      top: 16px;
      right: 24px;
      .ant-modal-close-x {
        color: ${({ theme }) => theme?.colors?.neutral400 || '#595959'};
        font-size: 24px;
        transition: 0.25s all linear;
        &:hover {
          color: ${({ theme }) => theme?.colors?.gray600 || '#595959'};
        }
      }
    }
    .ant-modal-header {
      border-bottom: 0;
      background-color: transparent;
      border-radius: 0;
      .ant-modal-title {
        color: ${(props) => props.theme?.colors?.gray800};
        font-size: 22px;
        font-weight: 600;
        letter-spacing: -0.6px;
        line-height: 1.4;
      }
    }
    .ant-modal-footer {
      border-top: 0;
      border-radius: 0;
    }

    .ant-btn {
      &.okBtn,
      &.cancelBtn {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  &.modalEmpty--v2 {
    .ant-modal-content {
      .ant-modal-close {
        top: 24px;
        right: 32px;
      }

      .ant-modal-header {
        padding-block: 1.5rem;
        padding-inline: 2rem;
        padding-bottom: 1rem;
      }
      .ant-modal-body {
        padding-block: 0;
        padding-inline: 2rem;
      }
      .ant-modal-footer {
        padding-block: 1.5rem;
        padding-inline: 2rem;
        padding-top: 1rem;
      }
    }
  }
`

export const ModalEmpty: FC<ModalProps> = ({
  closeIcon: closeIconProps,
  ...rest
}) => {
  const closeIcon = closeIconProps || <StyledIcon component={Close} />
  return <StyledModal {...rest} closeIcon={closeIcon} />
}
