import { CAMPAIGN_TEMPLATE } from '.'
import { CampaignPageEdit } from 'components/pages'
import Page404 from 'pages/404'
import { useParams } from 'react-router'

export const CampaignTemplateEdit = () => {
  const { template_type } = useParams()
  if (!CAMPAIGN_TEMPLATE.includes(template_type as string)) {
    return <Page404 />
  }
  return <CampaignPageEdit template_type={template_type} />
}
