import {
  StyledButton,
  StyledHeader,
  StyledHeaderContainer,
  StyledHeaderSpace,
  StyledHeaderTitle,
  StyledHeaderTitleContainer
} from './SegmentFormHeader.styled'
import Icon from '@ant-design/icons'
import { ButtonProps } from 'antd'
import { ChevronLeft } from 'icons'

export type SegmentFormHeaderProps = {
  title?: string
  onBack?: ButtonProps['onClick']
  okText?: string
  onOk?: ButtonProps['onClick']
  okButtonProps?: Omit<ButtonProps, 'onClick'>
  cancelText?: string
  onCancel?: ButtonProps['onClick']
  cancelButtonProps?: Omit<ButtonProps, 'onClick'>
  hideNavigation?: boolean
}

export const SegmentFormHeader = (props: SegmentFormHeaderProps) => {
  const {
    title,
    onBack,
    okText,
    onOk,
    okButtonProps,
    cancelText,
    onCancel,
    cancelButtonProps,
    hideNavigation
  } = props

  return (
    <StyledHeader>
      <StyledHeaderContainer>
        <StyledHeaderTitleContainer>
          {!hideNavigation && (
            <>
              <StyledButton
                size="small"
                type="link"
                onClick={onBack}
                icon={<Icon component={ChevronLeft} />}
                className="btnBack"
              />
              <StyledHeaderTitle>{title || 'New Segment'}</StyledHeaderTitle>
            </>
          )}
        </StyledHeaderTitleContainer>
        <StyledHeaderSpace size={8}>
          {!!onCancel && (
            <StyledButton
              {...cancelButtonProps}
              onClick={onCancel}
              className="btn">
              {cancelText || 'Cancel'}
            </StyledButton>
          )}
          {!!onOk && (
            <StyledButton
              type="primary"
              {...okButtonProps}
              onClick={onOk}
              className="btn">
              {okText || 'Save'}
            </StyledButton>
          )}
        </StyledHeaderSpace>
      </StyledHeaderContainer>
    </StyledHeader>
  )
}
