import Icon from '@ant-design/icons'
import { Tabs } from 'antd'
import { InputSearch } from 'components/atoms/input'
import styled from 'styled-components'
import { cssLimitLines } from 'styles'

export const StyledStepSelectCellImage = styled.div`
  width: 60px;
  height: 40px;
  border-radius: 6px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export const StyledStepSelectCellContent = styled.div`
  flex: 1;
  min-width: 0;
  &.whatsapp_body {
    ${cssLimitLines(3)}
  }
`

export const StyledStepSelectCell = styled.div`
  &.name {
    max-width: 320px;
    display: flex;
    .title {
      margin-bottom: 0;
      color: ${({ theme }) => theme?.colors?.gray800};
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      ${cssLimitLines(1)}
    }
    .id {
      color: ${({ theme }) => theme?.colors?.gray600};
      font-size: 12px;
      line-height: 1.5;
      margin-bottom: 0;
    }

    ${StyledStepSelectCellImage} {
      margin-right: 8px;
    }
  }
`

export const StyledStepSelectCellContainer = styled.div`
  &.name {
    display: flex;
    align-items: center;
    ${StyledStepSelectCell} {
      flex: 1;
      min-width: 0;
      margin-right: 10px;
    }
    .preview {
      font-size: 18px;
    }
  }
`

export const StyledStepSelectTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    display: inline-flex;
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding-top: 2px;
          padding-bottom: 8px;
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: -0.2px;
        }
      }
    }

    .ant-tabs-nav-operations,
    .ant-tabs-content-holder {
      display: none;
    }
  }
`

export const StyledStepSelectInputSearch = styled(InputSearch)`
  max-width: 240px;
  .ant-input-affix-wrapper {
    padding-block: 5px;
  }
`

export const StyledStepSelectHeaderTabs = styled.div``

export const StyledStepSelectHeaderSearch = styled.div``

export const StyledStepSelectHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledSelectValueIcon = styled(Icon)`
  margin-left: 16px;
  font-size: 24px;
  cursor: pointer;
`

export const StyledSelectValueTitle = styled.p`
  margin: 0;
  flex: 1;
  min-width: 0;
  color: ${({ theme }) => theme?.colors?.gray200};
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  strong {
    color: #fff;
    letter-spacing: 0;
  }
`

export const StyledSelectValue = styled.div`
  position: absolute;
  left: 50%;
  bottom: 80px;
  padding: 12px 16px;
  width: 100%;
  max-width: 560px;
  background-color: ${({ theme }) => theme?.colors.gray800};
  border-radius: 4px;
  color: #fff;
  transform: translateX(-50%) translateY(20px);
  display: flex;
  opacity: 0;
  pointer-events: none;
  transition: 0.25s linear all;
  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(-50%) translateY(0px);
    transition: 0.25s linear all;
  }
`

export const StyledSelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  .ant-table {
    td {
      background-color: #fff;
    }
    .ant-table-title {
      padding: 0 0 12px;
      border: none;
    }
    .ant-table-footer {
      padding-inline: 0;
      border: none;
    }

    &.ant-table-bordered {
      background-color: transparent;
      > .ant-table-container {
        border-radius: 8px;
        > .ant-table-header {
          > table {
            border-right: 0;
            border-top-left-radius: 8px !important;
            border-top-right-radius: 8px !important;
            > thead {
              > tr {
                > th {
                  border-top: 1px solid
                    ${({ theme }) => theme?.colors?.neutral100};
                  border-right: 0;
                  &:first-child {
                    border-top-left-radius: 8px;
                  }
                  &:last-child {
                    border-top-right-radius: 8px !important;
                    border-right: 1px solid
                      ${({ theme }) => theme?.colors?.neutral100};
                  }
                }
              }
            }
          }
        }
        > .ant-table-body {
          > table {
            > tbody {
              > tr {
                &:last-child {
                  > td {
                    &:first-child {
                      border-bottom-left-radius: 8px;
                    }
                  }
                }
                > td {
                  border-right: 0;
                  &:last-child {
                    border-right: 1px solid
                      ${({ theme }) => theme?.colors?.neutral100};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
