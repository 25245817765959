import Icon from '@ant-design/icons'
import { Button, Dropdown, Tabs } from 'antd'
import { ListSearch } from 'components/atoms/list'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

export const StyledIcon = styled(Icon)`
  font-size: 1.2em;
  vertical-align: middle;
  &.tabs--icon {
    margin-right: 8px;
    font-size: 16px;
  }
`

export const StyledButton = styled(Button)`
  padding-right: 16px;
  padding-left: 12px;
  &.dropdownTab--active {
    border-color: ${({ theme }) => theme?.colors?.blue800 || '#2B62CD'};
    background-color: ${({ theme }) => theme?.colors?.blue800 || '#2B62CD'};
    color: #fff;
  }
`

export const StyledListSearch = styled(ListSearch)`
  &.ant-list {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  .ant-spin-nested-loading {
    overflow: auto;
    min-height: 0;
    ${cssScrollBar}
  }
`

export const StyledTabsLabel = styled.div`
  display: flex;
  align-items: center;
`

export const StyledTabs = styled(Tabs)`
  &.ant-tabs {
    overflow: hidden;
    flex: 1;
    .ant-tabs-content-holder {
      height: 100%;
      .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane {
          height: 100%;
        }
      }
    }
  }
  .ant-tabs-nav {
    margin-top: 0;
    color: ${({ theme }) => theme?.colors?.gray500 || '#737373'};
  }
`

export const StyledOverlay = styled.div`
  display: flex;
  width: 100%;
  max-width: 830px;
  max-height: 320px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme?.colors?.gray200 || '#D9D9D9'};
  background-color: #fff;
  border-radius: ${({ theme }) => theme?.borderRadiusBase || '6px'};
  box-shadow: 0px 0px 1px 0px #0000000a, 0px 2px 8px 0px #0000000a,
    0px 10px 16px 0px #0000000a;
`

export const StyledDropdown = styled(Dropdown)``
