import { ConditionBaseProps } from '..'
import {
  StyledCondition,
  StyledConditionContent,
  StyledConditionDatePicker,
  StyledConditionLabel
} from '../index.styled'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

export type ConditionOperatorProps = ConditionBaseProps<
  string | null | [Dayjs, Dayjs]
> & {
  label?: string
  placeholder?: string
  size?: SizeType
}
export const ConditionDate = (props: ConditionOperatorProps) => {
  const {
    label,
    className,
    size,
    value: valueProps,
    onChange: onChangeProps
  } = props
  const [value, setValue] = useState<any>(null)

  const onChange = (val: [Dayjs, Dayjs] | null) => {
    const newValue = val
    setValue(newValue)
    onChangeProps && onChangeProps(newValue)
  }

  useEffect(() => {
    setValue(valueProps || null)
  }, [valueProps])

  return (
    <StyledCondition className={className}>
      {label && <StyledConditionLabel>{label}</StyledConditionLabel>}
      <StyledConditionContent>
        <StyledConditionDatePicker
          value={value || null}
          onChange={(e: any) => onChange(e)}
          size={size}
        />
      </StyledConditionContent>
    </StyledCondition>
  )
}
