import { useCallback, useEffect, useMemo, useState } from 'react'

export type UseFormStoreProps<T> = {
  defaultValue?: T
  value?: T
  onChange?: (value: T | undefined) => void
  onFinish?: (value: T | undefined) => void
}
export type UseFormStoreReturn<T> = {
  value: T | undefined
  setValue: React.Dispatch<React.SetStateAction<T | undefined>>
  onChange: (values?: T, options?: any) => void
  onFinish: (values?: T) => void
  reset: () => void
}
export const useFormStore = <T = any>(props: UseFormStoreProps<T>) => {
  const {
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    onFinish: onFinishProps
  } = props
  const [value, setValue] = useState<T | undefined>(defaultValue || valueProps)

  useEffect(() => {
    setValue(valueProps || defaultValue)
  }, [defaultValue, valueProps])

  const onChange = useCallback(
    (values?: Partial<T>, options = { replace: false }) => {
      if (typeof values === 'undefined') {
        setValue(undefined)
        onChangeProps?.(undefined)
        return
      }
      const newValues = (
        options.replace ? values : { ...value, ...values }
      ) as T
      setValue(newValues)
      onChangeProps?.(newValues)
    },
    [onChangeProps, value]
  )

  const onFinish = useCallback(
    (values?: T) => {
      if (typeof values === 'undefined') {
        setValue(value)
        onFinishProps?.(value)
        return
      }
      const newValues = { ...values, ...value }
      setValue(newValues)
      onFinishProps?.(newValues)
    },
    [onFinishProps, value]
  )

  const reset = useCallback(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return useMemo(
    () => ({ value, setValue, onChange, onFinish, reset }),
    [onChange, onFinish, reset, value]
  )
}
