import { SegmentFormProps } from '../SegmentForm.helpers'
import { SegmentCondition } from './SegmentCondition'
import {
  defaultSegmentFormQueryListItem,
  optionSegmentOperator,
  optionSegmentType,
  segmentEventIdVisitSite,
  segmentFormQueryRules
} from './SegmentFormQuery.helpers'
import {
  StyledButton,
  StyledDescription,
  StyledFormQueryContainer,
  StyledSegmentListCol,
  StyledSegmentListCondition,
  StyledSegmentListRow,
  StyledSelect
} from './SegmentFormQuery.styled'
import { SegmentFormQueryHeader } from './SegmentFormQueryHeader'
import { SegmentFormQuerySelectEvent } from './SegmentFormQuerySelectEvent'
import Icon from '@ant-design/icons'
import { Form, FormInstance, Input, Skeleton, notification } from 'antd'
import cn from 'classnames'
import { DatePicker } from 'components/atoms/datePicker'
import { ConditionsRelation } from 'components/condition/conditions/relation'
import { FORMAT_DATE } from 'constants/common'
import { PERMISSIONS } from 'constants/permission'
import { SEGMENT_TYPE } from 'constants/segment'
import { Dayjs } from 'dayjs'
import { useCheckAuth } from 'hooks/useCheckAuth'
import { Plus, TrashBin } from 'icons'
import { OptionTypeSite } from 'interfaces'
import { Fragment, Ref, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import { filterOption } from 'utils'

type Condition = {
  field: string
  operator: string
  value: any
  type: string
  data_type?: number
}
export type SegmentFormQueryListItem = {
  operator?: string
  event_id?: number
  submitted?: [Dayjs, Dayjs] | null
  segment_type?: string
  conditions?: Condition[]
}
export type SegmentFormQueryValue = {
  segment_type?: string
  operator?: string
  list?: Array<SegmentFormQueryListItem>
}
export type SegmentFormQueryRef = FormInstance<SegmentFormQueryValue>
export type SegmentFormQueryProps = SegmentFormProps<SegmentFormQueryValue> & {
  sites?: OptionTypeSite[]
  segment_status?: number
}
export const SegmentFormQuery = (
  props: SegmentFormQueryProps,
  ref?: Ref<SegmentFormQueryRef>
) => {
  const location = useLocation()
  /** Display the form to create segment for webpush inside the campaign create flow */
  const isCampaignWebpushPage = location.pathname.startsWith(
    '/campaign/web_push/'
  )
  const {
    form: formProps,
    segment_type,
    segment_status,
    name,
    loading,
    disabled,
    disabledKeys,
    sites,
    initialValues,
    onValuesChange,
    onFinish,
    onFinishFailed
  } = props
  const [form] = Form.useForm(formProps)
  const listForm = Form.useWatch('list', form)
  const isQuery = !useCheckAuth({ permissions: [PERMISSIONS.segment_edit] })

  const disabledRunQuery = useMemo(() => {
    return disabledKeys?.includes('list')
  }, [disabledKeys])
  const disabledButtonDeleteList = useMemo(() => {
    return disabledKeys?.includes('segment_type')
  }, [disabledKeys])

  const handleValuesChange = (valChanged: any, valsChanged: any) => {
    const newValues = { ...valsChanged }

    if (valChanged.hasOwnProperty('list')) {
      valChanged.list.forEach(
        (item: SegmentFormQueryListItem, listIndex: number) => {
          if (item.hasOwnProperty('conditions')) {
            item.conditions?.forEach((condition, condIndex: number) => {
              if (item.conditions?.length === 1) {
                const firstItem = item.conditions?.[0]
                const isQuantityCondition =
                  firstItem.hasOwnProperty('field') &&
                  firstItem.hasOwnProperty('operator') &&
                  firstItem.hasOwnProperty('value')
                if (isQuantityCondition) {
                  newValues.list[listIndex].conditions[0] = {
                    ...newValues.list[listIndex].conditions[0]
                  }
                  return
                }
              }

              const isChangedCondition =
                item.conditions?.includes(undefined as any) ||
                item.conditions?.length === 1

              if (isChangedCondition) {
                if (condition.hasOwnProperty('field')) {
                  newValues.list[listIndex].conditions[condIndex] = {
                    ...newValues.list[listIndex].conditions[condIndex],
                    operator: undefined,
                    value: undefined
                  }
                }
                if (condition.hasOwnProperty('operator')) {
                  newValues.list[listIndex].conditions[condIndex] = {
                    ...newValues.list[listIndex].conditions[condIndex],
                    value: undefined
                  }
                }
              }
            })
          }

          const isResetCondition =
            item.hasOwnProperty('event_id') ||
            item.hasOwnProperty('segment_type')
          if (isResetCondition) {
            newValues.list[listIndex].conditions = []
          }
        }
      )
    }

    onValuesChange?.(valChanged, newValues)
  }

  const handleFinishFailed: SegmentFormQueryProps['onFinishFailed'] = (
    errorInfo
  ) => {
    const { errorFields } = errorInfo
    if (errorFields.length) {
      errorFields.forEach((errField) => {
        if (errField.errors.length) {
          const isNotification =
            (errField.name.length === 1 &&
              //  Magic here
              ['list'].some((key) => {
                return errField.name.includes(key)
              })) ||
            errField.name?.[errField.name.length - 1] === 'conditions'
          if (isNotification) {
            notification.error({ message: errField.errors })
          }
        }
      })
    }
    onFinishFailed?.(errorInfo)
  }

  useEffect(() => {
    form.setFieldsValue({ ...initialValues })
  }, [initialValues])

  return (
    <Form
      disabled={disabled || disabledRunQuery}
      form={form}
      name={name}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      onFinish={onFinish}
      onFinishFailed={handleFinishFailed}>
      <Form.Item
        name="segment_type"
        rules={segmentFormQueryRules.operator}
        hidden>
        <Input />
      </Form.Item>
      <Skeleton loading={loading} paragraph={{ rows: 4 }} active>
        <StyledFormQueryContainer>
          <SegmentFormQueryHeader
            domain={sites?.map(({ domain }) => domain).join(', ')}
            className="header"
          />
          <Form.List name="list" rules={segmentFormQueryRules.list as any}>
            {(list, listOperation) => {
              const onAdd = () => {
                if (!sites?.length) {
                  notification.info({
                    message: 'Please choose a site before adding condition!'
                  })
                  return
                }
                listOperation.add({
                  ...defaultSegmentFormQueryListItem,
                  segment_type,
                  ...(isCampaignWebpushPage
                    ? { event_id: segmentEventIdVisitSite }
                    : {})
                })
              }
              return (
                <Fragment>
                  {list.map((item) => {
                    const itemValue = listForm?.[item.name]
                    const optionSegmentTypeDisabled =
                      disabled && itemValue?.segment_type
                        ? optionSegmentType.map((option) => ({
                            ...option,
                            disabled: itemValue.segment_type !== option.value
                          }))
                        : optionSegmentType

                    return (
                      <StyledSegmentListRow key={item.key} className="list_row">
                        <StyledSegmentListCol className="list_col-left">
                          <StyledDescription>
                            <Form.Item
                              name={[item.name, 'operator']}
                              rules={segmentFormQueryRules.operator}
                              noStyle>
                              <StyledSelect
                                options={optionSegmentOperator}
                                size="small"
                                placeholder="Relation"
                                className="form_content relation"
                              />
                            </Form.Item>
                            of the following condition in
                          </StyledDescription>
                          <StyledSegmentListCondition>
                            <div
                              className={cn('relation_container', {
                                noPadding: Boolean(
                                  !itemValue?.conditions?.length
                                )
                              })}>
                              <Form.Item
                                name={[item.name, 'operator']}
                                rules={segmentFormQueryRules.operator}
                                noStyle>
                                <ConditionsRelation
                                  className="relation"
                                  disabled={disabled || disabledRunQuery}
                                />
                              </Form.Item>
                            </div>
                            <div className="condition_container">
                              <div className="condition_type_container flex-wrap">
                                <Form.Item
                                  name={[item.name, 'segment_type']}
                                  rules={segmentFormQueryRules.segment_type}
                                  className="form_item form_type">
                                  <StyledSelect
                                    options={optionSegmentTypeDisabled}
                                    size="small"
                                    placeholder="Type"
                                    disabled={
                                      Boolean(isCampaignWebpushPage) ||
                                      disabled ||
                                      disabledKeys?.includes('segment_type')
                                    }
                                  />
                                </Form.Item>
                                <Form.Item shouldUpdate noStyle>
                                  {listForm?.[item.name]?.segment_type ===
                                    SEGMENT_TYPE.EVENT && (
                                    <>
                                      <div className="item item_event">
                                        <Form.Item
                                          name={[item.name, 'event_id']}
                                          rules={segmentFormQueryRules.event_id}
                                          className="form_item form_event"
                                          noStyle>
                                          <SegmentFormQuerySelectEvent
                                            showSearch
                                            disabled={disabled}
                                            filterOption={filterOption as any}
                                            size="small"
                                            placeholder="Event"
                                            rootName="list"
                                            name={[item.name, 'event_id']}
                                          />
                                        </Form.Item>
                                      </div>
                                      {/* <Form.Item
                                        name={[item.name, 'submitted']}
                                        rules={
                                          segmentFormQueryRules.type_time_range
                                        }>
                                        <DatePicker.RangePicker
                                          size="small"
                                          format={FORMAT_DATE}
                                        />
                                      </Form.Item> */}
                                      {listForm?.[item.name]?.event_id !==
                                        segmentEventIdVisitSite && (
                                        <Form.Item
                                          name={[item.name, 'submitted']}
                                          rules={
                                            segmentFormQueryRules.type_time_range
                                          }>
                                          <DatePicker.RangePicker
                                            size="small"
                                            disabled={disabled}
                                            format={FORMAT_DATE}
                                          />
                                        </Form.Item>
                                      )}
                                    </>
                                  )}
                                </Form.Item>
                              </div>
                              {/*
															TODO : This field is for operator on item list
															<StyledDescription className="gray400 condition_operator_container">
                                with
                                <Form.Item
                                  name={[item.name, 'operator']}
                                  rules={segmentFormQueryRules.operator}>
                                  <StyledSelect
                                    value={operator}
                                    options={optionSegmentOperator}
                                    size="small"
                                    placeholder="Relation"
                                    className="form_content relation"
                                  />
                                </Form.Item>
                                of the following
                              </StyledDescription> */}
                              <SegmentCondition
                                disabled={disabled}
                                segment_type={itemValue?.segment_type || ''}
                                segment_status={segment_status}
                                event_id={itemValue?.event_id}
                                site_domain={sites?.map(({ domain }) => domain)}
                                rootName="list"
                                name={[item.name, 'conditions']}
                              />
                            </div>
                          </StyledSegmentListCondition>
                        </StyledSegmentListCol>
                        <StyledSegmentListCol className="list_col-right">
                          <StyledButton
                            disabled={disabledButtonDeleteList}
                            size="small"
                            icon={<Icon component={TrashBin} />}
                            onClick={() => listOperation.remove(item.name)}
                          />
                        </StyledSegmentListCol>
                      </StyledSegmentListRow>
                    )
                  })}

                  {!list.length && (
                    <StyledButton
                      size="small"
                      icon={<Icon component={Plus} />}
                      onClick={onAdd}
                      className="add_group_button">
                      Add group condition
                    </StyledButton>
                  )}
                </Fragment>
              )
            }}
          </Form.List>
          <StyledButton
            size="small"
            type="primary"
            onClick={form.submit}
            className="footer"
            disabled={disabledRunQuery || isQuery || disabled}>
            Run Query
          </StyledButton>
        </StyledFormQueryContainer>
      </Skeleton>
    </Form>
  )
}
