import { PreviewMessageProps } from '../..'
import {
  StyleBrowserContent,
  StyleBrowserWrapper,
  StyleMessage,
  StyleTitle
} from '../../index.styled'
import { HhgIcon, MessageBrowser, MessageBrowserLarge } from 'icons'
import { FC } from 'react'

const PreviewMacOS: FC<PreviewMessageProps> = ({
  preview,
  collapsed,
  domain
}) => {
  const renderImageExpand = () => {
    if (preview.image) {
      return (
        <div className="wrapper-image-icon expand-image">
          <img src={preview.image} alt="icon" className="image-icon" />
        </div>
      )
    }
    return <MessageBrowserLarge className="message-icon-large" />
  }

  const renderImageCollapsed = () => {
    if (preview.image) {
      return (
        <div className="wrapper-image-icon">
          <img src={preview.image} alt="icon" className="image-icon" />
        </div>
      )
    }
    return <MessageBrowser />
  }
  return (
    <StyleBrowserWrapper>
      {collapsed === 'expand' && renderImageExpand()}
      <StyleBrowserContent>
        <HhgIcon />
        <div className="message-infor">
          <StyleTitle>{preview.title}</StyleTitle>
          <div>{domain}</div>
          <StyleMessage>
            {preview.description
              .split('\n')
              .map((strMessage: string, idx: number) => {
                return <span key={idx}>{strMessage}</span>
              })}
          </StyleMessage>
        </div>
        {collapsed === 'collapsed' && renderImageCollapsed()}
      </StyleBrowserContent>
    </StyleBrowserWrapper>
  )
}

export default PreviewMacOS
