import Icon from '@ant-design/icons'
import { Modal } from 'antd'
import { Close, Warning } from 'icons'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

const StyledIcon = styled(Icon)`
  &.warning {
    margin-bottom: 8px;
    font-size: 80px;
    line-height: 0;
  }
`

const StyledTitle = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray800};
  font-size: 22px;
  font-weight: 700;
  line-height: 1.4;
`

const StyledName = styled.h3`
  min-height: 16px;
  margin-block: 8px;
  color: ${({ theme }) => theme?.colors?.gray600};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
`

const StyledDescription = styled.p`
  margin: 0;
  color: ${({ theme }) => theme?.colors?.gray600};
  font-size: 16px;
  line-height: 1.5;
`

const StyledModal = styled(Modal)`
  .ant-modal-close {
    top: 15px;
    right: 20px;
    .ant-modal-close-x {
      width: 24px;
      height: 24px;
    }
  }
  .ant-modal-body {
    padding: 32px;
    text-align: center;
  }
  .ant-modal-footer {
    display: flex;
    gap: 12px;
    padding: 0 32px 32px;
    border-top: none;
  }
`

export type ModalConfirmProps = {
  workflowName: string
  loading?: boolean
  onOk?: () => void
  onCancel?: () => void
}
export const ModalConfirmStopWorkflow = (props: ModalConfirmProps) => {
  const { workflowName, loading, onOk, onCancel } = props
  const [open, setOpen] = useState(!!workflowName)

  const closeIcon = <Icon component={Close} />

  useEffect(() => {
    setOpen(!!workflowName)
  }, [workflowName])

  return (
    <StyledModal
      width={480}
      open={open}
      closeIcon={closeIcon}
      onOk={onOk}
      okText="Stop Workflow"
      okButtonProps={{ block: true, size: 'large', loading }}
      onCancel={onCancel}
      cancelButtonProps={{ block: true, size: 'large' }}>
      <StyledIcon component={Warning} className="warning" />
      <StyledTitle>Are you sure that you want to stop workout?</StyledTitle>
      <StyledName>{workflowName}</StyledName>
      <StyledDescription>
        This will prevent new users from being added to the journey and remove
        existing users after they complete the step they‘re on.
      </StyledDescription>
    </StyledModal>
  )
}
