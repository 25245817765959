import {
  CampaignStepForm,
  campaignStepOverviewsProps,
  combineInitialValue
} from '../..'
import { CampaignStepDateTimePicker } from '../CampaignStepDateTimePicker'
import {
  StyledStepOverviews,
  StyledStepOverviewsCol,
  StyledStepOverviewsDivider,
  StyledStepOverviewsFormItem,
  StyledStepOverviewsRadio,
  StyledStepOverviewsRadioGroup,
  StyledStepOverviewsSkeleton,
  StyledStepOverviewsTitle
} from '../CampaignStepOverviews.styled'
import { Form, FormInstance, Input, InputNumber, Row, Select } from 'antd'
import { optionsTimezone } from 'components/atoms/select'
import { FORMAT_DATE_TIME_DAYJS } from 'constants/common'
import dayjs from 'dayjs'
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'

export type CampaignStepOverviewsLandingPageValue = {
  name?: string
  description?: string
  approach?: number
  time_zone?: string
  start_time?: string
  end_time?: string
  type?: 'ld_page_manual' | 'ld_page_schedule'
  template_type?: string
  client_ids?: number[]
  site_ids?: number[]
  flow_id?: number[]
}
const defaultCampaignStepOverviewValue: CampaignStepOverviewsLandingPageValue =
  {
    approach: 1,
    type: 'ld_page_manual'
  }
export type CampaignStepOverviewsLandingPageProps =
  CampaignStepForm<CampaignStepOverviewsLandingPageValue>
const CampaignStepOverviewsLandingPageWithoutRef = (
  props: CampaignStepOverviewsLandingPageProps,
  ref?: Ref<FormInstance<CampaignStepOverviewsLandingPageValue>>
) => {
  const {
    name,
    loading,
    disabled,
    template_type,
    initialValues: initialValuesProps,
    onFinish,
    onFinishFailed,
    onValuesChange
  } = props
  const [form] = Form.useForm()
  const type = Form.useWatch('type', form)
  const startTime = Form.useWatch('start_time', form)
  const endTime = Form.useWatch('end_time', form)

  const initialValues = useMemo(() => {
    return combineInitialValue(
      { ...initialValuesProps, template_type },
      defaultCampaignStepOverviewValue
    )
  }, [initialValuesProps, template_type])

  const handleFinish = onFinish
  const handleValuesChange = (valChanged: any, valsChanged: any) => {
    const newValChanged = { ...valsChanged }
    if (valChanged.hasOwnProperty('approach')) {
      newValChanged.type = null
      newValChanged.start_time = ''
      newValChanged.end_time = ''
    }
    if (valChanged.hasOwnProperty('start_time') && valChanged.start_time) {
      const startTimeArr = valChanged.start_time?.split(' ') || []
      startTimeArr[0] = startTimeArr[0] ? startTimeArr[0] : ''
      startTimeArr[1] = startTimeArr[1]
        ? startTimeArr[1]
        : startTimeArr[0]
        ? '00:00:00'
        : ''
      newValChanged.start_time = startTimeArr.join(' ')
      valChanged.start_time = startTimeArr.join(' ')
      newValChanged.days_of_week = null
    }

    if (valChanged.hasOwnProperty('end_time') && valChanged.end_time) {
      const endTimeArr = valChanged.end_time?.split(' ') || []
      endTimeArr[0] = endTimeArr[0] ? endTimeArr[0] : ''
      endTimeArr[1] = endTimeArr[1]
        ? endTimeArr[1]
        : endTimeArr[0]
        ? '23:59:59'
        : ''
      newValChanged.end_time = endTimeArr.join(' ')
      valChanged.end_time = endTimeArr.join(' ')
      newValChanged.days_of_week = null
    }
    form.setFieldsValue(newValChanged)
    onValuesChange?.(valChanged, newValChanged)
  }

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [initialValues])

  useImperativeHandle(ref, () => form, [form])

  return (
    <StyledStepOverviews>
      <StyledStepOverviewsTitle>Info</StyledStepOverviewsTitle>
      <Form
        form={form}
        name={name}
        initialValues={initialValues}
        onFinish={handleFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={handleValuesChange}
        colon={false}
        layout="horizontal"
        labelAlign="left"
        {...campaignStepOverviewsProps}>
        <StyledStepOverviewsFormItem name="template_type" hidden noStyle>
          <Input />
        </StyledStepOverviewsFormItem>
        <StyledStepOverviewsFormItem name="approach" hidden noStyle>
          <InputNumber />
        </StyledStepOverviewsFormItem>
        <StyledStepOverviewsSkeleton
          active
          className="form_item"
          loading={loading}>
          <StyledStepOverviewsFormItem
            required
            name="name"
            label="Campaign name"
            rules={[
              { required: true, message: 'Please input campaign name' },
              { max: 255, message: 'Maximum 255 characters for name' },
              {
                whitespace: true,
                message: 'Please input campaign name with characters'
              }
            ]}>
            <Input placeholder="Fill campaign name here" />
          </StyledStepOverviewsFormItem>
        </StyledStepOverviewsSkeleton>
        <StyledStepOverviewsSkeleton
          active
          className="form_item"
          loading={loading}>
          <StyledStepOverviewsFormItem name="description" label="Description">
            <Input.TextArea placeholder="Description" rows={3} />
          </StyledStepOverviewsFormItem>
        </StyledStepOverviewsSkeleton>
        <StyledStepOverviewsDivider className="divider" />
        <StyledStepOverviewsSkeleton
          active
          className="form_item"
          loading={loading}>
          <StyledStepOverviewsFormItem
            required
            name="time_zone"
            label="Timezone"
            rules={[{ required: true, message: 'Please select timezone' }]}>
            <Select
              allowClear
              disabled={disabled}
              placeholder="Select a timezone"
              options={optionsTimezone}
            />
          </StyledStepOverviewsFormItem>
        </StyledStepOverviewsSkeleton>
        <StyledStepOverviewsSkeleton
          active
          className="form_item"
          loading={loading}>
          <StyledStepOverviewsFormItem
            required
            name="type"
            label="Time frame"
            rules={[{ required: true, message: 'Please select time frame' }]}>
            <StyledStepOverviewsRadioGroup disabled={disabled}>
              <StyledStepOverviewsRadio value="ld_page_manual">
                <div className="label">Start now / On going to</div>
              </StyledStepOverviewsRadio>
              <StyledStepOverviewsRadio value="ld_page_schedule">
                <div className="label">Select range</div>
              </StyledStepOverviewsRadio>
            </StyledStepOverviewsRadioGroup>
          </StyledStepOverviewsFormItem>
        </StyledStepOverviewsSkeleton>
        <Form.Item
          shouldUpdate={(prev, next) => Boolean(prev.type !== next.type)}
          noStyle>
          {type === 'ld_page_schedule' && (
            <>
              <Row>
                <StyledStepOverviewsCol span={6} className="form_label" />
                <StyledStepOverviewsCol flex={1} className="form_input">
                  <StyledStepOverviewsSkeleton
                    active
                    className="title"
                    loading={loading}>
                    <StyledStepOverviewsFormItem
                      required
                      name="start_time"
                      rules={[
                        { required: true, message: 'Please input start time' }
                      ]}>
                      <CampaignStepDateTimePicker
                        dayProps={{
                          disabled,
                          placeholder: 'Start date',
                          disabledDate(date) {
                            const endDate = dayjs(
                              endTime,
                              FORMAT_DATE_TIME_DAYJS,
                              true
                            )
                            return (
                              date < dayjs().startOf('day') || date > endDate
                            )
                          }
                        }}
                        timeProps={{
                          disabled: true,
                          placeholder: 'Start time'
                        }}
                      />
                    </StyledStepOverviewsFormItem>
                  </StyledStepOverviewsSkeleton>
                </StyledStepOverviewsCol>
              </Row>
            </>
          )}
        </Form.Item>
        <Row>
          <StyledStepOverviewsCol span={6} className="form_label" />
          <StyledStepOverviewsCol flex={1} className="form_input">
            <StyledStepOverviewsSkeleton
              active
              className="title"
              loading={loading}>
              <StyledStepOverviewsFormItem
                required
                name="end_time"
                rules={[
                  {
                    required: true,
                    message: 'Please input end time'
                  }
                ]}>
                <CampaignStepDateTimePicker
                  dayProps={{
                    disabled,
                    placeholder: 'End date',
                    disabledDate(date) {
                      const startDate = dayjs(
                        startTime,
                        FORMAT_DATE_TIME_DAYJS,
                        true
                      )
                      return startDate.isValid()
                        ? date < startDate
                        : date < dayjs().startOf('day')
                    }
                  }}
                  timeProps={{
                    disabled: true,
                    placeholder: 'End time'
                  }}
                />
              </StyledStepOverviewsFormItem>
            </StyledStepOverviewsSkeleton>
          </StyledStepOverviewsCol>
        </Row>
      </Form>
    </StyledStepOverviews>
  )
}
export const CampaignStepOverviewsLandingPage = forwardRef(
  CampaignStepOverviewsLandingPageWithoutRef
)
