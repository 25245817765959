import { Result } from 'antd'
import styled from 'styled-components'

export const Styled404Screen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
`

const Page404 = () => {
  return (
    <Styled404Screen>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    </Styled404Screen>
  )
}

export default Page404
