import {
  StyleFinalUrl,
  StyleWrapperSystem,
  StyledActions,
  StyledPreviewBox,
  StyledWrap
} from './index.styled'
import PreviewAndroid from './previewType/android'
import PreviewMacOS from './previewType/mac'
import PreviewWindow from './previewType/window'
import { Select } from 'antd'
import { Android, Apple, Window } from 'icons'
import { OptionTypeSite } from 'interfaces'
import {
  WebPushSettingsForm,
  webPushUTMKeys
} from 'pages/templateBuilderCreate/webPush/helpers'
import { useCallback, useMemo, useState } from 'react'

const ComponentPreview = {
  ios: PreviewMacOS,
  android: PreviewAndroid,
  window: PreviewWindow
}

export type PreviewProps = {
  preview: WebPushSettingsForm
}

interface PreviewWebPushProps extends PreviewProps {
  sites: OptionTypeSite[]
}

type CollapsedType = 'collapsed' | 'expand'

export interface PreviewMessageProps extends PreviewProps {
  collapsed: CollapsedType
  domain: string
}

export function PreviewWebPush({ preview, sites }: PreviewWebPushProps) {
  const [type, setType] = useState<'ios' | 'android' | 'window'>('ios')
  const [collapsed, setCollapsed] = useState<CollapsedType>('collapsed')

  const handleOnChange = (value: CollapsedType) => {
    setCollapsed(value)
  }

  const finalUrl = useMemo(() => {
    try {
      const url = new URL(preview.custom_link_url)
      preview.utm_source
        ? url.searchParams.set('utm_source', preview.utm_source)
        : url.searchParams.delete('utm_source')
      preview.utm_medium
        ? url.searchParams.set('utm_medium', preview.utm_medium)
        : url.searchParams.delete('utm_medium')
      preview.utm_campaign
        ? url.searchParams.set('utm_campaign', preview.utm_campaign)
        : url.searchParams.delete('utm_campaign')
      preview.utm_content
        ? url.searchParams.set('utm_content', preview.utm_content)
        : url.searchParams.delete('utm_content')
      preview.utm_term
        ? url.searchParams.set('utm_term', preview.utm_term)
        : url.searchParams.delete('utm_term')
      return url.href
    } catch (err) {
      return preview.custom_link_url
    }
  }, [preview])

  const domainSite = useMemo(() => {
    const result = sites.find((site) => site.value === preview.site)
    if (!result) {
      return ''
    }
    return result.domain
  }, [preview.site, sites])

  const renderComponentPreview = useCallback(() => {
    if (!type) {
      return null
    }
    const ComponentRender = ComponentPreview[type]
    return (
      <ComponentRender
        collapsed={collapsed}
        preview={preview}
        domain={domainSite}
      />
    )
  }, [type, collapsed, domainSite, preview])

  return (
    <StyledWrap>
      <StyleWrapperSystem>
        <StyledActions>
          <li
            onClick={() => setType('ios')}
            className={type === 'ios' ? 'active' : ''}>
            <Apple />
          </li>
          <li
            onClick={() => setType('window')}
            className={type === 'window' ? 'active' : ''}>
            <Window />
          </li>
          <li
            onClick={() => setType('android')}
            className={type === 'android' ? 'active' : ''}>
            <Android />
          </li>
        </StyledActions>
        <Select
          placeholder="Collapsed View"
          onChange={handleOnChange}
          value={collapsed}
          options={[
            {
              label: 'Collapsed Type',
              value: 'collapsed'
            },
            {
              label: 'Expand Type',
              value: 'expand'
            }
          ]}
          className="select-view"
        />
      </StyleWrapperSystem>
      <StyledPreviewBox>{renderComponentPreview()}</StyledPreviewBox>
      <StyleFinalUrl>
        <p className="title">Final URL</p>
        <p className="url">{finalUrl}</p>
      </StyleFinalUrl>
    </StyledWrap>
  )
}
