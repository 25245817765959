import { Button } from 'antd'
import cls from 'classnames'
import { useCallback, useState } from 'react'

const SETTING_TONE = [
  'auto',
  'formal',
  'casual',
  'friendly',
  'professional',
  'funny',
  'witty',
  'relax'
]
const SETTING_LENGTH = ['auto', 'short', 'medium', 'long']
const SETTING_LANGUAGE = [
  'auto',
  'english',
  'bahasa',
  'chinese',
  'vietnamese',
  'filipino'
]

export function useCopilotSettings() {
  const [openSettings, setOpenSettings] = useState(false)

  const [settingTone, setSettingTone] = useState('auto')
  const [settingLength, setSettingLength] = useState('auto')
  const [settingLanguage, setSettingLanguage] = useState('auto')

  function handleClickTone(evt: any) {
    const tone = evt.target.textContent.toLowerCase()
    setSettingTone(tone)
  }

  function handleClickLength(evt: any) {
    const length = evt.target.textContent.toLowerCase()
    setSettingLength(length)
  }

  function handleClickLanguage(evt: any) {
    const lang = evt.target.textContent.toLowerCase()
    setSettingLanguage(lang)
  }

  const renderSettingTone = useCallback(() => {
    return (
      <>
        <span className="setting-title">Tone</span>
        <div className="setting-group">
          {SETTING_TONE.map((tone) => (
            <Button
              shape="round"
              size="small"
              key={tone}
              className={cls({ active: tone === settingTone })}
              onClick={handleClickTone}>
              {tone}
            </Button>
          ))}
        </div>
      </>
    )
  }, [settingTone])

  const renderSettingLength = useCallback(() => {
    return (
      <>
        <span className="setting-title">Length</span>
        <div className="setting-group">
          {SETTING_LENGTH.map((len) => (
            <Button
              shape="round"
              size="small"
              key={len}
              className={cls({ active: len === settingLength })}
              onClick={handleClickLength}>
              {len}
            </Button>
          ))}
        </div>
      </>
    )
  }, [settingLength])

  const renderSettingLanguage = useCallback(() => {
    return (
      <>
        <span className="setting-title">Language</span>
        <div className="setting-group">
          {SETTING_LANGUAGE.map((lang) => (
            <Button
              shape="round"
              size="small"
              key={lang}
              className={cls({ active: lang === settingLanguage })}
              onClick={handleClickLanguage}>
              {lang}
            </Button>
          ))}
        </div>
      </>
    )
  }, [settingLanguage])

  return {
    openSettings,

    settingTone,
    settingLength,
    settingLanguage,

    setOpenSettings,
    setSettingTone,
    setSettingLength,
    setSettingLanguage,

    handleClickTone,
    handleClickLength,
    handleClickLanguage,

    renderSettingTone,
    renderSettingLength,
    renderSettingLanguage
  }
}
