import {
  formatAutoCampaignCreate,
  formatConditionCreate,
  formatValueFormCampaign
} from './CampaignAutomationCreatePage.helpers'
import { StyleContainer } from './CampaignAutomationCreatePage.styled'
import { notification } from 'antd'
import { MainHeader, menuRoutes } from 'components/molecules'
import {
  AutoCampaignHeader,
  CampaignFormAutomation
} from 'components/molecules/campaign'
import { HandleRef } from 'components/molecules/form/formSettingEmail'
import { CountUserEvent } from 'interfaces/event'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CampaignServices } from 'services/campaign'
import { EventServices } from 'services/event'

const CampaignAutomationCreatePage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [loadingQuery, setLoadingQuery] = useState(false)
  const [numberUsers, setNumberUsers] = useState(0)
  const [settingValue, setSettingValue] = useState<any>({
    name: '',
    description: '',
    approach: null,
    time_zone: null,
    start_date: '',
    start_time: '',
    end_date: '',
    end_time: '',
    sites: '',
    recurring: false,
    replication: 'Daily',
    schedule_on: [],
    trigger_time: '',
    status: '',
    groups: [],
    templates: [
      {
        template: null
      }
    ]
  })
  const formRef = useRef<HandleRef | undefined>()
  const handleOnSave = () => {
    formRef &&
      formRef.current?.form.validateFields().then(async (res) => {
        const requestBody = formatAutoCampaignCreate(res) as any
        setLoading(true)
        try {
          let response
          if (id) {
            response = await CampaignServices.updateCampaign(id, requestBody)
          } else {
            response = await CampaignServices.createCampaign(requestBody)
          }
          if (!response?.data) {
            const message = response._message ? response._message : ''
            throw new Error(message)
          }
          setLoading(false)
          notification.success({
            message: 'Automation Campaign saved successfully'
          })
          navigate(menuRoutes.campaign)
        } catch (error: any) {
          const message = error?.message
            ? error?.message
            : 'Something went wrong. Please try again!'
          setLoading(false)
          notification.error({
            message: message
          })
        }
      })
  }
  const fetchCountUser = async (data: CountUserEvent) => {
    try {
      setLoadingQuery(true)
      const res = await EventServices.countUserByEvent(data)
      if (!res.data) {
        throw new Error('')
      }
      setNumberUsers(res.data.data)
      setLoadingQuery(false)
    } catch (error) {
      notification.error({
        message: 'Something went wrong. Please try again!'
      })
      setLoadingQuery(false)
    }
  }
  const handleRunQuery = async () => {
    const values = formRef.current?.form.getFieldsValue()
    if (!values.sites) {
      notification.error({
        message: 'Please choose site before run query user.'
      })
      return
    }
    if (!values.groups.length) {
      notification.error({
        message: 'Please add target audience condition before run query user.'
      })
      return
    }
    const conditionBody = formatConditionCreate(values.groups)
    const resBodyQuery = {
      ...conditionBody[0],
      site_id: values.sites
    }

    fetchCountUser(resBodyQuery)
  }
  useEffect(() => {
    ;(async function () {
      if (id) {
        try {
          const response = await CampaignServices.getById(id)
          if (!response.data) {
            throw new Error('')
          }
          const campaign = response.data
          const newValue = formatValueFormCampaign(campaign)
          setSettingValue(newValue)
          formRef && formRef.current?.form.setFieldsValue(newValue)
          // fetch to count user
          const conditionBody = formatConditionCreate(newValue.groups)
          const resBodyQuery = {
            ...conditionBody[0],
            site_id: newValue.sites
          }
          fetchCountUser(resBodyQuery)
        } catch (error) {
          notification.error({
            message: 'Something went wrong. Please try again!'
          })
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <MainHeader
      headerComponent={
        <AutoCampaignHeader
          onSave={handleOnSave}
          loading={loading}
          title={id ? 'Edit Automation Campaign' : 'New Automation Campaign'}
        />
      }
      stickyHeader
      bgColor="#F7F9FC">
      <StyleContainer>
        <CampaignFormAutomation
          ref={formRef}
          settingValue={settingValue}
          loadingQuery={loadingQuery}
          numberUsers={numberUsers}
          setSettingValue={setSettingValue}
          handleRunQuery={handleRunQuery}
        />
      </StyleContainer>
    </MainHeader>
  )
}

export default CampaignAutomationCreatePage
