import { CampaignMessagingInsightFilterValue } from '../CampaignMessagingInsightFilter'
import {
  CampaignMessagingInsightTabTimeSeriesState,
  TIME_SERIES_CHART_KEY,
  TIME_SERIES_CHART_TABS,
  getCampaignMessagingInsightTimeSeries
} from './CampaignMessagingInsightTabTimeSeries.helpers'
import { Spin, Tabs } from 'antd'
import cn from 'classnames'
import { HighchartsLine } from 'components/atoms/Highcharts'
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import styled from 'styled-components'

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    margin-bottom: -1px;
  }
`
export type CampaignMessagingInsightTabTimeSeriesRef = {
  refresh?: (value: CampaignMessagingInsightFilterValue) => void
}
export type CampaignMessagingInsightTabTimeSeriesProps = {
  params?: CampaignMessagingInsightFilterValue
}
const CampaignMessagingInsightTabTimeSeriesWithoutRef = (
  props: CampaignMessagingInsightTabTimeSeriesProps,
  ref?: Ref<CampaignMessagingInsightTabTimeSeriesRef>
) => {
  const { params } = props
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<Highcharts.Options>({})
  const [state, setState] =
    useState<CampaignMessagingInsightTabTimeSeriesState>({
      chart_type: TIME_SERIES_CHART_KEY.combine_rate
    })

  const refresh = useCallback(
    async (
      input: CampaignMessagingInsightFilterValue &
        CampaignMessagingInsightTabTimeSeriesState
    ) => {
      setLoading(true)
      const { data, errors } = await getCampaignMessagingInsightTimeSeries({
        ...params,
        chart_type: state.chart_type,
        ...input
      })
      if (!!errors.length) {
        setLoading(false)
        return
      }
      setOptions(data)
      setLoading(false)
    },
    [params, state.chart_type]
  )

  const handleChange = (changedTab?: string) => {
    const newState = { ...state, chart_type: changedTab }
    setState(newState)
    refresh({ ...params, ...newState })
  }

  useEffect(() => {
    refresh({ ...params, ...state })
  }, [])

  useImperativeHandle(ref, () => ({ refresh }), [refresh])

  return (
    <div className="timeSeries bg-white border border-solid border-neutral100 rounded-lg">
      <div
        className={cn(
          'timeSeries--header px-6 pt-4',
          'flex items-end justify-between',
          'border-0 border-solid border-b-[1px] border-neutral100'
        )}>
        <h2 className="mb-4 text-base font-bold">Clicks / Opens</h2>
        <StyledTabs items={TIME_SERIES_CHART_TABS} onChange={handleChange} />
      </div>
      <div className="timeSeries--body pt-8">
        <Spin spinning={loading}>
          <HighchartsLine
            options={options}
            emptyProps={{
              iconClassName: 'text-[80px]',
              description: 'No data',
              className:
                'py-28 min-h-[450px] flex flex-col items-center justify-center'
            }}
          />
        </Spin>
      </div>
    </div>
  )
}

export const CampaignMessagingInsightTabTimeSeries = forwardRef(
  CampaignMessagingInsightTabTimeSeriesWithoutRef
)
