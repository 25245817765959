export const MESSAGE_TYPE = {
  MARKETING: 'Marketing Message',
  TRANSACTIONAL: 'Transactional Message',
  INTERNAL: 'Internal Message',
  PUSH_MARKETING: 'Push marketing message'
}

export type TemplateBuilderListParams = {
  page: number
  per_page: number
  type?: string
  keyword?: string
  status?: string
  site?: number | string
  message_type?: string
  types?: string[]

  tag?: string
  client_id?: number
}
