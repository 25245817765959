import { ANALYTIC_TEMPLATE_PERIOD_OPTIONS } from 'components/organisms/analytic/AnalyticTemplateDetail/AnalyticTemplateDetail.helpers'
import { AnalyticTemplateWidgetItem } from 'interfaces/analytic'
import { ReactNode, useMemo } from 'react'

export const LOCAL_STORAGE_DEBUG_ANALYTIC = 'DEBUG_ANALYTICS'

export function getAnalyticWidgetItemDebug() {
  return Boolean(localStorage.getItem(LOCAL_STORAGE_DEBUG_ANALYTIC))
}

export type AnalyticWidgetItemProps = {
  loading?: boolean
  data?: any
  timer?: ReactNode
  item?: AnalyticTemplateWidgetItem
  onChangeItem?: (item: AnalyticTemplateWidgetItem) => void
  segmentSites?: { id: number; name: string }[]
  downloadImage?: boolean
}

export const WIDGET_ITEM_KEY = {
  EMAIL: 'email',
  PHONE: 'phone',
  APP: 'app_token',
  WEB: 'web_token',
  WHATSAPP: 'whatsapp',
  MALE: 'male',
  FEMALE: 'female',
  UNKNOWN: 'unknown',
  SEC: 'sec'
}

export const WIDGET_ITEM_LABEL = {
  [WIDGET_ITEM_KEY.EMAIL]: 'Email',
  [WIDGET_ITEM_KEY.WEB]: 'Web Push ID',
  [WIDGET_ITEM_KEY.PHONE]: 'Phone',
  [WIDGET_ITEM_KEY.APP]: 'Mobile App',
  [WIDGET_ITEM_KEY.MALE]: 'Male',
  [WIDGET_ITEM_KEY.FEMALE]: 'Female',
  [WIDGET_ITEM_KEY.UNKNOWN]: 'Unknown',
  [WIDGET_ITEM_KEY.SEC]: 'SEC',
  [WIDGET_ITEM_KEY.WHATSAPP]: 'WhatsApp'
}

export const usePeriodLabel = (period?: string | number) => {
  return useMemo(() => {
    if (!period) {
      return ''
    }
    const periodOption = ANALYTIC_TEMPLATE_PERIOD_OPTIONS.find(
      ({ value }) => value === period
    )
    return periodOption?.label || ''
  }, [period])
}

export const WIDGET_ITEM_HIGHCHARTS_EMPTY_PROPS = {
  className: 'h-full flex-1 min-h-[400px] flex flex-col justify-center',
  iconClassName: 'text-[80px]'
}
