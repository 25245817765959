export const operatorLabel = {
  lt: 'Less than (<)',
  gt: 'Greater than (>)',
  eq: 'Equals (=)',
  n_eq: 'Does not equal (!=)',
  lt_eq: 'Less than or equal to (<=)',
  gt_eq: 'Greater than or equal to (>=)',
  bt: 'Is between',
  in: 'In',
  n_in: 'Not in',
  cont: 'Contains',
  n_cont: 'Does not contain'
}

export enum operatorValue {
  lt = 0,
  gt = 1,
  eq = 2,
  n_eq = 3,
  lt_eq = 4,
  gt_eq = 5,
  bt = 6,
  in = 7,
  n_in = 8,
  cont = 9,
  n_cont = 10
}

export enum operatorKeyEvent {
  lt = '<',
  gt = '>',
  eq = '=',
  n_eq = '!=',
  lt_eq = '<=',
  gt_eq = '>='
}

export type OperatorKeysEvent = keyof typeof operatorKeyEvent

export interface ConditionDataType {
  id: string
  name: string
  key: string
  description: string
  type: string
  dataType: string
  status: string
  value: string[]
  createdBy: string
  updatedBy: string
  site?: string
}

export enum ConditionValueOperator {
  LESS = 0,
  GREATER = 1,
  EQUAL = 2,
  NOT_EQUAL = 3,
  LESS_OR_EQUAL = 4,
  GREATER_OR_EQUAL = 5,
  BETWEEN = 6,
  IN = 7,
  NOT_IN = 8,
  CONTAINS = 9,
  DOES_NOT_CONTAINS = 10
}

export type OperatorKeys = keyof typeof operatorLabel

export const optionsConditionBoolean = [
  {
    label: 'True',
    value: 'true'
  },
  {
    label: 'False',
    value: 'false'
  }
]

// UI show All to get all segment by condition
// Back end use value OR to get all segment by condition
export const optionsConditionRelation = [
  {
    label: 'All',
    value: 'OR',
    disabled: true
  },
  {
    label: 'At least once',
    value: 'AND'
  }
]

export const optionsConditionType = [
  {
    label: 'Attribute',
    value: 'attribute'
  },
  {
    label: 'Event',
    value: 'event',
    disabled: true
  }
]

export const attributeDataType = {
  API: 2,
  BOOLEAN: 3,
  TEXT: 0,
  NUMBER: 1,
  DATE: 4,
  CHECKBOX: 5
}

export const attributeFieldType = {
  ONE: 2,
  MANY: 1
}

export const eventDataType = {
  TEXT: 'text',
  NUMBER: 'number'
}

export const CONDITION_TYPE = {
  ATTRIBUTE: 'condition',
  EVENT: 'automation'
}

export const EVENT_PERFORM = {
  HAS: 'has',
  HAS_NOT: 'has_not'
}

export type ConditionItem = {
  key: string
  data_type: number
  operator: number
  value: string[]
}
