import { Divider } from 'antd'
import styled from 'styled-components'

export const StyledWrap = styled.div`
  padding: 24px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.neutral100};
`

export const StyleWrapperFormItem = styled.div`
  display: flex;
  gap: 15px;
  .ant-form-item {
    flex: 1;

    &.push-open-action {
      flex: 1;
    }

    &.deep-link {
      max-width: 350px;
      flex: 1 1 350px;
    }
  }
  .ant-btn {
    margin-top: 28px;
  }
`

export const StyleTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.gray800};
  margin-bottom: 10px;
  svg {
    cursor: pointer;
  }
`

export const StyleDivider = styled(Divider)`
  margin: 30px 0;
`
