import HeaderNotification from '../headerNotification'
import cn from 'classnames'
import { ResultCustom } from 'components/atoms/result/ResultCustom'
import { useCheckAuth } from 'hooks/useCheckAuth'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

const StyledHeader = styled.div`
  height: 56px;
  &.stickyHeader {
    position: sticky;
    z-index: 1000;
    top: 0;
    background-color: #fff;
    box-shadow: 0px 0px 1px 0px #0000000a, 0px 2px 8px 0px #0000000a;
  }
`

const StyledBody = styled.div``

const StyledContent = styled.div`
  background-color: white;
`

export type HeaderBarProps = {
  headerTitle?: string
  headerDescription?: string
  headerComponent?: ReactNode
  stickyHeader?: boolean
  bgColor?: string
  className?: string
  bodyClassName?: string
  onBack?: () => void
  sites?: string[]
  roles?: string[]
  permissions?: string[]
}

const MainHeader: FC<HeaderBarProps> = ({
  headerTitle,
  headerDescription,
  headerComponent,
  stickyHeader,
  bgColor = 'white',
  className,
  bodyClassName: contentClassName,
  children,
  onBack,
  sites,
  roles,
  permissions,
  ...props
}) => {
  const isPermission = useCheckAuth({ sites, roles, permissions })
  return (
    <StyledContent
      className={cn('layout-main-header', className)}
      style={{ backgroundColor: bgColor }}
      {...props}>
      <StyledHeader className={cn('main--header', { stickyHeader })}>
        {headerComponent ? (
          headerComponent
        ) : (
          <HeaderNotification
            onBack={onBack}
            title={headerTitle}
            description={headerDescription}
          />
        )}
      </StyledHeader>
      <StyledBody
        className={cn('layout-main-body main--body', contentClassName)}>
        {isPermission && children}
        {!isPermission && (
          <div>
            <ResultCustom status="403" />
          </div>
        )}
      </StyledBody>
    </StyledContent>
  )
}

export default MainHeader
