import { Pagination, Select } from 'antd'
import styled from 'styled-components'
import { cssScrollBar } from 'styles'

export const StyledSelect = styled(Select)`
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      background-color: transparent;
    }
    .ant-select-selection-item {
      font-weight: 600;
    }
  }
`

export const StyledPagination = styled(Pagination)`
  font-weight: 600;
  .ant-pagination-item {
    border-color: transparent;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      background-color: transparent;
    }
  }
  .ant-pagination-disabled {
    .ant-pagination-item-link {
      border-color: transparent;
      background-color: ${({ theme }) => theme?.colors?.gray100 || '#F2F2F2'};
      color: ${({ theme }) => theme?.colors?.gray300 || '#BFBFBF'};
    }
  }
  .ant-pagination-options-quick-jumper {
    input {
      height: 32px;
    }
  }
`

export const StyledPageSize = styled.div`
  display: inline-flex;
  align-items: center;
  > span {
    display: inline-block;
    margin-right: 8px;
    color: ${({ theme }) => theme?.colors?.gray800 || '#262626'};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.57;
  }
`

export const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledTable = styled.div`
  max-width: 100%;
  min-height: 0;
  flex: 1;

  .ant-table-wrapper {
    height: 100%;
  }
  .ant-table-body {
    ${cssScrollBar}
  }
  .ant-table-thead > tr > th {
    &:before {
      content: none !important;
    }
    &:first-child {
      padding-left: 24px;
    }
  }
  .ant-table-tbody > tr > td {
    &:first-child {
      padding-left: 24px;
    }
  }
  .ant-table-cell {
    vertical-align: middle;
  }
  .ant-table-title {
    padding: 16px 24px;
    background-color: ${({ theme }) => theme?.colors?.neutral50 || '#F7F9FC'};
  }
  .ant-table-footer {
    padding: 16px 24px;
  }

  .ant-table {
    background-color: transparent;
    .ant-table-title {
      padding: 0 0 16px;
    }
    .ant-table-container {
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme?.colors?.neutral100};

      .ant-table-header {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .ant-table-body {
        tr:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
    .ant-table-footer {
      padding: 16px 0 0;
    }
  }
`
