import { SegmentConditionValueProps } from './SegmentConditionValue'
import { StyledSegmentConditionValueSelect } from './SegmentConditionValue.styled'
import { SEGMENT_CONDITION_OPERATOR } from 'constants/segment'

export type SegmentConditionValueARRAYProps = Omit<
  SegmentConditionValueProps<string[]>,
  'type'
>

export const SegmentConditionValueARRAY = (
  props: SegmentConditionValueARRAYProps
) => {
  const { site, operator, options, ...restProps } = props

  if (
    [
      SEGMENT_CONDITION_OPERATOR.EMPTY,
      SEGMENT_CONDITION_OPERATOR.NOT_EMPTY
    ].some((key) => key === operator)
  ) {
    return null
  }

  return (
    <StyledSegmentConditionValueSelect<any>
      showSearch
      options={options}
      mode={operator === '=' ? 'single' : 'multiple'}
      maxTagCount={10}
      placeholder="Value"
      allowClear
      {...restProps}
    />
  )
}
