import { Checkbox } from 'antd'
import styled from 'styled-components'

const StyleContainer = styled.div`
  .ant-checkbox-wrapper {
    align-items: center;
  }
`

const StyleWrapperContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`

const StyleWrapperIcon = styled.div`
  width: 40px;
  height: 40px;

  img {
    border-radius: 50%;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const StyleWrapperText = styled.div`
  margin-left: 15px;
`

const StyleName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
`

const StyleEmail = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #595959;
`

export type ClientType = {
  id: number
  icon: string
  name: string
  email: string
}

interface CheckboxClientProps {
  client: ClientType
  isChecked: boolean
  onChange: (e: any, id: number) => void
}

export const CheckboxClient = ({
  client,
  isChecked,
  onChange
}: CheckboxClientProps) => {
  return (
    <StyleContainer>
      <Checkbox onChange={(e) => onChange(e, client.id)} checked={isChecked}>
        <StyleWrapperContent>
          <StyleWrapperIcon>
            <img src={client.icon} alt={client.name} />
          </StyleWrapperIcon>
          <StyleWrapperText>
            <StyleName>{client.name}</StyleName>
            <StyleEmail>{client.email}</StyleEmail>
          </StyleWrapperText>
        </StyleWrapperContent>
      </Checkbox>
    </StyleContainer>
  )
}
