export interface ConditionDataType {
  id: string
  name: string
  key: string
  description: string
  type: string
  dataType: string
  status: string
  value: string[]
  createdBy: string
  updatedBy: string
  site?: string
}

export const operationDataType = {
  API: ['eq', 'in', 'n_in'],
  BOOLEAN: ['eq'],
  TEXT: ['eq', 'n_eq', 'cont', 'n_cont'],
  NUMBER: ['lt', 'gt', 'eq', 'n_eq', 'lt_eq', 'gt_eq', 'bt'],
  DATE: ['lt', 'gt', 'eq', 'n_eq', 'lt_eq', 'gt_eq', 'bt'],
  CHECKBOX: ['eq']
}
