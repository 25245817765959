import {
  StyleAnalyticItem,
  StyleContainer,
  StyleSegmentAnalyticCard,
  StyleWrapperAnalytic,
  StyleWrapperListItem
} from './SegmentReachable.styled'
import Skeleton from 'antd/lib/skeleton'
import { userReachableDefault } from 'constants/segment'
import { ReachableDataType, userReachableItem } from 'interfaces/segment'
import { FC, useMemo } from 'react'
import { formatNumberToCurrency } from 'utils'

interface SegmentReachableProps {
  loading?: boolean
  numOfUsers: number
  reachableData: Partial<ReachableDataType>
}

export const SegmentReachable: FC<SegmentReachableProps> = ({
  reachableData,
  loading
}) => {
  const userReachable = useMemo(() => {
    return userReachableDefault.map((item) => {
      if (item.key in reachableData) {
        return {
          ...item,
          value: Number(reachableData[item.key as keyof typeof reachableData])
        }
      }
      return item
    }) as userReachableItem[]
  }, [reachableData])
  return (
    <StyleContainer>
      <StyleWrapperAnalytic className="total-user">
        <div className="title">Result</div>
        <StyleSegmentAnalyticCard className="analytic-card card-user">
          <StyleAnalyticItem>
            <div className="label">User count</div>
            <div className="item-value">
              {loading && <Skeleton paragraph={{ rows: 0 }} active />}
              {!loading &&
                (Number(reachableData.TotalCount) < 0
                  ? 0
                  : formatNumberToCurrency(Number(reachableData.TotalCount)))}
            </div>
          </StyleAnalyticItem>
        </StyleSegmentAnalyticCard>
      </StyleWrapperAnalytic>
      <StyleWrapperAnalytic className="reachability-user">
        <div className="title">Reachability by channel</div>
        <StyleSegmentAnalyticCard className="analytic-card">
          <StyleWrapperListItem>
            {userReachable.map((item) => {
              return (
                <StyleAnalyticItem key={item.key}>
                  <div className="label">{item.label}</div>
                  <div className="item-value">
                    {loading && (
                      <Skeleton paragraph={{ rows: 0 }} active key={item.key} />
                    )}
                    {!loading &&
                      (item.value < 0 ? 0 : formatNumberToCurrency(item.value))}
                  </div>
                </StyleAnalyticItem>
              )
            })}
          </StyleWrapperListItem>
        </StyleSegmentAnalyticCard>
      </StyleWrapperAnalytic>
    </StyleContainer>
  )
}
