import { Button, Col, Row, Space, Tabs } from 'antd'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  &.btnFilter {
    &.expaned {
      border-color: ${({ theme }) => theme?.colors?.primary || '#2B62CD'};
      background-color: ${({ theme }) => theme?.colors?.primary || '#2B62CD'};
      color: #fff;
    }
  }
`

export const StyledTabs = styled(Tabs)``

export const StyledSpace = styled(Space)``

export const StyledCol = styled(Col)`
  &.colLeft {
    .ant-tabs-top {
      .ant-tabs-nav {
        margin: 0;
      }
      .ant-tabs-tab {
        color: ${({ theme }) => theme?.colors?.gray500 || ''};
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
      }
    }
  }
  &.colRight {
    display: flex;
    justify-content: flex-end;
  }
`

export const StyledRow = styled(Row)``

export const StyledSegmentHeader = styled.div``
