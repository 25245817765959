import {
  PickerDateProps,
  PickerTimeProps
} from 'antd/lib/date-picker/generatePicker'
import { DatePicker } from 'components/atoms/datePicker'
import { FORMAT_DATE_DAYJS_API, FORMAT_TIME } from 'constants/common'
import dayjs, { Dayjs } from 'dayjs'
import { Ref, forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'

const StyledCol = styled.div`
  min-width: 190px;
`

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const datetimePickerPropsToState = (value?: string | null) => {
  if (!value) {
    return [null, null]
  }

  const valueArray = value.split(' ')
  if (valueArray?.length >= 2) {
    const dateValue = dayjs(valueArray?.[0], FORMAT_DATE_DAYJS_API, true)
    const timeValue = dayjs(valueArray?.[1], FORMAT_TIME, true)
    return [
      dateValue.isValid() ? dateValue : null,
      timeValue.isValid() ? timeValue : null
    ]
  }

  return [null, null]
}

const datetimePickerStateToProps = (value: (Dayjs | null)[]) => {
  if (value.every((val) => Boolean(!val))) {
    return null
  }

  if (value.length) {
    const dateFormat = value?.[0]?.format(FORMAT_DATE_DAYJS_API) || ''
    const timeFormat = value?.[1]?.format(FORMAT_TIME) || ''
    return `${dateFormat}${timeFormat ? ` ${timeFormat}` : ''}`
  }

  return null
}

export type CampaignStepDateTimePickerProps = {
  defaultValue?: string | null
  value?: string | null
  onChange?: (value: string | null) => void
  dayProps?: Omit<PickerDateProps<Dayjs>, 'onChange' | 'picker'>
  timeProps?: Omit<PickerTimeProps<Dayjs>, 'onChange' | 'picker'>
}

const CampaignStepDateTimePickerWithoutRef = (
  props: CampaignStepDateTimePickerProps,
  ref?: Ref<HTMLDivElement>
) => {
  const {
    defaultValue,
    value: valueProps,
    onChange,
    dayProps,
    timeProps
  } = props
  const [value, setValue] = useState<(Dayjs | null)[]>(
    datetimePickerPropsToState(defaultValue || valueProps)
  )

  const disabledDate = !!dayProps?.disabledDate
    ? dayProps?.disabledDate
    : (date: Dayjs) => {
        return date < dayjs().startOf('day')
      }

  const handleChange = (key: number) => {
    return (val: Dayjs | null) => {
      const newValue = [...value]
      newValue[key] = val
      if (key === 0 && !val) {
        newValue[0] = null
        newValue[1] = null
      }
      setValue(newValue)
      onChange?.(datetimePickerStateToProps(newValue))
    }
  }

  useEffect(() => {
    setValue(datetimePickerPropsToState(valueProps))
  }, [valueProps])

  return (
    <StyledRow ref={ref}>
      <StyledCol>
        <DatePicker
          {...dayProps}
          disabledDate={disabledDate}
          value={value?.[0] || null}
          onChange={handleChange(0)}
        />
      </StyledCol>
      <StyledCol>
        <DatePicker.TimePicker
          {...timeProps}
          value={value?.[1] || null}
          onChange={handleChange(1)}
        />
      </StyledCol>
    </StyledRow>
  )
}

export const CampaignStepDateTimePicker = forwardRef(
  CampaignStepDateTimePickerWithoutRef
)
