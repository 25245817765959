import { SelectProps } from '..'
import { StyledSelect } from './index.styled'
import { useEventOptions } from 'hooks/useSegment'

export type SelectEventProps = SelectProps & {
  site?: string | number
}
export const SelectEvent = (props: SelectEventProps) => {
  const { options: optionsProps, ...restProps } = props
  const eventOptions = useEventOptions()
  const options = optionsProps || eventOptions

  return <StyledSelect options={options} {...restProps} />
}
