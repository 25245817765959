import {
  AnalyticWidgetItemProps,
  WIDGET_ITEM_HIGHCHARTS_EMPTY_PROPS,
  usePeriodLabel
} from './AnalyticWidgetItem.helpers'
import { HighchartsLine } from 'components/atoms/Highcharts'
import { theme } from 'constants/theme'
import { FC } from 'react'
import { mathRound } from 'utils'

export const AnalyticWidgetItemDeviceUsage: FC<AnalyticWidgetItemProps> = (
  props
) => {
  const { data, item } = props
  const label = usePeriodLabel(item?.query?.period)
  const highchartsOptions = formatDeviceUsage(data)

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between">
        <h4 className="period mb-0 text-gray600">{label}</h4>
      </div>
      <div className="flex-1 min-h-0">
        <HighchartsLine
          options={highchartsOptions}
          className="h-full"
          emptyProps={WIDGET_ITEM_HIGHCHARTS_EMPTY_PROPS}
        />
      </div>
    </div>
  )
}

export function formatDeviceUsage(data: any): Highcharts.Options {
  const { device_category, mobile_device_branding } = data || {}
  const highchartsOptions: Highcharts.Options = {
    chart: {},
    xAxis: {
      labels: { enabled: true },
      lineWidth: 1,
      tickWidth: 1,
      tickmarkPlacement: 'on',
      crosshair: true
    },
    yAxis: {
      labels: { enabled: true },
      lineWidth: 1,
      gridLineDashStyle: 'Dot'
    },
    series: [],
    plotOptions: {
      bar: {
        pointWidth: 16,
        color: theme.colors.pink600,
        tooltip: {
          headerFormat: '',
          pointFormat: '{point.name}: <strong>{point.y}</strong>'
        }
      },
      pie: {
        size: 220,
        center: [-270, 110],
        innerSize: '70%',
        tooltip: {
          headerFormat: '',
          pointFormat: '{point.name}: <strong>{point.y}%</strong>'
        }
      }
    }
  }

  try {
    const circleCategories = Object.keys(device_category).filter(
      (branding) => !['other', 'Other'].includes(branding)
    )
    const circleTotal = circleCategories.reduce(
      (total: number, key: string) =>
        total + parseInt(device_category[key] || '0'),
      0
    )
    const circleSeriesData: Array<[string, number]> = []
    for (let index = 0; index < circleCategories.length; index++) {
      const key = circleCategories[index]
      const value = parseInt(device_category[key] || '0')
      if (index === circleCategories.length - 1) {
        const restTotal = circleSeriesData.reduce(
          (sum: number, b: any) => sum + b[1],
          0
        )
        circleSeriesData.push([key, 100 - restTotal])
        continue
      }
      circleSeriesData.push([key, mathRound((value / circleTotal) * 100, 0)])
    }

    const barCategories = Object.keys(mobile_device_branding).filter(
      (branding) => !['Other'].includes(branding) // HHS-2087
    )
    const barSeriesData = barCategories
      .map((branding) => {
        return [branding, parseInt(mobile_device_branding[branding] || '0')]
      })
      .sort(([_, yA], [__, yB]) => {
        return yA > yB ? -1 : 1
      })
      .slice(0, 8)

    if (circleSeriesData.length) {
      highchartsOptions.series?.push({
        type: 'pie',
        data: circleSeriesData,
        showInLegend: true,
        colors: [
          theme.colors.blue600,
          theme.colors.pink600,
          theme.colors.yellow600,
          theme.colors.redViolet600,
          theme.colors.green600,
          theme.colors.violet600
        ] as string[]
      })
      highchartsOptions.chart = { ...highchartsOptions.chart, marginLeft: 450 }
    }
    if (barSeriesData.length) {
      highchartsOptions.series?.push({
        type: 'bar',
        centerInCategory: true,
        data: barSeriesData
      })
      highchartsOptions.xAxis = {
        ...highchartsOptions.xAxis,
        categories: barSeriesData.map(([name]) => (name || '') as string)
      }
    }
  } catch (error) {}
  return highchartsOptions
}

// const mockup = {
//   device_category: {
//     Other: 10102,
//     desktop: 12323,
//     mobile: 394,
//     tablet: 500
//   },
//   mobile_device_branding: {
//     Apple: 126,
//     BlackBerry: 2,
//     Google: 104,
//     Huawei: 2,
//     LG: 2,
//     Lenovo: 2,
//     Microsoft: 18,
//     Nokia: 2,
//     OPPO: 50,
//     Other: 10114,
//     Realme: 6,
//     Samsung: 130,
//     Vivo: 20,
//     Vsmart: 8,
//     Xiaomi: 56
//   },
//   mobile_device_model: {
//     '1904': 2,
//     '1906': 2,
//     '2201116SG': 2,
//     '2201116TG': 2,
//     '2201117TG': 18,
//     '220333QAG': 2,
//     '23053RN02A': 2,
//     '2311DRK48G': 2,
//     A5000: 2,
//     'Active 3': 2,
//     'BBB100-1': 2,
//     C20: 2,
//     CPH1801: 2,
//     CPH1819: 2,
//     CPH1825: 4,
//     CPH1909: 2,
//     CPH1911: 2,
//     CPH1912: 2,
//     CPH1933: 2,
//     CPH1937: 2,
//     CPH1989: 4,
//     CPH2059: 4,
//     CPH2095: 2,
//     CPH2121: 2,
//     CPH2159: 4,
//     CPH2179: 2,
//     CPH2219: 2,
//     CPH2237: 2,
//     CPH2251: 2,
//     CPH2387: 2,
//     CPH2457: 2,
//     CPH2471: 2,
//     CPH2505: 2,
//     Chrome: 100,
//     Edge: 18,
//     'Joy 3': 4,
//     'Joy 4': 2,
//     'LDN-LX2': 2,
//     'LM-Q510N': 2,
//     M2007J20CG: 2,
//     M2007J3SG: 2,
//     M2012K11AG: 2,
//     M2101K6G: 6,
//     M2101K7AG: 2,
//     M2101K7BG: 2,
//     M2101K9AG: 2,
//     M2102J20SG: 4,
//     'Mi 9T': 2,
//     Other: 10114,
//     'Pixel 3 XL': 2,
//     'Pixel 6': 2,
//     RMX1943: 2,
//     RMX3231: 2,
//     RMX3511: 2,
//     'Redmi 6A': 2,
//     'Redmi Note 8': 2,
//     SCV42: 2,
//     SCV45: 2,
//     'SM-A025F': 2,
//     'SM-A035F': 6,
//     'SM-A047F': 2,
//     'SM-A127F': 2,
//     'SM-A145F': 2,
//     'SM-A146P': 2,
//     'SM-A155F': 2,
//     'SM-A207F': 2,
//     'SM-A217F': 2,
//     'SM-A225F': 4,
//     'SM-A235F': 2,
//     'SM-A245F': 2,
//     'SM-A315G': 6,
//     'SM-A325F': 8,
//     'SM-A336E': 2,
//     'SM-A3460': 2,
//     'SM-A505F': 6,
//     'SM-A507FN': 2,
//     'SM-A515F': 4,
//     'SM-A525F': 2,
//     'SM-A530F': 2,
//     'SM-A605G': 2,
//     'SM-A725F': 2,
//     'SM-A736B': 2,
//     'SM-A750GN': 6,
//     'SM-A805F': 2,
//     'SM-F711B': 2,
//     'SM-G610F': 4,
//     'SM-G780F': 2,
//     'SM-G960F': 2,
//     'SM-G977N': 2,
//     'SM-G977U': 2,
//     'SM-G990E': 4,
//     'SM-G996B': 4,
//     'SM-G996N': 2,
//     'SM-J250F': 2,
//     'SM-J600G': 2,
//     'SM-M325FV': 2,
//     'SM-M515F': 4,
//     'SM-M625F': 2,
//     'SM-N960F': 4,
//     'SM-N986B': 2,
//     'SM-S908E': 2,
//     'SM-S9180': 2,
//     'SM-T295': 2,
//     Safari: 14,
//     V2029: 2,
//     V2110: 2,
//     V2143: 2,
//     V2162A: 2,
//     V2201: 2,
//     V2247: 2,
//     V2310: 4,
//     iPhone: 110,
//     'iPhone 11 Pro Max': 2
//   }
// }
