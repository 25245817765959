import Icon from '@ant-design/icons'
import { Modal, ModalFuncProps } from 'antd'
import cn from 'classnames'
import { Close } from 'icons'

export type ModalConfirmProps = ModalFuncProps
export const ModalConfirm = (props: ModalConfirmProps) => {
  const { ...restProps } = props

  const closeIcon =
    typeof restProps?.closeIcon === 'undefined' ? (
      <Icon component={Close} />
    ) : (
      restProps?.closeIcon
    )

  return Modal.confirm({
    ...restProps,
    closeIcon,
    width: restProps.width || 460,
    wrapClassName: cn(restProps.wrapClassName, 'modalWrap--confirm'),
    cancelButtonProps: {
      ...(restProps?.cancelButtonProps || {})
    },
    okButtonProps: {
      type: 'primary',
      ...(restProps?.okButtonProps || {})
    }
  })
}
