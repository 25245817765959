import { MAP_SGM_TYPE_TO_CAT } from './useFields'
import { FieldCategoryItem, FieldCategoryListQuery } from 'interfaces/field'
import { useCallback, useMemo, useState } from 'react'
import { FieldServices } from 'services/field'

export function useFieldCategory() {
  const [loading, setLoading] = useState(false)

  const fetchFieldCategory = useCallback(
    async (
      query: Partial<FieldCategoryListQuery> & { segment_type?: string }
    ) => {
      try {
        setLoading(true)

        const filter_type = []
        if (query?.segment_type) {
          filter_type.push(MAP_SGM_TYPE_TO_CAT?.[query.segment_type])
        }
        if (query?.filter_type && query.filter_type?.length) {
          filter_type.push(...query.filter_type)
        }
        if (!filter_type.length) {
          return { data: null, errors: [{ errors: ['Filter type is empty'] }] }
        }

        const response = await FieldServices.getFieldCategoryList({
          page: 1,
          per_page: 99999,
          filter_status: [true, false],
          ...query,
          filter_type
        } as FieldCategoryListQuery)
        if (!response.data || response._message) {
          throw new Error(JSON.stringify(response))
        }

        return { data: response.data.data as FieldCategoryItem[], errors: [] }
      } catch (error) {
        console.log(error)
        return { data: null, errors: [{ errors: ['Something went wrong'] }] }
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return useMemo(
    () => ({ loading, setLoading, fetchFieldCategory }),
    [loading, fetchFieldCategory]
  )
}
