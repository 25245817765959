import { StyleWrapperFooterButton, StyledDrawer } from './index.styled'
import { Button, DrawerProps } from 'antd'
import { FC } from 'react'

interface DrawerFilterProps extends DrawerProps {
  onReset?: () => void
  onSubmit?: () => void
  numberSearch?: number
  children?: any
}

interface DefaultFooterProps {
  numberSearch?: number
  onReset?: () => void
  onSubmit?: () => void
}

const DefaultFooter: FC<DefaultFooterProps> = ({
  numberSearch,
  onReset,
  onSubmit
}) => {
  return (
    <StyleWrapperFooterButton>
      <Button onClick={onReset}>Reset</Button>
      <Button type="primary" onClick={onSubmit}>
        {numberSearch} Filter
      </Button>
    </StyleWrapperFooterButton>
  )
}

export const DrawerFilter: FC<DrawerFilterProps> = ({
  open,
  footer,
  children,
  numberSearch,
  onReset,
  onSubmit,
  ...restProps
}) => {
  return (
    <StyledDrawer
      open={open}
      {...restProps}
      footer={
        footer ? (
          footer
        ) : (
          <DefaultFooter
            onReset={onReset}
            onSubmit={onSubmit}
            numberSearch={numberSearch}
          />
        )
      }>
      {children}
    </StyledDrawer>
  )
}
