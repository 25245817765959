import { AnalyticTemplateDetailValue } from 'components/organisms/analytic'
import { AnalyticServices } from 'services/analytic'

export const segmentAnalyticCreate = async (
  values: AnalyticTemplateDetailValue
) => {
  console.log('** RYAN SegmentAnalytic.helpers.ts 7 values : ', values)
  try {
    const res: any = await AnalyticServices.postAnalyticTemplates({
      name: values?.name,
      description: values?.description,
      data: values?.data
    })
    if (!res.data || res._message) {
      throw new Error(JSON.stringify(res))
    }

    if (typeof res.data?.id !== 'undefined') {
      if (values?.widgets?.length) {
        const widgets = await Promise.all(
          values.widgets.map(
            (widget) =>
              new Promise(async (resolve, reject) => {
                const resWidget: any =
                  await AnalyticServices.postAnalyticTemplateWidget({
                    template_id: res.data.id,
                    ...widget
                  })
                if (resWidget?.data) {
                  resolve(resWidget.data)
                }
                reject(false)
              })
          )
        )
        return { data: { ...res.data, widgets }, errors: [] }
      }
    }
    return { data: { ...res.data, widgets: [], errors: [] } }
  } catch (error: any) {
    const errorObj = JSON.parse(error.message)
    if (errorObj?._message?.includes('segment_analytics_templates_name_key')) {
      return {
        data: null,
        errors: [{ name: 'name', messages: ['Duplicate name'] }]
      }
    }
  }
  return { data: null, errors: [{ messages: ['Something went wrong'] }] }
}
