import { StyledDictionaryParagraph } from './DictionaryTable.styled'
import Icon from '@ant-design/icons'
import { MenuProps, TableColumnProps, TabsProps, Tooltip } from 'antd'
import { TableCellTitle } from 'components/atoms/table'
import { FORMAT_DATE_DAYJS } from 'constants/common'
import dayjs from 'dayjs'
import { Edit, TrashBin } from 'icons'
import { OptionType } from 'interfaces'

export const DICTIONARY_CATEGORY = {
  ATTRIBUTE: 'attribute',
  EVENT: 'event',
  MOBILE: 'mobile'
}

export const dictionaryTabsItems: TabsProps['items'] = [
  { key: DICTIONARY_CATEGORY.EVENT, label: 'Events', children: null },
  { key: DICTIONARY_CATEGORY.ATTRIBUTE, label: 'Attributes', children: null },
  { key: DICTIONARY_CATEGORY.MOBILE, label: 'Mobile', children: null }
]

export const dictionaryActionItems: Record<string, MenuProps['items']> = {
  [DICTIONARY_CATEGORY.ATTRIBUTE]: [
    {
      key: 'edit',
      label: 'Edit',
      icon: <Icon component={Edit} />,
      type: undefined
    }
  ],
  [DICTIONARY_CATEGORY.EVENT]: [
    {
      key: 'edit',
      label: 'Edit',
      icon: <Icon component={Edit} />,
      type: undefined
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: <Icon component={TrashBin} />,
      type: undefined
    }
  ],
  [DICTIONARY_CATEGORY.MOBILE]: [
    {
      key: 'edit',
      label: 'Edit',
      icon: <Icon component={Edit} />,
      type: undefined
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: <Icon component={TrashBin} />,
      type: undefined
    }
  ]
}
export const dictionaryOptionsDataType: Record<string, OptionType[]> = {
  [DICTIONARY_CATEGORY.MOBILE]: [
    { value: 'text', label: 'text' },
    { value: 'number', label: 'number' },
    { value: 'bool', label: 'bool' },
    { value: 'array', label: 'array' },
    { value: 'date', label: 'date' }
  ],
  [DICTIONARY_CATEGORY.EVENT]: [
    { value: 'text', label: 'text' },
    { value: 'number', label: 'number' },
    { value: 'bool', label: 'bool' },
    { value: 'array', label: 'array' },
    { value: 'date', label: 'date' }
  ],
  [DICTIONARY_CATEGORY.ATTRIBUTE]: [
    { value: 'text', label: 'text' },
    { value: 'number', label: 'number' },
    { value: 'bool', label: 'bool' },
    { value: 'array', label: 'array' },
    { value: 'date', label: 'date' }
  ]
}

const renderCreatedAt = (_: any, record: any) => {
  const createdAt = dayjs(record.created_at).format(FORMAT_DATE_DAYJS)
  const updatedAt = dayjs(record.updated_at).format(FORMAT_DATE_DAYJS)
  return (
    <TableCellTitle
      tooltip={false}
      title={`Updated at: ${updatedAt}`}
      subTitle={`Created at: ${createdAt}`}
    />
  )
}
export const dictionaryColumns: Record<string, TableColumnProps<any>[]> = {
  [DICTIONARY_CATEGORY.MOBILE]: [
    { dataIndex: 'name', title: 'Name', className: 'name', width: 210 },
    { dataIndex: 'field', title: 'Key', width: 150 },
    {
      dataIndex: 'type',
      title: 'Data Type',
      className: 'data_type',
      width: 130
    },
    { dataIndex: 'description', title: 'Description', width: 200 },
    {
      dataIndex: 'source_explanation',
      title: 'Source explanation',
      width: 230
    },
    {
      dataIndex: 'created_at',
      title: 'Created at',
      render: renderCreatedAt,
      width: 250
    },
    {
      dataIndex: 'category_name',
      title: 'Category',
      className: 'category',
      width: 200
    },
    { dataIndex: 'status', title: 'Status', className: 'status', width: 100 },
    { dataIndex: 'action', title: '', width: 70 }
  ],
  [DICTIONARY_CATEGORY.EVENT]: [
    { dataIndex: 'name', title: 'Name', className: 'name', width: 210 },
    { dataIndex: 'field', title: 'Key', width: 150 },
    {
      dataIndex: 'type',
      title: 'Data Type',
      className: 'data_type',
      width: 130
    },
    {
      dataIndex: 'events',
      title: 'Events',
      render: (events) => {
        return events?.map((evt: any) => (
          <Tooltip title={evt?.name} key={evt?.id}>
            <StyledDictionaryParagraph className="tooltip">
              {evt?.name}
            </StyledDictionaryParagraph>
          </Tooltip>
        ))
      },
      width: 200
    },
    { dataIndex: 'description', title: 'Description', width: 200 },
    {
      dataIndex: 'source_explanation',
      title: 'Source explanation',
      width: 230
    },
    {
      dataIndex: 'created_at',
      title: 'Created at',
      render: renderCreatedAt,
      width: 250
    },
    {
      dataIndex: 'category_name',
      title: 'Category',
      className: 'category',
      width: 200
    },
    { dataIndex: 'status', title: 'Status', className: 'status', width: 100 },
    { dataIndex: 'action', title: '', width: 70 }
  ],
  [DICTIONARY_CATEGORY.ATTRIBUTE]: [
    { dataIndex: 'name', title: 'Name', className: 'name', width: 210 },
    { dataIndex: 'field', title: 'Key', width: 150 },
    {
      dataIndex: 'type',
      title: 'Data Type',
      className: 'data_type',
      width: 130
    },
    { dataIndex: 'description', title: 'Description', width: 200 },
    {
      dataIndex: 'source_explanation',
      title: 'Source explanation',
      width: 230
    },
    {
      dataIndex: 'category_name',
      title: 'Category',
      className: 'category',
      width: 200
    },
    { dataIndex: 'status', title: 'Status', className: 'status', width: 100 },
    { dataIndex: 'action', title: '', width: 70 }
  ]
}
