/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AnalyticWidgetItemProps,
  WIDGET_ITEM_KEY,
  WIDGET_ITEM_LABEL,
  getAnalyticWidgetItemDebug
} from './AnalyticWidgetItem.helpers'
import Icon from '@ant-design/icons'
import { Checkbox, Empty } from 'antd'
import { HighchartsLine } from 'components/atoms/Highcharts'
import { FORMAT_DATE_DAYJS_API } from 'constants/common'
import { theme } from 'constants/theme'
import dayjs from 'dayjs'
import Highcharts from 'highcharts'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { NoDataFolder } from 'icons'
import { FC, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { formatNumberString } from 'utils'

const overallScorecardKeys = [
  WIDGET_ITEM_KEY.EMAIL,
  WIDGET_ITEM_KEY.WEB,
  WIDGET_ITEM_KEY.PHONE,
  WIDGET_ITEM_KEY.APP,
  WIDGET_ITEM_KEY.WHATSAPP
]

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox {
    .ant-checkbox-inner {
      width: 15px;
      height: 15px;
      border-radius: 4px;
      &:after {
        left: 10%;
      }
    }
    &:after {
      border-color: ${({ theme }) => theme?.colors?.gray500};
      border-radius: 4px;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${({ theme }) => theme?.colors?.gray700};
      border-color: ${({ theme }) => theme?.colors?.gray700};
    }
  }
  &:hover {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: ${({ theme }) => theme?.colors?.gray500};
      }
    }
  }
`

export const generateLineGraphKey = (templateId?: number) => {
  if (!templateId) return ''
  return `${templateId}|showLineGraph`
}

export const AnalyticWidgetItemOverallScorecard: FC<AnalyticWidgetItemProps> = (
  props
) => {
  const { data } = props
  const highchartsRef = useRef<Highcharts.Chart>(null)
  const [, , removeShowLineGraph] = useLocalStorage(
    generateLineGraphKey(Number(props?.item?.template_id)),
    'false'
  )

  const metrics = useMemo(() => {
    return formatOverallScorecard(data)
  }, [data])

  useEffect(() => {
    removeShowLineGraph()
  }, [removeShowLineGraph])

  if (!metrics?.length || metrics.every(({ total }) => !total)) {
    return (
      <Empty
        image={
          <Icon
            component={NoDataFolder}
            className="text-[80px] leading-none mb-2"
          />
        }
        imageStyle={{ height: 'auto' }}
        description="No data analytic"
      />
    )
  }

  return (
    <div className="flex gap-6 flex-nowrap overflow-x-scroll scrollbar h-[159px]">
      {metrics.map((metric, index) => {
        const highchartOptions: Highcharts.Options = {
          xAxis: {
            // categories: metric.categories,
            tickWidth: 0,
            lineWidth: 0,
            gridLineWidth: 0
          },
          yAxis: {
            lineWidth: 0,
            gridLineWidth: 0
          }
        }
        if (metric.data?.length) {
          highchartOptions.series = [
            {
              type: 'line',
              name: metric?.label,
              data: metric?.data,
              color: metric?.color,
              lineWidth: 1,
              linkedTo: ':previous',
              tooltip: {
                headerFormat: ''
              },
              marker: {
                radius: 1
              }
            }
          ]
        }
        if (!metric.total) {
          return null
        }
        return (
          <div key={`${index}`} className="min-w-[308px] flex flex-col h-full">
            <div className="flex gap-1">
              {/* <div className="w-[20px] flex justify-center">
                <StyledCheckbox checked />
              </div> */}
              <div className="flex-1 min-w-0">
                <h4 className="title font-semibold text-gray800 mt-[1px] mb-1">
                  {metric?.label}
                </h4>
                <p className="m-0 mb-1 text-3xl text-gray800 font-bold leading-8 tracking-[-1px]">
                  {formatNumberString(metric?.total)}
                </p>
                {/* <div className="flex items-center">
                  <Icon
                    component={metric?.ratio >= 0 ? TrendUp : TrendDown}
                    className="text-[20px] leading-none mr-1"
                  />
                  <span className="text-gray600">
                    {metric?.ratio > 0
                      ? `+${metric.ratio}%`
                      : `${metric.ratio}%`}
                  </span>
                </div> */}
              </div>
            </div>
            {props.item?.data?.showLineGraph && (
              <div className="flex-1 min-h-0 pl-[24px]">
                <HighchartsLine
                  ref={highchartsRef}
                  options={highchartOptions}
                  className="h-full"
                />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

type MetricItem = {
  key: string
  label?: string
  categories?: string[]
  data?: number[]
  total: number
  color?: string
  ratio: number
}
export function formatOverallScorecard(
  data?: any,
  itemKeys = overallScorecardKeys
): MetricItem[] {
  const { detail, ...restData } = data || {}
  const metrics: MetricItem[] = itemKeys.map((key) => {
    const metric: MetricItem = {
      key,
      label: WIDGET_ITEM_LABEL?.[key] || '',
      data: [],
      categories: [],
      total: typeof restData?.[key] === 'number' ? restData[key] : 0,
      color: theme?.colors.green600,
      ratio: 0
    }
    return metric
  })

  if (Array.isArray(detail) && !detail?.length) {
    return metrics
  }
  try {
    detail
      ?.sort((a: any, b: any) => {
        const timeA = dayjs(a?.time, FORMAT_DATE_DAYJS_API, true)
        const timeB = dayjs(b?.time, FORMAT_DATE_DAYJS_API, true)
        if (timeA.isValid() && timeB.isValid() && timeA > timeB) {
          return -1
        } else {
          return 1
        }
      })
      .forEach((dt: any, index: number) => {
        const indexTime = dt?.time
        metrics.forEach((metric) => {
          const itemKey = metric.key
          const detailValue =
            typeof dt?.[itemKey] === 'number' ? dt[itemKey] : 0
          metric.data?.push(detailValue)
          metric.categories?.push(indexTime)

          if (index === detail.length - 1) {
            const firstNumber = metric.data?.[0] || 0
            const lastNumber = detailValue
            if (firstNumber > 0) {
              metric.ratio = Math.round(
                ((lastNumber - firstNumber) / firstNumber) * 100
              )
              metric.color =
                metric.ratio < 0 ? theme?.colors.red600 : theme?.colors.green600
            }
          }
        })
      })
  } catch (error) {
    if (getAnalyticWidgetItemDebug()) {
      console.log('** formatAnalyticWidgetItemOverallScorecard :', error)
    }
  }
  return metrics
}

// const mockup = {
//   app_token: 0,
//   detail: [
//     {
//       app_token: 0,
//       date: '2024-03-24',
//       email: 6974,
//       phone: 21,
//       web_token: 0
//     },
//     {
//       app_token: 0,
//       date: '2024-03-25',
//       email: 78101,
//       phone: 201,
//       web_token: 9
//     },
//     {
//       app_token: 0,
//       date: '2024-03-26',
//       email: 8934,
//       phone: 18,
//       web_token: 2
//     },
//     {
//       app_token: 0,
//       date: '2024-03-27',
//       email: 173773,
//       phone: 444,
//       web_token: 30
//     },
//     {
//       app_token: 0,
//       date: '2024-03-28',
//       email: 173077,
//       phone: 444,
//       web_token: 16
//     },
//     {
//       app_token: 0,
//       date: '2024-03-29',
//       email: 171011,
//       phone: 439,
//       web_token: 20
//     },
//     {
//       app_token: 0,
//       date: '2024-03-30',
//       email: 170297,
//       phone: 440,
//       web_token: 15
//     },
//     {
//       app_token: 0,
//       date: '2024-03-31',
//       email: 19855,
//       phone: 61,
//       web_token: 0
//     },
//     {
//       app_token: 0,
//       date: '2024-04-01',
//       email: 34919,
//       phone: 90,
//       web_token: 1
//     },
//     {
//       app_token: 0,
//       date: '2024-04-02',
//       email: 170519,
//       phone: 443,
//       web_token: 11
//     },
//     {
//       app_token: 0,
//       date: '2024-04-03',
//       email: 171808,
//       phone: 443,
//       web_token: 16
//     },
//     {
//       app_token: 0,
//       date: '2024-04-04',
//       email: 171890,
//       phone: 443,
//       web_token: 12
//     },
//     {
//       app_token: 0,
//       date: '2024-04-05',
//       email: 171714,
//       phone: 443,
//       web_token: 17
//     },
//     {
//       app_token: 0,
//       date: '2024-04-06',
//       email: 171182,
//       phone: 443,
//       web_token: 5
//     },
//     {
//       app_token: 0,
//       date: '2024-04-07',
//       email: 171896,
//       phone: 443,
//       web_token: 17
//     },
//     {
//       app_token: 0,
//       date: '2024-04-08',
//       email: 172863,
//       phone: 443,
//       web_token: 14
//     },
//     {
//       app_token: 0,
//       date: '2024-04-09',
//       email: 173487,
//       phone: 443,
//       web_token: 9
//     },
//     {
//       app_token: 0,
//       date: '2024-04-10',
//       email: 174110,
//       phone: 444,
//       web_token: 32
//     },
//     {
//       app_token: 0,
//       date: '2024-04-11',
//       email: 174627,
//       phone: 444,
//       web_token: 20
//     },
//     {
//       app_token: 0,
//       date: '2024-04-12',
//       email: 175428,
//       phone: 444,
//       web_token: 22
//     },
//     {
//       app_token: 0,
//       date: '2024-04-13',
//       email: 175942,
//       phone: 443,
//       web_token: 14
//     },
//     {
//       app_token: 0,
//       date: '2024-04-14',
//       email: 27396,
//       phone: 75,
//       web_token: 4
//     },
//     {
//       app_token: 0,
//       date: '2024-04-15',
//       email: 176746,
//       phone: 444,
//       web_token: 25
//     },
//     {
//       app_token: 0,
//       date: '2024-04-16',
//       email: 178313,
//       phone: 446,
//       web_token: 22
//     },
//     {
//       app_token: 0,
//       date: '2024-04-17',
//       email: 179815,
//       phone: 446,
//       web_token: 21
//     },
//     {
//       app_token: 0,
//       date: '2024-04-18',
//       email: 181252,
//       phone: 446,
//       web_token: 27
//     },
//     {
//       app_token: 0,
//       date: '2024-04-19',
//       email: 182645,
//       phone: 446,
//       web_token: 21
//     },
//     {
//       app_token: 0,
//       date: '2024-04-20',
//       email: 183971,
//       phone: 446,
//       web_token: 20
//     },
//     {
//       app_token: 0,
//       date: '2024-04-21',
//       email: 185181,
//       phone: 447,
//       web_token: 36
//     },
//     {
//       app_token: 0,
//       date: '2024-04-22',
//       email: 179553,
//       phone: 435,
//       web_token: 41
//     },
//     {
//       app_token: 0,
//       date: '2024-04-23',
//       email: 187974,
//       phone: 448,
//       web_token: 34
//     },
//     {
//       app_token: 0,
//       date: '2024-04-24',
//       email: 189328,
//       phone: 448,
//       web_token: 37
//     },
//     {
//       app_token: 0,
//       date: '2024-04-25',
//       email: 27315,
//       phone: 75,
//       web_token: 4
//     },
//     {
//       app_token: 0,
//       date: '2024-04-26',
//       email: 141269,
//       phone: 295,
//       web_token: 20
//     },
//     {
//       app_token: 0,
//       date: '2024-04-27',
//       email: 193221,
//       phone: 448,
//       web_token: 29
//     },
//     {
//       app_token: 0,
//       date: '2024-04-28',
//       email: 194362,
//       phone: 448,
//       web_token: 26
//     },
//     {
//       app_token: 0,
//       date: '2024-04-29',
//       email: 195526,
//       phone: 448,
//       web_token: 42
//     },
//     {
//       app_token: 0,
//       date: '2024-04-30',
//       email: 196237,
//       phone: 449,
//       web_token: 41
//     },
//     {
//       app_token: 0,
//       date: '2024-05-01',
//       email: 59543,
//       phone: 127,
//       web_token: 11
//     },
//     {
//       app_token: 0,
//       date: '2024-05-02',
//       email: 57695,
//       phone: 123,
//       web_token: 12
//     },
//     {
//       app_token: 0,
//       date: '2024-05-03',
//       email: 197696,
//       phone: 450,
//       web_token: 33
//     },
//     {
//       app_token: 0,
//       date: '2024-05-04',
//       email: 198148,
//       phone: 451,
//       web_token: 23
//     },
//     {
//       app_token: 0,
//       date: '2024-05-05',
//       email: 198221,
//       phone: 451,
//       web_token: 21
//     }
//   ],
//   email: 6423814,
//   phone: 15769,
//   web_token: 832
// }
