import {
  StyledButton,
  StyledInput,
  StyledSlider,
  StyledSliderContainer
} from './index.styled'
import Icon from '@ant-design/icons'
import { ButtonProps } from 'antd'
import { SliderRangeProps } from 'antd/lib/slider'
import cn from 'classnames'
import { Minus, Plus } from 'icons'
import { useEffect, useRef, useState } from 'react'
import { CSSProperties } from 'styled-components'

function sliderCalculateByPosition({
  value: valueProps,
  position = 'from',
  min = 0,
  max = 100,
  step = 1
}: {
  value?: [number, number]
  position?: 'from' | 'to'
  min?: number
  max?: number
  step?: number
}): [number, number] {
  const defaultValue: [number, number] = [0, 0]
  if (!valueProps) {
    return defaultValue
  }
  const value: [number, number] =
    typeof valueProps !== 'undefined' ? [...valueProps] : defaultValue

  if (position === 'from') {
    value[0] = value[0] + step
    if (value[0] < min) {
      value[0] = min
    }
    if (value[0] > value[1]) {
      return [value[0], value[0]]
    }
  }
  if (position === 'to') {
    value[1] = value[1] + step
    if (value[1] > max) {
      value[1] = max
    }
    if (value[0] > value[1]) {
      return [value[1], value[1]]
    }
  }

  return value
}

export type SliderRangeInputProps = Omit<SliderRangeProps, 'range'> & {
  size?: ButtonProps['size']
  style?: CSSProperties
  className?: string
  formatString?: (value?: number) => string
}
export const SliderRangeInput = (props: SliderRangeInputProps) => {
  const {
    defaultValue,
    value: valueProps,
    onChange: onChangeProps,
    size,
    style,
    className,
    formatString: formatStringProps,
    ...restProps
  } = props
  const $sliderContainer = useRef<HTMLDivElement | null>(null)
  const valueRef = useRef(defaultValue || valueProps)
  const [value, setValue] = useState<[number, number] | undefined>(
    defaultValue || valueProps || [0, 0]
  )
  const [position, setPosition] = useState<'from' | 'to' | undefined>('from')

  const onChange = (val: [number, number]) => {
    if (valueRef.current) {
      const prevValue = valueRef.current
      const currentValue = val
      let pos = 'from'
      if (prevValue[1] !== currentValue[1]) {
        pos = 'to'
      }
      if (prevValue[0] !== currentValue[0]) {
        pos = 'from'
      }
      setPosition(pos as 'from' | 'to')
    }
    valueRef.current = val
    setValue(val)
    onChangeProps && onChangeProps(val)
  }

  const onPlus = () => {
    const newValue = sliderCalculateByPosition({
      value,
      position,
      min: restProps?.min,
      max: restProps?.max,
      step: restProps?.step ? restProps.step : 1
    })
    setValue(newValue)
    onChangeProps && onChangeProps(newValue)
  }
  const onMinus = () => {
    const newValue = sliderCalculateByPosition({
      value,
      position,
      min: restProps?.min,
      max: restProps?.max,
      step: restProps?.step ? -restProps.step : -1
    })
    setValue(newValue)
    onChangeProps && onChangeProps(newValue)
  }

  const formatString = (val?: number): string => {
    if (formatStringProps) {
      return formatStringProps(val)
    }

    if (typeof val === 'undefined') {
      return ''
    }

    return val.toString() || ''
  }

  useEffect(() => {
    setValue(valueProps)
  }, [valueProps])

  useEffect(() => {
    function addEventClickHandle(this: any, e: MouseEvent) {
      const $target = e.target as HTMLElement
      if ($target.classList.contains('ant-slider-handle-1')) {
        setPosition('from')
      }
      if ($target.classList.contains('ant-slider-handle-2')) {
        setPosition('to')
      }

      if (!$target.closest(`.slider--range-input`)) {
        setPosition(undefined)
      }
    }
    if ($sliderContainer.current) {
      window.addEventListener('click', addEventClickHandle)
    }

    return () => {
      window.removeEventListener('click', addEventClickHandle)
    }
  }, [])

  return (
    <StyledSliderContainer
      ref={$sliderContainer}
      style={style}
      className={cn(className, 'slider--range-input', position)}>
      <StyledInput value={formatString(value?.[0])} size={size} disabled />
      <StyledButton
        icon={<Icon component={Minus} />}
        size={size}
        onClick={onMinus}
      />
      <StyledSlider
        {...restProps}
        range
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        tooltip={{
          formatter: (val) => {
            return formatString(val)
          },
          placement: restProps.tooltipPlacement || 'top'
        }}
      />
      <StyledButton
        icon={<Icon component={Plus} />}
        size={size}
        onClick={onPlus}
      />
      <StyledInput value={formatString(value?.[1])} size={size} disabled />
    </StyledSliderContainer>
  )
}
